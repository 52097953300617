export default [
  {
    key: 'id',
    label: 'ID',
  },
  {
    key: 'device',
    label: 'Name',
  },
  {
    key: 'user',
    label: 'User',
  },
];
