import {SELECT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {string} from '../../types';

export default attribute({
  type: SELECT,
  attribute: 'fhv_inspection_type',
  api: string,
  label: {
    default: 'Vehicle Inspection Type',
    short: 'Inspection Type',
  },
  options: ['VISUAL', 'DMV'],
});
