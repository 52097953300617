export default [
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'fhv',
    label: 'FHV License Number',
  },
];
