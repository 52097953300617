import {profile} from 'f3tch';

// Constants
import url from '../constants/url.const.api';

// Errors
import isApiError from '../errors/isApiError.error.api';

// Profiles
import headers from '../headers/json.header.api';

export default ({authorize = true} = {}) =>
  profile(url)
    .headers(headers({authorize}))
    .bodyParser((data) => JSON.stringify(data))
    .responder(async (response) => {
      await isApiError(response);
      return true;
    });
