const docsUIList = (folders) =>
  [...folders].map((folder) => {
    const uiFolder = {
      key: folder.type,
      name: folder.folderName,
      uploadable: folder.uploadable,
    };
    if (!!folder.folders) uiFolder.folders = docsUIList(folder.folders);
    return uiFolder;
  });

export default (folders) => {
  const flatTree = [...folders].reduce(
    (combined, folder) =>
      !!folder.folders
        ? [...combined, folder, ...folder.folders]
        : [...combined, folder],
    []
  );

  const foldersObject = [...flatTree].reduce(
    (combined, folder) => ({...combined, [folder.type]: folder}),
    {}
  );

  const uiDocs = docsUIList(folders);

  return {
    folders,
    flatTree,
    foldersObject,
    uiDocs,
  };
};
