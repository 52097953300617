import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Api
import getDriverByID from '../../api/getByID.api.driver';
import listRentalsApi from '../../../rental/api/list.api.rental';

// Components
import DispatchDriverStep from '../../components/DispatchDriverStep/DispatchDriverStep';

// Libs
import {lib} from '@matthahn/sally-ui';

// States
import activeState from '../../state/active.state.driver';
import prospectState from '../../state/prospect.state.driver';
import readyState from '../../state/ready.state.driver';

// Alert
const {alert} = lib;

class DispatchDriverStepContainer extends Component {
  static propTypes = {
    Container: PropTypes.func,
    visible: PropTypes.bool,
    dispatchVehiclesLoading: PropTypes.bool,
    driver: PropTypes.object,
    drivers: PropTypes.array,
    vehicle: PropTypes.object,
    vehicles: PropTypes.array,
    onNext: PropTypes.func,
    onReset: PropTypes.func,
    onClose: PropTypes.func,
    onDisabled: PropTypes.func,
  };

  state = {
    loading: false,
    searching: false,
    selectedDriver: null,
    suggestedDrivers: [],
  };

  componentDidMount() {
    if (this.props.visible) this.init();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) this.init();
  }

  init = async () => {
    const {driver, onDisabled} = this.props;

    this.setState({
      loading: true,
      selectedDriver: !!driver ? `${driver.id}` : null,
    });
    onDisabled(true);

    try {
      const {results: rentals} = await listRentalsApi({
        ordering: '-created_at',
      });
      const suggestedDrivers = [...rentals].reduce(
        (combined, {driver}) =>
          [prospectState.key, readyState.key].includes(driver.state) &&
          ![...combined].find((d) => d.id === driver.id)
            ? [...combined, driver]
            : combined,
        []
      );

      this.setState({loading: false, suggestedDrivers});
    } catch (error) {
      alert.error('Something went wrong. Please close the flow and try again');
      this.setState({loading: false});
    }

    onDisabled(false);
  };

  onChange = (val) => {
    if (this.state.searching) return;
    this.props.onReset();
    this.setState({selectedDriver: val});
  };

  drivers = () =>
    [
      ...this.state.suggestedDrivers,
      ...[...this.props.drivers].filter(
        ({id, state}) =>
          [prospectState.key, readyState.key].includes(state) &&
          ![...this.state.suggestedDrivers].find((driver) => driver.id === id)
      ),
    ].map(({id, first_name, last_name, fhv_license_number}) => ({
      value: `${id}`,
      label: `${first_name} ${last_name} - ${
        !!fhv_license_number ? fhv_license_number : 'NO FHV'
      }`,
    }));

  next = async () => {
    const {vehicle, vehicles, onNext} = this.props;
    const {searching, selectedDriver} = this.state;
    if (searching) return;

    if (!selectedDriver) return alert.warning('Select a driver');

    this.setState({searching: true});

    try {
      const hotswap = [...vehicles].find(
        ({id, active_rentals}) =>
          id === vehicle.id && !!active_rentals && !!active_rentals.length
      );
      const driver = await getDriverByID(selectedDriver);

      if (driver.state === activeState.key) {
        alert.warning(`${driver.first_name} ${driver.last_name} is on duty`);
        return this.setState({searching: false});
      }

      if (![prospectState.key, readyState.key].includes(driver.state)) {
        alert.warning(
          `${driver.first_name} ${driver.last_name} is not ready to take a vehicle`
        );
        return this.setState({searching: false});
      }

      onNext({driver, hotswap});
    } catch (error) {
      alert.error('Could not find driver. Select another one.');
      this.setState({searching: false});
    }
  };

  render() {
    const {Container, dispatchVehiclesLoading} = this.props;
    const {searching, selectedDriver, loading} = this.state;
    return (
      <DispatchDriverStep
        Container={Container}
        searching={searching}
        loading={loading || dispatchVehiclesLoading}
        drivers={this.drivers()}
        selectedDriver={selectedDriver}
        onChange={this.onChange}
        onNext={this.next}
      />
    );
  }
}

export default connect((state) => ({drivers: state.spotlight.drivers}))(
  DispatchDriverStepContainer
);
