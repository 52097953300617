// Lib
import sum from '../../lib/sum';
import subtract from '../../lib/subtract';
import isCreditTransaction from './isCreditTransaction.lib.payout';

// Transactions
import parseTransactionTypes from '../../transaction/type/parseTypes.type.transaction';
import getTransactionKey from '../../transaction/type/transactionKey.type.transaction';
import transactionType from '../../transaction/type/type.transaction';

export default (
  transactions,
  types,
  {
    exclude = false,
    isIncomeAllocation = false,
    isDepositAllocation = false,
  } = {}
) => {
  const grouped = Object.values(
    [...transactions].reduce(
      (combined, transaction) => {
        const transactionKey = getTransactionKey(transaction);
        if (!combined[transactionKey])
          combined[transactionKey] = transactionType(transaction);
        const type = combined[transactionKey];
        return {
          ...combined,
          [transactionKey]: {
            ...type,
            transactions: [...type.transactions, transaction],
            balance: sum(type.balance, transaction.balance),
          },
        };
      },
      {...parseTransactionTypes(types)}
    )
  );

  const filters = [
    isDepositAllocation
      ? (transaction) => !transaction.exclude_from_deposit_payout
      : exclude
      ? (transaction) => !transaction.exclude_from_payout
      : null,
    isIncomeAllocation &&
      ((transaction) =>
        transaction.owed_to_sally || isCreditTransaction(transaction)),
  ];

  const filtered = [...filters].reduce(
    (combined, transactionFilter) =>
      !!transactionFilter ? [...combined].filter(transactionFilter) : combined,
    [...grouped]
  );

  return [...filtered].reduce((combined, current) => {
    if (!!current.deduct_balance_from) return combined;
    if (!current.owed_to_sally) return [...combined, current];
    const deduct = [...filtered].filter(
      (transaction) => transaction.deduct_balance_from === current.subtype
    );
    const balance = [...deduct].reduce(
      (oldBalance, deductTransaction) =>
        subtract(oldBalance, deductTransaction.balance),
      current.balance
    );
    const transactions = [...deduct]
      .map((deductTransaction) =>
        [...deductTransaction.transactions].map((transaction) => ({
          ...transaction,
          amount: transaction.amount * -1,
          balance: transaction.balance * -1,
        }))
      )
      .flat();
    return [
      ...combined,
      {
        ...current,
        balance,
        transactions: [...current.transactions, ...transactions],
      },
    ];
  }, []);
};
