// Prep
import prepare from '../../attribute/prepare.attribute';

// Constants
import readyAttributes from '../constants/readyAttributes.constants.vehicle';

export default (attributes) =>
  prepare(attributes, {
    field: 'api',
    required: [...readyAttributes],
  });
