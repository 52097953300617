import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {v4} from 'uuid';

// Api
import listDocumentsApi from '../../api/list.api.document';
import uploadDocumentApi from '../../api/upload.api.document';
import deleteDocumentApi from '../../api/delete.api.document';

// Documents
import * as accidentDocs from '../../../accident/documents/documents';
import * as driverDocs from '../../../driver/documents';
import * as medallionDocs from '../../../medallion/documents';
import * as vehicleDocs from '../../../vehicle/documents';

// Libs
import {lib} from '@matthahn/sally-ui';
import parseError from '../../../error/parseError';
import api from '../../../api/lib/getEverythingFromApi.lib.api';
import isProduction from '../../../app/lib/isProduction.lib.app';

// Components
import {FileManager, Row, Column, Heading} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';
import RemoveDocumentModal from '../../components/RemoveDocumentModal/RemoveDocumentModal';

// Containers
// import {DriverSelectionInputContainer} from '../../organisms/DriverSelectionInputContainer';
// import {VehicleSelectionInputContainer} from '../../organisms/VehicleSelectionInputContainer';
import DocumentSignatureContainer from '../DocumentSignatureContainer/DocumentSignatureContainer';

// Events
import docCreatedEvt from '../../events/created.event.document';
import docUpdatedEvt from '../../events/updated.event.document';
import docDeletedEvt from '../../events/deleted.event.document';

// Notifications
const {alert} = lib;

// Helpers
const docs = {
  accident: accidentDocs,
  driver: driverDocs,
  medallion: medallionDocs,
  vehicle: vehicleDocs,
};

class DocumentsContainer extends Component {
  static propTypes = {
    accident: PropTypes.object,
    driver: PropTypes.object,
    medallion: PropTypes.object,
    vehicle: PropTypes.object,
    type: PropTypes.string,
    folderType: PropTypes.string,
    uploadMode: PropTypes.bool,
    duplicates: PropTypes.array,
    staticSelection: PropTypes.bool,
    predefinedDocs: PropTypes.object,
    onDone: PropTypes.func,
    onLoad: PropTypes.func,
    getData: PropTypes.func,
  };

  static defaultProps = {
    uploadMode: false,
    staticSelection: false,
    duplicates: [],
    predefinedDocs: null,
    driver: null,
    medallion: null,
    vehicle: null,
    accident: null,
    onDone: () => {},
    onLoad: () => {},
    getData: () => {},
  };

  state = {
    loading: true,
    uploading: false,
    removing: false,
    documents: [],
    data: null,
    type: null,
    errors: [],
    attributesList: [],
    accident: null,
    driver: null,
    vehicle: null,
    removingFileID: null,
    signature: null,
  };

  componentDidMount() {
    this.mounted = true;
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.type !== this.props.type) this.init();
  }

  componentWillUnmount() {
    this.unsub.forEach((fn) => fn());
    this.mounted = false;
  }

  unsub = [];

  init = () => {
    const {
      uploadMode,
      accident,
      driver,
      medallion,
      vehicle,
      folderType,
      getData,
    } = this.props;
    this.setState({accident, driver, medallion, vehicle});
    this.unsub.forEach((fn) => fn());
    this.unsub = uploadMode
      ? []
      : [
          docCreatedEvt.sub(this.onDocCreated),
          docUpdatedEvt.sub(this.onDocUpdated),
          docDeletedEvt.sub(this.onDocDeleted),
        ];
    if (!uploadMode) this.getDocs();
    if (!!folderType) this.onType(folderType);
    getData(this.getData);
  };

  getData = () => this.state;

  notification = (docID) => ({
    id: 'removeDocumentNotification',
    title: 'Remove Files',
    icon: undefined,
    content: 'Are you sure you want to remove this file?',
    primary: {
      label: 'No',
      onClick: () => null,
    },
    secondary: {
      label: 'Yes',
      onClick: () => this.remove(docID),
    },
    closable: false,
    closeOnOutsideClick: true,
  });

  stop = (apiID) => this.apiID !== apiID || !this.mounted;

  onDocCreated = (doc) => {
    if (!this.mounted) return;
    this.setState({documents: [{...doc}, ...this.state.documents]});
  };

  onDocUpdated = (doc) => {
    if (!this.mounted) return;
    this.setState({
      documents: [...this.state.documents].map((d) =>
        d.id === doc.id ? {...doc} : d
      ),
    });
  };

  onDocDeleted = (id) => {
    if (!this.mounted) return;
    this.setState({
      documents: [...this.state.documents].filter((doc) => doc.id !== id),
    });
  };

  onDataChange = (value, key, errors) => {
    const {data, uploading} = this.state;
    if (uploading || !this.mounted) return;
    this.setState({
      data: {...data, [key]: value},
      errors,
    });
  };

  docs = () => {
    const {predefinedDocs, type} = this.props;
    return !!predefinedDocs ? predefinedDocs : docs[type];
  };

  onType = (folderType = null) => {
    const {
      folderType: defaultFolderType,
      accident,
      driver,
      medallion,
      vehicle,
    } = this.props;
    if (!folderType && !!defaultFolderType) return;
    const state = {type: folderType};
    if (!!folderType) {
      const folder = this.docs().foldersObject[folderType];
      const data = folder.initAttributes({
        accident,
        driver,
        medallion,
        vehicle,
      });
      state.data = data;
      state.attributesList = Object.keys(data);
    } else {
      state.data = null;
      state.attributesList = [];
    }
    if (!this.mounted) return;
    this.setState(state);
  };

  onObjectSelect = (object, type) => {
    if (!this.mounted) return;
    this.setState({[type]: object});
  };

  setLoading = ({uploading, ...data}) => {
    if (!this.mounted) return;
    this.setState({uploading, ...data});
    this.props.onLoad(uploading);
  };

  getDocs = async () => {
    const {accident, driver, medallion, vehicle} = this.props;
    const apiID = v4();
    this.apiID = apiID;

    this.setState({loading: true});

    try {
      const apiCall = (q) =>
        api(
          listDocumentsApi,
          {...q, ordering: '-id'},
          {shouldContinue: () => this.mounted}
        );

      const queries = [
        accident &&
          apiCall({
            accident: accident.id,
          }),
        driver &&
          apiCall({
            driver: driver.id,
          }),
        medallion &&
          apiCall({
            medallion: medallion.id,
          }),
        vehicle &&
          apiCall({
            vehicle: vehicle.id,
          }),
      ].filter((q) => !!q);

      const rawDocuments = await Promise.all(queries);

      if (this.stop(apiID)) return;

      const documents = rawDocuments.reduce(
        (combined, {data: docList}) => [
          ...combined,
          ...docList.filter((doc) => !combined.find((d) => d.id === doc.id)),
        ],
        []
      );

      this.setState({
        loading: false,
        documents,
      });
    } catch (error) {
      if (this.stop(apiID)) return;
      this.setState({loading: false});
    }
  };

  upload = async ({file: doc, done} = {}) => {
    const {type, duplicates, onDone} = this.props;
    const {
      uploading,
      data,
      type: folderType,
      accident,
      driver,
      medallion,
      vehicle,
      documents,
      signature,
    } = this.state;
    const file = this.docs().foldersObject[folderType];

    if (uploading || !file || !doc) return;

    if (file.signature && !signature)
      return alert.warning('Signature required');

    this.setLoading({uploading: true});

    const rawDocumentData = !!signature ? {signature} : {};

    try {
      const {files: newFiles, object} = await uploadDocumentApi({
        doc,
        file,
        accident,
        driver,
        medallion,
        vehicle,
        data,
        type,
        rawDocumentData,
        duplicates: [...documents, ...duplicates].reduce(
          (combined, current) =>
            !!combined.find((c) => c.id === current.id)
              ? [...combined]
              : [...combined, current],
          []
        ),
      });
      done();
      if (!this.mounted) return;
      this.setLoading({
        uploading: false,
        type: null,
        data: null,
        attributesList: [],
      });
      alert.success('File uploaded successfully');
      onDone({object, files: newFiles});
    } catch (error) {
      const {message, fields} = parseError(error);
      alert.error(message);
      if (!this.mounted) return;
      this.setLoading({uploading: false, errors: fields});
    }
  };

  removeDocuments = (id) => {
    if (this.state.removing || !this.mounted) return;
    this.setState({removingFileID: id});
  };

  closeRemoveModal = () => {
    if (this.state.removing || !this.mounted) return;
    this.setState({
      removingFileID: null,
    });
  };

  sign = (signature) => {
    if (this.state.uploading) return;
    this.setState({signature});
  };

  remove = async () => {
    const {removingFileID, removing} = this.state;
    if (removing) return;

    this.setState({removing: true});

    try {
      await deleteDocumentApi(removingFileID);
      alert.success('File deleted');
      if (!this.mounted) return;
      this.setState({
        removing: false,
        removingFileID: null,
        documents: [...this.state.documents].filter(
          ({id}) => id !== removingFileID
        ),
      });
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      if (!this.mounted) return;
      this.setState({
        removing: false,
      });
    }
  };

  files = () =>
    [...this.state.documents].map((doc) => ({
      id: doc.id,
      name: doc.name,
      folder: doc.type,
      download: doc.document_file,
      preview: doc.thumbnail_file,
    }));

  folders = () => [...this.docs().uiDocs];

  content = () => {
    const {staticSelection} = this.props;
    const {
      data,
      errors,
      uploading,
      attributesList,
      type: folderType,
      signature,
      // driver,
      // vehicle,
    } = this.state;
    const folder = this.docs().foldersObject[folderType];
    const selection = !!folder ? folder.selection : [];
    if (!attributesList.length && (staticSelection || !selection.length))
      return null;
    return (
      <div>
        {!!folder && (
          <Row margin>
            <Column>
              <Heading>{folder.defaultFileName}</Heading>
            </Column>
          </Row>
        )}
        {/* {!staticSelection && selection.includes('driver') && (
          <Row margin>
            <Column>
              <DriverSelectionInputContainer
                driver={driver}
                onSelect={(o) => this.onObjectSelect(o, 'driver')}
              />
            </Column>
          </Row>
        )}
        {!staticSelection && selection.includes('vehicle') && (
          <Row margin>
            <Column>
              <VehicleSelectionInputContainer
                vehicle={vehicle}
                onSelect={(o) => this.onObjectSelect(o, 'vehicle')}
              />
            </Column>
          </Row>
        )} */}
        {attributesList.map((attribute, index) => (
          <Row
            key={attribute}
            margin={folder.signature || index + 1 < attributesList.length}
          >
            <Column>
              <AttributeInput
                value={data[attribute]}
                onChange={this.onDataChange}
                disabled={uploading}
                errors={errors}
                computedValues={data}
                noPaste={isProduction()}
              >
                {data[attribute].label.short}
              </AttributeInput>
            </Column>
          </Row>
        ))}
        {folder.signature && (
          <Row>
            <Column>
              <DocumentSignatureContainer
                image={signature}
                onSign={this.sign}
              />
            </Column>
          </Row>
        )}
      </div>
    );
  };

  render() {
    const {
      accident,
      driver,
      medallion,
      vehicle,
      type: justToExtractItType,
      onDone,
      ...props
    } = this.props;
    const {loading, uploading, type, removing, removingFileID} = this.state;
    return (
      <Fragment>
        <FileManager
          loading={loading}
          uploading={uploading}
          type={type}
          files={this.files()}
          folders={this.folders()}
          onUpload={this.upload}
          onRemove={this.removeDocuments}
          onType={this.onType}
          {...props}
        >
          {this.content()}
        </FileManager>
        <RemoveDocumentModal
          visible={!!removingFileID}
          loading={removing}
          onRemove={this.remove}
          onClose={this.closeRemoveModal}
        />
      </Fragment>
    );
  }
}

export default DocumentsContainer;
