import React from 'react';
import PropTypes from 'prop-types';

// permission components
import BooleanPermission from '../BooleanPermission/BooleanPermission';
import ChargeTypePermission from '../ChargeTypePermission/ChargeTypePermission';
import CreditTypePermission from '../CreditTypePermission/CreditTypePermission';
import StringPermission from '../StringPermission/StringPermission';

const PermissionModifier = ({onChange, permission, value}) => {
  if (permission.type === 'boolean')
    return (
      <BooleanPermission
        label={permission.label}
        onChange={onChange}
        value={value}
      />
    );
  if (permission.permission === 'charge_types_authorized_permission')
    return (
      <ChargeTypePermission
        label={permission.label}
        onChange={onChange}
        value={value}
      />
    );
  if (permission.permission === 'credit_types_authorized_permission')
    return (
      <CreditTypePermission
        label={permission.label}
        onChange={onChange}
        value={value}
      />
    );
  if (permission.type === 'string')
    return (
      <StringPermission
        label={permission.label}
        onChange={onChange}
        value={value}
      />
    );
  return null;
};

PermissionModifier.propTypes = {
  onChange: PropTypes.func,
  permission: PropTypes.object,
  value: PropTypes.any,
};

export default PermissionModifier;
