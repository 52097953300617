import {folderTree as createFolderTree} from '../../document';

// Files
import {
  billOfSale,
  coi,
  dec,
  fh1,
  jfkSticker,
  mv50,
  other,
  rateCard,
  registration,
  registrationConfirm,
  repairImage,
  stateInspection,
  title,
  tollPass,
  wavConversionLetter,
} from './folders';

const {folders, flatTree, foldersObject, uiDocs} = createFolderTree([
  billOfSale(),
  coi(),
  dec(),
  fh1(),
  jfkSticker(),
  mv50(),
  other(),
  repairImage(),
  rateCard(),
  registration(),
  registrationConfirm(),
  stateInspection(),
  title(),
  tollPass(),
  wavConversionLetter(),
]);

export {foldersObject, flatTree, uiDocs};

export default folders;
