import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {hide as hideAct} from './redux/actions';

// Attributes
import reasonAttr from '../../attributes/reason.attribute.vehicle';

// Api
import unarchiveApi from '../../api/unarchive.api.vehicle';

// Components
import EnableVehicleModal from '../../components/EnableVehicleModal/EnableVehicleModal';

// Lib
import {lib} from '@matthahn/sally-ui';
import parseError from '../../../error/parseError';

// Permissions
import archivePermission from '../../permissions/archive.permission.vehicle';

// Preps
import unarchivePrep from '../../preparation/unarchive.preparation.vehicle';

// Alert
const {alert} = lib;

class EnableVehicleContainer extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    vehicle: PropTypes.object,
  };

  static STATE = {
    loading: false,
    reason: reasonAttr(''),
  };

  state = {...this.constructor.STATE};

  componentDidMount() {
    if (this.props.visible) this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.visible && this.props.visible) this.init();
  }

  init = () => {
    this.setState({...this.constructor.STATE});
  };

  onClose = () => {
    const {dispatch} = this.props;
    const {loading} = this.state;
    if (loading) return;
    dispatch(hideAct());
  };

  onChange = (val, key) => {
    if (this.state.loading) return;
    this.setState({[key]: val});
  };

  save = async () => {
    const {dispatch, visible, vehicle} = this.props;
    const {loading, ...attributes} = this.state;

    if (loading || !visible || !vehicle) return;

    if (!archivePermission())
      return alert.warning('You do not have permission to unarchive vehicles');

    this.setState({loading: true});

    try {
      const {reason} = await unarchivePrep(attributes);
      await unarchiveApi(vehicle.id, reason);
      alert.success('Vehicle unarchived');
      dispatch(hideAct());
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
    }

    this.setState({loading: false});
  };

  render() {
    const {visible} = this.props;
    const {loading, reason} = this.state;
    return (
      <EnableVehicleModal
        visible={visible}
        loading={loading}
        reason={reason}
        onClose={this.onClose}
        onChange={this.onChange}
        onSave={this.save}
      />
    );
  }
}

export default connect((state) => ({...state.enableVehicle}))(
  EnableVehicleContainer
);
