import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

// Events
import event from '../events/approved.event.cheque';

export default async (cheques, jwt = null) => {
  const api = f3tch('/payments/approve_checks/').profile(profile());

  if (!!jwt) api.headers({Authorization: `JWT ${jwt}`});

  const response = await api.body(cheques).post();
  event.pub(response);
  return response;
};
