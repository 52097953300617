import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import {Button} from '@matthahn/sally-ui';

// LibBu
import {lib} from '@matthahn/sally-ui';

// Components
import {Heading} from '@matthahn/sally-ui';

// Colors
const {
  colors: {grey},
} = lib;

// Helper Components
const Container = styled.div`
  width: 100%;
  padding: 20px 10px 15px 10px;
  border-bottom: 1px solid ${grey};
  display: flex;
  justify-content: space-between;
`;

const Header = ({children, onSearch, onNew}) => (
  <Container>
    <Heading bold>{children}</Heading>
    {!!onNew && (
      <Button size="small" theme="grey" icon="plus" onClick={onNew}>
        Create ticket
      </Button>
    )}
    {!!onSearch && (
      <Button size="small" theme="grey" icon="search1" onClick={onSearch}>
        Global Search
      </Button>
    )}
  </Container>
);

Header.propTypes = {
  children: PropTypes.node,
  onSearch: PropTypes.func,
  onNew: PropTypes.func,
};

export default Header;
