import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {hide as hideAct} from './redux/actions';

// Api
import listDocumentsApi from '../../api/list.api.document';
import uploadDocumentApi from '../../api/upload.api.document';
import getContractsApi from '../../api/getContracts.api.document';
import getContractByVersionApi from '../../api/getContract.api.document';
import convertToPDFApi from '../../../pdf/api/convert.api.pdf';
import listLeasesApi from '../../../lease/api/list.api.lease';

// Attributes
import effectiveDateAttr from '../../attributes/effective_date.attribute.document';

// Components
import Contract from '../../components/Contract/Contract';

// Docs
import {foldersObject as driverDocuments} from '../../../driver/documents';

// Lib
import {lib} from '@matthahn/sally-ui';
import parseError from '../../../error/parseError';
import availableContracts from '../../lib/availableContracts.lib.document';
import blobToFile from '../../../lib/blobToFile';

// Helpers
const {leaseContract: contractFile} = driverDocuments;
const {alert} = lib;

class ContractContainer extends Component {
  static propTypes = {
    externalDevicesVisible: PropTypes.bool,
    visible: PropTypes.bool,
    driver: PropTypes.object,
    dispatch: PropTypes.func,
  };

  static STATE = {
    init: false,
    loading: false,
    lease: null,
    view: 'preview',
    file: null,
    duplicates: [],
    noOptions: false,
    contracts: [],
    selectedContract: null,
  };

  state = {...this.constructor.STATE};

  componentDidMount() {
    if (this.props.init) this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.visible && this.props.visible) this.init();
  }

  init = async () => {
    const {driver, dispatch} = this.props;
    this.setState({...this.constructor.STATE, loading: true});

    try {
      const [
        {results: files},
        {results: allContracts},
        {results: leases},
      ] = await Promise.all([
        listDocumentsApi({
          driver: driver.id,
          type: contractFile.type,
          ordering: '-id',
        }),
        getContractsApi(),
        await listLeasesApi({
          driver: driver.id,
          ordering: '-created_at',
        }),
      ]);

      const contracts = availableContracts(allContracts);
      const lease = [...leases].find((l) => l.driver === driver.id);

      this.setState({
        lease,
        contracts,
        init: true,
        loading: false,
        file: !!files.length ? {...files[0]} : null,
        duplicates: [...files],
        view: !!files.length ? 'preview' : 'generate',
      });
    } catch (error) {
      alert.error('Something is wrong. Please contact a developer.');
      this.setState({loading: false});
      dispatch(hideAct());
    }
  };

  onChange = async (value, key) => {
    const {driver} = this.props;
    if (this.state.loading) return;
    const state = {[key]: value};

    try {
      await contractFile.preSaveValidation({driver});
      this.setState(state);
    } catch (error) {
      const {message} = parseError(error);
      alert.warning(message);
    }
  };

  onLoad = (loading) => this.setState({loading});

  onFileUploadDone = () => {
    this.props.dispatch(hideAct());
  };

  onView = (view) => {
    if (this.state.loading) return;
    this.setState({view, selectedContract: null});
  };

  onExec = async (html) => {
    const {driver, dispatch} = this.props;
    const {loading, duplicates, selectedContract, lease} = this.state;

    if (loading || !selectedContract) return;

    const data = {
      effective_date: effectiveDateAttr(
        effectiveDateAttr(new Date()).api.format()
      ),
    };

    const extraData = {
      version_major: selectedContract.version_major,
      version_minor: selectedContract.version_minor,
      version_revision: selectedContract.version_revision,
    };

    const rawDocumentData = !!selectedContract?.document?.extra_data
      ? {...selectedContract.document.extra_data, ...extraData}
      : {...extraData};

    this.setState({loading: true});

    try {
      const blob = await convertToPDFApi(html);
      const doc = blobToFile(blob);
      await uploadDocumentApi({
        doc,
        duplicates,
        driver,
        lease,
        data,
        rawDocumentData,
        file: contractFile,
        type: 'driver',
      });
      alert.success('Contract generated successfully');
      this.setState({loading: false});
      dispatch(hideAct());
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({loading: false});
    }
  };

  onContract = async (version) => {
    const {driver} = this.props;
    const {loading, lease} = this.state;
    const contract = [...this.state.contracts].find(
      (contract) => contract.version === version
    );

    if (loading || !contract) return;

    this.setState({loading: true});

    try {
      const html = await getContractByVersionApi({
        version: `${contract.version_major}.${contract.version_minor}.${contract.version_revision}`,
        leaseID: lease.id,
        driverID: driver.id,
      });
      this.setState({
        selectedContract: {...contract, parsedHTML: `${html}`},
        loading: false,
      });
    } catch (error) {
      this.setState({loading: false});
    }
  };

  undoVersion = () => {
    if (this.state.loading) return;
    this.setState({selectedContract: null});
  };

  onError = (error) => {
    const {message} = parseError(error);
    alert.error(message);
  };

  onClose = () => {
    if (this.state.loading) return;
    this.props.dispatch(hideAct());
  };

  render() {
    const {visible, externalDevicesVisible, driver} = this.props;

    const {
      init,
      loading,
      file,
      view,
      duplicates,
      contracts,
      selectedContract,
    } = this.state;

    return (
      <Contract
        init={init}
        externalDevicesVisible={externalDevicesVisible}
        visible={visible}
        loading={loading}
        view={view}
        driver={driver}
        file={file}
        duplicates={duplicates}
        folder={contractFile}
        contracts={contracts}
        contract={!!selectedContract ? selectedContract.parsedHTML : null}
        onFileUploadDone={this.onFileUploadDone}
        onView={this.onView}
        onChange={this.onChange}
        onClose={this.onClose}
        onLoad={this.onLoad}
        onExec={this.onExec}
        onError={this.onError}
        onContract={this.onContract}
        undoVersion={this.undoVersion}
      />
    );
  }
}

export default connect((state) => ({
  ...state.contract,
  externalDevicesVisible: state.externalDevice.visible,
}))(ContractContainer);
