import React from 'react';

// Components
import SubPage from '../../../layout/components/SubPage/SubPage';

// Containers
import DriverTicketsContainer from '../../containers/DriverTicketsContainer/DriverTicketsContainer';

const DriverTicketsPage = () => (
  <SubPage>
    <DriverTicketsContainer />
  </SubPage>
);

export default DriverTicketsPage;
