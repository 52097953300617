import {date, fileName, folder} from '../../../document';

// Attributes
import rateCardRetirementDateAttr from '../../attributes/rate_card_retirement_date.attribute.vehicle';
import mileRunDateAttr from '../../attributes/mile_run_date.attribute.vehicle';
import rateCardInspectionDateAttr from '../../attributes/rate_card_inspection_date.attribute.vehicle';
import vinLastSevenAttr from '../../attributes/vinLastSeven.attribute.vehicle';

// Lib
import vinValidation from '../../libs/vinValidation.lib.vehicle';

// Errors
import vinMissmatch from '../../errors/vinMissmatch.error.vehicle';

export default folder({
  attributes: [
    vinLastSevenAttr,
    rateCardRetirementDateAttr,
    mileRunDateAttr,
    rateCardInspectionDateAttr,
  ],
  fileAttributes: [
    'vinLastSeven',
    'rate_card_retirement_date',
    'mile_run_date',
    'rate_card_inspection_date',
  ],
  defaultFileName: 'Rate Card',
  // RATE CARD - VIN - UPLOAD DATE
  fileName: fileName(({vehicle}) => `RATE CARD - ${vehicle.vin} - ${date()}`),
  folderName: 'Rate Cards',
  preSaveValidation: ({vehicle, vinLastSeven}) => {
    if (vinValidation(vehicle, vinLastSeven.api.format()))
      throw vinMissmatch({
        message: 'Last 7 VIN characters are incorrect',
      });
  },
  type: 'rateCard',
});
