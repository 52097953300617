import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Api
import listDocumentsApi from '../../../document/api/list.api.document';

// Actions
import {updateDriver as updateDriverAct} from '../../redux/actions';

// Components
import {FilePreview} from '@matthahn/sally-ui';
import AttributeFileUploader from '../../../layout/components/AttributeFileUploader/AttributeFileUploader';
import DriverDmvCard from '../../components/DriverDmvCard/DriverDmvCard';

// Files
import {foldersObject} from '../../documents';

// UI
const {dmv: dmvFile} = foldersObject;

class DriverDmvContainer extends Component {
  static propTypes = {
    driver: PropTypes.object,
    loading: PropTypes.bool,
    date_of_birth: PropTypes.object,
    dmv_license_number: PropTypes.object,
    dmv_license_state: PropTypes.object,
    dmv_license_expiry: PropTypes.object,
    dmv_license_issue_date: PropTypes.object,
    onAttributeRefresh: PropTypes.func,
    dispatch: PropTypes.func,
  };

  state = {
    doc: null,
    docFetching: true,
    loading: false,
    showUploader: false,
    preview: false,
  };

  componentDidMount() {
    this.getDocument();
  }

  set = (driver) => this.props.dispatch(updateDriverAct(driver));

  isLoading = () => this.state.loading;

  showUploader = () => {
    if (this.isLoading()) return;
    this.setState({showUploader: true});
  };

  hideUploader = () => {
    if (this.isLoading() || !this.state.showUploader) return;
    this.setState({showUploader: false});
  };

  onDone = ({object, files: [file]}) => {
    this.set(object);
    this.props.onAttributeRefresh(dmvFile.attributesList);
    this.setState({showUploader: false, doc: file});
  };

  getDocument = async () => {
    try {
      const {results} = await listDocumentsApi({
        type: dmvFile.type,
        ordering: '-created_at',
        driver: this.props.driver.id,
      });
      const doc = results.length > 0 ? results[0] : null;
      this.setState({doc, docFetching: false});
    } catch (error) {
      this.setState({docFetching: false});
    }
  };

  openPreview = () => {
    const {doc} = this.state;
    if (!doc) return;
    this.setState({preview: true});
  };

  closePreview = () => {
    this.setState({preview: false});
  };

  render() {
    const {showUploader, doc, preview} = this.state;
    const {driver, ...props} = this.props;
    return (
      <Fragment>
        {doc !== null && (
          <FilePreview
            files={[{...doc, url: doc.document_file}]}
            visible={preview}
            onClose={this.closePreview}
          />
        )}
        <DriverDmvCard
          {...props}
          onShowUploader={this.showUploader}
          onOpen={this.openPreview}
          file={doc}
        />
        <AttributeFileUploader
          driver={driver}
          title="DMV"
          type="driver"
          folderType={dmvFile.type}
          visible={showUploader}
          onDone={this.onDone}
          onClose={this.hideUploader}
        />
      </Fragment>
    );
  }
}

export default connect()(DriverDmvContainer);
