import styled from 'styled-components';

// colors
import {darkGrey, black} from '@matthahn/sally-ui/lib/libs/colors';

const Label = styled.div`
  color: ${({light}) => (light ? black : darkGrey)};
  font-size: 10px;
  text-transform: uppercase;
  transform: translateY(-1px);
`;

export default Label;
