import isNumber from 'is-number';

import type from '../type';

const format = (value) =>
  !!value ? `${value}`.replace(/[^0-9]/g, '').slice(0, 2) : '';

const validate = (value) =>
  isNumber(value) && /^\d*$/.test(value) && `${value}`.length === 2;

export default type({type: 'string', format, validate});
