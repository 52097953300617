// Lib
import isTripTransaction from './isTripTransaction.lib.transaction';

// Types
import {dateTime} from '../../types';

export default (transactions) =>
  [...transactions].map((transaction) => {
    const income = transaction.type !== 'charge';
    const isTrip = isTripTransaction(transaction);
    return {
      ...transaction,
      createdAt: dateTime(transaction.created_at).format(),
      postedAt: dateTime(transaction.posted_at).format(),
      computedType: isTrip
        ? 'Trip Net'
        : `${transaction.type} - ${transaction.subtype}`,
      computedDescription: isTrip
        ? `Trip #${transaction.trip.id}`
        : transaction.description,
      income,
    };
  });
