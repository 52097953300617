// date
import {parseISO, isDate, isAfter} from 'date-fns';

const isAfterETC = ({date = new Date(), etc = new Date()}) =>
  !date ||
  !etc ||
  isAfter(
    isDate(date) ? date : parseISO(date),
    isDate(etc) ? etc : parseISO(etc)
  );

export default isAfterETC;
