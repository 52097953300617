import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

export default (query = {}) =>
  f3tch('/drivers/contract_signing_requests/')
    .profile(profile())
    .query(query)
    .get();
