export default [
  {
    key: 'summons_number',
    label: 'Summons Number',
  },
  {
    key: 'due_datetime',
    label: 'Due Date',
  },
  {
    key: 'violation',
    label: 'Violation',
    span: 2,
  },
  {
    key: 'medallion',
    label: 'Medallion',
  },
  {
    key: 'fine_amount',
    label: 'Amount',
  },
];
