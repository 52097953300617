import isObject from 'is-object';

import transactionKey from './transactionKey.type.transaction';

export default ({
  type = null,
  subtype = null,
  owed_to_sally = true,
  exclude_from_payout = false,
  deduct_balance_from = null,
  exclude_from_deposit_payout = false,
} = {}) => {
  const actualType = isObject(type) ? type.key : type;
  const actualSubType = isObject(subtype) ? subtype.key : subtype;
  const actualTypeLabel = isObject(type) ? type.label : type;
  const actualSubTypeLabel = isObject(subtype) ? subtype.label : subtype;
  return {
    key: transactionKey({type: type.key, subtype: subtype.key}),
    type: actualType,
    subtype: actualSubType,
    label:
      actualSubType === 'earning' || actualType === 'deposit'
        ? actualTypeLabel
        : actualSubTypeLabel,
    transactions: [],
    balance: 0,
    owed_to_sally,
    exclude_from_payout,
    deduct_balance_from,
    exclude_from_deposit_payout,
  };
};
