import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal, Row, Column, Text} from '@matthahn/sally-ui';
import TripPaymentType from '../../../trip/components/TripPaymentType/TripPaymentType';

// Types
import {dateTime, amount} from '../../../types';

const TripPreviewModal = ({visible, trip, onClose}) => (
  <Modal title="Trip Preview" icon="earth" visible={visible} onClose={onClose}>
    {(Content) =>
      !trip ? null : (
        <Content>
          <Row margin>
            <Column size={2 / 3}>
              <Text value={trip.start_address}>Start Address</Text>
            </Column>
            <Column size={1 / 3}>
              <Text value={dateTime(trip.start_datetime).format()}>
                Start Time
              </Text>
            </Column>
          </Row>
          <Row margin>
            <Column size={2 / 3}>
              <Text value={trip.end_address}>End Address</Text>
            </Column>
            <Column size={1 / 3}>
              <Text value={dateTime(trip.end_datetime).format()}>End Time</Text>
            </Column>
          </Row>
          <Row margin>
            <Column>
              <Text value={`${amount(trip.distance_served).format()} miles`}>
                Distance
              </Text>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 3}>
              <Text value={trip.num_service}>Service number</Text>
            </Column>
            <Column size={1 / 3}>
              <Text value={trip.passenger_number}>Number of Passengers</Text>
            </Column>
            <Column size={1 / 3}>
              <Text value={<TripPaymentType type={trip.type} />}>
                Payment type
              </Text>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 3}>
              <Text value={`$${amount(trip.trip_amount).format()}`}>
                Trip Amount
              </Text>
            </Column>
            <Column size={1 / 3}>
              <Text value={`$${amount(trip.tip_amount).format()}`}>
                Tip Amount
              </Text>
            </Column>
            <Column size={1 / 3}>
              <Text value={`$${amount(trip.extras).format()}`}>Extras</Text>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 3}>
              <Text value={`$${amount(trip.tolls).format()}`}>Tolls</Text>
            </Column>
            <Column size={1 / 3}>
              <Text value={`$${amount(trip.tax).format()}`}>Tax</Text>
            </Column>
            <Column size={1 / 3}>
              <Text value={`$${amount(trip.imp_tax).format()}`}>Imp Tax</Text>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 3}>
              <Text value={`$${amount(trip.ehail_fee).format()}`}>
                Ehail Fee
              </Text>
            </Column>
            <Column size={1 / 3}>
              <Text value={`$${amount(trip.healthfee).format()}`}>
                Health Fee
              </Text>
            </Column>
            <Column size={1 / 3}>
              <Text value={`$${amount(trip.congestion_fee).format()}`}>
                Congestion Fee
              </Text>
            </Column>
          </Row>
          <Row>
            <Column>
              <Text value={`$${amount(trip.total_amount).format()}`}>
                Total Amount
              </Text>
            </Column>
          </Row>
        </Content>
      )
    }
  </Modal>
);

TripPreviewModal.propTypes = {
  visible: PropTypes.bool,
  trip: PropTypes.object,
  onClose: PropTypes.func,
};

export default TripPreviewModal;
