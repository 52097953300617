import semver from 'semver';

export default (contracts = []) => {
  const latestVerstion = [...contracts].reduce(
    (version, {version_major}) =>
      version_major > version ? version_major : version,
    0
  );

  return Object.values(
    [...contracts]
      .filter(({version_major}) => version_major === latestVerstion)
      .reduce((combined, contract) => {
        const latest = !combined[contract.version_minor]
          ? null
          : combined[contract.version_minor];
        const latestVersion = !!latest
          ? `${latest.version_major}.${latest.version_minor}.${latest.version_revision}`
          : '';
        const currentVersion = `${contract.version_major}.${contract.version_minor}.${contract.version_revision}`;

        return {
          ...combined,
          [contract.version_minor]:
            !latest || semver.gt(currentVersion, latestVersion)
              ? contract
              : latest,
        };
      }, {})
  ).sort((a, b) => {
    const versionA = `${a.version_major}.${a.version_minor}.${a.version_revision}`;
    const versionB = `${b.version_major}.${b.version_minor}.${b.version_revision}`;
    return semver.gt(versionA, versionB) ? -1 : 1;
  });
};
