import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

export default attribute({
  type: TEXT,
  attribute: 'state',
  label: {
    default: 'Customer Status',
    short: 'Status',
  },
});
