import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// Api
import api from '@matthahn/sally-fw/lib/api/lib/getEverything.lib.api';
import listRentalsApi from '../../../rental/api/list.api.rental';

// Components
import SelectRentalStep from '../../components/SelectRentalStep/SelectRentalStep';

class SelectRentalStepContainer extends Component {
  static propTypes = {
    Container: PropTypes.any,
    visible: PropTypes.bool,
    driver: PropTypes.object,
    rental: PropTypes.object,
    goingBack: PropTypes.bool,
    onShow: PropTypes.func,
    onHide: PropTypes.func,
    onDisabled: PropTypes.func,
    onClose: PropTypes.func,
    onNext: PropTypes.func,
    onBack: PropTypes.func,
  };

  state = {
    loading: true,
    rentals: [],
  };

  componentDidMount() {
    this.init();
  }

  init = async () => {
    const {driver, onDisabled, onBack} = this.props;

    onDisabled(true);
    this.setState({loading: true});

    try {
      const {data: rentals} = await api(listRentalsApi, {
        driver: driver.id,
        ordering: '-start_date',
      });
      this.setState({loading: false, rentals});
      onDisabled(false);
    } catch (error) {
      onDisabled(false);
      alert.warning('Could not load rentals. Try again.');
      onBack();
    }
  };

  onRental = (rental) => () => this.selectRental(rental);

  selectRental = (rental) => {
    if (!rental) return alert.warning('Select a rental');
    this.props.onNext({rental});
  };

  next = () => {
    const {rental} = this.props;
    this.selectRental(rental);
  };

  render() {
    const {Container, rental, onBack} = this.props;
    const {loading, rentals} = this.state;
    return (
      <SelectRentalStep
        Container={Container}
        loading={loading}
        rental={rental}
        rentals={rentals}
        onRental={this.onRental}
        onNext={this.next}
        onBack={onBack}
      />
    );
  }
}

export default SelectRentalStepContainer;
