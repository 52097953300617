import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

export default (token) =>
  f3tch('/authentication/token-refresh/')
    .profile(profile({authorize: false}))
    .body({token})
    .post();
