// Types
import {apiDateTime} from '../../types';

const incomeAllocationTransaction = (transaction) => ({
  ...transaction,
  id: 'incomeAllocation',
  posted_at: apiDateTime(new Date()).format(),
});

export default incomeAllocationTransaction;
