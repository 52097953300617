import styled from 'styled-components';

const Center = styled.div`
  width: 100%;
  height: 49px;
  display: flex;
  align-items: center;
`;

export default Center;
