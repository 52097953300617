export default [
  {
    key: 'type',
    label: 'Type',
  },
  {
    key: 'payment',
    label: 'Payment',
  },
];
