import {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

// Api
import createMedallionApi from '../../api/create.api.medallion';

// Libs
import {lib} from '@matthahn/sally-ui';
import parseError from '../../../error/parseError';
import {
  setUnsavedChanges,
  clearUnsavedChanges,
} from '../../../layout/lib/unsavedChanges.lib.layout';

// Preparation
import createPreparation from '../../preparation/create.preparation.medallion';

// Routes
import medallionRoute from '../../pages/MedallionPage/route';

// Attributes
import medallion_number from '../../attributes/medallion_number.attribute.medallion';

// Notifications
const {alert} = lib;

class NewMedallionContainer extends Component {
  static propTypes = {
    lastUpdate: PropTypes.any,
    history: PropTypes.object,
    children: PropTypes.func.isRequired,
    onLoad: PropTypes.func,
  };

  static defaultProps = {
    onLoad: () => {},
  };

  static INITIAL_STATE = () => ({
    loading: false,
    errors: [],

    medallion_number: medallion_number(''),
  });

  state = {
    ...this.constructor.INITIAL_STATE(),
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.lastUpdate !== this.props.lastUpdate) this.init();
  }

  init = () => {
    this.setState({...this.constructor.INITIAL_STATE()});
  };

  onChange = setUnsavedChanges(
    (value, key, errors) => this.setState({[key]: value, errors}),
    () => this.state.loading
  );

  onCreate = async () => {
    const {history, onLoad} = this.props;
    const {visible, loading, errors, ...props} = this.state;

    if (loading) return;

    const attributes = {...props};

    this.setState({loading: true});
    onLoad(true);

    try {
      const medallion = await createPreparation(attributes);
      const response = await createMedallionApi(medallion);
      clearUnsavedChanges();
      history.push(medallionRoute(response.id));
    } catch (error) {
      const {fields, message} = parseError(error);
      alert.error(message);
      this.setState({loading: false, errors: fields});
      onLoad(false);
    }
  };

  render() {
    const {children} = this.props;
    return children({
      ...this.state,
      onChange: this.onChange,
      onCreate: this.onCreate,
    });
  }
}

export default withRouter(NewMedallionContainer);
