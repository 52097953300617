import {DATE} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {apiDate, date} from '../../types';

export default attribute({
  type: DATE,
  attribute: 'fhv_license_expiry',
  display: date,
  input: date,
  api: apiDate,
  label: {
    default: "Driver's FHV License Expiry",
    short: 'Expiry',
    alternative: 'FHV License Expiry',
  },
  disabledDates: [{before: new Date()}],
});
