import {SET} from './constants';

// Sync
import sync from './sync';
import syncObjectByModificationDate from '../../sync/lib/syncObjectByModificationDate';

export const set = (data) => ({type: SET, data});

export const syncData = (newData) =>
  sync((dispatch, getState) => {
    const oldData = getState().medallionOwner.list;
    const oldObject = getState().medallionOwner.object;
    const list = syncObjectByModificationDate(oldData, newData);
    const object = !!oldObject
      ? [...list].find(({id}) => id === oldObject.id) || null
      : null;
    dispatch({
      type: SET,
      data: {list, object},
    });
  });

export const removeData = (id) =>
  sync((dispatch, getState) => {
    const oldList = getState().medallionOwner.list;
    const list = [...oldList].filter((obj) => obj.id !== id);
    dispatch({type: SET, data: {list}});
  });

export const startLoading = () => ({type: SET, data: {loading: true}});

export const stopLoading = () => ({type: SET, data: {loading: false}});
