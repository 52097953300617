import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {Card, P, Info} from '@matthahn/sally-ui';

import Row from './components/Row';

const DriverAlerts = ({alerts, headless, resolving, onResolve}) => {
  const content = (
    <Fragment>
      {alerts.map((alert, index) => (
        <Row key={alert.id} margin={!!index}>
          <Info
            type={alert.error ? 'error' : 'warning'}
            action={{
              label: alert.actionLabel,
              onClick: alert.onAction,
            }}
            loading={alert.resolving}
            flat
          >
            {alert.message}
          </Info>
        </Row>
      ))}
    </Fragment>
  );
  return headless ? (
    content
  ) : (
    <Card title="Alerts" icon="exclamation">
      {(Content) =>
        !alerts.length ? (
          <Content>
            <P theme="darkGrey">No alerts</P>
          </Content>
        ) : (
          <Content padding="none">{content}</Content>
        )
      }
    </Card>
  );
};

DriverAlerts.propTypes = {
  alerts: PropTypes.array,
  resolving: PropTypes.array,
  onCreate: PropTypes.func,
  onResolve: PropTypes.func,
};

export default DriverAlerts;
