import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {set as setAct} from '../../redux/actions';

// Api
import listTicketsApi from '../../api/list.api.ticket';

// Lib
import wait from '../../../lib/wait';
import eventRace from '../../../events/race.event';
import shouldRemoveFromAssignList from '../../lib/shouldRemoveFromAssignList.lib.ticket';

// Query
import {isNull} from '../../../api/queries/queries';

// Sockets
import createdSocket from '../../socket/created.socket.ticket';
import removedSocket from '../../socket/removed.socket.ticket';
import updatedSocket from '../../socket/updated.socket.ticket';

// Statuses
import needsActionStatus from '../../statuses/needs_action.status.ticket';

class NumberOfTicketsSync extends Component {
  static propTypes = {
    numberOfTickets: PropTypes.number,
    children: PropTypes.node,
    dispatch: PropTypes.func,
  };

  static defualtProps = {
    children: null,
  };

  componentDidMount() {
    this.init();
    this.subscribers = [
      createdSocket.subscribe(this.ticketCreated),
      removedSocket.subscribe(this.ticketUpdated),
      updatedSocket.subscribe(this.ticketUpdated),
    ];
  }

  componentWillUnmount() {
    this.subscribers.forEach((unsub) => unsub());
  }

  subscribers = [];

  init = () => {
    this.getNumberOfTickets();
  };

  ticketCreated = () => {
    const {dispatch, numberOfTickets} = this.props;
    dispatch(setAct({numberOfTickets: numberOfTickets + 1}));
  };

  ticketUpdated = eventRace((ticket) => {
    const {dispatch, numberOfTickets} = this.props;
    if (!shouldRemoveFromAssignList(ticket)) return;
    const newNumberOfTickets = numberOfTickets > 0 ? numberOfTickets - 1 : 0;
    dispatch(setAct({numberOfTickets: newNumberOfTickets}));
  });

  getNumberOfTickets = async () => {
    const {dispatch} = this.props;
    try {
      const {count} = await listTicketsApi({
        [isNull('rental')]: true,
        removed: false,
        limit: 1,
        offset: 0,
        fields: 'id',
        status: needsActionStatus.key,
      });
      dispatch(setAct({numberOfTickets: count}));
    } catch (error) {
      // DO NOTHING
    }

    await wait(60 * 1000);
    this.getNumberOfTickets();
  };

  render() {
    const {children} = this.props;
    return children || null;
  }
}

export default connect((state) => ({
  numberOfTickets: state.ticket.numberOfTickets,
}))(NumberOfTicketsSync);
