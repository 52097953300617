import React from 'react';
import {Switch, Route} from 'react-router-dom';

// VehiclesList
import VehiclesListPage from '../../pages/VehiclesListPage/VehiclesListPage';
import routeVehiclesList from '../../pages/VehiclesListPage/route';

// Vehicle
import VehiclePage from '../../pages/VehiclePage/VehiclePage';
import routeVehicle from '../../pages/VehiclePage/route';

const VehiclesPagesContainer = () => (
  <Switch>
    <Route exact path={routeVehiclesList()} component={VehiclesListPage} />
    <Route path={routeVehicle()} component={VehiclePage} />
  </Switch>
);

export default VehiclesPagesContainer;
