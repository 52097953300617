// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// api
import api from '../../../api/lib/getEverythingFromApi.lib.api';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// insurance api
import applyVehiclesApi from '../../api/applyVehicles.api.insurance';
import deleteInsuranceApi from '../../api/delete.api.insurance';
import getInsuranceByIdApi from '../../api/getById.api.insurance';
import updateInsuranceApi from '../../api/update.api.insurance';

// insurance attributes
import accidentPhoneNumberAttribute from '../../attributes/accident_phone_number.attribute.insurance';
import insuranceCompanyNameAttribute from '../../attributes/insurance_company_name.attribute.insurance';
import insuranceCompanyNumberAttribute from '../../attributes/insurance_company_number.attribute.insurance';
import policyEffectiveDateAttribute from '../../attributes/policy_effective_date.attribute.insurance';
import policyExpirationDateAttribute from '../../attributes/policy_expiration_date.attribute.insurance';
import policyNumberAttribute from '../../attributes/policy_number.attribute.insurance';

// insurance components
import InsuranceEditModal from '../../components/InsuranceEditModal/InsuranceEditModal';

// insurance events
import showInsuranceEditModalEvent from '../../events/showEditModal.event.insurance';

// insurance preparations
import updateInsurancePreparation from '../../preparations/update.preparation.insurance';

// notify
import notify from '@matthahn/sally-ui/lib/libs/notify';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// vehicle api
import listVehiclesApi from '../../../vehicle/api/list.api.vehicle';

// vehicle events
import selectedVehicleEvent from '../../../vehicle/events/selected.event.vehicle';
import showVehicleSelectModalEvent from '../../../vehicle/events/showSelectModal.event.vehicle';

class EditInsuranceContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
  };

  static DEFAULT_STATE = {
    // ui state
    deleting: false,
    insurance: null,
    loading: false,
    saving: false,
    visible: false,
    vehicles: [],

    // attributes
    accident_phone_number: accidentPhoneNumberAttribute(''),
    insurance_company_name: insuranceCompanyNameAttribute(''),
    insurance_company_number: insuranceCompanyNumberAttribute(''),
    policy_effective_date: policyEffectiveDateAttribute(''),
    policy_expiration_date: policyExpirationDateAttribute(''),
    policy_number: policyNumberAttribute(''),
  };

  state = {
    ...this.constructor.DEFAULT_STATE,
  };

  componentDidMount() {
    this.props.subscribe(
      showInsuranceEditModalEvent.subscribe(this.show),
      selectedVehicleEvent.sub(this.attachVehicles)
    );
  }

  show = async (insuranceId) => {
    this.setState({
      ...this.constructor.DEFAULT_STATE,
      visible: true,
      loading: true,
    });

    try {
      const [insurance, {data: vehicles}] = await Promise.all([
        getInsuranceByIdApi(insuranceId),
        api(listVehiclesApi, {insurance: insuranceId}),
      ]);
      this.setState({
        insurance,
        loading: false,
        vehicles,

        accident_phone_number: accidentPhoneNumberAttribute(
          insurance?.accident_phone_number || ''
        ),
        insurance_company_name: insuranceCompanyNameAttribute(
          insurance?.insurance_company_name || ''
        ),
        insurance_company_number: insuranceCompanyNumberAttribute(
          insurance?.insurance_company_number || ''
        ),
        policy_effective_date: policyEffectiveDateAttribute(
          insurance?.policy_effective_date || ''
        ),
        policy_expiration_date: policyExpirationDateAttribute(
          insurance?.policy_expiration_date || ''
        ),
        policy_number: policyNumberAttribute(insurance?.policy_number || ''),
      });
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({loading: false, visible: false});
    }
  };

  hide = () => {
    const {deleting, loading, saving} = this.state;
    if (deleting || loading || saving) return;
    this.setState({visible: false});
  };

  change = (value, key) => {
    const {deleting, loading, saving} = this.state;
    if (deleting || loading || saving) return;
    this.setState({[key]: value});
  };

  save = async () => {
    const {deleting, loading, saving, visible, insurance, ...attributes} =
      this.state;

    if (deleting || loading || saving || !visible) return;

    this.setState({saving: true});

    try {
      const preparedInsurance = await updateInsurancePreparation(attributes);
      await updateInsuranceApi(insurance.id, preparedInsurance);
      alert.success(`Insurance ${insurance.policy_number} updated`);
      this.setState({saving: false, visible: false});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({saving: false});
    }
  };

  remove = async ({prompt = true} = {}) => {
    const {deleting, loading, insurance, saving} = this.state;

    if (deleting || loading || !insurance || saving) return;

    if (prompt)
      return notify({
        title: 'Warning',
        content: 'Are you sure you want to remove this policy?',
        primary: {
          label: 'No',
          onClick: () => null,
        },
        secondary: {
          label: 'Yes',
          onClick: () => this.remove({prompt: false}),
        },
      });

    this.setState({deleting: true});

    try {
      await deleteInsuranceApi(insurance.id);
      alert.success(`Insurance ${insurance.policy_number} deleted`);
      this.setState({deleting: false, visible: false});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({deleting: false});
    }
  };

  attachVehicles = async ({vehicles}) => {
    const {
      deleting,
      loading,
      insurance,
      saving,
      vehicles: insuranceVehicles,
    } = this.state;
    const vehiclesToApply = [...vehicles].filter(
      ({id: vehicleId}) => !insuranceVehicles.find(({id}) => id === vehicleId)
    );
    const vehicleIds = [...vehiclesToApply].map(({id}) => id);

    if (!vehicleIds || deleting || loading || saving) return;

    this.setState({loading: true});

    try {
      await applyVehiclesApi(insurance.id, vehicleIds);
      this.setState({
        loading: false,
        vehicles: [...insuranceVehicles, ...vehiclesToApply],
      });
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({loading: false});
    }
  };

  showSelectVehiclesModal = () => {
    const {deleting, loading, saving} = this.state;
    if (deleting || loading || saving) return;
    showVehicleSelectModalEvent.pub({multiple: true});
  };

  render() {
    const {
      deleting,
      loading,
      insurance,
      saving,
      vehicles,
      visible,
      ...attributes
    } = this.state;
    return (
      <InsuranceEditModal
        {...attributes}
        deleting={deleting}
        loading={loading}
        onAddVehicles={this.showSelectVehiclesModal}
        onChange={this.change}
        onClose={this.hide}
        onDelete={this.remove}
        onSave={this.save}
        saving={saving}
        title="Edit Insurance"
        vehicles={vehicles}
        visible={visible}
      />
    );
  }
}

export default subscriptionHOC(EditInsuranceContainer);
