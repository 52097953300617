import {Component} from 'react';
import PropTypes from 'prop-types';

// actions
import {set as setAction} from '../../redux/actions';

// api
import api from '../../../api/lib/getEverythingFromApi.lib.api';
import listAchsApi from '../../api/list.api.ach';
import getBalanceApi from '../../../bankAccount/api/getBalance.api.bankAccount';

// event HOCs
import subscriptionHoc from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// lib
import {isNull} from '../../../api/queries/queries';

// redux
import {connect} from 'react-redux';

// sockets
import achApprovedSocket from '../../sockets/approved.socket.ach';
import achRejectedSocket from '../../sockets/canceled.socket.ach';

class AchsPendingApprovalSync extends Component {
  static propTypes = {
    children: PropTypes.node,
    dispatch: PropTypes.func,
    dwollaBalance: PropTypes.number,
    loadingPendingAchs: PropTypes.bool,
    pendingAchs: PropTypes.array,
    subscribe: PropTypes.func,
  };

  static defaultProps = {
    children: null,
  };

  componentDidMount() {
    this.subscribe();
    this.getAchs();
    this.getBalance();
  }

  subscribe = () => {
    this.props.subscribe(
      achApprovedSocket.subscribe(this.removeAchFromList),
      achRejectedSocket.subscribe(this.removeAchFromList)
    );
  };

  removeAchFromList = (ach) => {
    const {pendingAchs, dispatch} = this.props;
    dispatch(
      setAction({
        pendingAchs: [...pendingAchs].filter(({id}) => id !== ach.id),
      })
    );
  };

  getAchs = async () => {
    const {dispatch} = this.props;

    dispatch(setAction({loadingPendingAchs: true}));

    try {
      const {data: pendingAchs} = await api(listAchsApi, {
        [isNull('approved_by_user')]: true,
        [isNull('canceled_on')]: true,
        ordering: 'created_at',
      });
      dispatch(
        setAction({
          loadingPendingAchs: false,
          pendingAchs,
        })
      );
    } catch (error) {
      dispatch(setAction({loadingPendingAchs: false, pendingAchs: []}));
    }
  };

  getBalance = async () => {
    const {dispatch} = this.props;

    dispatch(
      setAction({
        loadingDwollaBalance: true,
        dwollaBalance: 0,
      })
    );

    try {
      const {dwolla_balance} = await getBalanceApi();
      dispatch(
        setAction({
          loadingDwollaBalance: false,
          dwollaBalance: dwolla_balance,
        })
      );
    } catch (error) {
      dispatch(setAction({loadingDwollaBalance: false}));
    }
  };

  render() {
    const {children} = this.props;
    return children || null;
  }
}

export default connect((state) => ({...state.ach}))(
  subscriptionHoc(AchsPendingApprovalSync)
);
