import styled, {css} from 'styled-components';

// Colors
import {white} from '@matthahn/sally-ui/lib/libs/colors';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  ${({fullScreen}) =>
    !fullScreen
      ? ''
      : css`
          width: 100vw;
          height: 100vh;
          position: fixed;
          top: 0px;
          left: 0px;
          z-index: 1000000000000000000;
          background: ${white};
        `}
`;

export default Wrapper;
