import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Api
import updateVehicleApi from '../../api/update.api.vehicle';

// Actions
import {show as showRemoveVehicleFromHoldAct} from '../../../vehicleHold/containers/ReleaseVehicleFromHoldContainer/redux/actions';

// Attributes
import plateAttr from '../../attributes/plate.attribute.vehicle';

// Components
import DispatchMissingVehicleInfoStep from '../../components/DispatchMissingVehicleInfoStep/DispatchMissingVehicleInfoStep';

// Error
import parseError from '../../../error/parseError';

// Lib
import {lib} from '@matthahn/sally-ui';
import isRentable from '../../../dispatch/lib/isRentable.lib.dispatch';
import isOnHold from '../../../vehicleHold/lib/isOnHold.lib.vehicleHold';

// Prep
import dispatchPrep from '../../preparation/dispatch.preparation.vehicle';

// Alert
const {alert} = lib;

class DispatchMissingVehicleInfoStepContainer extends Component {
  static propTypes = {
    Container: PropTypes.object,
    visible: PropTypes.bool,
    vehicle: PropTypes.object,
    onNext: PropTypes.func,
    onDisabled: PropTypes.func,
    onClose: PropTypes.func,
    dispatch: PropTypes.func,
  };

  state = {
    loading: false,
    plate: plateAttr(''),
  };

  componentDidMount() {
    if (this.props.visible) this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.visible && this.props.visible) this.init();
  }

  init = () => {
    const {vehicle, onNext} = this.props;
    if (isRentable(vehicle) && !!vehicle.plate) return onNext({vehicle});
    this.setState({
      loading: false,
      plate: plateAttr(''),
    });
  };

  onChange = (val, key) => {
    if (this.state.loading) return;
    this.setState({[key]: val});
  };

  save = async () => {
    const {
      vehicle: {id},
      onNext,
    } = this.props;
    const {loading, plate} = this.state;

    if (loading) return;

    this.setState({loading: true});

    try {
      const body = await dispatchPrep({plate});
      const vehicle = await updateVehicleApi(id, body);
      onNext({vehicle});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({loading: false});
    }
  };

  removeFromHold = () => {
    const {vehicle, dispatch, onClose} = this.props;
    onClose();
    dispatch(
      showRemoveVehicleFromHoldAct({vehicle, hold: vehicle.vehicle_hold})
    );
  };

  render() {
    const {Container, vehicle} = this.props;
    const {loading, plate} = this.state;
    return (
      <DispatchMissingVehicleInfoStep
        Container={Container}
        loading={loading}
        vehicle={vehicle}
        isRentable={isRentable(vehicle)}
        isOnHold={isOnHold(!!vehicle ? vehicle.vehicle_hold : null)}
        plate={plate}
        onChange={this.onChange}
        onNext={this.save}
        onRemoveFromHold={this.removeFromHold}
      />
    );
  }
}

export default connect()(DispatchMissingVehicleInfoStepContainer);
