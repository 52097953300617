import prepare from '../../attribute/prepare.attribute';

// Validation
import amount from '../validations/amount.validation.payment';

export default async (attributes) => {
  const payment = prepare(attributes, {
    field: 'api',
    required: ['amount', 'driver', 'subtype'],
  });
  if (payment.subtype === 'check')
    prepare(attributes, {
      field: 'api',
      required: ['check_number'],
    });
  amount(payment);
  payment.type = 'payment';
  payment.balance = payment.amount;
  return payment;
};
