import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Api
import api from '../../../api/lib/getEverythingFromApi.lib.api';
import listPayoutsApi from '../../../payout/api/list.api.payout';
import listDocumentsApi from '../../../document/api/list.api.document';
import downloadPayoutReportApi from '../../../payout/api/download.api.payout';
import printChequeApi from '../../../cheque/api/print.api.cheque';
import emailStatementApi from '../../../payout/api/emailStatement.api.payout';

// Components
import DriverPayoutsTable from '../../components/DriverPayoutsTable/DriverPayoutsTable';
import ConfirmationModal from '../../../cheque/components/ConfirmationModal/ConfirmationModal';

// Documents
import payoutDocument from '../../../driver/documents/folders/payout.document.driver';

// Lib
import {lib} from '@matthahn/sally-ui';
import fkOrId from '../../../lib/fkOrId';
import numberOfPages from '../../../lib/numberOfPages';
import downloadFile from '../../../lib/downloadFile';
import parseError from '../../../error/parseError';

// Types
import {date} from '../../../types';

// Sockets
import payoutSocket from '../../../rental/sockets/payout.socket.rental';

// Alert
const {alert} = lib;

class DriverPayoutsContainer extends Component {
  static propTypes = {
    driver: PropTypes.object,
  };

  static LIMIT = 25;

  state = {
    loading: false,
    payouts: [],
    results: 0,
    page: 1,
    downloading: [],
    printing: [],
    emailing: [],
    screenshots: [],
    showConfirm: false,
    check_to_print: null,
  };

  componentDidMount() {
    this.mounted = true;
    this.subscribe();
    this.getScreenshots();
    this.getPayouts();
  }

  componentWillUnmount() {
    this.mounted = false;
    this.unsubscribe();
  }

  subscribers = [];

  subscribe = () => {
    this.subscribers = [payoutSocket.subscribe(this.newPayout)];
  };

  unsubscribe = () => {
    this.subscribers.forEach((unsubscribe) => unsubscribe());
  };

  newPayout = (payout) => {
    const {driver} = this.props;
    const {payouts, page} = this.state;
    if (page !== 1 || driver.id !== fkOrId(payout.driver) || !this.mounted)
      return;
    this.setState({payouts: [payout, ...payouts]});
  };

  getScreenshots = async () => {
    const {driver} = this.props;
    const {data: screenshots} = await api(listDocumentsApi, {
      driver: driver.id,
      type: payoutDocument.type,
    });
    if (!this.mounted) return;
    this.setState({screenshots});
  };

  getPayouts = async ({page = this.state.page} = {}) => {
    const {driver} = this.props;
    const {loading} = this.state;
    const {LIMIT} = this.constructor;
    if (loading) return;

    this.setState({loading: true, page});

    try {
      const {results, count} = await listPayoutsApi({
        driver: driver.id,
        status: 'successful',
        offset: (page - 1) * LIMIT,
        limit: LIMIT,
        ordering: '-payout_date',
      });
      if (!this.mounted) return;
      this.setState({
        loading: false,
        payouts: results,
        results: count,
      });
    } catch (error) {
      if (!this.mounted) return;
      this.setState({loading: false});
    }
  };

  onPage = (page) => this.getPayouts({page});

  pages = () => numberOfPages(this.state.results, this.constructor.LIMIT);

  onScreenshot = (screenshot) => () => {
    window.open(screenshot.document_file, '_blank');
  };

  onReport = (payout) => async () => {
    const {downloading} = this.state;
    if (downloading.includes(payout.id)) return;

    this.setState({downloading: [...downloading, payout.id]});

    try {
      const file = await downloadPayoutReportApi(payout.id);
      downloadFile(
        file,
        `${payout.driver.first_name} ${payout.driver.last_name} - ${date(
          payout.payout_date
        ).format()}.pdf`
      );
    } catch (error) {
      alert.error('Could not generate a statement');
    }

    if (!this.mounted) return;
    this.setState({
      downloading: [...this.state.downloading].filter((d) => d !== payout.id),
    });
  };

  onEmailStatement = (payout, email) => async () => {
    const {emailing} = this.state;
    if (emailing.includes(payout.id)) return;

    this.setState({emailing: [...emailing, payout.id]});

    try {
      const response = await emailStatementApi(payout.id, {
        driver_email: email,
      });
      alert.success(response.text);
    } catch (error) {
      alert.error('Could not generate a statement');
    }

    if (!this.mounted) return;
    this.setState({
      emailing: [...this.state.emailing].filter((d) => d !== payout.id),
    });
  };

  payouts = () => {
    const {payouts, screenshots} = this.state;
    return [...payouts].map((payout) => ({
      ...payout,
      screenshot:
        [...screenshots].find(
          (screenshot) => screenshot.extra_data.payout === payout.id
        ) || null,
    }));
  };

  onChange = (val, key) => {
    const {loading} = this.state;
    if (loading) return;
    this.setState({[key]: val});
  };

  showConfirmation = (payout) => () => {
    const {printing, loading} = this.state;
    if (printing.includes(payout.id) || loading) return;
    if (!this.mounted) return;

    this.setState({
      check_to_print: payout.approved_check,
      showConfirm: true,
    });
  };

  hideConfirmation = () => this.setState({showConfirm: false});

  printCheck = (approved_check) => async () => {
    const {printing} = this.state;
    if (!approved_check) return;
    if (printing.includes(approved_check.check_number)) return;

    if (!approved_check.check_pdf)
      return alert.warning('Cheque not generated yet');

    this.setState({printing: [...printing, approved_check.check_number]});

    try {
      await printChequeApi([{check_number: approved_check.check_number}]);

      window.open(approved_check.check_pdf, '_blank');
    } catch (error) {
      if (!this.mounted) return;
      const {message} = parseError(error);
      alert.error(message);
    }

    if (!this.mounted) return;
    this.setState({
      printing: [...this.state.printing].filter(
        (d) => d !== approved_check.check_number
      ),
      showConfirm: false,
    });
  };

  render() {
    const {
      loading,
      page,
      downloading,
      emailing,
      printing,
      showConfirm,
      check_to_print,
    } = this.state;

    return (
      <Fragment>
        <DriverPayoutsTable
          loading={loading}
          downloading={downloading}
          emailing={emailing}
          printing={printing}
          payouts={this.payouts()}
          page={page}
          pages={this.pages()}
          onPage={this.onPage}
          onReport={this.onReport}
          onScreenshot={this.onScreenshot}
          onPrintCheck={this.showConfirmation}
          onEmailStatement={this.onEmailStatement}
        />
        <ConfirmationModal
          loading={loading}
          visible={showConfirm}
          numberOfCheques={1}
          onClose={this.hideConfirmation}
          onConfirm={this.printCheck(check_to_print)}
          onChange={this.onChange}
        />
      </Fragment>
    );
  }
}

export default connect((state) => ({driver: state.driver.driver}))(
  DriverPayoutsContainer
);
