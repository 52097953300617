import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

// Events
import event from '../events/updated.event.document';

export default async (id, doc) => {
  const response = await f3tch(`/documents/${id}/`)
    .profile(profile())
    .body(doc)
    .patch();
  event.pub(response);
  return response;
};
