import React from 'react';
import PropTypes from 'prop-types';

// alert components
import AlertInfo from '../../../alert/components/AlertInfo/AlertInfo';

// Components
import {TextColor} from '@matthahn/sally-ui';

// Types
import {date} from '../../../types';

const VehicleDecommissionedInfo = ({vehicle}) => (
  <AlertInfo>
    Vehicle decommissioned.
    {!!vehicle && !!vehicle.vehicle_decommissioned && (
      <span>
        {' '}
        <TextColor theme="white" weight="bold">
          Reason:
        </TextColor>{' '}
        {vehicle.vehicle_decommissioned.reason}
      </span>
    )}
    {!!vehicle && !!vehicle.vehicle_decommissioned && (
      <span>
        {' '}
        <TextColor theme="white" weight="bold">
          Archived date:
        </TextColor>{' '}
        {!!vehicle.vehicle_decommissioned.decommision_date
          ? date(vehicle.vehicle_decommissioned.decommision_date).format()
          : '-'}
      </span>
    )}
  </AlertInfo>
);

VehicleDecommissionedInfo.propTypes = {
  vehicle: PropTypes.object,
};

export default VehicleDecommissionedInfo;
