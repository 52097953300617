import PropTypes from 'prop-types';

// Types
import {amount} from '../../../types';

const inParenthesis = (wrap, dom) => (wrap ? `(${dom})` : dom);

const NegativeParenthesis = ({wrap, value, pre, post, empty}) =>
  !!empty && !value
    ? empty
    : inParenthesis(
        wrap || value < 0,
        `${!!pre ? pre : ''}${amount(Math.abs(value)).format()}${
          !!post ? post : ''
        }`
      );

NegativeParenthesis.propTypes = {
  wrap: PropTypes.bool,
  value: PropTypes.number,
  pre: PropTypes.node,
  post: PropTypes.node,
  empty: PropTypes.node,
};

NegativeParenthesis.defaultProps = {
  wrap: false,
  value: 0,
  pre: null,
  post: null,
  empty: null,
};

export default NegativeParenthesis;
