import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Info} from '@matthahn/sally-ui';

const MedallionStoredInfo = ({medallion}) => (
  <Info type="error">
    Medallion stored.
    {!!medallion && !!medallion.medallion_stored && (
      <span> Reason: {medallion.medallion_stored.reason}</span>
    )}
  </Info>
);

MedallionStoredInfo.propTypes = {
  medallion: PropTypes.object,
};

export default MedallionStoredInfo;
