import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {Button, P, Row, Column} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';
import StepFooter from '../../../layout/components/StepFooter/StepFooter';
import SmallLoader from '../../../layout/components/SmallLoader/SmallLoader';
import Info from './components/Info';

// Types
import {dateTime} from '../../../types';

const DispatchDriverDispatchStep = ({
  Container,
  loading,
  loadingRate,
  proratedRent,
  lease,
  rental,
  driver,
  medallion,
  ezpass_tag_number,
  daily,
  weekly_rent_charge,
  onWeeklyRentChargeChange,
  onBack,
  onDispatch,
}) =>
  loadingRate ? (
    <Container>
      <SmallLoader />
    </Container>
  ) : (
    <Fragment>
      <Container>
        <Row margin={!daily}>
          <Column>
            <Info>
              <P size="6">
                {driver.first_name} {driver.last_name}
              </P>
            </Info>
            <Info>
              <P size="6">{medallion.medallion_number}</P>
            </Info>
            <Info>
              <P size="6">
                {dateTime(rental?.vehicle_pickup_datetime).format()}
              </P>
            </Info>
          </Column>
        </Row>
        {/* <Row>
          {!daily && (
            <Column>
              <Text value={`$${proratedRent}`}>Prorated Rate</Text>
            </Column>
          )}
        </Row> */}
        {!daily && (
          <Row>
            <Column>
              <AttributeInput
                value={weekly_rent_charge}
                onChange={onWeeklyRentChargeChange}
                disabled={loading}
              >
                {weekly_rent_charge.label.default}
              </AttributeInput>
            </Column>
          </Row>
        )}
      </Container>
      <Container>
        <StepFooter
          left={
            <Button
              size="small"
              theme="grey"
              onClick={onBack}
              disabled={loading}
            >
              Back
            </Button>
          }
          right={
            <Button
              size="small"
              theme="orange"
              onClick={onDispatch}
              loading={loading}
            >
              Dispatch
            </Button>
          }
        />
      </Container>
    </Fragment>
  );

DispatchDriverDispatchStep.propTypes = {
  Container: PropTypes.func,
  loading: PropTypes.bool,
  loadingRate: PropTypes.bool,
  proratedRent: PropTypes.string,
  lease: PropTypes.object,
  rental: PropTypes.object,
  driver: PropTypes.object,
  medallion: PropTypes.object,
  ezpass_tag_number: PropTypes.string,
  daily: PropTypes.bool,
  weekly_rent_charge: PropTypes.object,
  onWeeklyRentChargeChange: PropTypes.func,
  onBack: PropTypes.func,
  onDispatch: PropTypes.func,
};

export default DispatchDriverDispatchStep;
