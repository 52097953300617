import {profile} from 'f3tch';

// Constants
import url from '../constants/url.const.api';

// Errors
import isApiError from '../errors/isApiError.error.api';

// Profiles
import headers from '../headers/file.header.api';

export default ({authorize = true, contentType = null} = {}) =>
  profile(url)
    .headers(headers({authorize, contentType}))
    .responder(async (response) => {
      await isApiError(response);
      const data = await response.blob();
      return data;
    });
