import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal, Row, Column, Form} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

const BankAccountVeirifactionModal = ({
  visible,
  loading,
  account,
  amount_one,
  amount_two,
  onChange,
  onClose,
  onSave,
}) => (
  <Modal
    visible={visible}
    title="Verify Account"
    icon="bank"
    size="small"
    subtitle={!!account ? account.funding_name : ''}
    onClose={onClose}
    buttonsRight={[{label: 'Save', theme: 'orange', loading, onClick: onSave}]}
  >
    {(Content) => (
      <Content>
        <Form onSubmit={onSave} enter>
          <Row margin>
            <Column>
              <AttributeInput
                value={amount_one}
                onChange={onChange}
                disabled={loading}
              >
                {amount_one.label.default}
              </AttributeInput>
            </Column>
          </Row>
          <Row>
            <Column>
              <AttributeInput
                value={amount_two}
                onChange={onChange}
                disabled={loading}
              >
                {amount_two.label.default}
              </AttributeInput>
            </Column>
          </Row>
        </Form>
      </Content>
    )}
  </Modal>
);

BankAccountVeirifactionModal.propTypes = {
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  account: PropTypes.object,
  amount_one: PropTypes.object,
  amount_two: PropTypes.object,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default BankAccountVeirifactionModal;
