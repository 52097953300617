import styled from 'styled-components';

const IFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  display: block;
  padding: 0;
  background: none;
  margin: 0px;
`;

export default IFrame;
