import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

// Events
import event from '../events/created.event.driverNote';

export default async (driverID, driverNote) => {
  const response = await f3tch(`/drivers/${driverID}/notes/`)
    .profile(profile())
    .body(driverNote)
    .post();
  event.pub(response);
  return response;
};
