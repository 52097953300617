import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// error
import parseError from '../../../error/parseError';

// vehicle attributes
import mileageAttr from '../../../vehicle/attributes/mileage.attribute.vehicle';

// vehicle api
import updateVehicleMileageApi from '../../../vehicle/api/updateMileage.api.vehicle';

// vehicle components
import EditMileageModal from '../../components/EditMileageModal/EditMileageModal';
import VehicleMileageCard from '../../components/VehicleMileageCard/VehicleMileageCard';

// types
import {apiDateTime} from '../../../types';

class VehicleMileageContainer extends Component {
  static propTypes = {
    mileage: PropTypes.object,
    vehicle: PropTypes.object,
  };

  state = {
    editModalVisible: false,
    loading: false,
    mileage: mileageAttr(''),
  };

  componentDidMount() {
    this.init();
  }

  init = () => {
    this.setState({
      mileage: mileageAttr(this.getMileage() || ''),
    });
  };

  showEditModal = () => {
    this.setState({
      editModalVisible: true,
      mileage: mileageAttr(this.getMileage() || ''),
    });
  };

  hideEditModal = () => {
    if (this.state.loading) return;
    this.setState({
      editModalVisible: false,
      mileage: mileageAttr(this.getMileage() || ''),
    });
  };

  getMileage = () => {
    const {mileage, vehicle} = this.props;
    return mileage?.mileage || vehicle?.mileage || 0;
  };

  saveVehicleMileage = async () => {
    const {vehicle} = this.props;
    const {mileage, loading} = this.state;
    if (loading) return;

    if (
      !!mileage.api.format() &&
      (vehicle?.mileage || 0) > mileage.api.format()
    )
      return alert.warning(
        `Latest captured mileage was ${this.getMileage()}. Insert a number higher than that.`
      );

    this.setState({loading: true});
    try {
      await updateVehicleMileageApi(vehicle.id, {
        mileage: mileage.api.format(),
        mileage_captured_at: apiDateTime(new Date()).format(),
      });
      this.setState({editModalVisible: false, loading: false});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({loading: false});
    }
  };

  change = (value, key) => {
    if (this.state.loading) return;
    this.setState({[key]: value});
  };

  render() {
    const {editModalVisible, loading, mileage} = this.state;
    return (
      <Fragment>
        <VehicleMileageCard mileage={mileage} onEdit={this.showEditModal} />
        <EditMileageModal
          loading={loading}
          mileage={mileage}
          onChange={this.change}
          onClose={this.hideEditModal}
          onSave={this.saveVehicleMileage}
          visible={editModalVisible}
        />
      </Fragment>
    );
  }
}

export default VehicleMileageContainer;
