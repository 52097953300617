import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

// Events
import appliedEvt from '../events/applied.event.payout';
import endWithPayoutEvt from '../../rental/events/endedWithPayout.event.rental';

export default async ({
  payout,
  end_rental_id = null,
  current_rental_id = null,
  end_datetime,
  dwolla_funding_id,
  check_number_from_driver,
} = {}) => {
  const api = f3tch(`/payments/payouts/apply/`).profile(profile());

  const body = {
    ...payout,
  };

  if (!!end_rental_id) body.end_rental_id = end_rental_id;
  if (!!current_rental_id) body.current_rental_id = current_rental_id;
  if (!!end_datetime) body.end_datetime = end_datetime;
  if (!!dwolla_funding_id) body.dwolla_funding_id = dwolla_funding_id;
  if (!!check_number_from_driver)
    body.check_number_from_driver = check_number_from_driver;

  api.body(body);

  const response = await api.post();

  !!end_rental_id ? endWithPayoutEvt.pub(response) : appliedEvt.pub(response);
  return response;
};
