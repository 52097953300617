import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {uppercaseLetters} from '../../types';

export default attribute({
  type: TEXT,
  attribute: 'last_name',
  display: uppercaseLetters,
  input: uppercaseLetters,
  api: uppercaseLetters,
  label: {
    default: 'Last Name',
    short: 'Name',
  },
});
