import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Card, Form, Row, Column, Text} from '@matthahn/sally-ui';
// import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';
import AttributeText from '../../../layout/components/AttributeText/AttributeText';

const MedallionBasicInfoCard = ({
  loading,
  adminEditableAttributes,
  hasPermission,
  onSave,
  onOwner,
  onChange,
  errors,

  medallion_number,
  medallion_type,
  medallion_expiry,
  owner,

  bank_account_number,
  bank_routing_number,
  ezpass_tag,
  last_verified,
  payment_terms,
  state,
}) => (
  <Card
    title="Basic Info"
    icon="infocirlceo"
    headerActions={[
      hasPermission && {tooltip: 'Owner', onClick: onOwner, icon: 'team'},
    ].filter((act) => !!act)}
  >
    {(Content) => (
      <Form onSubmit={onSave} enter>
        <Content>
          <Row margin>
            <Column size={1 / 2}>
              <AttributeText attribute={medallion_number}>
                {medallion_number.label.default}
              </AttributeText>
            </Column>
            <Column size={1 / 2}>
              <Text value={!!owner && owner.name ? owner.name : '-'}>
                Medallion Owning Entity
              </Text>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 2}>
              <AttributeText attribute={medallion_type}>
                {medallion_type.label.default}
              </AttributeText>
            </Column>
            <Column size={1 / 2}>
              <AttributeText attribute={medallion_expiry}>
                {medallion_expiry.label.default}
              </AttributeText>
            </Column>
          </Row>
          <Row>
            <Column>
              <AttributeText attribute={ezpass_tag}>
                {ezpass_tag.label.default}
              </AttributeText>
            </Column>
          </Row>
          {/* <Row>
            <Column size={1 / 2}>
              <AttributeText attribute={payment_terms}>
                {payment_terms.label.default}
              </AttributeText>
            </Column>
            <Column size={1 / 2}>
              <AttributeText attribute={last_verified}>
                {last_verified.label.default}
              </AttributeText>
            </Column>
          </Row> */}
        </Content>
      </Form>
    )}
  </Card>
);

MedallionBasicInfoCard.propTypes = {
  loading: PropTypes.bool,
  hasPermission: PropTypes.bool,
  adminEditableAttributes: PropTypes.array,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  onOwner: PropTypes.func,
  errors: PropTypes.array,

  medallion_number: PropTypes.object,
  medallion_type: PropTypes.object,
  medallion_expiry: PropTypes.object,
  owner: PropTypes.object,

  bank_account_number: PropTypes.object,
  bank_routing_number: PropTypes.object,
  ezpass_tag: PropTypes.object,
  last_verified: PropTypes.object,
  payment_terms: PropTypes.object,
  state: PropTypes.object,
};

export default MedallionBasicInfoCard;
