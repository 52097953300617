// Action constants
import {SET_TOKEN} from './constants';

import roles from '../lib/roles.lib.auth';

const DEFAULT_STATE = {
  initialized: false,
  token: null,
  username: '',
  userID: 0,
  first_name: '',
  last_name: '',
  roles: roles(),
  kibana_creds: '',
};

/**
 * @description :: Reducer for authorization
 * @param {Object} state :: The current state
 * @param {String} type :: The type of action
 * @param {Any} data :: The data bind to the action
 * @return {Object} :: New state
 */
export default (state = DEFAULT_STATE, {type, data}) => {
  switch (type) {
    case SET_TOKEN: {
      return {
        ...state,
        initialized: true,
        ...data,
      };
    }

    default: {
      return state;
    }
  }
};
