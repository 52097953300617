import isObject from 'isobject';

const getLabel = (option) => {
  if (option.label) return option.label;
  return 'NO LABEL';
};

const getValue = (option) => {
  if (option.value || option.value === '') return option.value;
  if (option.key) return option.key;
  if (option.id) return option.id;
  throw new Error('No value in option object found');
};

export default (options) =>
  options.map((option) =>
    isObject(option)
      ? {label: getLabel(option), value: getValue(option)}
      : {label: option, value: option}
  );
