const columns = [
  {
    key: 'date',
    label: 'Date',
  },
  {
    key: 'label',
    label: 'Label',
  },
  {
    key: 'vin',
    label: 'VIN',
  },
  {
    key: 'branch',
    label: 'Branch',
  },
];

export default columns;
