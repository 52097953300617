const parseAlert = (alert) => ({
  ...alert,
  type: 'alert',
  computedId: `alert${alert.id}`,
  search: [
    alert?.driver
      ? `${alert.driver.first_name} ${alert.driver.last_name}`
      : null,
    alert.driver.fhv_license_number || null,
  ]
    .filter((q) => !!q)
    .map((query) => `${query}`.toLowerCase()),
});

export default parseAlert;
