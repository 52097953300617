import styled from 'styled-components';
import {transitions} from 'polished';

const Container = styled.div`
  width: 100%;
  max-width: 700px;
  height: ${({showResults}) => (showResults ? '80vh' : '56px')};
  max-height: calc(100vh - 30px);
  position: relative;
  overflow: hidden;
  ${transitions('height .2s ease-in-out')};
  background: rgba(250, 250, 250, 0.8);
  backdrop-filter: blur(3px);
  box-shadow: 0 0 30px 0px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
`;

export default Container;
