import styled from 'styled-components';

// Colors
import {lib} from '@matthahn/sally-ui';
const {
  colors: {orange},
} = lib;

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 292px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 70px;
  color: ${orange};
`;

export default Container;
