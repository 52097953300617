import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {hide as hideAct} from './redux/actions';

// Api
import hotswapApi from '../../api/hotswap.api.rental';

// Lib
import {lib} from '@matthahn/sally-ui';
import vehicleSearch from '../../../dispatch/lib/vehiclesSearch.lib.dispatch';
import parseError from '../../../error/parseError';

// Components
import HotswapModal from '../../components/HotswapModal/HotswapModal';

// Sockets
import rentalEndedSocket from '../../../rental/sockets/ended.socket.rental';
import hotswapSocket from '../../../rental/sockets/hotswap.socket.rental';

// Alerts
const {alert, notify} = lib;

class HotswapContainer extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    rental: PropTypes.object,
    vehicles: PropTypes.array,
    dispatch: PropTypes.func,
  };

  static INITIAL_STATE = {
    loading: false,
    search: '',
    closable: true,
  };

  state = {
    ...this.constructor.INITIAL_STATE,
  };

  componentDidMount() {
    this.subscribe();
    if (this.props.visible) this.init();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) this.init();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  hotswapping = false;

  subscribe = () => {
    this.subscriptions = [
      rentalEndedSocket.subscribe(this.onRentalEnd),
      hotswapSocket.subscribe(this.onHotswapSocket),
    ];
  };

  unsubscribe = () => {
    this.subscriptions.forEach((unsubscribe) => unsubscribe());
    this.subscriptions = [];
  };

  onRentalEnd = (rental) =>
    this.closeBecauseUpdate({
      rental,
      message: `${rental.driver.first_name} ${rental.driver.last_name} returned ${rental.medallion.medallion_number}`,
    });

  onHotswapSocket = ({hotswaps}) => {
    [...hotswaps].forEach(({old_rental, new_rental}) => {
      this.closeBecauseUpdate({
        rental: old_rental,
        message: `${old_rental.driver.first_name} ${old_rental.driver.last_name} switched from ${old_rental.medallion.medallion_number} to ${new_rental.medallion.medallion_number}`,
      });
      this.closeBecauseUpdate({
        rental: new_rental,
        message: `${old_rental.driver.first_name} ${old_rental.driver.last_name} switched from ${old_rental.medallion.medallion_number} to ${new_rental.medallion.medallion_number}`,
      });
    });
  };

  closeBecauseUpdate = ({rental, message}) => {
    const {visible, rental: currentRental} = this.props;
    const isSameRental = !!currentRental && currentRental.id === rental.id;
    if (!visible || this.hotswapping || !isSameRental) return;
    this.onClose();
    alert.info(message);
  };

  init = () => {
    this.setState({
      loading: false,
      search: '',
    });
  };

  onClose = () => {
    if (this.state.loading || !this.state.closable) return;
    this.props.dispatch(hideAct());
  };

  onSearch = (search) => {
    if (this.state.loading) return;
    this.setState({search});
  };

  vehicles = () => {
    const {vehicles} = this.props;
    const {search} = this.state;
    return vehicleSearch({vehicles, search, display: 'available'});
  };

  onHotswap = (vehicle) => () => this.hotswap(vehicle);

  hotswap = async (vehicle, prompt = true) => {
    const {rental, dispatch} = this.props;
    const {loading} = this.state;
    if (loading) return;

    if (prompt) {
      await this.setState({closable: false});
      return notify({
        id: 'hotswap',
        title: 'Hotswap',
        icon: undefined,
        content: `Are you sure you want to hotswap ${rental.driver.first_name} ${rental.driver.last_name} from ${rental.medallion.medallion_number} to ${vehicle.medallion.medallion_number}?`,
        primary: {
          label: 'No',
          onClick: () => this.setState({closable: true}),
        },
        onClose: () => this.setState({closable: true}),
        secondary: {
          label: 'Yes',
          onClick: () => this.hotswap(vehicle, false),
        },
        closable: false,
        closeOnOutsideClick: true,
      });
    }

    this.setState({loading: true});
    this.hotswapping = true;

    try {
      await hotswapApi({
        old_rental: rental.id,
        new_vehicle: vehicle.id,
        driver_tag: false,
      });
      alert.success('Hotswap completed');
      dispatch(hideAct());
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
    }

    this.setState({loading: false});
    this.hotswapping = false;
  };

  render() {
    const {visible, rental} = this.props;
    const {loading, search} = this.state;
    return (
      <HotswapModal
        loading={loading}
        visible={visible}
        vehicles={this.vehicles()}
        rental={rental}
        search={search}
        onSearch={this.onSearch}
        onHotswap={this.onHotswap}
        onClose={this.onClose}
      />
    );
  }
}

export default connect((state) => ({
  ...state.hotswap,
  vehicles: state.dispatcher.vehicles,
}))(HotswapContainer);
