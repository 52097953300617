import React from 'react';
import PropTypes from 'prop-types';

// components
import {Modal, Form} from '@matthahn/sally-ui';

// layout components
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

const EditMileageModal = ({
  loading,
  mileage,
  onChange,
  onClose,
  onSave,
  visible,
}) => (
  <Modal
    title="Edit mileage"
    visible={visible}
    buttonsRight={[{label: 'Save', loading, onClick: onSave, theme: 'orange'}]}
    onClose={onClose}
  >
    {(Content) => (
      <Content>
        <Form onSubmit={onSave} enter>
          <AttributeInput
            value={mileage}
            onChange={onChange}
            disabled={loading}
          >
            {mileage.label.default}
          </AttributeInput>
        </Form>
      </Content>
    )}
  </Modal>
);

EditMileageModal.propTypes = {
  loading: PropTypes.bool,
  mileage: PropTypes.object,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  visible: PropTypes.bool,
};

export default EditMileageModal;
