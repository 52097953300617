import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Components
import {Container, Row, Column, Table, InputTyping} from '@matthahn/sally-ui';

import PageContainer from './components/Container';
import Search from './components/Search';
import Content from './components/Content';

// Types
import {amount as amountType, dateTime as dateTimeType} from '../../../types';

class TollsList extends Component {
  static propTypes = {
    medallion: PropTypes.string,
    results: PropTypes.number,
    loading: PropTypes.bool,
    tolls: PropTypes.array,
    page: PropTypes.number,
    pages: PropTypes.number,
    onPage: PropTypes.func,
    sort: PropTypes.object,
    onSort: PropTypes.func,
    onMedallion: PropTypes.func,
  };

  columns = () => [
    {
      key: 'tollId',
      label: 'Toll ID',
    },
    {
      key: 'transaction_date',
      label: 'Transaction Date',
      sortable: true,
    },
    {
      key: 'medallion',
      label: 'Medallion',
    },
    {
      key: 'plate',
      label: 'Kuiper Vehicle Plate',
    },
    {
      key: 'amount',
      label: 'Amount',
      sortable: true,
    },
    {
      key: 'exit_plaza',
      label: 'Exit Plaza',
    },
    {
      key: 'date_created',
      label: 'Date Created',
    },
  ];

  headerItems = (Content) => <Content />;

  results = () =>
    `${this.props.results} toll${this.props.results === 1 ? '' : 's'}`;

  negativeInParantheses = (value) => {
    const dom = <span>${amountType(Math.abs(value)).format()}</span>;
    return value < 0 ? <span>({dom})</span> : dom;
  };

  render() {
    const {
      medallion,
      loading,
      tolls,
      page,
      pages,
      onPage,
      sort,
      onSort,
      onToll,
      onMedallion,
    } = this.props;
    return (
      <PageContainer>
        <Search>
          <Row>
            <Column size={1 / 4} offset={3 / 8}>
              <InputTyping
                value={medallion}
                onChange={onMedallion}
                preIcon="search1"
                placeholder="Search by medallion"
              />
            </Column>
          </Row>
        </Search>
        <Content>
          <Container>
            <Row margin>
              <Column>
                <Table
                  columns={this.columns()}
                  subtitle={this.results()}
                  infinite={false}
                  page={page}
                  pages={pages}
                  onPage={onPage}
                  loading={loading}
                  sort={sort}
                  onSort={onSort}
                  headerItems={this.headerItems}
                  theme="orange"
                >
                  {(TableRow) =>
                    tolls.map((toll) => (
                      <TableRow onClick={onToll(toll)}>
                        {(TableCol) => [
                          <TableCol key="tollId">{toll.plate || '-'}</TableCol>,
                          <TableCol key="transaction_date">
                            {dateTimeType(toll.transaction_date).format()}
                          </TableCol>,
                          <TableCol key="medallion">
                            {toll.vehicle_medallion || '-'}
                          </TableCol>,
                          <TableCol key="plate">
                            {toll.vehicle_plate || '-'}
                          </TableCol>,
                          <TableCol key="amount">
                            {this.negativeInParantheses(toll.amount)}
                          </TableCol>,
                          <TableCol key="exit_plaza">
                            {toll.exit_plaza}
                          </TableCol>,
                          <TableCol key="date_created">
                            {dateTimeType(toll.date_created).format()}
                          </TableCol>,
                        ]}
                      </TableRow>
                    ))
                  }
                </Table>
              </Column>
            </Row>
          </Container>
        </Content>
      </PageContainer>
    );
  }
}

export default TollsList;
