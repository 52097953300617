import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

export default (id, {amount_one, amount_two}) =>
  f3tch(`/payments/funding_sources/${id}/verify_micro_deposits/`)
    .profile(profile())
    .body({amount_one, amount_two})
    .post();
