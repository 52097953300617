import prepare from '../../attribute/prepare.attribute';

export default async (attributes) =>
  prepare(attributes, {
    field: 'api',
    required: [
      'poc1',
      'poc1_address_street1',
      'poc1_address_zip',
      'poc1_address_city',
      'poc1_address_state',
      'poc1_phone_number',
      'poc1_email',
    ],
  });
