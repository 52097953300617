import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {hide as hideAct} from './redux/actions';

// Api
import assignToEmployeeApi from '../../api/assignToEmployee.api.toll';

// Components
import ReassignTollToEmployeeModal from '../../components/ReassignTollToEmployeeModal/ReassignTollToEmployeeModal';

// Lib
import {lib} from '@matthahn/sally-ui';
import parseError from '../../../error/parseError';

// Permissions
import assignPermission from '../../permissions/assign.permission.toll';

// Alert
const {alert} = lib;

class ReassignTollToEmployeeContainer extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    toll: PropTypes.object,
    dispatch: PropTypes.func,
  };

  state = {
    loading: false,
    employeeName: ''
  };

  componentDidMount() {
    if (this.props.visible) this.init();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) this.init();
  }

  init = () => {
    this.setState({loading: false});
  };

  onClose = () => {
    if (this.state.loading) return;
    this.props.dispatch(hideAct());
  };

  onEmployee = (employeeName) => {
    if (this.state.loading) return;
    this.setState({employeeName});
  };

  assign = async () => {
    const {dispatch, toll} = this.props;
    const {loading, employeeName} = this.state;
    if (loading) return;

    if (!assignPermission())
      return alert.warning('You do not have permission to assign tolls');

    if (!employeeName) return alert.warning('You must name the employee');

    this.setState({loading: true});

    try {
      await assignToEmployeeApi({toll: toll.id, employee: employeeName});
      this.setState({loading: false});
      alert.success(`Toll assigned to ${employeeName}`);
      dispatch(hideAct());
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({loading: false});
    }
  };

  render() {
    const {visible, toll} = this.props;
    const {loading, employeeName} = this.state;
    return (
      <Fragment>
        <ReassignTollToEmployeeModal
          visible={visible}
          canAssign={assignPermission()}
          loading={loading}
          toll={toll}
          onEmployee={this.onEmployee}
          onClose={this.onClose}
          onAssign={this.assign}
          employeeName={employeeName}
        />
      </Fragment>
    );
  }
}

export default connect((state) => ({
  ...state.reassignTollToEmployee,
}))(ReassignTollToEmployeeContainer);

