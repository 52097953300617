import styled from 'styled-components';

// constants
const SIZE = '142px';

const Date = styled.div`
  width: ${SIZE};
  max-width: ${SIZE};
  min-width: ${SIZE};
  display: flex;
  align-items: flex-end;
  gap: 4px;
`;

export default Date;
