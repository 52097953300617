import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import {Form} from '@matthahn/sally-ui';
import AttributeInput from '../../../../layout/components/AttributeInput/AttributeInput';

// Lib
import {lib} from '@matthahn/sally-ui';

// Colors
const {
  colors: {darkGrey, black},
} = lib;

const Actions = styled.div`
  width: 100%;
  padding-top: 10px;
  text-align: right;
  font-weight: bold;
  font-size: 12px;
  color: ${darkGrey};
  text-align: right;
`;

const Line = styled.span`
  display: inline-block;
  margin: 0px 5px;
`;

const Action = styled.span`
  cursor: pointer;
  &:hover {
    color: ${black};
  }
`;

const Create = ({text, onText, onSave, onCancel, creating}) => (
  <Form onSubmit={onSave} enter>
    <AttributeInput
      value={text}
      onChange={onText}
      disabled={creating}
      placeholder="Enter your note here.."
    />
    <Actions>
      <Action onClick={onSave}>Create</Action>
      <Line>|</Line>
      <Action onClick={onCancel}>Cancel</Action>
    </Actions>
  </Form>
);

Create.propTypes = {
  text: PropTypes.object,
  onText: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  creating: PropTypes.bool,
};

export default Create;
