import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {apiPhoneNumber, phoneNumber} from '../../types';

export default attribute({
  type: TEXT,
  attribute: 'poc1_phone_number',
  display: phoneNumber,
  input: phoneNumber,
  api: apiPhoneNumber,
  label: {
    default: 'Phone Number',
    short: 'Phone',
  },
});
