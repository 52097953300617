import {SET} from './constants';

export const show = (driver) => ({
  type: SET,
  data: {visible: true, driver},
});

export const hide = () => ({
  type: SET,
  data: {visible: false},
});
