import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

// Events
import event from '../events/dispatched.event.dispatch';

export default async (dispatch, eventPayload = null) => {
  const response = await f3tch('/dispatch/dispatch_driver/')
    .profile(profile())
    .body(dispatch)
    .post();
  event.pub(eventPayload || response || null);
  return response;
};
