import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Card, Form, Row, Column} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

// vehicle lib
import getVehicleMakes from '../../../vehicle/libs/getVehicleMakes.lib.vehicle';
import getVehicleModels from '../../../vehicle/libs/getVehicleModels.lib.vehicle';
import getVehicleTypes from '../../../vehicle/libs/getVehicleTypes.lib.vehicle';

const VehicleBasicInfoCard = ({
  loading,
  adminEditableAttributes,
  onSave,
  onChange,
  errors,

  hackup_date,
  dov,
  vehicle_type,
  vehicle_model,
  vehicle_make,
  vin,
  vehicle_year,
  topper_type,
  dashcam,
}) => (
  <Card title="Basic Info" icon="infocirlceo">
    {(Content) => (
      <Form onSubmit={onSave} enter>
        <Content>
          <Row margin>
            <Column>
              <AttributeInput
                value={vin}
                onChange={onChange}
                errors={errors}
                disabled={loading}
                auth={adminEditableAttributes}
              >
                {vin.label.default}
              </AttributeInput>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 2}>
              <AttributeInput
                value={vehicle_type}
                onChange={onChange}
                errors={errors}
                disabled={loading}
                auth={adminEditableAttributes}
                notFilterable
                options={getVehicleTypes()}
              >
                {vehicle_type.label.default}
              </AttributeInput>
            </Column>
            <Column size={1 / 2}>
              <AttributeInput
                value={vehicle_year}
                onChange={onChange}
                errors={errors}
                disabled={loading}
                auth={adminEditableAttributes}
              >
                {vehicle_year.label.default}
              </AttributeInput>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 2}>
              <AttributeInput
                value={vehicle_make}
                onChange={onChange}
                errors={errors}
                disabled={loading}
                auth={adminEditableAttributes}
                notFilterable
                options={getVehicleMakes()}
              >
                {vehicle_make.label.default}
              </AttributeInput>
            </Column>
            <Column size={1 / 2}>
              <AttributeInput
                value={vehicle_model}
                onChange={onChange}
                errors={errors}
                disabled={loading}
                auth={adminEditableAttributes}
                notFilterable
                options={getVehicleModels({make: vehicle_make.api.format()})}
              >
                {vehicle_model.label.default}
              </AttributeInput>
            </Column>
          </Row>
          <Row margin>
            <Column>
              <AttributeInput
                value={hackup_date}
                onChange={onChange}
                errors={errors}
                disabled={loading}
                auth={adminEditableAttributes}
              >
                {hackup_date.label.default}
              </AttributeInput>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 2}>
              <AttributeInput
                value={topper_type}
                onChange={onChange}
                errors={errors}
                disabled={loading}
                auth={adminEditableAttributes}
                notFilterable
              >
                {topper_type.label.default}
              </AttributeInput>
            </Column>
            <Column size={1 / 2}>
              <AttributeInput
                value={dov}
                onChange={onChange}
                errors={errors}
                disabled={loading}
                auth={adminEditableAttributes}
                green
              >
                {dov.label.default}
              </AttributeInput>
            </Column>
          </Row>
          <Row>
            <Column>
              <AttributeInput
                value={dashcam}
                onChange={onChange}
                errors={errors}
                disabled={loading}
                auth={adminEditableAttributes}
                notFilterable
              >
                {dashcam.label.default}
              </AttributeInput>
            </Column>
          </Row>
        </Content>
      </Form>
    )}
  </Card>
);

VehicleBasicInfoCard.propTypes = {
  loading: PropTypes.bool,
  adminEditableAttributes: PropTypes.array,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  errors: PropTypes.array,

  hackup_date: PropTypes.object,
  dov: PropTypes.object,
  vehicle_type: PropTypes.object,
  vehicle_model: PropTypes.object,
  vin: PropTypes.object,
  vehicle_year: PropTypes.object,
  vehicle_make: PropTypes.object,
  retirement_date: PropTypes.object,
  topper_type: PropTypes.object,
  dashcam: PropTypes.object,
};

export default VehicleBasicInfoCard;
