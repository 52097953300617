import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

// Events
import event from '../events/listed.event.driverAlert';

export default async (driverId, query = {}) => {
  const response = await f3tch(`/drivers/${driverId}/alerts/`)
    .profile(profile())
    .query(query)
    .get();
  event.publish(...response.results);
  return response;
};
