import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {string, uppercase} from '../../types';

export default attribute({
  type: TEXT,
  attribute: 'policy_number',
  display: string,
  input: uppercase,
  api: string,
  label: {
    default: 'Policy Number',
    short: 'Policy Number',
  },
});
