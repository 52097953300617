import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {Row, Column, Form, Button, Text, Info} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';
// import AttributeText from '../../../layout/components/AttributeText/AttributeText';
import StepFooter from '../../../layout/components/StepFooter/StepFooter';
import SmallLoader from '../../../layout/components/SmallLoader/SmallLoader';
import NegativeParenthesis from '../../../layout/components/NegativeParenthesis/NegativeParenthesis';

const DispatchLeaseStep = ({
  Container,
  loading,
  saving,
  leaseExists,
  contractTypes,
  driver,
  amount,
  contract_type,
  vehicle_pickup_datetime,
  disableContinuing,
  onChange,
  onAction,
  onBack,
}) =>
  loading ? (
    <Container>
      <SmallLoader />
    </Container>
  ) : (
    <Fragment>
      <Form onSubmit={onAction} enter>
        {disableContinuing && (
          <Container padding="none">
            <Info type="warning" flat>
              Driver needs to have a weekly lease contract
            </Info>
          </Container>
        )}
        <Container>
          <Row margin>
            <Column>
              <Text value={`${driver.first_name} ${driver.last_name}`}>
                Driver
              </Text>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 2}>
              <Text
                value={<NegativeParenthesis value={driver.balance} pre="$" />}
              >
                Balance
              </Text>
            </Column>
            <Column size={1 / 2}>
              {/* {!!leaseExists ? (
              <AttributeText attribute={contract_type}>
                {contract_type.label.default}
              </AttributeText>
            ) : (
              <AttributeInput
                value={contract_type}
                options={contractTypes}
                onChange={onChange}
                disabled={saving}
                notFilterable
              >
                {contract_type.label.default}
              </AttributeInput>
            )} */}
              <AttributeInput
                value={contract_type}
                options={contractTypes}
                onChange={onChange}
                disabled={saving}
                notFilterable
              >
                {contract_type.label.default}
              </AttributeInput>
            </Column>
          </Row>
          <Row>
            <Column size={1 / 3}>
              <AttributeInput
                value={amount}
                preValue="$"
                onChange={onChange}
                disabled={saving}
              >
                Recurring weekly rate
              </AttributeInput>
            </Column>
            <Column size={2 / 3}>
              <AttributeInput
                value={vehicle_pickup_datetime}
                onChange={onChange}
                disabled={saving}
              >
                {vehicle_pickup_datetime.label.default}
              </AttributeInput>
            </Column>
          </Row>
        </Container>
      </Form>
      <Container>
        <StepFooter
          left={
            <Button
              size="small"
              theme="grey"
              onClick={onBack}
              disabled={saving}
            >
              Back
            </Button>
          }
          right={
            <Button
              size="small"
              theme="orange"
              onClick={onAction}
              loading={saving}
              disabled={disableContinuing}
            >
              Save
            </Button>
          }
        />
      </Container>
    </Fragment>
  );

DispatchLeaseStep.propTypes = {
  Container: PropTypes.func,
  loading: PropTypes.bool,
  saving: PropTypes.bool,
  leaseExists: PropTypes.bool,
  driver: PropTypes.object,
  contractTypes: PropTypes.array,
  amount: PropTypes.object,
  contract_type: PropTypes.object,
  vehicle_pickup_datetime: PropTypes.object,
  disableContinuing: PropTypes.bool,
  onChange: PropTypes.func,
  onAction: PropTypes.func,
  onBack: PropTypes.func,
};

export default DispatchLeaseStep;
