import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

// Events
import event from '../events/updatedRentalRate.event.rental';

export default async (rentalRate, jwt = null) => {
  const api = f3tch(`/dispatch/rental_rates/`).profile(profile());

  if (!!jwt) api.headers({Authorization: `JWT ${jwt}`});

  const response = await api.body(rentalRate).post();
  event.pub(response);
  return response;
};
