import React from 'react';
import PropTypes from 'prop-types';

// Components
import MedallionContainer from '../../containers/MedallionContainer/MedallionContainer';

const MedallionPage = ({
  match: {
    params: {id},
  },
}) => <MedallionContainer id={id} />;

MedallionPage.propTypes = {
  match: PropTypes.object,
};

export default MedallionPage;
