import {SET} from './constants';

export const set = (data = {}) => ({
  type: SET,
  data,
});

export const show = (
  driver = null,
  requestAddon = {},
  {allocate = true} = {}
) => set({driver, visible: true, requestAddon, allocate});

export const hide = (creditCreated = false) =>
  set({driver: null, visible: false, creditCreated});
