import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/file.profile.api';

// Events
import event from '../events/created.event.document';

export default async (doc, publish = true) => {
  const response = await f3tch('/documents/')
    .profile(profile())
    .body(doc)
    .post();
  if (publish) event.pub(response);
  return response;
};
