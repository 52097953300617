import styled from 'styled-components';

// Libs
import {lib} from '@matthahn/sally-ui';

// Colors
const {
  media: {media},
} = lib;

const HeaderContent = styled.div`
  width: 100%;
  padding: 20px 0px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  ${media.s`
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
  `}
`;

export default HeaderContent;
