import needs_action from './needs_action.status.ticket';
import paid_by_sally from './paid_by_sally.status.ticket';
import resolved from './resolved.status.ticket';
import sally_to_pay from './sally_to_pay.status.ticket';
import unknown from './unknown.status.ticket';
import waiting from './waiting.status.ticket';

// date
import {parseISO} from 'date-fns';
import isObject from 'is-object';

const states = [
  needs_action,
  paid_by_sally,
  resolved,
  sally_to_pay,
  waiting,
].reduce((combined, state) => ({...combined, [state.key]: state}), {});

export const stateList = [
  needs_action,
  paid_by_sally,
  resolved,
  sally_to_pay,
  waiting,
];

export const changableStates = [...stateList].filter(
  ({canChangeState}) => canChangeState
);

export default (ticket) => {
  if (!ticket) {
    return unknown;
  }

  var state = {...states[ticket.status]};

  const due_datetime = isObject(ticket.due_datetime)
    ? parseISO(ticket.due_datetime.value())
    : parseISO(ticket.due_datetime);
  const isPassedDue = due_datetime <= Date.now();

  if (isPassedDue && !ticket.charge) {
    state.transition = [sally_to_pay];
  }

  return state;
};
