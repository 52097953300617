import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/file.profile.api';

// Events
import event from '../events/driverStatementGenerated.event.accident';

export default async (accidentId) => {
  const response = await f3tch(
    `/accidents/dol/${accidentId}/generate_driver_statement/`
  )
    .profile(profile())
    .get();
  event.publish(response);
  return response;
};
