import React, {Component} from 'react';
import PropTypes from 'prop-types';
import isObject from 'is-object';

// Components
import {P, Button} from '@matthahn/sally-ui';
import HighlightTicket from '../HighlightTicket/HighlightTicket';
import SmallLoader from '../../../layout/components/SmallLoader/SmallLoader';
import Center from './components/Center';
import Container from './components/Container';
import Header from './components/Header';
import Content from './components/Content';
import Input from './components/Input';
import More from './components/More';
import TableHeader from './components/TableHeader';
import TableRow from './components/TableRow';
import TableCol from './components/TableCol';

// Lib
import wait from '../../../lib/wait';

// Types
import {amount as amountType, dateTime as dateTimeType} from '../../../types';

// Helpers
const negativeInParantheses = (value) => {
  const dom = <span>${amountType(Math.abs(value)).format()}</span>;
  return value < 0 ? <span>({dom})</span> : dom;
};

class TicketsTable extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    more: PropTypes.bool,
    title: PropTypes.string,
    tickets: PropTypes.array,
    medallion: PropTypes.string,
    withDriver: PropTypes.bool,
    onMedallion: PropTypes.func,
    onTicket: PropTypes.func,
    onMore: PropTypes.func,
    onSearch: PropTypes.func,
    onNew: PropTypes.func,
  };

  state = {
    medallion: this.props.medallion,
  };

  onMedallion = async (e) => {
    const {onMedallion} = this.props;
    const realNewValue = e.target.value;
    this.setState({medallion: realNewValue}, async () => {
      await wait(200);
      if (this.state.medallion === realNewValue) onMedallion(realNewValue);
    });
  };

  render() {
    const {
      loading,
      more,
      title,
      tickets,
      onTicket,
      onMore,
      onSearch,
      onNew,
      withDriver,
    } = this.props;
    const {medallion} = this.state;
    return (
      <Container>
        <Header onSearch={onSearch} onNew={onNew}>
          {title}
        </Header>
        <Input
          value={medallion}
          onChange={this.onMedallion}
          placeholder="Search"
        />
        <TableHeader>
          <TableCol>Summons Number</TableCol>
          <TableCol>Violation Date</TableCol>
          <TableCol>Due Date</TableCol>
          <TableCol>Violation</TableCol>
          <TableCol>{withDriver ? 'Driver' : 'License Plate'}</TableCol>
          <TableCol>Amount</TableCol>
        </TableHeader>
        <Content>
          {loading && !tickets.length && (
            <Center>
              <SmallLoader />
            </Center>
          )}
          {!loading && !tickets.length && (
            <Center>
              <P theme="darkGrey" size="3">
                No tickets found
              </P>
            </Center>
          )}
          {tickets.map((ticket) => (
            <TableRow key={ticket.id} onClick={onTicket(ticket)}>
              <TableCol key="summons_number">
                <HighlightTicket ticket={ticket}>
                  {ticket.summons_number}
                </HighlightTicket>
              </TableCol>
              <TableCol key="violation_datetime">
                <HighlightTicket ticket={ticket}>
                  {dateTimeType(ticket.violation_datetime).format() || '-'}
                </HighlightTicket>
              </TableCol>
              <TableCol key="due_datetime">
                <HighlightTicket ticket={ticket}>
                  {dateTimeType(ticket.due_datetime).format()}
                </HighlightTicket>
              </TableCol>
              <TableCol key="violation" span={2}>
                <HighlightTicket ticket={ticket}>
                  {ticket.violation}
                </HighlightTicket>
              </TableCol>
              <TableCol key="license_plate">
                <HighlightTicket ticket={ticket}>
                  {withDriver
                    ? !!ticket.rental && isObject(ticket.rental)
                      ? `${ticket.rental.driver.first_name} ${ticket.rental.driver.last_name}`
                      : '-'
                    : ticket.license_plate}
                </HighlightTicket>
              </TableCol>
              <TableCol key="fine_amount">
                <HighlightTicket ticket={ticket}>
                  {negativeInParantheses(ticket.fine_amount)}
                </HighlightTicket>
              </TableCol>
            </TableRow>
          ))}
          {more && !!tickets.length && (
            <More>
              <Button
                size="small"
                theme="grey"
                outline
                loading={loading}
                onClick={onMore}
                icon="ellipsis1"
              >
                Load more
              </Button>
            </More>
          )}
        </Content>
      </Container>
    );
  }
}

export default TicketsTable;
