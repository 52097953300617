import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

// accident actions
import {set as setAct} from '../../redux/actions';

// accident components
import PoliceReportModal from '../../components/PoliceReportModal/PoliceReportModal';

// components
import {Button} from '@matthahn/sally-ui';
import AttributeFileUploader from '../../../layout/components/AttributeFileUploader/AttributeFileUploader';

// documents
import policeReportDocument from '../../../accident/documents/folders/policeReport.document.accident';

// redux
import {connect} from 'react-redux';

class PoliceReportStateContainer extends Component {
  static propTypes = {
    accident: PropTypes.object,
    children: PropTypes.func,
    policeReport: PropTypes.object,
  };

  state = {
    displayUpload: false,
    displayModal: false,
  };

  showUploadModal = () =>
    this.setState({displayUpload: true, displayModal: false});

  hideUploadModal = () => this.setState({displayUpload: false});

  showFileModal = () => this.setState({displayModal: true});

  hideFileModal = () => this.setState({displayModal: false});

  policeReportUploaded = ({files: [policeReport]}) => {
    this.props.dispatch(setAct({policeReport}));
    this.hideUploadModal();
  };

  renderContent = () => {
    const {accident} = this.props;
    if (
      accident?.accidentstate?.police_report_state === 'police_report_uploaded'
    )
      return (
        <Button
          size="small"
          theme="green"
          icon="check"
          onClick={this.showFileModal}
        >
          Show
        </Button>
      );
    return accident?.accidentstate?.police_report_state ===
      'police_report_pending_upload' ? (
      <Button
        size="small"
        theme="grey"
        icon="upload"
        onClick={this.showUploadModal}
      >
        Upload
      </Button>
    ) : (
      '-'
    );
  };

  render = () => {
    const {accident, policeReport} = this.props;
    const {displayUpload, displayModal} = this.state;
    return (
      <Fragment>
        {this.renderContent()}
        <AttributeFileUploader
          accident={accident}
          title="Upload DriverStatement"
          type="accident"
          folderType={policeReportDocument.type}
          visible={displayUpload}
          onDone={this.policeReportUploaded}
          onClose={this.hideUploadModal}
        />
        <PoliceReportModal
          visible={displayModal}
          onClose={this.hideFileModal}
          policeReport={policeReport}
          onUpload={this.showUploadModal}
        />
      </Fragment>
    );
  };
}

export default connect((state) => ({
  accident: state.accident.accident,
  policeReport: state.accident.policeReport,
}))(PoliceReportStateContainer);
