import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Card, Row, Column, Button, P} from '@matthahn/sally-ui';
import ActionContainer from './components/ActionContainer';
import Action from './components/Action';

// driver containers
import DriverAutoPayoutContainer from '../../containers/DriverAutoPayoutContainer/DriverAutoPayoutContainer';

// lease containers
// import ChangeLeaseContainer from '../../../lease/containers/ChangeLeaseContainer/ChangeLeaseContainer';

const DriverPaymentActions = ({actions}) => (
  <Card title="Payment Actions" icon="sharealt">
    {(Content) => (
      <Content>
        <Row margin>
          {actions.map(({key, label, icon, theme, isDisabled, action}) => (
            <Column size={1 / actions.length} key={key}>
              <ActionContainer isDisabled={isDisabled} onClick={action}>
                {icon === '$' ? (
                  <Button size="large" theme={theme || 'darkGrey'} circular>
                    {icon}
                  </Button>
                ) : (
                  <Button
                    size="large"
                    theme={theme || 'darkGrey'}
                    icon={icon}
                  />
                )}
                <Action>
                  <P theme="darkGrey" align="center">
                    {label}
                  </P>
                </Action>
              </ActionContainer>
            </Column>
          ))}
        </Row>
        <Row>
          <Column>
            <DriverAutoPayoutContainer />
          </Column>
        </Row>
        {/* <Row>
          <Column>
            <ChangeLeaseContainer />
          </Column>
        </Row> */}
      </Content>
    )}
  </Card>
);

DriverPaymentActions.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      icon: PropTypes.string,
      label: PropTypes.string,
      theme: PropTypes.string,
      action: PropTypes.func,
    })
  ),
};

export default DriverPaymentActions;
