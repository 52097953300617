import transactionType from './type.transaction';

export default (types) =>
  [...types]
    .map(({type, subtypes}) =>
      [...subtypes].map((subtype) =>
        transactionType({
          type,
          subtype,
          owed_to_sally: subtype.owed_to_sally,
          exclude_from_payout: subtype.exclude_from_payout,
          deduct_balance_from: subtype.deduct_balance_from,
          exclude_from_deposit_payout: subtype.exclude_from_deposit_payout,
        })
      )
    )
    .flat()
    .reduce(
      (combined, current) =>
        !!combined[current.key]
          ? combined
          : {
              ...combined,
              [current.key]: {
                ...current,
                transactions: [],
              },
            },
      {}
    );
