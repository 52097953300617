import styled from 'styled-components';

// Components
import {P} from '@matthahn/sally-ui';

const SpaceTag = styled(P)`
  vertical-align: middle;
`;

export default SpaceTag;
