class EventRace {
  operating = false;

  handle(fn) {
    return async (...args) => {
      if (this.operating) return;
      this.operating = true;
      await fn(...args);
      this.operating = false;
    };
  }
}

export default (fn) => new EventRace().handle(fn);
