import {NUMBER} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {stringNumber, number} from '../../types';

export default attribute({
  type: NUMBER,
  attribute: 'dmv_years',
  display: stringNumber,
  input: stringNumber,
  api: number,
  label: {
    default: 'DMV Years',
    short: 'Years',
  },
});
