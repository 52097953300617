import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

export default attribute({
  type: TEXT,
  attribute: 'poc1',
  label: {
    default: 'Name',
    short: 'Name',
  },
});
