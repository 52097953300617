import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Card, Row, Column, Text} from '@matthahn/sally-ui';

// type
import dateType from '@matthahn/sally-fw/lib/type/types/date.type';
import phoneNumberType from '@matthahn/sally-fw/lib/type/types/phoneNumber.type';

const VehicleInsuranceCard = ({vehicle}) => (
  <Card title="Insurance" icon="contacts">
    {(Content) =>
      !!vehicle.insurance ? (
        <Content>
          <Row margin>
            <Column size={1 / 2}>
              <Text value={vehicle?.insurance?.policy_number}>
                Policy Number
              </Text>
            </Column>
            <Column size={1 / 2}>
              <Text
                value={phoneNumberType(
                  vehicle?.insurance?.accident_phone_number
                ).format()}
              >
                Phone Number
              </Text>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 2}>
              <Text value={vehicle?.insurance?.insurance_company_name}>
                Insurance Name
              </Text>
            </Column>
            <Column size={1 / 2}>
              <Text value={vehicle?.insurance?.insurance_company_number}>
                Insurance Number
              </Text>
            </Column>
          </Row>
          <Row>
            <Column size={1 / 2}>
              <Text
                value={dateType(
                  vehicle?.insurance?.policy_effective_date
                ).format()}
              >
                Effective Date
              </Text>
            </Column>
            <Column size={1 / 2}>
              <Text
                value={dateType(
                  vehicle?.insurance?.policy_expiration_date
                ).format()}
              >
                Expiry Date
              </Text>
            </Column>
          </Row>
        </Content>
      ) : (
        <Content>Vehicle doesn't have insurance</Content>
      )
    }
  </Card>
);

VehicleInsuranceCard.propTypes = {
  loading: PropTypes.bool,
  adminEditableAttributes: PropTypes.array,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  errors: PropTypes.array,

  insurance_liability_number: PropTypes.object,
};

export default VehicleInsuranceCard;
