// Branch
// import getBranch from '../branch/lib/getBranch.lib.branch';

// const getTimeZoneFromBranch = ({branch = null} = {}) =>
//   getBranch(branch)?.timezone_name || null;

// export default getTimeZoneFromBranch;
const getTimeZoneFromBranch = () => 'America/New_York';

export default getTimeZoneFromBranch;
