import React from 'react';
import PropTypes from 'prop-types';

// Attributes
import poc1Attr from '../../attributes/poc1.attribute.medallionOwner';
import addressStreet1Attr from '../../attributes/poc1_address_street1.attribute.medallionOwner';
import addressStreet2Attr from '../../attributes/poc1_address_street2.attribute.medallionOwner';
import addressCityAttr from '../../attributes/poc1_address_city.attribute.medallionOwner';
import addressZipAttr from '../../attributes/poc1_address_zip.attribute.medallionOwner';
import addressStateAttr from '../../attributes/poc1_address_state.attribute.medallionOwner';
import phoneNumberAttr from '../../attributes/poc1_phone_number.attribute.medallionOwner';
import emailAttr from '../../attributes/poc1_email.attribute.medallionOwner';
import notesAttr from '../../attributes/poc1_notes.attribute.medallionOwner';

// Components
import {Card, Row, Column} from '@matthahn/sally-ui';
import AttributeText from '../../../layout/components/AttributeText/AttributeText';

const Poc1 = ({
  poc1,
  poc1_address_street1,
  poc1_address_street2,
  poc1_address_city,
  poc1_address_zip,
  poc1_address_state,
  poc1_email,
  poc1_phone_number,
  poc1_notes,
  onEdit,
}) => (
  <Card
    title="POC 1"
    icon="phone"
    headerActions={[
      {
        icon: 'edit',
        onClick: onEdit,
        tooltip: 'Edit',
      },
    ]}
  >
    {(Content) => (
      <Content>
        <Row margin>
          <Column>
            <AttributeText attribute={poc1Attr(poc1)}>
              {poc1Attr.label.default}
            </AttributeText>
          </Column>
        </Row>
        <Row margin>
          <Column size={1 / 2}>
            <AttributeText attribute={emailAttr(poc1_email)}>
              {emailAttr.label.default}
            </AttributeText>
          </Column>
          <Column size={1 / 2}>
            <AttributeText attribute={phoneNumberAttr(poc1_phone_number)}>
              {phoneNumberAttr.label.default}
            </AttributeText>
          </Column>
        </Row>
        <Row margin>
          <Column size={1 / 3}>
            <AttributeText attribute={addressStreet1Attr(poc1_address_street1)}>
              {addressStreet1Attr.label.default}
            </AttributeText>
          </Column>
          <Column size={1 / 3}>
            <AttributeText attribute={addressStreet2Attr(poc1_address_street2)}>
              {addressStreet2Attr.label.default}
            </AttributeText>
          </Column>
          <Column size={1 / 3}>
            <AttributeText attribute={addressCityAttr(poc1_address_city)}>
              {addressCityAttr.label.default}
            </AttributeText>
          </Column>
        </Row>
        <Row margin>
          <Column size={1 / 2}>
            <AttributeText attribute={addressZipAttr(poc1_address_zip)}>
              {addressZipAttr.label.default}
            </AttributeText>
          </Column>
          <Column size={1 / 2}>
            <AttributeText attribute={addressStateAttr(poc1_address_state)}>
              {addressStateAttr.label.default}
            </AttributeText>
          </Column>
        </Row>
        <Row>
          <Column>
            <AttributeText attribute={notesAttr(poc1_notes)}>
              {notesAttr.label.default}
            </AttributeText>
          </Column>
        </Row>
      </Content>
    )}
  </Card>
);

Poc1.propTypes = {
  poc1: PropTypes.string,
  poc1_address_street1: PropTypes.string,
  poc1_address_street2: PropTypes.string,
  poc1_address_city: PropTypes.string,
  poc1_address_zip: PropTypes.string,
  poc1_address_state: PropTypes.string,
  poc1_email: PropTypes.string,
  poc1_phone_number: PropTypes.string,
  poc1_notes: PropTypes.string,
  onEdit: PropTypes.func,
};

export default Poc1;
