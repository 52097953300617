import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Containers
import TicketsContainer from '../../../ticket/containers/TicketsContainer/TicketsContainer';

class DriverTicketsContainer extends Component {
  static propTypes = {
    driver: PropTypes.object,
  };

  shouldUpdateOnEvent = (ticket) => {
    const {driver} = this.props;
    return (
      !!ticket &&
      !!ticket.rental &&
      !!ticket.rental.driver &&
      ticket.rental.driver.id === driver.id
    );
  };

  render() {
    const {driver} = this.props;
    return (
      <TicketsContainer
        query={{rental__driver: driver.id}}
        shouldUpdateOnEvent={this.shouldUpdateOnEvent}
      />
    );
  }
}

export default connect((state) => ({driver: state.driver.driver}))(
  DriverTicketsContainer
);
