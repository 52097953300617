import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

export default (rentalID, end_datetime = null) =>
  f3tch(`/dispatch/rentals/${rentalID}/rent_credit_amount/`)
    .profile(profile())
    .body({end_datetime})
    .post();
