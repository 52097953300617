import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

// Components
import MedallionOwnersLayout from '../../components/MedallionOwnersLayout/MedallionOwnersLayout';

// MedallionOwnersDashboard
import MedallionOwnersDashboardPage from '../../pages/MedallionOwnersDashboardPage/MedallionOwnersDashboardPage';
import routeMedallionOwnersDashboard from '../../pages/MedallionOwnersDashboardPage/route';

// NewMedallionOwner
import NewMedallionOwnerPage from '../../pages/NewMedallionOwnerPage/NewMedallionOwnerPage';
import routeNewMedallionOwner from '../../pages/NewMedallionOwnerPage/route';

// MedallionOwner
import MedallionOwnerPage from '../../pages/MedallionOwnerPage/MedallionOwnerPage';
import routeMedallionOwner from '../../pages/MedallionOwnerPage/route';

class MedallionOwnersLayoutContainer extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    list: PropTypes.array,
    history: PropTypes.object,
    location: PropTypes.object,
  };

  onAdd = () => this.props.history.push(routeNewMedallionOwner());

  onMedallion = (id) => () => this.props.history.push(routeMedallionOwner(id));

  medallionOwners = () =>
    [...this.props.list].map(({id, name}) => ({
      key: id,
      label: name,
      selected: this.props.location.pathname === routeMedallionOwner(id),
      onClick: this.onMedallion(id),
    }));

  render() {
    const {loading} = this.props;
    return (
      <MedallionOwnersLayout
        loading={loading}
        medallionOwners={this.medallionOwners()}
        onAdd={this.onAdd}
      >
        <Switch>
          <Route
            exact
            path={routeMedallionOwnersDashboard()}
            component={MedallionOwnersDashboardPage}
          />
          <Route
            exact
            path={routeNewMedallionOwner()}
            component={NewMedallionOwnerPage}
          />
          <Route
            exact
            path={routeMedallionOwner()}
            component={MedallionOwnerPage}
          />
        </Switch>
      </MedallionOwnersLayout>
    );
  }
}

export default withRouter(
  connect((state) => ({...state.medallionOwner}))(
    MedallionOwnersLayoutContainer
  )
);
