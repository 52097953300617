import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/pdf.profile.api';

// Url
import url from '../constants/url.constants.pdf';

export default async (html, options = {}) =>
  f3tch(`${url}/`).profile(profile()).body({html, options}).post();
