// react
import React, {Component} from 'react';

// propTypes
import PropTypes from 'prop-types';

// uuid
import {v4} from 'uuid';

// api
import listVehiclesApi from '../../api/list.api.vehicle';

// components
import SelectVehiclesModal from '../../components/SelectVehiclesModal/SelectVehiclesModal';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// libs
import {lib} from '@matthahn/sally-ui';
import sortQuery from '../../../api/lib/sortQuery.lib.api';

// vehicle events
import selectedVehicleEvent from '../../events/selected.event.vehicle';
import showVehicleSelectModalEvent from '../../events/showSelectModal.event.vehicle';

// alert
const {alert} = lib;

class SelectVehiclesContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
  };

  static OPTIONS = {
    perPage: 30,
  };

  static DEFAULT_SORT = {
    key: 'vin',
    direction: 'asc',
  };

  static DEFAULT_STATE = {
    eventId: v4(),
    loading: false,
    more: false,
    multiple: false,
    page: 1,
    query: {},
    search: '',
    selectedVehicles: [],
    sort: {...this.constructor.DEFAULT_SORT},
    vehicles: [],
    visible: false,
  };

  state = {
    ...this.constructor.DEFAULT_STATE,
  };

  apiID = v4();

  componentDidMount() {
    this.props.subscribe(showVehicleSelectModalEvent.sub(this.show));
  }

  show = ({multiple = false, query = {}, search = '', eventId} = {}) => {
    const actualEventId = eventId || v4();
    this.setState(
      {
        ...this.constructor.DEFAULT_STATE,
        eventId: actualEventId,
        multiple,
        query,
        search,
        visible: true,
      },
      () => this.getVehicles({search, page: 1})
    );
  };

  hide = () => {
    const {loading} = this.state;
    if (loading) return;
    this.setState({visible: false});
  };

  loadMore = () => this.getVehicles({page: this.state.page + 1});

  search = (search) => this.getVehicles({search, page: 1});

  sort = (sort) => this.getVehicles({sort, page: 1});

  getVehicles = async ({
    page = this.state.page,
    query = this.state.query,
    search = this.state.search,
    sort = this.state.sort,
  } = {}) => {
    const apiID = v4();
    this.apiID = apiID;
    const {perPage} = this.constructor.OPTIONS;

    const reset = page === 1;

    this.setState({
      loading: true,
      vehicles: reset ? [] : this.state.vehicles,
      page,
      sort,
    });

    const apiQuery = {
      ...query,
      ...sortQuery(sort || this.constructor.DEFAULT_SORT),
      limit: perPage,
      offset: (page - 1) * perPage,
    };

    if (!!search) apiQuery.search = search;

    try {
      const {results, next} = await listVehiclesApi(apiQuery);
      if (this.apiID !== apiID) return;
      this.setState({
        vehicles: [...this.state.vehicles, ...results],
        loading: false,
        more: !!next,
      });
    } catch (error) {
      if (this.apiID !== apiID) return;
      this.setState({loading: false, more: false});
    }
  };

  selectVehicle = (vehicle) => () => {
    const {eventId, loading, multiple, selectedVehicles} = this.state;
    if (loading) return;
    if (!multiple) {
      selectedVehicleEvent.pub({
        eventId,
        vehicles: [vehicle],
      });
      this.setState({visible: false});
      return;
    }
    const isAlreadySelected = selectedVehicles.some(
      ({id}) => id === vehicle.id
    );
    const updatedSelectedVehicles = isAlreadySelected
      ? selectedVehicles.filter(({id}) => id !== vehicle.id)
      : [...selectedVehicles, vehicle];
    this.setState({selectedVehicles: updatedSelectedVehicles});
  };

  clearSelectedVehicles = () => {
    if (this.state.loading) return;
    this.setState({selectedVehicles: []});
  };

  confirmSelectedVehicles = () => {
    const {eventId, selectedVehicles} = this.state;
    if (!selectedVehicles.length)
      return alert.warning('Select at least one vehicle');
    selectedVehicleEvent.pub({
      eventId,
      vehicles: selectedVehicles,
    });
    this.setState({visible: false});
  };

  render() {
    const {loading, more, search, selectedVehicles, sort, vehicles, visible} =
      this.state;
    return (
      <SelectVehiclesModal
        loading={loading}
        more={more}
        onClear={this.clearSelectedVehicles}
        onClose={this.hide}
        onConfirm={this.confirmSelectedVehicles}
        onLoadMore={this.loadMore}
        onSearch={this.search}
        onSelect={this.selectVehicle}
        onSort={this.sort}
        search={search}
        selectedVehicles={selectedVehicles}
        sort={sort}
        vehicles={vehicles}
        visible={visible}
      />
    );
  }
}

export default subscriptionHOC(SelectVehiclesContainer);
