const combineDispatchAlerts = ({vehicle_alerts_unresolved, active_rentals}) => {
  const vehicleAlerts = !!vehicle_alerts_unresolved?.length
    ? [...vehicle_alerts_unresolved]
    : [];
  const driverAlerts = !!active_rentals
    ? [...active_rentals].reduce(
        (combined, rental) =>
          !!rental?.driver?.driver_alerts_unresolved?.length
            ? [...combined, ...rental?.driver?.driver_alerts_unresolved]
            : combined,
        []
      )
    : [];
  return [...vehicleAlerts, ...driverAlerts];
};

export default combineDispatchAlerts;
