import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {string} from '../../types';

export default attribute({
  type: TEXT,
  attribute: 'dmv_image_uri',
  api: string,
  label: {
    default: 'DMV Image URI',
    short: 'Image',
  },
});
