import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Api
import listTicketsApi from '../../api/list.api.ticket';

// Action
import {hide as hideAct} from './redux/actions';
import {show as showTicketModalAct} from '../TicketContainer/redux/actions';

// Components
import SearchTicketModal from '../../components/SearchTicketModal/SearchTicketModal';

// Lib
import {lib} from '@matthahn/sally-ui';
import parseError from '../../../error/parseError';

// Alerts
const {alert} = lib;

class SearchTicketContainer extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    dispatch: PropTypes.func,
  };

  state = {
    loading: false,
    search: '',
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.visible && this.props.visible) this.init();
  }

  inputDom = null;

  init = () => {
    this.setState({search: ''});
    this.focusOnInput();
  };

  focusOnInput = () => {
    const {visible} = this.props;
    if (!visible || !this.inputDom) return;
    setTimeout(() => {
      this.inputDom.focus();
    }, 300);
  };

  onClose = () => {
    if (this.state.loading) return;
    this.props.dispatch(hideAct());
  };

  onSearch = (search) => {
    if (this.state.loading) return;
    this.setState({search});
  };

  search = async () => {
    const {dispatch} = this.props;
    const {loading, search} = this.state;

    if (loading) return;

    this.setState({loading: true});

    try {
      const {results} = await listTicketsApi({summons_number: search});
      const ticket = [...results].find(
        ({summons_number}) => summons_number === search
      );
      if (!ticket) throw new Error(`Ticket ${search} does not exist`);
      dispatch(hideAct());
      dispatch(showTicketModalAct(ticket));
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
    }

    this.setState({loading: false});
  };

  onInputDom = (inputDom) => {
    this.inputDom = inputDom;
  };

  render() {
    const {visible} = this.props;
    const {loading, search} = this.state;
    return (
      <SearchTicketModal
        visible={visible}
        loading={loading}
        search={search}
        onInputDom={this.onInputDom}
        onChange={this.onSearch}
        onClose={this.onClose}
        onSearch={this.search}
      />
    );
  }
}

export default connect((state) => ({...state.searchTicket}))(
  SearchTicketContainer
);
