import React from 'react';
import PropTypes from 'prop-types';

// Components
import {TextColor} from '@matthahn/sally-ui';

// Lib
import highlight from '../../lib/highlight.lib.ticket';

const HighlightTicket = ({children, ticket}) => (
  <TextColor theme={highlight(ticket) ? 'red' : 'black'}>{children}</TextColor>
);

HighlightTicket.propTypes = {
  children: PropTypes.node,
  ticket: PropTypes.object,
};

export default HighlightTicket;
