import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

// Events
import updatedEvent from '../events/updated.event.ticket';
import removedEvent from '../events/removed.event.ticket';

export default async (id, ticket) => {
  const response = await f3tch(`/tickets/${id}/`)
    .profile(profile())
    .body(ticket)
    .patch();
  const event = response.removed ? removedEvent : updatedEvent;
  event.pub(response);
  return response;
};
