import React, {Component} from 'react';
import PropTypes from 'prop-types';

// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// driver actions
import {set as setDriverAction} from '../../redux/actions';

// driver api
import updateDriverApi from '../../api/update.api.driver';

// driver attributes
import preferredPaymentWayAttribute from '../../attributes/preferred_payment_way.attribute.driver';

// driver components
import DriverAutoPayout from '../../components/DriverAutoPayout/DriverAutoPayout';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// redux
import {connect} from 'react-redux';

class DriverAutoPayoutContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    driver: PropTypes.object,
  };

  state = {
    loading: false,
    preferred_payment_way: preferredPaymentWayAttribute('disabled'),
  };

  componentDidMount() {
    this.mounted = true;
    this.init();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  init = () => {
    const {driver} = this.props;
    this.setState({
      preferred_payment_way: preferredPaymentWayAttribute(
        driver?.preferred_payment_way || 'disabled'
      ),
    });
  };

  change = (value, key) => {
    if (this.state.loading) return;
    this.setState({[key]: value});
  };

  save = async (preferredPaymentWay) => {
    const {dispatch, driver} = this.props;
    const {loading, preferred_payment_way: currentPreferredPaymentWay} =
      this.state;

    if (
      loading ||
      currentPreferredPaymentWay.api.format() ===
        preferredPaymentWay.api.format()
    )
      return;
    this.setState({loading: true, preferred_payment_way: preferredPaymentWay});

    const preferred_payment_way =
      (preferredPaymentWay.api.format() === 'disabled'
        ? null
        : preferredPaymentWay.api.format()) || null;

    try {
      const updatedDriver = await updateDriverApi(driver.id, {
        preferred_payment_way,
      });
      if (!this.mounted) return;
      dispatch(setDriverAction({driver: updatedDriver}));
    } catch (error) {
      if (!this.mounted) return;
      const {message} = parseError(error);
      alert.error(message);
    }

    this.setState({loading: false});
  };

  render() {
    const {loading, preferred_payment_way} = this.state;
    return (
      <DriverAutoPayout
        loading={loading}
        onChange={this.save}
        option={preferred_payment_way}
      />
    );
  }
}

export default connect((state) => ({driver: state.driver.driver}))(
  DriverAutoPayoutContainer
);
