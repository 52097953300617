import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

// ach actions
import {set as setAchAction} from '../../../ach/redux/actions';

// ach api
import approveAchsApi from '../../../ach/api/approve.api.ach';
import rejectAchsApi from '../../../ach/api/reject.api.ach';

// cheque actions
import {set as setChequeAction} from '../../../cheque/redux/actions';

// cheque api
import approveChequesApi from '../../../cheque/api/approve.api.cheque';
import rejectChequesApi from '../../../cheque/api/reject.api.cheque';

// cheque permissions
import approvePaymentPermission from '../../../cheque/permissions/approve.permission.check';
import cancelPaymentPermission from '../../../cheque/permissions/cancel.permission.check';

// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// driver routes
import driverRoute from '../../../driver/pages/DriverPage/route';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// lib
import sum from '../../../lib/sum';
import downloadFile from '../../../lib/downloadFile';
import fkOrId from '../../../lib/fkOrId';

// payment components
import ApprovePaymentsCard from '../../components/ApprovePaymentsCard/ApprovePaymentsCard';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';

// payment lib
import groupPaymentsByType from '../../lib/groupByType.lib.payment';
import isPaymentSelected from '../../lib/isSelected.lib.payment';
import parsePayments from '../../lib/parsePayments.lib.payment';

// payout actions
import {show as showPayoutAct} from '../../../payout/containers/PayoutDriverContainer/redux/actions';

// payout api
import downloadPayoutReportApi from '../../../payout/api/download.api.payout';

// redux
import {connect} from 'react-redux';

// router
import {withRouter} from 'react-router-dom';

// types
import {amount as amountType, date} from '../../../types';

class ApprovePaymentsContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    dwollaBalance: PropTypes.number,
    failedPayoutAlerts: PropTypes.array,
    history: PropTypes.object,
    loadingBalance: PropTypes.bool,
    loadingFailedPayoutAlerts: PropTypes.bool,
    loadingPendingAchs: PropTypes.bool,
    loadingPendingCheques: PropTypes.bool,
    pendingAchs: PropTypes.array,
    pendingCheques: PropTypes.array,
  };

  state = {
    approving: false,
    rejecting: false,
    search: '',
    selected: [],
    showConfirm: false,
    confirmationAction: null,
    confirmSum: 0,
    downloading: [],
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  isLoading = () => {
    const {
      loadingFailedPayoutAlerts,
      loadingPendingAchs,
      loadingPendingCheques,
    } = this.props;
    const loading = [
      loadingFailedPayoutAlerts,
      loadingPendingAchs,
      loadingPendingCheques,
    ].some((loading) => loading);
    return loading;
  };

  payments = () => {
    const {failedPayoutAlerts, pendingAchs, pendingCheques} = this.props;
    const {search} = this.state;
    const payments = parsePayments({
      achs: pendingAchs,
      alerts: failedPayoutAlerts,
      cheques: pendingCheques,
    });
    return !!search.trim().length
      ? [...payments].filter((payment) =>
          payment.search.some((query) =>
            `${query}`.toLowerCase().includes(search.toLowerCase())
          )
        )
      : payments;
  };

  selectablePayments = () =>
    [...this.payments()].filter(({type}) => type !== 'alert');

  onSearch = (search) => {
    if (this.isLoading()) return;
    this.setState({search, selected: []});
  };

  onSelect = (payment) => () => {
    if (this.isLoading()) return;
    const {selected} = this.state;
    const updatedSelected = isPaymentSelected({payment, payments: selected})
      ? [...this.state.selected].filter(
          (selectedPayment) => selectedPayment.computedId !== payment.computedId
        )
      : [...selected, {...payment}];
    this.setState({selected: updatedSelected});
  };

  allSelected = () => {
    const {selected} = this.state;
    return this.selectablePayments().length === selected.length;
  };

  onSelectAll = () => {
    if (this.isLoading()) return;
    const selected = this.allSelected() ? [] : [...this.selectablePayments()];
    this.setState({selected});
  };

  onDriver = (driver) => () => this.props.history.push(driverRoute(driver.id));

  showConfirmation = (confirmationAction) => () => {
    const {selected} = this.state;
    if (this.isLoading()) return;
    if (!selected.length)
      return alert.warning(`Please select payments to ${confirmationAction}`);
    const confirmSum = sum(...[...selected].map(({amount}) => amount));
    this.setState({
      showConfirm: true,
      confirmSum,
      confirmationAction,
    });
  };

  hideConfirmation = () => this.setState({showConfirm: false});

  onAction = () => {
    const {selected, confirmationAction} = this.state;
    if (this.isLoading() || !confirmationAction) return;

    if (!selected.length)
      return alert.warning(`Please select payments to ${confirmationAction}`);

    switch (confirmationAction) {
      case 'approve':
        return this.approve();
      case 'reject':
        return this.reject();

      default:
        break;
    }
  };

  onChange = (val, key) => {
    if (this.isLoading()) return;
    this.setState({[key]: val});
  };

  approve = async () => {
    const {selected} = this.state;
    if (this.isLoading()) return;
    if (!selected.length) return alert.warning('Please select achs to approve');
    if (!approvePaymentPermission())
      return alert.warning('You do not have permission to approve payments');
    this.setState({approving: true});
    this.pauseEventsFor = [...selected];
    try {
      const {achs, cheques} = groupPaymentsByType(selected);
      await Promise.all([this.approveAch(achs), this.approveCheques(cheques)]);
      if (!this.mounted) return;
      alert.success('Payments approved');
      this.setState({
        selected: [],
        showConfirm: false,
        approving: false,
      });
    } catch (error) {
      if (!this.mounted) return;
      const {message} = parseError(error);
      alert.error(message);
      this.setState({approving: false});
    }

    this.pauseEventsFor = [];
  };

  approveAch = async (achs) => {
    const {dispatch, pendingAchs} = this.props;
    if (!achs.length) return;
    await approveAchsApi([...achs].map(({id}) => ({id})));
    dispatch(
      setAchAction({
        pendingAchs: [...pendingAchs].filter(
          (ach) =>
            !isPaymentSelected({
              payment: ach,
              payments: achs,
              attributeKey: 'id',
            })
        ),
      })
    );
    this.setState({
      selected: [...this.state.selected].filter(
        (selectedPayment) =>
          ![...achs].find(
            (ach) => ach.computedId === selectedPayment.computedId
          )
      ),
    });
  };

  approveCheques = async (cheques) => {
    const {dispatch, pendingCheques} = this.props;
    if (!cheques.length) return;
    await approveChequesApi([...cheques].map(({id}) => ({id})));
    dispatch(
      setChequeAction({
        pendingCheques: [...pendingCheques].filter(
          (cheque) =>
            !isPaymentSelected({
              payment: cheque,
              payments: cheques,
              attributeKey: 'id',
            })
        ),
      })
    );
    this.setState({
      selected: [...this.state.selected].filter(
        (selectedPayment) =>
          ![...cheques].find(
            (cheque) => cheque.computedId === selectedPayment.computedId
          )
      ),
    });
  };

  reject = async () => {
    const {selected} = this.state;
    if (this.isLoading()) return;
    if (!selected.length)
      return alert.warning('Please select payments to reject');
    if (!cancelPaymentPermission())
      return alert.warning('You do not have permission to reject payments');
    this.setState({rejecting: true});
    this.pauseEventsFor = [...selected];
    try {
      const {achs, cheques} = groupPaymentsByType(selected);
      await Promise.all([this.rejectAch(achs), this.rejectCheques(cheques)]);
      if (!this.mounted) return;
      alert.success('Payments rejected');
      this.setState({
        selected: [],
        rejecting: false,
        showConfirm: false,
      });
    } catch (error) {
      if (!this.mounted) return;
      const {message} = parseError(error);
      alert.error(message);
      this.setState({rejecting: false});
    }
    this.pauseEventsFor = [];
  };

  rejectAch = async (achs) => {
    const {dispatch, pendingAchs} = this.props;
    if (!achs.length) return;
    await rejectAchsApi([...achs].map(({id}) => ({id})));
    dispatch(
      setAchAction({
        pendingAchs: [...pendingAchs].filter(
          (ach) =>
            !isPaymentSelected({
              payment: ach,
              payments: achs,
              attributeKey: 'id',
            })
        ),
      })
    );
    this.setState({
      selected: [...this.state.selected].filter(
        (selectedPayment) =>
          ![...achs].find(
            (ach) => ach.computedId === selectedPayment.computedId
          )
      ),
    });
  };

  rejectCheques = async (cheques) => {
    const {dispatch, pendingCheques} = this.props;
    if (!cheques.length) return;
    await rejectChequesApi([...cheques].map(({id}) => ({id})));
    dispatch(
      setChequeAction({
        pendingCheques: [...pendingCheques].filter(
          (cheque) =>
            !isPaymentSelected({
              payment: cheque,
              payments: cheques,
              attributeKey: 'id',
            })
        ),
      })
    );
    this.setState({
      selected: [...this.state.selected].filter(
        (selectedPayment) =>
          ![...cheques].find(
            (cheque) => cheque.computedId === selectedPayment.computedId
          )
      ),
    });
  };

  dispatchAction = (payment) => () => {
    const action = payment.type === 'alert' ? this.payout : this.report;
    action(payment);
  };

  report = async (payment) => {
    const {downloading} = this.state;
    if (downloading.includes(payment.computedId)) return;

    this.setState({downloading: [...downloading, payment.computedId]});

    try {
      const file = await downloadPayoutReportApi(fkOrId(payment.payout));
      downloadFile(
        file,
        `${payment.driver.first_name} ${payment.driver.last_name} - ${date(
          payment.created_at
        ).format()}.pdf`
      );
    } catch (error) {
      alert.error('Could not generate a statement');
    }

    if (!this.mounted) return;
    this.setState({
      downloading: [...this.state.downloading].filter(
        (d) => d !== payment.computedId
      ),
    });
  };

  payout = (alert) => {
    const {dispatch} = this.props;
    dispatch(
      showPayoutAct({
        driver: !!alert?.driver?.id ? {...alert.driver} : {id: alert.driver},
        endRental: false,
      })
    );
  };

  render() {
    const {loadingBalance, dwollaBalance} = this.props;
    const {
      approving,
      rejecting,
      downloading,
      selected,
      search,
      showConfirm,
      confirmSum,
      confirmationAction,
    } = this.state;
    return (
      <Fragment>
        <ApprovePaymentsCard
          loadingBalance={loadingBalance}
          balance={dwollaBalance}
          loading={this.isLoading()}
          approving={approving}
          rejecting={rejecting}
          downloading={downloading}
          selected={selected}
          search={search}
          payments={this.payments()}
          onSelect={this.onSelect}
          onSelectAll={this.onSelectAll}
          onApprove={this.showConfirmation('approve')}
          onReject={this.showConfirmation('reject')}
          onSearch={this.onSearch}
          onDriver={this.onDriver}
          onAction={this.dispatchAction}
        />
        <ConfirmationModal
          loading={this.isLoading()}
          visible={showConfirm}
          numberOfAchs={selected.length}
          sum={amountType(confirmSum).format()}
          action={confirmationAction}
          onClose={this.hideConfirmation}
          onConfirm={this.onAction}
          onChange={this.onChange}
        />
      </Fragment>
    );
  }
}

export default connect((state) => ({
  ...state.ach,
  ...state.alert,
  ...state.cheque,
}))(withRouter(ApprovePaymentsContainer));
