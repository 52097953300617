const columns = [
  {
    key: 'policy_number',
    label: 'Policy Number',
    sortable: true,
  },
  {
    key: 'insurance_company_name',
    label: 'Company',
    sortable: true,
  },
  {
    key: 'insurance_company_number',
    label: 'Company Number',
    sortable: true,
  },
  {
    key: 'policy_effective_date',
    label: 'Effective Date',
    sortable: true,
  },
  {
    key: 'policy_expiration_date',
    label: 'Expiry',
    sortable: true,
  },
];

export default columns;
