import {folderTree as createFolderTree} from '../../document';

// Files
import {lease, other} from './folders';

const {folders, flatTree, foldersObject, uiDocs} = createFolderTree([
  lease(),
  other(),
]);

export {foldersObject, flatTree, uiDocs};

export default folders;
