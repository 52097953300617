import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal, Row, Column, Select, Text} from '@matthahn/sally-ui';

// Types
import {dateTime, amount} from '../../../types';

const ReassignTollToVehicleModal = ({
  visible,
  canAssign,
  loading,
  toll,
  vehicle,
  vehicles,
  onVehicle,
  onClose,
  onAssign,
}) => (
  <Modal
    title="Reassign Toll to different Vehicle"
    icon="car"
    visible={visible}
    onClose={onClose}
    buttonsRight={[
      {label: 'Assign', loading, onClick: onAssign, theme: 'orange'},
    ]}
    size="large"
  >
    {(Content) => (
      <Content>
        <Row margin />
        <Row margin>
          <Column size={1 / 2} offset={1 / 4}>
            <Select
              value={vehicle}
              options={vehicles}
              disabled={loading || !canAssign}
              onChange={onVehicle}
              limitOptions={10}
              size="large"
            >
              Select a Vehicle
            </Select>
          </Column>
        </Row>
        <Row margin />
        {!!toll && (
          <Fragment>
            <Row margin>
              <Column size={1 / 3}>
                <Text value={dateTime(toll.transaction_date).format()}>
                  Date
                </Text>
              </Column>
              <Column size={1 / 3}>
                <Text value={toll.vehicle_medallion || '-'}>Medallion</Text>
              </Column>
              <Column size={1 / 3}>
                <Text value={toll.vehicle_plate || '-'}>License Plate</Text>
              </Column>
            </Row>
            <Row>
              <Column size={1 / 3}>
                <Text value={toll.plate}>Tag ID</Text>
              </Column>
              <Column size={1 / 3}>
                <Text value={`$${amount(toll.amount).format()}`}>Amount</Text>
              </Column>
              <Column size={1 / 3}>
                <Text value={toll.agency}>Agency</Text>
              </Column>
            </Row>
          </Fragment>
        )}
      </Content>
    )}
  </Modal>
);

ReassignTollToVehicleModal.propTypes = {
  visible: PropTypes.bool,
  canAssign: PropTypes.bool,
  loading: PropTypes.bool,
  vehicle: PropTypes.string,
  vehicles: PropTypes.array,
  toll: PropTypes.object,
  onVehicle: PropTypes.func,
  onClose: PropTypes.func,
  onAssign: PropTypes.func,
};

export default ReassignTollToVehicleModal;
