import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {show as showPayoutDriverModalAct} from '../../../payout/containers/PayoutDriverContainer/redux/actions';

// Components
import {DropDown, Menu} from '@matthahn/sally-ui';
import DriverTakeDeposit from '../DriverTakeDeposit/DriverTakeDeposit';

class DriverDepositBalanceContainer extends Component {
  static propTypes = {
    driver: PropTypes.object,
    children: PropTypes.func.isRequired,
    dispatch: PropTypes.func,
  };

  state = {
    dropdown: false,
    takeNow: false,
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  apiID = null;

  depositBalance = () => {
    const {driver} = this.props;
    return !!driver ? driver.deposit_balance : 0;
  };

  options = () =>
    [
      {
        id: 'now',
        label: 'Take Deposit',
        icon: 'creditcard',
        onClick: () => this.showTakeNow(),
      },
      this.props.hold || this.props.driver.deposit_balance <= 0
        ? null
        : {
            id: 'allocate',
            label: 'Allocate Deposit',
            icon: 'swap',
            onClick: () => this.dispatchPayout(),
          },
    ].filter((opt) => !!opt);

  dispatchPayout = () => {
    const {dispatch, driver} = this.props;
    if (!driver) return;
    this.hideDropdown();
    this.hideTakeNow();
    dispatch(
      showPayoutDriverModalAct({
        driver: driver,
        depositPayout: true,
      })
    );
  };

  showDropdown = () => this.setState({dropdown: true});

  hideDropdown = () => this.setState({dropdown: false});

  showTakeNow = () => this.setState({takeNow: true});

  hideTakeNow = () => this.setState({takeNow: false});

  render() {
    const {children, driver} = this.props;
    const {dropdown, takeNow} = this.state;
    return (
      <Fragment>
        <DropDown
          visible={dropdown}
          onOpen={this.showDropdown}
          onClose={this.hideDropdown}
          trigger={children({deposit_balance: this.depositBalance()})}
          size={235}
          block
        >
          <Menu options={this.options()} />
        </DropDown>

        <DriverTakeDeposit
          visible={takeNow}
          deposit_balance={this.depositBalance()}
          driver={driver}
          onClose={this.hideTakeNow}
        />
      </Fragment>
    );
  }
}

export default connect((state) => ({
  driver: state.driver.driver,
}))(DriverDepositBalanceContainer);
