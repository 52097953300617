import styled from 'styled-components';

// colors
// import {grey} from '@matthahn/sally-ui/lib/libs/colors';

const InputContainer = styled.div`
  width: 100%;
  flex: 1;
  ${
    '' /* border-right: 1px solid ${grey};
  margin-right: 10px; */
  }
`;

export default InputContainer;
