import {fileName, folder} from '../../../document';

// Attributes
import effectiveAttr from '../../../document/attributes/effective_date.attribute.document';

export default folder({
  attributes: [effectiveAttr],
  defaultFileName: 'Lease Contract',
  fileAttributes: ['effective_date'],
  // CONTRACT - DRIVER IDs - EFFECTIVE DATE
  fileName: fileName(
    ({driver, effective_date}) =>
      `CONTRACT - ${driver.first_name}_${
        driver.last_name
      } - ${effective_date.display.format()}`
  ),
  initAttributes: ({attributes}) =>
    [...attributes].map((attr) =>
      attr.attribute === 'effective_date'
        ? attr(attr(new Date()).api.format())
        : attr('')
    ),
  folderName: 'Contracts',
  type: 'contract',
  uploadable: false,
});
