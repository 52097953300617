import {startOfWeek} from 'date-fns';

import {DATETIME} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {dateTime, apiDateTime} from '../../types';

export default attribute({
  type: DATETIME,
  attribute: 'end_datetime',
  display: dateTime,
  input: dateTime,
  api: apiDateTime,
  label: {
    default: 'End Date',
    short: 'End',
  },
  disabledDates: [
    {before: startOfWeek(new Date(), {weekStartsOn: 1})},
    {after: new Date()},
  ],
});
