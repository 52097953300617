import {DATE} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {date, apiDate} from '../../types';

export default attribute({
  type: DATE,
  attribute: 'date_of_loss',
  display: date,
  input: date,
  api: apiDate,
  label: {
    default: 'Date of Loss',
    short: 'DOL',
  },
});
