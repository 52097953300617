import {Component} from 'react';
import PropTypes from 'prop-types';

// actions
import {set as setAction} from '../../redux/actions';

// api
import api from '../../../api/lib/getEverythingFromApi.lib.api';
import listChequesApi from '../../api/list.api.cheque';

// event HOCs
import subscriptionHoc from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// lib
import {isNull} from '../../../api/queries/queries';

// redux
import {connect} from 'react-redux';

// sockets
import chequeApprovedSocket from '../../sockets/approved.socket.cheque';
import chequeRejectedSocket from '../../sockets/canceled.socket.cheque';

class ChequesPendingApprovalSync extends Component {
  static propTypes = {
    children: PropTypes.node,
    dispatch: PropTypes.func,
    loadingPendingCheques: PropTypes.bool,
    pendingCheques: PropTypes.array,
    subscribe: PropTypes.func,
  };

  static defaultProps = {
    children: null,
  };

  componentDidMount() {
    this.subscribe();
    this.getCheques();
  }

  subscribe = () => {
    this.props.subscribe(
      chequeApprovedSocket.subscribe(this.removeChequeFromList),
      chequeRejectedSocket.subscribe(this.removeChequeFromList)
    );
  };

  removeChequeFromList = (cheque) => {
    const {pendingCheques, dispatch} = this.props;
    dispatch(
      setAction({
        pendingCheques: [...pendingCheques].filter(
          ({check_number}) => check_number !== cheque.check_number
        ),
      })
    );
  };

  getCheques = async () => {
    const {dispatch} = this.props;

    dispatch(setAction({loadingPendingCheques: true}));

    try {
      const {data: pendingCheques} = await api(listChequesApi, {
        [isNull('approved_by_user')]: true,
        [isNull('canceled_on')]: true,
        ordering: 'created_at',
      });
      dispatch(
        setAction({
          loadingPendingCheques: false,
          pendingCheques,
        })
      );
    } catch (error) {
      dispatch(setAction({loadingPendingCheques: false, pendingCheques: []}));
    }
  };

  render() {
    const {children} = this.props;
    return children || null;
  }
}

export default connect((state) => ({...state.cheque}))(
  subscriptionHoc(ChequesPendingApprovalSync)
);
