import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal, Table, TextColor} from '@matthahn/sally-ui';
import NegativeParenthesis from '../../../layout/components/NegativeParenthesis/NegativeParenthesis';

// Columns
import columns from './columns';

const DriverBalanceModal = ({
  loading,
  visible,
  balance,
  transactions,
  onClose,
  onTransaction,
}) => (
  <Modal
    visible={visible}
    onClose={onClose}
    title="Balance Overview"
    icon="areachart"
  >
    {(Content) => (
      <Content padding="none">
        <Table
          columns={columns}
          loading={loading}
          noBorder
          noRadius
          smallNoResultsLabel
          theme="orange"
        >
          {(TableRow) =>
            [
              ...transactions.map((transaction) => (
                <TableRow
                  key={transaction.id}
                  onClick={onTransaction(transaction)}
                >
                  {(TableColumn) => [
                    <TableColumn key={'type'}>{transaction.label}</TableColumn>,
                    <TableColumn key={'balance'}>
                      <NegativeParenthesis
                        pre="$"
                        value={transaction.balance}
                        wrap={!transaction.owed_to_sally}
                      />
                    </TableColumn>,
                  ]}
                </TableRow>
              )),
              <TableRow key="total">
                {(TableColumn) => [
                  <TableColumn key="total">
                    <TextColor weight="bold">TOTAL</TextColor>
                  </TableColumn>,
                  <TableColumn key="totalAmount">
                    <NegativeParenthesis pre="$" value={balance} />
                  </TableColumn>,
                ]}
              </TableRow>,
            ].filter((row) => !!row)
          }
        </Table>
      </Content>
    )}
  </Modal>
);

DriverBalanceModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  ontransaction: PropTypes.func,
  balance: PropTypes.any,
  credit: PropTypes.number,
  transactions: PropTypes.array,
  loading: PropTypes.bool,
};

export default DriverBalanceModal;
