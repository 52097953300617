import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import styled from 'styled-components';

// Actions
import {show as showExternalDevicesAct} from '../../../externalDevice/redux/actions';

// Components
import {Signature, Row, Column, Button} from '@matthahn/sally-ui';

// Helper Components
const SignatureContainer = styled.div`
  width: 100%;
`;

class DocumentSignatureContainer extends Component {
  static propTypes = {
    image: PropTypes.string,
    onSign: PropTypes.func,
    dispatch: PropTypes.func,
  };

  state = {
    generating: false,
    signature: null,
  };

  componentDidMount() {
    const {image} = this.props;
    this.mounted = true;
    if (!!image) this.setState({signature: image});
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  sign = (signature) => {
    const {generating} = this.state;
    if (generating) return;
    if (!this.mounted) return;
    this.setState({signature});
    this.props.onSign(signature);
  };

  openOnDevice = () => {
    const {dispatch} = this.props;
    dispatch(
      showExternalDevicesAct({
        extension: 'signature',
        handler: this.handleExternalSignature,
      })
    );
  };

  handleExternalSignature = ({signature}) => {
    this.setState({signature});
    this.props.onSign(signature);
  };

  resetSignature = () => {
    this.setState({signature: null});
    this.props.onSign(null);
  };

  render() {
    const {generating, signature} = this.state;
    return (
      <Fragment>
        <Row margin={!generating}>
          <Column>
            <SignatureContainer>
              <Signature
                image={generating}
                signature={signature}
                onSign={this.sign}
              />
            </SignatureContainer>
          </Column>
        </Row>
        {!generating && (
          <Row>
            <Column>
              <Button theme="grey" icon="tablet1" onClick={this.openOnDevice}>
                Sign on tablet
              </Button>
            </Column>
          </Row>
        )}
      </Fragment>
    );
  }
}

export default connect()(DocumentSignatureContainer);
