import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

export default attribute({
  type: TEXT,
  attribute: 'summons_number',
  label: {
    default: 'Summons Number',
    short: 'Summons Number',
  },
});
