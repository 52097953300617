export default [
  'email',
  'first_name',
  'last_name',
  'phone_number',
  'address_street1',
  'address_city',
  'address_zip',
  'address_state',
];
