import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

export default ({toll = null, rental = null} = {}) =>
  f3tch('/tolls/assign_rental_to_toll/')
    .profile(profile())
    .body({toll, rental})
    .post();
