import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

// Events
import event from '../events/connectedWithMedallion.event.vehicle';

export default async (vehicleID, medallionID) => {
  const response = await f3tch(
    `/vehicles/${vehicleID}/connect_with_medallion/${medallionID}/`
  )
    .profile(profile())
    .post();
  event.pub(response);
  return response;
};
