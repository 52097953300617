import {TEXTAREA} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

export default attribute({
  type: TEXTAREA,
  attribute: 'violation',
  label: {
    default: 'Violation',
    short: 'Violation',
  },
});
