import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {Button, CheckBox, InputTyping, Table} from '@matthahn/sally-ui';
import StepFooter from '../../../layout/components/StepFooter/StepFooter';

// Columns
import columns from './columns';

// Types
import {phoneNumber} from '../../../types';

const SelectDriverStep = ({
  Container,
  search,
  driver,
  drivers,
  onDriver,
  onSearch,
  onNext,
}) => (
  <Fragment>
    <Container>
      <InputTyping
        value={search}
        onChange={onSearch}
        placeholder="Search"
        preIcon="search1"
      />
    </Container>
    <Container padding="none" noBorder>
      <Table columns={columns} noBorder noRadius smallNoResultsLabel>
        {(TableRow) =>
          [
            ...drivers.map((driverToDisplay) => (
              <TableRow
                key={driverToDisplay.id}
                onClick={onDriver(driverToDisplay)}
              >
                {(TableColumn) => [
                  <TableColumn key="name">
                    <CheckBox
                      value={driverToDisplay.id === driver?.id}
                      onChange={() => null}
                      size="small"
                    />{' '}
                    {driverToDisplay.first_name} {driverToDisplay.last_name}
                  </TableColumn>,
                  <TableColumn key="fhv">
                    {!!driverToDisplay.phone_number
                      ? phoneNumber(driverToDisplay.phone_number).format()
                      : '-'}
                  </TableColumn>,
                ]}
              </TableRow>
            )),
          ].filter((row) => !!row)
        }
      </Table>
    </Container>
    <Container>
      <StepFooter
        right={
          <Button size="small" theme="orange" onClick={onNext}>
            Next
          </Button>
        }
      />
    </Container>
  </Fragment>
);

SelectDriverStep.propTypes = {
  Container: PropTypes.any,
  search: PropTypes.string,
  driver: PropTypes.object,
  drivers: PropTypes.array,
  onDriver: PropTypes.func,
  onSearch: PropTypes.func,
  onNext: PropTypes.func,
};

export default SelectDriverStep;
