import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {show as showBlacklistModalAct} from '../BlacklistDriverContainer/redux/actions';
import {show as showEnableModalAct} from '../EnableDriverContainer/redux/actions';

// Components
import {Button} from '@matthahn/sally-ui';

// driverAlert lib
import showDriverAlertCreateModal from '../../../driverAlert/lib/showCreateModal.lib.driverAlert';

// Lib
import canBeBlacklisted from '../../lib/canBeBlacklisted.lib.driver';
import canBeEnabled from '../../lib/canBeEnabled.lib.driver';

// local components
import Container from './components/Container';

// Permissions
import blacklistPermission from '../../permissions/blacklist.permission.driver';

class DriverPaymentsSubpageContainer extends Component {
  static propTypes = {
    driver: PropTypes.object,
    dispatch: PropTypes.func,
  };

  blacklist = () => {
    const {driver, dispatch} = this.props;
    dispatch(showBlacklistModalAct(driver));
  };

  enable = () => {
    const {driver, dispatch} = this.props;
    dispatch(showEnableModalAct(driver));
  };

  showCreateAlertModal = () => {
    const {driver} = this.props;
    showDriverAlertCreateModal({driver});
  };

  render() {
    const {driver} = this.props;
    return (
      <Container>
        <Button
          theme="grey"
          icon="exclamationcircleo"
          onClick={this.showCreateAlertModal}
          size="small"
        >
          Create driver alert
        </Button>
        {canBeBlacklisted(driver) && blacklistPermission() && (
          <div>
            <Button
              theme="grey"
              size="small"
              icon="delete"
              onClick={this.blacklist}
              outline
            >
              Blacklist
            </Button>
          </div>
        )}
        {canBeEnabled(driver) && blacklistPermission() && (
          <div>
            <Button
              theme="grey"
              size="small"
              icon="check"
              onClick={this.enable}
              outline
            >
              Enable
            </Button>
          </div>
        )}
      </Container>
    );
  }
}

export default connect((state) => ({driver: state.driver.driver}))(
  DriverPaymentsSubpageContainer
);
