import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {registrationControlNumber} from '../../types';

export default attribute({
  type: TEXT,
  attribute: 'registration_control_number',
  display: registrationControlNumber,
  input: registrationControlNumber,
  api: registrationControlNumber,
  label: {
    default: 'Document Control Number',
    short: 'Control Number',
  },
});
