// Action constants
import {SET} from './constants';

const DEFAULT_STATE = {
  loadingVehicle: false,
  vehicle: null,

  loadingHold: false,
  hold: null,

  loadingNotes: false,
  notes: [],

  loadingVehicleAlerts: false,
  vehicleAlerts: [],

  loadingMileage: false,
  mileage: null,

  waitingTickets: [],

  keyLabel: null,
  loadingKeyLabel: true,

  makes: [],
  types: [],
};

export default (state = DEFAULT_STATE, {type, data}) => {
  switch (type) {
    case SET: {
      return {
        ...state,
        ...data,
      };
    }

    default: {
      return state;
    }
  }
};
