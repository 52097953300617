import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';

// Attributes
import phoneNumberAttr from '../../../driver/attributes/phone_number.attribute.driver';

// Components
import {P} from '@matthahn/sally-ui';
import SpotlightRow from '../SpotlightRow/SpotlightRow';
import Container from './components/Container';
import SearchContainer from './components/SearchContainer';
import SearchIcon from './components/SearchIcon';
import Input from './components/Input';
import Content from './components/Content';
import ContentHeader from './components/ContentHeader';
import NoResults from './components/NoResults';

class Spotlight extends Component {
  static propTypes = {
    showResults: PropTypes.bool,
    search: PropTypes.string,
    drivers: PropTypes.array,
    medallions: PropTypes.array,
    vehicles: PropTypes.array,
    onSearch: PropTypes.func,
    onEscape: PropTypes.func,
    onClose: PropTypes.func,
    onDriver: PropTypes.func,
    onMedallion: PropTypes.func,
    onVehicle: PropTypes.func,
  };

  componentDidMount() {
    if (this.props.visible) this.init();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) this.init();
  }

  init = () => setTimeout(this.focus, 150);

  focus = () => {
    if (!this.input) return;
    this.input.focus();
  };

  handleClickOutside = () => {
    this.props.onClose();
  };

  onChange = (e) => {
    this.props.onSearch(e.target.value);
  };

  onKeyDown = (e) => {
    if (e.key === 'Escape') {
      this.props.onEscape();
      e.preventDefault();
      return false;
    }
  };

  onRef = (input) => {
    this.input = input;
  };

  render() {
    const {
      showResults,
      search,
      drivers,
      medallions,
      vehicles,
      onDriver,
      onMedallion,
      onVehicle,
    } = this.props;
    return (
      <Container showResults={showResults}>
        <SearchContainer>
          <SearchIcon icon="search1" />
          <Input
            ref={this.onRef}
            value={search}
            onKeyDown={this.onKeyDown}
            onChange={this.onChange}
            placeholder="Search"
          />
        </SearchContainer>
        <Content>
          {!drivers.length && !medallions.length && !vehicles.length && (
            <NoResults>
              <P theme="darkGrey" size="2">
                No Results found
              </P>
            </NoResults>
          )}
          {!!drivers.length && (
            <Fragment>
              <ContentHeader>Drivers</ContentHeader>
              {drivers.map((driver) => (
                <SpotlightRow
                  key={driver.id}
                  title={`${driver.first_name} ${driver.last_name}`}
                  addon1={driver.fhv_license_number}
                  addon2={phoneNumberAttr(driver.phone_number).display.format()}
                  onClick={onDriver(driver)}
                />
              ))}
            </Fragment>
          )}
          {!!medallions.length && (
            <Fragment>
              <ContentHeader>Medallions</ContentHeader>
              {medallions.map((medallion) => (
                <SpotlightRow
                  key={medallion.id}
                  title={medallion.medallion_number}
                  onClick={onMedallion(medallion)}
                />
              ))}
            </Fragment>
          )}
          {!!vehicles.length && (
            <Fragment>
              <ContentHeader>Vehicles</ContentHeader>
              {vehicles.map((vehicle) => (
                <SpotlightRow
                  key={vehicle.id}
                  title={vehicle.vin}
                  onClick={onVehicle(vehicle)}
                />
              ))}
            </Fragment>
          )}
        </Content>
      </Container>
    );
  }
}

export default onClickOutside(Spotlight);
