export default [
  {
    key: 'number',
    label: 'Number',
  },
  {
    key: 'amount',
    label: 'Amount',
  },
  {
    key: 'date',
    label: 'Date',
  },
  {
    key: 'account',
    label: 'Account',
  },
  {
    key: 'driver',
    label: 'Driver',
  },
  {
    key: 'medallion',
    label: 'Medallion',
  },
  {
    key: 'report',
    label: '',
  },
];
