import numeral from 'numeral';

import type from '../type';

const format = (input) => {
  if (!input) return '';
  const numbers = `${input}`.replace(/[^0-9]/g, '');
  return !numbers ? '' : numeral(numbers.slice(0, 4)).format('0,0');
};

const validate = (value) =>
  value !== null &&
  value !== undefined &&
  `${value}`.length > 0 &&
  `${value}`.length <= 5 &&
  /(?=.)^(([1-9][0-9]{0,2}(,[0-9]{3})*)|[0-9]+)?$/.test(value);

export default type({type: 'string', format, validate});
