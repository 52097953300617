import prepare from '../../attribute/prepare.attribute';

// Validation
import amount from '../validations/amount.validation.transaction';

export default async (attributes) => {
  const charge = prepare(attributes, {
    field: 'api',
    required: ['amount', 'driver', 'subtype', 'description'],
  });
  amount(charge);
  charge.type = 'charge';
  return charge;
};
