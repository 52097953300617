import {combineReducers} from 'redux';

// Reducers
import accident from '../accident/redux/reducer';
import ach from '../ach/redux/reducer';
import alert from '../alert/redux/reducer';
import auth from '../auth/redux/reducer';
import cheque from '../cheque/redux/reducer';
import driver from '../driver/redux/reducer';
import externalDevice from '../externalDevice/redux/reducer';
import layout from '../layout/redux/reducer';
import lease from '../lease/redux/reducer';
import medallion from '../medallion/redux/reducer';
import medallionOwner from '../medallionOwner/redux/reducer';
import spotlight from '../spotlight/redux/reducer';
import ticket from '../ticket/redux/reducer';
import toll from '../toll/redux/reducer';
import transactionType from '../transactionType/redux/reducer';
import vehicle from '../vehicle/redux/reducer';

// Local Reducers
import archiveVehicle from '../vehicle/containers/ArchiveVehicleContainer/redux/reducer';
import blacklistDriver from '../driver/containers/BlacklistDriverContainer/redux/reducer';
import charge from '../transaction/containers/ChargeContainer/redux/reducer';
import contract from '../document/containers/ContractContainer/redux/reducer';
import credit from '../transaction/containers/CreditContainer/redux/reducer';
import decommissionVehicle from '../vehicle/containers/DecommissionVehicleContainer/redux/reducer';
import dispatcher from '../dispatch/containers/DispatcherContainer/redux/reducer';
import dispatchVehicleFlow from '../dispatch/containers/DispatchVehicleFlowContainer/redux/reducer';
import driverFhvCapture from '../driver/containers/DriverFhvCaptureContainer/redux/reducer';
import driverInfo from '../driver/containers/DriverInfoContainer/redux/reducer';
import enableDriver from '../driver/containers/EnableDriverContainer/redux/reducer';
import enableMedallion from '../medallion/containers/EnableMedallionContainer/redux/reducer';
import enableVehicle from '../vehicle/containers/EnableVehicleContainer/redux/reducer';
import hotswap from '../rental/containers/HotswapContainer/redux/reducer';
import medallionInfo from '../medallion/containers/MedallionInfoContainer/redux/reducer';
import newTicket from '../ticket/containers/NewTicketContainer/redux/reducer';
import payoutDriver from '../payout/containers/PayoutDriverContainer/redux/reducer';
import payoutTickets from '../ticket/containers/PayoutTicketsContainer/redux/reducer';
import putVehicleOnHold from '../vehicleHold/containers/PutVehicleOnHoldContainer/redux/reducer';
import reassignTollToVehicle from '../toll/containers/ReassignTollToVehicleContainer/redux/reducer';
import reassignTollToEmployee from '../toll/containers/ReassignTollToEmployeeContainer/redux/reducer';
import releaseVehicleFromHold from '../vehicleHold/containers/ReleaseVehicleFromHoldContainer/redux/reducer';
import rentalReportPreview from '../rental/containers/RentalReportPreviewContainer/redux/reducer';
import revertDecommissionVehicle from '../vehicle/containers/RevertDecommissionVehicleContainer/redux/reducer';
import searchTicket from '../ticket/containers/SearchTicketContainer/redux/reducer';
import skipPayout from '../payout/containers/SkipPayoutDriverContainer/redux/reducer';
import storeMedallion from '../medallion/containers/StoreMedallionContainer/redux/reducer';
import unmanageMedallion from '../medallion/containers/UnmanageMedallionContainer/redux/reducer';
import takePayment from '../payment/containers/TakePaymentContainer/redux/reducer';
import ticketInfo from '../ticket/containers/TicketContainer/redux/reducer';
import vehicleInfo from '../vehicle/containers/VehicleInfoContainer/redux/reducer';

export default combineReducers({
  accident,
  ach,
  alert,
  auth,
  cheque,
  driver,
  externalDevice,
  layout,
  lease,
  medallion,
  medallionOwner,
  spotlight,
  ticket,
  toll,
  transactionType,
  vehicle,

  archiveVehicle,
  blacklistDriver,
  charge,
  contract,
  credit,
  decommissionVehicle,
  dispatcher,
  dispatchVehicleFlow,
  driverFhvCapture,
  driverInfo,
  enableDriver,
  enableMedallion,
  enableVehicle,
  hotswap,
  medallionInfo,
  newTicket,
  payoutDriver,
  payoutTickets,
  putVehicleOnHold,
  reassignTollToVehicle,
  reassignTollToEmployee,
  releaseVehicleFromHold,
  rentalReportPreview,
  revertDecommissionVehicle,
  searchTicket,
  skipPayout,
  storeMedallion,
  unmanageMedallion,
  takePayment,
  ticketInfo,
  vehicleInfo,
});
