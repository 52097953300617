import prepare from '../../attribute/prepare.attribute';

// Errors
import ssnMissmatchErr from '../errors/ssnMissmatch.error.driver';

export default async (attributes) => {
  const body = prepare(attributes, {
    field: 'api',
    required: ['ssn', 'ssnConfirm'],
  });
  if (body.ssn !== body.ssnConfirm)
    throw ssnMissmatchErr({message: 'Confirm SSN'});
  const {ssnConfirm, ...driver} = body;
  return driver;
};
