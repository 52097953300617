// components
import CheckBox from '@matthahn/sally-ui/lib/components/CheckBox/CheckBox';
import Icon from '@matthahn/sally-ui/lib/components/Icon/Icon';
import Modal from '@matthahn/sally-ui/lib/components/Modal/Modal';
import Table from '@matthahn/sally-ui/lib/components/Table/Table';
import Tag from '@matthahn/sally-ui/lib/components/Tag/Tag';

// layout components
import AttributeFormatter from '../../../layout/components/AttributeFormatter/AttributeFormatter';

// local constants
import columns from './constants/columns.constant';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// vehicle attributes
import vin from '../../attributes/vin.attribute.vehicle';
import vehicle_year from '../../attributes/vehicle_year.attribute.vehicle';
import vehicle_model from '../../attributes/vehicle_model.attribute.vehicle';
import plate from '../../attributes/plate.attribute.vehicle';

// vehicle components
import VehicleTags from '../VehicleTags/VehicleTags';

// vehicle states
import vehicleState from '../../state';

const SelectVehiclesModal = ({
  loading,
  more,
  onClear,
  onClose,
  onConfirm,
  onLoadMore,
  onSearch,
  onSelect,
  onSort,
  search,
  selectedVehicles,
  sort,
  vehicles,
  visible,
}) => (
  <Modal
    title="Select Vehicles"
    visible={visible}
    onClose={onClose}
    size="large"
    buttonsRight={[
      {
        label: 'Select',
        onClick: onConfirm,
        disabled: loading || !selectedVehicles.length,
        theme: 'orange',
      },
    ]}
    buttonsLeft={
      !!selectedVehicles.length
        ? [
            {
              label: 'Clear',
              onClick: onClear,
              disabled: loading || !selectedVehicles.length,
            },
          ]
        : []
    }
  >
    {(Content) => (
      <Content padding="none">
        <Table
          columns={columns}
          infinite
          loading={loading && !vehicles.length}
          sort={sort}
          onSort={onSort}
          theme="orange"
          noBorder
          noRadius
          smallNoResultsLabel
        >
          {(TableRow) =>
            [
              ...vehicles.map((vehicle) => (
                <TableRow key={vehicle.id} onClick={onSelect(vehicle)}>
                  {(TableCol) =>
                    [
                      <TableCol key="check" span={1 / 4}>
                        <CheckBox
                          value={selectedVehicles.some(
                            ({id}) => id === vehicle.id
                          )}
                          onChange={() => null}
                          size="small"
                        />
                      </TableCol>,
                      <TableCol key="vin" uppercase bold>
                        <VehicleTags vehicle={vehicle} display="vin">
                          <AttributeFormatter attribute={vin(vehicle.vin)} />
                        </VehicleTags>
                      </TableCol>,
                      <TableCol key="medallion">
                        {!!vehicle.medallion &&
                        !!vehicle.medallion.medallion_number
                          ? vehicle.medallion.medallion_number
                          : '-'}
                      </TableCol>,
                      <TableCol key="year-model">
                        <AttributeFormatter
                          attribute={vehicle_year(vehicle.vehicle_year)}
                        />{' '}
                        <AttributeFormatter
                          attribute={vehicle_model(vehicle.vehicle_model)}
                        />
                      </TableCol>,
                      <TableCol key="plate">
                        <AttributeFormatter attribute={plate(vehicle.plate)} />
                      </TableCol>,
                      <TableCol key="state">
                        <Tag theme={vehicleState(vehicle.state).color}>
                          {vehicleState(vehicle.state).label}
                        </Tag>
                      </TableCol>,
                    ].filter((col) => !!col)
                  }
                </TableRow>
              )),
              more && !!vehicles.length && (
                <TableRow key="load-more" onClick={onLoadMore}>
                  {(TableCol) => (
                    <TableCol span={5 + 1 / 4} key="load-more" align="center">
                      {loading && <Icon icon="loading2" spin />} Load More
                    </TableCol>
                  )}
                </TableRow>
              ),
            ].filter((row) => !!row)
          }
        </Table>
      </Content>
    )}
  </Modal>
);

SelectVehiclesModal.propTypes = {
  loading: PropTypes.bool,
  more: PropTypes.bool,
  onClear: PropTypes.func,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  onLoadMore: PropTypes.func,
  onSearch: PropTypes.func,
  onSelect: PropTypes.func,
  onSort: PropTypes.func,
  search: PropTypes.string,
  selectedVehicles: PropTypes.array,
  sort: PropTypes.object,
  vehicles: PropTypes.array,
  visible: PropTypes.bool,
};

export default SelectVehiclesModal;
