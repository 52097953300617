import {SET} from './constants';

export const set = (data = {}) => ({
  type: SET,
  data,
});

export const show = (driver = null, requestAddon = {}) =>
  set({driver, visible: true, requestAddon, chargeCreated: false});

export const hide = (chargeCreated = false) =>
  set({driver: null, visible: false, chargeCreated});
