import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Card, Form, Row, Column} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';
import AttributeText from '../../../layout/components/AttributeText/AttributeText';

const DriverBasicInfoCard = ({
  loading,
  onSave,
  first_name,
  last_name,
  phone_number,
  email,
  ssn,
  onChange,
  onSSN,
  errors,
}) => (
  <Card
    title="Basic Info"
    icon="infocirlceo"
    headerActions={[
      {tooltip: 'Update SSN', icon: 'creditcard', onClick: onSSN},
    ]}
  >
    {(Content) => (
      <Form onSubmit={onSave} enter>
        <Content>
          <Row margin>
            <Column size={1 / 2}>
              <AttributeInput
                value={first_name}
                onChange={onChange}
                errors={errors}
                disabled={loading}
              >
                {first_name.label.default}
              </AttributeInput>
            </Column>
            <Column size={1 / 2}>
              <AttributeInput
                value={last_name}
                onChange={onChange}
                errors={errors}
                disabled={loading}
              >
                {last_name.label.default}
              </AttributeInput>
            </Column>
          </Row>
          <Row margin>
            <Column>
              <AttributeInput
                value={phone_number}
                onChange={onChange}
                errors={errors}
                disabled={loading}
                preIcon="phone"
              >
                {phone_number.label.default}
              </AttributeInput>
            </Column>
          </Row>
          <Row margin>
            <Column>
              <AttributeInput
                value={email}
                onChange={onChange}
                errors={errors}
                disabled={loading}
                preValue="@"
              >
                {email.label.default}
              </AttributeInput>
            </Column>
          </Row>
          <Row>
            <Column>
              <AttributeText attribute={ssn}>{ssn.label.default}</AttributeText>
            </Column>
          </Row>
        </Content>
      </Form>
    )}
  </Card>
);

DriverBasicInfoCard.propTypes = {
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  onSSN: PropTypes.func,
  errors: PropTypes.array,
  first_name: PropTypes.object,
  last_name: PropTypes.object,
  phone_number: PropTypes.object,
  email: PropTypes.object,
  ssb: PropTypes.object,
};

export default DriverBasicInfoCard;
