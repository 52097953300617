import styled from 'styled-components';

// Colors
import {lib} from '@matthahn/sally-ui';
const {
  colors: {grey, darkGrey},
} = lib;

const ContentHeader = styled.div`
  width: 100%;
  padding: 4px 15px;
  font-size: 14px;
  font-weight: bold;
  background: ${grey};
  color: ${darkGrey};
  text-transform: uppercase;
  font-weight: 700;
`;

export default ContentHeader;
