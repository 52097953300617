import styled, {css} from 'styled-components';

// colors
import {grey} from '@matthahn/sally-ui/lib/libs/colors';

const SelectContainer = styled.div`
  width: 300px;
  max-width: 300px;
  min-width: 300px;
  ${({border}) =>
    border
      ? css`
          border-right: 1px solid ${grey};
          margin-right: 10px;
        `
      : ''}
`;

export default SelectContainer;
