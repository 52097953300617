import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

export default (id, data) =>
  f3tch(`/payments/funding_sources/${id}/`)
    .profile(profile())
    .body(data)
    .patch();
