import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Card, Row, Column, Form} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

const Poc2Edit = ({
  loading,
  errors,
  poc2,
  poc2_address_street1,
  poc2_address_street2,
  poc2_address_city,
  poc2_address_zip,
  poc2_address_state,
  poc2_email,
  poc2_phone_number,
  poc2_notes,
  onChange,
  onCancel,
  onSave,
}) => (
  <Card
    title="POC 2"
    icon="phone"
    headerActions={[
      {
        icon: 'close',
        onClick: onCancel,
        tooltip: 'Exit edit mode',
      },
    ]}
    buttonsRight={[{label: 'Save', loading, onClick: onSave, theme: 'orange'}]}
  >
    {(Content) => (
      <Content>
        <Form onSubmit={onSave} enter>
          <Row margin>
            <Column>
              <AttributeInput
                value={poc2}
                onChange={onChange}
                disabled={loading}
                errors={errors}
              >
                {poc2.label.default}
              </AttributeInput>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 2}>
              <AttributeInput
                value={poc2_email}
                onChange={onChange}
                disabled={loading}
                errors={errors}
              >
                {poc2_email.label.default}
              </AttributeInput>
            </Column>
            <Column size={1 / 2}>
              <AttributeInput
                value={poc2_phone_number}
                onChange={onChange}
                disabled={loading}
                errors={errors}
              >
                {poc2_phone_number.label.default}
              </AttributeInput>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 3}>
              <AttributeInput
                value={poc2_address_street1}
                onChange={onChange}
                disabled={loading}
                errors={errors}
              >
                {poc2_address_street1.label.default}
              </AttributeInput>
            </Column>
            <Column size={1 / 3}>
              <AttributeInput
                value={poc2_address_street2}
                onChange={onChange}
                disabled={loading}
                errors={errors}
              >
                {poc2_address_street2.label.default}
              </AttributeInput>
            </Column>
            <Column size={1 / 3}>
              <AttributeInput
                value={poc2_address_city}
                onChange={onChange}
                disabled={loading}
                errors={errors}
              >
                {poc2_address_city.label.default}
              </AttributeInput>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 2}>
              <AttributeInput
                value={poc2_address_zip}
                onChange={onChange}
                disabled={loading}
                errors={errors}
              >
                {poc2_address_zip.label.default}
              </AttributeInput>
            </Column>
            <Column size={1 / 2}>
              <AttributeInput
                value={poc2_address_state}
                onChange={onChange}
                disabled={loading}
                errors={errors}
              >
                {poc2_address_state.label.default}
              </AttributeInput>
            </Column>
          </Row>
          <Row>
            <Column>
              <AttributeInput
                value={poc2_notes}
                onChange={onChange}
                disabled={loading}
                errors={errors}
              >
                {poc2_notes.label.default}
              </AttributeInput>
            </Column>
          </Row>
        </Form>
      </Content>
    )}
  </Card>
);

Poc2Edit.propTypes = {
  loading: PropTypes.bool,
  errors: PropTypes.array,
  poc2: PropTypes.object,
  poc2_address_street1: PropTypes.object,
  poc2_address_street2: PropTypes.object,
  poc2_address_city: PropTypes.object,
  poc2_address_zip: PropTypes.object,
  poc2_address_state: PropTypes.object,
  poc2_email: PropTypes.object,
  poc2_phone_number: PropTypes.object,
  poc2_notes: PropTypes.object,
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};

export default Poc2Edit;
