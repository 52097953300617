import {v4} from 'uuid';

// Constants
import RECONNECTION_TIMEOUT from './constants/reconnectionTimeout.const.socket';
import PING_TIME from './constants/pingTime.const.socket';

// Services
import parseMessage from './services/parseMessage.service.socket';
import createMessage from './services/message.service.socket';

class Socket {
  url = null;
  client = null;
  token = null;
  connected = false;
  authorized = false;
  subscribers = [];
  handlers = [];

  connect = (url = this.url) => {
    if (this.connected) return;
    this.ping();
    try {
      this.url = url;
      this.client = new WebSocket(url);
      this.clientSetup();
      this.eventListeners();
    } catch (error) {
      this.client = null;
    }
  };

  retry = () => setTimeout(() => this.connect(), RECONNECTION_TIMEOUT);

  clientSetup = () => {
    this.client.on = (event, handler) => {
      this.client.addEventListener(event, handler);
      this.handlers = [
        ...this.handlers,
        () => this.client.removeEventListener(event, handler),
      ];
    };
    this.client.removeAllListeners = () => {
      [...this.handlers].forEach((fn) => fn());
      this.handlers = [];
    };
  };

  eventListeners = () => {
    this.client.on('open', this.onOpen);
    this.client.on('message', this.onMessage);
    this.client.on('close', this.onClose);
    this.client.on('error', () => null);
  };

  onOpen = () => {
    this.connected = true;
    this.publish('connected');
  };

  onMessage = async ({data: socketData}) => {
    const {message, data} = parseMessage(socketData);

    if (message === 'authorize' && this.token)
      return this.authorize(this.token);

    if (message === 'authorized') {
      this.authorized = true;
      return;
    }

    if (!this.authorized) return;

    this.publish(message, data);
  };

  onClose = () => {
    this.authorized = false;
    if (this.connected) {
      this.connected = false;
      this.publish('disconnected');
    }
    if (!!this.client) this.client.removeAllListeners();
    this.retry();
  };

  authorize = (token) => {
    this.token = token;
    if (!this.connected) return;
    this.client.send(createMessage('authorize', {token: `WEB ${token}`}));
  };

  deauthorize = () => {
    this.token = null;
    this.authorized = false;
    if (!this.connected) return;
    this.client.send(createMessage('deauthorize'));
  };

  subscribe = (event, handler = () => null) => {
    const id = v4();
    const sub = {id, event, handler};
    this.subscribers = [...this.subscribers, sub];
    return () => this.unsubscribe(id);
  };

  unsubscribe = (id) => {
    this.subscribers = [...this.subscribers].filter((sub) => sub.id !== id);
  };

  publish = (event, data = null) => {
    if (!this.client || !this.authorized) return;
    this.subscribers.forEach((sub) => {
      if (sub.event === event) sub.handler(data);
    });
  };

  send = (message, data = null) => {
    if (!this.client || !this.authorized || !message) return;
    this.client.send(createMessage('broadcast', {message, data}));
  };

  ping = () => {
    if (this.connected && !!this.client)
      this.client.send(createMessage('ping', 'pong'));

    setTimeout(this.ping, PING_TIME);
  };
}

export default new Socket();
