const permissionTree = {
  Payments: [
    'create_credit_permission',
    'create_charge_permission',
    'maximum_credit_authorized_permission',
    'maximum_charge_authorized_permission',
    'credit_types_authorized_permission',
    'charge_types_authorized_permission',
    'cancel_check_permission',
    'change_transaction_description_permission',
  ],
  Drivers: [
    'add_driver_permission',
    'change_driver_info_permission',
    'put_driver_on_hold_permission',
    'remove_driver_on_hold_permission',
    'can_blacklist_driver_permission',
  ],
  Vehicles: [
    'add_vehicle_permission',
    'change_vehicle_info_permission',
    'can_archive_vehicle_permission',
    'can_decommission_vehicle_permission',
    'put_vehicle_on_hold_permission',
    'remove_vehicle_on_hold_permission',
  ],
  Medallions: [
    'medallion_access_permission',
    'medallion_owner_access_permission',
    'add_medallion_permission',
    'change_medallion_permission',
    'can_store_medallion_permission',
  ],
  Documents: ['delete_document_permission'],
  Rentals: [
    'dispatch_driver_permission',
    'hotswap_driver_permission',
    'dispatch_additional_driver_permission',
    'check_in_only_permission',
    'check_in_and_payout_permission',
    'payout_only_permission',
  ],
  Tickets: [
    'assign_tickets_permission',
    'modify_ticket_permission',
    'delete_tickets_permission',
    'create_ticket_charge_permission',
  ],
  Tolls: ['assign_tolls_permission', 'delete_tolls_permission'],
  Accidents: [
    'accidents_create_permission',
    'accidents_edit_permission',
    'accidents_delete_permission',
  ],
};

export default permissionTree;
