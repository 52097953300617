import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {
  Modal,
  Row,
  Column,
  Button,
  Table,
  MenuButton,
  Heading,
  Text,
} from '@matthahn/sally-ui';
import NegativeParenthesis from '../../../layout/components/NegativeParenthesis/NegativeParenthesis';
import DriverTags from '../../../driver/components/DriverTags/DriverTags';
import VehicleOnHoldInfo from '../../../vehicle/components/VehicleOnHoldInfo/VehicleOnHoldInfo';
import Action from './components/Action';

// Libs
import orderByDate from '../../../lib/orderByDate';

// Columns
import columns from './columns';

const PayoutActionModal = ({
  canCheckInAndPayout,
  canCheckInOnly,
  canPayoutOnly,
  isOnHold,
  visible,
  vehicle,
  rental,
  canHaveMultipleRentals,
  options,
  hotswapPermission,
  onEndWithPayout,
  onEndWithoutPayout,
  onClose,
  onPayout,
  onHotswap,
  onRental,
  onBack,
  onRemoveFromHold,
}) => (
  <Modal
    title={
      !!vehicle
        ? !!vehicle.medallion
          ? vehicle.medallion.medallion_number
          : vehicle.vin
        : ''
    }
    visible={visible}
    onClose={onClose}
    buttonsLeft={
      !!vehicle &&
      !!vehicle.active_rentals &&
      vehicle.active_rentals.length > 1 &&
      !!rental
        ? [{label: 'Select another driver', onClick: onBack}]
        : []
    }
  >
    {(Content) => (
      <Fragment>
        {!!vehicle && !rental && (
          <Fragment>
            <Content>
              <Heading>Select driver</Heading>
            </Content>
            <Content padding="none">
              <Table
                columns={columns}
                theme="orange"
                infinite
                noBorder
                noRadius
                smallNoResultsLabel
              >
                {(TableRow) =>
                  orderByDate(
                    [...vehicle.active_rentals].map((activeRental) => ({
                      ...activeRental,
                      lastActivityRecord: !!activeRental.curb_rental
                        ? activeRental.curb_rental.start_datetime ||
                          activeRental.curb_rental.created_at
                        : 0,
                    })),
                    'lastActivityRecord',
                    'desc'
                  ).map((rental) => (
                    <TableRow key={rental.driver.id} onClick={onRental(rental)}>
                      {(TableColumn) => [
                        <TableColumn key="name">
                          <DriverTags
                            driver={{
                              ...rental.driver,
                              lease: rental.lease_agreement,
                            }}
                          >
                            {rental.driver.first_name} {rental.driver.last_name}
                          </DriverTags>
                        </TableColumn>,
                        <TableColumn key="hack">
                          {rental.driver.fhv_license_number}
                        </TableColumn>,
                        <TableColumn key="amount">
                          <NegativeParenthesis
                            value={rental.driver.balance}
                            pre="$"
                          />
                        </TableColumn>,
                      ]}
                    </TableRow>
                  ))
                }
              </Table>
            </Content>
          </Fragment>
        )}
        {!!rental && (
          <Fragment>
            {isOnHold && (
              <Content padding="none">
                <VehicleOnHoldInfo
                  hold={vehicle.vehicle_hold}
                  onResolve={onRemoveFromHold}
                  flat
                />
              </Content>
            )}
            <Content>
              <Row margin>
                <Column>
                  <Heading>
                    {rental.driver.first_name} {rental.driver.last_name} on{' '}
                    {rental.medallion.medallion_number}
                  </Heading>
                </Column>
              </Row>
              <Row margin>
                <Column>
                  <Text
                    value={
                      <NegativeParenthesis
                        value={rental.driver.balance}
                        pre="$"
                      />
                    }
                  >
                    Balance
                  </Text>
                </Column>
              </Row>
              <Row margin />
              {canHaveMultipleRentals ? (
                <Row margin>
                  <Column size={5 / 8} offset={3 / 16}>
                    {canPayoutOnly && (
                      <Action>
                        <Button
                          size="large"
                          theme="orange"
                          icon="creditcard"
                          onClick={onPayout}
                          block
                        >
                          Payout Only
                        </Button>
                      </Action>
                    )}
                  </Column>
                </Row>
              ) : (
                <Fragment>
                  {canCheckInAndPayout && (
                    <Row margin>
                      <Column size={5 / 8} offset={3 / 16}>
                        <Action>
                          <Button
                            size="large"
                            theme="green"
                            icon="arrowright"
                            onClick={onEndWithPayout}
                            block
                          >
                            Check in and payout
                          </Button>
                        </Action>
                      </Column>
                    </Row>
                  )}
                  {canCheckInOnly && (
                    <Row margin>
                      <Column size={5 / 8} offset={3 / 16}>
                        <Action>
                          <Button
                            size="large"
                            theme="purple"
                            icon="enter"
                            onClick={onEndWithoutPayout}
                            block
                          >
                            Check in only
                          </Button>
                        </Action>
                      </Column>
                    </Row>
                  )}
                  {canPayoutOnly && (
                    <Row margin>
                      <Column size={5 / 8} offset={3 / 16}>
                        <Action>
                          <Button
                            size="large"
                            theme="orange"
                            icon="creditcard"
                            onClick={onPayout}
                            block
                          >
                            Payout only
                          </Button>
                        </Action>
                      </Column>
                    </Row>
                  )}
                  {hotswapPermission && (
                    <Row margin>
                      <Column size={5 / 8} offset={3 / 16}>
                        <Action>
                          <Button
                            size="large"
                            theme="blue"
                            icon="swap"
                            onClick={onHotswap}
                            block
                          >
                            Hotswap
                          </Button>
                        </Action>
                      </Column>
                    </Row>
                  )}
                </Fragment>
              )}
              {!!options.length && (
                <Row margin>
                  <Column size={1 / 3} offset={1 / 3}>
                    <Action>
                      <MenuButton theme="grey" options={options}>
                        More
                      </MenuButton>
                    </Action>
                  </Column>
                </Row>
              )}
            </Content>
          </Fragment>
        )}
      </Fragment>
    )}
  </Modal>
);

PayoutActionModal.propTypes = {
  canCheckInAndPayout: PropTypes.bool,
  canCheckInOnly: PropTypes.bool,
  canPayoutOnly: PropTypes.bool,
  isOnHold: PropTypes.bool,
  visible: PropTypes.bool,
  vehicle: PropTypes.object,
  rental: PropTypes.object,
  canHaveMultipleRentals: PropTypes.bool,
  options: PropTypes.array,
  hotswapPermission: PropTypes.bool,
  onEndWithPayout: PropTypes.func,
  onEndWithoutPayout: PropTypes.func,
  onClose: PropTypes.func,
  onPayout: PropTypes.func,
  onHotswap: PropTypes.func,
  onRental: PropTypes.func,
  onBack: PropTypes.func,
  onRemoveFromHold: PropTypes.func,
};

export default PayoutActionModal;
