import React, {Component} from 'react';
import styled, {keyframes, css} from 'styled-components';
import PropTypes from 'prop-types';

const shake = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`;

const Container = styled.div`
  width: 100%;
  font-size: 10px;
  font-weight: 400;
  user-select: none;
  pointer-events: none;
  ${({shakeit}) =>
    shakeit
      ? css`
          animation: ${shake} 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        `
      : ''};
`;

class RoundMessage extends Component {
  static propTypes = {
    children: PropTypes.node,
    lastRoundMessageShake: PropTypes.number,
  };

  state = {
    shakeit: false,
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lastRoundMessageShake !== this.props.lastRoundMessageShake)
      this.shake();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  shake = () => {
    this.setState({shakeit: true});
    setTimeout(() => {
      if (!this.mounted) return;
      this.setState({shakeit: false});
    }, 1000);
  };

  render() {
    const {children, lastRoundMessageShake, ...props} = this.props;
    const {shakeit} = this.state;
    return (
      <Container shakeit={shakeit} {...props}>
        {children}
      </Container>
    );
  }
}

export default RoundMessage;
