// lib
import sum from '@matthahn/sally-fw/lib/lib/sum';

const numberOfDriverAlerts = ({active_rentals}) =>
  !!active_rentals?.length
    ? [...active_rentals].reduce(
        (combined, rental) =>
          sum(combined, rental?.driver?.driver_alerts_unresolved?.length || 0),
        0
      )
    : 0;

export default numberOfDriverAlerts;
