import prepare from '../../attribute/prepare.attribute';

// Errors
import missingImageError from '../errors/missingImage.error.ticket';

export default async ({image, ...attributes}) => {
  if (!image) throw missingImageError();
  const ticket = prepare(attributes, {
    field: 'api',
    required: [
      'amount_due',
      'due_datetime',
      'fine_amount',
      'interest_amount',
      'payment_amount',
      'penalty_amount',
      'plate',
      'reduction_amount',
      'state',
      'summons_number',
      'violation_datetime',
      'violation',
    ],
  });
  return {...ticket, image, license_plate: ticket.plate};
};
