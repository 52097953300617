import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import isObject from 'is-object';

// Components
import {Modal, Table, Row, Column, Text, Icon} from '@matthahn/sally-ui';
import SmallLoader from '../../../layout/components/SmallLoader/SmallLoader';
import NoData from './components/NoData';

// Columns
import columns from './columns';

// Types
import {dateTime, amount} from '../../../types';

const ReassignTollModal = ({
  loading,
  canAssign,
  canDelete,
  deleting,
  visible,
  toll,
  rentals,
  onRental,
  onClose,
  onAdd,
  onDelete,
  onChangeVehicle,
  onAssignEmployee,
}) => (
  <Modal
    title={`Reassign Toll ${!!toll ? toll.id : '-'}`}
    visible={visible}
    onClose={onClose}
    headerActions={[
      canAssign && {tooltip: 'Assign', icon: 'plus', onClick: onAdd},
      canDelete && {
        tooltip: 'Delete',
        icon: 'delete',
        onClick: onDelete,
        loading: deleting,
      },
      {
        tooltip: 'Reassign Vehicle',
        icon: 'car',
        onClick: onChangeVehicle,
      },
      {
        tooltip: 'Assign to  employee',
        icon: 'team',
        onClick: onAssignEmployee,
      },
    ].filter((act) => !!act)}
    size="large"
  >
    {(Content) =>
      loading || !toll ? (
        <Content padding="none">
          <SmallLoader />
        </Content>
      ) : (
        <Fragment>
          <Content>
            <Row margin>
              <Column size={1 / 3}>
                <Text value={dateTime(toll.transaction_date).format()}>
                  Date
                </Text>
              </Column>
              <Column size={1 / 3}>
                <Text value={toll.vehicle_medallion || '-'}>Medallion</Text>
              </Column>
              <Column size={1 / 3}>
                <Text value={toll.vehicle_plate || '-'}>
                  Kuiper Vehicle Plate
                </Text>
              </Column>
            </Row>
            <Row margin>
              <Column size={1 / 3}>
                <Text value={toll.plate}>Tag ID</Text>
              </Column>
              <Column size={1 / 3}>
                <Text value={`$${amount(toll.amount).format()}`}>Amount</Text>
              </Column>
              <Column size={1 / 3}>
                <Text value={toll.agency}>Agency</Text>
              </Column>
            </Row>
            <Row margin>
              <Column size={1 / 2}>
                <Text value={toll.exit_plaza}>Exit Plaza</Text>
              </Column>
              <Column size={1 / 2}>
                <Text value={toll.exit_lane}>Exit Lane</Text>
              </Column>
            </Row>
            <Row>
              <Column>
                The following table displays possible rentals to reassign the
                toll to
              </Column>
            </Row>
          </Content>
          <Content padding="none">
            {!rentals.length && <NoData>No rentals found</NoData>}
            {!!rentals.length && (
              <Table
                columns={columns}
                theme="orange"
                infinite
                noBorder
                noRadius
                smallNoResultsLabel
              >
                {(TableRow) =>
                  rentals.map((rental) => (
                    <TableRow key={rental.id} onClick={onRental(rental)}>
                      {(TableColumn) => [
                        <TableColumn key="pickup">
                          {!!rental.canApply && (
                            <span>
                              <Icon icon="arrowright" />{' '}
                            </span>
                          )}
                          {!!rental.vehicle_pickup_datetime
                            ? dateTime(rental.vehicle_pickup_datetime).format()
                            : '-'}
                        </TableColumn>,
                        <TableColumn key="dropoff">
                          {!!rental.vehicle_dropoff_datetime
                            ? dateTime(rental.vehicle_dropoff_datetime).format()
                            : '-'}
                        </TableColumn>,
                        <TableColumn key="driver" span={1.5}>
                          {rental.driver.first_name} {rental.driver.last_name} (
                          {rental?.driver?.fhv_license_number})
                        </TableColumn>,
                        <TableColumn key="contract_type" span={0.5}>
                          {isObject(rental.lease_agreement)
                            ? rental.lease_agreement.contract_type
                            : '-'}
                        </TableColumn>,
                      ]}
                    </TableRow>
                  ))
                }
              </Table>
            )}
          </Content>
        </Fragment>
      )
    }
  </Modal>
);

ReassignTollModal.propTypes = {
  loading: PropTypes.bool,
  canAssign: PropTypes.bool,
  canDelete: PropTypes.bool,
  deleting: PropTypes.bool,
  visible: PropTypes.bool,
  toll: PropTypes.object,
  rentals: PropTypes.array,
  onRental: PropTypes.func,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  onChangeVehicle: PropTypes.func,
};

export default ReassignTollModal;
