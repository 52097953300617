import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Libs
import parseError from '../../../error/parseError';
import {lib} from '@matthahn/sally-ui';

// Api
import takeDepositDriversApi from '../../api/takeDeposit.api.driver';

// Components
import {Modal, Form, Row, Column, Info} from '@matthahn/sally-ui';

// Attributes
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';
import amountAttr from '../../../transaction/attributes/amount.attribute.transaction';

const {alert} = lib;

class DriverTakeDeposit extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    deposit_balance: PropTypes.number,
    transactions: PropTypes.array,
    loading: PropTypes.bool,
    driver: PropTypes.object,
  };

  state = {
    loading: false,
    amount: amountAttr(''),
  };

  componentDidMount() {
    if (this.props.visible) this.init();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) this.init();
  }

  init = () => {
    this.setState({
      amount: amountAttr(''),
    });
  };

  change = (val, key) => {
    if (this.state.loading) return;
    this.setState({[key]: val});
  };

  save = async () => {
    const {loading, amount} = this.state;

    const {visible, driver, onClose} = this.props;

    if (!visible || !driver || loading) return;

    this.setState({loading: true});

    try {
      await takeDepositDriversApi(driver, amount.value());
      alert.success('Deposit Taken');
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
    }

    this.setState({loading: false});
    onClose();
  };

  render() {
    const {visible, onClose} = this.props;
    const {loading, amount} = this.state;
    return (
      <Modal
        visible={visible}
        onClose={onClose}
        title="Take Deposit"
        buttonsRight={[
          {
            label: 'Take Deposit',
            onClick: this.save,
            theme: 'orange',
            loading,
          },
        ]}
        icon="swap"
        size="small"
      >
        {(Component) => (
          <Component>
            <Form onSubmit={this.save} enter>
              <Row margin>
                <Column>
                  <Info type="info" flat>
                    Take cash deposit
                  </Info>
                </Column>
              </Row>
              <Row>
                <Column>
                  <AttributeInput
                    value={amount}
                    onChange={this.change}
                    disabled={loading}
                    preValue="$"
                  >
                    {amount.label.short}
                  </AttributeInput>
                </Column>
              </Row>
            </Form>
          </Component>
        )}
      </Modal>
    );
  }
}

export default DriverTakeDeposit;
