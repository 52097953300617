import {DATE} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {date, apiDate} from '../../types';

export default attribute({
  type: DATE,
  attribute: 'policy_effective_date',
  display: date,
  input: date,
  api: apiDate,
  label: {
    default: 'Effective Date',
    short: 'Effective Date',
  },
});
