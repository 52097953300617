import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {
  set as setAct,
  syncVehicles as syncVehiclesAct,
  removeUnusedVehicles as removeUnusedVehiclesAct,
} from '../../../spotlight/redux/actions';

// Api
import listVehiclesApi from '../../api/list.api.vehicle';

// Constants
import STORAGE_KEY from '../../constants/spotlightStorageKey.constants.vehicle';

// Localstorage
import * as storage from '../../../lib/localStorage';

class VehiclesSpotlightSync extends Component {
  static propTypes = {
    vehicles: PropTypes.array,
    dispatch: PropTypes.func,
  };

  componentDidMount() {
    this.init();
  }

  init = async () => {
    const {dispatch} = this.props;
    const rawVehicles = storage.get(STORAGE_KEY);
    const vehicles = !!rawVehicles ? JSON.parse(rawVehicles) : [];
    dispatch(syncVehiclesAct(vehicles));
    dispatch(setAct({vehiclesLoading: true}));
    const freshVehicles = await this.fetch();
    dispatch(removeUnusedVehiclesAct(freshVehicles));
    dispatch(setAct({vehiclesLoading: false, vehiclesLoaded: true}));
  };

  fetch = async ({vehicles = [], limit = 500, offset = 0} = {}) => {
    try {
      const {results, next} = await listVehiclesApi({
        limit,
        offset,
        fields:
          'id,created_at,modified_at,svid,vin,name,plate,vehicle_make,vehicle_model,vehicle_year,medallion',
      });
      const newList = [...vehicles, ...results];
      this.props.dispatch(syncVehiclesAct(results));
      if (!!next)
        return this.fetch({
          vehicles: newList,
          limit,
          offset: offset + limit,
        });
      return newList;
    } catch (error) {
      return vehicles;
    }
  };

  render() {
    return null;
  }
}

export default connect((state) => ({vehicles: state.spotlight.vehicles}))(
  VehiclesSpotlightSync
);
