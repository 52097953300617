import styled from 'styled-components';

const Overflow = styled.div`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export default Overflow;
