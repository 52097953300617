import {SET} from './constants';

export const set = (data = {}) => ({
  type: SET,
  data,
});

export const setUnsavedChanges = () => ({
  type: SET,
  data: {unsavedChanges: true},
});

export const unsetUnsavedChanges = () => ({
  type: SET,
  data: {unsavedChanges: false},
});
