import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import isObject from 'is-object';

// Components
import {
  Modal,
  Table,
  Row,
  Column,
  Text,
  Icon,
  FrameDoc,
  Button,
  Uploader,
} from '@matthahn/sally-ui';
import SmallLoader from '../../../layout/components/SmallLoader/SmallLoader';
import NoData from './components/NoData';
import ActionsContainer from './components/ActionsContainer';
import Action from './components/Action';
import FileContainer from './components/FileContainer';
import FileLoader from './components/FileLoader';

// Columns
import columns from './columns';

// Lib
import https from '../../../lib/https';

// Types
import {dateTime, amount} from '../../../types';

import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

const ReassignTicketModal = ({
  transitioning,
  loading,
  deleting,
  assigning,
  uploading,
  visible,
  ticket,
  rentals,
  status,
  onRental,
  onClose,
  onAdd,
  onDelete,
  onChange,
  onTransition,
  onAssignToSally,
  onDocument,
  showUpload,
  onShowUpload,
}) => (
  <Modal
    title="Ticket"
    subtitle={
      !!ticket
        ? `${status.label} | ${
            !!ticket.charge ? 'Driver Charged' : 'Driver NOT Charged'
          }`
        : '-'
    }
    visible={visible}
    onClose={onClose}
    size="large"
    headerActions={[
      {
        tooltip: showUpload ? 'Hide upload area' : 'Show upload area',
        icon: 'upload',
        onClick: onShowUpload,
      },
    ]}
  >
    {(Content) =>
      loading || !ticket ? (
        <Content padding="none">
          <SmallLoader />
        </Content>
      ) : (
        <Fragment>
          <Content padding="none">
            <Row>
              <Column
                size={
                  !!ticket.latest_ticket_image || !!ticket.summons_image
                    ? 1 / 2
                    : 1
                }
              >
                <Content noBorder>
                  {!!ticket.rental && !!ticket.rental.driver && (
                    <Row margin>
                      <Column>
                        <Text
                          value={`${ticket.rental.driver.first_name} ${ticket.rental.driver.last_name}`}
                        >
                          Driver
                        </Text>
                      </Column>
                    </Row>
                  )}
                  <Row margin>
                    <Column size={1 / 3}>
                      <Text
                        value={dateTime(ticket.violation_datetime).format()}
                      >
                        Violation Date
                      </Text>
                    </Column>
                    <Column size={2 / 3}>
                      <AttributeInput
                        value={ticket.due_datetime}
                        onChange={onChange}
                        disabled={loading}
                      >
                        {ticket.due_datetime.label.default}
                      </AttributeInput>
                    </Column>
                  </Row>
                  <Row margin>
                    <Column size={1 / 3}>
                      <Text value={ticket.license_plate || '-'}>
                        License Plate
                      </Text>
                    </Column>
                    <Column size={1 / 3}>
                      <Text value={ticket.state}>State</Text>
                    </Column>
                    <Column size={1 / 3}>
                      <Text value={ticket.summons_number}>Summons Number</Text>
                    </Column>
                  </Row>
                  <Row margin>
                    <Column size={1 / 3}>
                      <Text value={`$${amount(ticket.fine_amount).format()}`}>
                        Fine Amount
                      </Text>
                    </Column>
                    <Column size={1 / 3}>
                      <Text
                        value={`$${amount(ticket.interest_amount).format()}`}
                      >
                        Interests
                      </Text>
                    </Column>
                    <Column size={1 / 3}>
                      <Text
                        value={`$${amount(ticket.penalty_amount).format()}`}
                      >
                        Penalty Amount
                      </Text>
                    </Column>
                  </Row>
                  <Row margin>
                    <Column size={1 / 3}>
                      <Text
                        value={`$${amount(ticket.reduction_amount).format()}`}
                      >
                        Reduction Amount
                      </Text>
                    </Column>
                    <Column size={1 / 3}>
                      <Text
                        value={`$${amount(ticket.payment_amount).format()}`}
                      >
                        Payment Amount
                      </Text>
                    </Column>
                    <Column size={1 / 3}>
                      <Text value={`$${amount(ticket.amount_due).format()}`}>
                        Amount Due
                      </Text>
                    </Column>
                  </Row>
                  <Row>
                    <Column>
                      <Text value={ticket.violation}>Violation</Text>
                    </Column>
                  </Row>
                  {(!!status.transition.length || !!ticket) && (
                    <ActionsContainer>
                      {status.transition.map((s) => (
                        <Action key={s.key}>
                          <Button
                            block
                            disabled={!!transitioning || deleting || assigning}
                            loading={transitioning === s.key}
                            icon={s.icon}
                            onClick={onTransition(s)}
                            theme="grey"
                          >
                            {s.label}
                          </Button>
                        </Action>
                      ))}

                      {!!ticket &&
                        (ticket.status === 'needs_action' ||
                          ticket.status === 'waiting') && (
                          <Action>
                            <Button
                              block
                              icon="enter"
                              onClick={onAssignToSally}
                              loading={assigning}
                              disabled={!!transitioning || deleting}
                              theme="grey"
                            >
                              Assign to company and sally to pay now.
                            </Button>
                          </Action>
                        )}
                      {!!ticket && (
                        <Action>
                          <Button
                            block
                            icon="delete"
                            onClick={onDelete}
                            loading={deleting}
                            disabled={!!transitioning || assigning}
                            theme="grey"
                          >
                            Delete.
                          </Button>
                        </Action>
                      )}
                    </ActionsContainer>
                  )}
                </Content>
              </Column>
              {(!!ticket.latest_ticket_image || !!ticket.summons_image) && (
                <Column size={1 / 2}>
                  <Row>
                    <Column>
                      {!uploading ? (
                        <FrameDoc
                          height="573px"
                          file={https(
                            ticket.latest_ticket_image || ticket.summons_image
                          )}
                          fullscreenable
                        />
                      ) : (
                        <FileLoader>
                          <Icon icon="loading2" spin />
                        </FileLoader>
                      )}
                    </Column>
                  </Row>
                </Column>
              )}
            </Row>
            {showUpload && (
              <Content>
                <FileContainer>
                  <Uploader loading={uploading} onChange={onDocument} small />
                </FileContainer>
              </Content>
            )}
            {!ticket.rental && (
              <Content>
                The following table displays possible rentals to reassign the
                ticket to
              </Content>
            )}
          </Content>
          {!ticket.rental && (
            <Content padding="none">
              {!rentals.length && <NoData>No rentals found</NoData>}
              {!!rentals.length && (
                <Table
                  columns={columns}
                  theme="orange"
                  infinite
                  noBorder
                  noRadius
                  smallNoResultsLabel
                >
                  {(TableRow) =>
                    rentals.map((rental) => (
                      <TableRow key={rental.id} onClick={onRental(rental)}>
                        {(TableColumn) => [
                          <TableColumn key="pickup">
                            {!!rental.canApply && (
                              <span>
                                <Icon icon="arrowright" />{' '}
                              </span>
                            )}
                            {!!rental.vehicle_pickup_datetime
                              ? dateTime(
                                  rental.vehicle_pickup_datetime
                                ).format()
                              : '-'}
                          </TableColumn>,
                          <TableColumn key="dropoff">
                            {!!rental.vehicle_dropoff_datetime
                              ? dateTime(
                                  rental.vehicle_dropoff_datetime
                                ).format()
                              : '-'}
                          </TableColumn>,
                          <TableColumn key="driver">
                            {rental.driver.first_name} {rental.driver.last_name}{' '}
                            ({rental?.driver?.fhv_license_number})
                          </TableColumn>,
                          <TableColumn key="contract_type">
                            {isObject(rental.lease_agreement)
                              ? rental.lease_agreement.contract_type
                              : '-'}
                          </TableColumn>,
                        ]}
                      </TableRow>
                    ))
                  }
                </Table>
              )}
            </Content>
          )}
        </Fragment>
      )
    }
  </Modal>
);

ReassignTicketModal.propTypes = {
  transitioning: PropTypes.string,
  loading: PropTypes.bool,
  deleting: PropTypes.bool,
  uploading: PropTypes.bool,
  visible: PropTypes.bool,
  ticket: PropTypes.object,
  rentals: PropTypes.array,
  status: PropTypes.object,
  onRental: PropTypes.func,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  onTransition: PropTypes.func,
  onAssignToSally: PropTypes.func,
  onDocument: PropTypes.func,
  showUpload: PropTypes.bool,
  onShowUpload: PropTypes.func,
};

export default ReassignTicketModal;
