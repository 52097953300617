import {date, fileName, folder} from '../../../document';

// Api
import verifyFhvApi from '../../api/verifyFhv.api.driver';

// Attributes
import fhvLicenseExpiryAttr from '../../attributes/fhv_license_expiry.attribute.driver';
import fhvLicenseNumberAttr from '../../attributes/fhv_license_number.attribute.driver';

// Errors
import invalidFhvNumberErr from '../../errors/invalidFhvNumber.error.driver';

// Production
import isProduction from '../../../app/lib/isProduction.lib.app';

export default folder({
  attributes: [fhvLicenseExpiryAttr, fhvLicenseNumberAttr],
  defaultFileName: 'FHV License',
  // FHV - DRIVER ID - UPLOAD DATE
  fileName: fileName(
    ({driver}) => `FHV - ${driver.first_name}_${driver.last_name} - ${date()}`
  ),
  folderName: 'Licenses FHV',
  preSaveValidation: async ({fhv_license_number}) => {
    if (!isProduction()) return true;
    const response = await verifyFhvApi(fhv_license_number.api.format());
    if (!!response.text && !response.license_number)
      throw invalidFhvNumberErr({message: response.text});
  },
  type: 'fhv',
});
