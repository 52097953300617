import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {hide as hideAct} from './redux/actions';

// Attributes
import endDatetimeAttr from '../../attributes/end_datetime.attribute.payout';

// Api
import endWithoutPayoutApi from '../../../rental/api/endWithoutPayout.api.rental';

// Components
import SkipPayoutModal from '../../components/SkipPayoutModal/SkipPayoutModal';

// Date
import {formatISO} from 'date-fns';

// Sockets
import rentalEndedSocket from '../../../rental/sockets/ended.socket.rental';
import hotswapSocket from '../../../rental/sockets/hotswap.socket.rental';

// Lib
import {lib} from '@matthahn/sally-ui';
import fkOrId from '../../../lib/fkOrId';
import parseError from '../../../error/parseError';
import isWeekly from '../../../lease/lib/isWeekly.lib.lease';

// Alert
const {alert} = lib;

class SkipPayoutDriverContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    visible: PropTypes.bool,
    rental: PropTypes.object,
  };

  state = {
    loading: false,
    end_datetime: endDatetimeAttr(formatISO(new Date())),
  };

  componentDidMount() {
    this.subscribe();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) this.init();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  checkingin = false;

  init = async () => {
    this.checkingin = false;
    await this.setState({
      loading: false,
      end_datetime: endDatetimeAttr(formatISO(new Date())),
    });
  };

  subscribe = () => {
    this.subscriptions = [
      rentalEndedSocket.subscribe(this.onRentalEnd),
      hotswapSocket.subscribe(this.onHotswap),
    ];
  };

  unsubscribe = () => {
    this.subscriptions.forEach((unsubscribe) => unsubscribe());
    this.subscriptions = [];
  };

  onRentalEnd = (rental) =>
    this.closeBecauseUpdate({
      rental,
      message: `${rental.driver.first_name} ${rental.driver.last_name} returned ${rental.medallion.medallion_number}`,
    });

  onHotswap = ({hotswaps}) => {
    [...hotswaps].forEach(({old_rental, new_rental}) => {
      this.closeBecauseUpdate({
        rental: old_rental,
        message: `${old_rental.driver.first_name} ${old_rental.driver.last_name} switched from ${old_rental.medallion.medallion_number} to ${new_rental.medallion.medallion_number}`,
      });
      this.closeBecauseUpdate({
        rental: new_rental,
        message: `${old_rental.driver.first_name} ${old_rental.driver.last_name} switched from ${old_rental.medallion.medallion_number} to ${new_rental.medallion.medallion_number}`,
      });
    });
  };

  closeBecauseUpdate = ({rental, message}) => {
    const {visible, rental: currentRental} = this.props;
    const isSameRental = !!currentRental && currentRental.id === rental.id;
    if (!visible || this.checkingin || !isSameRental) return;
    this.onClose();
    alert.info(message);
  };

  payoutOccured = (driverID) => {
    const {dispatch} = this.props;
    const {rental} = this.state;
    if (this.checkingin || !rental || fkOrId(rental.driver) !== driverID)
      return;
    dispatch(hideAct());
    alert.info(
      `${rental.driver.first_name} ${rental.driver.last_name} payed out`
    );
  };

  skipPayout = async () => {
    const {dispatch, rental} = this.props;
    const {loading, end_datetime} = this.state;
    if (loading) return;

    this.setState({loading: true});
    this.checkingin = true;

    try {
      await endWithoutPayoutApi({
        rental_id: rental.id,
        end_datetime: isWeekly(rental.lease_agreement)
          ? end_datetime.api.format()
          : undefined,
      });
      dispatch(hideAct());
      alert.success(
        `${rental.driver.first_name} ${rental.driver.last_name} checked in`
      );
    } catch (error) {
      this.checkingin = false;
      const {message} = parseError(error);
      alert.error(message);
    }

    this.setState({loading: false});
  };

  onClose = () => {
    if (this.state.loading) return;
    this.props.dispatch(hideAct());
  };

  onEndDatetime = (end_datetime) => {
    if (this.state.loading) return;
    this.setState({end_datetime});
  };

  render() {
    const {visible, rental} = this.props;
    const {loading, end_datetime} = this.state;

    return (
      <Fragment>
        {/* <SkipPayoutModal
          loading={loading}
          visible={visible}
          rental={rental}
          onSkip={this.skipPayout}
          onClose={this.onClose}
        /> */}
        <SkipPayoutModal
          loading={loading}
          visible={visible}
          rental={rental}
          end_datetime={end_datetime}
          displayCreditWarning={
            !!rental ? isWeekly(rental.lease_agreement) : false
          }
          displayEndDatePicker={
            !!rental ? isWeekly(rental.lease_agreement) : false
          }
          onSkip={this.skipPayout}
          onClose={this.onClose}
          onEndDatetime={this.onEndDatetime}
        />
      </Fragment>
    );
  }
}

export default connect((state) => ({
  ...state.skipPayout,
}))(SkipPayoutDriverContainer);
