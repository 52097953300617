import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Api
import listTransactionTypesApi from '../../api/list.api.transactionType';

// Actions
import {set as setAct} from '../../redux/actions';

class TransactionTypesSyncContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    children: PropTypes.node,
  };

  componentDidMount() {
    this.init();
  }

  init = () => {
    this.getTransactionTypes();
  };

  getTransactionTypes = async () => {
    const {dispatch} = this.props;

    dispatch(setAct({loading: true}));

    try {
      const types = await listTransactionTypesApi();
      dispatch(setAct({loading: false, transactionTypes: types}));
    } catch (error) {
      dispatch(setAct({loading: false}));
    }
  };

  render() {
    const {children} = this.props;
    return !!children ? children : null;
  }
}

export default connect()(TransactionTypesSyncContainer);
