import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {Card, Row, Column, Text} from '@matthahn/sally-ui';
import SmallLoader from '../../../layout/components/SmallLoader/SmallLoader';
// import TripPaymentType from '../../../trip/components/TripPaymentType/TripPaymentType';
// import TripVoided from '../../../trip/components/TripVoided/TripVoided';

// Columns
// import columns from './columns';

// Lib
// import orderByDate from '../../../lib/orderByDate';

// Types
import {amount, dateTime} from '../../../types';

const ActiveDriverRentalCard = ({
  loading,
  rental,
  lease,
  trips,
  cashTrips,
  ccTrips,
  cashEarnings,
  ccEarnings,
  totalEarnings,
  // onTrip,
  onUpdateRental,
}) => (
  <Card
    title="Active Rental"
    subtitle="Summary"
    icon="car"
    headerActions={[
      {
        tooltip: 'Update rental rate',
        icon: 'creditcard',
        onClick: onUpdateRental,
      },
    ]}
  >
    {(Content) =>
      loading || !rental || !lease ? (
        <Content padding="none">
          <SmallLoader />
        </Content>
      ) : (
        <Fragment>
          <Content>
            <Row margin>
              <Column size={1 / 2}>
                <Text value={rental.medallion.medallion_number}>Medallion</Text>
              </Column>
              <Column size={1 / 2}>
                <Text value={dateTime(rental.vehicle_pickup_datetime).format()}>
                  Start Date
                </Text>
              </Column>
            </Row>
            <Row margin>
              <Column>
                <Text
                  value={
                    !!rental.created_by && !!rental.created_by.username
                      ? rental.created_by.username
                      : '-'
                  }
                >
                  Dispatcher
                </Text>
              </Column>
            </Row>
            <Row>
              <Column size={1 / 2}>
                <Text value={`$${amount(rental.amount).format()}`}>Rate</Text>
              </Column>
              <Column size={1 / 2}>
                <Text value={lease.contract_type}>Rental Type</Text>
              </Column>
            </Row>
            {/* <Row margin>
              <Column size={1 / 3}>
                <Text value={trips.length}>Number of All Trips</Text>
              </Column>
              <Column size={1 / 3}>
                <Text value={cashTrips.length}>Cash Trips</Text>
              </Column>
              <Column size={1 / 3}>
                <Text value={ccTrips.length}>Card Trips</Text>
              </Column>
            </Row>
            <Row>
              <Column size={1 / 3}>
                <Text value={`$${amount(totalEarnings).format()}`}>
                  Total Earnings
                </Text>
              </Column>
              <Column size={1 / 3}>
                <Text value={`$${amount(cashEarnings).format()}`}>
                  Cash Earnings
                </Text>
              </Column>
              <Column size={1 / 3}>
                <Text value={`$${amount(ccEarnings).format()}`}>
                  Card Earnings
                </Text>
              </Column>
            </Row> */}
          </Content>
          {/* <Content padding="none">
            <Table
              columns={columns}
              loading={loading}
              theme="orange"
              infinite
              noBorder
              noRadius
              smallNoResultsLabel
            >
              {(TableRow) =>
                orderByDate(trips, 'start_datetime', 'desc').map((trip) => (
                  <TableRow key={trip.id} onClick={onTrip(trip)}>
                    {(TableColumn) => [
                      <TableColumn key="trip">
                        <TripVoided trip={trip}>#{trip.id}</TripVoided>
                      </TableColumn>,
                      <TableColumn key="date">
                        <TripVoided trip={trip}>
                          {dateTime(trip.start_datetime).format()}
                        </TripVoided>
                      </TableColumn>,
                      <TableColumn key="type">
                        <TripVoided trip={trip}>
                          <TripPaymentType type={trip.type} />
                        </TripVoided>
                      </TableColumn>,
                      <TableColumn key="price">
                        <TripVoided trip={trip}>
                          ${amount(trip.total_amount).format()}
                        </TripVoided>
                      </TableColumn>,
                    ]}
                  </TableRow>
                ))
              }
            </Table>
          </Content> */}
        </Fragment>
      )
    }
  </Card>
);

ActiveDriverRentalCard.propTypes = {
  loading: PropTypes.bool,
  rental: PropTypes.object,
  lease: PropTypes.object,
  trips: PropTypes.array,
  cashTrips: PropTypes.array,
  ccTrips: PropTypes.array,
  cashEarnings: PropTypes.number,
  ccEarnings: PropTypes.number,
  totalEarnings: PropTypes.number,
  onTrip: PropTypes.func,
  onUpdateRental: PropTypes.func,
};

export default ActiveDriverRentalCard;
