// permission constants
import PERMISSION_TREE from '../constants/permissionTree.constant.permission';
import UNGROUPED_PERMISSION_LABEL from '../constants/ungroupedPermissionLabel.constants.permission';

const generatePermissionTree = (availablePermissions) => {
  const groupedPermissions = Object.values({...PERMISSION_TREE}).flat();
  const ungroupedPermissions = [...availablePermissions]
    .filter((permission) => !groupedPermissions.includes(permission.permission))
    .map(({permission}) => permission);
  return Object.entries({
    [UNGROUPED_PERMISSION_LABEL]: ungroupedPermissions,
    ...PERMISSION_TREE,
  }).reduce((combined, [permissionGroup, permissionsList]) => {
    const groupPermissions = [...permissionsList]
      .map((permission) =>
        [...availablePermissions].find(
          (availablePermission) => availablePermission.permission === permission
        )
      )
      .filter((permission) => !!permission);
    return !!groupPermissions.length
      ? {
          ...combined,
          [permissionGroup]: groupPermissions,
        }
      : combined;
  }, {});
};

export default generatePermissionTree;
