import React from 'react';

// Containers
import Page from '../../../layout/containers/Page/Page';
import MedallionsPagesContainer from '../../containers/MedallionsPagesContainer/MedallionsPagesContainer';

// Page
import PAGE from './page';

const MedallionsPage = () => (
  <Page page={PAGE}>
    <MedallionsPagesContainer />
  </Page>
);

export default MedallionsPage;
