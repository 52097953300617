import styled from 'styled-components';

// colors
import {orange} from '@matthahn/sally-ui/lib/libs/colors';

const Loader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  color: ${orange};
`;

export default Loader;
