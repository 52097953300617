import {SELECT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {string} from '../../types';

export default attribute({
  type: SELECT,
  attribute: 'topper_type',
  api: string,
  label: {
    default: 'Topper Type',
    short: 'Topper Type',
  },
  options: ['NONE', 'STATIC', 'FIREFLY'],
});
