import {folderTree as createFolderTree} from '../../document';

// Files
import * as docs from './folders';

const {folders, flatTree, foldersObject, uiDocs} = createFolderTree(
  Object.values(docs).map((doc) => doc())
);

export {foldersObject, flatTree, uiDocs};

export default folders;
