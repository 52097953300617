import React from 'react';
import PropTypes from 'prop-types';

// local components
import Content from './components/Content';
import Indicator from './components/Indicator/Indicator';

const AccidentDashboardIndicator = ({state, label}) => (
  <Content>
    {state !== 'pending' && <Indicator state={state} />}
    {label}
  </Content>
);

AccidentDashboardIndicator.propTypes = {
  state: PropTypes.string,
  label: PropTypes.node,
};

export default AccidentDashboardIndicator;
