import React from 'react';
import PropTypes from 'prop-types';

// components
import {Modal, Row, Column, CheckBox} from '@matthahn/sally-ui';

// layout components
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

const DriverStatementModal = ({
  loading,
  visible,
  start_date,
  end_date,
  summary,
  onChange,
  onClose,
  onGenerate,
}) => (
  <Modal
    title="Generate statement"
    visible={visible}
    onClose={onClose}
    buttonsRight={[
      {label: 'Generate', onClick: onGenerate, loading, theme: 'orange'},
    ]}
  >
    {(Content) => (
      <Content>
        <Row>
          <Column size={2 / 5}>
            <AttributeInput
              value={start_date}
              onChange={onChange}
              disabled={loading}
            >
              {start_date.label.default}
            </AttributeInput>
          </Column>
          <Column size={2 / 5}>
            <AttributeInput
              value={end_date}
              onChange={onChange}
              disabled={loading}
            >
              {end_date.label.default}
            </AttributeInput>
          </Column>
          <Column size={1 / 5}>
            <CheckBox
              value={summary}
              onChange={() => onChange(!summary, 'summary')}
              disabled={loading}
              theme="orange"
            >
              Summary
            </CheckBox>
          </Column>
        </Row>
      </Content>
    )}
  </Modal>
);

DriverStatementModal.propTypes = {
  loading: PropTypes.bool,
  visible: PropTypes.bool,
  start_date: PropTypes.object,
  end_date: PropTypes.object,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onGenerate: PropTypes.func,
};

export default DriverStatementModal;
