import html2canvas from 'html2canvas';
import dataUriToFile from './dataUriToFile.lib.layout';

export default async (container, fileName = 'test.pdf') => {
  const canvas = await html2canvas(document.querySelector(container));
  const base64URL = canvas
    .toDataURL('image/jpeg')
    .replace('image/jpeg', 'image/octet-stream');
  return dataUriToFile(base64URL, fileName);
};
