import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Card, Row, Column, Form} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

const ContactEdit = ({
  loading,
  errors,
  phone_number,
  phone_number2,
  email,
  onChange,
  onCancel,
  onSave,
}) => (
  <Card
    title="Contact"
    icon="phone"
    headerActions={[
      {
        icon: 'close',
        onClick: onCancel,
        tooltip: 'Exit edit mode',
      },
    ]}
    buttonsRight={[{label: 'Save', loading, onClick: onSave, theme: 'orange'}]}
  >
    {(Content) => (
      <Content>
        <Form onSubmit={onSave} enter>
          <Row margin>
            <Column size={1 / 2}>
              <AttributeInput
                value={phone_number}
                onChange={onChange}
                disabled={loading}
                errors={errors}
              >
                {phone_number.label.default}
              </AttributeInput>
            </Column>
            <Column size={1 / 2}>
              <AttributeInput
                value={phone_number2}
                onChange={onChange}
                disabled={loading}
                errors={errors}
              >
                {phone_number2.label.default}
              </AttributeInput>
            </Column>
          </Row>
          <Row>
            <Column>
              <AttributeInput
                value={email}
                onChange={onChange}
                disabled={loading}
                errors={errors}
              >
                {email.label.default}
              </AttributeInput>
            </Column>
          </Row>
        </Form>
      </Content>
    )}
  </Card>
);

ContactEdit.propTypes = {
  loading: PropTypes.bool,
  errors: PropTypes.array,
  phone_number: PropTypes.object,
  phone_number2: PropTypes.object,
  email: PropTypes.object,
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};

export default ContactEdit;
