import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Info} from '@matthahn/sally-ui';

// medallion redux
import {show as showAct} from '../../containers/StoreMedallionContainer/redux/actions';

// redux
import {connect} from 'react-redux';

const MedallionUnmanagedInfo = ({dispatch, medallion}) => {
  return (
    <Info
      type="error"
      action={{
        onClick: () => dispatch(showAct(medallion)),
        label: 'Back to storage',
      }}
    >
      Medallion unmanaged.
      {!!medallion && !!medallion.medallion_unamanaged && (
        <span> Reason: {medallion.medallion_unamanaged.reason}</span>
      )}
    </Info>
  );
};

MedallionUnmanagedInfo.propTypes = {
  medallion: PropTypes.object,
};

export default connect()(MedallionUnmanagedInfo);
