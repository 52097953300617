import React from 'react';
import PropTypes from 'prop-types';

// Constants
import gasIcon from '../../../gas/constants/icon.const.gas';

// Components
import {TextColor, Icon} from '@matthahn/sally-ui';
import Container from './components/Container';
import Flex from './components/Flex';
import Name from './components/Name';
import Tag from './components/Tag';

// Libs
import accessibility from '../../libs/accessibility.lib.vehicle';

// States
import pairedState from '../../state/paired.state.vehicle';
import decommissionedState from '../../state/decommissioned.state.vehicle';

const naming = ({children, vehicle, display}) => {
  switch (display) {
    case 'medallion':
      return (
        <Name>
          {!!vehicle.medallion && !!vehicle.medallion.medallion_number
            ? vehicle.medallion.medallion_number
            : vehicle.vin}
        </Name>
      );
    case 'vin':
      return <Name>{children}</Name>;

    default:
      return (
        <Name>
          {children}
          {!!vehicle.medallion && (
            <span> - {vehicle.medallion.medallion_number}</span>
          )}
        </Name>
      );
  }
};

const VehicleTags = ({children, vehicle, inline, display}) => (
  <Container inline={inline}>
    <Flex>
      {naming({children, vehicle, display})}
      {accessibility(vehicle.vehicle_type) && <Tag>{'♿'}</Tag>}
      {!!vehicle.repair_etc && (
        <Tag>
          <TextColor theme="darkGrey">
            <Icon icon="tool" />
          </TextColor>
        </Tag>
      )}
      {!vehicle.repair_etc &&
        vehicle.gas_check_done &&
        [pairedState.key].includes(vehicle.state) && (
          <Tag>
            <TextColor theme="green">
              <Icon icon={gasIcon} />
            </TextColor>
          </Tag>
        )}
      {vehicle.state === decommissionedState.key && (
        <Tag>
          <TextColor theme="darkGrey">
            <span role="img" aria-label="ban icon">
              🚫
            </span>
          </TextColor>
        </Tag>
      )}
    </Flex>
  </Container>
);

VehicleTags.propTypes = {
  vehicle: PropTypes.object,
  children: PropTypes.node,
  inline: PropTypes.bool,
  display: PropTypes.oneOf(['default', 'medallion', 'vin']),
};

VehicleTags.defaultProps = {
  inline: false,
  display: 'default',
};

export default VehicleTags;
