import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {set as setAction} from '../../../driver/redux/actions';

// Attributes
import driverAttribute from '../../attributes/driver.attribute.driverNote';
import textAttribute from '../../attributes/text.attribute.driverNote';

// Api
import createApi from '../../api/create.api.driverNote';
import updateApi from '../../api/update.api.driverNote';
import deleteApi from '../../api/delete.api.driverNote';

// Preparation
import createPrep from '../../preparation/create.preparation.driverNote';
import updatePrep from '../../preparation/update.preparation.driverNote';

// Lib
import parseError from '../../../error/parseError';
import {lib} from '@matthahn/sally-ui';

// Components
import {CardLoader} from '@matthahn/sally-ui';
import NotesCard from '../../../note/components/NotesCard/NotesCard';

// Alert
const {alert, notify} = lib;

class DriverNotesContainer extends Component {
  static propTypes = {
    loadingNotes: PropTypes.bool,
    notes: PropTypes.array,
    driver: PropTypes.object,
    dispatch: PropTypes.func,
  };

  state = {
    create: false,
    creating: false,
    text: textAttribute(''),
    update: [],
    updating: [],
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  set = (data) => this.props.dispatch(setAction(data));

  onText = (text) => this.setState({text});

  notification = (noteID) => ({
    id: `DriverNotesContainer__${noteID}`,
    title: `Remove Note #${noteID}`,
    icon: undefined,
    content: 'Are you sure you want to delete this note?',
    primary: {
      label: 'No',
      onClick: () =>
        this.setState({
          updating: [...this.state.updating].filter((u) => u !== noteID),
        }),
    },
    secondary: {
      label: 'Yes',
      onClick: () => this.remove(noteID, false),
    },
    closable: false,
  });

  onUpdateText = (id, text) =>
    this.setState({
      update: [...this.state.update].map((u) => {
        if (u.id === id && !this.isUpdating(id)) u.text = text;
        return u;
      }),
    });

  isUpdating = (id) => this.state.updating.includes(id);

  onUpdateStart = (id) => {
    const note = [...this.props.notes].find((n) => n.id === id);
    if (!note) return;
    this.setState({
      update: [
        ...this.state.update,
        {id: note.id, text: textAttribute(note.text)},
      ],
    });
  };

  onCreateToggle = (open) =>
    this.setState({create: open, text: textAttribute('')});

  onUpdateCancel = (id) => {
    if (this.isUpdating(id)) return;
    this.setState({update: this.state.update.filter((u) => u.id !== id)});
  };

  create = async () => {
    const {notes, driver} = this.props;
    const {creating, text} = this.state;
    if (creating) return;

    this.setState({creating: true});

    try {
      const note = await createPrep({
        text,
        driver: driverAttribute(driver.id),
      });
      const response = await createApi(driver.id, note);
      this.set({notes: [response, ...notes]});
      if (!this.mounted) return;
      this.setState({creating: false, text: textAttribute(''), create: false});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      if (!this.mounted) return;
      this.setState({creating: false});
    }
  };

  update = async (id) => {
    const {update, updating} = this.state;
    if (this.isUpdating(id) || !update.find((u) => u.id === id)) return;

    this.setState({updating: [...updating, id]});

    const {text} = update.find((u) => u.id === id);

    try {
      const note = await updatePrep({text});
      const response = await updateApi(this.props.driver.id, id, note);
      this.set({
        notes: [...this.props.notes].map((n) =>
          n.id === response.id ? response : n
        ),
      });
      if (!this.mounted) return;
      this.setState({
        update: [...this.state.update].filter((u) => u.id !== id),
        updating: [...this.state.updating].filter((u) => u !== id),
      });
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      if (!this.mounted) return;
      this.setState({
        updating: [...this.state.updating].filter((u) => u !== id),
      });
    }
  };

  remove = async (id, prompt = true) => {
    const {updating, update} = this.state;
    if (
      prompt &&
      (this.isUpdating(id) || update.find((u) => u.id === id) !== undefined)
    )
      return;

    if (prompt) {
      this.setState({updating: [...updating, id]});
      notify(this.notification(id));
      return;
    }

    try {
      await deleteApi(this.props.driver.id, id);
      this.set({notes: [...this.props.notes].filter((n) => n.id !== id)});
      if (!this.mounted) return;
      this.setState({
        updating: [...this.state.updating].filter((u) => u !== id),
      });
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      if (!this.mounted) return;
      this.setState({
        updating: [...this.state.updating].filter((u) => u !== id),
      });
    }
  };

  render() {
    const {notes, loadingNotes} = this.props;
    const {text, create, creating, update, updating} = this.state;
    return loadingNotes ? (
      <CardLoader />
    ) : (
      <NotesCard
        notes={notes}
        add={create}
        adding={creating}
        edit={update}
        editing={updating}
        text={text}
        onText={this.onText}
        onAdd={() => this.onCreateToggle(true)}
        onAddCancel={() => this.onCreateToggle(false)}
        onSave={this.create}
        onUpdateStart={this.onUpdateStart}
        onUpdateCancel={this.onUpdateCancel}
        onUpdateText={this.onUpdateText}
        onEdit={this.update}
        onRemove={this.remove}
      />
    );
  }
}

export default connect((state) => ({
  loadingNotes: state.driver.loadingNotes,
  notes: state.driver.notes,
  driver: state.driver.driver,
}))(DriverNotesContainer);
