import styled from 'styled-components';

// Colors
import {lib} from '@matthahn/sally-ui';
const {
  colors: {white, black},
} = lib;

const Input = styled.input`
  width: 100%;
  height: 50px;
  min-height: 50px;
  max-height: 50px;
  padding: 0px 10px;
  background: ${white};
  color: ${black};
  font-family: inherit;
  font-size: 14px;
  display: block;
  margin: 0px;
  outline: none;
  border: none;
`;

export default Input;
