import {DATE} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {date, apiDate} from '../../types';

export default attribute({
  type: DATE,
  attribute: 'medallion_expiry',
  display: date,
  input: date,
  api: apiDate,
  label: {
    default: 'Medallion Expiry',
    short: 'Expiry',
  },
  disabledDates: [{before: new Date()}],
});
