import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

// Api
import createApi from '../../api/create.api.medallionOwner';

// Attributes
import nameAttr from '../../attributes/name.attribute.medallionOwner';
import emailAttr from '../../attributes/email.attribute.medallionOwner';
import addressStreet1Attr from '../../attributes/address_street1.attribute.medallionOwner';
import addressStreet2Attr from '../../attributes/address_street2.attribute.medallionOwner';
import addressCityAttr from '../../attributes/address_city.attribute.medallionOwner';
import addressZipAttr from '../../attributes/address_zip.attribute.medallionOwner';
import addressStateAttr from '../../attributes/address_state.attribute.medallionOwner';
import fedIDAttr from '../../attributes/taxx_id.attribute.medallionOwner';
import phoneNumberAttr from '../../attributes/phone_number.attribute.medallionOwner';
import phoneNumber2Attr from '../../attributes/phone_number2.attribute.medallionOwner';

// Components
import NewMedallionOwner from '../../components/NewMedallionOwner/NewMedallionOwner';

// Libs
import {lib} from '@matthahn/sally-ui';
import parseError from '../../../error/parseError';
import {
  setUnsavedChanges,
  clearUnsavedChanges,
} from '../../../layout/lib/unsavedChanges.lib.layout';

// Preps
import createPrep from '../../preparation/create.preparation.medallionOwner';

// Routes
import medallionOwnerRoute from '../../pages/MedallionOwnerPage/route';

// Notifications
const {alert} = lib;

class NewMedallionOwnerContainer extends Component {
  static propTypes = {
    history: PropTypes.object,
  };

  state = {
    loading: false,
    errors: [],
    name: nameAttr(''),
    email: emailAttr(''),
    address_street1: addressStreet1Attr(''),
    address_street2: addressStreet2Attr(''),
    address_city: addressCityAttr(''),
    address_zip: addressZipAttr(''),
    address_state: addressStateAttr(''),
    taxx_id: fedIDAttr(''),
    phone_number: phoneNumberAttr(''),
    phone_number2: phoneNumber2Attr(''),
  };

  onChange = setUnsavedChanges(
    (value, key, errors) => this.setState({[key]: value, errors}),
    () => this.state.loading
  );

  onCreate = async () => {
    const {loading, errors, ...attributes} = this.state;

    if (loading) return;

    this.setState({loading: true});

    try {
      const rawOwner = await createPrep(attributes);
      const owner = await createApi(rawOwner);
      clearUnsavedChanges();
      this.props.history.push(medallionOwnerRoute(owner.id));
    } catch (error) {
      const {fields, message} = parseError(error);
      alert.error(message);
      this.setState({loading: false, errors: fields});
    }
  };

  render() {
    const {
      loading,
      errors,
      name,
      email,
      address_street1,
      address_street2,
      address_city,
      address_zip,
      address_state,
      taxx_id,
      phone_number,
      phone_number2,
    } = this.state;
    return (
      <NewMedallionOwner
        loading={loading}
        errors={errors}
        name={name}
        email={email}
        address_street1={address_street1}
        address_street2={address_street2}
        address_city={address_city}
        address_zip={address_zip}
        address_state={address_state}
        taxx_id={taxx_id}
        phone_number={phone_number}
        phone_number2={phone_number2}
        onChange={this.onChange}
        onSave={this.onCreate}
      />
    );
  }
}

export default withRouter(NewMedallionOwnerContainer);
