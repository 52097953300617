import {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

// Api
import createVehicleApi from '../../api/create.api.vehicle';

// Constants
import createAttributes from '../../constants/createAttributes.constants.vehicle';

// Libs
import {lib} from '@matthahn/sally-ui';
import parseError from '../../../error/parseError';
import {
  setUnsavedChanges,
  clearUnsavedChanges,
} from '../../../layout/lib/unsavedChanges.lib.layout';

// Preparation
import createPreparation from '../../preparation/create.preparation.vehicle';

// Routes
import vehicleRoute from '../../pages/VehiclePage/route';

// Attributes
import dov from '../../attributes/dov.attribute.vehicle';
import hackup_date from '../../attributes/hackup_date.attribute.vehicle';
import vin from '../../attributes/vin.attribute.vehicle';
import purchase_date from '../../attributes/purchase_date.attribute.vehicle';
import purchase_mileage from '../../attributes/purchase_mileage.attribute.vehicle';
import purchase_price from '../../attributes/purchase_price.attribute.vehicle';
import vehicle_model from '../../attributes/vehicle_model.attribute.vehicle';
import vehicle_year from '../../attributes/vehicle_year.attribute.vehicle';
import vehicle_make from '../../attributes/vehicle_make.attribute.vehicle';
import vehicle_type from '../../attributes/vehicle_type.attribute.vehicle';

// Notifications
const {alert} = lib;

class NewVehicleContainer extends Component {
  static propTypes = {
    lastUpdate: PropTypes.any,
    history: PropTypes.object,
    children: PropTypes.func.isRequired,
    onLoad: PropTypes.func,
  };

  static defaultProps = {
    onLoad: () => {},
  };

  static INITIAL_STATE = () => ({
    loading: false,
    errors: [],

    dov: dov(false),
    vin: vin(''),
    hackup_date: hackup_date(''),
    purchase_date: purchase_date(''),
    purchase_mileage: purchase_mileage(''),
    purchase_price: purchase_price(''),
    vehicle_model: vehicle_model(''),
    vehicle_year: vehicle_year(''),
    vehicle_make: vehicle_make(''),
    vehicle_type: vehicle_type(''),
  });

  state = {
    ...this.constructor.INITIAL_STATE(),
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.lastUpdate !== this.props.lastUpdate) this.init();
  }

  init = () => {
    this.setState({...this.constructor.INITIAL_STATE()});
  };

  onChange = setUnsavedChanges(
    (value, key, errors) => this.setState({[key]: value, errors}),
    () => this.state.loading
  );

  onCreate = async () => {
    const {history, onLoad} = this.props;
    const {loading, errors, ...attributes} = this.state;

    if (loading) return;

    this.setState({loading: true});
    onLoad(true);

    try {
      const vehicle = await createPreparation(attributes);
      const response = await createVehicleApi(vehicle);
      clearUnsavedChanges();
      history.push(vehicleRoute(response.id));
    } catch (error) {
      const {fields, message} = parseError(error);
      alert.error(message);
      this.setState({loading: false, errors: fields});
      onLoad(false);
    }
  };

  render() {
    const {children} = this.props;
    return children({
      ...this.state,
      required: createAttributes,
      onChange: this.onChange,
      onCreate: this.onCreate,
    });
  }
}

export default withRouter(NewVehicleContainer);
