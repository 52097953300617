export default (oldData, newData, compare = null) =>
  [...newData].reduce(
    (combined, newObject) =>
      !!combined.find((oldObject) => oldObject.id === newObject.id)
        ? [...combined].map((oldObject) =>
            oldObject.id === newObject.id
              ? !!compare
                ? compare(oldObject, newObject)
                : newObject
              : oldObject
          )
        : [...combined, newObject],
    [...oldData]
  );
