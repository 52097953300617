import isString from 'is-string';

import type from '../type';

const format = (value) =>
  isString(value) ? value.replace(/[^a-zA-Z0-9]/gi, '').toUpperCase() : '';

// const validate = (value) => /^[A-Z0-9]{8}$/.test(value);
const validate = (value) => /^[A-Z\d]+$/.test(value);

export default type({type: 'string', format, validate});
