import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Heading, Container, Row, Column, Tabs, Tag} from '@matthahn/sally-ui';

// Lib
import accidentState from '../../../accident/state';

// import NegativeParenthesis from '../../../layout/components/NegativeParenthesis/NegativeParenthesis';
// import AccidentTags from '../AccidentTags/AccidentTags';
import PageContainer from './components/Container';
import Header from './components/Header';
import Content from './components/Content';
import HeaderContent from './components/HeaderContent';
import Big from './components/Big';
import Small from './components/Small';
import Space from './components/Space';
import SpaceTag from './components/SpaceTag';
// import StyledLink from './components/StyledLink';
// import Box from './components/Box';
// import Right from './components/Right';
import TabContainer from './components/TabContainer';

// Routes
// import driverRoute from '../../../driver/pages/DriverPage/route';
// import vehicleRoute from '../../../vehicle/pages/VehiclePage/route';

// Types
import {dateTime} from '../../../types';

const AccidentPageLayout = ({
  driver,
  vehicle,
  medallion,
  datetime_of_loss,
  accident_state,
  tab,
  tabs,
  onTab,
  addon,
  children,
}) => (
  <PageContainer>
    <Header>
      <Container>
        <Row>
          <Column>
            <HeaderContent>
              <div>
                <Big>
                  <Heading size="1">
                    {!!driver
                      ? `${driver?.first_name} ${driver?.last_name}`
                      : 'Unknown driver'}{' '}
                    on{' '}
                    {medallion?.medallion_number ||
                      vehicle?.plate ||
                      'Unknown vehicle'}
                  </Heading>
                  <Space flex>
                    <SpaceTag theme="darkGrey" inline>
                      <Tag theme={accidentState(accident_state).color}>
                        {accidentState(accident_state).label}
                      </Tag>
                    </SpaceTag>
                  </Space>
                </Big>
                <Small>{dateTime(datetime_of_loss).format()}</Small>
              </div>
            </HeaderContent>
          </Column>
        </Row>
      </Container>
      <TabContainer>
        <Tabs
          tab={tab}
          tabs={tabs}
          onChange={onTab}
          theme="orange"
          addon={(Addon) =>
            [!!addon && <Addon key="vehicleTabAddon">{addon}</Addon>].filter(
              (a) => !!a
            )
          }
          nav
        />
      </TabContainer>
    </Header>
    <Content>{children}</Content>
  </PageContainer>
);

AccidentPageLayout.propTypes = {
  datetime_of_loss: PropTypes.string,
  driver: PropTypes.object,
  medallion: PropTypes.object,
  vehicle: PropTypes.object,

  tab: PropTypes.string,
  tabs: PropTypes.array,
  onTab: PropTypes.func,
  addon: PropTypes.node,
  children: PropTypes.node,
};

export default AccidentPageLayout;
