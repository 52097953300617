import styled from 'styled-components';

// Colors
// import {red} from '@matthahn/sally-ui/lib/libs/colors';

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  overflow: hidden;

  background: #cb2d3e;
  background: -webkit-linear-gradient(to top, #ef473a, #cb2d3e);
  background: linear-gradient(to top, #ef473a, #cb2d3e);
`;

export default Container;
