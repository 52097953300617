import styled from 'styled-components';

const Content = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding-top: 90px;
`;

export default Content;
