import prepare from '../../attribute/prepare.attribute';

export default async (attributes) => {
  const {sameAsDriverInfo} = prepare(attributes, {
    field: 'api',
    required: [],
  });
  const requiredAttributes = [
    'driver_first_name',
    'driver_last_name',
    'party_type',
  ];
  const {sameAsDriverInfo: asDriverInfo, ...partyInvolved} = prepare(
    attributes,
    {
      field: 'api',
      required: requiredAttributes,
    }
  );
  const registeredAddress = sameAsDriverInfo
    ? {
        registered_owner_address_city: partyInvolved.address_city,
        registered_owner_address_apt_number: partyInvolved.address_street2,
        registered_owner_address_state: partyInvolved.address_state,
        registered_owner_address_street: partyInvolved.address_street1,
        registered_owner_address_zip: partyInvolved.address_zip,
        registered_owner_name_first: partyInvolved.driver_first_name,
        registered_owner_name_last: partyInvolved.driver_last_name,
        registered_owner_name_middle: partyInvolved.driver_middle,
      }
    : {};
  return {...partyInvolved, ...registeredAddress};
};
