import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal, Row, Column} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

const DecommissionVehicleModal = ({
  loading,
  visible,
  decommission_date,
  reason,
  onClose,
  onChange,
  onSave,
}) => (
  <Modal
    title="Decommission vehicle"
    visible={visible}
    onClose={onClose}
    buttonsRight={[
      {label: 'Decommission', onClick: onSave, loading, theme: 'orange'},
    ]}
  >
    {(Content) => (
      <Content>
        <Row margin>
          <Column>
            <AttributeInput
              value={decommission_date}
              onChange={onChange}
              disabled={loading}
            >
              {decommission_date.label.default}
            </AttributeInput>
          </Column>
        </Row>
        <Row>
          <Column>
            <AttributeInput
              value={reason}
              onChange={onChange}
              disabled={loading}
            >
              {reason.label.default}
            </AttributeInput>
          </Column>
        </Row>
      </Content>
    )}
  </Modal>
);

DecommissionVehicleModal.propTypes = {
  loading: PropTypes.bool,
  visible: PropTypes.bool,
  decommission_date: PropTypes.object,
  reason: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  onChange: PropTypes.func,
};

export default DecommissionVehicleModal;
