import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Button, Modal, Table, TextColor, Print} from '@matthahn/sally-ui';
import NegativeParenthesis from '../../../layout/components/NegativeParenthesis/NegativeParenthesis';

// Columns
import columns from './columns';

// Types
import {dateTime} from '../../../types';

const TransactionTypeOverviewModal = ({
  visible,
  transaction,
  amount,
  balance,
  onClose,
}) => {
  const content = !transaction ? (
    ''
  ) : (
    <Table
      columns={columns(transaction.type === 'credit')}
      theme="orange"
      infinite
      noBorder
      noRadius
      smallNoResultsLabel
    >
      {(TableRow) => [
        ...transaction.transactions.map((trans) => (
          <TableRow key={trans.id}>
            {(TableColumn) =>
              [
                <TableColumn key="date">
                  {dateTime(trans.posted_at).format()}
                </TableColumn>,
                transaction.type === 'credit' && (
                  <TableColumn key="type">{trans.subtype}</TableColumn>
                ),
                <TableColumn key="amount">
                  <NegativeParenthesis value={trans.amount} pre="$" />
                </TableColumn>,
                <TableColumn key="balance">
                  <NegativeParenthesis value={trans.balance} pre="$" />
                </TableColumn>,
                <TableColumn key="description" span={2}>
                  {trans.description}
                </TableColumn>,
              ].filter((col) => !!col)
            }
          </TableRow>
        )),
        <TableRow key="sum">
          {(TableColumn) => [
            <TableColumn key="total">
              <TextColor weight="bold">Total</TextColor>
            </TableColumn>,
            <TableColumn key="amount">
              <TextColor weight="bold">
                <NegativeParenthesis value={amount} pre="$" />
              </TextColor>
            </TableColumn>,
            <TableColumn key="balance">
              <TextColor weight="bold">
                <NegativeParenthesis value={balance} pre="$" />
              </TextColor>
            </TableColumn>,
            <TableColumn key="description" />,
          ]}
        </TableRow>,
      ]}
    </Table>
  );
  return (
    <Modal
      title="Transactions overview"
      subtitle={!!transaction ? transaction.label : null}
      visible={visible}
      onClose={onClose}
      size="large"
    >
      {(Content) => (
        <Content padding="none">
          {content}
          <Content>
            <Print
              trigger={({print}) => (
                <Button theme="grey" onClick={print}>
                  Print
                </Button>
              )}
            >
              {content}
            </Print>
          </Content>
        </Content>
      )}
    </Modal>
  );
};

TransactionTypeOverviewModal.propTypes = {
  visible: PropTypes.bool,
  transaction: PropTypes.object,
  amount: PropTypes.number,
  balance: PropTypes.number,
  onClose: PropTypes.func,
};

export default TransactionTypeOverviewModal;
