import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// alert lib
import alert from '@matthahn/sally-ui/lib/libs/alert';

// Api
import listDocumentsApi from '../../../document/api/list.api.document';

// Actions
import {set as setVehicleAct} from '../../redux/actions';

// Components
import {FilePreview} from '@matthahn/sally-ui';
import AttributeFileUploader from '../../../layout/components/AttributeFileUploader/AttributeFileUploader';
import VehicleLicensesCard from '../../components/VehicleLicensesCard/VehicleLicensesCard';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// Files
import {foldersObject} from '../../documents';

// notify lib
import notify from '@matthahn/sally-ui/lib/libs/notify';

// vehicle api
import updateVehicleApi from '../../api/update.api.vehicle';

// UI
const {registration: registrationFile} = foldersObject;

class VehicleLicensesContainer extends Component {
  static propTypes = {
    vehicle: PropTypes.object,
    loading: PropTypes.bool,
    onAttributeRefresh: PropTypes.func,
  };

  state = {
    doc: null,
    docFetching: true,
    loading: false,
    preview: false,
    removingPlate: false,
    showUploader: false,
  };

  componentDidMount() {
    this.mounted = true;
    this.getDocument();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  set = (vehicle) => this.props.dispatch(setVehicleAct({vehicle}));

  isLoading = () => this.state.loading;

  showUploader = () => {
    if (this.isLoading()) return;
    this.setState({showUploader: true});
  };

  hideUploader = () => {
    if (this.isLoading() || !this.state.showUploader) return;
    this.setState({showUploader: false});
  };

  onDone = ({object, files: [file]}) => {
    this.set(object);
    this.props.onAttributeRefresh(registrationFile.attributesList);
    this.setState({showUploader: false, doc: file});
  };

  getDocument = async () => {
    try {
      const {results} = await listDocumentsApi({
        type: registrationFile.type,
        ordering: '-created_at',
        vehicle: this.props.vehicle.id,
      });
      const doc = results.length > 0 ? results[0] : null;
      this.setState({doc, docFetching: false});
    } catch (error) {
      this.setState({docFetching: false});
    }
  };

  openPreview = () => {
    const {doc} = this.state;
    if (!doc) return;
    this.setState({preview: true});
  };

  closePreview = () => {
    this.setState({preview: false});
  };

  onLicenseRemoval = () => {
    if (this.state.removingPlate) return;

    notify({
      title: 'Remove Plate',
      content: 'Are you sure you want to remove the license plate?',
      primary: {
        label: 'No',
        onClick: () => null,
      },
      secondary: {
        label: 'Yes',
        onClick: this.removeLicense,
      },
    });
  };

  removeLicense = async () => {
    const {vehicle} = this.props;
    this.setState({removingPlate: true});

    try {
      const updatedVehicle = await updateVehicleApi(this.props.vehicle.id, {
        plate: null,
      });
      if (!this.mounted) return;
      this.set({...vehicle, plate: updatedVehicle.plate});
      this.props.onAttributeRefresh(['plate']);
      this.setState({removingPlate: false});
    } catch (error) {
      if (!this.mounted) return;
      const {message} = parseError(error);
      alert.error(message);
      this.setState({removingPlate: false});
    }
  };

  render() {
    const {showUploader, doc, preview, removingPlate} = this.state;
    const {vehicle, ...props} = this.props;
    return (
      <Fragment>
        {doc !== null && (
          <FilePreview
            files={[{...doc, url: doc.document_file}]}
            visible={preview}
            onClose={this.closePreview}
          />
        )}
        <VehicleLicensesCard
          {...props}
          canRemovePlate={!!vehicle.plate}
          file={doc}
          onLicenseRemoval={this.onLicenseRemoval}
          onOpen={this.openPreview}
          onShowUploader={this.showUploader}
          removingPlate={removingPlate}
        />
        <AttributeFileUploader
          vehicle={vehicle}
          title="Registration"
          type="vehicle"
          folderType={registrationFile.type}
          visible={showUploader}
          onDone={this.onDone}
          onClose={this.hideUploader}
        />
      </Fragment>
    );
  }
}

export {VehicleLicensesContainer};

export default connect()(VehicleLicensesContainer);
