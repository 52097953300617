import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

export default attribute({
  type: TEXT,
  attribute: 'title_owner',
  label: {
    default: 'Title Owner',
    short: 'Title Owner',
  },
});
