import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Alerts
import alert from '@matthahn/sally-ui/lib/libs/alert';

// Attributes
import * as partyInvolvedAttributes from '../../../accidentPartyInvolved/attributes';

// Api
import createPartyApi from '../../../accidentPartyInvolved/api/create.api.accidentPartyInvolved';
// import listPartiesApi from '../../../accidentPartyInvolved/api/list.api.accidentPartyInvolved';
import updatePartyApi from '../../../accidentPartyInvolved/api/update.api.accidentPartyInvolved';

// Components
import PartiesInvolvedCard from '../../components/PartiesInvolvedCard/PartiesInvolvedCard';

// Error
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// Input Types
import SWITCH from '@matthahn/sally-fw/lib/inputTypes/switch.inputType';
import TOGGLE from '@matthahn/sally-fw/lib/inputTypes/toggle.inputType';

// Preparations
import createPartyPrep from '../../../accidentPartyInvolved/preparation/create.preparation.accidentPartyInvolved';
import updatePartyPrep from '../../../accidentPartyInvolved/preparation/update.preparation.accidentPartyInvolved';

// Helpers
const convertToAttributes = (partyInvolved = null) =>
  Object.entries(partyInvolvedAttributes).reduce((combined, [key, current]) => {
    const defaultValue = [SWITCH, TOGGLE].includes(current.type) ? false : '';
    return {
      ...combined,
      [current.attribute]: current(
        !!partyInvolved
          ? partyInvolved[current.attribute] || defaultValue
          : defaultValue
      ),
    };
  }, {});

class PartiesInvolvedContainer extends Component {
  static propTypes = {
    accident: PropTypes.object,
  };

  state = {
    loading: true,
    saving: false,
    partyInvolved: null,
    ...convertToAttributes(),
  };

  componentDidMount() {
    this.mounted = true;
    this.init();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  init = async () => {
    const {accident} = this.props;
    this.setState({loading: true});
    try {
      // const {results} = await listPartiesApi({accident: accident.id});
      const [partyInvolved] = accident.parties_involved;
      this.setState({
        loading: false,
        partyInvolved: partyInvolved || null,
        ...convertToAttributes(partyInvolved),
      });
    } catch (error) {
      if (!this.mounted) return;
      this.setState({loading: false});
    }
  };

  onChange = (value, key) => {
    const {loading, saving} = this.state;
    if (loading || saving) return;
    this.setState({[key]: value});
  };

  save = async () => {
    const {accident} = this.props;
    const {loading, saving, partyInvolved, dol, ...attributes} = this.state;
    if (loading || saving) return;

    const api = (apiProps) =>
      !!partyInvolved
        ? updatePartyApi(accident.id, partyInvolved.id, apiProps)
        : createPartyApi(accident.id, apiProps);
    const prep = !!partyInvolved ? updatePartyPrep : createPartyPrep;

    this.setState({saving: true});

    try {
      const preparedData = await prep({
        ...attributes,
        dol: partyInvolvedAttributes.dol(accident.id),
      });
      const newPartyInvolved = await api(preparedData);
      if (!this.mounted) return;
      this.setState({saving: false, partyInvolved: newPartyInvolved});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      if (!this.mounted) return;
      this.setState({saving: false});
    }
  };

  render() {
    const {loading, saving, partyInvolved, ...attributes} = this.state;
    return (
      <PartiesInvolvedCard
        saving={saving}
        onChange={this.onChange}
        onSave={this.save}
        {...attributes}
      />
    );
  }
}

export default PartiesInvolvedContainer;
