import {SET} from './constants';

export const show = ({vehicle, hold}) => ({
  type: SET,
  data: {visible: true, vehicle, hold},
});

export const hide = () => ({
  type: SET,
  data: {visible: false},
});
