// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// api lib
import sortQuery from '../../../api/lib/sortQuery.lib.api';

// event HOCs
import subscriptionHoc from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// insurance api
import listInsurancesApi from '../../../insurance/api/list.api.insurance';
import uploadInsuranceDocumentsApi from '../../../insurance/api/uploadDocuments.api.insurance';

// insurance components
import InsurancesList from '../../components/InsurancesList/InsurancesList';

// insurance events
import insuranceCreatedEvt from '../../../insurance/events/created.event.insurance';
import insuranceDeletedEvt from '../../../insurance/events/deleted.event.insurance';
import insuranceUpdatedEvt from '../../../insurance/events/updated.event.insurance';
import showCreateInsuranceModalEvent from '../../../insurance/events/showCreateModal.event.insurance';
import showEditInsuranceModalEvent from '../../../insurance/events/showEditModal.event.insurance';

// libs
import numberOfPages from '../../../lib/numberOfPages';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// react-router
import {withRouter} from 'react-router-dom';

class InsurancesContainer extends Component {
  static propTypes = {
    history: PropTypes.object,
    subscribe: PropTypes.func,
  };

  static OPTIONS = {
    perPage: 50,
  };

  static DEFAULT_SORT = {
    key: 'policy_number',
    direction: 'asc',
  };

  state = {
    insurances: [],
    loading: false,
    page: 1,
    query: {},
    search: '',
    sort: {...this.constructor.DEFAULT_SORT},
    uploading: false,
  };

  componentDidMount() {
    this.mounted = true;
    this.props.subscribe(
      insuranceCreatedEvt.subscribe(this.loadInsurances),
      insuranceDeletedEvt.subscribe(this.loadInsurances),
      insuranceUpdatedEvt.subscribe(this.loadInsurances)
    );
    this.init();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  init = () => {
    this.getInsurances();
  };

  loadInsurances = () => {
    this.getInsurances();
  };

  getInsurances = async ({
    page = this.state.page,
    sort = this.state.sort,
    search = this.state.search,
    query = this.state.query,
  } = {}) => {
    const {perPage} = this.constructor.OPTIONS;
    this.setState({
      loading: true,
      insurances: [],
      page,
      sort,
      search,
      query,
    });

    const actualQuery = {
      ...sortQuery(sort || this.constructor.DEFAULT_SORT),
      limit: perPage,
      offset: (page - 1) * perPage,
      ...query,
    };

    if (!!search.trim().length) actualQuery.search = search;

    try {
      const {results, count} = await listInsurancesApi(actualQuery);
      if (!this.mounted) return;
      this.setState({insurances: results, loading: false, results: count});
    } catch (error) {
      if (!this.mounted) return;
      alert.info(`Failed to fetch insurances: ${error}`);
      this.setState({loading: false, insurances: [], results: 0});
    }
  };

  onSearch = (search) => this.getInsurances({search, page: 1});

  onPage = (page) => this.getInsurances({page});

  onSort = (sort) => this.getInsurances({sort});

  openInsurance = (insurance) => () => {
    showEditInsuranceModalEvent.publish(insurance.id);
  };

  pages = () =>
    numberOfPages(this.state.results, this.constructor.OPTIONS.perPage);

  createInsurance = () => showCreateInsuranceModalEvent.publish();

  uploadZipFile = async (file) => {
    const {uploading} = this.state;
    if (uploading) return;

    this.setState({uploading: true});

    try {
      const formMeta = new FormData();
      formMeta.append('zip_file', file);
      await uploadInsuranceDocumentsApi(formMeta);
      if (!this.mounted) return;
      alert.success('Zip file uploaded');
      this.setState({uploading: false});
    } catch (error) {
      if (!this.mounted) return;
      const {message} = parseError(error);
      alert.error(message);
      this.setState({uploading: false});
    }
  };

  render() {
    const {loading, insurances, page, sort, search, uploading} = this.state;
    return (
      <InsurancesList
        insurances={insurances}
        loading={loading}
        onInsurance={this.openInsurance}
        onNewInsurance={this.createInsurance}
        onPage={this.onPage}
        onSearch={this.onSearch}
        onSort={this.onSort}
        onZipUpload={this.uploadZipFile}
        page={page}
        pages={this.pages()}
        search={search}
        sort={sort}
        uploading={uploading}
      />
    );
  }
}

export default withRouter(subscriptionHoc(InsurancesContainer));
