import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Containers
import TicketsContainer from '../../../ticket/containers/TicketsContainer/TicketsContainer';

class VehicleTicketsContainer extends Component {
  static propTypes = {
    vehicle: PropTypes.object,
  };

  shouldUpdateOnEvent = (ticket) => {
    const {vehicle} = this.props;
    return !!ticket && !!ticket.vehicle && ticket.vehicle.id === vehicle.id;
  };

  render() {
    const {vehicle} = this.props;
    return (
      <TicketsContainer
        query={{vehicle: vehicle.id}}
        shouldUpdateOnEvent={this.shouldUpdateOnEvent}
      />
    );
  }
}

export default connect((state) => ({vehicle: state.vehicle.vehicle}))(
  VehicleTicketsContainer
);
