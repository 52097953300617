import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal, Row, Column, Form} from '@matthahn/sally-ui';
import AttributeInput from '../../../../layout/components/AttributeInput/AttributeInput';

const NewRentalRateModal = ({
  hasPermission,
  visible,
  amount,
  loading,
  onSave,
  onChange,
  onClose,
}) => (
  <Modal
    icon="creditcard"
    title="New Rental Rate"
    visible={visible}
    onClose={onClose}
    size="small"
    buttonsRight={[
      {
        label: 'Apply',
        theme: 'orange',
        onClick: onSave,
        loading: loading,
      },
    ]}
  >
    {(Component) => (
      <Component>
        <Form onSubmit={onSave} enter>
          <Row>
            <Column>
              <AttributeInput
                value={amount}
                onChange={onChange}
                disabled={loading}
                preValue="$"
              >
                Set new rental rate
              </AttributeInput>
            </Column>
          </Row>
        </Form>
      </Component>
    )}
  </Modal>
);

NewRentalRateModal.propTypes = {
  hasPermission: PropTypes.bool,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  amount: PropTypes.object,

  onSave: PropTypes.func,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
};

export default NewRentalRateModal;
