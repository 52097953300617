import isArray from 'isarray';
import isNumber from 'is-number';
import isObject from 'is-object';
import isString from 'is-string';
import {isDate} from 'date-fns';

export default (type = null, value = undefined) => {
  switch (type) {
    case 'string':
      return isString(value);
    case 'number':
      return isNumber(value) && !isString(value);
    case 'boolean':
      return value === true || value === false;
    case 'object':
      return value === null || isObject(value);
    case 'array':
      return value === null || isArray(value);
    case 'any':
      return true;
    case 'date':
      return isDate(value) || isString(value);
    default:
      return false;
  }
};
