export default [
  {
    key: 'medallion',
    label: 'Medallion',
  },
  {
    key: 'year',
    label: 'Year',
  },
  {
    key: 'model',
    label: 'Model',
  },
];
