import f3tch from 'f3tch';

// Events
import event from '../events/employeeAssigned.event.toll';

// Profile
import profile from '../../api/profiles/json.profile.api';

export default async ({toll = null, employee} = {}) => {
  const response = await f3tch('/tolls/assign_toll_to_employee/')
    .profile(profile())
    .body({toll, employee})
    .post();
  event.pub(response);
  return response;
};

