import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Components
import SelectMedallionOwnerModal from '../../components/SelectMedallionOwnerModal/SelectMedallionOwnerModal';

class SelectMedallionOwnerContainer extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    medallionOwners: PropTypes.array,
    exclude: PropTypes.array,
    onSelect: PropTypes.func,
    onClose: PropTypes.func,
  };

  static defaultProps = {
    exclude: [],
  };

  state = {
    search: '',
  };

  componentDidMount() {
    if (this.props.visible) this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.visible && this.props.visible) this.init();
  }

  init = () => {
    this.setState({search: ''});
  };

  onSearch = (search) => this.setState({search});

  owners = () => {
    const {medallionOwners, exclude} = this.props;
    const {search: rawSearch} = this.state;
    const search = rawSearch.toLowerCase();
    const owners = !!exclude.length
      ? [...medallionOwners].filter((owner) => !exclude.includes(owner.id))
      : medallionOwners;
    return !!search.length
      ? [...owners].filter((owner) =>
          [owner.name, owner.address_city, owner.fed_id]
            .filter((q) => !!q)
            .some((q) => q.toLowerCase().includes(search))
        )
      : owners;
  };

  select = (owner) => () => this.props.onSelect(owner);

  render() {
    const {visible, onClose} = this.props;
    const {search} = this.state;
    return (
      <SelectMedallionOwnerModal
        visible={visible}
        search={search}
        owners={this.owners()}
        onClose={onClose}
        onSelect={this.select}
        onSearch={this.onSearch}
      />
    );
  }
}

export default connect((state) => ({
  medallionOwners: state.medallionOwner.list,
}))(SelectMedallionOwnerContainer);
