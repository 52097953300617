import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Steps
import SelectDriverStepContainer from '../../../driver/containers/SelectDriverStepContainer/SelectDriverStepContainer';
import SelectRentalStepContainer from '../../../rental/containers/SelectRentalStepContainer/SelectRentalStepContainer';
import CreateAccidentStepContainer from '../CreateAccidentStepContainer/CreateAccidentStepContainer';

// Components
import {Flow} from '@matthahn/sally-ui';

// Event
import startNewAccidentFlowEvt from '../../events/startNewAccidentFlow.event.accident';

// Subscribe
import subscriptionHoc from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

class NewAccidentFlowContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
  };

  static INITIAL_STATE = {
    visible: false,
    disabled: false,
    hide: false,
    step: 1,
    done: 0,
    driver: null,
    rental: null,
    accident: null,
    goingBack: false,
  };

  state = {
    ...this.constructor.INITIAL_STATE,
  };

  componentDidMount() {
    this.props.subscribe(startNewAccidentFlowEvt.subscribe(this.init));
  }

  init = () => {
    this.setState({
      ...this.constructor.INITIAL_STATE,
      visible: true,
    });
  };

  onStep = (step, ignoreCheck = false) => {
    const {disabled, done, step: currentStep} = this.state;
    const goingBack = step < currentStep;
    if (!ignoreCheck && disabled) return;
    const newDone = step - 1 > done ? step - 1 : done;
    this.setState({step, done: newDone, goingBack});
  };

  onStepChange = (step) => this.onStep(step, true);

  onStepReset = (step) => () => this.setState({step, done: step - 1});

  onDone = (step) => () => this.onStep(true)(step);

  onStepComplete = (step) => (data = {}) => {
    const {disabled} = this.state;
    if (disabled) return;
    this.setState(data);
    this.onStep(step);
  };

  close = (ignoreDisabled = false) => {
    const {visible, disabled} = this.state;
    if (!visible || (disabled && !ignoreDisabled)) return;
    this.setState({visible: false});
  };

  disable = (disabled) => this.setState({disabled});

  finish = () => this.close(true);

  hide = () => this.setState({hide: true});

  show = () => this.setState({hide: false});

  flows = () => [{title: 'Driver'}, {title: 'Vehicle'}, {title: 'Accident'}];

  content = () => (Content) => {
    const {visible, step, driver, rental, accident, goingBack} = this.state;

    const props = {
      Container: Content,
      visible,
      accident,
      driver,
      rental,
      goingBack,
      onShow: this.show,
      onHide: this.hide,
      onDisabled: this.disable,
      onClose: this.close,
    };
    switch (step) {
      case 1:
        return (
          <SelectDriverStepContainer
            onNext={this.onStepComplete(2)}
            {...props}
          />
        );
      case 2:
        return (
          <SelectRentalStepContainer
            onBack={this.onStepComplete(1)}
            onNext={this.onStepComplete(3)}
            {...props}
          />
        );
      case 3:
        return (
          <CreateAccidentStepContainer
            onBack={this.onStepComplete(2)}
            onComplete={this.finish}
            {...props}
          />
        );
      default:
        return <Content>You even know what you're doing here?</Content>;
    }
  };

  render() {
    const {visible, step, done, hide} = this.state;
    const flows = this.flows();
    const flow = flows[step - 1];
    return (
      <Flow
        flows={flows}
        title={flow.title}
        visible={visible && !hide}
        onClose={this.close}
        step={step}
        done={done}
        size={flow.size || 'medium'}
        onStep={this.onStepChange}
      >
        {this.content()}
      </Flow>
    );
  }
}

export default subscriptionHoc(NewAccidentFlowContainer);
