import {getTime, parseISO} from 'date-fns';
import syncObjects from './syncObjects';

export default (oldData, newData) =>
  syncObjects(oldData, newData, (oldObject, newObject) =>
    (!!newObject.modified_at ? getTime(parseISO(newObject.modified_at)) : 1) >=
    (!!oldObject.modified_at ? getTime(parseISO(oldObject.modified_at)) : 0)
      ? newObject
      : oldObject
  );
