export default [
  'address_street1',
  'address_city',
  'address_state',
  'address_zip',
  'date_of_birth',
  'dmv_license_expiry',
  'dmv_license_number',
  'dmv_license_state',
  'fhv_license_expiry',
  'fhv_license_number',
];
