import sum from '../../lib/sum';

export default (transactions) => {
  const [earningTransaction, creditTransaction, ...allOtherTransactions] = [
    ...transactions,
  ].reduce(
    (combined, transaction) => {
      if (transaction.owed_to_sally) return [...combined, transaction];
      const [earning, credit, ...otherTransactions] = combined;
      if (transaction.type === 'cc') {
        earning.balance = sum(earning.balance, transaction.balance);
        earning.transactions = [
          ...earning.transactions,
          ...transaction.transactions,
        ];
      } else {
        credit.balance = sum(credit.balance, transaction.balance);
        credit.transactions = [
          ...credit.transactions,
          ...transaction.transactions,
        ];
      }
      return [earning, credit, ...otherTransactions];
    },
    [
      {
        balance: 0,
        key: 'earnings',
        type: 'earnings',
        subtype: 'earnings',
        label: 'Earnings',
        owed_to_sally: false,
        transactions: [],
      },
      {
        balance: 0,
        key: 'credit',
        type: 'credit',
        subtype: 'credit',
        label: 'Credit',
        owed_to_sally: false,
        transactions: [],
      },
    ]
  );
  return [...allOtherTransactions, earningTransaction, creditTransaction];
};
