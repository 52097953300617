import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

// Actions
import {set as setDispatcherAct} from './redux/actions';
import {show as showDispatchVehicleFlowAct} from '../../../dispatch/containers/DispatchVehicleFlowContainer/redux/actions';

// Api
import listVehiclesApi from '../../../vehicle/api/listWithActiveRentals.api.vehicle';

// Components
import DispatcherCard from '../../components/DispatcherCard/DispatcherCard';
import DriversPreviewModal from '../../components/DriversPreviewModal/DriversPreviewModal';

// Containers
import PayoutActionModalContainer from '../../../payout/containers/PayoutActionModalContainer/PayoutActionModalContainer';

// dispatch containers
import DispatchAlertsContainer from '../DispatchAlertsContainer/DispatchAlertsContainer';

// driverAlert events
import driverAlertCreatedEvent from '../../../driverAlert/events/created.event.driverAlert';
import driverAlertUpdatedEvent from '../../../driverAlert/events/updated.event.driverAlert';

// driverAlert sockets
import driverAlertCreatedSocket from '../../../driverAlert/sockets/created.socket.driverAlert';
import driverAlertUpdatedSocket from '../../../driverAlert/sockets/updated.socket.driverAlert';

// event HOCs
import subscriptionHoc from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// Lib
import {lib} from '@matthahn/sally-ui';
// import isRentable from '../../lib/isRentable.lib.dispatch';
import formatVehicles from '../../lib/formatVehicles.lib.dispatch';
import vehiclesSearch from '../../lib/vehiclesSearch.lib.dispatch';
import orderByDate from '../../../lib/orderByDate';
import fkOrId from '../../../lib/fkOrId';

// Query
import {isIn, isNull} from '../../../api/queries/queries';

// Events
import eventRace from '../../../events/race.event';
import vehicleUpdatedEvt from '../../../vehicle/events/updated.event.vehicle';
import dispatchedEvt from '../../events/dispatched.event.dispatch';
import hotswappedEvt from '../../../rental/events/hotswapped.event.rental';
import endedWithPayoutEvt from '../../../rental/events/endedWithPayout.event.rental';
import endedWithoutPayoutEvt from '../../../rental/events/endedWithoutPayout.event.rental';

// Sockets
import dispatchedSocket from '../../../rental/sockets/dispatched.socket.rental';
import rentalEndedSocket from '../../../rental/sockets/ended.socket.rental';
import hotswapSocket from '../../../rental/sockets/hotswap.socket.rental';
import putOnHoldSocket from '../../../vehicleHold/socket/putOnHold.socket.vehicleHold';
import removeFromHoldSocket from '../../../vehicleHold/socket/removeFromHold.socket.vehicleHold';

// States
import pairedState from '../../../vehicle/state/paired.state.vehicle';
import activeState from '../../../vehicle/state/active.state.vehicle';

// Permissions
import dispatchPermission from '../../permission/dispatchDriver.permission.dispatch';

// Routes
import driverRoute from '../../../driver/pages/DriverPage/route';
import medallionRoute from '../../../medallion/pages/MedallionPage/route';
import vehicleRoute from '../../../vehicle/pages/VehiclePage/route';

// vehicleAlert events
import vehicleAlertCreatedEvent from '../../../vehicleAlert/events/created.event.vehicleAlert';
import vehicleAlertUpdatedEvent from '../../../vehicleAlert/events/updated.event.vehicleAlert';

// vehicleAlert sockets
import vehicleAlertCreatedSocket from '../../../vehicleAlert/sockets/created.socket.vehicleAlert';
import vehicleAlertUpdatedSocket from '../../../vehicleAlert/sockets/updated.socket.vehicleAlert';

// vehicleHold events
import vehicleReleasedFromHoldEvent from '../../../vehicleHold/events/released.event.vehicleHold';
import vehicleHoldUpdatedEvent from '../../../vehicleHold/events/updated.event.vehicleHold';

// Alert
const {alert} = lib;

class DispatcherContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.object,
    vehicles: PropTypes.array,
    subscribe: PropTypes.func,
    creatingAlert: PropTypes.bool,
    updatingAlert: PropTypes.bool,
  };

  static LIMIT = 50;

  state = {
    loading: true,
    search: '',
    display: 'all',
    actionVisible: false,
    actionVehicle: null,
    driversPreviewVisible: false,
    driversPreviewVehicle: null,
    driversPreview: [],
    dispatchAlertsVisible: false,
    dispatchAlertsVehicle: null,
  };

  componentDidMount() {
    this.mounted = true;
    this.getVehicles();
    this.subscribe();
  }

  componentWillUnmount() {
    this.mounted = false;
    this.unsubscribe();
  }

  subscriptions = [];

  subscribe = () => {
    this.subscriptions = [
      // Sockets
      dispatchedSocket.subscribe(this.onDispatched),
      rentalEndedSocket.subscribe(this.onRentalEnd),
      hotswapSocket.subscribe(this.onHotswap),
      putOnHoldSocket.subscribe(this.putOnHold),
      removeFromHoldSocket.subscribe(this.removeFromHold),

      // Events
      vehicleUpdatedEvt.sub(this.onVehicleUpdate),
      dispatchedEvt.sub(this.onDispatched),
      hotswappedEvt.sub(this.onHotswap),
      endedWithoutPayoutEvt.sub(this.onRentalEnd),
      endedWithPayoutEvt.sub(this.onRentalEnd),
      vehicleReleasedFromHoldEvent.sub(this.vehicleHoldUpdated),
      vehicleHoldUpdatedEvent.sub(this.vehicleHoldUpdated),
    ];

    this.props.subscribe(
      driverAlertCreatedEvent.subscribe(this.driverAlertCreated(false)),
      driverAlertUpdatedEvent.subscribe(this.driverAlertUpdated(false)),
      driverAlertCreatedSocket.subscribe(this.driverAlertCreated(true)),
      driverAlertUpdatedSocket.subscribe(this.driverAlertUpdated(true)),
      vehicleAlertCreatedEvent.subscribe(this.vehicleAlertCreated(false)),
      vehicleAlertUpdatedEvent.subscribe(this.vehicleAlertUpdated(false)),
      vehicleAlertCreatedSocket.subscribe(this.vehicleAlertCreated(true)),
      vehicleAlertUpdatedSocket.subscribe(this.vehicleAlertUpdated(true))
    );
  };

  unsubscribe = () => {
    this.subscriptions.forEach((unsubscribe) => unsubscribe());
    this.subscriptions = [];
  };

  vehicleHoldUpdated = (vehicleHold) => {
    const vehicles = [...this.props.vehicles].map((vehicle) => {
      if (vehicle.id !== fkOrId(vehicleHold.vehicle)) return vehicle;
      return {
        ...vehicle,
        vehicle_hold: !!vehicleHold.date_released ? null : vehicleHold,
      };
    });
    this.props.dispatch(setDispatcherAct({vehicles}));
  };

  vehicleAlertCreated =
    (socket = false) =>
    (vehicleAlert) => {
      const {vehicles, creatingAlert} = this.props;
      if (socket && creatingAlert) return;
      const updatedVehicles = [...vehicles].map((vehicle) =>
        vehicle.id === fkOrId(vehicleAlert.vehicle)
          ? {
              ...vehicle,
              vehicle_alerts_unresolved: !!vehicle.vehicle_alerts_unresolved
                ? [...vehicle.vehicle_alerts_unresolved, vehicleAlert]
                : [vehicleAlert],
            }
          : vehicle
      );
      this.props.dispatch(setDispatcherAct({vehicles: updatedVehicles}));
    };

  vehicleAlertUpdated =
    (socket = false) =>
    (vehicleAlert) => {
      if (!vehicleAlert.resolved) return;
      const {vehicles, updatingAlert} = this.props;
      if ((socket && updatingAlert) || !vehicleAlert.resolved) return;
      const updatedVehicles = [...vehicles].map((vehicle) => {
        if (vehicle.id !== fkOrId(vehicleAlert.vehicle)) return vehicle;
        const vehicle_alerts_unresolved = !!vehicle.vehicle_alerts_unresolved
          ? [...vehicle.vehicle_alerts_unresolved].filter(
              (alert) => alert.id !== vehicleAlert.id
            )
          : [];
        return {
          ...vehicle,
          vehicle_alerts_unresolved,
        };
      });
      this.props.dispatch(setDispatcherAct({vehicles: updatedVehicles}));
    };

  driverAlertCreated =
    (socket = false) =>
    (driverAlert) => {
      const {vehicles, creatingAlert} = this.props;
      if (socket && creatingAlert) return;
      const updatedVehicles = [...vehicles].map((vehicle) =>
        !vehicle.active_rentals
          ? vehicle
          : {
              ...vehicle,
              active_rentals: [...vehicle.active_rentals].map((rental) =>
                fkOrId(rental.driver) === fkOrId(driverAlert.driver)
                  ? {
                      ...rental,
                      driver: {
                        ...rental.driver,
                        driver_alerts_unresolved: !!rental.driver
                          .driver_alerts_unresolved
                          ? [
                              ...rental.driver.driver_alerts_unresolved,
                              driverAlert,
                            ]
                          : [driverAlert],
                      },
                    }
                  : rental
              ),
            }
      );
      this.props.dispatch(setDispatcherAct({vehicles: updatedVehicles}));
    };

  driverAlertUpdated =
    (socket = false) =>
    (driverAlert) => {
      if (!driverAlert.resolved) return;
      const {vehicles, updatingAlert} = this.props;
      if ((socket && updatingAlert) || !driverAlert.resolved) return;
      const updatedVehicles = [...vehicles].map((vehicle) =>
        !vehicle.active_rentals
          ? vehicle
          : {
              ...vehicle,
              active_rentals: [...vehicle.active_rentals].map((rental) => {
                if (fkOrId(rental.driver) !== fkOrId(driverAlert.driver))
                  return rental;
                const driver_alerts_unresolved = !!rental.driver
                  .driver_alerts_unresolved
                  ? [...rental.driver.driver_alerts_unresolved].filter(
                      (alert) => alert.id !== driverAlert.id
                    )
                  : [];
                return {
                  ...rental,
                  driver: {
                    ...rental.driver,
                    driver_alerts_unresolved,
                  },
                };
              }),
            }
      );
      this.props.dispatch(setDispatcherAct({vehicles: updatedVehicles}));
    };

  putOnHold = (hold) => {
    const {actionVisible, actionVehicle} = this.state;
    const vehicles = [...this.props.vehicles].map((vehicle) =>
      vehicle.id === fkOrId(hold.vehicle)
        ? {...vehicle, vehicle_hold: hold}
        : vehicle
    );
    this.props.dispatch(setDispatcherAct({vehicles}));

    if (
      !actionVisible ||
      !actionVehicle ||
      actionVehicle.id !== fkOrId(hold.vehicle)
    )
      return;
    this.setState({actionVehicle: {...actionVehicle, vehicle_hold: {...hold}}});
  };

  removeFromHold = (hold) => {
    const {actionVisible, actionVehicle} = this.state;

    const vehicles = [...this.props.vehicles].map((vehicle) =>
      vehicle.id === fkOrId(hold.vehicle)
        ? {...vehicle, vehicle_hold: null}
        : vehicle
    );
    this.props.dispatch(setDispatcherAct({vehicles}));

    if (
      !actionVisible ||
      !actionVehicle ||
      actionVehicle.id !== fkOrId(hold.vehicle)
    )
      return;
    this.setState({actionVehicle: {...actionVehicle, vehicle_hold: null}});
  };

  rentalAlreadyExists = (vehicle, rental) =>
    !!vehicle.active_rentals &&
    !!vehicle.active_rentals.find((oldRental) => oldRental.id === rental.id);

  onHotswap = eventRace(({hotswaps}) => {
    const vehicles = [...this.props.vehicles].map((vehicle) => {
      if (!vehicle.active_rentals) vehicle.active_rentals = [];
      vehicle.active_rentals = [...hotswaps].reduce(
        (combined, {old_rental, new_rental}) => {
          let newCombined = [...combined];
          if (vehicle.id === fkOrId(old_rental.vehicle))
            newCombined = [...newCombined].filter(
              (rental) => rental.id !== old_rental.id
            );
          if (
            vehicle.id === fkOrId(new_rental.vehicle) &&
            !combined.find((rental) => rental.id === new_rental.id)
          )
            newCombined = [...newCombined, new_rental];
          return newCombined;
        },
        [...vehicle.active_rentals]
      );

      return vehicle;
    });

    this.props.dispatch(setDispatcherAct({vehicles}));
  });

  onDispatched = eventRace((active_rental) => {
    const rental = Array.isArray(active_rental)
      ? active_rental[0]
      : active_rental;

    const vehicles = [...this.props.vehicles].map((vehicle) =>
      vehicle.id === fkOrId(rental.vehicle) &&
      !this.rentalAlreadyExists(vehicle, rental)
        ? {
            ...vehicle,
            active_rentals: !vehicle.active_rentals
              ? [rental]
              : [...vehicle.active_rentals, rental],
          }
        : vehicle
    );

    this.props.dispatch(setDispatcherAct({vehicles}));
  });

  onRentalEnd = eventRace((active_rental) => {
    const vehicles = [...this.props.vehicles].map((vehicle) =>
      vehicle.id === fkOrId(active_rental.vehicle)
        ? {
            ...vehicle,
            active_rentals: !vehicle.active_rentals
              ? []
              : [...vehicle.active_rentals].filter(
                  (ac) => ac.id !== active_rental.id
                ),
          }
        : vehicle
    );
    this.props.dispatch(setDispatcherAct({vehicles}));
  });

  onVehicleUpdate = ({id, plate}) => {
    const vehicles = [...this.props.vehicles].map((vehicle) =>
      vehicle.id === id ? {...vehicle, plate} : vehicle
    );
    this.props.dispatch(setDispatcherAct({vehicles}));
  };

  displayOptions = () => [
    {
      value: 'all',
      label: 'All',
      count: this.vehicles({search: '', display: 'all'}).length,
    },
    {
      value: 'available',
      label: 'Available',
      count: this.vehicles({search: '', display: 'available'}).length,
    },
    {
      value: 'dispatched',
      label: 'Active',
      count: this.vehicles({search: '', display: 'dispatched'}).length,
    },
    {
      value: 'hold',
      label: 'Hold',
      count: this.vehicles({search: '', display: 'hold'}).length,
    },
  ];

  vehicles = ({
    search = this.state.search,
    display = this.state.display,
  } = {}) => {
    const {vehicles} = this.props;
    return vehiclesSearch({vehicles, search, display});
  };

  getVehicles = async () => {
    this.setState({loading: true});
    this.props.dispatch(setDispatcherAct({loading: true}));
    const vehicles = await this.getVehicleBatch();
    this.props.dispatch(setDispatcherAct({loading: false, vehicles}));
    if (!this.mounted) return;
    this.setState({loading: false});
  };

  getVehicleBatch = async ({vehicles = [], page = 1} = {}) => {
    const {LIMIT} = this.constructor;
    try {
      const {results, next} = await listVehiclesApi({
        [isNull('medallion')]: false,
        limit: LIMIT,
        offset: (page - 1) * LIMIT,
        [isIn('state')]: `${pairedState.key},${activeState.key}`,
        ordering: 'medallion__medallion_number',
      });
      const newVehicles = [...vehicles, ...results];
      if (!this.mounted) return newVehicles;
      // this.props.dispatch(setDispatcherAct({vehicles: newVehicles}));
      return !!next
        ? this.getVehicleBatch({vehicles: newVehicles, page: page + 1})
        : newVehicles;
    } catch (error) {
      return vehicles;
    }
  };

  onSearch = (search) => this.setState({search});

  onDisplay = (display) => this.setState({display});

  onAction = (vehicle) => () => {
    const {dispatch} = this.props;
    // if (!isRentable(vehicle))
    //   return alert.warning(
    //     'This vehicle is not ready to be rented. Check the vehicle and the medallion.'
    //   );
    if (!vehicle.rental && !vehicle.medallion)
      return alert.warning(
        'Vehicle does not have a medallion number associated'
      );
    if (!!vehicle.rental) return this.showPayoutAction(vehicle);
    if (!dispatchPermission())
      return alert.warning('You do not have permission to dispatch drivers');
    dispatch(showDispatchVehicleFlowAct(vehicle));
  };

  onMedallion = (vehicle) => () => {
    if (!vehicle.medallion) return;
    this.props.history.push(medallionRoute(vehicle.medallion.id));
  };

  onVehicle = (vehicle) => () => {
    if (!vehicle) return;
    this.props.history.push(vehicleRoute(vehicle.id));
  };

  onDriver = (vehicle) => () => {
    if (!vehicle.driver.id) return this.onAction(vehicle)();
    this.props.history.push(driverRoute(vehicle.driver.id));
  };

  onDrivers = (vehicle) => () => {
    if (!vehicle.active_rentals || vehicle.active_rentals.length <= 1)
      return this.onAction(vehicle)();
    const drivers = orderByDate(
      [...vehicle.active_rentals].map((activeRental) => ({
        ...activeRental,
        lastActivityRecord: !!activeRental.curb_rental
          ? activeRental.curb_rental.start_datetime ||
            activeRental.curb_rental.created_at
          : 0,
      })),
      'lastActivityRecord',
      'desc'
    ).map((rental) => ({
      ...rental.driver,
      lastActivityRecord: rental.lastActivityRecord || null,
      lease: rental.lease_agreement,
    }));
    this.showDriversPreview({drivers, vehicle});
  };

  onDriverPreview = (driver) => () =>
    this.props.history.push(driverRoute(driver.id));

  showPayoutAction = (vehicle) =>
    this.setState({actionVisible: true, actionVehicle: {...vehicle}});

  hidePayoutAction = () => this.setState({actionVisible: false});

  showDriversPreview = ({drivers, vehicle}) =>
    this.setState({
      driversPreview: drivers,
      driversPreviewVehicle: vehicle,
      driversPreviewVisible: true,
    });

  hideDriversPreview = () => this.setState({driversPreviewVisible: false});

  showAlerts = (vehicle) => () => {
    this.setState({
      dispatchAlertsVisible: true,
      dispatchAlertsVehicle: vehicle,
    });
  };

  hideAlerts = () => {
    this.setState({dispatchAlertsVisible: false});
  };

  render() {
    const {
      loading,
      search,
      display,
      actionVisible,
      actionVehicle,
      driversPreview,
      driversPreviewVehicle,
      driversPreviewVisible,
      dispatchAlertsVisible,
      dispatchAlertsVehicle,
    } = this.state;

    return (
      <Fragment>
        <DispatcherCard
          loading={loading}
          vehicles={formatVehicles(this.vehicles())}
          search={search}
          display={display}
          displayOptions={this.displayOptions()}
          onAction={this.onAction}
          onSearch={this.onSearch}
          onDisplay={this.onDisplay}
          onMedallion={this.onMedallion}
          onVehicle={this.onVehicle}
          onDriver={this.onDriver}
          onDrivers={this.onDrivers}
          onAlert={this.showAlerts}
        />
        <PayoutActionModalContainer
          visible={actionVisible}
          vehicle={actionVehicle}
          onClose={this.hidePayoutAction}
        />
        <DriversPreviewModal
          visible={driversPreviewVisible}
          vehicle={driversPreviewVehicle}
          drivers={driversPreview}
          onClose={this.hideDriversPreview}
          onClick={this.onDriverPreview}
        />
        <DispatchAlertsContainer
          visible={dispatchAlertsVisible}
          vehicle={dispatchAlertsVehicle}
          onClose={this.hideAlerts}
        />
      </Fragment>
    );
  }
}

export default connect((state) => ({
  vehicles: state.dispatcher.vehicles,
  ...state.alert,
}))(withRouter(subscriptionHoc(DispatcherContainer)));
