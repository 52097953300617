import React from 'react';

// Containers
import Page from '../../../layout/containers/Page/Page';
import VehiclesPagesContainer from '../../containers/VehiclesPagesContainer/VehiclesPagesContainer';

// Page
import PAGE from './page';

const VehiclesPage = () => (
  <Page page={PAGE}>
    <VehiclesPagesContainer />
  </Page>
);

export default VehiclesPage;
