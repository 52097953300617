import styled from 'styled-components';

const Scroller = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
`;

export default Scroller;
