import {SELECT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

export default attribute({
  type: SELECT,
  attribute: 'subtype',
  label: {
    default: 'Type',
    short: 'Type',
  },
  // options: [
  //   {key: 'rent', label: 'Rent'},
  //   {key: 'inspection', label: 'Inspection (Emissions only)'},
  //   {key: 'late_fee', label: 'Late Fee Offset'},
  //   {key: 'early_term', label: 'Early Term Offset'},
  //   {key: 'referral', label: 'Referral'},
  //   {key: 'promo', label: 'Promo'},
  //   {key: 'other', label: 'Other'},
  // ],
});
