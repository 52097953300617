import styled from 'styled-components';

// Colors
import {lib} from '@matthahn/sally-ui';
const {
  colors: {grey},
} = lib;

const Content = styled.div`
  width: 100%;
  margin-top: 56px;
  height: calc(100% - 56px);
  border-style: solid none none none;
  border-width: 1px;
  border-color: ${grey};
  overflow: scroll;
`;

export default Content;
