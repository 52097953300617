import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

export default attribute({
  type: TEXT,
  attribute: 'payment_terms',
  label: {
    default: 'Payment Terms',
    short: 'Payment Terms',
  },
});
