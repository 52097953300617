const columns = [
  {
    key: 'id',
    label: 'ID',
    span: 0.5,
  },
  {
    key: 'datetime_of_loss',
    label: 'Date',
  },
  {
    key: 'driver',
    label: 'Driver',
  },
  {
    key: 'vehicle',
    label: 'Vehicle',
  },
  {
    key: 'state',
    label: 'State',
  },
];

export default columns;
