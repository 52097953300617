import styled from 'styled-components';

const Content = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  gap: 4px;
`;

export default Content;
