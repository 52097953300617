import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {zip} from '../../types';

export default attribute({
  type: TEXT,
  attribute: 'poc1_address_zip',
  display: zip,
  input: zip,
  api: zip,
  label: {
    default: 'Zip Code',
    short: 'Zip',
  },
});
