import styled from 'styled-components';

// Components
import {Icon} from '@matthahn/sally-ui';

// Colors
import {lib} from '@matthahn/sally-ui';
const {
  colors: {orange},
} = lib;

const Loader = styled(Icon)`
  font-size: 50px !important;
  color: ${orange};
`;

export default Loader;
