import styled from 'styled-components';

const ButtonContainer = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10000000;
`;

export default ButtonContainer;
