import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {string} from '../../types';

export default attribute({
  type: TEXT,
  attribute: 'insurance_adjuster_name',
  display: string,
  input: string,
  api: string,
  label: {
    default: 'Insurance adjuster name',
    short: 'Insurance adjuster name',
  },
});
