import {NUMBER} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {amount, number, formattedInteger} from '../../types';

export default attribute({
  type: NUMBER,
  attribute: 'amount',
  display: amount,
  input: formattedInteger,
  api: number,
  label: {
    default: 'Amount',
    short: 'Amount',
  },
});
