import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {hide as hideAct} from './redux/actions';

// Api
import assignToVehicleApi from '../../api/assignToVehicle.api.toll';

// Components
import ReassignTollToVehicleModal from '../../components/ReassignTollToVehicleModal/ReassignTollToVehicleModal';

// Lib
import {lib} from '@matthahn/sally-ui';
import parseError from '../../../error/parseError';

// Permissions
import assignPermission from '../../permissions/assign.permission.toll';

// Alert
const {alert} = lib;

class ReassignTollToVehicleContainer extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    toll: PropTypes.object,
    vehicles: PropTypes.array,
    dispatch: PropTypes.func,
  };

  state = {
    loading: false,
    vehicle: null,
  };

  componentDidMount() {
    if (this.props.visible) this.init();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) this.init();
  }

  init = () => {
    this.setState({loading: false, vehicle: null});
  };

  onClose = () => {
    if (this.state.loading) return;
    this.props.dispatch(hideAct());
  };

  onVehicle = (vehicle) => {
    if (this.state.loading) return;
    this.setState({vehicle});
  };

  assign = async () => {
    const {dispatch, toll} = this.props;
    const {loading, vehicle} = this.state;
    if (loading) return;

    if (!assignPermission())
      return alert.warning('You do not have permission to assign tolls');

    if (!vehicle) return alert.warning('Select a vehicle');

    this.setState({loading: true});

    try {
      await assignToVehicleApi({toll: toll.id, vehicle});
      this.setState({loading: false});
      alert.success('Toll reassigned');
      dispatch(hideAct());
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({loading: false});
    }
  };

  vehicles = () =>
    [...this.props.vehicles]
      .filter(({medallion, plate}) => !!medallion || !!plate)
      .map((vehicle) => ({
        value: `${vehicle.id}`,
        label: `${vehicle?.medallion?.medallion_number || 'No medallion'} - ${
          vehicle.plate || 'No plate'
        }`,
      }));

  render() {
    const {visible, toll} = this.props;
    const {loading, vehicle} = this.state;
    return (
      <Fragment>
        <ReassignTollToVehicleModal
          visible={visible}
          canAssign={assignPermission()}
          loading={loading}
          toll={toll}
          vehicle={vehicle}
          vehicles={this.vehicles()}
          onVehicle={this.onVehicle}
          onClose={this.onClose}
          onAssign={this.assign}
        />
      </Fragment>
    );
  }
}

export default connect((state) => ({
  ...state.reassignTollToVehicle,
  vehicles: state.spotlight.vehicles,
}))(ReassignTollToVehicleContainer);
