import {TOGGLE} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

export default attribute({
  type: TOGGLE,
  attribute: 'settlement_denied',
  label: {
    default: 'Settlement Denied',
    short: 'Settlement Denied',
  },
});
