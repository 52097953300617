import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {year} from '../../types';

export default attribute({
  type: TEXT,
  attribute: 'vehicle_year',
  display: year,
  input: year,
  api: year,
  label: {
    default: 'Vehicle Year',
    short: 'Year',
  },
});
