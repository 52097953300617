import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

export default attribute({
  type: TEXT,
  attribute: 'owner',
  label: {
    default: 'Medallion Owning Entity',
    short: 'Medallion Owning Entity',
  },
});
