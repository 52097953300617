import {date, fileName, folder} from '../../../document';

// Attributes
import {issue_date as issueDate} from '../../../document/attributes';
import lienHolder from '../../attributes/lien_holder.attribute.vehicle';
import titleOwner from '../../attributes/title_owner.attribute.vehicle';
import vinLastSevenAttr from '../../attributes/vinLastSeven.attribute.vehicle';

// Lib
import vinValidation from '../../libs/vinValidation.lib.vehicle';

// Errors
import vinMissmatch from '../../errors/vinMissmatch.error.vehicle';

export default folder({
  attributes: [vinLastSevenAttr, issueDate, titleOwner, lienHolder],
  fileAttributes: ['vinLastSeven', 'issue_date', 'title_owner', 'lien_holder'],
  optionalAttributes: ['lien_holder'],
  defaultFileName: 'Title',
  // TITLE - VIN - UPLOAD DATE
  fileName: fileName(({vehicle}) => `TITLE - ${vehicle.vin} - ${date()}`),
  folderName: 'Title',
  preSaveValidation: ({vehicle, vinLastSeven}) => {
    if (vinValidation(vehicle, vinLastSeven.api.format()))
      throw vinMissmatch({
        message: 'Last 7 VIN characters are incorrect',
      });
  },
  type: 'title',
});
