import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {fhvLicense} from '../../types';

export default attribute({
  type: TEXT,
  attribute: 'fhv_license_number',
  display: fhvLicense,
  input: fhvLicense,
  api: fhvLicense,
  label: {
    default: "Driver's FHV License Number",
    short: 'FHV License Number',
    alternative: 'FHV License Number',
  },
});
