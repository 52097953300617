import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

export default ({rate, rental_type, vehicle_type}) =>
  f3tch('/dispatch/validate_rate/')
    .profile(profile())
    .body({rate, rental_type, vehicle_type})
    .post();
