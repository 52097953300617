import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

// Components
import {Card, Icon, P} from '@matthahn/sally-ui';
import Arrow from './components/Arrow';
import CloseIcon from './components/CloseIcon';
import Content from './components/Content';
import Date from './components/Date';
import Image from './components/Image';
import Slide from './components/Slide';
import Wrapper from './components/Wrapper';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Lib
import displayTimeZoneSpecificDate from '../../../lib/displayTimeZoneSpecificDate';

// Types
import {dateTime} from '../../../types';

// Settings
const settings = {
  arrows: false,
  dots: false,
  centerMode: true,
  centerPadding: '0px',
  infinite: true,
  speed: 250,
  slidesToShow: 1,
  slidesToScroll: 1,
};

class ImageSliderCard extends Component {
  static propTypes = {
    title: PropTypes.string,
    images: PropTypes.array,
  };

  state = {
    fullScreen: false,
  };

  slider = null;

  onNext = () => {
    if (!this.slider) return;
    this.slider.slickNext();
  };

  onPrev = () => {
    if (!this.slider) return;
    this.slider.slickPrev();
  };

  onSliderRef = (slider) => {
    this.slider = slider;
  };

  toggleFullScreen = () => this.setState({fullScreen: !this.state.fullScreen});

  render() {
    const {title, images} = this.props;
    const {fullScreen} = this.state;
    return (
      <Card
        title={title}
        icon="picture"
        headerActions={[
          {
            icon: 'plus',
            tooltip: 'Full Screen',
            onClick: this.toggleFullScreen,
          },
        ]}
      >
        {(Container) =>
          !images.length ? (
            <Container>
              <P theme="darkGrey">No images found</P>
            </Container>
          ) : (
            <Container padding="none">
              <Content>
                <Wrapper fullScreen={fullScreen}>
                  <Slider {...settings} ref={this.onSliderRef}>
                    {images.map((picture) => (
                      <Slide key={picture.id} fullScreen={fullScreen}>
                        <Image src={picture.document_file} />
                        <Date>
                          {displayTimeZoneSpecificDate({
                            date: picture.created_at,
                            type: dateTime,
                          })}
                        </Date>
                      </Slide>
                    ))}
                  </Slider>
                  <Arrow left onClick={this.onPrevious}>
                    <Icon icon="left" />
                  </Arrow>
                  <Arrow onClick={this.onNext}>
                    <Icon icon="right" />
                  </Arrow>
                  {fullScreen && (
                    <CloseIcon onClick={this.toggleFullScreen}>
                      <Icon icon="close" />
                    </CloseIcon>
                  )}
                </Wrapper>
              </Content>
            </Container>
          )
        }
      </Card>
    );
  }
}

export default ImageSliderCard;
