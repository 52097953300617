export default [
  {
    key: 'date',
    label: 'Date',
    span: 4,
  },
  {
    key: 'type',
    label: 'Type',
    span: 4,
  },
  {
    key: 'amount',
    label: 'Amount',
    span: 4,
  },
  {
    key: 'balance',
    label: 'Balance',
    span: 4,
  },
];
