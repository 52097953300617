import React from 'react';
import PropTypes from 'prop-types';

// alert lib
import combineDispatchAlerts from '../../../alert/lib/combineDispatchAlerts.lib.alert';
import getAlertsTheme from '../../../alert/lib/getTheme.lib.alert';

// Components
import {InputTyping, P, Icon, Button, Tag} from '@matthahn/sally-ui';
// import NegativeParenthesis from '../../../layout/components/NegativeParenthesis/NegativeParenthesis';
import DriverTags from '../../../driver/components/DriverTags/DriverTags';
import VehicleRentableDescription from '../../../vehicle/components/VehicleRentableDescription/VehicleRentableDescription';
import Rentable from '../Rentable/Rentable';

import Container from './components/Container';
import Header from './components/Header';
import HeaderFilter from './components/HeaderFilter';
import Filter from './components/Filter';
import FilterButton from './components/FilterButton';
import FixedTableCol from './components/FixedTableCol';
import TableHeader from './components/TableHeader';
import TableRow from './components/TableRow';
import TableCol from './components/TableCol';
import MediumTableCol from './components/MediumTableCol';
import SmallTableCol from './components/SmallTableCol';
import NoResults from './components/NoResults';

// dispatcher lib
import numberOfAlerts from '../../lib/numberOfAlerts.lib.dispatch';
import rowBackgroundColor from '../../lib/rowBackgroundColor.lib.dispatch';

const DispatcherCard = ({
  loading,
  search,
  display,
  vehicles,
  displayOptions,
  onAction,
  onSearch,
  onDisplay,
  onMedallion,
  onDriver,
  onDrivers,
  onVehicle,
  onAlert,
}) => (
  <Container>
    <Header>
      <HeaderFilter>
        <Filter big>
          {displayOptions.map((option) => (
            <FilterButton key={option.value}>
              <Button
                theme={display === option.value ? 'orange' : 'grey'}
                onClick={() => onDisplay(option.value)}
                block
              >
                {option.label} ({option.count})
              </Button>
            </FilterButton>
          ))}
        </Filter>
        <Filter>
          <InputTyping
            preIcon="search1"
            placeholder="Search"
            value={search}
            onChange={onSearch}
            size="large"
          />
        </Filter>
      </HeaderFilter>
      <TableHeader>
        <MediumTableCol>Medallion</MediumTableCol>
        <FixedTableCol>Year - Model</FixedTableCol>
        <MediumTableCol />
        <SmallTableCol />
        <TableCol>Driver</TableCol>
        <MediumTableCol>Hack</MediumTableCol>
        <MediumTableCol>Rental Type</MediumTableCol>
      </TableHeader>
    </Header>
    {vehicles.map((vehicle) => {
      const numberOfAllAlerts = numberOfAlerts(vehicle);
      const backgroundColor = rowBackgroundColor(vehicle);
      return (
        <TableRow key={vehicle.id} bg={backgroundColor}>
          <MediumTableCol onClick={onVehicle(vehicle)}>
            <Rentable vehicle={vehicle}>
              {!!vehicle.medallion ? vehicle.medallion.medallion_number : ''}
            </Rentable>
          </MediumTableCol>
          <FixedTableCol onClick={onVehicle(vehicle)}>
            <Rentable vehicle={vehicle}>
              {vehicle.vehicle_year} - {vehicle.vehicle_model}
            </Rentable>
          </FixedTableCol>
          <MediumTableCol onClick={onAlert(vehicle)}>
            {numberOfAllAlerts > 0 && (
              <Tag theme={getAlertsTheme(combineDispatchAlerts(vehicle))}>
                {numberOfAllAlerts}{' '}
                {numberOfAllAlerts === 1 ? 'alert' : 'alerts'}
              </Tag>
            )}
          </MediumTableCol>
          <SmallTableCol onClick={onDrivers(vehicle)}>
            <Rentable vehicle={vehicle}>
              {vehicle.hasMultipleActiveRentals && <Icon icon="team" />}
            </Rentable>
          </SmallTableCol>
          <VehicleRentableDescription vehicle={vehicle}>
            {(description) =>
              !!description
                ? [<TableCol key="driver">{description}</TableCol>]
                : [
                    <TableCol onClick={onDriver(vehicle)} key="driver">
                      <Rentable vehicle={vehicle}>
                        <DriverTags driver={vehicle.driver} withoutWeeklyIcon>
                          {vehicle.driver.name}
                        </DriverTags>
                      </Rentable>
                    </TableCol>,
                    <MediumTableCol onClick={onAction(vehicle)} key="fhv">
                      <Rentable vehicle={vehicle}>
                        {vehicle.driver.fhv_license_number}
                      </Rentable>
                    </MediumTableCol>,
                    <MediumTableCol onClick={onAction(vehicle)} key="contract">
                      <Rentable vehicle={vehicle}>
                        {vehicle.lease_agreement.contract_type}
                      </Rentable>
                    </MediumTableCol>,
                  ]
            }
          </VehicleRentableDescription>
        </TableRow>
      );
    })}
    {!loading && !vehicles.length && (
      <NoResults>
        <P theme="darkGrey" size="2">
          No Results Found
        </P>
      </NoResults>
    )}
    {loading ? (
      !!vehicles.length ? (
        <TableRow key="loading">
          <TableCol>
            <P theme="orange" align="center" size="6">
              <Icon icon="loading2" spin />
            </P>
          </TableCol>
        </TableRow>
      ) : (
        <NoResults>
          <P theme="orange" align="center" size="6">
            <Icon icon="loading2" spin />
          </P>
        </NoResults>
      )
    ) : null}
  </Container>
);

DispatcherCard.propTypes = {
  loading: PropTypes.bool,
  search: PropTypes.string,
  display: PropTypes.string,
  vehicles: PropTypes.array,
  displayOptions: PropTypes.array,
  onAction: PropTypes.func,
  onSearch: PropTypes.func,
  onDisplay: PropTypes.func,
  onMedallion: PropTypes.func,
  onDriver: PropTypes.func,
  onDrivers: PropTypes.func,
  onVehicle: PropTypes.func,
  onAlert: PropTypes.func,
};

export default DispatcherCard;
