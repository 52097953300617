// vehicleHold lib
import isHoldAfterETC from '../../vehicleHold/lib/isAfterETC.lib.vehicleHold';
import isOnHold from '../../vehicleHold/lib/isOnHold.lib.vehicleHold';

const rowBackgroundColor = (vehicle) =>
  isOnHold(vehicle.vehicle_hold) &&
  isHoldAfterETC({etc: vehicle.vehicle_hold.date_etc})
    ? 'orange'
    : null;

export default rowBackgroundColor;
