import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal, Form, Row, Column, Input} from '@matthahn/sally-ui';

const SearchTicketModal = ({
  loading,
  visible,
  search,
  onChange,
  onSearch,
  onClose,
  onInputDom,
}) => (
  <Modal
    visible={visible}
    title="Search Ticket"
    size="small"
    onClose={onClose}
    buttonsRight={[
      {label: 'Search', onClick: onSearch, loading, theme: 'orange'},
    ]}
  >
    {(Content) => (
      <Content>
        <Form onSubmit={onSearch} enter>
          <Row margin />
          <Row margin>
            <Column>
              <Input
                value={search}
                onChange={onChange}
                disabled={loading}
                inputRef={onInputDom}
              >
                Insert summons number
              </Input>
            </Column>
          </Row>
        </Form>
      </Content>
    )}
  </Modal>
);

SearchTicketModal.propTypes = {
  loading: PropTypes.bool,
  visible: PropTypes.bool,
  search: PropTypes.string,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  onClose: PropTypes.func,
  onInputDom: PropTypes.func,
};

export default SearchTicketModal;
