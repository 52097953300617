import styled from 'styled-components';

// Colors
import {black} from '@matthahn/sally-ui/lib/libs/colors';

const CloseIcon = styled.div`
  position: fixed;
  top: 10px;
  right: 20px;
  cursor: pointer;
  font-size: 40px;
  color: ${black};
  user-select: none;
  z-index: 10000000000000000000;
`;

export default CloseIcon;
