import styled from 'styled-components';

const TableCol = styled.div`
  padding: 7px 10px;
  width: 24%;
  max-width: 24%;
  min-width: 24%;
  cursor: ${({onClick}) => (!!onClick ? 'pointer' : 'initial')};
`;

export default TableCol;
