import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Card} from '@matthahn/sally-ui';

import Create from './components/Create';
import NoNotes from './components/NoNotes';
import Note from './components/Note';

const NotesCard = ({
  notes,
  add,
  adding,
  edit,
  editing,
  text,
  onText,
  onAdd,
  onAddCancel,
  onSave,
  onEdit,
  onUpdateStart,
  onUpdateCancel,
  onUpdateText,
  onRemove,
}) => (
  <Card
    title="Notes"
    icon="bars"
    headerActions={[
      {
        icon: 'plus',
        onClick: onAdd,
        tooltip: 'Add Note',
        loading: adding,
        disabled: adding,
      },
    ]}
  >
    {(CardContent) => [
      add && (
        <CardContent key="notes-card-create">
          <Create
            text={text}
            onText={onText}
            onSave={onSave}
            onCancel={onAddCancel}
            creating={adding}
          />
        </CardContent>
      ),
      notes.length === 0 && (
        <CardContent key="notes-card-no-notes">
          <NoNotes>No Notes Found</NoNotes>
        </CardContent>
      ),
      ...notes.map((note) => (
        <CardContent key={`notes-card-${note.id}`}>
          <Note
            note={note}
            edit={edit.find((n) => n.id === note.id) || null}
            editing={editing.includes(note.id)}
            onSave={(e) => onEdit(note.id)}
            onEdit={(e) => onUpdateStart(note.id)}
            onCancel={(e) => onUpdateCancel(note.id)}
            onDelete={(e) => onRemove(note.id)}
            onUpdateText={(t) => onUpdateText(note.id, t)}
          />
        </CardContent>
      )),
    ]}
  </Card>
);

NotesCard.propTypes = {
  notes: PropTypes.array,
  add: PropTypes.bool,
  adding: PropTypes.bool,
  edit: PropTypes.array,
  editing: PropTypes.array,
  text: PropTypes.object,
  onText: PropTypes.func,
  onAdd: PropTypes.func,
  onAddCancel: PropTypes.func,
  onSave: PropTypes.func,
  onEdit: PropTypes.func,
  onUpdateStart: PropTypes.func,
  onUpdateCancel: PropTypes.func,
  onUpdateText: PropTypes.func,
  onRemove: PropTypes.func,
};

export default NotesCard;
