const groupPaymentsByType = (payments) =>
  [...payments].reduce(
    (combined, payment) => ({
      ...combined,
      [`${payment.type}s`]: [...combined[`${payment.type}s`], payment],
    }),
    {achs: [], alerts: [], cheques: []}
  );

export default groupPaymentsByType;
