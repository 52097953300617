import styled from 'styled-components';

// colors
import {grey} from '@matthahn/sally-ui/lib/libs/colors';

const SearchContainer = styled.div`
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid ${grey};
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
`;

export default SearchContainer;
