import prepare from '../../attribute/prepare.attribute';

// Constants
import createAttributes from '../constants/createAttributes.constants.vehicle';

export default (attributes) =>
  prepare(attributes, {
    field: 'api',
    required: [...createAttributes],
  });
