const incomeOrder = [
  'congestion',
  'mta',
  'imp',
  'ehail',
  'health',
  'advance',
  'gas',
  'cash_rounding',
  'ezpass',
  'ticket',
  'rent',
  'repair',
];

const sort = (a, b) => {
  if (a.label > b.label) return 1;
  if (a.label < b.label) return -1;
  return 0;
};

export default (transactions, customOrder = []) => {
  const availableCustomOrder = [...customOrder].filter((orderKey) =>
    incomeOrder.includes(orderKey)
  );
  const {income, outcome} = [...transactions].reduce(
    (combined, current) => {
      const key = current.owed_to_sally ? 'income' : 'outcome';
      return {
        ...combined,
        [key]: [...combined[key], current],
      };
    },
    {income: [], outcome: []}
  );
  const {charges, otherIncome} = [...income].reduce(
    (combined, current) => {
      const key = current.type === 'charge' ? 'charges' : 'otherIncome';
      return {
        ...combined,
        [key]: [...combined[key], current],
      };
    },
    {charges: [], otherIncome: []}
  );
  const order = [
    ...availableCustomOrder,
    ...incomeOrder.filter(
      (orderKey) => !availableCustomOrder.includes(orderKey)
    ),
  ];
  const orderedCharges = [...order]
    .map((order) =>
      [...charges].find((transaction) => transaction.subtype === order)
    )
    .filter((transaction) => !!transaction);
  const unorderedCharges = [...charges].filter(
    (transaction) => !order.includes(transaction.subtype)
  );
  return [
    ...orderedCharges,
    ...unorderedCharges,
    ...otherIncome.sort(sort),
    ...outcome.sort(sort),
  ];
};
