// local components
import Input from './components/Input';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component, Fragment, createRef} from 'react';

class FileUpload extends Component {
  static propTypes = {
    children: PropTypes.func,
    onFile: PropTypes.func,
  };

  inputDom = createRef();

  clickOnInput = () => {
    this.inputDom.current.click();
  };

  fileSelected = (event) => {
    const file = event.target.files[0];
    this.props.onFile(file);
    this.inputDom.current.value = '';
  };

  render() {
    const {children} = this.props;
    return (
      <Fragment>
        {children({onClick: this.clickOnInput})}
        <Input
          type="file"
          onChange={this.fileSelected}
          ref={this.inputDom}
          accept=".zip"
        />
      </Fragment>
    );
  }
}

export default FileUpload;
