import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal, Table, Button, P, Row, Column} from '@matthahn/sally-ui';
import Loader from './components/Loader';

// Columns
import columns from './columns';

const ExternalDevices = ({
  visible,
  loading,
  devices,
  device,
  onClose,
  onDevice,
  onCancel,
}) => (
  <Modal
    title="Select External Device"
    icon="tablet1"
    visible={visible}
    onClose={onClose}
  >
    {(Content) =>
      !device ? (
        <Content padding="none">
          <Table
            columns={columns}
            loading={loading}
            theme="orange"
            infinite
            noBorder
            noRadius
            smallNoResultsLabel
          >
            {(TableRow) =>
              devices.map((externalDevice) => (
                <TableRow
                  key={externalDevice.id}
                  onClick={onDevice(externalDevice)}
                >
                  {(TableColumn) => [
                    <TableColumn key="id">{externalDevice.id}</TableColumn>,
                    <TableColumn key="device">
                      {externalDevice.device}
                    </TableColumn>,
                    <TableColumn key="user">{externalDevice.user}</TableColumn>,
                  ]}
                </TableRow>
              ))
            }
          </Table>
        </Content>
      ) : (
        <Content>
          <Row margin />
          <Row margin />
          <Row margin>
            <Column>
              <P align="center">
                <Loader icon="loading2" spin />
              </P>
            </Column>
          </Row>
          <Row margin>
            <Column>
              <P align="center">Waiting for input on the external device</P>
            </Column>
          </Row>
          <Row margin />
          <Row margin>
            <Column>
              <P align="center">
                <Button theme="grey" icon="close" onClick={onCancel}>
                  Cancel
                </Button>
              </P>
            </Column>
          </Row>
          <Row margin />
        </Content>
      )
    }
  </Modal>
);

ExternalDevices.propTypes = {
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  devices: PropTypes.array,
  device: PropTypes.object,
  onClose: PropTypes.func,
  onDevice: PropTypes.func,
  onCancel: PropTypes.func,
};

export default ExternalDevices;
