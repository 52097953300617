import numeral from 'numeral';
import isNumber from 'is-number';
import isString from 'is-string';

import type from '../type';

const format = (value) =>
  isString(value) || isNumber(value) ? numeral(value).format('0,0.00') : null;

const validate = (value) =>
  /(?=.)^(([1-9][0-9]{0,2}(,[0-9]{3})*)|[0-9]+)?(\.[0-9]{2})?$/.test(value);

export default type({type: 'string', format, validate});
