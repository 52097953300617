import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

// Api
import {isIn} from '../../../api/queries/queries';

// Attributes
import dmvLicenseNumber from '../../attributes/dmv_license_number.attribute.driver';
import fhvLicenseNumber from '../../attributes/fhv_license_number.attribute.driver';

// contract api
import listCsrsApi from '../../../contract/api/listCsrs.api.contract';

// Documents
import listDocumentsApi from '../../../document/api/list.api.document';
import dispatchPackage from '../../documents/bundles/dispatchPackage.bundle.driver';
import AttributeFileUploader from '../../../layout/components/AttributeFileUploader/AttributeFileUploader';

// DispatchDocumentsStep
import DispatchDocumentsStep from '../../components/DispatchDocumentsStep/DispatchDocumentsStep';

class DispatchDocumentsStepContainer extends Component {
  static propTypes = {
    Container: PropTypes.func,
    visible: PropTypes.bool,
    driver: PropTypes.object,
    documents: PropTypes.array,
    onBack: PropTypes.func,
    onNext: PropTypes.func,
    onDisabled: PropTypes.func,
    onShow: PropTypes.func,
    onHide: PropTypes.func,
    goingBack: PropTypes.bool,
  };

  state = {
    csr: null,
    files: [],
    fileToUpload: false,
    fileUploadVisible: false,
    loading: false,
  };

  componentDidMount() {
    if (this.props.visible) this.init();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) this.init();
  }

  init = async () => {
    const {driver, documents, onDisabled, onNext, onBack, goingBack} =
      this.props;

    this.setState({loading: true});
    onDisabled(true);

    const csr = await this.getLatestCsr(driver.id);

    const skip = (data) => {
      const fn = goingBack ? onBack : onNext;
      onDisabled(false);
      fn({documents: data, csr});
    };

    if (!documents.length && this.allAttributesPresent(driver)) return skip([]);

    if (this.allFilesPresent(documents)) return skip(documents);

    try {
      const {results: files} = await listDocumentsApi({
        driver: driver.id,
        [isIn('type')]: dispatchPackage.files
          .map(({file: {type}}) => type)
          .join(','),
      });
      if (this.allFilesPresent(files)) return skip(files);
      this.setState({loading: false, files, csr});
    } catch (error) {
      alert.error('Something went wrong. Please close the flow and try again');
      this.setState({loading: false});
    }

    onDisabled(false);
  };

  getLatestCsr = async (driverId) => {
    try {
      const {results: csrs} = await listCsrsApi({
        signed_contract__isnull: true,
        driver: driverId,
        limit: 1,
        offset: 0,
        ordering: 'created_at',
      });
      return csrs?.[0] || null;
    } catch (error) {
      return null;
    }
  };

  allFilesPresent = (files, driver = this.props.driver) =>
    [...dispatchPackage.files].every(
      ({file: {type}}) => !!this.getFileByType(type, files)
    );

  allAttributesPresent = (driver = this.props.driver) =>
    dmvLicenseNumber(driver.dmv_license_number).api.validate() &&
    fhvLicenseNumber(driver.fhv_license_number).api.validate();

  getFileByType = (type, files = this.state.files) =>
    [...files].find((file) => file.type === type);

  showFileUpload = (file) => () => {
    this.props.onHide();
    this.setState({fileUploadVisible: true, fileToUpload: file});
  };

  hideFileUpload = () => {
    this.props.onShow();
    this.setState({fileUploadVisible: false});
  };

  fileUploaded = ({object, files: [file]}) => {
    const files = [...this.state.files, file];
    this.props.onShow();
    if (this.allFilesPresent(files))
      return this.props.onNext({driver: object, documents: files});
    this.setState({files, driver: object, fileUploadVisible: false});
  };

  files = () =>
    [...dispatchPackage.files].map(({file}) => ({
      ...file,
      uploaded: !!this.getFileByType(file.type, this.state.files),
    }));

  render() {
    const {Container, driver, onBack, onNext} = this.props;
    const {loading, files, fileUploadVisible, fileToUpload} = this.state;
    return (
      <Fragment>
        <DispatchDocumentsStep
          Container={Container}
          loading={loading}
          driver={driver}
          files={this.files()}
          disableNext={!this.allFilesPresent(files)}
          onShow={this.showFileUpload}
          onBack={onBack}
          onNext={onNext}
        />
        {!!fileToUpload && (
          <AttributeFileUploader
            driver={driver}
            title={fileToUpload.defaultFileName}
            type="driver"
            folderType={fileToUpload.type}
            visible={fileUploadVisible}
            onDone={this.fileUploaded}
            onClose={this.hideFileUpload}
          />
        )}
      </Fragment>
    );
  }
}

export default DispatchDocumentsStepContainer;
