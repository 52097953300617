import styled from 'styled-components';

// Libs
import {lib} from '@matthahn/sally-ui';

// Colors
const {
  media: {media},
} = lib;

const Box = styled.div`
  margin-left: 50px;
  text-align: right;
  cursor: pointer;

  ${media.s`
    margin-left: 0px;
    margin-right: 40px;
    text-align: left;
  `}
`;

export default Box;
