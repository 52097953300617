import type from '../type';

import dmvNYLicense from './dmvNYLicense.type';

const format = (input) => {
  if (!input) return '';
  const string = `${input}`;
  return string.length === 10 ? string.slice(0, 9) : string;
};

const validate = (value) => dmvNYLicense.validate(value);

export default type({type: 'string', format, validate});
