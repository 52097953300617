export default [
  {
    key: 'type',
    label: 'Type',
  },
  // {
  //   key: 'amount',
  //   label: 'Amount',
  // },
  {
    key: 'balance',
    label: 'Balance',
  },
  // {
  //   key: 'description',
  //   label: 'Description',
  // },
];
