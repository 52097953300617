import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import {isSafari} from 'react-device-detect';

import {connect} from 'react-redux';

// Components
import {Button} from '@matthahn/sally-ui';
import Page from './components/Page';
import ButtonContainer from './components/ButtonContainer';
import KibanaDashboards from './components/KibanaDashboards';

// Dashboards
import URL from '../../constants/url.constant.cyclops';


class Cyclops extends Component {
  static propTypes = {
    kibanaCreds: PropTypes.string.isRequired,
  };

  openKibana = (src) => () => window.open(src, '_target');

  render() {
    const {kibanaCreds} = this.props;
    return (
      <Fragment>
        <Page src={URL} />
        <KibanaDashboards
          kibanaCreds={kibanaCreds}
        />
        {isSafari && (
          <ButtonContainer>
            <Button size="small" theme="orange" onClick={this.openKibana(URL)}>
              Open in new tab
            </Button>
          </ButtonContainer>
        )}
      </Fragment>
    );
  }
}


export default connect((state) => ({
  kibanaCreds: state.auth.kibana_creds
}))(Cyclops);


