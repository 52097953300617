import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Card, Row, Column, Text} from '@matthahn/sally-ui';
import AttributeText from '../../../layout/components/AttributeText/AttributeText';

// types
import amountType from '@matthahn/sally-fw/lib/type/types/amount.type';
import dateType from '@matthahn/sally-fw/lib/type/types/date.type';

const VehicleLicensesCard = ({
  canRemovePlate,
  file,
  loading,
  onLicenseRemoval,
  onOpen,
  onShowUploader,
  removingPlate,

  plate,
  registration_expiry,
}) => (
  <Card
    title="Licenses"
    icon="bars"
    headerActions={[
      !!file && {
        icon: 'eyeo',
        onClick: onOpen,
        tooltip: 'Show file',
      },
      {
        icon: 'addfile',
        tooltip: 'Upload Registration',
        onClick: onShowUploader,
        loading,
      },
      canRemovePlate && {
        icon: 'delete',
        onClick: onLicenseRemoval,
        tooltip: 'Remove License Plate',
        loading: removingPlate,
        disabled: loading,
      },
    ].filter((act) => !!act)}
  >
    {(Content) => (
      <Content>
        <Row margin>
          <Column size={1 / 2}>
            <AttributeText attribute={plate} disabled={loading}>
              {plate.label.default}
            </AttributeText>
          </Column>
          <Column size={1 / 2}>
            <AttributeText attribute={registration_expiry}>
              {registration_expiry.label.default}
            </AttributeText>
          </Column>
        </Row>
        <Row>
          <Column size={1 / 2}>
            <Text
              value={
                !!file?.extra_data?.registration_cost
                  ? `$${amountType(
                      file?.extra_data?.registration_cost
                    ).format()}`
                  : '-'
              }
            >
              Registration Costs
            </Text>
          </Column>
          <Column size={1 / 2}>
            <Text
              value={
                !!file?.extra_data?.effective_date
                  ? dateType(file?.extra_data?.effective_date).format()
                  : '-'
              }
            >
              Registration Effective Date
            </Text>
          </Column>
        </Row>
      </Content>
    )}
  </Card>
);

VehicleLicensesCard.propTypes = {
  canRemovePlate: PropTypes.bool,
  canUpdateMedallion: PropTypes.bool,
  emissions_expiry: PropTypes.object,
  emissions_issued: PropTypes.object,
  loading: PropTypes.bool,
  medallion_expiry: PropTypes.object,
  medallion_number: PropTypes.object,
  onLicenseRemoval: PropTypes.func,
  onShowUploader: PropTypes.func,
  plate: PropTypes.object,
  registration_expiry: PropTypes.object,
  removingPlate: PropTypes.bool,
  yellow: PropTypes.bool,
};

export default VehicleLicensesCard;
