import styled from 'styled-components';

// Colors
import {lib} from '@matthahn/sally-ui';
const {
  colors: {darkGrey, grey},
} = lib;

const Connect = styled.div`
  width: 100%;
  height: 100px;
  border: 2px dashed ${grey};
  color: ${darkGrey};
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export default Connect;
