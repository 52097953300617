import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {apiPhoneNumber, phoneNumber} from '../../types';

export default attribute({
  type: TEXT,
  attribute: 'home_phone_number',
  display: phoneNumber,
  input: phoneNumber,
  api: apiPhoneNumber,
  label: {
    default: 'Home Phone Number',
    short: 'Home Phone',
  },
});
