import styled from 'styled-components';

// colors
import {darkGrey} from '@matthahn/sally-ui/lib/libs/colors';

const UserName = styled.div`
  width: 100%;
  color: ${darkGrey};
`;

export default UserName;
