import React from 'react';

// Containers
import Page from '../../../layout/containers/Page/Page';
import Cyclops from '../../components/Cyclops/Cyclops';

// Page
import PAGE from './page';

const CyclopsPage = () => (
  <Page page={PAGE}>
    <Cyclops />
  </Page>
);

export default CyclopsPage;
