import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

// Events
import event from '../events/created.event.accidentNote';

export default async (accidentID, accidentNote) => {
  const response = await f3tch(`/accidents/dol/${accidentID}/notes/`)
    .profile(profile())
    .body(accidentNote)
    .post();
  event.pub(response);
  return response;
};
