import styled from 'styled-components';

// Color
import {lib} from '@matthahn/sally-ui';
const {
  colors: {darkGrey},
} = lib;

const Flip = styled.div`
  transform: scaleX(-1);
  color: ${darkGrey};
`;

export default Flip;
