import React from 'react';
import PropTypes from 'prop-types';

// components
import {Icon} from '@matthahn/sally-ui';

// local components
import Container from './components/Container';

const icons = {
  completed: 'check',
  warning: 'warning',
  error: 'close',
};

const colors = {
  completed: 'green',
  warning: 'orange',
  error: 'red',
};

const Indicator = ({state}) => (
  <Container color={colors[state] || colors.error}>
    <Icon icon={icons[state] || icons.error} />
  </Container>
);

Indicator.propTypes = {
  state: PropTypes.string,
};

export default Indicator;
