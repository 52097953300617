import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {licencePlate} from '../../types';

export default attribute({
  type: TEXT,
  attribute: 'plateConfirm',
  input: licencePlate,
  api: licencePlate,
  label: {
    default: 'Confirm License Plate',
    short: 'Confirm Plate',
  },
});
