import React from 'react';
import PropTypes from 'prop-types';

// components
import {Modal, Table} from '@matthahn/sally-ui';

// local data
import columns from './columns';

// types
import dateTime from '@matthahn/sally-fw/lib/type/types/dateTime.type';

const KeyLabelHistoryModal = ({loading, visible, keyLabels, onClose}) => (
  <Modal title="Key Label History" visible={visible} onClose={onClose}>
    {(Content) => (
      <Content padding="none">
        <Table
          columns={columns}
          infinite={false}
          loading={loading}
          noBorder
          noRadius
          smallNoResultsLabel
        >
          {(TableRow) =>
            keyLabels.map((keyLabel) => (
              <TableRow key={keyLabel.id}>
                {(TableColumn) => [
                  <TableColumn key="date">
                    {dateTime(keyLabel.created_at).format()}
                  </TableColumn>,
                  <TableColumn key="label">{keyLabel.label}</TableColumn>,
                  <TableColumn key="vin">
                    {keyLabel.vin || 'Unlinked'}
                  </TableColumn>,
                  <TableColumn key="branch">-</TableColumn>,
                ]}
              </TableRow>
            ))
          }
        </Table>
      </Content>
    )}
  </Modal>
);

KeyLabelHistoryModal.propTypes = {
  loading: PropTypes.bool,
  visible: PropTypes.bool,
  keyLabels: PropTypes.array,
  onClose: PropTypes.func,
};

export default KeyLabelHistoryModal;
