import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal, Row, Column, Form} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

const TakePaymentModal = ({
  allocate,
  visible,
  loading,
  isCheck,
  amount,
  subtype,
  check_number,
  onSave,
  onIssue,
  onChange,
  onClose,
}) => (
  <Modal
    icon="barcode"
    title="Take Payment"
    visible={visible}
    onClose={onClose}
    size="small"
    buttonsLeft={[
      {
        label: 'Take',
        theme: 'grey',
        onClick: onIssue,
        loading: loading,
      },
    ]}
    buttonsRight={
      allocate
        ? [
            {
              label: 'Allocate Payment',
              theme: 'orange',
              onClick: onSave,
              disabled: loading,
            },
          ]
        : []
    }
  >
    {(Component) => (
      <Component>
        <Form onSubmit={onSave} enter>
          <Row margin>
            <Column>
              <AttributeInput
                value={amount}
                onChange={onChange}
                disabled={loading}
                preValue="$"
              >
                {amount.label.short}
              </AttributeInput>
            </Column>
          </Row>
          <Row margin={isCheck}>
            <Column>
              <AttributeInput
                value={subtype}
                onChange={onChange}
                disabled={loading}
              >
                {subtype.label.short}
              </AttributeInput>
            </Column>
          </Row>
          {isCheck && (
            <Row>
              <Column>
                <AttributeInput
                  value={check_number}
                  onChange={onChange}
                  disabled={loading}
                >
                  {check_number.label.short}
                </AttributeInput>
              </Column>
            </Row>
          )}
        </Form>
      </Component>
    )}
  </Modal>
);

TakePaymentModal.propTypes = {
  allocate: PropTypes.bool,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  isCheck: PropTypes.bool,
  amount: PropTypes.object,
  subtype: PropTypes.object,
  check_number: PropTypes.object,
  onSave: PropTypes.func,
  onIssue: PropTypes.func,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
};

export default TakePaymentModal;
