import prepare from '../../attribute/prepare.attribute';

// Preparations
import readyFhv from './readyFhv.preparation.driver';

export default async (attributes) => {
  const driver = prepare(attributes, {
    field: 'api',
    required: ['state'],
  });
  if (['ready', 'reserved', 'active'].includes(driver.state))
    await readyFhv(attributes);
  return driver;
};
