import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

// Events
import event from '../events/created.event.driverAlert';

export default async (driverId, driverAlert) => {
  const response = await f3tch(`/drivers/${driverId}/alerts/`)
    .profile(profile())
    .body(driverAlert)
    .post();
  event.publish(response);
  return response;
};
