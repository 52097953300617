import {SELECT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

export default attribute({
  type: SELECT,
  attribute: 'contract_type',
  label: {
    default: 'Contract Type',
    short: 'Type',
  },
  options: [
    {
      value: 'standard',
      label: 'Standard',
    },
    {
      value: 'reduced',
      label: 'Reduced Deductible',
    },
    {
      value: 'minimum4Weeks',
      label: '4 Week Minimum',
    },
  ],
});
