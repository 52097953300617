import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Api
import api from '../../../api/lib/getEverythingFromApi.lib.api';
import getRootTransactionsApi from '../../api/root.api.transaction';
import getChildTransactionsApi from '../../api/children.api.transaction';
import getTripByIdApi from '../../../trip/api/getByID.api.trip';
import listPayoutsApi from '../../../payout/api/list.api.payout';
import listDocumentsApi from '../../../document/api/list.api.document';
import downloadPayoutReportApi from '../../../payout/api/download.api.payout';
import updateTransactionApi from '../../../transaction/api/update.api.transaction';

// Components
import TripTransactionModal from '../../components/TripTransactionModal/TripTransactionModal';

// Documents
import payoutDocument from '../../../driver/documents/folders/payout.document.driver';

// Lib
import {lib} from '@matthahn/sally-ui';
import downloadFile from '../../../lib/downloadFile';
import parseError from '../../../error/parseError';
import parseTransactions from '../../lib/parse.lib.transaction';
import payoutOverview from '../../../payout/lib/overview.lib.payout';
import canEditDescription from '../../../transaction/lib/canEditDescription.lib.transaction';
import isTripTransaction from '../../../transaction/lib/isTripTransaction.lib.transaction';

// Types
import {date} from '../../../types';

// Permissions
import changeDescriptionPermission from '../../permission/changeDescription.permission.transaction';

// Alert
const {alert} = lib;

class TripTransactionContainer extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    transaction: PropTypes.object,
    onClose: PropTypes.func,
    query: PropTypes.object,
    transactionTypes: PropTypes.array,
    onTransactionUpdate: PropTypes.func,
  };

  static defaultProps = {
    query: {},
    onTransactionUpdate: () => {},
  };

  state = {
    loading: false,
    initing: false,
    downloading: false,
    trip: null,
    tripTransactions: [],
    transaction: null,
    childTransactions: [],
    payout: null,
    loadingChildTransactions: false,
    screenshot: null,
    description: '',
  };

  componentDidMount() {
    if (this.props.visible) this.init();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) this.init();
  }

  init = async () => {
    const {transaction, query, transactionTypes, onClose} = this.props;

    const isTrip = isTripTransaction(transaction);

    this.setState({
      initing: true,
      downloading: false,
      trip: null,
      tripTransactions: [],
      transaction: isTrip ? null : {...transaction},
      childTransactions: [],
      loadingChildTransactions: false,
      screenshot: null,
      description: transaction.description || '',
    });

    try {
      const [
        {data: rawTransactions},
        trip,
        {results: payouts},
      ] = await Promise.all([
        isTrip
          ? api(getRootTransactionsApi, {trip: transaction.trip.id})
          : api((q) => getChildTransactionsApi(transaction.id, q), query),
        isTrip ? getTripByIdApi(transaction.trip.id) : null,
        isTrip
          ? {results: []}
          : listPayoutsApi({
              transactions: transaction.id,
              status: 'successful',
              ordering: '-payout_date',
            }),
      ]);
      const {transactions} = parseTransactions(rawTransactions);
      const parsedTransactions = isTrip
        ? {tripTransactions: transactions}
        : {
            childTransactions: this.groupTransactions({
              transactionID: transaction.id,
              transactions,
            }),
          };

      const payout = payoutOverview(
        !!payouts && !!payouts.length ? payouts[0] : null,
        transactionTypes
      );

      const {data: screenshots} = !!payout
        ? await api(listDocumentsApi, {
            driver: payout.driver.id,
            type: payoutDocument.type,
          })
        : {data: []};
      const screenshot = !!payout
        ? [...screenshots].find(
            ({extra_data}) => extra_data.payout === payout.id
          )
        : null;

      this.setState({
        initing: false,
        trip,
        payout,
        screenshot,
        ...parsedTransactions,
      });
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      onClose();
    }
  };

  groupTransactions = ({transactionID, transactions}) => {
    const {rootTransactions, childTransactions} = [...transactions].reduce(
      (combined, current) => {
        const pile = current.parents.includes(transactionID)
          ? 'rootTransactions'
          : 'childTransactions';
        return {
          ...combined,
          [pile]: [...combined[pile], current],
        };
      },
      {rootTransactions: [], childTransactions: []}
    );
    return [...rootTransactions].map((transaction) => ({
      ...transaction,
      childTransactions: [...childTransactions].filter((childTransaction) =>
        childTransaction.parents.includes(transaction.id)
      ),
    }));
  };

  onClose = () => {
    if (
      this.state.initing ||
      this.state.loading ||
      this.state.loadingChildTransactions ||
      this.state.downloading
    )
      return;
    this.props.onClose();
  };

  onTransaction = (transaction) => async () => {
    const {query} = this.props;
    if (this.state.initing || this.state.loading) return;

    this.setState({transaction, loadingChildTransactions: true});

    const stop = () =>
      !!this.state.transaction && this.state.transaction.id !== transaction.id;

    try {
      const {data: rawTransactions} = await api(
        (q) => getChildTransactionsApi(transaction.id, q),
        query
      );
      if (stop()) return;
      const {transactions} = parseTransactions(rawTransactions);
      this.setState({
        childTransactions: this.groupTransactions({
          transactionID: transaction.id,
          transactions,
        }),
        loadingChildTransactions: false,
      });
    } catch (error) {
      if (stop()) return;
      this.setState({childTransactions: [], loadingChildTransactions: false});
    }
  };

  onScreenshot = () => {
    const {screenshot} = this.state;
    if (!screenshot) return;
    window.open(screenshot.document_file, '_blank');
  };

  onReportDownload = async () => {
    const {payout, downloading} = this.state;
    if (downloading || !payout) return;

    this.setState({downloading: true});

    try {
      const file = await downloadPayoutReportApi(payout.id);
      downloadFile(
        file,
        `${payout.driver.first_name} ${payout.driver.last_name} - ${date(
          payout.payout_date
        ).format()}.pdf`
      );
    } catch (error) {
      alert.error('Could not generate a statement');
    }

    this.setState({downloading: false});
  };

  onChange = (description) => {
    const {initing, loading} = this.state;
    if (initing || loading) return;
    this.setState({description});
  };

  saveDescription = async () => {
    const {visible, transaction, onTransactionUpdate} = this.props;
    const {initing, loading, description} = this.state;
    if (!visible || initing || loading) return;

    if (!description.trim().length) return alert.warning('Insert description');

    this.setState({loading: true});

    try {
      await updateTransactionApi(transaction.id, {
        description,
        amount: transaction.amount,
        driver: transaction.driver,
        subtype: transaction.subtype,
        type: transaction.type,
      });
      onTransactionUpdate({...transaction, description});
      alert.success('Description updated');
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
    }

    this.setState({loading: false});
  };

  render() {
    const {visible} = this.props;
    const {
      loading,
      initing,
      trip,
      tripTransactions,
      transaction,
      payout,
      childTransactions,
      loadingChildTransactions,
      screenshot,
      downloading,
      description,
    } = this.state;
    return (
      <TripTransactionModal
        loading={loading}
        visible={visible}
        initing={initing}
        downloading={downloading}
        screenshot={screenshot}
        description={description}
        trip={trip}
        tripTransactions={tripTransactions}
        transaction={transaction}
        transactionAmount={
          !!this.props.transaction ? this.props.transaction.amount : 0
        }
        childTransactions={childTransactions}
        loadingChildTransactions={loadingChildTransactions}
        payout={payout}
        canEditDescription={
          changeDescriptionPermission() && canEditDescription(transaction)
        }
        onClose={this.onClose}
        onTransaction={this.onTransaction}
        onScreenshot={this.onScreenshot}
        onReportDownload={this.onReportDownload}
        onDescription={this.onChange}
        onSaveDescription={this.saveDescription}
      />
    );
  }
}

export default connect((state) => ({
  transactionTypes: state.transactionType.transactionTypes,
}))(TripTransactionContainer);
