import isString from 'is-string';

import type from '../type';

const format = (value) => {
  if (!isString(value)) return '';
  const numbers = value.replace(/\D+/g, '');
  return `${numbers.slice(0, 3)}-${numbers.slice(3, 6)}-${numbers.slice(
    6,
    10
  )}`;
};

const validate = (value) => /^\d{3}-\d{3}-\d{4}$/.test(value);

export default type({type: 'string', format, validate});
