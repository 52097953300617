export default (payout, types) => {
  const charge = [...types].find(({type: {key}}) => key === 'charge');
  if (!payout || !charge) return null;
  const allocation = charge.subtypes.map(({key, label}) => ({
    key,
    label,
    amount: payout[`${key}_amount`] || 0,
  }));
  return {...payout, allocation};
};
