import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Api
import updateVehicleApi from '../../api/update.api.vehicle';
import listDocumentsApi from '../../../document/api/list.api.document';

// Actions
import {set as setVehicleAct} from '../../redux/actions';

// Components
import {FilePreview} from '@matthahn/sally-ui';
import AttributeFileUploader from '../../../layout/components/AttributeFileUploader/AttributeFileUploader';
import VehicleTollPassCard from '../../components/VehicleTollPassCard/VehicleTollPassCard';

// Lib
import {lib} from '@matthahn/sally-ui';
import parseError from '../../../error/parseError';

// Files
import {foldersObject} from '../../documents';

// Alert
const {alert, notify} = lib;

// UI
const {tollPass: tollPassFile} = foldersObject;

class VehicleTollPassContainer extends Component {
  static propTypes = {
    vehicle: PropTypes.object,
    loading: PropTypes.bool,
    onAttributeRefresh: PropTypes.func,
    ezpass_tag_number: PropTypes.object,
  };

  state = {
    doc: null,
    docFetching: true,
    loading: false,
    showUploader: false,
    preview: false,
  };

  componentDidMount() {
    this.getDocument();
  }

  set = (vehicle) => this.props.dispatch(setVehicleAct({vehicle}));

  isLoading = () => this.state.loading;

  showUploader = () => {
    if (this.isLoading()) return;
    this.setState({showUploader: true});
  };

  hideUploader = () => {
    if (this.isLoading() || !this.state.showUploader) return;
    this.setState({showUploader: false});
  };

  onDone = ({object, files: [file]}) => {
    this.set(object);
    this.props.onAttributeRefresh(tollPassFile.attributesList);
    this.setState({showUploader: false, doc: file});
  };

  getDocument = async () => {
    try {
      const {results} = await listDocumentsApi({
        type: tollPassFile.type,
        ordering: '-created_at',
        vehicle: this.props.vehicle.id,
      });
      const doc = results.length > 0 ? results[0] : null;
      this.setState({doc, docFetching: false});
    } catch (error) {
      this.setState({docFetching: false});
    }
  };

  removeTollPass = async (prompt = true) => {
    const {vehicle, onAttributeRefresh} = this.props;
    const {loading} = this.state;
    if (loading) return;

    if (prompt)
      return notify({
        id: 'removeTollPassModal',
        title: 'Close',
        icon: undefined,
        content: 'Are you sure you want to remove the toll pass?',
        primary: {
          label: 'No',
          onClick: () => null,
        },
        secondary: {
          label: 'Yes',
          onClick: () => this.removeTollPass(false),
        },
        closable: false,
        closeOnOutsideClick: true,
      });

    try {
      const newVehicle = await updateVehicleApi(vehicle.id, {
        ezpass_tag_number: null,
      });
      this.set(newVehicle);
      onAttributeRefresh(['ezpass_tag_number']);
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
    }
    this.setState({loading: false});
  };

  openPreview = () => {
    const {doc} = this.state;
    if (!doc) return;
    this.setState({preview: true});
  };

  closePreview = () => {
    this.setState({preview: false});
  };

  render() {
    const {showUploader, doc, preview} = this.state;
    const {vehicle, ...props} = this.props;
    return (
      <Fragment>
        {doc !== null && (
          <FilePreview
            files={[{...doc, url: doc.document_file}]}
            visible={preview}
            onClose={this.closePreview}
          />
        )}
        <VehicleTollPassCard
          {...props}
          tollPassSet={!!vehicle.ezpass_tag_number}
          onShowUploader={this.showUploader}
          onOpen={this.openPreview}
          onRemove={this.removeTollPass}
          file={doc}
        />
        <AttributeFileUploader
          vehicle={vehicle}
          title="Toll Pass"
          type="vehicle"
          folderType={tollPassFile.type}
          visible={showUploader}
          onDone={this.onDone}
          onClose={this.hideUploader}
        />
      </Fragment>
    );
  }
}

export {VehicleTollPassContainer};

export default connect()(VehicleTollPassContainer);
