import styled from 'styled-components';

const Bottom = styled.div`
  margin: 0px 30px;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-y: hidden;
`;

export default Bottom;
