const columns = [
  {
    key: 'driverStatement',
    label: 'Driver Statement',
  },
  {
    key: 'photos',
    label: 'Photos',
  },
  {
    key: 'policeReport',
    label: 'Police Report',
  },
  {
    key: 'claim',
    label: 'Claim',
  },
];

export default columns;
