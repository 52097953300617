import validate from './validate.attribute';
import format from './format.attribute';
import requiredAttribute from './required.attribute';
import reinit from './reinit.attribute';

export default (attributes, {required = [], field = null} = {}) => {
  const reinitedAttributes = reinit(attributes);
  const formatted = requiredAttribute(reinitedAttributes, {required, field});
  validate(formatted, field);
  return format(formatted, field);
};
