import {DATETIME} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {dateTime, apiDateTime} from '../../types';

export default attribute({
  type: DATETIME,
  attribute: 'date_etc',
  display: dateTime,
  input: dateTime,
  api: apiDateTime,
  label: {
    default: 'ETC',
    short: 'ETC',
  },
  disabledDates: [{before: new Date()}],
});
