import React, {Component, Fragment} from 'react';
import {v4} from 'uuid';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {set as setAct} from '../../redux/actions';

// Api
import listTollsApi from '../../api/list.api.toll';

// Components
import TollsList from '../../components/TollsList/TollsList';

// Containers
import ReassignTollContainer from '../ReassignTollContainer/ReassignTollContainer';

// Events
import vehicleReassignedEvt from '../../events/vehicleReassigned.event.toll';
import employeeAssignedEvt from '../../events/employeeAssigned.event.toll';

// Libs
import {lib} from '@matthahn/sally-ui';
import sortQuery from '../../../api/lib/sortQuery.lib.api';
import numberOfPages from '../../../lib/numberOfPages';

// Query
import {isNull} from '../../../api/queries/queries';

// Alert
const {alert} = lib;

class TollsContainer extends Component {
  static propTypes = {
    numberOfTolls: PropTypes.number,
    dispatch: PropTypes.func,
    medallion: PropTypes.string,
  };

  static defaultProps = {
    medallion: '',
  };

  static OPTIONS = {
    perPage: 50,
  };

  static DEFAULT_SORT = {
    key: 'transaction_date',
    direction: 'desc',
  };

  state = {
    results: 0,
    page: 1,
    loading: true,
    tolls: [],
    sort: {...this.constructor.DEFAULT_SORT},
    showTollReassignModal: false,
    reassignToll: null,
    medallion: this.props.medallion,
  };

  componentDidMount() {
    const {medallion} = this.props;
    this.mounted = true;
    this.subscribe();
    this.getTolls({medallion});
  }

  componentWillUnmount() {
    this.mounted = false;
    this.unsubscribe();
  }

  mounted = false;
  apiID = v4();
  subscribers = [];

  subscribe = () => {
    this.subscribers = [
      vehicleReassignedEvt.sub(this.vehicleReassigned),
      employeeAssignedEvt.sub(this.employeeAssigned),
    ];
  };

  unsubscribe = () => {
    this.subscribers.forEach((unsub) => unsub());
    this.subscribers = [];
  };

  vehicleReassigned = (toll) => {
    const tolls = [...this.state.tolls].map((t) =>
      t.id === toll.id ? toll : t
    );
    this.setState({tolls});
  };

  employeeAssigned = (toll) => {
    const tolls = [...this.state.tolls].filter((t) => t.id !== toll.id);
    this.setState({tolls});
  };

  onPage = (page) => this.getTolls({page});

  onSort = (sort) => this.getTolls({sort});

  onMedallion = (medallion) => this.getTolls({medallion, page: 1});

  pages = () =>
    numberOfPages(this.state.results, this.constructor.OPTIONS.perPage);

  getTolls = async ({
    page = this.state.page,
    sort = this.state.sort,
    medallion = this.state.medallion,
  } = {}) => {
    const apiID = v4();
    this.apiID = apiID;
    const {perPage} = this.constructor.OPTIONS;
    this.setState({loading: true, tolls: [], page, sort, medallion});
    const query = {
      ...sortQuery(sort || this.constructor.DEFAULT_SORT),
      [isNull('rental')]: true,
      [isNull('assigned_to_employee')]: true,
      removed: false,
      limit: perPage,
      offset: (page - 1) * perPage,
    };

    if (!!medallion.trim().length)
      query.vehicle__medallion__medallion_number = medallion.toUpperCase();

    try {
      const {results, count} = await listTollsApi(query);
      if (this.apiID !== apiID || !this.mounted) return;
      this.setState({tolls: results, loading: false, results: count});
    } catch (error) {
      if (this.apiID !== apiID || !this.mounted) return;
      alert.info('The request could not be made');
      this.setState({loading: false, tolls: [], results: 0});
    }
  };

  onToll = (toll) => () => this.showTollReasign(toll);

  showTollReasign = (toll) =>
    this.setState({showTollReassignModal: true, reassignToll: {...toll}});

  hideTollReasign = () => this.setState({showTollReassignModal: false});

  reassigningComplete = () => {
    const {dispatch, numberOfTolls} = this.props;
    const {showTollReassignModal, reassignToll, tolls} = this.state;
    if (!showTollReassignModal || !reassignToll) return;
    const newNumberOfTolls = !numberOfTolls ? 0 : numberOfTolls - 1;
    dispatch(
      setAct({numberOfTolls: newNumberOfTolls > 0 ? newNumberOfTolls : 0})
    );
    this.setState({
      tolls: [...tolls].filter(({id}) => id !== reassignToll.id),
      showTollReassignModal: false,
    });
  };

  render() {
    const {
      page,
      loading,
      tolls,
      sort,
      results,
      showTollReassignModal,
      reassignToll,
      medallion,
    } = this.state;
    return (
      <Fragment>
        <TollsList
          medallion={medallion}
          results={results}
          loading={loading}
          tolls={tolls}
          page={page}
          pages={this.pages()}
          onPage={this.onPage}
          sort={sort}
          onSort={this.onSort}
          onToll={this.onToll}
          onMedallion={this.onMedallion}
        />
        <ReassignTollContainer
          visible={showTollReassignModal}
          toll={reassignToll}
          onAdd={this.showAssignModal}
          onClose={this.hideTollReasign}
          onComplete={this.reassigningComplete}
          onDelete={this.reassigningComplete}
        />
      </Fragment>
    );
  }
}

export default connect((state) => ({numberOfTolls: state.toll.numberOfTolls}))(
  TollsContainer
);
