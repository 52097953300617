// Action constants
import {SET} from './constants';

const DEFAULT_STATE = {
  visible: false,

  driversLoading: false,
  driverLoaded: false,
  drivers: [],

  vehiclesLoading: false,
  vehicleLoaded: false,
  vehicles: [],

  medallionsLoading: false,
  medallionLoaded: false,
  medallions: [],
};

export default (state = DEFAULT_STATE, {type, data}) => {
  switch (type) {
    case SET: {
      return {
        ...state,
        ...data,
      };
    }

    default: {
      return state;
    }
  }
};
