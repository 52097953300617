import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Avatar, Row, Column} from '@matthahn/sally-ui';
import Container from './components/Container';
import AvatarContainer from './components/AvatarContainer';
import Name from './components/Name';

const SpotlightRow = ({
  title,
  addon1,
  addon2,
  avatar,
  avatarProps,
  onClick,
}) => (
  <Container onClick={onClick}>
    <AvatarContainer>
      <Avatar
        avatar={avatar || title}
        theme="grey"
        size="large"
        {...avatarProps}
      />
    </AvatarContainer>
    <Name>
      <Row>
        <Column size={1 / 3}>{title}</Column>
        <Column size={1 / 3}>{addon1}</Column>
        <Column size={1 / 3}>{addon2}</Column>
      </Row>
    </Name>
  </Container>
);

SpotlightRow.propTypes = {
  avatar: PropTypes.string,
  title: PropTypes.string,
  addon1: PropTypes.string,
  addon2: PropTypes.string,
  avatarProps: PropTypes.object,
};

SpotlightRow.defaultProps = {
  avatarProps: {},
};

export default SpotlightRow;
