import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/noData.profile.api';

// Events
import event from '../events/taken.event.payment';

export default async (payment, jwt = null) => {
  const api = f3tch('/payments/take_payment_from_driver/').profile(profile());

  if (!!jwt) api.headers({Authorization: `JWT ${jwt}`});

  const response = await api.body(payment).post();
  event.pub(response);
  return response;
};
