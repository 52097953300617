import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// accident components
import AccidentTableHeader from '../../components/AccidentTableHeader/AccidentTableHeader';
import AccidentValueTable from '../../components/AccidentValueTable/AccidentValueTable';

// Components
import {Card} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

// vehicle lib
import getVehicleMakes from '../../../vehicle/libs/getVehicleMakes.lib.vehicle';
import getVehicleModels from '../../../vehicle/libs/getVehicleModels.lib.vehicle';
import getVehicleTypes from '../../../vehicle/libs/getVehicleTypes.lib.vehicle';

const PartiesInvolvedCard = ({
  saving,
  onChange,
  onSave,

  address_city,
  address_state,
  address_street1,
  address_street2,
  address_zip,
  date_of_birth,
  dmv_license_number,
  dmv_license_state,
  driver_first_name,
  driver_last_name,
  driver_middle,
  insurance_company,
  party_type,
  plate_number,
  policy_number,
  registered_owner_address_apt_number,
  registered_owner_address_city,
  registered_owner_address_state,
  registered_owner_address_street,
  registered_owner_address_zip,
  registered_owner_name_first,
  registered_owner_name_last,
  registered_owner_name_middle,
  registration_state,
  sameAsDriverInfo,
  vehicle_make,
  vehicle_model,
  vehicle_type,
  vehicle_vin,
  vehicle_year,
}) => (
  <Card
    title="Other Parties Involved"
    buttonsRight={[
      {label: 'Save', loading: saving, onClick: onSave, theme: 'orange'},
    ]}
  >
    {(Content) => (
      <Fragment>
        <Content padding="none">
          <AccidentTableHeader>General Info</AccidentTableHeader>
          <AccidentValueTable
            label={party_type.label.default}
            display={
              <AttributeInput
                value={party_type}
                onChange={onChange}
                disabled={saving}
                notFilterable
                size="small"
                flat
              />
            }
            wrapContent
            noPadding
          />
          <AccidentValueTable
            label={driver_first_name.label.default}
            display={
              <AttributeInput
                value={driver_first_name}
                placeholder={driver_first_name.label.default}
                onChange={onChange}
                disabled={saving}
                notFilterable
                size="small"
                flat
              />
            }
            wrapContent
            noPadding
          />
          <AccidentValueTable
            label={driver_last_name.label.default}
            display={
              <AttributeInput
                value={driver_last_name}
                placeholder={driver_last_name.label.default}
                onChange={onChange}
                disabled={saving}
                size="small"
                flat
              />
            }
            wrapContent
            noPadding
          />
          <AccidentValueTable
            label={driver_middle.label.default}
            display={
              <AttributeInput
                value={driver_middle}
                placeholder={driver_middle.label.default}
                onChange={onChange}
                disabled={saving}
                size="small"
                flat
              />
            }
            wrapContent
            noPadding
          />
          <AccidentValueTable
            label={date_of_birth.label.default}
            display={
              <AttributeInput
                value={date_of_birth}
                onChange={onChange}
                disabled={saving}
                size="small"
                flat
              />
            }
            wrapContent
            noPadding
          />
          <AccidentValueTable
            label={dmv_license_state.label.default}
            display={
              <AttributeInput
                value={dmv_license_state}
                onChange={onChange}
                disabled={saving}
                size="small"
                flat
              />
            }
            wrapContent
            noPadding
          />
          <AccidentValueTable
            label={dmv_license_number.label.default}
            display={
              <AttributeInput
                value={dmv_license_number}
                placeholder={dmv_license_number.label.default}
                onChange={onChange}
                disabled={saving}
                size="small"
                flat
              />
            }
            wrapContent
            noPadding
          />
        </Content>
        <Content padding="none">
          <AccidentTableHeader>Address</AccidentTableHeader>
          <AccidentValueTable
            label={address_street1.label.default}
            display={
              <AttributeInput
                value={address_street1}
                placeholder={address_street1.label.default}
                onChange={onChange}
                disabled={saving}
                size="small"
                flat
              />
            }
            wrapContent
            noPadding
          />
          <AccidentValueTable
            label={address_street2.label.default}
            display={
              <AttributeInput
                value={address_street2}
                placeholder={address_street2.label.default}
                onChange={onChange}
                disabled={saving}
                size="small"
                flat
              />
            }
            wrapContent
            noPadding
          />
          <AccidentValueTable
            label={address_zip.label.default}
            display={
              <AttributeInput
                value={address_zip}
                placeholder={address_zip.label.default}
                onChange={onChange}
                disabled={saving}
                size="small"
                flat
              />
            }
            wrapContent
            noPadding
          />
          <AccidentValueTable
            label={address_city.label.default}
            display={
              <AttributeInput
                value={address_city}
                placeholder={address_city.label.default}
                onChange={onChange}
                disabled={saving}
                size="small"
                flat
              />
            }
            wrapContent
            noPadding
          />
          <AccidentValueTable
            label={address_state.label.default}
            display={
              <AttributeInput
                value={address_state}
                onChange={onChange}
                disabled={saving}
                size="small"
                flat
              />
            }
            wrapContent
            noPadding
          />
        </Content>
        <Content padding="none">
          <AccidentTableHeader>Owner</AccidentTableHeader>
          <AccidentValueTable
            label={sameAsDriverInfo.label.default}
            display={
              <AttributeInput
                value={sameAsDriverInfo}
                onChange={onChange}
                disabled={saving}
                size="small"
                flat
              />
            }
          />
          {!sameAsDriverInfo.value() && (
            <Fragment>
              <AccidentValueTable
                label={registered_owner_name_first.label.default}
                display={
                  <AttributeInput
                    value={registered_owner_name_first}
                    placeholder={registered_owner_name_first.label.default}
                    onChange={onChange}
                    disabled={saving}
                    size="small"
                    flat
                  />
                }
                wrapContent
                noPadding
              />
              <AccidentValueTable
                label={registered_owner_name_last.label.default}
                display={
                  <AttributeInput
                    value={registered_owner_name_last}
                    placeholder={registered_owner_name_last.label.default}
                    onChange={onChange}
                    disabled={saving}
                    size="small"
                    flat
                  />
                }
                wrapContent
                noPadding
              />
              <AccidentValueTable
                label={registered_owner_name_middle.label.default}
                display={
                  <AttributeInput
                    value={registered_owner_name_middle}
                    placeholder={registered_owner_name_middle.label.default}
                    onChange={onChange}
                    disabled={saving}
                    size="small"
                    flat
                  />
                }
                wrapContent
                noPadding
              />
              <AccidentValueTable
                label={registered_owner_address_street.label.default}
                display={
                  <AttributeInput
                    value={registered_owner_address_street}
                    placeholder={registered_owner_address_street.label.default}
                    onChange={onChange}
                    disabled={saving}
                    size="small"
                    flat
                  />
                }
                wrapContent
                noPadding
              />
              <AccidentValueTable
                label={registered_owner_address_apt_number.label.default}
                display={
                  <AttributeInput
                    value={registered_owner_address_apt_number}
                    placeholder={
                      registered_owner_address_apt_number.label.default
                    }
                    onChange={onChange}
                    disabled={saving}
                    size="small"
                    flat
                  />
                }
                wrapContent
                noPadding
              />
              <AccidentValueTable
                label={registered_owner_address_zip.label.default}
                display={
                  <AttributeInput
                    value={registered_owner_address_zip}
                    placeholder={registered_owner_address_zip.label.default}
                    onChange={onChange}
                    disabled={saving}
                    size="small"
                    flat
                  />
                }
                wrapContent
                noPadding
              />
              <AccidentValueTable
                label={registered_owner_address_city.label.default}
                display={
                  <AttributeInput
                    value={registered_owner_address_city}
                    placeholder={registered_owner_address_city.label.default}
                    onChange={onChange}
                    disabled={saving}
                    size="small"
                    flat
                  />
                }
                wrapContent
                noPadding
              />
              <AccidentValueTable
                label={registered_owner_address_state.label.default}
                display={
                  <AttributeInput
                    value={registered_owner_address_state}
                    onChange={onChange}
                    disabled={saving}
                    size="small"
                    flat
                  />
                }
                wrapContent
                noPadding
              />
            </Fragment>
          )}
        </Content>
        <Content padding="none">
          <AccidentTableHeader>Insurance</AccidentTableHeader>
          <AccidentValueTable
            label={insurance_company.label.default}
            display={
              <AttributeInput
                value={insurance_company}
                placeholder={insurance_company.label.default}
                onChange={onChange}
                disabled={saving}
                size="small"
                flat
              />
            }
            wrapContent
            noPadding
          />
          <AccidentValueTable
            label={policy_number.label.default}
            display={
              <AttributeInput
                value={policy_number}
                placeholder={policy_number.label.default}
                onChange={onChange}
                disabled={saving}
                size="small"
                flat
              />
            }
            wrapContent
            noPadding
          />
        </Content>
        <Content padding="none">
          <AccidentTableHeader>Vehicle Info</AccidentTableHeader>
          <AccidentValueTable
            label={plate_number.label.default}
            display={
              <AttributeInput
                value={plate_number}
                placeholder={plate_number.label.default}
                onChange={onChange}
                disabled={saving}
                size="small"
                flat
              />
            }
            wrapContent
            noPadding
          />
          <AccidentValueTable
            label={vehicle_vin.label.default}
            display={
              <AttributeInput
                value={vehicle_vin}
                placeholder={vehicle_vin.label.default}
                onChange={onChange}
                disabled={saving}
                size="small"
                flat
              />
            }
            wrapContent
            noPadding
          />
          <AccidentValueTable
            label={vehicle_type.label.default}
            display={
              <AttributeInput
                value={vehicle_type}
                onChange={onChange}
                disabled={saving}
                size="small"
                flat
                option={getVehicleTypes()}
              />
            }
            wrapContent
            noPadding
          />
          <AccidentValueTable
            label={vehicle_year.label.default}
            display={
              <AttributeInput
                value={vehicle_year}
                placeholder={vehicle_year.label.default}
                onChange={onChange}
                disabled={saving}
                size="small"
                flat
              />
            }
            wrapContent
            noPadding
          />
          <AccidentValueTable
            label={vehicle_make.label.default}
            display={
              <AttributeInput
                value={vehicle_make}
                placeholder={vehicle_make.label.default}
                onChange={onChange}
                disabled={saving}
                size="small"
                flat
                options={getVehicleMakes()}
              />
            }
            wrapContent
            noPadding
          />
          <AccidentValueTable
            label={vehicle_model.label.default}
            display={
              <AttributeInput
                value={vehicle_model}
                placeholder={vehicle_model.label.default}
                onChange={onChange}
                disabled={saving}
                size="small"
                flat
                options={getVehicleModels({make: vehicle_make.api.format()})}
              />
            }
            wrapContent
            noPadding
          />
          <AccidentValueTable
            label={registration_state.label.default}
            display={
              <AttributeInput
                value={registration_state}
                onChange={onChange}
                disabled={saving}
                size="small"
                flat
              />
            }
            wrapContent
            noPadding
          />
        </Content>
      </Fragment>
    )}
  </Card>
);

PartiesInvolvedCard.propTypes = {
  saving: PropTypes.bool,
  onChange: PropTypes.func,
  onSave: PropTypes.func,

  address_city: PropTypes.object,
  address_state: PropTypes.object,
  address_street1: PropTypes.object,
  address_street2: PropTypes.object,
  address_zip: PropTypes.object,
  date_of_birth: PropTypes.object,
  dmv_license_number: PropTypes.object,
  dmv_license_state: PropTypes.object,
  driver_first_name: PropTypes.object,
  driver_last_name: PropTypes.object,
  driver_middle: PropTypes.object,
  insurance_company: PropTypes.object,
  party_type: PropTypes.object,
  plate_number: PropTypes.object,
  policy_number: PropTypes.object,
  registered_owner_address_apt_number: PropTypes.object,
  registered_owner_address_city: PropTypes.object,
  registered_owner_address_state: PropTypes.object,
  registered_owner_address_street: PropTypes.object,
  registered_owner_address_zip: PropTypes.object,
  registered_owner_name_first: PropTypes.object,
  registered_owner_name_last: PropTypes.object,
  registered_owner_name_middle: PropTypes.object,
  registration_state: PropTypes.object,
  sameAsDriverInfo: PropTypes.object,
  vehicle_make: PropTypes.object,
  vehicle_model: PropTypes.object,
  vehicle_type: PropTypes.object,
  vehicle_vin: PropTypes.object,
  vehicle_year: PropTypes.object,
};

export default PartiesInvolvedCard;
