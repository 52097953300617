import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {capitalWord} from '../../types';

export default attribute({
  type: TEXT,
  attribute: 'poc2_address_street2',
  display: capitalWord,
  input: capitalWord,
  api: capitalWord,
  label: {
    default: 'Apartment / Floor',
    short: 'Apt / Floor',
  },
});
