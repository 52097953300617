import React from 'react';
import PropTypes from 'prop-types';

// components
import {Card} from '@matthahn/sally-ui';

// layout components
import AttributeText from '../../../layout/components/AttributeText/AttributeText';

const VehicleMileageCard = ({mileage, onEdit}) => (
  <Card
    title="Mileage"
    icon="dashboard"
    headerActions={[{tooltip: 'Update mileage', icon: 'plus', onClick: onEdit}]}
  >
    {(Content) => (
      <Content>
        <AttributeText attribute={mileage}>Mileage</AttributeText>
      </Content>
    )}
  </Card>
);

VehicleMileageCard.propTypes = {
  mileage: PropTypes.object,
  onEdit: PropTypes.func,
};

export default VehicleMileageCard;
