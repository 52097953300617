import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {registrationControlNumber} from '../../types';

export default attribute({
  type: TEXT,
  attribute: 'registration_control_number_confirm',
  display: registrationControlNumber,
  input: registrationControlNumber,
  api: registrationControlNumber,
  label: {
    default: 'Confirm Document Control Number',
    short: 'Confirm Control Number',
  },
});
