import styled from 'styled-components';

// Colors
import {lib} from '@matthahn/sally-ui';
const {
  colors: {grey},
} = lib;

const FlexCol = styled.div`
  width: ${({full}) => (full ? '100%' : '50%')};
  max-width: ${({full}) => (full ? '100%' : '50%')};
  min-width: ${({full}) => (full ? '100%' : '50%')};
  border-style: ${({border}) => (border ? 'none none none solid' : 'none')};
  border-width: 1px;
  border-color: ${grey};
`;

export default FlexCol;
