import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// accident components
import AccidentTableHeader from '../../components/AccidentTableHeader/AccidentTableHeader';
import AccidentValueTable from '../../components/AccidentValueTable/AccidentValueTable';

// Components
import {Card} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

const AccidentInsuranceCard = ({
  loading,
  onChange,
  onSave,

  insurance_adjuster_name,
  insurance_adjuster_contact_info_email,
  insurance_adjuster_contact_info_phone,
  insurance_carrier,
  insurance_claim_number,

  settlement_denied,
  settlement_pending_amount,
  settlement_reason,
}) => (
  <Card
    title="Insurance Info"
    buttonsRight={[{label: 'Save', loading, onClick: onSave, theme: 'orange'}]}
  >
    {(Content) => (
      <Fragment>
        <Content padding="none">
          <AccidentValueTable
            label={settlement_pending_amount.label.default}
            display={
              <AttributeInput
                value={settlement_pending_amount}
                onChange={onChange}
                disabled={loading}
                size="small"
                flat
                range
              />
            }
          />
          <AccidentValueTable
            label={settlement_denied.label.default}
            display={
              <AttributeInput
                value={settlement_denied}
                onChange={onChange}
                disabled={loading || settlement_pending_amount.api.format() > 0}
                size="small"
              />
            }
          />
          <AccidentValueTable
            label={settlement_reason.label.default}
            display={
              <AttributeInput
                value={settlement_reason}
                onChange={onChange}
                disabled={loading}
                size="small"
              />
            }
            noPadding
          />
        </Content>
        <Content padding="none">
          <AccidentTableHeader>Insurance</AccidentTableHeader>
          <AccidentValueTable
            label={insurance_adjuster_name.label.default}
            display={
              <AttributeInput
                value={insurance_adjuster_name}
                placeholder={insurance_adjuster_name.label.default}
                onChange={onChange}
                disabled={loading}
                size="small"
                flat
              />
            }
            wrapContent
            noPadding
          />
          <AccidentValueTable
            label={insurance_carrier.label.default}
            display={
              <AttributeInput
                value={insurance_carrier}
                onChange={onChange}
                disabled={loading}
                notFilterable
                size="small"
                flat
              />
            }
            wrapContent
            noPadding
          />
          <AccidentValueTable
            label={insurance_claim_number.label.default}
            display={
              <AttributeInput
                value={insurance_claim_number}
                placeholder={insurance_claim_number.label.default}
                onChange={onChange}
                disabled={loading}
                size="small"
                flat
              />
            }
            wrapContent
            noPadding
          />
          <AccidentValueTable
            label={insurance_adjuster_contact_info_phone.label.default}
            display={
              <AttributeInput
                value={insurance_adjuster_contact_info_phone}
                placeholder={
                  insurance_adjuster_contact_info_phone.label.default
                }
                onChange={onChange}
                disabled={loading}
                size="small"
                flat
              />
            }
            wrapContent
            noPadding
          />
          <AccidentValueTable
            label={insurance_adjuster_contact_info_email.label.default}
            display={
              <AttributeInput
                value={insurance_adjuster_contact_info_email}
                placeholder={
                  insurance_adjuster_contact_info_email.label.default
                }
                onChange={onChange}
                disabled={loading}
                size="small"
                flat
              />
            }
            wrapContent
            noPadding
          />
        </Content>
      </Fragment>
    )}
  </Card>
);

AccidentInsuranceCard.propTypes = {
  loading: PropTypes.bool,
  accident: PropTypes.object,
  onChange: PropTypes.func,
  onSave: PropTypes.func,

  insurance_adjuster_name: PropTypes.object,
  insurance_adjuster_contact_info_email: PropTypes.object,
  insurance_adjuster_contact_info_phone: PropTypes.object,
  insurance_carrier: PropTypes.object,
  insurance_claim_number: PropTypes.object,

  settlement_denied: PropTypes.object,
  settlement_pending_amount: PropTypes.object,
  settlement_reason: PropTypes.object,
};

export default AccidentInsuranceCard;
