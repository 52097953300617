import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// API
import createTransactionApi from '../../api/create.api.transaction';
import getDriverByIDApi from '../../../driver/api/getByID.api.driver';
import listRentalsApi from '../../../rental/api/list.api.rental';

// Actions
import {hide as hideAct} from './redux/actions';
import {show as showPayoutAct} from '../../../payout/containers/PayoutDriverContainer/redux/actions';

// Attributes
import amountAttr from '../../attributes/amount.attribute.transaction';
import subtypeAttr from '../../attributes/creditSubtype.attribute.transaction';
import descriptionAttr from '../../attributes/description.attribute.transaction';
import driverAttr from '../../attributes/driver.attribute.transaction';

// Components
import CreditModal from '../../components/CreditModal/CreditModal';

// Constants
import creditToChargeList from '../../constants/creditToCharge.constant.transaction';

// Driver
import activeDriverState from '../../../driver/state/active.state.driver';

// Libs
import {lib} from '@matthahn/sally-ui';
import parseError from '../../../error/parseError';
import getTransactionSubtypes from '../../../transactionType/lib/getSubtype.lib.transactionType';
import fkOrId from '../../../lib/fkOrId';

// Prep
import creditPrep from '../../preparation/credit.preparation.transaction';

// Permission
import creditPermission from '../../permission/credit.permission.transaction';

// Rental
import activeRentalState from '../../../rental/state/active.state.rental';

// Types
import {dateTime} from '../../../types';

// UI
const {alert} = lib;

class CreditContainer extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    loadingTransactionTypes: PropTypes.bool,
    transactionTypes: PropTypes.array,
    driver: PropTypes.object,
    drivers: PropTypes.array,
    requestAddon: PropTypes.object,
    allocate: PropTypes.bool,
    dispatch: PropTypes.func,
  };

  static defaultProps = {
    requestAddon: {},
  };

  static INITIAL_STATE = {
    loading: false,
    loadingDrivers: false,
    amount: amountAttr(''),
    subtype: subtypeAttr(''),
    description: descriptionAttr(''),
    suggestedDrivers: [],
    chargeDriver: null,
    showDriverSelection: false,
  };

  state = {...this.constructor.INITIAL_STATE};

  componentDidMount() {
    if (this.props.visible) this.init();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) this.init();
  }

  init = () => {
    this.setState({...this.constructor.INITIAL_STATE});
    this.getSuggestedDrivers();
  };

  hide = () => {
    if (this.state.loading) return;
    this.props.dispatch(hideAct(false));
  };

  change = (val, key) => {
    if (this.state.loading) return;
    const state = {[key]: val};
    if (key === 'subtype') {
      state.chargeDriver = null;
      state.showDriverSelection = creditToChargeList.includes(val.api.format());
    }
    this.setState(state);
  };

  getSuggestedDrivers = async () => {
    const {driver} = this.props;
    const stop = () =>
      this.setState({loadingDrivers: false, suggestedDrivers: []});
    if (!driver || driver.state !== activeDriverState.key) return stop();
    this.setState({loadingDrivers: true});

    try {
      const {results: activeDriverRentals} = await listRentalsApi({
        ordering: '-created_at',
        driver: driver.id,
        state: activeRentalState.key,
      });
      const activeDriverRental = [...activeDriverRentals].find(
        (rental) =>
          rental.state === activeRentalState.key &&
          fkOrId(rental.driver) === driver.id
      );
      if (!activeDriverRental) return stop();

      const {results: rentals} = await listRentalsApi({
        ordering: '-created_at',
        vehicle: fkOrId(activeDriverRental.vehicle),
        limit: 10,
        offest: 0,
        'driver!': driver.id,
      });

      const suggestedDrivers = [...rentals]
        .filter((rental) => fkOrId(rental.driver) !== driver.id)
        .map((rental) => ({
          ...rental.driver,
          rental,
        }));

      this.setState({loadingDrivers: false, suggestedDrivers});
    } catch (error) {
      stop();
    }
  };

  save =
    (allocate = true) =>
    async () => {
      const {visible, dispatch, driver, requestAddon} = this.props;

      const {
        loading,
        amount,
        subtype,
        description,
        chargeDriver,
        showDriverSelection,
      } = this.state;

      if (!creditPermission())
        return alert.warning('You do not have permissions to perform this.');
      if (!visible || !driver || loading) return;

      this.setState({loading: true});

      try {
        const credit = await creditPrep({
          amount,
          balance: amount,
          subtype,
          description,
          driver: driverAttr(driver.id),
        });
        credit.balance = credit.amount;
        const api = () =>
          this.issueCredit({
            credit,
            requestAddon,
            showDriverSelection,
            chargeDriver,
            driver,
          });
        if (allocate) {
          dispatch(
            showPayoutAct({
              driver,
              incomeAllocation: {
                label: 'Credit',
                transaction: credit,
                api,
              },
            })
          );
          dispatch(hideAct(false));
          return;
        }
        const charged = await api();
        this.setState({loading: false});
        const {message, type} = this.message(showDriverSelection, charged);
        alert[type](message);
        dispatch(hideAct(true));
      } catch (error) {
        const {message} = parseError(error);
        alert.error(message);
        this.setState({loading: false});
      }
    };

  issueCredit = async ({
    credit,
    requestAddon,
    showDriverSelection,
    chargeDriver,
    driver,
  }) => {
    await createTransactionApi({...credit, ...requestAddon});
    const charged =
      showDriverSelection && !!chargeDriver
        ? await this.charge(credit, chargeDriver)
        : false;
    await getDriverByIDApi(driver.id);
    return charged;
  };

  charge = async (credit, driver) => {
    const charge = {
      ...credit,
      driver,
      type: 'charge',
      description: `Automatic charge. (${credit.description})`,
    };
    try {
      await createTransactionApi(charge);
      return true;
    } catch (error) {
      return false;
    }
  };

  drivers = () =>
    [...this.state.suggestedDrivers, ...[...this.props.drivers]].reduce(
      (combined, {id, first_name, last_name, rental}) =>
        !!combined.find(({value}) => `${value}` === `${id}`)
          ? combined
          : [
              ...combined,
              {
                value: `${id}`,
                label: `${first_name} ${last_name}${
                  !!rental
                    ? ` - ${dateTime(rental.vehicle_dropoff_datetime).format()}`
                    : ''
                }`,
              },
            ],
      []
    );

  message = (shouldCharge, charged) => {
    if (!shouldCharge) return {type: 'success', message: 'Credit issued'};
    return charged
      ? {type: 'success', message: 'Credit issued & Charge created'}
      : {
          type: 'warning',
          message: 'Credit was issued but Charge could not be created',
        };
  };

  render() {
    const {visible, loadingTransactionTypes, transactionTypes, allocate} =
      this.props;
    const {
      loading,
      amount,
      subtype,
      description,
      chargeDriver,
      showDriverSelection,
    } = this.state;
    return (
      <CreditModal
        allocate={allocate}
        visible={visible}
        loading={loading || loadingTransactionTypes}
        amount={amount}
        subtype={subtype}
        subtypes={getTransactionSubtypes(transactionTypes, 'credit')}
        description={description}
        chargeDriver={chargeDriver}
        showDriverSelection={showDriverSelection}
        drivers={this.drivers()}
        onSave={this.save(true)}
        onIssue={this.save(false)}
        onChange={this.change}
        onClose={this.hide}
      />
    );
  }
}

export default connect((state) => ({
  ...state.credit,
  roles: state.auth.roles,
  loadingTransactionTypes: state.transactionType.loading,
  transactionTypes: state.transactionType.transactionTypes,
  drivers: state.spotlight.drivers,
}))(CreditContainer);
