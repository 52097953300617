import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

export default (tollID, query = {}) =>
  f3tch(`/tolls/rental_lookup_by_toll/${tollID}/`)
    .profile(profile())
    .query(query)
    .get();
