export default (fn) =>
  ({extension, duplicates, ...data}) => {
    const name = fn(data);
    const append = (() => {
      const similarFiles = duplicates.filter((file) =>
        (file?.name || '').startsWith(name)
      );
      return !!similarFiles.length ? `_${similarFiles.length + 1}` : '';
    })();
    return `${name}${append}.${extension}`;
  };
