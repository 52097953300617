import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {Card, Table, Row, Column, InputTyping} from '@matthahn/sally-ui';
import DriverTags from '../../../driver/components/DriverTags/DriverTags';

// Columns
import columns from './columns';

// Types
import {amount, dateTime} from '../../../types';

const ApprovedChequesCard = ({
  loading,
  cheques,
  search,
  onSearch,
  onAction,
  onDriver,
}) => (
  <Card title="Approved Cheques" icon="creditcard">
    {(Content) => (
      <Fragment>
        <Content>
          <Row>
            <Column size={1 / 2} offset={1 / 4}>
              <InputTyping
                preIcon="search1"
                placeholder="Search"
                value={search}
                onChange={onSearch}
              />
            </Column>
          </Row>
        </Content>
        <Content padding="none">
          <Table
            columns={columns}
            loading={loading && !cheques.length}
            theme="orange"
            infinite
            noBorder
            noRadius
            smallNoResultsLabel
          >
            {(TableRow) =>
              cheques.map((cheque) => (
                <TableRow key={cheque.check_number}>
                  {(TableColumn) => [
                    <TableColumn key="number" onClick={onAction(cheque)}>
                      {cheque.check_number}
                    </TableColumn>,
                    <TableColumn key="date" onClick={onAction(cheque)}>
                      {dateTime(cheque.created_at).format()}
                    </TableColumn>,
                    <TableColumn key="amount" onClick={onAction(cheque)}>
                      ${amount(cheque.amount).format()}
                    </TableColumn>,
                    <TableColumn key="driver" onClick={onDriver(cheque.driver)}>
                      {!!cheque.driver ? (
                        <DriverTags driver={cheque.driver}>
                          {cheque.driver.first_name} {cheque.driver.last_name}
                        </DriverTags>
                      ) : (
                        'REFRESH PAGE'
                      )}
                    </TableColumn>,
                    <TableColumn key="medallion" onClick={onAction(cheque)}>
                      {!!cheque.medallion && !!cheque.medallion.medallion_number
                        ? cheque.medallion.medallion_number
                        : '-'}
                    </TableColumn>,
                    <TableColumn key="approved_by" onClick={onAction(cheque)}>
                      {!!cheque.approved_by_user
                        ? cheque.approved_by_user.username
                        : ''}
                    </TableColumn>,
                  ]}
                </TableRow>
              ))
            }
          </Table>
        </Content>
      </Fragment>
    )}
  </Card>
);

ApprovedChequesCard.propTypes = {
  loading: PropTypes.bool,
  cheques: PropTypes.array,
  search: PropTypes.string,
  onSearch: PropTypes.func,
  onAction: PropTypes.func,
  onDriver: PropTypes.func,
};

export default ApprovedChequesCard;
