import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {string} from '../../types';

export default attribute({
  type: TEXT,
  attribute: 'description',
  display: string,
  input: string,
  api: string,
  label: {
    default: 'Description',
    short: 'Description',
  },
});
