// components
import Info from '@matthahn/sally-ui/lib/components/Info/Info';

// local components
import Date from './components/Date';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// types
import dateTimeType from '@matthahn/sally-fw/lib/type/types/dateTime.type';

const AlertInfo = ({children, alert, ...props}) => (
  <Info type="error" {...props}>
    {children || alert.message}
    {!!alert?.created_at && (
      <Date>{dateTimeType(alert.created_at).format()}</Date>
    )}
  </Info>
);

AlertInfo.propTypes = {
  children: PropTypes.node,
  alert: PropTypes.object,
};

export default AlertInfo;
