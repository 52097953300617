import {SET} from './constants';

export const set = (data = {}) => ({
  type: SET,
  data,
});

export const show = (driver = null, {allocate = true} = {}) =>
  set({driver, visible: true, allocate});

export const hide = (creditCreated = false) =>
  set({driver: null, visible: false, creditCreated});
