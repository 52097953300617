export default [
  {
    key: 'pickup',
    label: 'Start',
  },
  {
    key: 'dropoff',
    label: 'End',
  },
  {
    key: 'driver',
    label: 'Driver',
  },
  {
    key: 'contract_type',
    label: 'Rental Type',
  },
];
