import {profile} from 'f3tch';

// Errors
import isApiError from '../errors/isApiError.error.api';

// Profiles
import headers from '../headers/pdf.header.api';

export default ({authorize = true} = {}) =>
  profile()
    .headers(headers({authorize}))
    .bodyParser((data) => JSON.stringify(data))
    .responder(async (response) => {
      await isApiError(response);
      const data = await response.blob();
      return data;
    });
