import React from 'react';
import PropTypes from 'prop-types';

// Components
import {AddressBook, Container} from '@matthahn/sally-ui';
import PageContainer from './components/Container';
import Side from './components/Side';

const MedallionOwnersLayout = ({loading, children, medallionOwners, onAdd}) => (
  <PageContainer>
    <Side>
      <AddressBook
        loading={loading}
        title="Medallion Owners"
        action={{icon: 'plus', fn: onAdd}}
        items={medallionOwners}
        theme="orange"
        grouped
        border
      />
    </Side>
    <Container>{children}</Container>
  </PageContainer>
);

MedallionOwnersLayout.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
  medallionOwners: PropTypes.array,
  onAdd: PropTypes.func,
};

export default MedallionOwnersLayout;
