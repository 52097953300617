import {date, fileName, folder} from '../../../document';

export default folder({
  defaultFileName: 'Payout',
  // PAYOUT - DRIVER ID - MEDALLION NUMBER - UPLOAD DATE
  fileName: fileName(
    ({driver, rental}) =>
      `PAYOUT - ${driver.first_name} ${driver.last_name}${
        !!rental ? ` - ${rental.medallion.medallion_number}` : ''
      } - ${date()}`
  ),
  folderName: 'Payouts',
  type: 'payout',
  uploadable: false,
});
