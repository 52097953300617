import React from 'react';
import PropTypes from 'prop-types';

// components
import {Select} from '@matthahn/sally-ui';

// local handlers
import onChangeHandler from './handlers/onChange.handler';

// local lib
import parseOptions from './lib/parseOptions.lib';
import parseValue from './lib/parseValue.lib';

// redux
import {connect} from 'react-redux';

// transactionType lib
import getTransactionSubtypes from '../../../transactionType/lib/getSubtype.lib.transactionType';

const CreditTypePermission = ({label, onChange, value, transactionTypes}) => {
  const parsedValue = parseValue(value);
  return (
    <Select
      value={parsedValue}
      options={parseOptions(getTransactionSubtypes(transactionTypes, 'credit'))}
      onChange={onChangeHandler({value: parsedValue, onChange})}
    >
      {label}
    </Select>
  );
};

CreditTypePermission.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  transactionTypes: PropTypes.array,
  value: PropTypes.string,
};

export default connect((state) => ({
  transactionTypes: state.transactionType.transactionTypes,
}))(CreditTypePermission);
