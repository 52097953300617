import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

// Events
import appliedEvt from '../events/applied.event.payout';

export default async ({payout, dwolla_funding_id} = {}) => {
  const api = f3tch(`/payments/payouts/apply_deposit/`).profile(profile());

  const body = {
    ...payout,
  };

  if (!!dwolla_funding_id) body.dwolla_funding_id = dwolla_funding_id;

  api.body(body);

  const response = await api.post();

  appliedEvt.pub(response);
  return response;
};
