import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/file.profile.api';

export default (csrId, html) =>
  f3tch(`/documents/v2/contract/${csrId}/`)
    .profile(profile())
    .body(html)
    .post();
