import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal, Form, Row, Column} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

const NewMedallionModal = ({
  visible,
  onClose,
  loading,
  onCreate,
  onChange,
  errors,

  medallion_number,
}) => (
  <Modal
    visible={visible}
    onClose={onClose}
    size="small"
    closable={!loading}
    title="New Medallion"
    buttonsRight={[
      {
        loading,
        label: 'Create',
        theme: 'orange',
        onClick: onCreate,
      },
    ]}
  >
    {(Content) => (
      <Form onSubmit={onCreate} enter>
        <Content>
          <Row>
            <Column>
              <AttributeInput
                errors={errors}
                value={medallion_number}
                onChange={onChange}
                disabled={loading}
              >
                {medallion_number.label.short}
              </AttributeInput>
            </Column>
          </Row>
        </Content>
      </Form>
    )}
  </Modal>
);

NewMedallionModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  loading: PropTypes.bool,
  onCreate: PropTypes.func,
  onChange: PropTypes.func,
  errors: PropTypes.array,

  medallion_number: PropTypes.object,
};

export default NewMedallionModal;
