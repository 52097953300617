import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {medallionNumber} from '../../types';

export default attribute({
  type: TEXT,
  attribute: 'medallion_number',
  display: medallionNumber,
  input: medallionNumber,
  api: medallionNumber,
  label: {
    default: 'Medallion Number',
    short: 'Medallion Number',
  },
});
