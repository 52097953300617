import {SET} from './constants';

export const show = ({
  driver = null,
  rental = null,
  incomeAllocation = null,
  endRental = false,
  depositPayout = false,
} = {}) => ({
  type: SET,
  data: {
    visible: true,
    driver,
    incomeAllocation,
    rental,
    endRental,
    depositPayout,
  },
});

export const hide = () => ({
  type: SET,
  data: {visible: false},
});
