import {DATE} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {date, apiDate} from '../../types';

export default attribute({
  type: DATE,
  attribute: 'date_of_hire',
  display: date,
  input: date,
  api: apiDate,
  label: {
    default: 'Hiring date',
    short: 'Hiring date',
  },
  disabledDates: [{after: new Date()}],
});
