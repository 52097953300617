import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Components
import ImageSliderCard from '../../../layout/components/ImageSliderCard/ImageSliderCard';

class AccidentPhotosCard extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    fullScreen: PropTypes.bool,
    images: PropTypes.array,
    toggleFullScreen: PropTypes.func,
    onNext: PropTypes.func,
    onPrevious: PropTypes.func,
    onSliderRef: PropTypes.func,
  };

  render() {
    const {images} = this.props;
    return <ImageSliderCard title="Scene photos" images={images} />;
  }
}

export default AccidentPhotosCard;
