import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import isObject from 'is-object';

// Components
import {
  Modal,
  Table,
  InputTyping,
  Row,
  Column,
  Icon,
  Button,
} from '@matthahn/sally-ui';
import More from './components/More';

// Columns
import driverColumns from './driverColumns';
import medallionColumns from './medallionColumns';

// Types
import {dateTime} from '../../../types';

const DriverRentalTollModal = ({
  visible,
  loading,
  drivers,
  rentals,
  moreRentals,
  search,
  driver,
  onSearch,
  onDriver,
  onRental,
  onClose,
  onBack,
  onMore,
}) => (
  <Modal
    visible={visible}
    onClose={onClose}
    title="Rental Assignment"
    subtitle={
      !!driver
        ? `Select a rental for ${driver.first_name} ${driver.last_name}`
        : 'Select a Driver'
    }
    buttonsLeft={
      !!driver ? [{label: 'Back', onClick: onBack, disabled: loading}] : []
    }
    size="large"
  >
    {(Content) =>
      !!driver ? (
        <Content padding="none">
          <Table
            columns={medallionColumns}
            loading={loading}
            theme="orange"
            infinite
            noBorder
            noRadius
            smallNoResultsLabel
          >
            {(TableRow) =>
              rentals.map((rental) => (
                <TableRow key={rental.id} onClick={onRental(rental)}>
                  {(TableColumn) => [
                    <TableColumn key="pickup">
                      {!!rental.canApply && (
                        <span>
                          <Icon icon="arrowright" />{' '}
                        </span>
                      )}
                      {!!rental.vehicle_pickup_datetime
                        ? dateTime(rental.vehicle_pickup_datetime).format()
                        : '-'}
                    </TableColumn>,
                    <TableColumn key="dropoff">
                      {!!rental.vehicle_dropoff_datetime
                        ? dateTime(rental.vehicle_dropoff_datetime).format()
                        : '-'}
                    </TableColumn>,
                    <TableColumn key="medallion">
                      {!!rental.medallion
                        ? rental.medallion.medallion_number
                        : '-'}
                    </TableColumn>,
                    <TableColumn key="contract_type">
                      {isObject(rental.lease_agreement)
                        ? rental.lease_agreement.contract_type
                        : '-'}
                    </TableColumn>,
                  ]}
                </TableRow>
              ))
            }
          </Table>
          {moreRentals && !!rentals.length && (
            <More>
              <Button
                theme="orange"
                loading={loading}
                onClick={onMore}
                size="tiny"
                outline
              >
                Load More
              </Button>
            </More>
          )}
        </Content>
      ) : (
        <Fragment>
          <Content>
            <Row>
              <Column size={1 / 2} offset={1 / 4}>
                {!!visible && (
                  <InputTyping
                    preIcon="search1"
                    placeholder="Search"
                    value={search}
                    onChange={onSearch}
                  />
                )}
              </Column>
            </Row>
          </Content>
          <Content padding="none">
            <Table
              columns={driverColumns}
              loading={loading}
              theme="orange"
              infinite
              noBorder
              noRadius
              smallNoResultsLabel
            >
              {(TableRow) =>
                drivers.map((driver) => (
                  <TableRow key={driver.id} onClick={onDriver(driver)}>
                    {(TableColumn) => [
                      <TableColumn key="name" onClick={onDriver(driver)}>
                        {driver.first_name} {driver.last_name}
                      </TableColumn>,
                      <TableColumn key="fhv">
                        {driver.fhv_license_number || '-'}
                      </TableColumn>,
                    ]}
                  </TableRow>
                ))
              }
            </Table>
          </Content>
        </Fragment>
      )
    }
  </Modal>
);

DriverRentalTollModal.propTypes = {
  loading: PropTypes.bool,
  visible: PropTypes.bool,
  drivers: PropTypes.array,
  rentals: PropTypes.array,
  moreRentals: PropTypes.bool,
  search: PropTypes.string,
  driver: PropTypes.object,
  onSearch: PropTypes.func,
  onDriver: PropTypes.func,
  onRental: PropTypes.func,
  onClose: PropTypes.func,
  onBack: PropTypes.func,
  onMore: PropTypes.func,
};

export default DriverRentalTollModal;
