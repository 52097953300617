import {date, fileName, folder} from '../../../document';

// Attributes
import vinLastSevenAttr from '../../attributes/vinLastSeven.attribute.vehicle';

// Lib
import vinValidation from '../../libs/vinValidation.lib.vehicle';

// Errors
import vinMissmatch from '../../errors/vinMissmatch.error.vehicle';

export default folder({
  attributes: [vinLastSevenAttr],
  fileAttributes: ['vinLastSeven'],
  defaultFileName: 'MV-50',
  // MV-50 - VIN - UPLOAD DATE
  fileName: fileName(({vehicle}) => `MV-50 - ${vehicle.vin} - ${date()}`),
  folderName: 'MV-50',
  preSaveValidation: ({vehicle, vinLastSeven}) => {
    if (vinValidation(vehicle, vinLastSeven.api.format()))
      throw vinMissmatch({
        message: 'Last 7 VIN characters are incorrect',
      });
  },
  type: 'mv50',
});
