import {Component} from 'react';
import PropTypes from 'prop-types';

class PhotosStateContainer extends Component {
  static propTypes = {
    accident: PropTypes.object,
    children: PropTypes.func,
  };

  parseState = () => {
    const {accident} = this.props;
    return accident?.accidentstate?.photos_state === 'accident_photos_uploaded'
      ? {state: 'completed', label: 'Uploaded'}
      : {state: 'warning', label: 'Pending upload'};
  };

  render() {
    const {children} = this.props;
    return !!children ? children({state: this.parseState()}) : null;
  }
}

export default PhotosStateContainer;
