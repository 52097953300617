import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Api
import updateApi from '../../api/update.api.medallion';

// Actions
import {set as setAct} from '../../redux/actions';

// Attributes
import * as medallionAttributes from '../../attributes';
import objectToAttributes from '../../../attribute/objectToAttributes';

// Components
import {Row, Column} from '@matthahn/sally-ui';
import SubPage from '../../../layout/components/SubPage/SubPage';
import MedallionBasicInfoCard from '../../components/MedallionBasicInfoCard/MedallionBasicInfoCard';
import MedallionPaymentInfoCard from '../../components/MedallionPaymentInfoCard/MedallionPaymentInfoCard';
import MedallionStoredInfo from '../../components/MedallionStoredInfo/MedallionStoredInfo';
import MedallionUnmanagedInfo from '../../components/MedallionUnmanagedInfo/MedallionUnmanagedInfo';
import PocEdit from '../../components/PocEdit/PocEdit';

// Containers
// import MedallionLeaseContainer from '../MedallionLeaseContainer/MedallionLeaseContainer';
import SelectMedallionOwnerContainer from '../../../medallionOwner/containers/SelectMedallionOwnerContainer/SelectMedallionOwnerContainer';

// Error
import parseError from '../../../error/parseError';

// Libs
import {lib} from '@matthahn/sally-ui';
import {
  setUnsavedChanges,
  clearUnsavedChanges,
} from '../../../layout/lib/unsavedChanges.lib.layout';
import fkOrId from '../../../lib/fkOrId';

// medallionOwner containers
// import {Poc1Container} from '../../../medallionOwner/containers/Poc1Container/Poc1Container';
// import {Poc2Container} from '../../../medallionOwner/containers/Poc2Container/Poc2Container';

// Prep
import updatePrep from '../../preparation/update.preparation.medallion';

// Permission
import updatePermission from '../../permissions/update.permission.medallion';

// State
import storedState from '../../state/stored.state.medallion';
import unmanagedState from '../../state/unmanaged.state.medallion';

// Alerts
const {alert} = lib;

class MedallionInfoContainer extends Component {
  static propTypes = {
    medallion: PropTypes.object,
    save: PropTypes.number,
  };

  static attributes = [
    'medallion_number',
    'medallion_type',
    'medallion_expiry',

    'bank_account_number',
    'bank_routing_number',
    'ezpass_tag',
    'last_verified',
    'payment_terms',
    'state',

    'owner',

    'lease_rate',
    'lease_expiry',
    'lease_start_date',
    'lease_end_date',

    'payee',
    'payment_address_city',
    'payment_address_state',
    'payment_address_street1',
    'payment_address_street2',
    'payment_address_zip',
    'payment_method',

    'poc_address_city',
    'poc_address_state',
    'poc_address_street1',
    'poc_address_street2',
    'poc_address_zip',
    'poc_email',
    'poc_name',
    'poc_notes',
    'poc_phone_number',
  ];

  static medallionAttributes = {
    ...medallionAttributes,
  };

  state = {
    loading: false,
    medallion: null,
    errors: [],
    selectOwnerModalVisible: false,
  };

  componentDidMount() {
    this.mounted = true;
    this.setState({
      medallion: objectToAttributes({
        object: this.props.medallion,
        attributes: {
          ...this.constructor.medallionAttributes,
        },
        convert: this.constructor.attributes,
      }),
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.save !== this.props.save) this.save();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  change = setUnsavedChanges(
    (value, key, errors) => {
      const {medallion} = this.state;
      this.setState({medallion: {...medallion, [key]: value}, errors});
    },
    () => this.state.loading
  );

  save = async () => {
    // const {dispatch} = this.props;
    const {
      loading,
      medallion: {owner, ...attributes},
    } = this.state;

    if (loading) return;

    if (!updatePermission())
      return alert.warning(
        'You do not have permission to update medallion info'
      );

    this.setState({loading: true});

    try {
      const medallion = await updatePrep(attributes);
      await updateApi(this.props.medallion.id, medallion);
      // dispatch(
      //   setAct({medallion: {...this.props.medallion, ...response, owner}})
      // );
      clearUnsavedChanges();
      alert.success('Medallion info saved');
      if (!this.mounted) return;
      this.setState({
        loading: false,
      });
    } catch (error) {
      const {message, fields} = parseError(error);
      alert.error(message);
      if (!this.mounted) return;
      this.setState({loading: false, errors: fields});
    }
  };

  update = () => {};

  showOwnerModal = () => this.setState({selectOwnerModalVisible: true});

  hideOwnerModal = () => this.setState({selectOwnerModalVisible: false});

  saveOwner = async (owner) => {
    const {dispatch, medallion} = this.props;
    const {loading, medallion: stateMedallion} = this.state;

    if (loading || !medallion) return;

    this.setState({loading: true, selectOwnerModalVisible: false});

    try {
      const newMedallion = await updateApi(medallion.id, {owner: owner.id});
      if (!this.mounted) return;
      dispatch(setAct({medallion: {...newMedallion, owner}}));
      this.setState({
        loading: false,
        medallion: {
          ...stateMedallion,
          owner: medallionAttributes.owner(newMedallion.owner.id),
        },
      });
    } catch (error) {
      if (!this.mounted) return;
      const {message} = parseError(error);
      alert.error(message);
      this.setState({loading: false});
    }
  };

  refreshAttributes = (attributes) => {
    const {medallion} = this.state;
    const newMedallionAttributes = objectToAttributes({
      object: this.props.medallion,
      attributes: {
        ...this.constructor.medallionAttributes,
      },
      convert: attributes,
    });

    this.setState({
      medallion: {
        ...medallion,
        ...newMedallionAttributes,
        owner: medallionAttributes.owner(medallion.owner.id),
      },
    });
  };

  ownerChanged = (medallionOwner) => {
    const {medallion, dispatch} = this.props;
    const {medallion: stateMedallion} = this.state;
    dispatch(setAct({medallion: {...medallion, owner: medallionOwner}}));
    this.setState({
      loading: false,
      medallion: {
        ...stateMedallion,
        owner: medallionAttributes.owner(medallionOwner.id),
      },
    });
  };

  render() {
    const {medallion: rawMedallion} = this.props;
    const {loading, medallion, errors, selectOwnerModalVisible} = this.state;
    return !medallion ? null : (
      <SubPage>
        {rawMedallion.state === storedState.key && (
          <Row margin>
            <Column>
              <MedallionStoredInfo medallion={rawMedallion} />
            </Column>
          </Row>
        )}
        {rawMedallion.state === unmanagedState.key && (
          <Row margin>
            <Column>
              <MedallionUnmanagedInfo medallion={rawMedallion} />
            </Column>
          </Row>
        )}
        <Row margin>
          <Column size={1 / 3} m={1 / 2} margin>
            <MedallionBasicInfoCard
              loading={loading}
              errors={errors}
              hasPermission={updatePermission()}
              onChange={this.change}
              onSave={this.save}
              onOwner={this.showOwnerModal}
              {...medallion}
              owner={rawMedallion.owner}
            />
          </Column>
          <Column size={1 / 3} m={1 / 2} margin>
            <MedallionPaymentInfoCard
              loading={loading}
              errors={errors}
              hasPermission={updatePermission()}
              onChange={this.change}
              onSave={this.save}
              onOwner={this.showOwnerModal}
              {...medallion}
            />
          </Column>
          <Column size={1 / 3} m={1 / 2} margin>
            <PocEdit
              loading={loading}
              errors={errors}
              hasPermission={updatePermission()}
              onChange={this.change}
              onSave={this.save}
              onOwner={this.showOwnerModal}
              {...medallion}
            />
            {/* <Row margin>
              <Column>
                <Poc1Container
                  medallionOwner={rawMedallion.owner}
                  onUpdate={this.ownerChanged}
                />
              </Column>
            </Row>
            <Row>
              <Column>
                <Poc2Container
                  medallionOwner={rawMedallion.owner}
                  onUpdate={this.ownerChanged}
                />
              </Column>
            </Row> */}
          </Column>
          {/* <Column size={1 / 3} m={1 / 2} margin>
            <MedallionLeaseContainer
              loading={loading}
              errors={errors}
              medallion={rawMedallion}
              hasPermission={updatePermission()}
              onChange={this.change}
              onSave={this.save}
              onAttributeRefresh={this.refreshAttributes}
              {...medallion}
            />
          </Column> */}
        </Row>
        <SelectMedallionOwnerContainer
          visible={selectOwnerModalVisible}
          exclude={!!medallion ? [fkOrId(medallion.owner)] : []}
          onSelect={this.saveOwner}
          onClose={this.hideOwnerModal}
        />
      </SubPage>
    );
  }
}

export default connect((state) => ({
  medallion: state.medallion.medallion,
  save: state.medallionInfo.save,
}))(MedallionInfoContainer);
