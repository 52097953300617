import React from 'react';
import PropTypes from 'prop-types';

// Components
import Container from './components/Container';
import Content from './components/Content';

const TwoTicketColumnLayout = ({left, right}) => (
  <Container>
    <Content>{left}</Content>
    <Content border>{right}</Content>
  </Container>
);

TwoTicketColumnLayout.propTypes = {
  left: PropTypes.node,
  right: PropTypes.node,
};

export default TwoTicketColumnLayout;
