import {date, fileName, folder} from '../../../document';

// Attributes
import vinLastSevenAttr from '../../attributes/vinLastSeven.attribute.vehicle';

// Lib
import vinValidation from '../../libs/vinValidation.lib.vehicle';

// Errors
import vinMissmatch from '../../errors/vinMissmatch.error.vehicle';

export default folder({
  attributes: [
    vinLastSevenAttr,
  ],
  fileAttributes: ['vinLastSeven'],
  defaultFileName: 'FH-1',
  // FH1 - VIN - POLICY NUMBER - UPLOAD DATE
  fileName: fileName(
    ({vehicle}) =>
      `FH1 - ${
        vehicle.vin
      } - ${date()}`
  ),
  folderName: 'FH-1',
  preSaveValidation: ({vehicle, vinLastSeven}) => {
    if (vinValidation(vehicle, vinLastSeven.api.format()))
      throw vinMissmatch({
        message: 'Last 7 VIN characters are incorrect',
      });
  },
  initAttributes: ({attributes, vehicle}) =>
    [...attributes].map((attr) =>
      attr.attribute === 'vinLastSeven'
        ? attr('')
        : attr(vehicle[attr.attribute])
    ),
  type: 'fh1',
});
