import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {Card, Table, Row, Column, Text, Button, Tag} from '@matthahn/sally-ui';
import TripPaymentType from '../../../trip/components/TripPaymentType/TripPaymentType';
import TripVoided from '../../../trip/components/TripVoided/TripVoided';
import EntityContainer from './components/EntityContainer';
import EntityContent from './components/EntityContent';
import EntityText from './components/EntityText';

// Columns
import tripColumns from './tripColumns';
import tollColumns from './tollColumns';

// Lib
import orderByDate from '../../../lib/orderByDate';

// States
import activeState from '../../state/active.state.rental';

// Types
import {amount, dateTime} from '../../../types';

const Rental = ({
  rental,
  trips,
  tolls,
  driver,
  medallion,
  vehicle,
  onDriver,
  onMedallion,
  onVehicle,
  onTrip,
  onToll,
  onNewRentalRate,
}) => (
  <Fragment>
    <Row margin>
      <Column size={1 / 2}>
        
        <Card
          title="Rental"
          icon="switcher"
          subtitle={
            rental.state === activeState.key ? (
              <Tag theme="green">Active</Tag>
            ) : (
              <Tag theme="grey">Completed</Tag>
            )
          }
          headerActions={rental.state === activeState.key ? ([
            {
              icon: 'plus',
              tooltip: 'New Rate',
              onClick: onNewRentalRate,
            },
          ]) : (
            []
          )}
        >
          {(Content) => (
            <Content>
              <Row margin>
                <Column size={1 / 3}>
                  <Text
                    value={
                      !!rental.created_by && !!rental.created_by.username
                        ? rental.created_by.username
                        : '-'
                    }
                  >
                    Dispatcher
                  </Text>
                </Column>
                <Column size={1 / 3}>
                  <Text value={rental.lease_agreement.contract_type}>Type</Text>
                </Column>
                <Column size={1 / 3}>
                  <Text value={`$${amount(rental.amount).format()}`}>Rate</Text>
                </Column>
              </Row>
              <Row>
                <Column size={1 / 2}>
                  <Text
                    value={
                      !!rental.vehicle_pickup_datetime
                        ? dateTime(rental.vehicle_pickup_datetime).format()
                        : '-'
                    }
                    postIcon="calendar"
                  >
                    Start Time
                  </Text>
                </Column>
                <Column size={1 / 2}>
                  <Text
                    value={
                      !!rental.vehicle_dropoff_datetime
                        ? dateTime(rental.vehicle_dropoff_datetime).format()
                        : '-'
                    }
                    postIcon="calendar"
                  >
                    End Time
                  </Text>
                </Column>
              </Row>
            </Content>
          )}
        </Card>
      </Column>
      <Column size={1 / 2}>
        <Row margin>
          <Column>
            <EntityContainer onClick={onDriver}>
              <Card>
                {(Content) => (
                  <Content noBorder>
                    <EntityContent>
                      <Button icon="user" theme="orange" />
                      <EntityText>
                        {driver.first_name} {driver.last_name}
                      </EntityText>
                    </EntityContent>
                  </Content>
                )}
              </Card>
            </EntityContainer>
          </Column>
        </Row>
        <Row margin>
          <Column>
            <EntityContainer onClick={onMedallion}>
              <Card>
                {(Content) => (
                  <Content noBorder>
                    <EntityContent>
                      <Button icon="staro" theme="orange" />
                      <EntityText>{medallion.medallion_number}</EntityText>
                    </EntityContent>
                  </Content>
                )}
              </Card>
            </EntityContainer>
          </Column>
        </Row>
        <Row>
          <Column>
            <EntityContainer onClick={onVehicle}>
              <Card>
                {(Content) => (
                  <Content noBorder>
                    <EntityContent>
                      <Button icon="car" theme="orange" />
                      <EntityText>
                        {vehicle.plate} - {vehicle.vin}
                      </EntityText>
                    </EntityContent>
                  </Content>
                )}
              </Card>
            </EntityContainer>
          </Column>
        </Row>
      </Column>
    </Row>
    <Row>
      <Column size={1 / 2}>
        <Card title="Trips" icon="earth">
          {(Content) => (
            <Content padding="none">
              <Table
                columns={tripColumns}
                theme="orange"
                infinite
                noBorder
                noRadius
                smallNoResultsLabel
              >
                {(TableRow) =>
                  orderByDate(trips, 'start_datetime').map((trip) => (
                    <TableRow key={trip.id} onClick={onTrip(trip)}>
                      {(TableColumn) => [
                        <TableColumn key="trip">
                          <TripVoided trip={trip}>#{trip.id}</TripVoided>
                        </TableColumn>,
                        <TableColumn key="start_date">
                          <TripVoided trip={trip}>
                            {dateTime(trip.start_datetime).format()}
                          </TripVoided>
                        </TableColumn>,
                        <TableColumn key="end_date">
                          <TripVoided trip={trip}>
                            {dateTime(trip.end_datetime).format()}
                          </TripVoided>
                        </TableColumn>,
                        <TableColumn key="num_service">
                          <TripVoided trip={trip}>
                            {trip.num_service || '-'}
                          </TripVoided>
                        </TableColumn>,
                        <TableColumn key="type">
                          <TripVoided trip={trip}>
                            <TripPaymentType type={trip.type} />
                          </TripVoided>
                        </TableColumn>,
                        <TableColumn key="price">
                          <TripVoided trip={trip}>
                            ${amount(trip.total_amount).format()}
                          </TripVoided>
                        </TableColumn>,
                      ]}
                    </TableRow>
                  ))
                }
              </Table>
            </Content>
          )}
        </Card>
      </Column>
      <Column size={1 / 2}>
        <Card title="Tolls" icon="tago">
          {(Content) => (
            <Content padding="none">
              <Table
                columns={tollColumns}
                theme="orange"
                infinite
                noBorder
                noRadius
                smallNoResultsLabel
              >
                {(TableRow) =>
                  orderByDate(tolls, 'start_datetime').map((toll) => (
                    <TableRow key={toll.id} onClick={onToll(toll)}>
                      {(TableColumn) => [
                        <TableColumn key="transaction_date">
                          {dateTime(toll.transaction_date).format()}
                        </TableColumn>,
                        <TableColumn key="amount">
                          ${amount(toll.amount).format()}
                        </TableColumn>,
                        <TableColumn key="exit_plaza">
                          {toll.exit_plaza}
                        </TableColumn>,
                        <TableColumn key="trip">
                          {!!toll.trip ? `#${toll.trip}` : '-'}
                        </TableColumn>,
                      ]}
                    </TableRow>
                  ))
                }
              </Table>
            </Content>
          )}
        </Card>
      </Column>
    </Row>
  </Fragment>
);

Rental.propTypes = {
  rental: PropTypes.object,
  trips: PropTypes.array,
  tolls: PropTypes.array,
  driver: PropTypes.object,
  medallion: PropTypes.object,
  vehicle: PropTypes.object,
  onDriver: PropTypes.func,
  onMedallion: PropTypes.func,
  onVehicle: PropTypes.func,
  onTrip: PropTypes.func,
  onToll: PropTypes.func,
  onNewRentalRate: PropTypes.func,
};

export default Rental;
