import {SELECT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {string} from '../../types';

export default attribute({
  type: SELECT,
  attribute: 'state',
  display: string,
  input: string,
  api: string,
  label: {
    default: 'State',
    short: 'State',
  },
  options: ['pending_4x4', 'completed_4x4'],
});
