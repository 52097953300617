export default ({type = null} = {}) => {
  switch (type) {
    case 'C':
      return 'CC';
    case '$':
      return 'CASH';
    default:
      return type;
  }
};
