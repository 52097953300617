import React from 'react';

// Containers
import MedallionOwnersLayoutContainer from '../../containers/MedallionOwnersLayoutContainer/MedallionOwnersLayoutContainer';

// Containers
import Page from '../../../layout/containers/Page/Page';

// Page
import PAGE from './page';

const MedallionOwnersPage = () => (
  <Page page={PAGE}>
    <MedallionOwnersLayoutContainer />
  </Page>
);

export default MedallionOwnersPage;
