import {DATE} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {date, apiDateTime} from '../../types';

export default attribute({
  type: DATE,
  attribute: 'due_datetime',
  display: date,
  input: date,
  api: apiDateTime,
  label: {
    default: 'Due Date',
    short: 'DUE DATE: When does this ticket need to be paid?',
  },
  disabledDates: [],
});
