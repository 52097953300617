import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

export default attribute({
  type: TEXT,
  attribute: 'medallion_type',
  label: {
    default: 'Medallion Type',
    short: 'Medallion Type',
  },
});
