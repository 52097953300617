import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

// Events
import event from '../events/updated.event.accident';

export default async (accidentId, accident) => {
  const response = await f3tch(`/accidents/dol/${accidentId}/`)
    .profile(profile())
    .body(accident)
    .patch();
  event.publish(response);
  return response;
};
