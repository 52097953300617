import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

// Api
import connectWithMedallionApi from '../../api/connectWithMedallion.api.vehicle';
import disconnectFromMedallionApi from '../../api/disconnectFromMedallion.api.vehicle';
import listMedallionsApi from '../../../medallion/api/list.api.medallion';
// import getMedallionOpenDataApi from '../../../medallion/api/openData.api.medallion';

// Attributes
import medallionNumberAttr from '../../../medallion/attributes/medallion_number.attribute.medallion';

// Components
import VehicleMedallionCard from '../../components/VehicleMedallionCard/VehicleMedallionCard';
import AttachMedallionModal from '../../components/AttachMedallionModal/AttachMedallionModal';

// Libs
import {lib} from '@matthahn/sally-ui';
import parseError from '../../../error/parseError';
import canDisconnectFromMedallion from '../../libs/canDisconnectFromMedallion.lib.vehicle';

// Prep
import connectMedallionPrep from '../../preparation/connectMedallion.preparation.vehicle';

// States
import archivedState from '../../state/archived.state.vehicle';
import decommissionedState from '../../state/decommissioned.state.vehicle';
import storedMedallionState from '../../../medallion/state/stored.state.medallion';
import unmangedMedallionState from '../../../medallion/state/unmanaged.state.medallion';

// Alert
const {alert, notify} = lib;

class VehicleMedallionContainer extends Component {
  static propTypes = {
    vehicle: PropTypes.object,
  };

  state = {
    loading: false,
    medallion_number: medallionNumberAttr(''),
    connectModalVisible: false,
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  showConnectModal = () => {
    if (this.state.loading) return;
    this.setState({connectModalVisible: true});
  };

  hideConnectModal = () => {
    if (this.state.loading) return;
    this.setState({connectModalVisible: false});
  };

  onChange = (val, key) => {
    if (this.state.loading) return;
    this.setState({[key]: val});
  };

  checkMedallion = async () => {
    const {loading, medallion_number: medallionAttr} = this.state;

    if (loading) return;

    this.setState({loading: true});

    try {
      const {medallion_number} = await connectMedallionPrep({
        medallion_number: medallionAttr,
      });
      const {results: medallions} = await listMedallionsApi({
        medallion_number,
      });
      const medallion = medallions.find(
        (m) => m.medallion_number === medallion_number
      );
      if (!medallion) throw new Error('Medallion does not exist');
      if (medallion.state === storedMedallionState.key || medallion.state === unmangedMedallionState.key)
        throw new Error('Medallion stored/unmanaged');
      if (!!medallion.vehicle)
        throw new Error(
          `Medallion is already connected to ${
            medallion.vehicle.plate || medallion.vehicle.vin
          }`
        );
      // TODO: Uncomment this after open data api is ready
      // const openData = await getMedallionOpenDataApi(medallion.id);
      // if (false) {
      //   this.setState({loading: false});
      //   notify({
      //     id: 'confirmWeirdConnection',
      //     title: 'Open Data Missmatch',
      //     icon: undefined,
      //     content:
      //       'This medallion seems to be connected with another vehicle. Do you want to continue?',
      //     primary: {
      //       label: 'No',
      //       onClick: () => null,
      //     },
      //     secondary: {
      //       label: 'Yes',
      //       onClick: () => this.connect(medallion.id),
      //     },
      //     closable: false,
      //     closeOnOutsideClick: true,
      //   });
      // } else {
      //   this.connect(medallion.id);
      // }
      this.connect(medallion.id);
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({loading: false});
    }
  };

  connect = async (medallionID) => {
    const {vehicle} = this.props;

    this.setState({loading: true});

    try {
      await connectWithMedallionApi(vehicle.id, medallionID);
      alert.success('Medallion connected');
      this.setState({loading: false, connectModalVisible: false});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({loading: false});
    }
  };

  disconnect = async (prompt = true) => {
    const {vehicle} = this.props;
    const {loading} = this.state;

    if (loading || !vehicle || !vehicle.medallion) return;

    if (prompt)
      return notify({
        id: 'disconnectMedallion',
        title: 'Disconnect Medallion',
        icon: undefined,
        content:
          'Are you sure you want to remove the medallion from this vehicle?',
        primary: {
          label: 'No',
          onClick: () => null,
        },
        secondary: {
          label: 'Yes',
          onClick: () => this.disconnect(false),
        },
        closable: false,
        closeOnOutsideClick: true,
      });

    this.setState({loading: true});

    try {
      await disconnectFromMedallionApi(vehicle.id, vehicle.medallion.id);
      alert.success('Medallion removed');
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
    }

    if (!this.mounted) return;
    this.setState({loading: false});
  };

  render() {
    const {vehicle} = this.props;
    const {loading, medallion_number, connectModalVisible} = this.state;
    return (
      <Fragment>
        <VehicleMedallionCard
          loading={loading}
          canDisconnect={canDisconnectFromMedallion(vehicle)}
          display={
            ![archivedState.key, decommissionedState.key].includes(
              vehicle.state
            )
          }
          vehicle={vehicle}
          onConnect={this.showConnectModal}
          onDisconnect={this.disconnect}
        />
        <AttachMedallionModal
          loading={loading}
          visible={connectModalVisible}
          medallion_number={medallion_number}
          onClose={this.hideConnectModal}
          onSave={this.checkMedallion}
          onChange={this.onChange}
        />
      </Fragment>
    );
  }
}

export default VehicleMedallionContainer;
