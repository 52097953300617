// Attributes
import amountType from '../attributes/amount.attribute.payout';

export default (payout) =>
  Object.entries(payout)
    .filter(
      ([key]) =>
        key.endsWith('_amount') || ['id', 'payment_method'].includes(key)
    )
    .reduce(
      (combined, [key, value]) => ({
        ...combined,
        [key]: key.endsWith('_amount')
          ? amountType(value).api.format() || 0
          : value,
      }),
      {}
    );
