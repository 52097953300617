import React from 'react';
import PropTypes from 'prop-types';

// Attributes
import phoneNumberAttr from '../../attributes/phone_number.attribute.medallionOwner';
import phoneNumber2Attr from '../../attributes/phone_number2.attribute.medallionOwner';
import emailAttr from '../../attributes/email.attribute.medallionOwner';

// Components
import {Card, Row, Column} from '@matthahn/sally-ui';
import AttributeText from '../../../layout/components/AttributeText/AttributeText';

const ContactInfo = ({phone_number, phone_number2, email, onEdit}) => (
  <Card
    title="Contact"
    icon="phone"
    headerActions={[
      {
        icon: 'edit',
        onClick: onEdit,
        tooltip: 'Edit',
      },
    ]}
  >
    {(Content) => (
      <Content>
        <Row margin>
          <Column size={1 / 2}>
            <AttributeText
              attribute={phoneNumberAttr(phone_number)}
              preIcon="phone"
            >
              {phoneNumberAttr.label.default}
            </AttributeText>
          </Column>
          <Column size={1 / 2}>
            <AttributeText attribute={phoneNumber2Attr(phone_number2)}>
              {phoneNumber2Attr.label.default}
            </AttributeText>
          </Column>
        </Row>
        <Row>
          <Column>
            <AttributeText attribute={emailAttr(email)} preIcon="mail">
              {emailAttr.label.default}
            </AttributeText>
          </Column>
        </Row>
      </Content>
    )}
  </Card>
);

ContactInfo.propTypes = {
  phone_number: PropTypes.string,
  phone_number2: PropTypes.string,
  email: PropTypes.string,
  onEdit: PropTypes.func,
};

export default ContactInfo;
