import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Card, Form, Row, Column} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

const DriverAddressCard = ({
  loading,
  onSave,
  onChange,
  errors,

  address_street1,
  address_street2,
  address_city,
  address_zip,
  address_state,
}) => (
  <Card title="Address" icon="home">
    {(Content) => (
      <Form onSubmit={onSave} enter>
        <Content>
          <Row margin>
            <Column size={1 / 2}>
              <AttributeInput
                value={address_street1}
                onChange={onChange}
                errors={errors}
                disabled={loading}
              >
                {address_street1.label.default}
              </AttributeInput>
            </Column>
            <Column size={1 / 2}>
              <AttributeInput
                value={address_street2}
                onChange={onChange}
                errors={errors}
                disabled={loading}
              >
                {address_street2.label.default}
              </AttributeInput>
            </Column>
          </Row>
          <Row margin>
            <Column>
              <AttributeInput
                value={address_city}
                onChange={onChange}
                errors={errors}
                disabled={loading}
              >
                {address_city.label.default}
              </AttributeInput>
            </Column>
          </Row>
          <Row margin>
            <Column>
              <AttributeInput
                value={address_zip}
                onChange={onChange}
                errors={errors}
                disabled={loading}
              >
                {address_zip.label.default}
              </AttributeInput>
            </Column>
          </Row>
          <Row>
            <Column>
              <AttributeInput
                value={address_state}
                onChange={onChange}
                errors={errors}
                disabled={loading}
                notFilterable
              >
                {address_state.label.short}
              </AttributeInput>
            </Column>
          </Row>
        </Content>
      </Form>
    )}
  </Card>
);

DriverAddressCard.propTypes = {
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  errors: PropTypes.array,
  address_street1: PropTypes.object,
  address_street2: PropTypes.object,
  address_city: PropTypes.object,
  address_zip: PropTypes.object,
  address_state: PropTypes.object,
};

export default DriverAddressCard;
