import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {v4} from 'uuid';
import {withRouter, Switch, Route} from 'react-router-dom';
import {connect} from 'react-redux';

// Api
import api from '../../../api/lib/getEverythingFromApi.lib.api';
import getDriverByIDApi from '../../api/getByID.api.driver';
import listNotesApi from '../../../driverNote/api/list.api.driverNote';
import listRentalsApi from '../../../rental/api/list.api.rental';
import getMedallionByIDApi from '../../../medallion/api/getByID.api.medallion';
import listTicketsApi from '../../../ticket/api/list.api.ticket';
import listLeasesApi from '../../../lease/api/list.api.lease';

// Attributes
import firstNameAttr from '../../attributes/first_name.attribute.driver';
import emailAttr from '../../attributes/email.attribute.driver';
import phoneAttr from '../../attributes/phone_number.attribute.driver';

// Actions
import {set as setAct} from '../../redux/actions';

// Components
import DriverLoader from '../../components/DriverLoader/DriverLoader';
import DriverPageLayout from '../../components/DriverPageLayout/DriverPageLayout';

// contract api
import listCsrsApi from '../../../contract/api/listCsrs.api.contract';

// contract events
import csrSignedEvent from '../../../contract/events/changeOrderSigned.event.changeOrder';

// driverAlert api
import listDriverAlertsApi from '../../../driverAlert/api/list.api.driverAlert';

// driverAlert lib
import showDriverAlertCreateModal from '../../../driverAlert/lib/showCreateModal.lib.driverAlert';

// Events
import driverListedEvt from '../../events/listed.event.driver';
import blacklistedEvt from '../../events/blacklisted.event.driver';
import enabledEvt from '../../events/enabled.event.driver';
import updatedRentalRateEvt from '../../../rental/events/updatedRentalRate.event.rental';

// Libs
import {lib} from '@matthahn/sally-ui';
import fkOrId from '../../../lib/fkOrId';
import eventRace from '../../../events/race.event';

// State
import activeState from '../../state/active.state.driver';
import activeRentalState from '../../../rental/state/active.state.rental';
import driverState from '../../state';

// Sockets
import updatedSocket from '../../socket/updated.socket.driver';
import balanceUpdatedSocket from '../../socket/balanceUpdated.socket.driver';
import dispatchedSocket from '../../../rental/sockets/dispatched.socket.rental';
import payoutSocket from '../../../rental/sockets/payout.socket.rental';
import rentalEndedSocket from '../../../rental/sockets/ended.socket.rental';
import tripCreatedSocket from '../../../trip/sockets/created.socket.trip';
import tripUpdatedSocket from '../../../trip/sockets/updated.socket.trip';
import tripDeletedSocket from '../../../trip/sockets/deleted.socket.trip';

// Route
import driversListRoute from '../../pages/DriversListPage/route';

// Tickets
import waitingTicketStatus from '../../../ticket/statuses/waiting.status.ticket';

// DriverAccidents
import DriverAccidentsPage from '../../pages/DriverAccidentsPage/DriverAccidentsPage';
import routeDriverAccidents from '../../pages/DriverAccidentsPage/route';

// DriverInfoSave
import DriverInfoSavePage from '../../pages/DriverInfoSavePage/DriverInfoSavePage';
import routeDriverInfoSave from '../../pages/DriverInfoSavePage/route';

// DriverPaymentsSubPage
import DriverPaymentsSubPage from '../../pages/DriverPaymentsSubPage/DriverPaymentsSubPage';
import routeDriverPaymentsSubPage from '../../pages/DriverPaymentsSubPage/route';

// DriverPayments
import DriverPaymentsPage from '../../pages/DriverPaymentsPage/DriverPaymentsPage';
import routeDriverPayments from '../../pages/DriverPaymentsPage/route';

// DriverPayouts
import DriverPayoutsPage from '../../pages/DriverPayoutsPage/DriverPayoutsPage';
import routeDriverPayouts from '../../pages/DriverPayoutsPage/route';

// DriverInfo
import DriverInfoPage from '../../pages/DriverInfoPage/DriverInfoPage';
import routeDriverInfo from '../../pages/DriverInfoPage/route';

// DriverRentals
import DriverRentalsPage from '../../pages/DriverRentalsPage/DriverRentalsPage';
import routeDriverRentals from '../../pages/DriverRentalsPage/route';

// DriverDocuments
import DriverDocumentsPage from '../../pages/DriverDocumentsPage/DriverDocumentsPage';
import routeDriverDocuments from '../../pages/DriverDocumentsPage/route';

// DriverTickets
import DriverTicketsPage from '../../pages/DriverTicketsPage/DriverTicketsPage';
import routeDriverTickets from '../../pages/DriverTicketsPage/route';

// DriverNotes
import DriverNotesPage from '../../pages/DriverNotesPage/DriverNotesPage';
import routeDriverNotes from '../../pages/DriverNotesPage/route';

// DriverRedirect
import DriverRedirectPage from '../../pages/DriverRedirectPage/DriverRedirectPage';
import routeDriverRedirect from '../../pages/DriverRedirectPage/route';

// driver pages
import DriverEventsPage from '../../pages/DriverEventsPage/DriverEventsPage';

// driver routes
import routeDriverEvents from '../../pages/DriverEventsPage/route';

// driverAlert lib
import isPayoutAlert from '../../../driverAlert/lib/isPayoutAlert.lib.driverAlert';

// Alert
const {alert} = lib;

class DriverContainer extends Component {
  static propTypes = {
    id: PropTypes.string,
    dispatch: PropTypes.func,
    loading: PropTypes.bool,
    history: PropTypes.object,
    location: PropTypes.object,
    loadingDriver: PropTypes.bool,
    driver: PropTypes.object,
    loadingDriverAlerts: PropTypes.bool,
    driverAlerts: PropTypes.array,
    loadingNotes: PropTypes.bool,
    loadingLease: PropTypes.bool,
    notes: PropTypes.array,
    loadingActiveRental: PropTypes.bool,
    activeRental: PropTypes.object,
    waitingTickets: PropTypes.array,
  };

  componentDidMount() {
    this.init(this.props.id);
    this.events = [
      driverListedEvt.sub(this.onDriverListed),
      csrSignedEvent.subscribe(this.csrSigned),
    ];
    this.subscribe();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) this.init(this.props.id);
  }

  componentWillUnmount() {
    this.mounted = false;
    this.events.forEach((fn) => fn());
    this.unsubscribe();
  }

  mounted = true;
  apiID = v4();

  subscribe = () => {
    this.subscriptions = [
      blacklistedEvt.sub(this.remoteUpdate),
      enabledEvt.sub(this.remoteUpdate),

      updatedSocket.subscribe(this.remoteUpdate),
      updatedSocket.subscribe(this.setNewDriver),
      balanceUpdatedSocket.subscribe(this.onBalanceUpdate),
      payoutSocket.subscribe(this.onPayout),
      tripCreatedSocket.subscribe(this.onTripUpdate),
      tripUpdatedSocket.subscribe(this.onTripUpdate),
      tripDeletedSocket.subscribe(this.onTripUpdate),
      dispatchedSocket.subscribe(this.onDriverDispatch),
      rentalEndedSocket.subscribe(this.onRentalEnd),
      updatedRentalRateEvt.sub(this.rentalRateUpdated),
    ];
  };

  unsubscribe = () => {
    this.subscriptions.forEach((unsubscribe) => unsubscribe());
    this.subscriptions = [];
  };

  init = (id) => {
    this.apiID = v4();
    this.getDriver(id);
    this.getLease(id);
    this.getNotes(id);
    this.getDriverAlerts(id);
    this.getWaitingTickets(id);
    this.getLatestCsr(id);
  };

  remoteUpdate = eventRace((newDriver) => {
    const {driver, dispatch} = this.props;
    if (
      !driver ||
      driver.id !== newDriver.id ||
      driver.state === newDriver.state
    )
      return;
    dispatch(setAct({driver: {...driver, state: newDriver.state}}));
  });

  rentalRateUpdated = (rentalRate) => {
    const {loadingActiveRental, activeRental, dispatch} = this.props;
    if (loadingActiveRental || activeRental?.id !== rentalRate.rental) return;
    dispatch(
      setAct({activeRental: {...activeRental, amount: rentalRate.amount}})
    );
  };

  onPayout = ({payout} = {}) => {
    if (!this.props.driver || this.props.driver.id !== fkOrId(payout.driver))
      return;
    this.refreshDriver({id: this.props.id});
    this.removeAlertsAfterPayout({payout});
  };

  onBalanceUpdate = (driver) => {
    if (!driver || !driver.driver_id) return;
    this.refreshDriver({id: driver.driver_id});
  };

  onTripUpdate = (trip) => {
    const {activeRental} = this.props;
    if (
      !activeRental ||
      !trip ||
      !trip.rental ||
      fkOrId(trip.rental) !== activeRental.id
    )
      return;
  };

  setNewDriver = (driver) => {
    const {dispatch} = this.props;
    if (!this.props.driver || this.props.driver.id !== driver.id) return;
    dispatch(setAct({driver}));
  };

  refreshDriver = async () => {
    const {dispatch, driver} = this.props;
    const newDriver = await this.getNewDriver(driver.id);
    if (!newDriver) return;
    dispatch(setAct({driver: newDriver}));
  };

  onDriverDispatch = async (rental) => {
    const {driver, dispatch} = this.props;
    if (!driver || driver.id !== fkOrId(rental.driver)) return;
    const newDriver = await this.getNewDriver(driver.id);
    dispatch(setAct({activeRental: rental, driver: newDriver || driver}));
  };

  onRentalEnd = async (rental) => {
    const {driver, dispatch} = this.props;
    if (!driver || driver.id !== fkOrId(rental.driver)) return;
    const newDriver = await this.getNewDriver(driver.id);
    dispatch(
      setAct({
        activeRental: null,
        driver: newDriver || driver,
      })
    );
  };

  onDriverListed = (...drivers) => {
    if (!this.props.driver) return;
    const driver = [...drivers].find(({id}) => id === this.props.driver.id);
    if (!driver || !driver.accessed_at) return;
    this.props.dispatch(setAct({driver}));
  };

  stop = (apiID) => this.apiID !== apiID || !this.mounted;

  getNewDriver = async (driverID) => {
    try {
      const driver = await getDriverByIDApi(driverID);
      return driver;
    } catch (error) {
      return null;
    }
  };

  getDriver = async (id) => {
    const {dispatch} = this.props;
    const apiID = this.apiID;

    dispatch(setAct({loadingDriver: true}));

    try {
      const driver = await getDriverByIDApi(id);
      if (this.stop(apiID)) return;
      await Promise.all([this.getActiveRental(driver)]);
      dispatch(setAct({loadingDriver: false, driver}));
    } catch (error) {
      if (this.stop(apiID)) return;
      alert.warning('This driver does not exist');
      this.props.history.replace(driversListRoute());
    }
  };

  getDriverAlerts = async (driverId) => {
    const {dispatch} = this.props;
    const apiID = this.apiID;

    dispatch(setAct({loadingDriverAlerts: true}));

    try {
      const {results: driverAlerts} = await listDriverAlertsApi(driverId, {
        resolved: false,
      });
      if (this.stop(apiID)) return;
      dispatch(setAct({loadingDriverAlerts: false, driverAlerts}));
    } catch (error) {
      if (this.stop(apiID)) return;
      dispatch(setAct({loadingDriverAlerts: false}));
    }
  };

  getActiveRental = async (driver) => {
    const {dispatch} = this.props;
    const apiID = this.apiID;
    if (driver.state !== activeState.key)
      return dispatch(setAct({activeRental: null}));

    dispatch(setAct({loadingActiveRental: true}));

    try {
      const {results: rentals} = await listRentalsApi({
        driver: driver.id,
        state: activeRentalState.key,
      });
      const rental = [...rentals].find(
        ({driver: rentalDriver}) => driver.id === fkOrId(rentalDriver)
      );
      if (this.stop(apiID)) return;
      if (!rental) return dispatch(setAct({loadingActiveRental: false}));
      const medallion = await getMedallionByIDApi(fkOrId(rental.medallion));
      if (this.stop(apiID)) return;
      rental.medallion = medallion;
      dispatch(setAct({loadingActiveRental: false, activeRental: rental}));
    } catch (error) {
      if (this.stop(apiID)) return;
      dispatch(setAct({loadingActiveRental: false}));
    }
  };

  getNotes = async (driver) => {
    const {dispatch} = this.props;
    const apiID = this.apiID;

    dispatch(setAct({loadingNotes: true}));

    try {
      const {results: notes} = await listNotesApi(driver, {
        ordering: '-date_created',
      });
      if (this.stop(apiID)) return;
      dispatch(setAct({loadingNotes: false, notes}));
    } catch (error) {
      if (this.stop(apiID)) return;
      dispatch(setAct({loadingNotes: false}));
    }
  };

  getLease = async (driver) => {
    const {dispatch} = this.props;
    const apiID = this.apiID;

    dispatch(setAct({loadingLease: true}));

    try {
      const {results} = await listLeasesApi({
        driver,
        ordering: '-created_at',
      });
      if (this.stop(apiID)) return;
      const lease =
        [...results].find((l) => `${l.driver}` === `${driver}`) || null;
      dispatch(setAct({loadingLease: false, lease}));
    } catch (error) {
      if (this.stop(apiID)) return;
      dispatch(setAct({loadingLease: false}));
    }
  };

  getWaitingTickets = async (driver) => {
    const {dispatch} = this.props;
    const apiID = this.apiID;

    dispatch(setAct({waitingTickets: []}));

    try {
      const {data: waitingTickets} = await api(listTicketsApi, {
        rental__driver: driver,
        removed: false,
        status: waitingTicketStatus.key,
      });
      if (this.stop(apiID)) return;
      dispatch(setAct({waitingTickets}));
    } catch (error) {
      if (this.stop(apiID)) return;
      dispatch(setAct({waitingTickets: []}));
    }
  };

  csrSigned = ({signatureRequest}) => {
    const {driver} = this.props;
    if (driver.id !== fkOrId(signatureRequest.driver)) return;
    this.getLatestCsr(driver.id);
  };

  getLatestCsr = async (driverId) => {
    const {dispatch} = this.props;
    const apiID = this.apiID;

    dispatch(setAct({loadingCsr: true, csr: null}));

    try {
      const {results: csrs} = await listCsrsApi({
        signed_contract__isnull: true,
        driver: driverId,
        limit: 1,
        offset: 0,
        ordering: 'created_at',
      });
      if (this.stop(apiID)) return;
      const csr = csrs?.[0] || null;
      dispatch(setAct({loadingCsr: false, csr}));
    } catch (error) {
      if (this.stop(apiID)) return;
      dispatch(setAct({loadingCsr: false, csr: null}));
    }
  };

  removeAlertsAfterPayout = ({payout}) => {
    const driverAlerts = [...this.props.driverAlerts].filter(
      (alert) =>
        !isPayoutAlert(alert) || fkOrId(alert.driver) !== fkOrId(payout.driver)
    );
    this.props.dispatch(setAct({driverAlerts}));
  };

  tabs = () => {
    const {
      driver: {id},
      notes,
      waitingTickets,
    } = this.props;
    return [
      {
        id: routeDriverPayments(id),
        label: 'Payments',
      },
      {
        id: routeDriverPayouts(id),
        label: 'Payouts',
      },
      {
        id: routeDriverInfo(id),
        label: 'Info',
      },
      {
        id: routeDriverEvents(id),
        label: 'Events',
      },
      {
        id: routeDriverRentals(id),
        label: 'Rentals',
        selected: this.props.location.pathname.startsWith(
          routeDriverRentals(id)
        ),
      },
      {
        id: routeDriverDocuments(id),
        label: 'Documents',
      },
      {
        id: routeDriverNotes(id),
        label: 'Notes',
        notifications: notes.length,
      },
      {
        id: routeDriverTickets(id),
        label: 'Tickets',
        notifications: waitingTickets.length,
      },
      {
        id: routeDriverAccidents(id),
        label: 'Accidents',
      },
    ];
  };

  onTab = (route) => this.props.history.push(route);

  showCreateAlertModal = () => {
    const {driver} = this.props;
    showDriverAlertCreateModal({driver});
  };

  addon = () => (
    <Switch>
      <Route
        exact
        path={routeDriverPaymentsSubPage()}
        component={DriverPaymentsSubPage}
      />
      <Route
        exact
        path={routeDriverInfoSave()}
        component={DriverInfoSavePage}
      />
    </Switch>
  );

  render() {
    const {
      id,
      loadingDriver,
      loadingLease,
      loadingNotes,
      loadingDriverAlerts,
      loadingActiveRental,
      activeRental,
      driver,
      location: {pathname},
    } = this.props;
    return loadingDriver ||
      loadingLease ||
      loadingNotes ||
      loadingDriverAlerts ||
      loadingActiveRental ||
      !driver ||
      (!!driver && `${driver.id}` !== id) ? (
      <DriverLoader />
    ) : (
      <DriverPageLayout
        name={firstNameAttr(
          `${driver.first_name} ${driver.last_name}`
        ).display.format()}
        email={emailAttr(driver.email).display.format()}
        phone_number={phoneAttr(driver.phone_number).display.format()}
        state={driverState(driver.state)}
        loadingActiveRental={loadingActiveRental}
        activeRental={activeRental}
        addon={this.addon()}
        driver={driver}
        tab={pathname}
        tabs={this.tabs()}
        onTab={this.onTab}
        onCreateAlert={this.showCreateAlertModal}
      >
        <Switch>
          <Route
            exact
            path={routeDriverPayments()}
            component={DriverPaymentsPage}
          />
          <Route
            exact
            path={routeDriverPayouts()}
            component={DriverPayoutsPage}
          />
          <Route exact path={routeDriverInfo()} component={DriverInfoPage} />
          <Route path={routeDriverEvents()} component={DriverEventsPage} />
          <Route path={routeDriverRentals()} component={DriverRentalsPage} />
          <Route
            exact
            path={routeDriverDocuments()}
            component={DriverDocumentsPage}
          />
          <Route exact path={routeDriverNotes()} component={DriverNotesPage} />
          <Route
            exact
            path={routeDriverAccidents()}
            component={DriverAccidentsPage}
          />
          <Route
            exact
            path={routeDriverTickets()}
            component={DriverTicketsPage}
          />
          <Route path={routeDriverRedirect()} component={DriverRedirectPage} />
        </Switch>
      </DriverPageLayout>
    );
  }
}

export default withRouter(
  connect((state) => ({
    ...state.driver,
  }))(DriverContainer)
);
