import styled from 'styled-components';

const SearchContainer = styled.div`
  width: 100%;
  height: 56px;
  position: absolute;
  top: 0px;
  left: 0px;
`;

export default SearchContainer;
