import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Button, Container, Row, Column, Table} from '@matthahn/sally-ui';
import NegativeParenthesis from '../../../layout/components/NegativeParenthesis/NegativeParenthesis';
import PageContainer from './components/Container';
import Content from './components/Content';

// Columns
import columns from './columns';

// Types
import {dateTime} from '../../../types';

const DriverPayoutsTable = ({
  loading,
  payouts,
  emailing,
  downloading,
  printing,
  page,
  pages,
  onPage,
  onReport,
  onScreenshot,
  onPrintCheck,
  onEmailStatement,
}) => (
  <PageContainer>
    <Content>
      <Container>
        <Row margin>
          <Column>
            <Table
              columns={columns}
              infinite={false}
              page={page}
              pages={pages}
              onPage={onPage}
              loading={loading}
              theme="orange"
            >
              {(TableRow) =>
                payouts.map((payout) => (
                  <TableRow key={payout.id}>
                    {(TableCol) => [
                      <TableCol key="date">
                        {dateTime(payout.payout_date).format()}
                      </TableCol>,
                      <TableCol key="payment_method">
                        {payout.payment_method === 'direct_deposit'
                          ? `Direct deposit to ${
                              payout?.direct_deposit?.funding_source
                                ?.funding_name || '-'
                            }`
                          : payout.payment_method || payout.payment_method_from_driver || '-'}
                      </TableCol>,
                      <TableCol key="total">
                        <NegativeParenthesis
                          value={payout.earnings_amount}
                          wrap={false}
                          empty={'-'}
                        />
                      </TableCol>,
                      <TableCol key="status">
                        {payout?.direct_deposit?.dwolla_status || 'completed'}
                      </TableCol>,
                      <TableCol key="dispatcher">
                        {!!payout.payout_by ? payout.payout_by.username : '-'}
                      </TableCol>,
                      <TableCol key="screenshot">
                        {!!payout.screenshot && (
                          <Button
                            theme="grey"
                            icon="picture"
                            size="small"
                            onClick={onScreenshot(payout.screenshot)}
                          >
                            Screenshot
                          </Button>
                        )}
                      </TableCol>,
                      <TableCol key="report">
                        <Button
                          theme="grey"
                          icon="download"
                          size="small"
                          loading={downloading.includes(payout.id)}
                          onClick={onReport(payout)}
                        >
                          Report
                        </Button>
                      </TableCol>,
                      <TableCol key="print">
                        {!!payout.direct_deposit && (
                          <Button
                            theme="grey"
                            icon="file-markdown"
                            size="small"
                            loading={emailing.includes(payout.id)}
                            onClick={onEmailStatement(payout, payout.driver.email)}
                          >
                            Email Statement
                          </Button>
                        )}

                        {!!payout.approved_check && (
                          <Button
                            theme="grey"
                            icon="download"
                            size="small"
                            loading={printing.includes(payout.id)}
                            onClick={onPrintCheck(payout)}
                          >
                            Print Check
                          </Button>
                        )}
                      </TableCol>,
                    ]}
                  </TableRow>
                ))
              }
            </Table>
          </Column>
        </Row>
      </Container>
    </Content>
  </PageContainer>
);

DriverPayoutsTable.propTypes = {
  loading: PropTypes.bool,
  payouts: PropTypes.array,
  downloading: PropTypes.array,
  printing: PropTypes.array,
  emailing: PropTypes.array,
  results: PropTypes.number,
  page: PropTypes.number,
  pages: PropTypes.number,
  onPage: PropTypes.func,
  onReport: PropTypes.func,
  onScreenshot: PropTypes.func,
  onPrintCheck: PropTypes.func,
  onEmailStatement: PropTypes.func,
};

export default DriverPayoutsTable;
