import {date, fileName, folder} from '../../../document';

// Attributes
import vinLastSevenAttr from '../../attributes/vinLastSeven.attribute.vehicle';
import purchaseDate from '../../attributes/purchase_date.attribute.vehicle';
import purchasePrice from '../../attributes/purchase_price.attribute.vehicle';

// Lib
import vinValidation from '../../libs/vinValidation.lib.vehicle';

// Errors
import vinMissmatch from '../../errors/vinMissmatch.error.vehicle';

export default folder({
  attributes: [vinLastSevenAttr, purchaseDate, purchasePrice],
  fileAttributes: ['vinLastSeven'],
  defaultFileName: 'Bill Of Sale',
  // BILL OF SALE - VIN - UPLOAD DATE
  fileName: fileName(
    ({vehicle}) => `BILL OF SALE - ${vehicle.vin} - ${date()}`
  ),
  folderName: 'Bill Of Sale',
  preSaveValidation: ({vehicle, vinLastSeven}) => {
    if (vinValidation(vehicle, vinLastSeven.api.format()))
      throw vinMissmatch({
        message: 'Last 7 VIN characters are incorrect',
      });
  },
  type: 'billOfSale',
});
