import {DATE} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {date, apiDate} from '../../types';

export default attribute({
  type: DATE,
  attribute: 'archived_date',
  display: date,
  input: date,
  api: apiDate,
  label: {
    default: 'Archived Date',
    short: 'Archived Date',
  },
  disabledDates: [{after: new Date()}],
});
