import React from 'react';

// Components
import Page from '../../../layout/containers/Page/Page';

// Containers
import AccidentsContainer from '../../containers/AccidentsContainer/AccidentsContainer';

// Info
import page from './page';

const AccidentsPage = () => (
  <Page page={page}>
    <AccidentsContainer />
  </Page>
);

export default AccidentsPage;
