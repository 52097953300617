import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// component lib
import alert from '@matthahn/sally-ui/lib/libs/alert';
import notify from '@matthahn/sally-ui/lib/libs/notify';

// vehicle actions
import {set as setAction} from '../../../vehicle/redux/actions';

// vehicleAlert api
import updateAlertApi from '../../api/update.api.vehicleAlert';

// vehicleAlert components
import VehicleAlerts from '../../components/VehicleAlerts/VehicleAlerts';

// vehicleAlert events
import vehicleAlertCreatedEvent from '../../events/created.event.vehicleAlert';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// lib
import fkOrId from '@matthahn/sally-fw/lib/lib/fkOrId';

class VehicleAlertsContainer extends Component {
  static propTypes = {
    vehicle: PropTypes.object.isRequired,
    vehicleAlerts: PropTypes.array.isRequired,
    user: PropTypes.string.isRequired,
    dispatch: PropTypes.func,
    headless: PropTypes.bool,
    subscribe: PropTypes.func,
    children: PropTypes.func,
  };

  state = {
    resolving: [],
  };

  componentDidMount() {
    this.init();
    this.props.subscribe(vehicleAlertCreatedEvent.subscribe(this.alertCreated));
  }

  init = () => {
    const {vehicle} = this.props;
    if (!vehicle || !vehicle.alerts) return;
    this.setState({alerts: [...vehicle.alerts]});
  };

  alertCreated = (vehicleAlert) => {
    const {vehicle, vehicleAlerts, dispatch} = this.props;
    if (vehicle.id !== fkOrId(vehicleAlert?.vehicle)) return;
    dispatch(setAction({vehicleAlerts: [...vehicleAlerts, vehicleAlert]}));
  };

  execute = (alert) => () => {
    if (alert.isCreditRequest) this.showCreditResolve(alert);
    else this.resolve(alert.id);
  };

  onCreditResolved = (vehicle) => {
    const alerts = [...vehicle.alerts];

    this.props.dispatch(setAction({customer: vehicle}));
    this.setState({alerts, showCreditResolve: false});
  };

  resolve = async (id, prompt = true) => {
    const {vehicle, dispatch} = this.props;
    const {resolving} = this.state;

    if (resolving.includes(id)) return;

    if (prompt)
      return notify({
        id: 'resolveVehicleAlert',
        title: 'Confirm',
        icon: undefined,
        content: 'Are you sure you want to resolve this alert?',
        primary: {
          label: 'No',
          onClick: () => null,
        },
        secondary: {
          label: 'Yes',
          onClick: () => this.resolve(id, false),
        },
        closable: false,
        closeOnOutsideClick: true,
      });

    this.setState({resolving: [...resolving, id]});
    const removeResolving = (alertID) =>
      [...this.state.resolving].filter((aID) => aID !== alertID);

    try {
      await updateAlertApi(vehicle.id, id, {
        resolved: true,
      });

      const vehicleAlerts = [...this.props.vehicleAlerts].filter(
        (a) => a.id !== id
      );

      dispatch(setAction({vehicleAlerts}));
      this.setState({resolving: removeResolving(id)});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({resolving: removeResolving(id)});
    }
  };

  alerts = () => [...this.props.vehicleAlerts];

  render() {
    const {vehicle, headless, children} = this.props;
    const {resolving} = this.state;
    return children({
      dom: !vehicle ? null : (
        <VehicleAlerts
          alerts={this.alerts()}
          headless={headless}
          resolving={resolving}
          onResolve={this.execute}
        />
      ),
    });
  }
}

export default connect((state) => ({
  vehicle: state.vehicle.vehicle,
  vehicleAlerts: state.vehicle.vehicleAlerts,
  user: state.auth.username,
}))(subscriptionHOC(VehicleAlertsContainer));
