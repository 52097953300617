import styled from 'styled-components';

// Colors
import {lib} from '@matthahn/sally-ui';
const {
  colors: {white},
} = lib;

const Search = styled.div`
  width: 100%;
  position: fixed;
  top: 110px;
  left: 0px;
  padding: 20px 0px;
  background: ${white};
  z-index: 10;
`;

export default Search;
