import {isAfter, isSameMonth, isSameYear} from 'date-fns';

// Lib
import toDate from '../../lib/toDate';

export default (vehicle) =>
  !vehicle.emissions_expiry || !vehicle.emissions_issued
    ? {status: 'warning', message: 'State Inspection missing'}
    : isAfter(new Date(), toDate(vehicle.emissions_expiry)) ||
      (isSameYear(new Date(), toDate(vehicle.emissions_expiry)) &&
        isSameMonth(new Date(), toDate(vehicle.emissions_expiry)))
    ? {status: 'error', message: 'Perform state inspection'}
    : {status: 'done', message: 'State Inspection'};
