import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {Button, CheckBox, Table} from '@matthahn/sally-ui';
import StepFooter from '../../../layout/components/StepFooter/StepFooter';
import SmallLoader from '../../../layout/components/SmallLoader/SmallLoader';

// Columns
import columns from './columns';

// Lib
import displayTimeZoneSpecificDate from '../../../lib/displayTimeZoneSpecificDate';

// Types
import {dateTime} from '@matthahn/sally-fw/lib/type';

const SelectRentalStep = ({
  Container,
  loading,
  rental,
  rentals,
  onRental,
  onNext,
  onBack,
}) =>
  loading ? (
    <Container>
      <SmallLoader />
    </Container>
  ) : (
    <Fragment>
      <Container padding="none" noBorder>
        <Table columns={columns} noBorder noRadius smallNoResultsLabel>
          {(TableRow) =>
            [
              ...rentals.map((rentalToDisplay) => (
                <TableRow
                  key={rentalToDisplay.id}
                  onClick={onRental(rentalToDisplay)}
                >
                  {(TableColumn) => [
                    <TableColumn
                      key="vehicle"
                      span={2}
                      bold={rentalToDisplay.state === 'active'}
                    >
                      <CheckBox
                        value={rentalToDisplay.id === rental?.id}
                        onChange={() => null}
                        size="small"
                      />{' '}
                      {rentalToDisplay?.medallion?.medallion_number ||
                        rentalToDisplay?.vehicle?.plate ||
                        '-'}
                    </TableColumn>,
                    <TableColumn
                      key="start"
                      bold={rentalToDisplay.state === 'active'}
                    >
                      {displayTimeZoneSpecificDate({
                        date: rentalToDisplay.vehicle_pickup_datetime,
                        type: dateTime,
                      })}
                    </TableColumn>,
                    <TableColumn
                      key="end"
                      bold={rentalToDisplay.state === 'active'}
                    >
                      {!!rentalToDisplay.vehicle_dropoff_datetime
                        ? displayTimeZoneSpecificDate({
                            date: rentalToDisplay.vehicle_dropoff_datetime,
                            type: dateTime,
                          })
                        : '-'}
                    </TableColumn>,
                  ]}
                </TableRow>
              )),
            ].filter((row) => !!row)
          }
        </Table>
      </Container>
      <Container>
        <StepFooter
          left={
            <Button size="small" theme="grey" onClick={onBack}>
              Back
            </Button>
          }
          right={
            <Button size="small" theme="orange" onClick={onNext}>
              Next
            </Button>
          }
        />
      </Container>
    </Fragment>
  );

SelectRentalStep.propTypes = {
  Container: PropTypes.any,
  loading: PropTypes.bool,
  rental: PropTypes.object,
  rentals: PropTypes.array,
  onRental: PropTypes.func,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
};

export default SelectRentalStep;
