import {DATETIME} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {shortDateTime} from '../../types';

export default attribute({
  type: DATETIME,
  attribute: 'modified_at',
  display: shortDateTime,
  label: {
    default: 'Modified at',
    short: 'Modified',
  },
});
