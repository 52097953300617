import {lib} from '@matthahn/sally-ui';

// Redux
import store from '../../redux/store';
import {
  setUnsavedChanges as set,
  unsetUnsavedChanges as unset,
} from '../redux/actions';

const {notify} = lib;

export const shouldNotify = () => store.getState().layout.unsavedChanges;

export const notifyChange = (fn, cancel = () => null) =>
  notify({
    id: 'unsavedChangesCustomNotification',
    title: 'Unsaved Changes',
    icon: undefined,
    content: 'There are unsaved changes. Are you sure you want to proceed?',
    primary: {
      label: 'No',
      onClick: cancel,
    },
    secondary: {
      label: 'Yes',
      onClick: () => {
        store.dispatch(unset());
        fn();
      },
    },
    closable: false,
    closeOnOutsideClick: true,
  });

export const checkForUnsavedChanges = (fn, ignore = () => false) => (
  ...args
) => {
  if (ignore(...args)) return;

  if (!shouldNotify()) {
    fn(...args);
    return;
  }

  notifyChange(() => fn(...args));
};

export const setUnsavedChanges = (fn, ignore = () => false) => (...args) => {
  if (ignore(...args)) return;
  store.dispatch(set());
  fn(...args);
};

export const clearUnsavedChanges = () => store.dispatch(unset());
