import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {Card, Info, Text} from '@matthahn/sally-ui';
import DocumentsContainer from '../../../document/containers/DocumentsContainer/DocumentsContainer';

import Container from './components/Container';

// Documents
import * as documents from '../../documents';

// Types
import {date} from '../../../types';

const VehicleRegistrationCard = ({status, vehicle, onDone}) => (
  <Card
    title="Registration"
    icon="profile"
    info={(Content) =>
      vehicle.registration_pickup_status !== null &&
      vehicle.registration_pickup_status ? (
        <Fragment>
          <Content padding="none" noBorder>
            {status.status === 'done' ? (
              <Info type="plain" flat>
                Registration is up to date
              </Info>
            ) : (
              <Info type={status.status} flat>
                {status.message}
              </Info>
            )}
          </Content>
          <Content>
            <Text value={date(vehicle.registration_expiry).format() || '-'}>
              Expiration
            </Text>
          </Content>
        </Fragment>
      ) : (
        <Content padding="none" noBorder>
          <Info type={status.status} flat>
            {status.message}
          </Info>
        </Content>
      )
    }
    visible={status.status !== 'done'}
    foldable
  >
    {(Content) => (
      <Content padding="none">
        <Container>
          <DocumentsContainer
            key={status.file}
            type="vehicle"
            vehicle={vehicle}
            folderType={documents.foldersObject[status.file].type}
            predefinedDocs={documents}
            onDone={onDone}
            uploadMode
            clearFileOnBack
            staticSelection
            small
          />
        </Container>
      </Content>
    )}
  </Card>
);

VehicleRegistrationCard.propTypes = {
  status: PropTypes.object,
  vehicle: PropTypes.object,
  onDone: PropTypes.func,
};

export default VehicleRegistrationCard;
