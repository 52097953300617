import styled from 'styled-components';

// Libs
import {lib} from '@matthahn/sally-ui';

// Colors
const {
  media: {media},
} = lib;

const Right = styled.div`
  display: flex;

  ${media.s`
    margin-top: 20px;
  `}
`;

export default Right;
