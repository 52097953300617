import f3tch from 'f3tch';

// Events
import event from '../events/updated.event.insurance';

// Profile
import profile from '../../api/profiles/noData.profile.api';

export default async (id) => {
  const response = await f3tch(`/vehicles/insurance/${id}/`)
    .profile(profile())
    .delete();
  event.publish(id);
  return response;
};
