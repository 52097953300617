import {date, fileName, folder} from '../../../document';

// Attributes
import vinLastSevenAttr from '../../attributes/vinLastSeven.attribute.vehicle';
import insurancePolicyNumberAttr from '../../attributes/insurance_liability_number.attribute.vehicle';
import insuranceBrokerAttr from '../../attributes/insurance_liability_broker.attribute.vehicle';
import insuranceCarrierAttr from '../../attributes/insurance_liability_carrier.attribute.vehicle';
import insuranceRateAttr from '../../attributes/insurance_liability_rate.attribute.vehicle';

// Lib
import vinValidation from '../../libs/vinValidation.lib.vehicle';

// Errors
import vinMissmatch from '../../errors/vinMissmatch.error.vehicle';

export default folder({
  attributes: [
    vinLastSevenAttr,
    insurancePolicyNumberAttr,
    insuranceBrokerAttr,
    insuranceCarrierAttr,
    insuranceRateAttr,
  ],
  fileAttributes: ['vinLastSeven'],
  defaultFileName: 'Dec',
  // DEC - SVID - POLICY NUMBER - EFFECTIVE DATE
  fileName: fileName(
    ({vehicle}) =>
      `DEC - ${vehicle.svid} - ${
        vehicle.insurance_liability_number
      } - ${date()}`
  ),
  folderName: 'DECs',
  preSaveValidation: ({vehicle, vinLastSeven}) => {
    if (vinValidation(vehicle, vinLastSeven.api.format()))
      throw vinMissmatch({
        message: 'Last 7 VIN characters are incorrect',
      });
  },
  initAttributes: ({attributes, vehicle}) =>
    [...attributes].map((attr) =>
      attr.attribute === 'vinLastSeven'
        ? attr('')
        : attr(vehicle[attr.attribute])
    ),
  type: 'dec',
});
