import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal, Row, Column, Form} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

const AttachMedallionModal = ({
  loading,
  visible,
  medallion_number,
  onClose,
  onSave,
  onChange,
}) => (
  <Modal
    title="Connect Medallion"
    icon="staro"
    size="small"
    visible={visible}
    onClose={onClose}
    buttonsRight={[
      {label: 'Connect', theme: 'orange', loading, onClick: onSave},
    ]}
  >
    {(Content) => (
      <Content>
        <Row margin />
        <Row margin>
          <Column>
            <Form onSubmit={onSave} enter>
              <AttributeInput
                value={medallion_number}
                onChange={onChange}
                disabled={loading}
              >
                Medallion
              </AttributeInput>
            </Form>
          </Column>
        </Row>
      </Content>
    )}
  </Modal>
);

AttachMedallionModal.propTypes = {
  loading: PropTypes.bool,
  visible: PropTypes.bool,
  medallion_number: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  onChange: PropTypes.func,
};

export default AttachMedallionModal;
