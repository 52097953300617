// api
import checkPayoutRulesApi from '../api/rules.api.payout';

const checkPayoutRulesService = async ({
  payout,
  performPayout,
  total,
  transactions,
  credit,
}) => {
  if (!performPayout) return {payoutWarningMessage: null};
  const check = [...transactions].reduce(
    (combined, {transactions: subTransactions, ...transaction}) => ({
      ...combined,
      transactions: [
        ...combined.transactions,
        {
          ...transaction,
          payment: payout?.[`${transaction.subtype}_amount`] || 0,
        },
      ],
    }),
    {
      ...['created_by', 'driver', 'earnings_amount', 'payment_method'].reduce(
        (combined, key) => ({...combined, [key]: payout[key]}),
        {total}
      ),
      credit,
      transactions: [],
    }
  );

  const {auto_approved, reason} = await checkPayoutRulesApi(check);
  return {payoutWarningMessage: auto_approved ? null : reason};
};

export default checkPayoutRulesService;
