import React from 'react';
import PropTypes from 'prop-types';

// Columns
import columns from './columns';

// Components
import {
  Container,
  Row,
  Column,
  Table,
  Button,
  InputTyping,
} from '@matthahn/sally-ui';

import ButtonContainer from './components/ButtonContainer';
import Content from './components/Content';
import InputContainer from './components/InputContainer';
import Menu from './components/Menu';
import PageContainer from './components/PageContainer';

// file components
import FileUpload from '../../../file/components/FileUpload/FileUpload';

// Types
import {date} from '../../../types';

const InsurancesList = ({
  insurances,
  loading,
  onInsurance,
  onNewInsurance,
  onPage,
  onSearch,
  onSort,
  onZipUpload,
  page,
  pages,
  search,
  sort,
  uploading,
}) => (
  <PageContainer>
    <Menu>
      <InputContainer>
        <InputTyping
          value={search}
          onChange={onSearch}
          preIcon="search1"
          placeholder="Search for insurance"
          size="large"
          flat
        />
      </InputContainer>
      <FileUpload onFile={onZipUpload}>
        {({onClick}) => (
          <ButtonContainer>
            <Button theme="grey" onClick={onClick} loading={uploading} flat>
              Upload ZIP
            </Button>
          </ButtonContainer>
        )}
      </FileUpload>
      <ButtonContainer>
        <Button icon="plus" theme="orange" onClick={onNewInsurance} flat>
          New Insurance
        </Button>
      </ButtonContainer>
    </Menu>
    <Content>
      <Container>
        <Row margin>
          <Column>
            <Table
              columns={columns}
              infinite={false}
              page={page}
              pages={pages}
              onPage={onPage}
              loading={loading}
              sort={sort}
              onSort={onSort}
              theme="darkGrey"
            >
              {(TableRow) =>
                insurances.map((insurance) => (
                  <TableRow kwt={insurance.id} onClick={onInsurance(insurance)}>
                    {(TableCol) => [
                      <TableCol key="policy_number">
                        {insurance.policy_number}
                      </TableCol>,
                      <TableCol key="insurance_company_name">
                        {insurance.insurance_company_name}
                      </TableCol>,
                      <TableCol key="insurance_company_number">
                        {insurance.insurance_company_number}
                      </TableCol>,
                      <TableCol key="policy_effective_date">
                        {date(insurance.policy_effective_date).format()}
                      </TableCol>,
                      <TableCol key="policy_expiration_date">
                        {date(insurance.policy_expiration_date).format()}
                      </TableCol>,
                    ]}
                  </TableRow>
                ))
              }
            </Table>
          </Column>
        </Row>
      </Container>
    </Content>
  </PageContainer>
);

InsurancesList.propTypes = {
  insurances: PropTypes.array,
  loading: PropTypes.bool,
  onInsurance: PropTypes.func,
  onNewInsurance: PropTypes.func,
  onPage: PropTypes.func,
  onSearch: PropTypes.func,
  onSort: PropTypes.func,
  onZipUpload: PropTypes.func,
  page: PropTypes.number,
  pages: PropTypes.number,
  search: PropTypes.string,
  sort: PropTypes.object,
  uploading: PropTypes.bool,
};

export default InsurancesList;
