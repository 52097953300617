const parseCheque = (cheque) => ({
  ...cheque,
  type: 'cheque',
  id: cheque.check_number,
  computedId: `cheque${cheque.check_number}`,
  search: [
    cheque.id,
    cheque?.driver
      ? `${cheque.driver.first_name} ${cheque.driver.last_name}`
      : null,
    cheque.driver.fhv_license_number || null,
  ]
    .filter((q) => !!q)
    .map((query) => `${query}`.toLowerCase()),
});

export default parseCheque;
