import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// alert components
import AlertInfo from '../../../alert/components/AlertInfo/AlertInfo';

// Components
import {Card, P} from '@matthahn/sally-ui';

import Row from './components/Row';

const VehicleAlerts = ({alerts, headless, resolving, onResolve}) => {
  const content = (
    <Fragment>
      {alerts.map((alert, index) => (
        <Row key={alert.id} margin={!!index}>
          <AlertInfo
            type={alert.error ? 'error' : 'warning'}
            alert={alert}
            action={
              !alert.isCreditRequest || alert.hasPermission
                ? {
                    label: 'Resolve',
                    onClick: onResolve(alert),
                  }
                : null
            }
            loading={resolving.includes(alert.id)}
            flat
          />
        </Row>
      ))}
    </Fragment>
  );
  return headless ? (
    content
  ) : (
    <Card title="Alerts" icon="exclamation">
      {(Content) =>
        !alerts.length ? (
          <Content>
            <P theme="darkGrey">No alerts</P>
          </Content>
        ) : (
          <Content padding="none">{content}</Content>
        )
      }
    </Card>
  );
};

VehicleAlerts.propTypes = {
  alerts: PropTypes.array,
  resolving: PropTypes.array,
  onCreate: PropTypes.func,
  onResolve: PropTypes.func,
};

export default VehicleAlerts;
