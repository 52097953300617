import React, {Component} from 'react';
import PropTypes from 'prop-types';

// dispatch components
import DispatchAlertsModal from '../../components/DispatchAlertsModal/DispatchAlertsModal';

// driverAlert api
import listDriverAlertsApi from '../../../driverAlert/api/list.api.driverAlert';
import updateDriverAlertApi from '../../../driverAlert/api/update.api.driverAlert';

// driverAlert events
import showDriverAlertCreateModal from '../../../driverAlert/lib/showCreateModal.lib.driverAlert';

// vehicleAlert api
import listVehicleAlertsApi from '../../../vehicleAlert/api/list.api.vehicleAlert';
import updateVehicleAlertApi from '../../../vehicleAlert/api/update.api.vehicleAlert';

// vehicleAlert events
import showVehicleAlertCreateModal from '../../../vehicleAlert/lib/showCreateModal.lib.vehicleAlert';

class DispatchAlertsContainer extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    vehicle: PropTypes.object,
    onClose: PropTypes.func,
  };

  state = {...this.constructor.INITIAL_STATE};

  componentDidMount() {
    if (this.props.visible) this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.visible && this.props.visible) this.init();
  }

  init = async () => {
    const {vehicle} = this.props;
    this.setState({...this.constructor.INITIAL_STATE, loading: true});

    if (!vehicle) return this.setState({loading: false});
  };

  vehicle = () => {
    const {vehicle} = this.props;
    return !!vehicle ? {...vehicle} : null;
  };

  drivers = () => {
    const {vehicle} = this.props;
    return !!vehicle?.active_rentals
      ? [...vehicle.active_rentals].map(({driver}) => driver)
      : [];
  };

  newDriverAlert = (driver) => () => showDriverAlertCreateModal({driver});

  newVehicleAlert = () => {
    const {vehicle} = this.props;
    showVehicleAlertCreateModal({vehicle});
  };

  render() {
    const {onClose, visible} = this.props;
    return (
      <DispatchAlertsModal
        drivers={this.drivers()}
        listDriverAlerts={listDriverAlertsApi}
        listVehicleAlerts={listVehicleAlertsApi}
        onClose={onClose}
        onNewDriverAlert={this.newDriverAlert}
        onNewVehicleAlert={this.newVehicleAlert}
        updateDriverAlert={updateDriverAlertApi}
        updateVehicleAlert={updateVehicleAlertApi}
        vehicle={this.vehicle()}
        visible={visible}
      />
    );
  }
}

export default DispatchAlertsContainer;
