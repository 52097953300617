import React from 'react';
import PropTypes from 'prop-types';

// Attributes
import notesAttr from '../../attributes/notes.attribute.medallionOwner';

// Components
import {Card, Row, Column} from '@matthahn/sally-ui';
import AttributeText from '../../../layout/components/AttributeText/AttributeText';

const BasicInfo = ({notes, onEdit}) => (
  <Card
    title="Notes"
    icon="file1"
    headerActions={[
      {
        icon: 'edit',
        onClick: onEdit,
        tooltip: 'Edit',
      },
    ]}
  >
    {(Content) => (
      <Content>
        <Row>
          <Column>
            <AttributeText attribute={notesAttr(notes)}>
              {notesAttr.label.default}
            </AttributeText>
          </Column>
        </Row>
      </Content>
    )}
  </Card>
);

BasicInfo.propTypes = {
  notes: PropTypes.string,
  onEdit: PropTypes.func,
};

export default BasicInfo;
