import {TOGGLE} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

export default attribute({
  type: TOGGLE,
  attribute: 'tow_required',
  label: {
    default: 'Tow required',
    short: 'Tow required',
  },
});
