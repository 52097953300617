import React from 'react';

// Components
import {Row, Column} from '@matthahn/sally-ui';
import SubPage from '../../../layout/components/SubPage/SubPage';

// Containers
import DriverNotesContainer from '../../../driverNote/containers/DriverNotesContainer/DriverNotesContainer';

const DriverNotesPage = () => (
  <SubPage>
    <Row>
      <Column size={2 / 3} offset={1 / 6}>
        <DriverNotesContainer />
      </Column>
    </Row>
  </SubPage>
);

export default DriverNotesPage;
