export default [
  {
    key: 'number',
    label: 'Number',
  },
  {
    key: 'date',
    label: 'Date',
  },
  {
    key: 'amount',
    label: 'Amount',
  },
  {
    key: 'driver',
    label: 'Driver',
  },
  {
    key: 'medallion',
    label: 'Medallion',
  },
  {
    key: 'approved_by',
    label: 'Approved By',
  },
];
