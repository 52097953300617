import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Card, Row, Column, Form} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

const Poc1Edit = ({
  loading,
  errors,
  poc_name,
  poc_address_street1,
  poc_address_street2,
  poc_address_city,
  poc_address_zip,
  poc_address_state,
  poc_email,
  poc_phone_number,
  poc_notes,
  onChange,
  onSave,
}) => (
  <Card
    title="POC"
    icon="phone"
    buttonsRight={[{label: 'Save', loading, onClick: onSave, theme: 'orange'}]}
  >
    {(Content) => (
      <Content>
        <Form onSubmit={onSave} enter>
          <Row margin>
            <Column>
              <AttributeInput
                value={poc_name}
                onChange={onChange}
                disabled={loading}
                errors={errors}
              >
                {poc_name.label.default}
              </AttributeInput>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 2}>
              <AttributeInput
                value={poc_email}
                onChange={onChange}
                disabled={loading}
                errors={errors}
              >
                {poc_email.label.default}
              </AttributeInput>
            </Column>
            <Column size={1 / 2}>
              <AttributeInput
                value={poc_phone_number}
                onChange={onChange}
                disabled={loading}
                errors={errors}
              >
                {poc_phone_number.label.default}
              </AttributeInput>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 3}>
              <AttributeInput
                value={poc_address_street1}
                onChange={onChange}
                disabled={loading}
                errors={errors}
              >
                {poc_address_street1.label.default}
              </AttributeInput>
            </Column>
            <Column size={1 / 3}>
              <AttributeInput
                value={poc_address_street2}
                onChange={onChange}
                disabled={loading}
                errors={errors}
              >
                {poc_address_street2.label.default}
              </AttributeInput>
            </Column>
            <Column size={1 / 3}>
              <AttributeInput
                value={poc_address_city}
                onChange={onChange}
                disabled={loading}
                errors={errors}
              >
                {poc_address_city.label.default}
              </AttributeInput>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 2}>
              <AttributeInput
                value={poc_address_zip}
                onChange={onChange}
                disabled={loading}
                errors={errors}
              >
                {poc_address_zip.label.default}
              </AttributeInput>
            </Column>
            <Column size={1 / 2}>
              <AttributeInput
                value={poc_address_state}
                onChange={onChange}
                disabled={loading}
                errors={errors}
              >
                {poc_address_state.label.default}
              </AttributeInput>
            </Column>
          </Row>
          <Row>
            <Column>
              <AttributeInput
                value={poc_notes}
                onChange={onChange}
                disabled={loading}
                errors={errors}
              >
                {poc_notes.label.default}
              </AttributeInput>
            </Column>
          </Row>
        </Form>
      </Content>
    )}
  </Card>
);

Poc1Edit.propTypes = {
  loading: PropTypes.bool,
  errors: PropTypes.array,
  poc_name: PropTypes.object,
  poc_address_street1: PropTypes.object,
  poc_address_street2: PropTypes.object,
  poc_address_city: PropTypes.object,
  poc_address_zip: PropTypes.object,
  poc_address_state: PropTypes.object,
  poc_email: PropTypes.object,
  poc_phone_number: PropTypes.object,
  poc_notes: PropTypes.object,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
};

export default Poc1Edit;
