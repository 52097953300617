import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {Table, Button} from '@matthahn/sally-ui';
import StepFooter from '../../../layout/components/StepFooter/StepFooter';
import SmallLoader from '../../../layout/components/SmallLoader/SmallLoader';

// Columns
import columns from './columns';

const DispatchDocumentsStep = ({
  Container,
  loading,
  driver,
  files,
  disableNext,
  onShow,
  onBack,
  onNext,
}) =>
  loading ? (
    <Container>
      <SmallLoader />
    </Container>
  ) : (
    <Fragment>
      <Container>
        {driver.first_name} {driver.last_name}
      </Container>
      <Container padding="none">
        <Table
          columns={columns}
          loading={loading}
          theme="orange"
          infinite
          noBorder
          noRadius
          smallNoResultsLabel
        >
          {(TableRow) =>
            files.map((file) => (
              <TableRow key={file.id}>
                {(TableColumn) => [
                  <TableColumn key="name" span={3}>
                    {file.defaultFileName}
                  </TableColumn>,
                  <TableColumn key="vehicle">
                    {file.uploaded ? (
                      <Button icon="check" size="small" theme="grey" />
                    ) : (
                      <Button
                        size="small"
                        theme="darkGrey"
                        onClick={onShow(file)}
                      >
                        Upload
                      </Button>
                    )}
                  </TableColumn>,
                ]}
              </TableRow>
            ))
          }
        </Table>
      </Container>
      <Container>
        <StepFooter
          left={
            <Button size="small" theme="grey" onClick={onBack}>
              Back
            </Button>
          }
          right={
            <Button
              size="small"
              theme="orange"
              disabled={disableNext}
              onClick={onNext}
            >
              Save
            </Button>
          }
        />
      </Container>
    </Fragment>
  );

DispatchDocumentsStep.propTypes = {
  Container: PropTypes.func,
  loading: PropTypes.bool,
  driver: PropTypes.object,
  files: PropTypes.array,
  disableNext: PropTypes.bool,
  onShow: PropTypes.func,
  onBack: PropTypes.func,
  onNext: PropTypes.func,
};

export default DispatchDocumentsStep;
