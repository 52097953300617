import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {set as setAct} from '../../redux/actions';

// Components
import {Row, Column} from '@matthahn/sally-ui';
import SubPage from '../../../layout/components/SubPage/SubPage';

// Containers
import VehicleRegistrationContainer from '../VehicleRegistrationContainer/VehicleRegistrationContainer';
import VehicleStateInspectionContainer from '../VehicleStateInspectionContainer/VehicleStateInspectionContainer';

class VehicleMaintenanceContainer extends Component {
  static propTypes = {
    vehicle: PropTypes.object,
    dispatch: PropTypes.func,
  };

  onComplete = (vehicle) => this.props.dispatch(setAct({vehicle}));

  render() {
    const {vehicle} = this.props;
    return (
      <SubPage>
        <Row>
          <Column size={1 / 2}>
            <Row>
              <Column>
                <VehicleStateInspectionContainer
                  vehicle={vehicle}
                  onComplete={this.onComplete}
                />
              </Column>
            </Row>
          </Column>
          <Column size={1 / 2}>
            <Row>
              <Column>
                <VehicleRegistrationContainer
                  vehicle={vehicle}
                  onComplete={this.onComplete}
                />
              </Column>
            </Row>
          </Column>
        </Row>
      </SubPage>
    );
  }
}

export default connect((state) => ({vehicle: state.vehicle.vehicle}))(
  VehicleMaintenanceContainer
);
