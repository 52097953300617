import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getUnixTime} from 'date-fns';

// Actions
import {set as setAct} from '../DriverInfoContainer/redux/actions';

// Components
import {Button} from '@matthahn/sally-ui';

// Permissions
import updatePermission from '../../permissions/update.permission.driver';

class DriverInfoSaveContainer extends Component {
  static propTypes = {
    saving: PropTypes.bool,
    dispatch: PropTypes.func,
  };

  save = () => this.props.dispatch(setAct({save: getUnixTime(new Date())}));

  render() {
    const {saving} = this.props;
    return updatePermission() ? (
      <Button theme="orange" size="small" loading={saving} onClick={this.save}>
        Save
      </Button>
    ) : null;
  }
}

export default connect((state) => ({saving: state.driverInfo.saving}))(
  DriverInfoSaveContainer
);
