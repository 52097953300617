import styled from 'styled-components';

// colors
import {orange} from '@matthahn/sally-ui/lib/libs/colors';

const Loader = styled.div`
  width: 100%;
  padding: 5px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${orange};
  font-size: 20px;
`;

export default Loader;
