import {date, fileName, folder} from '../../../document';

// Attributes
import lastNameAttr from '../../attributes/last_name.attribute.driver';
import ssnAttr from '../../attributes/ssn.attribute.driver';
import ssnConfirmAttr from '../../attributes/ssnConfirm.attribute.driver';

// Errors
import lastNameMissmatchErr from '../../errors/lastNameMissmatch.error.driver';
import ssnMissmatchErr from '../../errors/ssnMissmatch.error.driver';

export default folder({
  attributes: [lastNameAttr, ssnAttr, ssnConfirmAttr],
  fileAttributes: ['last_name', 'ssnConfirm'],
  defaultFileName: 'W-9',
  // W-9 - DRIVER ID - UPLOAD DATE
  fileName: fileName(
    ({driver}) => `W-9 - ${driver.first_name}_${driver.last_name} - ${date()}`
  ),
  folderName: 'W-9',
  preSaveValidation: async ({driver, ssn, ssnConfirm, last_name}) => {
    if (driver.last_name !== last_name.api.format())
      throw lastNameMissmatchErr({message: 'Incorrect last name'});
    if (ssn.api.format() !== ssnConfirm.api.format())
      throw ssnMissmatchErr({message: 'SSN numbers must match'});
  },
  type: 'w9',
  signature: true,
});
