import {TOGGLE} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

export default attribute({
  type: TOGGLE,
  attribute: 'payment_plan_active',
  label: {
    default: 'Is Payment Plan Active',
    short: 'Active',
  },
});
