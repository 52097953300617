import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

// Events
import event from '../events/listed.event.transaction';

export default async (rootID, query = {}) => {
  const response = await f3tch(`/payments/transactions/children/${rootID}/`)
    .profile(profile())
    .query(query)
    .get();
  event.pub(...response.results);
  return response;
};
