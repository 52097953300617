import React from 'react';
import PropTypes from 'prop-types';

// Components
import {AddressBook, Container} from '@matthahn/sally-ui';
import PageContainer from './components/Container';
import Side from './components/Side';

const DriverRentalsLayout = ({loading, children, rentals, onMore}) => (
  <PageContainer>
    <Side>
      <AddressBook
        loading={loading}
        title="Rentals"
        items={rentals}
        theme="orange"
        onMore={onMore}
        avatarProps={{
          textDisplay: 'full',
          oval: true,
        }}
        border
      />
    </Side>
    <Container>{children}</Container>
  </PageContainer>
);

DriverRentalsLayout.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
  rentals: PropTypes.array,
};

export default DriverRentalsLayout;
