import {isNumeric} from 'validator';

export default (searchValue) => {
  if (!searchValue || !searchValue.trim().length) return {};
  const uppercaseSearch = searchValue.toUpperCase();
  const queryKey = isNumeric(searchValue)
    ? 'summons_number'
    : searchValue.length === 4
    ? 'vehicle__medallion__medallion_number'
    : 'driver_name';
  return {[queryKey]: uppercaseSearch};
};
