import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {string, alphanumeric} from '../../types';

export default attribute({
  type: TEXT,
  attribute: 'check_number_from_driver',
  display: alphanumeric,
  input: alphanumeric,
  api: string,
  label: {
    default: 'Check Number',
    short: 'Check Number',
  },
});
