import prepare from '../../attribute/prepare.attribute';

// Errors
import emptyWeeklyRentChargeErr from '../errors/emptyWeeklyRentCharge.error.dispatch';

export default async ({daily = false, ...attributes} = {}) => {
  if (daily) return {weekly_rent_charge: 0};
  const data = prepare(attributes, {
    field: 'api',
    required: [],
  });
  if (data.weekly_rent_charge !== 0 && !data.weekly_rent_charge)
    throw emptyWeeklyRentChargeErr();
  return data;
};
