import styled from 'styled-components';

// colors
import {red} from '@matthahn/sally-ui/lib/libs/colors';

const Action = styled.div`
  flex: 1;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;
  user-select: none;

  opacity: ${({disabled}) => (disabled ? 0.5 : 1)};
  pointer-events: ${({disabled}) => (disabled ? 'none' : 'auto')};

  & i {
    font-size: 50px;
    display: block;
  }

  &:hover {
    color: ${red};
  }
`;

export default Action;
