import React from 'react';

// Components
import DispatcherLayout from '../../components/DispatcherLayout/DispatcherLayout';

// Containers
import Page from '../../../layout/containers/Page/Page';

// Page
import PAGE from './page';

const DashboardPage = () => (
  <Page page={PAGE}>
    <DispatcherLayout />
  </Page>
);

export default DashboardPage;
