import {DATE} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {date, apiDate} from '../../types';

export default attribute({
  type: DATE,
  attribute: 'policy_expiration_date',
  display: date,
  input: date,
  api: apiDate,
  label: {
    default: 'Expiration Date',
    short: 'Expiration Date',
  },
});
