import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// API
import createTransactionApi from '../../api/create.api.transaction';
import getDriverByIDApi from '../../../driver/api/getByID.api.driver';

// Actions
import {hide as hideAct} from './redux/actions';

// Attributes
import amountAttr from '../../attributes/amount.attribute.transaction';
import subtypeAttr from '../../attributes/chargeSubtype.attribute.transaction';
import descriptionAttr from '../../attributes/description.attribute.transaction';
import driverAttr from '../../attributes/driver.attribute.transaction';

// Components
import ChargeModal from '../../components/ChargeModal/ChargeModal';

// Libs
import {lib} from '@matthahn/sally-ui';
import parseError from '../../../error/parseError';
import getTransactionSubtypes from '../../../transactionType/lib/getSubtype.lib.transactionType';

// Prep
import chargePrep from '../../preparation/charge.preparation.transaction';

// Permission
import chargePermission from '../../permission/charge.permission.transaction';

// UI
const {alert} = lib;

class ChargeContainer extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    loadingTransactionTypes: PropTypes.bool,
    transactionTypes: PropTypes.array,
    driver: PropTypes.object,
    requestAddon: PropTypes.object,
    dispatch: PropTypes.func,
  };

  static defaultProps = {
    requestAddon: {},
  };

  state = {
    loading: false,
    amount: amountAttr(''),
    subtype: subtypeAttr(''),
    description: descriptionAttr(''),
  };

  componentDidMount() {
    if (this.props.visible) this.init();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) this.init();
  }

  init = () => {
    this.setState({
      amount: amountAttr(''),
      subtype: subtypeAttr(''),
      description: descriptionAttr(''),
    });
  };

  hide = () => {
    if (this.state.loading) return;
    this.props.dispatch(hideAct(false));
  };

  change = (val, key) => {
    if (this.state.loading) return;
    this.setState({[key]: val});
  };

  save = async () => {
    const {visible, dispatch, driver, requestAddon} = this.props;

    const {loading, amount, subtype, description} = this.state;

    if (!chargePermission())
      return alert.warning('You do not have permissions to perform this.');
    if (!visible || !driver || loading) return;

    this.setState({loading: true});

    try {
      const charge = await chargePrep({
        amount,
        balance: amount,
        subtype,
        description,
        driver: driverAttr(driver.id),
      });
      charge.balance = charge.amount;
      await createTransactionApi({...charge, ...requestAddon});
      await getDriverByIDApi(driver.id);
      this.setState({loading: false});
      alert.success('Charge created');
      dispatch(hideAct(true));
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({loading: false});
    }
  };

  render() {
    const {visible, loadingTransactionTypes, transactionTypes} = this.props;
    const {loading, amount, subtype, description} = this.state;
    return (
      <ChargeModal
        visible={visible}
        loading={loading || loadingTransactionTypes}
        amount={amount}
        subtype={subtype}
        subtypes={getTransactionSubtypes(transactionTypes, 'charge')}
        description={description}
        onSave={this.save}
        onChange={this.change}
        onClose={this.hide}
      />
    );
  }
}

export default connect((state) => ({
  ...state.charge,
  roles: state.auth.roles,
  loadingTransactionTypes: state.transactionType.loading,
  transactionTypes: state.transactionType.transactionTypes,
}))(ChargeContainer);
