import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

const EnableMedallionModal = ({
  loading,
  visible,
  reason,
  onClose,
  onChange,
  onSave,
}) => (
  <Modal
    title="Enable medallion"
    visible={visible}
    onClose={onClose}
    buttonsRight={[
      {label: 'Enable', onClick: onSave, loading, theme: 'orange'},
    ]}
  >
    {(Content) => (
      <Content>
        <AttributeInput value={reason} onChange={onChange} disabled={loading}>
          {reason.label.default}
        </AttributeInput>
      </Content>
    )}
  </Modal>
);

EnableMedallionModal.propTypes = {
  loading: PropTypes.bool,
  visible: PropTypes.bool,
  reason: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  onChange: PropTypes.func,
};

export default EnableMedallionModal;
