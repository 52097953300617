// dispatch lib
import numberOfDriverAlerts from './numberOfDriverAlerts.lib.dispatch';

// lib
import sum from '@matthahn/sally-fw/lib/lib/sum';

const numberOfAlerts = ({vehicle_alerts_unresolved, active_rentals}) =>
  sum(
    vehicle_alerts_unresolved?.length,
    numberOfDriverAlerts({active_rentals})
  );

export default numberOfAlerts;
