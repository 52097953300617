import active from './active.state.vehicle';
import archived from './archived.state.vehicle';
import available from './available.state.vehicle';
import decommissioned from './decommissioned.state.vehicle';
import paired from './paired.state.vehicle';
import unknown from './unknown.state.vehicle';

const states = [active, archived, available, decommissioned, paired].reduce(
  (combined, state) => ({...combined, [state.key]: state}),
  {}
);

export const stateList = [available, paired, active, archived, decommissioned];

export default (state) => states[state] || unknown;
