import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Api
import updateApi from '../../api/update.api.medallionOwner';

// Attributes
import phoneNumberAttr from '../../attributes/phone_number.attribute.medallionOwner';
import phoneNumber2Attr from '../../attributes/phone_number2.attribute.medallionOwner';
import emailAttr from '../../attributes/email.attribute.medallionOwner';

// Components
import ContactInfo from '../../components/ContactInfo/ContactInfo';
import ContactEdit from '../../components/ContactEdit/ContactEdit';

// Libs
import {lib} from '@matthahn/sally-ui';
import parseError from '../../../error/parseError';

// Prep
import updatePrep from '../../preparation/updateContact.preparation.medallionOwner';

// Notifications
const {alert} = lib;

class ContactContainer extends Component {
  static propTypes = {
    medallionOwner: PropTypes.object,
  };

  state = {
    loading: false,
    edit: false,
    errors: [],
    phone_number: phoneNumberAttr(''),
    phone_number2: phoneNumber2Attr(''),
    email: emailAttr(''),
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  startEdit = () => {
    const {medallionOwner} = this.props;
    if (this.state.loading) return;
    this.setState({
      edit: true,
      phone_number: phoneNumberAttr(medallionOwner.phone_number || ''),
      phone_number2: phoneNumber2Attr(medallionOwner.phone_number2 || ''),
      email: emailAttr(medallionOwner.email || ''),
    });
  };

  stopEdit = () => {
    if (this.state.loading) return;
    this.setState({edit: false, errors: []});
  };

  onChange = (val, key, errors) => {
    if (this.state.loading) return;
    this.setState({[key]: val, errors});
  };

  update = async () => {
    const {loading, edit, errors, ...attributes} = this.state;
    if (loading) return;

    this.setState({loading: true});

    try {
      const obj = await updatePrep(attributes);
      await updateApi(this.props.medallionOwner.id, obj);
      if (!this.mounted) return;
      this.setState({loading: false, edit: false});
    } catch (error) {
      if (!this.mounted) return;
      const {fields: errors, message} = parseError(error);
      alert.error(message);
      this.setState({loading: false, errors});
    }
  };

  render() {
    const {medallionOwner} = this.props;
    const {
      loading,
      edit,
      errors,
      phone_number,
      phone_number2,
      email,
    } = this.state;
    return edit ? (
      <ContactEdit
        loading={loading}
        errors={errors}
        phone_number={phone_number}
        phone_number2={phone_number2}
        email={email}
        onCancel={this.stopEdit}
        onChange={this.onChange}
        onSave={this.update}
      />
    ) : (
      <ContactInfo {...medallionOwner} onEdit={this.startEdit} />
    );
  }
}

export default connect((state) => ({
  medallionOwner: state.medallionOwner.object,
}))(ContactContainer);
