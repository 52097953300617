import React from 'react';
import PropTypes from 'prop-types';

// components
import {CheckBox} from '@matthahn/sally-ui';

const BooleanPermission = ({label, onChange, value}) => (
  <CheckBox value={value} onChange={onChange} green>
    {label}
  </CheckBox>
);

BooleanPermission.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.bool,
};

export default BooleanPermission;
