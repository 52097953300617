import createFolderTree from './folderTree.document';

export default ({name, files = []} = {}) => {
  const computedFiles = [...files].map((rawFile) =>
    !!rawFile.file
      ? {
          ...rawFile,
          file: rawFile.file,
          alwaysRequired: rawFile.alwaysRequired || false,
        }
      : {file: rawFile, alwaysRequired: false}
  );
  return {
    name,
    files: computedFiles,
    folderTree: createFolderTree([...computedFiles].map(({file}) => file())),
  };
};
