import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Api
import updateDriverApi from '../../api/update.api.driver';

// Attributes
import ssnAttr from '../../attributes/ssn.attribute.driver';
import ssnConfirmAttr from '../../attributes/ssnConfirm.attribute.driver';

// Components
import DriverSSNModal from '../../components/DriverSSNModal/DriverSSNModal';

// Error
import parseError from '../../../error/parseError';

// Lib
import {lib} from '@matthahn/sally-ui';

// Prep
import updateSSNPrep from '../../preparation/updateSSN.preparation.driver';

// Alert
const {alert} = lib;

class DriverSSNContainer extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    driver: PropTypes.object,
    onComplete: PropTypes.func,
    onClose: PropTypes.func,
  };

  state = {
    loading: false,
    ssn: ssnAttr(''),
    ssnConfirm: ssnConfirmAttr(''),
  };

  componentDidMount() {
    if (this.props.visible) this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.visible && this.props.visible) this.init();
  }

  init = () => {
    this.setState({
      loading: false,
      ssn: ssnAttr(''),
      ssnConfirm: ssnConfirmAttr(''),
    });
  };

  onChange = (val, key) => {
    if (this.state.loading) return;
    this.setState({[key]: val});
  };

  onClose = () => {
    if (this.state.loading) return;
    this.props.onClose();
  };

  save = async () => {
    const {
      visible,
      driver: {id},
      onComplete,
    } = this.props;
    const {loading, ssn, ssnConfirm} = this.state;

    if (!visible || loading) return;

    this.setState({loading: true});

    try {
      const body = await updateSSNPrep({ssn, ssnConfirm});
      const driver = await updateDriverApi(id, body);
      onComplete(driver);
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
    }

    this.setState({loading: false});
  };

  render() {
    const {visible} = this.props;
    const {loading, ssn, ssnConfirm} = this.state;
    return (
      <DriverSSNModal
        visible={visible}
        loading={loading}
        ssn={ssn}
        ssnConfirm={ssnConfirm}
        onChange={this.onChange}
        onClose={this.onClose}
        onSave={this.save}
      />
    );
  }
}

export default DriverSSNContainer;
