import React from 'react';
import PropTypes from 'prop-types';

// components
import TableRow from '@matthahn/sally-ui/lib/components/Table/components/Row.Table';

// local components
import ValueContainer from './components/ValueContainer';

const AccidentValueTable = ({
  rowProps,
  labelProps,
  displayProps,
  label,
  display,
  noPadding,
  wrapContent,
}) => (
  <TableRow portions={4} {...rowProps}>
    {(TableColumn) => [
      <TableColumn key="label" {...labelProps}>
        {label}
      </TableColumn>,
      <TableColumn
        key="display"
        span={3}
        border="none none none solid"
        noPadding={noPadding}
        {...displayProps}
      >
        {wrapContent ? <ValueContainer>{display}</ValueContainer> : display}
      </TableColumn>,
    ]}
  </TableRow>
);

AccidentValueTable.propTypes = {
  rowProps: PropTypes.object,
  labelProps: PropTypes.object,
  displayProps: PropTypes.object,
  label: PropTypes.node,
  display: PropTypes.node,
  noPadding: PropTypes.bool,
  wrapContent: PropTypes.bool,
};

AccidentValueTable.defaultProps = {
  rowProps: {},
  labelProps: {},
  displayProps: {},
  label: null,
  display: null,
  noPadding: false,
  wrapContent: false,
};

export default AccidentValueTable;
