import styled from 'styled-components';

// Colors
import {lib} from '@matthahn/sally-ui';
const {
  colors: {grey, darkGrey},
} = lib;

const TableHeader = styled.div`
  width: 100%;
  height: 50px;
  max-height: 50px;
  min-height: 50px;
  display: flex;
  align-items: center;
  background: ${grey};
  color: ${darkGrey};
  text-transform: uppercase;
  font-weight: 500;
  font-size: 13px;
`;

export default TableHeader;
