import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {parseISO, isWithinInterval} from 'date-fns';
import {connect} from 'react-redux';

// Actions
import {hide as hideAct, set as setAct} from './redux/actions';

// Api
import api from '../../../api/lib/getEverythingFromApi.lib.api';
import listPossibleRentalsApi from '../../api/possibleRentals.api.ticket';
import updateApi from '../../api/update.api.ticket';
import assignToRentalApi from '../../api/assignToRental.api.ticket';
import assignToSallyApi from '../../api/assignToSally.api.ticket';
import listDocumentsApi from '../../../document/api/list.api.document';
import uploadDocumentApi from '../../../document/api/upload.api.document';
import updateDocumentApi from '../../../document/api/update.api.document';

// Components
import TicketModal from '../../components/TicketModal/TicketModal';

// Containers
import DriverRentalTicketContainer from '../DriverRentalTicketContainer/DriverRentalTicketContainer';

// Documents
import ticketDoc from '../../documents/folders/ticket.document.ticket';

// Events
import updatedEvt from '../../events/updated.event.ticket';

// Lib
import {lib} from '@matthahn/sally-ui';
import parseError from '../../../error/parseError';
import fkOrId from '../../../lib/fkOrId';

// Query
import {isNull} from '../../../api/queries/queries';

// Statuses
import status from '../../statuses/status';

// Attributes
import * as ticketAttributes from '../../attributes';
import objectToAttributes from '../../../attribute/objectToAttributes';

// Alert
const {alert, notify} = lib;

class TicketContainer extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    ticket: PropTypes.object,
    dispatch: PropTypes.func,
  };

  state = {
    loading: false,
    deleting: false,
    assigning: false,
    updating: false,
    uploading: false,
    closable: true,
    showAssignModal: false,
    ticket: null,
    rentals: [],
    transitioning: null,
    showUpload: false,
  };

  componentDidMount() {
    if (this.props.visible) this.init();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) this.init();
  }

  init = () => {
    const {ticket} = this.props;
    if (!ticket) return;
    const convertedAttributes = objectToAttributes({
      object: ticket,
      attributes: {
        ...ticketAttributes,
      },
      convert: ['due_datetime'],
    });

    this.setState(
      {
        ticket: {...ticket, ...convertedAttributes},
      },
      () => {
        this.getRentals();
      }
    );
  };

  isLoading = () =>
    this.state.loading ||
    this.state.deleting ||
    this.state.updating ||
    this.state.assigning ||
    this.state.uploading ||
    !!this.state.transitioning;

  getRentals = async () => {
    const {dispatch} = this.props;
    const {ticket} = this.state;

    this.setState({loading: true});

    try {
      const {data: rawRentals} = await api((query) =>
        listPossibleRentalsApi(ticket.id, query)
      );
      const rentals = this.parseRentals(rawRentals);
      this.setState({loading: false, rentals});
    } catch (error) {
      this.setState({loading: false});
      alert.error('Could not load rentals');
      dispatch(hideAct());
    }
  };

  parseRentals = (rentals) => {
    const {ticket} = this.state;
    const ticketDate = parseISO(ticket.transaction_date);
    return [...rentals].map((rental) => ({
      ...rental,
      canApply:
        !!rental.vehicle_pickup_datetime &&
        !!rental.vehicle_dropoff_datetime &&
        rental.vehicle_dropoff_datetime > rental.vehicle_pickup_datetime &&
        isWithinInterval(ticketDate, {
          start: parseISO(rental.vehicle_pickup_datetime),
          end: parseISO(rental.vehicle_dropoff_datetime),
        }),
    }));
  };

  onClose = () => {
    if (this.isLoading() || !this.state.closable || this.state.showAssignModal)
      return;
    this.props.dispatch(hideAct());
  };

  onAssign = (rental) => () => this.assign(rental);

  assign = async (rental, prompt = true) => {
    const {dispatch} = this.props;
    const {ticket} = this.state;
    if (this.isLoading()) return;

    if (prompt) {
      await this.setState({closable: false});
      return notify({
        id: 'assignTicket',
        title: 'Assign',
        icon: undefined,
        content: 'Are you sure you want to assign this ticket?',
        primary: {
          label: 'No',
          onClick: () => this.setState({closable: true}),
        },
        onClose: () => this.setState({closable: true}),
        secondary: {
          label: 'Yes',
          onClick: () => this.assign(rental, false),
        },
        closable: false,
        closeOnOutsideClick: true,
      });
    }

    this.setState({loading: true, showAssignModal: false, closable: true});

    try {
      const newTicket = await assignToRentalApi(ticket.id, rental.id);
      await this.updateDriverField({
        ticket: newTicket,
        driver: fkOrId(rental.driver),
      });
      this.setState({loading: false, showAssignModal: false});
      alert.success('Ticket reassigned');
      dispatch(setAct({ticket: newTicket}));
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({loading: false});
    }
  };

  delete = async (prompt = true) => {
    const {dispatch} = this.props;
    const {ticket} = this.state;

    if (this.isLoading()) return;

    if (prompt) {
      await this.setState({closable: false});
      return notify({
        id: 'deleteTicket',
        title: 'Delete',
        icon: undefined,
        content: 'Are you sure you want to delete this ticket?',
        primary: {
          label: 'No',
          onClick: () => this.setState({closable: true}),
        },
        secondary: {
          label: 'Yes',
          onClick: () => this.delete(false),
        },
        onClose: () => this.setState({closable: true}),
        closable: false,
        closeOnOutsideClick: true,
      });
    }

    this.setState({deleting: true, closable: true});

    try {
      await updateApi(ticket.id, {removed: true});
      this.setState({deleting: false, closable: true});
      alert.success('Ticket deleted');
      dispatch(hideAct());
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({deleting: false, closable: true});
    }
  };

  transition = async (ticketStatus, prompt = true) => {
    const {dispatch} = this.props;
    const {ticket} = this.state;
    if (this.isLoading()) return;

    if (ticketStatus.needsRentalToTransition && !ticket.rental)
      return alert.warning('Assign ticket to a rental first');

    if (prompt) {
      await this.setState({closable: false});
      return notify({
        id: `transitionTicketStatusTo${ticketStatus.key}`,
        title: 'Transition Ticket',
        icon: undefined,
        content: `Are you sure you want to mark this ticket as "${ticketStatus.label}"?`,
        primary: {
          label: 'No',
          onClick: () => this.setState({closable: true}),
        },
        secondary: {
          label: 'Yes',
          onClick: () => this.transition(ticketStatus, false),
        },
        onClose: () => this.setState({closable: true}),
        closable: false,
        closeOnOutsideClick: true,
      });
    }

    this.setState({transitioning: ticketStatus.key, closable: true});

    try {
      await ticketStatus.api(ticket.id);
      alert.success(`Ticket marked as "${ticketStatus.label}"`);
      dispatch(hideAct());
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
    }

    this.setState({transitioning: null});
  };

  assignToSally = async (prompt = true) => {
    const {ticket, dispatch} = this.props;

    if (this.isLoading()) return;

    if (prompt) {
      await this.setState({closable: false});
      return notify({
        id: 'assignToSallyTicket',
        title: 'Assign To Sally',
        icon: undefined,
        content: 'Are you sure you want to assign this ticket to Sally?',
        primary: {
          label: 'No',
          onClick: () => this.setState({closable: true}),
        },
        secondary: {
          label: 'Yes',
          onClick: () => this.assignToSally(false),
        },
        onClose: () => this.setState({closable: true}),
        closable: false,
        closeOnOutsideClick: true,
      });
    }

    this.setState({assigning: true, closable: true});

    try {
      await assignToSallyApi(ticket.id);
      this.setState({assigning: false, closable: true});
      alert.success('Ticket Assigned To Sally');
      dispatch(hideAct());
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({assigning: false, closable: true});
    }
  };

  onChange = async (value, key) => {
    const {dispatch} = this.props;

    const newTicket = {...this.state.ticket, [key]: value};
    this.setState({
      ticket: newTicket,
      loading: true,
      closable: false,
    });

    try {
      await updateApi(newTicket.id, {[key]: value.value()});
      this.setState({loading: false, closable: true});
      dispatch(setAct({ticket: newTicket}));
      alert.success(`Ticket ${value.label.default} updated!`);
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({loading: false, closable: true});
    }
  };

  updateDriverField = async ({ticket, driver}) => {
    try {
      const {results: rawFiles} = await listDocumentsApi({
        ticket: ticket.id,
        [isNull('driver')]: true,
      });
      const files = [...rawFiles].filter(
        (file) => !files.driver && file.ticket.id === ticket.id
      );
      const promises = files.map((file) =>
        updateDocumentApi(file.id, {driver})
      );
      await Promise.all(promises);
    } catch (error) {
      // Do nothing, just don't fail
    }
  };

  updateDocument = async ([file]) => {
    const {ticket, dispatch} = this.props;
    if (!ticket || this.isLoading()) return;

    this.setState({uploading: true});

    try {
      const query = {
        doc: file,
        file: ticketDoc,
        ticket,
        type: 'ticket',
      };
      if (!!ticket?.rental?.driver)
        query.driver = {id: fkOrId(ticket?.rental?.driver)};
      await uploadDocumentApi(query);
      dispatch(
        setAct({ticket: {...ticket, latest_ticket_image: file.preview}})
      );
      this.setState({
        ticket: {...this.state.ticket, latest_ticket_image: file.preview},
        uploading: false,
        showUpload: false,
      });
      updatedEvt.pub({...ticket, latest_ticket_image: file.preview});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({uploading: false});
    }
  };

  onTransition = (ticketStatus) => () => this.transition(ticketStatus);

  showAssignModal = () => this.setState({showAssignModal: true});

  hideAssignModal = () => this.setState({showAssignModal: false});

  onCustomRentalSelect = (rental) => this.assign(rental, false);

  toggleUpload = () => {
    this.setState({showUpload: !this.state.showUpload});
  };

  render() {
    const {visible} = this.props;
    const {
      ticket,
      loading,
      deleting,
      assigning,
      uploading,
      rentals,
      transitioning,
      showAssignModal,
      showUpload,
    } = this.state;
    return (
      <Fragment>
        <TicketModal
          visible={visible && !showAssignModal}
          transitioning={transitioning}
          loading={loading}
          deleting={deleting}
          assigning={assigning}
          uploading={uploading}
          ticket={ticket}
          rentals={rentals}
          status={status(ticket)}
          onRental={this.onAssign}
          onClose={this.onClose}
          onChange={this.onChange}
          onDelete={this.delete}
          onAdd={this.showAssignModal}
          onTransition={this.onTransition}
          onAssignToSally={this.assignToSally}
          onDocument={this.updateDocument}
          showUpload={showUpload}
          onShowUpload={this.toggleUpload}
        />
        <DriverRentalTicketContainer
          ticket={ticket}
          visible={visible && showAssignModal}
          onSelect={this.onCustomRentalSelect}
          onClose={this.hideAssignModal}
        />
      </Fragment>
    );
  }
}

export default connect((state) => ({...state.ticketInfo}))(TicketContainer);
