import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

// Events
import event from '../events/updated.event.ticket';

export default async (id) => {
  const response = await f3tch(`/tickets/${id}/mark_paid/`)
    .profile(profile())
    .post();
  event.pub(response);
  return response;
};
