import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

export default (driverId, query = {}) =>
  f3tch(`/drivers/${driverId}/event_log/`)
    .profile(profile())
    .query(query)
    .get();
