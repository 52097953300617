import {fileName, folder, date} from '../../../document';

export default folder({
  defaultFileName: 'Other',
  // OTHER - DRIVER ID - DATE OF LOSS
  fileName: fileName(
    ({accident}) =>
      `OTHER - ${accident.driver?.first_name}_${accident.driver?.last_name} - ${
        accident?.vehicle?.svid
      } - ${date()}`
  ),
  folderName: 'Others',
  type: 'accidentOther',
});
