import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Api
import downloadPayoutApi from '../../../payout/api/download.api.payout';

// Actions
import {hide as hideAct} from './redux/actions';

// Components
import RentalReportPreviewModal from '../../components/RentalReportPreviewModal/RentalReportPreviewModal';

// Lib
import {lib} from '@matthahn/sally-ui';
import downloadFile from '../../../lib/downloadFile';

// Types
import {date} from '../../../types';

// Alerts
const {alert} = lib;

class RentalReportPreviewContainer extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    rentalReport: PropTypes.object,
    dispatch: PropTypes.func,
  };

  state = {
    downloading: false,
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  close = () => this.props.dispatch(hideAct());

  download = async () => {
    const {rentalReport} = this.props;
    const {downloading} = this.state;
    if (downloading || !rentalReport) return;

    this.setState({downloading: true});

    try {
      const file = await downloadPayoutApi(rentalReport.payout.id);
      downloadFile(
        file,
        `${rentalReport.driver.first_name} ${rentalReport.driver.last_name} - ${
          rentalReport.medallion.medallion_number
        } - ${date(new Date()).format()}.pdf`
      );
    } catch (error) {
      alert.error('Could not generate a statement');
    }
    if (!this.mounted) return;
    this.setState({downloading: false});
  };

  render() {
    const {visible, rentalReport} = this.props;
    const {downloading} = this.state;
    return (
      <RentalReportPreviewModal
        downloadingReport={downloading}
        visible={visible}
        rentalReport={rentalReport}
        onClose={this.close}
        onStatementDownload={this.download}
      />
    );
  }
}

export default connect((state) => ({...state.rentalReportPreview}))(
  RentalReportPreviewContainer
);
