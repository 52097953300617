import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Api
import updateApi from '../../api/update.api.medallionOwner';

// Attributes
import addressStreet1Attr from '../../attributes/address_street1.attribute.medallionOwner';
import addressStreet2Attr from '../../attributes/address_street2.attribute.medallionOwner';
import addressCityAttr from '../../attributes/address_city.attribute.medallionOwner';
import addressZipAttr from '../../attributes/address_zip.attribute.medallionOwner';
import addressStateAttr from '../../attributes/address_state.attribute.medallionOwner';

// Components
import AddressInfo from '../../components/AddressInfo/AddressInfo';
import AddressEdit from '../../components/AddressEdit/AddressEdit';

// Libs
import {lib} from '@matthahn/sally-ui';
import parseError from '../../../error/parseError';

// Prep
import updatePrep from '../../preparation/updateAddress.preparation.medallionOwner';

// Notifications
const {alert} = lib;

class AddressContainer extends Component {
  static propTypes = {
    medallionOwner: PropTypes.object,
  };

  state = {
    loading: false,
    edit: false,
    errors: [],
    address_street1: addressStreet1Attr(''),
    address_street2: addressStreet2Attr(''),
    address_city: addressCityAttr(''),
    address_zip: addressZipAttr(''),
    address_state: addressStateAttr(''),
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  startEdit = () => {
    const {medallionOwner} = this.props;
    if (this.state.loading) return;
    this.setState({
      edit: true,
      address_street1: addressStreet1Attr(medallionOwner.address_street1 || ''),
      address_street2: addressStreet2Attr(medallionOwner.address_street2 || ''),
      address_city: addressCityAttr(medallionOwner.address_city || ''),
      address_zip: addressZipAttr(medallionOwner.address_zip || ''),
      address_state: addressStateAttr(medallionOwner.address_state || ''),
    });
  };

  stopEdit = () => {
    if (this.state.loading) return;
    this.setState({edit: false, errors: []});
  };

  onChange = (val, key, errors) => {
    if (this.state.loading) return;
    this.setState({[key]: val, errors});
  };

  update = async () => {
    const {loading, edit, errors, ...attributes} = this.state;
    if (loading) return;

    this.setState({loading: true});

    try {
      const obj = await updatePrep(attributes);
      await updateApi(this.props.medallionOwner.id, obj);
      if (!this.mounted) return;
      this.setState({loading: false, edit: false});
    } catch (error) {
      if (!this.mounted) return;
      const {fields: errors, message} = parseError(error);
      alert.error(message);
      this.setState({loading: false, errors});
    }
  };

  render() {
    const {medallionOwner} = this.props;
    const {
      loading,
      edit,
      errors,
      address_street1,
      address_street2,
      address_city,
      address_zip,
      address_state,
    } = this.state;
    return edit ? (
      <AddressEdit
        loading={loading}
        errors={errors}
        address_street1={address_street1}
        address_street2={address_street2}
        address_city={address_city}
        address_zip={address_zip}
        address_state={address_state}
        onCancel={this.stopEdit}
        onChange={this.onChange}
        onSave={this.update}
      />
    ) : (
      <AddressInfo {...medallionOwner} onEdit={this.startEdit} />
    );
  }
}

export default connect((state) => ({
  medallionOwner: state.medallionOwner.object,
}))(AddressContainer);
