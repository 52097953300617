import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal, Text, Row, Column, Form} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

// Libs
import focusAfterAnimation from '../../../layout/lib/focusAfterAnimation.lib.layout';

// paymentMethod lib
import getPaymentMethodName from '../../../paymentMethod/lib/getName.lib.paymentMethod';
class ConfirmCashPayoutModal extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    visible: PropTypes.bool,
    driver: PropTypes.object,
    confirmAmount: PropTypes.object,
    paymentMethod: PropTypes.string,
    paymentMethods: PropTypes.array,
    onChange: PropTypes.func,
    onConfirm: PropTypes.func,
    onClose: PropTypes.func,
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) this.focus();
  }

  input = null;

  focus = () => {
    const {visible, driver} = this.props;
    if (!visible || !driver || !this.input) return;
    focusAfterAnimation(this.input);
  };

  inputRef = (dom) => {
    this.input = dom;
  };

  render() {
    const {
      loading,
      visible,
      driver,
      confirmAmount,
      paymentMethod,
      paymentMethods,
      onChange,
      onConfirm,
      onClose,
    } = this.props;
    return (
      <Modal
        title="Confirm Transaction"
        visible={visible}
        onClose={onClose}
        buttonsLeft={[{label: 'Cancel', disabled: loading, onClick: onClose}]}
        buttonsRight={[
          {label: 'Confirm', loading, onClick: onConfirm, theme: 'yellow'},
        ]}
      >
        {(Content) =>
          !driver ? null : (
            <Content>
              <Form onSubmit={onConfirm} enter>
                <Row margin>
                  <Column size={1 / 2}>
                    <Text value={`${driver.first_name} ${driver.last_name}`}>
                      Driver
                    </Text>
                  </Column>
                  <Column size={1 / 2}>
                    <Text
                      value={getPaymentMethodName({
                        paymentMethod,
                        paymentMethods,
                      })}
                    >
                      Payment method
                    </Text>
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <AttributeInput
                      value={confirmAmount}
                      onChange={onChange}
                      inputRef={this.inputRef}
                    >
                      Confirm Amount
                    </AttributeInput>
                  </Column>
                </Row>
              </Form>
            </Content>
          )
        }
      </Modal>
    );
  }
}

export default ConfirmCashPayoutModal;
