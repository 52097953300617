import prepare from '../../attribute/prepare.attribute';

// Constants
import maximumDailyRate from '../../dispatch/constants/maximumDailyRate.constant.dispatch';
import minimumDailyRate from '../../dispatch/constants/minimumDailyRate.constant.dispatch';
import minimumWeeklyRate from '../../dispatch/constants/minimumWeeklyRate.constant.dispatch';

// Errors
import dailyRateToSmall from '../errors/dailyRateToSmall.error.rental';
import weeklyRateToSmall from '../errors/weeklyRateToSmall.error.rental';

export default async ({isDaily = false, ...attributes} = {}) => {
  const rental = prepare(attributes, {
    field: 'api',
    required: ['amount', 'vehicle_pickup_datetime', 'driver', 'medallion'],
  });

  if (!isDaily && rental.amount < minimumWeeklyRate)
    throw weeklyRateToSmall({
      message: `Weekly rental rate can not be less than ${minimumWeeklyRate}`,
    });
  if (isDaily && rental.amount < minimumDailyRate)
    throw dailyRateToSmall({
      message: `Daily rental rate can not be less than ${minimumDailyRate}`,
    });
  if (isDaily && rental.amount > maximumDailyRate)
    throw dailyRateToSmall({
      message: `Daily rental rate can not be more than ${maximumDailyRate}`,
    });

  return rental;
};
