import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

export default (id, jwt) => {
  const api = f3tch(`/authentication/users/${id}/`).profile(profile());

  if (!!jwt) api.headers({Authorization: `JWT ${jwt}`});

  return api.get();
};
