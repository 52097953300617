import {NUMBER} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {amount, formattedNumber, purchasePrice} from '../../types';

export default attribute({
  type: NUMBER,
  attribute: 'insurance_liability_rate',
  display: amount,
  input: formattedNumber,
  api: purchasePrice,
  label: {
    default: 'Insurance Rate',
    short: 'Rate',
  },
});
