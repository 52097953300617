// Lib
import fkOrId from '../../lib/fkOrId';
import sum from '../../lib/sum';
import orderByDate from '../../lib/orderByDate';

export default (transactions, rental = null) => {
  const rentTransactions = [...transactions].find(
    ({type, subtype}) => type === 'charge' && subtype === 'rent'
  );
  if (!rentTransactions) return 0;
  const {previousRentTransactions, currentRentTransactions} = [
    ...rentTransactions.transactions,
  ].reduce(
    (combined, transaction) => {
      const key =
        !!rental && fkOrId(transaction.rental) === rental.id
          ? 'currentRentTransactions'
          : 'previousRentTransactions';
      return {
        ...combined,
        [key]: [...combined[key], transaction],
      };
    },
    {previousRentTransactions: [], currentRentTransactions: []}
  );
  // eslint-disable-next-line
  const [latestTransaction, ...leftoverCurrentRentTransactions] = orderByDate(
    currentRentTransactions,
    'posted_at',
    'desc'
  );
  const previousTransactions = [
    ...previousRentTransactions,
    ...leftoverCurrentRentTransactions,
  ];
  return sum(...previousTransactions.map(({balance}) => balance));
};
