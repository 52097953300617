export default ({
  is_staff = false,
  is_superuser = false,
  approve_check_permission = false,
  delete_document_permission = false,
  ...other
} = {}) => ({
  is_staff,
  is_superuser,
  approve_check_permission,
  delete_document_permission,
  ...other,
});
