import status from './wrapper.status';

// Api
import api from '../api/markAsPaid.api.ticket';

export default status({
  key: 'paid_by_sally',
  label: 'Ticket Paid',
  icon: 'creditcard',
  api,
});
