import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// component lib
import alert from '@matthahn/sally-ui/lib/libs/alert';
import notify from '@matthahn/sally-ui/lib/libs/notify';

// contract events
import showChangeOrderActionsEvent from '../../../contract/events/showActions.event.changeOrder';

// driver actions
import {set as setAction} from '../../../driver/redux/actions';

// driverAlert api
import updateAlertApi from '../../api/update.api.driverAlert';

// driverAlert components
import DriverAlerts from '../../components/DriverAlerts/DriverAlerts';

// driverAlert events
import driverAlertCreatedEvent from '../../events/created.event.driverAlert';

// driverAlert lib
import isPayoutAlert from '../../lib/isPayoutAlert.lib.driverAlert';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// lib
import fkOrId from '@matthahn/sally-fw/lib/lib/fkOrId';

// payout actions
import {show as showPayoutAct} from '../../../payout/containers/PayoutDriverContainer/redux/actions';

class DriverAlertsContainer extends Component {
  static propTypes = {
    activeRental: PropTypes.object,
    children: PropTypes.func,
    csr: PropTypes.object,
    dispatch: PropTypes.func,
    driver: PropTypes.object.isRequired,
    driverAlerts: PropTypes.array.isRequired,
    headless: PropTypes.bool,
    loadingCsr: PropTypes.bool,
    subscribe: PropTypes.func,
    user: PropTypes.string.isRequired,
  };

  state = {
    resolving: [],
  };

  componentDidMount() {
    this.init();
    this.props.subscribe(driverAlertCreatedEvent.subscribe(this.alertCreated));
  }

  init = () => {
    const {driver} = this.props;
    if (!driver || !driver.alerts) return;
    this.setState({alerts: [...driver.alerts]});
  };

  alertCreated = (driverAlert) => {
    const {driver, driverAlerts, dispatch} = this.props;
    if (driver.id !== fkOrId(driverAlert?.driver)) return;
    dispatch(setAction({driverAlerts: [...driverAlerts, driverAlert]}));
  };

  execute = (alert) => () => {
    if (alert.isCreditRequest) this.showCreditResolve(alert);
    else this.resolve(alert.id);
  };

  onCreditResolved = (driver) => {
    const alerts = [...driver.alerts];

    this.props.dispatch(setAction({customer: driver}));
    this.setState({alerts, showCreditResolve: false});
  };

  resolve = async (id, prompt = true) => {
    const {driver, dispatch} = this.props;
    const {resolving} = this.state;

    if (resolving.includes(id)) return;

    if (prompt)
      return notify({
        id: 'resolveDriverAlert',
        title: 'Confirm',
        icon: undefined,
        content: 'Are you sure you want to resolve this alert?',
        primary: {
          label: 'No',
          onClick: () => null,
        },
        secondary: {
          label: 'Yes',
          onClick: () => this.resolve(id, false),
        },
        closable: false,
        closeOnOutsideClick: true,
      });

    this.setState({resolving: [...resolving, id]});
    const removeResolving = (alertID) =>
      [...this.state.resolving].filter((aID) => aID !== alertID);

    try {
      await updateAlertApi(driver.id, id, {
        resolved: true,
      });

      const driverAlerts = [...this.props.driverAlerts].filter(
        (a) => a.id !== id
      );

      dispatch(setAction({driverAlerts}));
      this.setState({resolving: removeResolving(id)});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({resolving: removeResolving(id)});
    }
  };

  payout = (alert) => () => {
    const {activeRental, driver, dispatch} = this.props;
    const isPayout = isPayoutAlert(alert);
    if (!isPayout) return;
    dispatch(showPayoutAct({driver, activeRental, endRental: false}));
  };

  alerts = () => {
    const {csr, driverAlerts} = this.props;
    return [
      !!csr && {
        id: 'csr',
        error: true,
        actionLabel: 'Sign',
        onAction: () => {
          showChangeOrderActionsEvent.publish({csr});
        },
        resolving: false,
        message: 'Contract signature required',
      },
      ...[...driverAlerts].map((alert) => {
        const isPayout = isPayoutAlert(alert);
        const actionLabel = isPayout ? 'Payout' : 'Resolve';
        const onAction = isPayout
          ? this.payout(alert)
          : () => this.resolve(alert.id);
        const resolving = this.state.resolving.includes(alert.id);
        return {...alert, isPayout, actionLabel, onAction, resolving};
      }),
    ].filter((alert) => !!alert);
  };

  render() {
    const {driver, headless, children} = this.props;
    const {resolving} = this.state;
    const alerts = this.alerts();
    return children({
      dom:
        !driver || !alerts?.length ? null : (
          <DriverAlerts
            alerts={this.alerts()}
            headless={headless}
            resolving={resolving}
            onResolve={this.execute}
          />
        ),
    });
  }
}

export default connect((state) => ({
  activeRental: state.driver.activeRental,
  csr: state.driver.csr,
  driver: state.driver.driver,
  driverAlerts: state.driver.driverAlerts,
  loadingCsr: state.driver.loadingCsr,
  user: state.auth.username,
}))(subscriptionHOC(DriverAlertsContainer));
