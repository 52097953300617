import {fileName, folder} from '../../../document';

// Attributes
import {filename} from '../../../document/attributes';

export default folder({
  attributes: [filename],
  defaultFileName: 'Other',
  // Custom
  fileName: fileName(({filename}) => filename.display.format()),
  folderName: 'Other',
  type: 'other',
});
