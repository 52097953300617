import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal, Row, Column, Input, Text} from '@matthahn/sally-ui';

// Types
import {dateTime, amount} from '../../../types';

const ReassignTollToEmployeeModal = ({
  visible,
  canAssign,
  loading,
  toll,
  onEmployee,
  onClose,
  onAssign,
  employeeName,
}) => (
  <Modal
    title="Reassign Toll to employee"
    icon="team"
    visible={visible}
    onClose={onClose}
    buttonsRight={[
      {label: 'Assign', loading, onClick: onAssign, theme: 'orange'},
    ]}
    size="large"
  >
    {(Content) => (
      <Content>
        <Row margin />
        <Row margin>
          <Column size={1 / 2} offset={1 / 4}>
            <Input
              type="text"
              value={employeeName}
              onChange={onEmployee}
              placeholder="Employee Name"
              size="large"
              autoCapitalize="none"
            />
          </Column>
        </Row>
        <Row margin />
        {!!toll && (
          <Fragment>
            <Row margin>
              <Column size={1 / 3}>
                <Text value={dateTime(toll.transaction_date).format()}>
                  Date
                </Text>
              </Column>
              <Column size={1 / 3}>
                <Text value={toll.vehicle_medallion || '-'}>Medallion</Text>
              </Column>
              <Column size={1 / 3}>
                <Text value={toll.vehicle_plate || '-'}>License Plate</Text>
              </Column>
            </Row>
            <Row>
              <Column size={1 / 3}>
                <Text value={toll.plate}>Tag ID</Text>
              </Column>
              <Column size={1 / 3}>
                <Text value={`$${amount(toll.amount).format()}`}>Amount</Text>
              </Column>
              <Column size={1 / 3}>
                <Text value={toll.agency}>Agency</Text>
              </Column>
            </Row>
          </Fragment>
        )}
      </Content>
    )}
  </Modal>
);

ReassignTollToEmployeeModal.propTypes = {
  visible: PropTypes.bool,
  canAssign: PropTypes.bool,
  loading: PropTypes.bool,
  toll: PropTypes.object,
  onEmployee: PropTypes.func,
  onClose: PropTypes.func,
  onAssign: PropTypes.func,
};

export default ReassignTollToEmployeeModal;

