import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal} from '@matthahn/sally-ui';
import SmallLoader from '../SmallLoader/SmallLoader';

import Center from './components/Center';
import Wrapper from './components/Wrapper';

// Containers
import DocumentsContainer from '../../../document/containers/DocumentsContainer/DocumentsContainer';

class AttributeFileUploader extends Component {
  static propTypes = {
    loader: PropTypes.bool,
    accident: PropTypes.object,
    driver: PropTypes.object,
    medallion: PropTypes.object,
    vehicle: PropTypes.object,
    rental: PropTypes.object,
    title: PropTypes.string,
    type: PropTypes.string,
    folderType: PropTypes.string,
    visible: PropTypes.bool,
    onDone: PropTypes.func,
    onClose: PropTypes.func,
  };

  static defaultProps = {
    loader: false,
    title: 'File Upload',
    accident: null,
    driver: null,
    medallion: null,
    vehicle: null,
    rental: null,
  };

  state = {
    loading: false,
  };

  onLoad = (loading) => this.setState({loading});

  onClose = () => {
    if (this.state.loading) return;
    this.props.onClose();
  };

  render() {
    const {
      loader,
      accident,
      driver,
      medallion,
      vehicle,
      rental,
      title,
      type,
      folderType,
      visible,
      onDone,
    } = this.props;
    return (
      <Modal
        title={title}
        icon="addfile"
        visible={visible}
        onClose={this.onClose}
        size="large"
      >
        {(Content) => (
          <Content padding="none">
            <Wrapper>
              {loader || !visible ? (
                <Center>
                  <SmallLoader />
                </Center>
              ) : (
                <DocumentsContainer
                  type={type}
                  folderType={folderType}
                  accident={accident}
                  driver={driver}
                  medallion={medallion}
                  vehicle={vehicle}
                  rental={rental}
                  onDone={onDone}
                  onLoad={this.onLoad}
                  uploadMode
                  clearFileOnBack={!!folderType}
                  staticSelection
                  small
                />
              )}
            </Wrapper>
          </Content>
        )}
      </Modal>
    );
  }
}

export default AttributeFileUploader;
