import {dateTime, fileName, folder} from '../../../document';

export default folder({
  defaultFileName: 'Repair Image',
  // SVID - DATE - REPAIR IMAGE
  fileName: fileName(
    ({vehicle}) =>
      `${
        vehicle?.medallion?.medallion_number || vehicle.vin
      } - ${dateTime()} - REPAIR IMAGE`
  ),
  folderName: 'Repair',
  type: 'repairImage',
  uploadable: false,
});
