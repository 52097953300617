import prepare from '../../attribute/prepare.attribute';

export default async (attributes) => {
  const accident = prepare(attributes, {
    field: 'api',
    required: [
      'datetime_of_loss',
      'description',
      'appointment_time',
      'state',
      'driver',
      'medallion',
      'vehicle',
      'rental',
    ],
  });
  return accident;
};
