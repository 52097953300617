import React from 'react';
import PropTypes from 'prop-types';

// Attributes
import addressStreet1Attr from '../../attributes/address_street1.attribute.medallionOwner';
import addressStreet2Attr from '../../attributes/address_street2.attribute.medallionOwner';
import addressCityAttr from '../../attributes/address_city.attribute.medallionOwner';
import addressZipAttr from '../../attributes/address_zip.attribute.medallionOwner';
import addressStateAttr from '../../attributes/address_state.attribute.medallionOwner';

// Components
import {Card, Row, Column} from '@matthahn/sally-ui';
import AttributeText from '../../../layout/components/AttributeText/AttributeText';

const AddressInfo = ({
  address_street1,
  address_street2,
  address_city,
  address_zip,
  address_state,
  onEdit,
}) => (
  <Card
    title="Address"
    icon="home"
    headerActions={[
      {
        icon: 'edit',
        onClick: onEdit,
        tooltip: 'Edit',
      },
    ]}
  >
    {(Content) => (
      <Content>
        <Row margin>
          <Column>
            <AttributeText attribute={addressStreet1Attr(address_street1)}>
              {addressStreet1Attr.label.default}
            </AttributeText>
          </Column>
        </Row>
        <Row margin>
          <Column size={1 / 2}>
            <AttributeText attribute={addressStreet2Attr(address_street2)}>
              {addressStreet2Attr.label.default}
            </AttributeText>
          </Column>
          <Column size={1 / 2}>
            <AttributeText attribute={addressCityAttr(address_city)}>
              {addressCityAttr.label.default}
            </AttributeText>
          </Column>
        </Row>
        <Row>
          <Column size={1 / 2}>
            <AttributeText attribute={addressZipAttr(address_zip)}>
              {addressZipAttr.label.default}
            </AttributeText>
          </Column>
          <Column size={1 / 2}>
            <AttributeText attribute={addressStateAttr(address_state)}>
              {addressStateAttr.label.default}
            </AttributeText>
          </Column>
        </Row>
      </Content>
    )}
  </Card>
);

AddressInfo.propTypes = {
  address_street1: PropTypes.string,
  address_street2: PropTypes.string,
  address_city: PropTypes.string,
  address_zip: PropTypes.string,
  address_state: PropTypes.string,
  onEdit: PropTypes.func,
};

export default AddressInfo;
