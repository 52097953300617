import {DATE} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {date, apiDate} from '../../types';

export default attribute({
  type: DATE,
  attribute: 'registration_expiry_confirm',
  display: date,
  input: date,
  api: apiDate,
  label: {
    default: 'Confirm Registration Expiry',
    short: 'Confirm Expiry',
  },
  disabledDates: [{before: new Date()}],
});
