import styled from 'styled-components';

// Logo
import ActualLogo from '../../../../layout/components/Logo/Logo';

const Logo = styled(ActualLogo)`
  width: 100%;
  display: block;
  margin: auto;
  margin-bottom: 40px;
`;

export default Logo;
