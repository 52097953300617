import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

// Events
import event from '../events/created.event.lease';

export default async (lease) => {
  const response = await f3tch('/dispatch/leases/')
    .profile(profile())
    .body(lease)
    .post();
  event.pub(response);
  return response;
};
