import styled from 'styled-components';

// Libs
import {lib} from '@matthahn/sally-ui';

// Helpers
const {
  colors: {darkGrey},
} = lib;

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 180px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 60px;
  color: ${darkGrey};
`;

export default Container;
