import f3tch from 'f3tch';

// Events
import event from '../events/vehicleReassigned.event.toll';

// Profile
import profile from '../../api/profiles/json.profile.api';

export default async ({toll = null, vehicle = null} = {}) => {
  const response = await f3tch('/tolls/assign_toll_to_vehicle/')
    .profile(profile())
    .body({toll, vehicle})
    .post();
  event.pub(response);
  return response;
};
