import React from 'react';

// Containers
import Page from '../../../layout/containers/Page/Page';
import DriversPagesContainer from '../../containers/DriversPagesContainer/DriversPagesContainer';

// Page
import PAGE from './page';

const DriversPage = () => (
  <Page page={PAGE}>
    <DriversPagesContainer />
  </Page>
);

export default DriversPage;
