import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/noData.profile.api';

export default async (insuranceId, vehicle_ids) =>
  f3tch(`/vehicles/insurance/${insuranceId}/apply/`)
    .profile(profile())
    .body({vehicle_ids})
    .patch();
