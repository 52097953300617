import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {show as showExternalDevicesAct} from '../../../../externalDevice/redux/actions';

// Components
import {Signature, Row, Column, Button} from '@matthahn/sally-ui';

class RequiredSignature extends Component {
  static propTypes = {
    html: PropTypes.string,
    required: PropTypes.func.isRequired,
    onGeneration: PropTypes.func.isRequired,
    dispatch: PropTypes.func,
  };

  state = {
    generating: false,
    signature: null,
  };

  componentDidMount() {
    this.mounted = true;
    this.props.onGeneration({
      start: this.onGenerationStart,
      end: this.onGenerationEnd,
    });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onGenerationStart = () => {
    if (!this.mounted) return;
    this.setState({generating: true});
  };

  onGenerationEnd = () => {
    if (!this.mounted) return;
    this.setState({generating: false});
  };

  sign = (signature) => {
    if (this.state.generating) return;
    const done = !!signature;
    if (!this.mounted) return;
    this.setState({signature});
    this.props.required(done);
  };

  openOnDevice = () => {
    const {dispatch, html} = this.props;
    dispatch(
      showExternalDevicesAct({
        extension: 'contract',
        handler: this.handleExternalSignature,
        data: {html},
      })
    );
  };

  handleExternalSignature = ({signature}) => {
    this.setState({signature});
    this.props.required(true);
  };

  resetSignature = () => {
    this.setState({signature: null});
    this.props.required(false);
  };

  render() {
    const {generating, signature} = this.state;
    return (
      <Fragment>
        <Row margin={!generating}>
          <Column>
            <Signature
              image={generating}
              signature={signature}
              onSign={this.sign}
            />
          </Column>
        </Row>
        {!generating && (
          <Row>
            <Column>
              <Button theme="grey" icon="tablet1" onClick={this.openOnDevice}>
                Sign on tablet
              </Button>
            </Column>
          </Row>
        )}
      </Fragment>
    );
  }
}

export default connect()(RequiredSignature);
