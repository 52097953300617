import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Button} from '@matthahn/sally-ui';
import Container from './components/Container';
import Separator from './components/Separator';

const MedallionHeaderButtonSeparator = ({
  canBeStored,
  canBeEnabled,
  canBeUnmanaged,
  onStore,
  onEnable,
  onUnmanage,
  saving,
  onSave,
  hasSavePermission,
}) => (
  <Container>
    {canBeEnabled && (
      <Separator>
        <Button
          theme="grey"
          icon="check"
          size="small"
          onClick={onEnable}
          outline
        >
          Enable
        </Button>
      </Separator>
    )}
    {canBeStored && (
      <Separator>
        <Button
          theme="grey"
          icon="delete"
          size="small"
          onClick={onStore}
          outline
        >
          Archive
        </Button>
      </Separator>
    )}
    {canBeUnmanaged && (
      <Separator>
        <Button
          theme="grey"
          icon="delete"
          size="small"
          onClick={onUnmanage}
          outline
        >
          Unmanage
        </Button>
      </Separator>
    )}
    {hasSavePermission && (
      <Separator>
        <Button theme="orange" size="small" loading={saving} onClick={onSave}>
          Save
        </Button>
      </Separator>
    )}
  </Container>
);

MedallionHeaderButtonSeparator.propTypes = {
  canBePutOnHold: PropTypes.bool,
  hasSavePermission: PropTypes.bool,
  canBeStored: PropTypes.bool,
  canBeEnabled: PropTypes.bool,
  canBeUnmanaged: PropTypes.bool,
  saving: PropTypes.bool,
  onSave: PropTypes.func,
  onHold: PropTypes.func,
  onStore: PropTypes.func,
  onEnable: PropTypes.func,
  onUnmanage: PropTypes.func,
};

export default MedallionHeaderButtonSeparator;
