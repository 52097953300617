import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Components
import {Button} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';
import AttributeText from '../../../layout/components/AttributeText/AttributeText';
import Container from './components/Container';
import InputContainer from './components/InputContainer';
import ButtonContainer from './components/ButtonContainer';

class EndDatetime extends Component {
  static propTypes = {
    end_datetime: PropTypes.object,
    onEndDatetime: PropTypes.func,
  };

  state = {
    edit: false,
    end_datetime: this.props.end_datetime,
  };

  edit = () => {
    this.setState({edit: true, end_datetime: this.props.end_datetime});
  };

  cancel = () => {
    this.setState({edit: false});
  };

  change = (end_datetime) => {
    this.setState({end_datetime});
  };

  save = () => {
    this.props.onEndDatetime(this.state.end_datetime);
  };

  render() {
    const {end_datetime: end} = this.props;
    const {edit, end_datetime} = this.state;
    return (
      <Container>
        <InputContainer>
          {edit ? (
            <AttributeInput value={end_datetime} onChange={this.change}>
              End Date
            </AttributeInput>
          ) : (
            <AttributeText attribute={end}>End Date</AttributeText>
          )}
        </InputContainer>
        {edit && (
          <ButtonContainer>
            <Button
              theme="grey"
              size="small"
              onClick={this.cancel}
              icon="close"
            />
          </ButtonContainer>
        )}
        {edit && (
          <ButtonContainer>
            <Button
              theme="green"
              size="small"
              onClick={this.save}
              icon="check"
            />
          </ButtonContainer>
        )}
        {!edit && (
          <ButtonContainer>
            <Button theme="grey" size="small" onClick={this.edit} icon="edit" />
          </ButtonContainer>
        )}
      </Container>
    );
  }
}

export default EndDatetime;
