import styled from 'styled-components';

const Slide = styled.div`
  width: 100%;
  height: ${({fullScreen}) => (fullScreen ? '100vh' : '400px')};
  padding: ${({fullScreen}) => (fullScreen ? '60px' : '0px')};
  position: relative;
`;

export default Slide;
