// permission lib
import defaultPermissionValue from './defaultPermissionValue.lib.permission';

const generateUserPermissions = ({user, availablePermissions}) =>
  [...availablePermissions].reduce(
    (combined, permission) => ({
      ...combined,
      [permission.permission]: defaultPermissionValue({user, permission}),
    }),
    {}
  );

export default generateUserPermissions;
