import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {hide as hideAct} from './redux/actions';
import {show as showPutOnHoldAct} from '../../../vehicleHold/containers/PutVehicleOnHoldContainer/redux/actions';

// dispatch components
import DispatchAlerts from '../../components/DispatchAlerts/DispatchAlerts';

// driverAlert lib
import showCreateDriverAlertModal from '../../../driverAlert/lib/showCreateModal.lib.driverAlert';

// Steps
import DispatchMissingVehicleInfoStepContainer from '../../../vehicle/containers/DispatchMissingVehicleInfoStepContainer/DispatchMissingVehicleInfoStepContainer';
// import DispatchContractStepContainer from '../../../document/containers/DispatchContractStepContainer/DispatchContractStepContainer';
import DispatchLeaseStepContainer from '../../../lease/containers/DispatchLeaseStepContainer/DispatchLeaseStepContainer';
import DispatchDocumentsStepContainer from '../../../driver/containers/DispatchDocumentsStepContainer/DispatchDocumentsStepContainer';
import DispatchDriverStepContainer from '../../../driver/containers/DispatchDriverStepContainer/DispatchDriverStepContainer';
import DispatchMissingDriverInfoStepContainer from '../../../driver/containers/DispatchMissingDriverInfoStepContainer/DispatchMissingDriverInfoStepContainer';
import DispatchStepContainer from '../DispatchStepContainer/DispatchStepContainer';

// Libs
import isOnHold from '../../../vehicleHold/lib/isOnHold.lib.vehicleHold';

// Components
import {Flow} from '@matthahn/sally-ui';

// vehicleAlert lib
import showCreateVehicleAlertModal from '../../../vehicleAlert/lib/showCreateModal.lib.vehicleAlert';

class DispatchVehicleFlowContainer extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    dispatchVehiclesLoading: PropTypes.bool,
    vehicle: PropTypes.object,
    vehicles: PropTypes.array,
    onlyWeekly: PropTypes.bool,
    dispatch: PropTypes.func,
  };

  static INITIAL_STATE = {
    contract: null,
    csr: null,
    disabled: false,
    documents: [],
    done: 0,
    driver: null,
    driverAlerts: [],
    goingBack: false,
    hide: false,
    hotswap: false,
    lease: null,
    medallion: null,
    rental: null,
    step: 1,
    vehicleAlerts: [],
    visible: false,
  };

  state = {
    ...this.constructor.INITIAL_STATE,
  };

  componentDidMount() {
    if (this.props.visible) this.init();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) this.init();
  }

  init = () => {
    const {vehicle} = this.props;
    this.setState({
      ...this.constructor.INITIAL_STATE,
      visible: true,
      medallion: {...vehicle.medallion, vehicle},
    });
  };

  onStep = (step, ignoreCheck = false) => {
    const {disabled, done, step: currentStep} = this.state;
    const goingBack = step < currentStep;
    if (!ignoreCheck && disabled) return;
    const newDone = step - 1 > done ? step - 1 : done;
    this.setState({step, done: newDone, goingBack});
  };

  onStepChange = (step) => this.onStep(step, true);

  onStepReset = (step) => () => this.setState({step, done: step - 1});

  onDone = (step) => () => this.onStep(true)(step);

  onStepComplete =
    (step) =>
    (data = {}) => {
      const {disabled} = this.state;
      if (disabled) return;
      const {vehicle, ...otherData} = data;
      this.setState({...otherData});
      this.onStep(step);
    };

  close = (ignoreDisabled = false) => {
    const {visible, dispatch} = this.props;
    const {disabled} = this.state;
    if (!visible || (disabled && !ignoreDisabled)) return;
    this.setState({visible: false});
    dispatch(hideAct());
  };

  disable = (disabled) => this.setState({disabled});

  finish = () => this.close(true);

  hide = () => this.setState({hide: true});

  show = () => this.setState({hide: false});

  createVehicleAlert = () => {
    const {vehicle} = this.props;
    showCreateVehicleAlertModal({vehicle});
  };

  createDriverAlert = () => {
    const {driver} = this.state;
    showCreateDriverAlertModal({driver});
  };

  putOnHold = () => {
    const {vehicle, dispatch} = this.props;
    const {disabled} = this.state;
    if (!vehicle || disabled) return;
    this.close(true);
    dispatch(showPutOnHoldAct(vehicle));
  };

  subtitle = () => {
    const {medallion, driver} = this.state;
    return [
      'Dispatch',
      !!driver && `${driver.first_name} ${driver.last_name}`,
      !!medallion && medallion.medallion_number,
    ]
      .filter((st) => !!st)
      .join(' - ');
  };

  flows = () => [
    {title: 'Vehicle Info'},
    {title: 'Driver'},
    {title: 'Documents'},
    {title: 'Info'},
    {title: 'Lease'},
    // {title: 'Contract'},
    {title: 'Dispatch'},
  ];

  content = () => (Content) => {
    const {vehicle, vehicles, dispatchVehiclesLoading, onlyWeekly} = this.props;
    const {
      contract,
      csr,
      documents,
      driver,
      goingBack,
      hotswap,
      lease,
      medallion,
      rental,
      step,
      visible,
    } = this.state;

    const props = {
      Container: Content,
      csr,
      documents,
      vehicle,
      driver,
      lease,
      rental,
      onlyWeekly,
      medallion,
      contract,
      hotswap,
      goingBack,
      visible,
      dispatchVehiclesLoading,
      onShow: this.show,
      onHide: this.hide,
      onDisabled: this.disable,
      onClose: this.close,
      onCreateDriverAlert: this.createDriverAlert,
      onCreateVehicleAlert: this.createVehicleAlert,
    };

    switch (step) {
      case 1:
        return (
          <DispatchMissingVehicleInfoStepContainer
            onNext={this.onStepComplete(2)}
            {...props}
          />
        );
      case 2:
        return (
          <Fragment>
            <DispatchAlerts vehicle={vehicle} />
            <DispatchDriverStepContainer
              onNext={this.onStepComplete(3)}
              onReset={this.onStepReset(2)}
              vehicles={vehicles}
              {...props}
            />
          </Fragment>
        );
      case 3:
        return (
          <Fragment>
            <DispatchAlerts
              driver={driver}
              vehicle={vehicle}
              csr={csr}
              displayCsr
              onCloseModal={this.close}
            />
            <DispatchDocumentsStepContainer
              onBack={this.onStepComplete(2)}
              onNext={this.onStepComplete(4)}
              {...props}
            />
          </Fragment>
        );
      case 4:
        return (
          <Fragment>
            <DispatchAlerts
              driver={driver}
              vehicle={vehicle}
              csr={csr}
              displayCsr
              onCloseModal={this.close}
            />
            <DispatchMissingDriverInfoStepContainer
              onBack={this.onStepComplete(3)}
              onNext={this.onStepComplete(5)}
              {...props}
            />
          </Fragment>
        );
      case 5:
        return (
          <Fragment>
            <DispatchAlerts
              driver={driver}
              vehicle={vehicle}
              csr={csr}
              displayCsr
              onCloseModal={this.close}
            />
            <DispatchLeaseStepContainer
              onBack={this.onStepComplete(4)}
              onNext={this.onStepComplete(6)}
              {...props}
            />
          </Fragment>
        );
      // case 6:
      //   return (
      //     <DispatchContractStepContainer
      //       onBack={this.onStepComplete(5)}
      //       onNext={this.onStepComplete(7)}
      //       {...props}
      //     />
      //   );
      case 6:
        return (
          <Fragment>
            <DispatchAlerts
              driver={driver}
              vehicle={vehicle}
              csr={csr}
              displayCsr
              onCloseModal={this.close}
            />
            <DispatchStepContainer
              onBack={this.onStepComplete(5)}
              onComplete={this.finish}
              {...props}
            />
          </Fragment>
        );
      default:
        return <Content>You even know what you're doing here?</Content>;
    }
  };

  render() {
    const {vehicle} = this.props;
    const {visible, step, done, hide, disabled, driver} = this.state;
    const flows = this.flows();
    const flow = flows[step - 1];
    return (
      <Flow
        flows={flows}
        title={flow.title}
        subtitle={this.subtitle()}
        visible={visible && !hide}
        onClose={this.close}
        step={step}
        done={done}
        size={flow.size || 'medium'}
        theme="orange"
        onStep={this.onStepChange}
        headerActions={[
          !!driver && {
            tooltip: 'Driver alert',
            icon: 'user',
            onClick: this.createDriverAlert,
            disabled,
          },
          !!vehicle && {
            tooltip: 'Vehicle alert',
            icon: 'car',
            onClick: this.createVehicleAlert,
            disabled,
          },
          !!vehicle &&
            !isOnHold(vehicle.vehicle_hold) && {
              tooltip: 'Put on hold',
              icon: 'warning',
              onClick: this.putOnHold,
              disabled,
            },
        ].filter((action) => !!action)}
      >
        {this.content()}
      </Flow>
    );
  }
}

export default connect((state) => ({
  ...state.dispatchVehicleFlow,
  dispatchVehiclesLoading: state.dispatcher.loading,
  vehicles: state.dispatcher.vehicles,
}))(DispatchVehicleFlowContainer);
