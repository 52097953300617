import React from 'react';

// Components
import {Row, Column} from '@matthahn/sally-ui';
import SubPage from '../../../layout/components/SubPage/SubPage';

// Containers
import VehicleNotesContainer from '../../../vehicleNote/containers/VehicleNotesContainer/VehicleNotesContainer';

const VehicleNotesPage = () => (
  <SubPage>
    <Row>
      <Column size={2 / 3} offset={1 / 6}>
        <VehicleNotesContainer />
      </Column>
    </Row>
  </SubPage>
);

export default VehicleNotesPage;
