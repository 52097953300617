import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Containers
import RentalContainer from '../../../rental/containers/RentalContainer/RentalContainer';

class DriverRentalPage extends Component {
  static propTypes = {
    match: PropTypes.object,
  };

  render() {
    const {
      match: {
        params: {rentalID},
      },
    } = this.props;
    return <RentalContainer id={rentalID} />;
  }
}

export default DriverRentalPage;
