import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {string, stringNumber} from '../../types';

export default attribute({
  type: TEXT,
  attribute: 'check_number',
  display: stringNumber,
  input: stringNumber,
  api: string,
  label: {
    default: 'Check Number',
    short: 'Check Number',
  },
});
