import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

export default attribute({
  type: TEXT,
  attribute: 'bank_routing_number',
  label: {
    default: 'Bank Routing Number',
    short: 'Bank Routing Number',
  },
});
