import {fileName, folder, date} from '../../../document';

// Attributes
// import {plate as plateAttr} from '../../../vehicle/attributes';

// Errors
// import {plateMissmatch} from '../../../vehicle/errors';

export default folder({
  // attributes: [plateAttr],
  // fileAttributes: ['plate'],
  defaultFileName: 'Video',
  // VIDEO - DRIVER ID - DATE OF LOSS
  fileName: fileName(
    ({accident}) =>
      `VIDEO - ${accident.driver?.first_name}_${accident.driver?.last_name} - ${
        accident?.vehicle?.svid
      } - ${date()}`
  ),
  // preSaveValidation: ({plate, accident}) => {
  //   if (accident?.vehicle?.plate !== plate.api.format())
  //     throw plateMissmatch({
  //       message: 'Plates need to match',
  //     });
  // },
  folderName: 'Videos',
  type: 'accidentVideo',
});
