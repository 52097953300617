// Lib
import isRentable from './isRentable.lib.dispatch';
import isOnHold from '../../vehicleHold/lib/isOnHold.lib.vehicleHold';

const displayCondition = ({vehicle, display}) =>
  display === 'all'
    ? true
    : display === 'available'
    ? isRentable(vehicle) &&
      (!vehicle.active_rentals || !vehicle.active_rentals.length)
    : display === 'dispatched'
    ? !!vehicle.active_rentals && !!vehicle.active_rentals.length
    : display === 'hold'
    ? isOnHold(vehicle.vehicle_hold)
    : false;

const isInSearch = ({vehicle, word}) => {
  const searchFields = [
    !!vehicle.medallion && vehicle.medallion.medallion_number,
    vehicle.vehicle_year,
    vehicle.vehicle_model,
  ];
  const rentalFields = !vehicle.active_rentals
    ? []
    : [...vehicle.active_rentals]
        .map((active_rental) => [
          `${active_rental.driver.first_name} ${active_rental.driver.last_name}`,
          active_rental.driver.fhv_license_number,
          active_rental.lease_agreement.contract_type,
        ])
        .flat();
  return [...searchFields, ...rentalFields]
    .filter((query) => !!query)
    .some((query) => `${query}`.toLowerCase().includes(word));
};

const searchCondition = ({vehicle, words}) =>
  [...words].every((word) => isInSearch({vehicle, word}));

export default ({search = '', display = 'all', vehicles = []} = {}) => {
  if (!search.trim().length)
    return display !== 'all'
      ? [...vehicles].filter((vehicle) => displayCondition({vehicle, display}))
      : vehicles;
  return search
    .toLowerCase()
    .split(',')
    .map((query) => query.split(' ').filter((src) => !!src))
    .filter((words) => !!words.length)
    .reduce(
      (combined, words) => [
        ...combined,
        ...vehicles.filter(
          (vehicle) =>
            ![...combined].find(
              (filteredVehicle) => filteredVehicle.id === vehicle.id
            ) &&
            displayCondition({vehicle, display}) &&
            searchCondition({vehicle, words})
        ),
      ],
      []
    );
};
