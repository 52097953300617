import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal, Form, Row, Column, Uploader, FrameDoc} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';
import AttributeText from '../../../layout/components/AttributeText/AttributeText';
import FileContainer from './components/FileContainer';

import https from '../../../lib/https';

const NewTicketModal = ({
  visible,
  loading,
  onClose,
  onChange,
  onSave,

  image,
  amount_due,
  due_datetime,
  fine_amount,
  interest_amount,
  payment_amount,
  penalty_amount,
  plate,
  reduction_amount,
  state,
  summons_number,
  violation_datetime,
  violation,
}) => (
  <Modal
    visible={visible}
    title="New Ticket"
    icon="tag"
    size="large"
    onClose={onClose}
    buttonsRight={[{label: 'Save', onClick: onSave, loading, theme: 'orange'}]}
  >
    {(Content) => (
      <Content>
        <Form onSubmit={onSave} enter>
          <Row margin>
            <Column size={1 / 2}>
              <AttributeInput
                value={violation_datetime}
                step={1}
                onChange={onChange}
                disabled={loading}
              >
                {violation_datetime.label.default}
              </AttributeInput>
            </Column>
            <Column size={1 / 2}>
              <AttributeInput
                value={due_datetime}
                onChange={onChange}
                disabled={loading}
              >
                {due_datetime.label.default}
              </AttributeInput>
            </Column>
          </Row>
          <Row margin>
            <Column>
              <AttributeInput
                value={violation}
                onChange={onChange}
                disabled={loading}
              >
                {violation.label.default}
              </AttributeInput>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 3}>
              <AttributeInput
                value={plate}
                onChange={onChange}
                disabled={loading}
              >
                {plate.label.default}
              </AttributeInput>
            </Column>
            <Column size={1 / 3}>
              <AttributeInput
                value={state}
                onChange={onChange}
                disabled={loading}
              >
                {state.label.default}
              </AttributeInput>
            </Column>
            <Column size={1 / 3}>
              <AttributeInput
                value={summons_number}
                onChange={onChange}
                disabled={loading}
              >
                {summons_number.label.default}
              </AttributeInput>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 3}>
              <AttributeInput
                value={fine_amount}
                onChange={onChange}
                disabled={loading}
              >
                {fine_amount.label.default}
              </AttributeInput>
            </Column>
            <Column size={1 / 3}>
              <AttributeInput
                value={interest_amount}
                onChange={onChange}
                disabled={loading}
              >
                {interest_amount.label.default}
              </AttributeInput>
            </Column>
            <Column size={1 / 3}>
              <AttributeInput
                value={penalty_amount}
                onChange={onChange}
                disabled={loading}
              >
                {penalty_amount.label.default}
              </AttributeInput>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 3}>
              <AttributeInput
                value={reduction_amount}
                onChange={onChange}
                disabled={loading}
              >
                {reduction_amount.label.default}
              </AttributeInput>
            </Column>
            <Column size={1 / 3}>
              <AttributeInput
                value={payment_amount}
                onChange={onChange}
                disabled={loading}
              >
                {payment_amount.label.default}
              </AttributeInput>
            </Column>
            <Column size={1 / 3}>
              <AttributeText
                attribute={amount_due}
              >
                {amount_due.label.default}
              </AttributeText>
            </Column>
          </Row>
          <Row>
            <Column size={1 / 2}>
              <FileContainer>
                <Uploader
                  loading={loading}
                  onChange={([file]) => onChange(file, 'image')}
                  small
                />
              </FileContainer>
            </Column>
            {!!image && (
              <Column size={1 / 2}>
                <FileContainer>
                  <FrameDoc
                    file={https(image.preview)}
                    fullscreenable
                  />
                </FileContainer>
              </Column>
            )}
          </Row>
        </Form>
      </Content>
    )}
  </Modal>
);

NewTicketModal.propTypes = {
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  onSave: PropTypes.func,

  image: PropTypes.object,
  amount_due: PropTypes.object,
  due_datetime: PropTypes.object,
  fine_amount: PropTypes.object,
  interest_amount: PropTypes.object,
  payment_amount: PropTypes.object,
  penalty_amount: PropTypes.object,
  plate: PropTypes.object,
  reduction_amount: PropTypes.object,
  state: PropTypes.object,
  summons_number: PropTypes.object,
  violation_datetime: PropTypes.object,
  violation: PropTypes.object,
};

export default NewTicketModal;
