import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal, Row, Column, Text} from '@matthahn/sally-ui';

// Types
import {amount} from '../../../types';

const RentalReportPreviewModal = ({
  downloadingReport,
  onStatementDownload,
  visible,
  rentalReport,
  onClose,
}) => (
  <Modal
    title="Rental Report"
    visible={visible}
    onClose={onClose}
    headerActions={[
      {
        icon: 'download',
        tooltip: 'Download Statement',
        onClick: onStatementDownload,
        loading: downloadingReport,
      },
    ]}
  >
    {(Content) =>
      !rentalReport ? null : (
        <Content>
          <Row margin>
            <Column size={1 / 2}>
              <Text
                value={`${rentalReport.driver.first_name} ${rentalReport.driver.last_name}`}
              >
                Driver
              </Text>
            </Column>
            <Column size={1 / 2}>
              <Text
                value={`${
                  !!rentalReport.medallion &&
                  !!rentalReport.medallion.medallion_number
                    ? rentalReport.medallion.medallion_number
                    : ''
                } - ${rentalReport.vehicle.plate}`}
              >
                Vehicle
              </Text>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 2}>
              <Text value={amount(rentalReport.miles_serviced).format()}>
                Mileage
              </Text>
            </Column>
            <Column size={1 / 2}>
              <Text value={rentalReport.total_trips}>Number of trips</Text>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 2}>
              <Text value={`$${amount(rentalReport.total_extras).format()}`}>
                Extras
              </Text>
            </Column>
            <Column size={1 / 2}>
              <Text
                value={`$${amount(
                  rentalReport.total_congenstion_surcharge
                ).format()}`}
              >
                Congestion Surcharge
              </Text>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 2}>
              <Text value={`$${amount(rentalReport.total_tolls).format()}`}>
                Tolls
              </Text>
            </Column>
            <Column size={1 / 2}>
              <Text value={`$${amount(rentalReport.total_tips).format()}`}>
                Tips
              </Text>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 2}>
              <Text value={`$${amount(rentalReport.total_cash).format()}`}>
                Cash
              </Text>
            </Column>
            <Column size={1 / 2}>
              <Text value={`$${amount(rentalReport.total_cc).format()}`}>
                Credit Card
              </Text>
            </Column>
          </Row>
          <Row>
            <Column>
              <Text value={`$${amount(rentalReport.total_amount).format()}`}>
                Total
              </Text>
            </Column>
          </Row>
        </Content>
      )
    }
  </Modal>
);

RentalReportPreviewModal.propTypes = {
  visible: PropTypes.bool,
  downloadingReport: PropTypes.bool,
  rentalReport: PropTypes.object,
  onClose: PropTypes.func,
  onStatementDownload: PropTypes.func,
};

export default RentalReportPreviewModal;
