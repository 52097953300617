import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal, Row, Column, Form} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

const PutVehicleOnHoldModal = ({
  date_etc,
  hold_type,
  loading,
  onChange,
  onClose,
  onSave,
  reason,
  vehicle,
  visible,
}) => (
  <Modal
    visible={visible}
    icon="warning"
    title="Put on Hold"
    subtitle={
      !!vehicle && !!vehicle.medallion && !!vehicle.medallion.medallion_number
        ? vehicle.medallion.medallion_number
        : '-'
    }
    onClose={onClose}
    buttonsRight={[
      {label: 'Put on hold', loading, onClick: onSave, theme: 'orange'},
    ]}
    size="small"
  >
    {(Content) => (
      <Content>
        <Form onSubmit={onSave} enter>
          <Row margin>
            <Column>
              <AttributeInput
                value={hold_type}
                onChange={onChange}
                disabled={loading}
                notFilterable
              >
                {hold_type.label.default}
              </AttributeInput>
            </Column>
          </Row>
          <Row margin>
            <Column>
              <AttributeInput
                value={date_etc}
                onChange={onChange}
                disabled={loading}
              >
                {date_etc.label.default}
              </AttributeInput>
            </Column>
          </Row>
          <Row>
            <Column>
              <AttributeInput
                value={reason}
                onChange={onChange}
                disabled={loading}
              >
                {reason.label.default}
              </AttributeInput>
            </Column>
          </Row>
        </Form>
      </Content>
    )}
  </Modal>
);

PutVehicleOnHoldModal.propTypes = {
  date_etc: PropTypes.object,
  hold_type: PropTypes.object,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  reason: PropTypes.object,
  vehicle: PropTypes.object,
  visible: PropTypes.bool,
};

export default PutVehicleOnHoldModal;
