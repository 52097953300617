import {TEXTAREA} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

export default attribute({
  type: TEXTAREA,
  attribute: 'notes',
  label: {
    default: 'Notes',
    short: 'Notes',
  },
});
