// Lib
import sum from '../../lib/sum';
import isCreditTransaction from './isCreditTransaction.lib.payout';

export default (transactions, returnCredit = 0) =>
  sum(
    returnCredit,
    [...transactions]
      .filter(isCreditTransaction)
      .reduce((combined, {balance}) => sum(combined, balance), 0)
  );
