// List
import list from './list.attribute';

// Fields
import fields from './fields.attribute';

// Error
import {requiredError} from './error.attribute';

export const missing = (attributes, required) => {
  const pure = list(attributes).map((attribute) => attribute.attribute);
  const missingAttributes = required.filter((req) => !pure.includes(req));
  if (missingAttributes.length === 0) return;
  throw requiredError({
    fields: missingAttributes,
    names: missingAttributes,
    message: `The following data is missing: ${missingAttributes.join(', ')}`,
  });
};

export const validate = (attributes, required, field) => {
  const errors = list(attributes)
    .map((attribute) =>
      required.includes(attribute.attribute) && !attribute[field].required()
        ? attribute
        : null
    )
    .filter((err) => err !== null);

  if (!errors.length) return;

  const errorData = errors.reduce(
    (combined, attribute) => ({
      fields: [...combined.fields, attribute.attribute],
      names: [...combined.names, attribute.label.default],
      message: `${combined.message}${combined.fields.length > 0 ? ',' : ''} ${
        attribute.label.default
      }${attribute.errorMessage ? ` (${attribute.errorMessage})` : ''}`,
    }),
    {
      fields: [],
      names: [],
      message: 'The following is required:',
    }
  );
  throw requiredError(errorData);
};

export const format = (attributes, required, field) =>
  list(attributes).filter((attribute) => attribute[field].required());

export default (attributes, {required = [], field = null} = {}) => {
  fields(field);
  missing(attributes, required);
  validate(attributes, required, field);
  return format(attributes, required, field);
};
