import isFunction from 'is-function';

import {SET} from './constants';

// Constants
import MESSAGE_TIMEOUT from '../constants/messageTimeout.constant.externalDevice';

export const set = (data = {}) => ({
  type: SET,
  data,
});

export const show = ({
  extension = null,
  handler = null,
  data = null,
  timeout = MESSAGE_TIMEOUT,
} = {}) => {
  if (!extension || !handler || !isFunction(handler)) return;
  return set({visible: true, extension, handler, data, timeout});
};

export const hide = () =>
  set({
    visible: false,
    extension: null,
    handler: null,
  });
