import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Api
import releaseApi from '../../api/release.api.vehicleHold';
import updateApi from '../../api/update.api.vehicleHold';

// Attributes
import dateEtcAttr from '../../attributes/date_etc.attribute.vehicleHold';
import holdTypeAttr from '../../attributes/hold_type.attribute.vehicleHold';
import reasonAttr from '../../attributes/reason.attribute.vehicleHold';
import vehicleAttr from '../../attributes/vehicle.attribute.vehicleHold';

// Actions
import {hide as hideModalAct} from './redux/actions';

// Components
import ReleaseVehicleFromHoldModal from '../../components/ReleaseVehicleFromHoldModal/ReleaseVehicleFromHoldModal';

// Lib
import {lib} from '@matthahn/sally-ui';
import parseError from '../../../error/parseError';
import fkOrId from '../../../lib/fkOrId';

// Permission
import releasePermission from '../../permissions/release.permission.vehicleHold';
import putOnHoldPermission from '../../permissions/putOnHold.permission.vehicleHold';

// Prep
import createPrep from '../../preparation/create.preparation.vehicleHold';

// Sockets
import putOnHoldSocket from '../../socket/putOnHold.socket.vehicleHold';
import removeFromHoldSocket from '../../socket/removeFromHold.socket.vehicleHold';

// Alert
const {alert} = lib;

class ReleaseVehicleFromHoldContainer extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    vehicle: PropTypes.object,
    hold: PropTypes.object,
    dispatch: PropTypes.func,
  };

  static INITIAL_STATE = {
    loading: false,
    saving: false,
    hold_type: holdTypeAttr(''),
    reason: reasonAttr(''),
    date_etc: dateEtcAttr(''),
  };

  state = {
    ...this.constructor.INITIAL_STATE,
  };

  componentDidMount() {
    this.subscribe();
    if (this.props.visible) this.init();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) this.init();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  events = [];

  subscribe = () => {
    this.events = [
      putOnHoldSocket.subscribe(
        this.socketFired('Vehicle was put on hold by somebody else')
      ),
      removeFromHoldSocket.subscribe(
        this.socketFired('Vehicle was made available again by somebody else')
      ),
    ];
  };

  unsubscribe = () => {
    this.events.forEach((unsub) => unsub());
  };

  init = () => {
    const {hold} = this.props;
    this.setState({
      ...this.constructor.INITIAL_STATE,
      hold_type: holdTypeAttr(hold?.hold_type || ''),
      reason: reasonAttr(hold?.reason || ''),
      date_etc: dateEtcAttr(hold?.date_etc || ''),
    });
  };

  socketFired = (message) => (hold) => {
    const {vehicle, visible, dispatch} = this.props;
    const {loading, saving} = this.state;
    if (
      loading ||
      saving ||
      !visible ||
      !vehicle ||
      vehicle.id !== fkOrId(hold.vehicle)
    )
      return;
    alert.info(message);
    dispatch(hideModalAct());
  };

  onClose = () => {
    if (this.state.loading || this.state.saving) return;
    this.props.dispatch(hideModalAct());
  };

  onChange = (val, key) => {
    if (this.state.loading || this.state.saving) return;
    this.setState({[key]: val});
  };

  onResolve = async () => {
    const {dispatch, hold} = this.props;
    const {loading, saving} = this.state;
    if (!releasePermission())
      return alert.warning('You do not have permissions to perform this.');
    if (loading || saving) return;

    this.setState({loading: true});

    try {
      await releaseApi(hold.id);
      alert.success('Vehicle is now available again');
      dispatch(hideModalAct());
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
    }

    this.setState({loading: false});
  };

  save = async () => {
    const {dispatch, hold, vehicle} = this.props;
    const {saving, loading, hold_type, reason, date_etc} = this.state;
    if (!putOnHoldPermission())
      return alert.warning('You do not have permissions to perform this.');
    if (saving || loading) return;

    this.setState({saving: true});

    try {
      const holdToUpdate = await createPrep({
        hold_type,
        reason,
        date_etc,
        vehicle: vehicleAttr(vehicle.id),
      });
      await updateApi(hold.id, holdToUpdate);
      alert.success('Saved successfully');
      dispatch(hideModalAct());
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
    }

    this.setState({saving: false});
  };

  render() {
    const {visible, vehicle} = this.props;
    const {loading, saving, hold_type, reason, date_etc} = this.state;
    return (
      <ReleaseVehicleFromHoldModal
        date_etc={date_etc}
        hold_type={hold_type}
        loading={loading}
        onChange={this.onChange}
        onClose={this.onClose}
        onResolve={this.onResolve}
        onSave={this.save}
        reason={reason}
        saving={saving}
        vehicle={vehicle}
        visible={visible}
      />
    );
  }
}

export default connect((state) => ({...state.releaseVehicleFromHold}))(
  ReleaseVehicleFromHoldContainer
);
