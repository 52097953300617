import prepare from '../../attribute/prepare.attribute';

export default async (attributes) =>
  prepare(attributes, {
    field: 'api',
    required: [
      'poc2',
      'poc2_address_street1',
      'poc2_address_zip',
      'poc2_address_city',
      'poc2_address_state',
      'poc2_phone_number',
      'poc2_email',
    ],
  });
