import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal, Textarea, Portal} from '@matthahn/sally-ui';

const CreateAlertModal = ({
  loading,
  message,
  objectType,
  onChange,
  onClose,
  onCreate,
  visible,
}) => (
  <Modal
    visible={visible}
    title={`New ${objectType} Alert`}
    icon="warning"
    onClose={onClose}
    buttonsRight={[
      {label: 'Create', loading, onClick: onCreate, theme: 'orange'},
    ]}
    portal={Portal.assetsContainer.secondary}
  >
    {(Content) => (
      <Content>
        <Textarea value={message} onChange={onChange} disabled={loading}>
          Alert
        </Textarea>
      </Content>
    )}
  </Modal>
);

CreateAlertModal.propTypes = {
  loading: PropTypes.bool,
  message: PropTypes.string,
  objectType: PropTypes.string,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onCreate: PropTypes.func,
  visible: PropTypes.bool,
};

export default CreateAlertModal;
