import prepare from '../../attribute/prepare.attribute';

export default async (attributes) =>
  prepare(attributes, {
    field: 'api',
    required: [
      'name',
      'taxx_id',
      'address_street1',
      'address_zip',
      'address_city',
      'address_state',
      'phone_number',
      'email',
    ],
  });
