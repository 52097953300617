import React from 'react';
import PropTypes from 'prop-types';

// local components
import {Card, Row, Column} from '@matthahn/sally-ui';
import AttributeText from '../../../layout/components/AttributeText/AttributeText';

// medallion attributes
import poc_address_city from '../../attributes/poc_address_city.attribute.medallion';
import poc_address_state from '../../attributes/poc_address_state.attribute.medallion';
import poc_address_street1 from '../../attributes/poc_address_street1.attribute.medallion';
import poc_address_street2 from '../../attributes/poc_address_street2.attribute.medallion';
import poc_address_zip from '../../attributes/poc_address_zip.attribute.medallion';
import poc_email from '../../attributes/poc_email.attribute.medallion';
import poc_name from '../../attributes/poc_name.attribute.medallion';
import poc_notes from '../../attributes/poc_notes.attribute.medallion';
import poc_phone_number from '../../attributes/poc_phone_number.attribute.medallion';

const MedallionContactCard = ({onClick, medallion}) => (
  <Card
    title={medallion.medallion_number}
    icon="staro"
    headerActions={[{tooltip: 'Open medallion', icon: 'enter', onClick}]}
  >
    {(Content) => (
      <Content>
        <Row margin>
          <Column>
            <AttributeText attribute={poc_name(medallion?.poc_name || '')}>
              {poc_name.label.default}
            </AttributeText>
          </Column>
        </Row>
        <Row margin>
          <Column size={1 / 2}>
            <AttributeText attribute={poc_email(medallion?.poc_email || '')}>
              {poc_email.label.default}
            </AttributeText>
          </Column>
          <Column size={1 / 2}>
            <AttributeText
              attribute={poc_phone_number(medallion?.poc_phone_number || '')}
            >
              {poc_phone_number.label.default}
            </AttributeText>
          </Column>
        </Row>
        <Row margin>
          <Column size={1 / 3}>
            <AttributeText
              attribute={poc_address_street1(
                medallion?.poc_address_street1 || ''
              )}
            >
              {poc_address_street1.label.default}
            </AttributeText>
          </Column>
          <Column size={1 / 3}>
            <AttributeText
              attribute={poc_address_street2(
                medallion?.poc_address_street2 || ''
              )}
            >
              {poc_address_street2.label.default}
            </AttributeText>
          </Column>
          <Column size={1 / 3}>
            <AttributeText
              attribute={poc_address_city(medallion?.poc_address_city || '')}
            >
              {poc_address_city.label.default}
            </AttributeText>
          </Column>
        </Row>
        <Row margin>
          <Column size={1 / 2}>
            <AttributeText
              attribute={poc_address_zip(medallion?.poc_address_zip || '')}
            >
              {poc_address_zip.label.default}
            </AttributeText>
          </Column>
          <Column size={1 / 2}>
            <AttributeText
              attribute={poc_address_state(medallion?.poc_address_state || '')}
            >
              {poc_address_state.label.default}
            </AttributeText>
          </Column>
        </Row>
        <Row>
          <Column>
            <AttributeText attribute={poc_notes(medallion?.poc_notes || '')}>
              {poc_notes.label.default}
            </AttributeText>
          </Column>
        </Row>
      </Content>
    )}
  </Card>
);

MedallionContactCard.propTypes = {
  medallion: PropTypes.object,
  onClick: PropTypes.func,
};

export default MedallionContactCard;
