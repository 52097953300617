import prepare from '../../attribute/prepare.attribute';

// Constants
import readyAttributes from '../constants/readyAttributes.constants.driver';

export default async (attributes) =>
  prepare(attributes, {
    field: 'api',
    required: [...readyAttributes],
  });
