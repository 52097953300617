import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {
  Modal,
  Row,
  Column,
  Table,
  TextColor,
  Text,
  Textarea,
  Label,
  Icon,
  P,
} from '@matthahn/sally-ui';
import TripPaymentType from '../../../trip/components/TripPaymentType/TripPaymentType';
import SmallLoader from '../../../layout/components/SmallLoader/SmallLoader';
import NegativeParenthesis from '../../../layout/components/NegativeParenthesis/NegativeParenthesis';
import PayoutOverview from '../../../payout/components/PayoutOverview/PayoutOverview';
import Flip from './components/Flip';
import Break from './components/Break';
import FlexRow from './components/FlexRow';
import FlexCol from './components/FlexCol';

// Columns
import columns from './columns';
import tripColumns from './tripColumns';

// Type
import {dateTime, shortDateTime} from '../../../types';

const TripTransactionModal = ({
  loading,
  visible,
  initing,
  downloading,
  payout,
  screenshot,
  trip,
  tripTransactions,
  transaction,
  transactionAmount,
  childTransactions,
  loadingChildTransactions,
  description,
  canEditDescription,
  onClose,
  onTransaction,
  onScreenshot,
  onReportDownload,
  onDescription,
  onSaveDescription,
}) => (
  <Modal
    visible={visible}
    size={
      !!trip && (loadingChildTransactions || !!transaction) ? 'large' : 'medium'
    }
    title={
      !!payout
        ? 'Payout Overview'
        : `${!!trip ? 'Trip ' : ''}Transaction Overview`
    }
    onClose={onClose}
    buttonsRight={
      canEditDescription
        ? [
            {
              label: 'Save',
              loading,
              onClick: onSaveDescription,
              theme: 'orange',
            },
          ]
        : []
    }
    headerActions={[
      !!payout && {
        icon: 'download',
        tooltip: 'Download Report',
        onClick: onReportDownload,
        loading: downloading,
      },
      !!screenshot && {
        icon: 'picture',
        tooltip: 'Payout Screenshot',
        onClick: onScreenshot,
      },
    ].filter((act) => !!act)}
  >
    {(Content) =>
      initing ? (
        <Content>
          <SmallLoader />
        </Content>
      ) : (
        <Content padding="none">
          <FlexRow>
            {!!payout && (
              <FlexCol full>
                <PayoutOverview payout={payout} />
              </FlexCol>
            )}
            {!!trip && !payout && (
              <FlexCol full={!(loadingChildTransactions || !!transaction)}>
                <Content noBorder>
                  <Row margin>
                    <Column>
                      <P weight="bold" size="2">
                        Trip #{trip.id}
                      </P>
                    </Column>
                  </Row>
                  {trip.voided && (
                    <Row margin>
                      <Column>
                        <P weight="bold" size="2" theme="red">
                          VOIDED
                        </P>
                      </Column>
                    </Row>
                  )}
                  <Row margin>
                    <Column size={2 / 3}>
                      <Text value={trip.start_address}>Start Address</Text>
                    </Column>
                    <Column size={1 / 3}>
                      <Text value={dateTime(trip.start_datetime).format()}>
                        Start Time
                      </Text>
                    </Column>
                  </Row>
                  <Row margin>
                    <Column size={2 / 3}>
                      <Text value={trip.end_address}>End Address</Text>
                    </Column>
                    <Column size={1 / 3}>
                      <Text value={dateTime(trip.end_datetime).format()}>
                        End Time
                      </Text>
                    </Column>
                  </Row>
                  <Row>
                    <Column size={2 / 3}>
                      <Text value={<TripPaymentType type={trip.type} />}>
                        Payment type
                      </Text>
                    </Column>
                    <Column size={1 / 3}>
                      <Text
                        value={
                          <NegativeParenthesis
                            value={transactionAmount}
                            pre="$"
                          />
                        }
                      >
                        TRIP NET
                      </Text>
                    </Column>
                  </Row>
                </Content>
                <Content padding="none">
                  <Table
                    columns={tripColumns}
                    theme="orange"
                    infinite
                    noBorder
                    noRadius
                    smallNoResultsLabel
                  >
                    {(TableRow) =>
                      tripTransactions.map((trans) => (
                        <TableRow
                          key={trans.id}
                          onClick={onTransaction(trans)}
                          bg={
                            !!transaction && transaction.id === trans.id
                              ? 'grey'
                              : 'transparent'
                          }
                        >
                          {(TableColumn) => [
                            <TableColumn key="date">
                              {shortDateTime(trans.posted_at).format()}
                            </TableColumn>,
                            <TableColumn key="type">
                              {trans.computedType}
                            </TableColumn>,
                            <TableColumn key="amount">
                              <NegativeParenthesis
                                wrap={trans.income}
                                value={trans.amount}
                                pre="$"
                              />
                            </TableColumn>,
                            <TableColumn key="balance">
                              <TextColor theme="darkGrey">
                                <NegativeParenthesis
                                  wrap={trans.income}
                                  value={trans.balance}
                                  pre="$"
                                />
                              </TextColor>
                            </TableColumn>,
                            <TableColumn key="description" span={3}>
                              <Break>{trans.description}</Break>
                            </TableColumn>,
                          ]}
                        </TableRow>
                      ))
                    }
                  </Table>
                </Content>
              </FlexCol>
            )}
            {(loadingChildTransactions || !!transaction) && !payout && (
              <FlexCol full={!trip} border={!!trip}>
                <Fragment>
                  {!!transaction && (
                    <Content noBorder>
                      {!!trip && (
                        <Row margin>
                          <Column>
                            <P weight="bold" size="2">
                              Transaction
                            </P>
                          </Column>
                        </Row>
                      )}
                      <Row margin>
                        <Column size={1 / 2}>
                          <Text
                            value={dateTime(transaction.posted_at).format()}
                          >
                            Date Created
                          </Text>
                        </Column>
                        <Column size={1 / 2}>
                          <Text value={transaction.computedType}>Type</Text>
                        </Column>
                      </Row>
                      <Row margin>
                        <Column size={1 / 2}>
                          <Text
                            value={
                              <NegativeParenthesis
                                wrap={transaction.income}
                                value={transaction.amount}
                                pre="$"
                              />
                            }
                          >
                            Amount
                          </Text>
                        </Column>
                        <Column size={1 / 2}>
                          <Text
                            value={
                              <NegativeParenthesis
                                value={transaction.balance}
                                pre="$"
                              />
                            }
                          >
                            Balance
                          </Text>
                        </Column>
                      </Row>
                      <Row>
                        <Column>
                          {canEditDescription ? (
                            <Textarea
                              value={description}
                              onChange={onDescription}
                              disabled={loading}
                            >
                              Description
                            </Textarea>
                          ) : (
                            <Label label="Description">
                              {description || '-'}
                            </Label>
                          )}
                        </Column>
                      </Row>
                    </Content>
                  )}
                  <Content padding="none">
                    <Table
                      columns={columns}
                      loading={
                        loadingChildTransactions && !childTransactions.length
                      }
                      theme="orange"
                      infinite
                      noBorder
                      noRadius
                      smallNoResultsLabel
                    >
                      {(TableRow) =>
                        childTransactions.map((trans) => [
                          <TableRow key={trans.id}>
                            {(TableColumn) => [
                              <TableColumn span={4} key="date">
                                {dateTime(trans.posted_at).format()}
                              </TableColumn>,
                              <TableColumn span={4} key="type">
                                {trans.computedType}
                              </TableColumn>,
                              <TableColumn span={4} key="amount">
                                <NegativeParenthesis
                                  wrap={trans.income}
                                  value={trans.amount}
                                  pre="$"
                                />
                              </TableColumn>,
                              <TableColumn span={4} key="balance">
                                <TextColor theme="darkGrey">
                                  <NegativeParenthesis
                                    value={trans.balance}
                                    pre="$"
                                  />
                                </TextColor>
                              </TableColumn>,
                            ]}
                          </TableRow>,
                          ...trans.childTransactions.map((childTransaction) => (
                            <TableRow
                              key={`${trans.id}-${childTransaction.id}`}
                            >
                              {(TableColumn) => [
                                <TableColumn key="icon">
                                  <Flip>
                                    <Icon icon="enter" />
                                  </Flip>
                                </TableColumn>,
                                <TableColumn span={3} key="date">
                                  {dateTime(
                                    childTransaction.posted_at
                                  ).format()}
                                </TableColumn>,
                                <TableColumn span={4} key="type">
                                  {childTransaction.computedType}
                                </TableColumn>,
                                <TableColumn span={4} key="amount">
                                  <NegativeParenthesis
                                    wrap={childTransaction.income}
                                    value={childTransaction.amount}
                                    pre="$"
                                  />
                                </TableColumn>,
                                <TableColumn span={4} key="balance">
                                  <TextColor theme="darkGrey">
                                    <NegativeParenthesis
                                      value={childTransaction.balance}
                                      pre="$"
                                    />
                                  </TextColor>
                                </TableColumn>,
                              ]}
                            </TableRow>
                          )),
                        ])
                      }
                    </Table>
                  </Content>
                </Fragment>
              </FlexCol>
            )}
          </FlexRow>
        </Content>
      )
    }
  </Modal>
);

TripTransactionModal.propTypes = {
  loading: PropTypes.bool,
  visible: PropTypes.bool,
  initing: PropTypes.bool,
  canEditDescription: PropTypes.bool,
  downloading: PropTypes.bool,
  screenshot: PropTypes.object,
  payout: PropTypes.object,
  trip: PropTypes.object,
  tripTransactions: PropTypes.array,
  transaction: PropTypes.object,
  transactionAmount: PropTypes.number,
  childTransactions: PropTypes.array,
  loadingChildTransactions: PropTypes.bool,
  description: PropTypes.string,
  onClose: PropTypes.func,
  onTransaction: PropTypes.func,
  onScreenshot: PropTypes.func,
  onReportDownload: PropTypes.func,
  onDescription: PropTypes.func,
  onSaveDescription: PropTypes.func,
};

export default TripTransactionModal;
