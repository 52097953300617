import React from 'react';
import PropTypes from 'prop-types';

// Columns
import columns from './columns';

// Components
import {
  Container,
  Row,
  Column,
  Table,
  Button,
  InputTyping,
  Select,
} from '@matthahn/sally-ui';

import ButtonContainer from './components/ButtonContainer';
import Content from './components/Content';
import InputContainer from './components/InputContainer';
import Menu from './components/Menu';
import PageContainer from './components/PageContainer';
import SelectContainer from './components/SelectContainer';

// Lib
import displayTimeZoneSpecificDate from '../../../lib/displayTimeZoneSpecificDate';
import getAccidentState from '../../lib/getState.lib.accident';

// Types
import {dateTime} from '../../../types';

const AccidentsList = ({
  loading,
  accidents,
  state,
  states,
  onState,
  page,
  pages,
  onPage,
  sort,
  onSort,
  onAccident,
  search,
  onSearch,
  onNewAccident,
}) => (
  <PageContainer>
    <Menu>
      <InputContainer>
        <InputTyping
          value={search}
          onChange={onSearch}
          preIcon="search1"
          placeholder="Search for accident"
          size="large"
          flat
        />
      </InputContainer>
      <SelectContainer>
        <Select
          value={state}
          options={states}
          onChange={onState}
          size="large"
          notFilterable
          flat
        />
      </SelectContainer>
      <ButtonContainer>
        <Button icon="plus" theme="orange" onClick={onNewAccident} flat>
          New Accident
        </Button>
      </ButtonContainer>
    </Menu>
    <Content>
      <Container>
        <Row margin>
          <Column>
            <Table
              columns={columns}
              infinite={false}
              page={page}
              pages={pages}
              onPage={onPage}
              loading={loading}
              sort={sort}
              onSort={onSort}
              theme="darkGrey"
            >
              {(TableRow) =>
                accidents.map((accident) => (
                  <TableRow onClick={onAccident(accident)}>
                    {(TableCol) => [
                      <TableCol key="id" span={0.5}>
                        {accident.id}
                      </TableCol>,
                      <TableCol key="datetime_of_loss">
                        {displayTimeZoneSpecificDate({
                          date: accident.datetime_of_loss,
                          type: dateTime,
                        })}
                      </TableCol>,
                      <TableCol key="driver">
                        {!!accident.driver
                          ? `${accident.driver.first_name} ${accident.driver.last_name}`
                          : '-'}
                      </TableCol>,
                      <TableCol key="vehicle">
                        {accident?.medallion?.medallion_number ||
                          accident?.vehicle?.plate ||
                          '-'}
                      </TableCol>,
                      <TableCol key="state">
                        {getAccidentState(accident)}
                      </TableCol>,
                    ]}
                  </TableRow>
                ))
              }
            </Table>
          </Column>
        </Row>
      </Container>
    </Content>
  </PageContainer>
);

AccidentsList.propTypes = {
  loading: PropTypes.bool,
  accidents: PropTypes.array,
  state: PropTypes.string,
  states: PropTypes.array,
  onState: PropTypes.func,
  page: PropTypes.number,
  pages: PropTypes.number,
  onPage: PropTypes.func,
  sort: PropTypes.object,
  onSort: PropTypes.func,
  onAccident: PropTypes.func,
  search: PropTypes.string,
  onSearch: PropTypes.func,
  onNewAccident: PropTypes.func,
};

export default AccidentsList;
