import React from 'react';
import {Switch, Route} from 'react-router-dom';

// MedallionsList
import MedallionsListPage from '../../pages/MedallionsListPage/MedallionsListPage';
import routeMedallionsList from '../../pages/MedallionsListPage/route';

// Medallion
import MedallionPage from '../../pages/MedallionPage/MedallionPage';
import routeMedallion from '../../pages/MedallionPage/route';

const MedallionsPagesContainer = () => (
  <Switch>
    <Route exact path={routeMedallionsList()} component={MedallionsListPage} />
    <Route path={routeMedallion()} component={MedallionPage} />
  </Switch>
);

export default MedallionsPagesContainer;
