import status from './wrapper.status';

import api from '../api/createCharge.api.ticket';

export default status({
  key: 'sally_to_pay',
  label: 'Sally to pay now and charge driver.',
  api,
  transition: [],
  icon: 'home',
});
