import {Component} from 'react';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// DriverPayments
import routeDriverPayments from '../DriverPaymentsPage/route';

class DriverRedirectPage extends Component {
  static propTypes = {
    driver: PropTypes.object,
    history: PropTypes.object,
  };

  componentDidMount() {
    const {
      history,
      driver: {id},
    } = this.props;
    history.replace(routeDriverPayments(id));
  }

  render() {
    return null;
  }
}

export default withRouter(
  connect((state) => ({driver: state.driver.driver}))(DriverRedirectPage)
);
