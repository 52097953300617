class AuthToken {
  constructor() {
    this.token = null;
  }

  set(token) {
    this.token = token;
  }

  get() {
    return this.token;
  }

  remove() {
    this.token = null;
  }
}

export default new AuthToken();
