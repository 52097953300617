import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

export default attribute({
  type: TEXT,
  attribute: 'ezpass_tag',
  label: {
    default: 'E-ZPass Tag',
    short: 'E-ZPass Tag',
  },
});
