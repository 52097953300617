import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Container, Row, Column, Table} from '@matthahn/sally-ui';
import PageContainer from './components/Container';
import Content from './components/Content';

// Columns
import columns from './columns';

// Libs
import displayTimeZoneSpecificDate from '../../../lib/displayTimeZoneSpecificDate';
import getAccidentState from '../../lib/getState.lib.accident';

// Types
import {dateTime} from '@matthahn/sally-fw/lib/type';

const AssociatedAccidentsContainer = ({
  loading,
  displayDriver,
  displayVehicle,
  accidents,
  page,
  pages,
  onPage,
  onAccident,
}) => (
  <PageContainer>
    <Content>
      <Container>
        <Row margin>
          <Column>
            <Table
              columns={columns({displayDriver, displayVehicle})}
              infinite={false}
              page={page}
              pages={pages}
              onPage={onPage}
              loading={loading}
            >
              {(TableRow) =>
                accidents.map((accident) => (
                  <TableRow onClick={onAccident(accident)}>
                    {(TableCol) =>
                      [
                        <TableCol key="datetime_of_loss">
                          {displayTimeZoneSpecificDate({
                            date: accident.datetime_of_loss,
                            type: dateTime,
                          })}
                        </TableCol>,
                        displayDriver && (
                          <TableCol key="driver">
                            {!!accident.driver
                              ? `${accident.driver.first_name} ${accident.driver.last_name}`
                              : '-'}
                          </TableCol>
                        ),
                        displayVehicle && (
                          <TableCol key="vehicle">
                            {accident?.medallion?.medallion_number ||
                              accident?.vehicle?.plate ||
                              '-'}
                          </TableCol>
                        ),
                        <TableCol key="state">
                          {getAccidentState(accident)}
                        </TableCol>,
                      ].filter((col) => !!col)
                    }
                  </TableRow>
                ))
              }
            </Table>
          </Column>
        </Row>
      </Container>
    </Content>
  </PageContainer>
);

AssociatedAccidentsContainer.propTypes = {
  loading: PropTypes.bool,
  displayDriver: PropTypes.bool,
  displayVehicle: PropTypes.bool,
  accidents: PropTypes.array,
  page: PropTypes.number,
  pages: PropTypes.number,
  onPage: PropTypes.func,
  onAccident: PropTypes.func,
};

export default AssociatedAccidentsContainer;
