import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 110px);
  overflow: hidden;
  display: flex;
`;

export default Container;
