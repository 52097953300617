import styled, {css} from 'styled-components';

// Colors
import {lib} from '@matthahn/sally-ui';
const {
  colors: {grey},
} = lib;

const Content = styled.div`
  width: 50%;
  max-width: 50%;
  min-width: 50%;
  height: 100%;
  overflow: hidden;
  ${({border}) =>
    border
      ? css`
          border-left: 1px solid ${grey};
        `
      : css`
          border-right: 1px solid ${grey};
        `}
`;

export default Content;
