import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal, P, Row, Column} from '@matthahn/sally-ui';

const ConfirmationModal = ({
  loading,
  visible,
  numberOfPayments,
  sum,
  action,
  onClose,
  onConfirm,
}) => (
  <Modal
    title="Check Confirmation"
    visible={visible}
    onClose={onClose}
    buttonsRight={[
      {label: 'Confirm', loading, onClick: onConfirm, theme: 'grey'},
    ]}
  >
    {(Content) => (
      <Content>
        <Row margin />
        <Row margin>
          <Column>
            <P>
              You are sure you want to {action} {numberOfPayments} payments for
              ${sum}?
            </P>
          </Column>
        </Row>
      </Content>
    )}
  </Modal>
);

ConfirmationModal.propTypes = {
  loading: PropTypes.bool,
  visible: PropTypes.bool,
  numberOfPayments: PropTypes.number,
  sum: PropTypes.string,
  action: PropTypes.string,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default ConfirmationModal;
