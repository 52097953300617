import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {licencePlate} from '../../types';

export default attribute({
  type: TEXT,
  attribute: 'plate',
  input: licencePlate,
  api: licencePlate,
  label: {
    default: 'License Plate',
    short: 'Plate',
  },
});
