import {isDate, parseISO, format as formatDate} from 'date-fns';

import type from '../type';

const format = (value) =>
  !!value ? formatDate(isDate(value) ? value : parseISO(value), 'M.d.yy') : '';

const validate = (value) => /^\d{1,2}\.\d{1,2}\.\d{2}$/.test(value);

export default type({type: 'date', format, validate});
