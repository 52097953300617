import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

// Events
import event from '../events/endedWithoutPayout.event.rental';

export default async ({rental_id, end_datetime = undefined} = {}) => {
  const response = await f3tch('/dispatch/rentals/end_without_payout/')
    .profile(profile())
    .body({rental_id, end_datetime})
    .post();
  event.pub(response);
  return response;
};
