import React from 'react';

// Components
import Page from '../../../layout/containers/Page/Page';

// Containers
import InsurancesContainer from '../../containers/InsurancesContainer/InsurancesContainer';

// Info
import page from './page';

const InsurancesPage = () => (
  <Page page={page}>
    <InsurancesContainer />
  </Page>
);

export default InsurancesPage;
