/**
 * @description :: Creating a field for api query object is equal
 * @param {String} key :: The key of the field
 * @return {String} :: The generated field
 */
export const isEqual = (key) => `${key}`;

/**
 * @description :: Creating a field for api query object less than
 * @param {String} key :: The key of the field
 * @return {String} :: The generated field
 */
export const lessThan = (key) => `${key}__lt`;

/**
 * @description :: Creating a field for api query object greater than
 * @param {String} key :: The key of the field
 * @return {String} :: The generated field
 */
export const greaterThan = (key) => `${key}__gt`;

/**
 * @description :: Creating a field for api query object less than or equal to
 * @param {String} key :: The key of the field
 * @return {String} :: The generated field
 */
export const lessThanOrEqualTo = (key) => `${key}__lte`;

/**
 * @description :: Creating a field for api query object greater than or equal to
 * @param {String} key :: The key of the field
 * @return {String} :: The generated field
 */
export const greaterThanOrEqualTo = (key) => `${key}__gte`;

/**
 * @description :: Creating a field for api query object contains
 * @param {String} key :: The key of the field
 * @return {String} :: The generated field
 */
export const contains = (key) => `${key}__contains`;

/**
 * @description :: Creating a field for api query object case insensitive contains
 * @param {String} key :: The key of the field
 * @return {String} :: The generated field
 */
export const containsInsensitive = (key) => `${key}__icontains`;

/**
 * @description :: Creating a field for api query object exact
 * @param {String} key :: The key of the field
 * @return {String} :: The generated field
 */
export const exact = (key) => `${key}__exact`;

/**
 * @description :: Creating a field for api query object case insensitive exact
 * @param {String} key :: The key of the field
 * @return {String} :: The generated field
 */
export const exactInsensitive = (key) => `${key}__iexact`;

/**
 * @description :: Creating a field for api query object is in
 * @param {String} key :: The key of the field
 * @return {String} :: The generated field
 */
export const isIn = (key) => `${key}__in`;

/**
 * @description :: Creating a field for api query object starts with
 * @param {String} key :: The key of the field
 * @return {String} :: The generated field
 */
export const startsWith = (key) => `${key}__startswith`;

/**
 * @description :: Creating a field for api query object case insensitive starts with
 * @param {String} key :: The key of the field
 * @return {String} :: The generated field
 */
export const startsWithInsensitive = (key) => `${key}__istartswith`;

/**
 * @description :: Creating a field for api query object ends with
 * @param {String} key :: The key of the field
 * @return {String} :: The generated field
 */
export const endsWith = (key) => `${key}__endswith`;

/**
 * @description :: Creating a field for api query object case insensitive ends with
 * @param {String} key :: The key of the field
 * @return {String} :: The generated field
 */
export const endsWithInsensitive = (key) => `${key}__iendswith`;

/**
 * @description :: Creating a field for api query object range
 * @param {String} key :: The key of the field
 * @return {String} :: The generated field
 */
export const inRange = (key) => `${key}__range`;

/**
 * @description :: Creating a field for api query object is null
 * @param {String} key :: The key of the field
 * @return {String} :: The generated field
 */
export const isNull = (key) => `${key}__isnull`;

/**
 * @description :: negating a key
 * @param {String} key :: Key parameter
 * @return {String}
 */
export const negate = (key) => `${key}!`;
