import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Api
import dispatchDriverApi from '../../api/dispatchDriver.api.dispatch';
// import getLeaseRateApi from '../../api/leaseRate.api.dispatch';
import listRentalsApi from '../../../rental/api/list.api.rental';

// Attributes
import weeklyRentChargeAttr from '../../attributes/weekly_rent_charge.attribute.dispatch';

// Components
import DispatchStep from '../../components/DispatchStep/DispatchStep';

// Lib
import {lib} from '@matthahn/sally-ui';
import isDailyLease from '../../../lease/lib/isDaily.lib.lease';
import parseError from '../../../error/parseError';

// Types
import {amount as amountType} from '../../../types';

// Preps
import dispatchPrep from '../../preparation/dispatch.preparation.driver';

// Alerts
const {alert} = lib;

class DispatchStepContainer extends Component {
  static propTypes = {
    Container: PropTypes.func,
    visible: PropTypes.bool,
    vehicle: PropTypes.object,
    driver: PropTypes.object,
    lease: PropTypes.object,
    rental: PropTypes.object,
    medallion: PropTypes.object,
    onBack: PropTypes.func,
    onComplete: PropTypes.func,
    onDisabled: PropTypes.func,
  };

  state = {
    loadingRate: false,
    loading: false,
    proratedRent: 0,
    weekly_rent_charge: weeklyRentChargeAttr(''),
    daily: false,
  };

  componentDidMount() {
    this.getLeaseRate();
  }

  getLeaseRate = async () => {
    const {lease} = this.props;
    // const {lease, rental} = this.props;

    if (isDailyLease(lease))
      return this.setState({loadingRate: false, daily: true});

    this.setState({loadingRate: true});

    try {
      // const {prorated_rent_amount: proratedRent} = await getLeaseRateApi(
      //   rental.amount
      // );
      const proratedRent = 0;
      this.setState({proratedRent, loadingRate: false, daily: false});
    } catch (error) {
      alert.error('Cloud not load prorated lease rate');
    }
  };

  dispatchDriver = async () => {
    const {vehicle, driver, medallion, rental, onComplete, onDisabled} =
      this.props;
    const {
      loading,
      daily,
      weekly_rent_charge: rawWeeklyRentCharge,
    } = this.state;

    if (loading) return;

    this.setState({loading: true});
    onDisabled(true);

    try {
      const {weekly_rent_charge} = await dispatchPrep({
        daily,
        weekly_rent_charge: rawWeeklyRentCharge,
      });
      const {results: rentals} = await listRentalsApi({
        vehicle: vehicle.id,
        state: 'active',
        fields: 'id',
      });
      await dispatchDriverApi({
        drivers: [driver.id],
        medallion: medallion.medallion_number,
        rate: rental.amount,
        driver_tag: false,
        additional_driver: !!rentals.length,
        vehicle_pickup_datetime: rental.vehicle_pickup_datetime,
        weekly_rent_charge,
      });
      alert.success(`${driver.first_name} ${driver.last_name} dispatched`);
      onComplete();
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({loading: false});
      onDisabled(false);
    }
  };

  onWeeklyRentChargeChange = (weekly_rent_charge) => {
    if (this.state.loading) return;
    this.setState({weekly_rent_charge});
  };

  render() {
    const {Container, driver, vehicle, medallion, lease, rental, onBack} =
      this.props;
    const {loading, loadingRate, proratedRent, daily, weekly_rent_charge} =
      this.state;
    return (
      <DispatchStep
        Container={Container}
        loading={loading}
        loadingRate={loadingRate}
        proratedRent={amountType(proratedRent).format()}
        lease={lease}
        rental={rental}
        driver={driver}
        medallion={medallion}
        weekly_rent_charge={weekly_rent_charge}
        ezpass_tag_number={vehicle.ezpass_tag_number}
        daily={daily}
        onBack={onBack}
        onWeeklyRentChargeChange={this.onWeeklyRentChargeChange}
        onDispatch={this.dispatchDriver}
      />
    );
  }
}

export default DispatchStepContainer;
