// permission constants
import NON_BOOLEAN_PERMISSIONS from '../constants/nonBooleanPermissions.constant.permission';

const isPermission = ({permissionKey, permissionMeta}) =>
  NON_BOOLEAN_PERMISSIONS.includes(permissionKey) ||
  (permissionKey.endsWith('_permission') &&
    permissionMeta?.type === 'boolean' &&
    !permissionMeta?.read_only);

export default isPermission;
