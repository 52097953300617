import prepare from '../../attribute/prepare.attribute';
import combine from '../../attribute/combinePreparation.attribute';

// Constants
import createAttributes from '../constants/createAttributes.constants.driver';

// Errors
import ssnMissmatchError from '../errors/ssnMissmatch.error.driver';

// Preparations
import address from './address.preparation.driver';

export default async (attributes) => {
  const [rawDriver] = await combine(
    async () =>
      prepare(attributes, {
        field: 'api',
        required: [...createAttributes],
      }),
    async () => await address(attributes)
  );
  const {ssnConfirm, ...driver} = rawDriver;
  if (!ssnConfirm || ssnConfirm !== driver.ssn)
    throw ssnMissmatchError({
      message: 'Confirm SSN number',
      fields: ['ssn', 'ssnConfirm'],
    });
  return driver;
};
