import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

export default (driverID, query = {}) =>
  f3tch(`/payments/transactions/trip-grouped-root/${driverID}/`)
    .profile(profile())
    .query(query)
    .get();
