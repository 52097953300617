import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Card, Row, Column} from '@matthahn/sally-ui';
import AttributeText from '../../../layout/components/AttributeText/AttributeText';

const DriverDmvCard = ({
  loading,
  onOpen,
  onShowUploader,
  file,
  date_of_birth,
  dmv_license_number,
  dmv_license_state,
  dmv_license_expiry,
  dmv_license_issue_date,
}) => (
  <Card
    title="DMV License"
    icon="idcard"
    headerActions={[
      {
        icon: 'addfile',
        tooltip: 'Upload DMV',
        onClick: onShowUploader,
        loading,
      },
      !!file && {
        icon: 'eyeo',
        onClick: onOpen,
        tooltip: 'Show file',
      },
    ].filter((act) => !!act)}
  >
    {(Content) => (
      <Content>
        <Row margin>
          <Column>
            <AttributeText attribute={date_of_birth}>
              {date_of_birth.label.default}
            </AttributeText>
          </Column>
        </Row>
        <Row margin>
          <Column>
            <AttributeText attribute={dmv_license_state}>
              {dmv_license_state.label.default}
            </AttributeText>
          </Column>
        </Row>
        <Row margin>
          <Column>
            <AttributeText attribute={dmv_license_number}>
              {dmv_license_number.label.default}
            </AttributeText>
          </Column>
        </Row>
        <Row>
          <Column size={1 / 2}>
            <AttributeText attribute={dmv_license_expiry}>
              {dmv_license_expiry.label.default}
            </AttributeText>
          </Column>
          <Column size={1 / 2}>
            <AttributeText attribute={dmv_license_issue_date}>
              {dmv_license_issue_date.label.default}
            </AttributeText>
          </Column>
        </Row>
      </Content>
    )}
  </Card>
);

DriverDmvCard.propTypes = {
  loading: PropTypes.bool,
  onOpen: PropTypes.func,
  onShowUploader: PropTypes.func,
  file: PropTypes.object,
  date_of_birth: PropTypes.object,
  dmv_license_number: PropTypes.object,
  dmv_license_state: PropTypes.object,
  dmv_license_expiry: PropTypes.object,
  dmv_license_issue_date: PropTypes.object,
};

export default DriverDmvCard;
