// Bundle
import bundleObject from '../../../document/bundle.document';

// Files
import * as accidentFiles from '../folders';

export default bundleObject({
  name: 'Accident Bundle',
  files: Object.entries(accidentFiles)
    .filter(([key]) => key !== 'video')
    .map(([key, value]) => value),
});
