import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal, Table} from '@matthahn/sally-ui';
import NegativeParenthesis from '../../../layout/components/NegativeParenthesis/NegativeParenthesis';
import DriverTags from '../../../driver/components/DriverTags/DriverTags';

// Columns
import columns from './columns';

const DriversPreviewModal = ({visible, vehicle, drivers, onClose, onClick}) => (
  <Modal
    visible={visible}
    onClose={onClose}
    title={
      !!vehicle && !!vehicle.medallion
        ? vehicle.medallion.medallion_number
        : '-'
    }
    subtitle="Drivers Overview"
  >
    {(Content) => (
      <Content padding="none">
        <Table
          columns={columns}
          theme="orange"
          infinite
          noBorder
          noRadius
          smallNoResultsLabel
        >
          {(TableRow) =>
            drivers.map((driver) => (
              <TableRow key={driver.id} onClick={onClick(driver)}>
                {(TableColumn) => [
                  <TableColumn key="name">
                    <DriverTags driver={driver}>
                      {driver.first_name} {driver.last_name}
                    </DriverTags>
                  </TableColumn>,
                  <TableColumn key="hack">
                    {driver.fhv_license_number}
                  </TableColumn>,
                  <TableColumn key="amount">
                    <NegativeParenthesis value={driver.balance} pre="$" />
                  </TableColumn>,
                ]}
              </TableRow>
            ))
          }
        </Table>
      </Content>
    )}
  </Modal>
);

DriversPreviewModal.propTypes = {
  visible: PropTypes.bool,
  vehicle: PropTypes.object,
  drivers: PropTypes.array,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
};

export default DriversPreviewModal;
