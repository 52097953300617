import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal, Form, Row, Column, Uploader, Info} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';
import UploadContainer from './components/UploadContainer';

const NewDriverModal = ({
  visible,
  onClose,
  loading,
  onCreate,
  onChange,
  errors,
  required,

  first_name,
  last_name,
  phone_number,
  email,
  address_street1,
  address_street2,
  address_city,
  address_state,
  address_zip,
  fhv_license_expiry,
  fhv_license_number,
  ssn,
  ssnConfirm,
  date_of_birth,
  dmv_license_state,
  dmv_license_number,
  dmv_license_expiry,
  dmv_license_issue_date,

  fhv,
  dmv,
}) => (
  <Modal
    visible={visible}
    onClose={onClose}
    size="full"
    closable={!loading}
    title="New Driver"
    buttonsRight={[
      {
        loading,
        label: 'Create',
        theme: 'orange',
        onClick: onCreate,
      },
    ]}
  >
    {(Content) => (
      <Form onSubmit={onCreate} enter>
        <Content>
          <Row>
            <Column size={1 / 2}>
              <Row margin>
                <Column size={1 / 4}>
                  <AttributeInput
                    required={required}
                    errors={errors}
                    value={first_name}
                    onChange={onChange}
                    disabled={loading}
                  >
                    {first_name.label.default}
                  </AttributeInput>
                </Column>
                <Column size={1 / 4}>
                  <AttributeInput
                    required={required}
                    errors={errors}
                    value={last_name}
                    onChange={onChange}
                    disabled={loading}
                  >
                    {last_name.label.default}
                  </AttributeInput>
                </Column>
                <Column size={1 / 4}>
                  <AttributeInput
                    required={required}
                    errors={errors}
                    value={phone_number}
                    onChange={onChange}
                    disabled={loading}
                  >
                    {phone_number.label.default}
                  </AttributeInput>
                </Column>
                <Column size={1 / 4}>
                  <AttributeInput
                    required={required}
                    errors={errors}
                    value={email}
                    onChange={onChange}
                    disabled={loading}
                  >
                    {email.label.default}
                  </AttributeInput>
                </Column>
              </Row>
              <Row>
                <Column size={1 / 3}>
                  <AttributeInput
                    required={required}
                    errors={errors}
                    value={date_of_birth}
                    onChange={onChange}
                    disabled={loading}
                  >
                    {date_of_birth.label.default}
                  </AttributeInput>
                </Column>
                <Column size={1 / 3}>
                  <AttributeInput
                    required={required}
                    errors={errors}
                    value={ssn}
                    onChange={onChange}
                    disabled={loading}
                  >
                    {ssn.label.default}
                  </AttributeInput>
                </Column>
                <Column size={1 / 3}>
                  <AttributeInput
                    required={required}
                    errors={errors}
                    value={ssnConfirm}
                    onChange={onChange}
                    disabled={loading}
                  >
                    {ssnConfirm.label.default}
                  </AttributeInput>
                </Column>
              </Row>
            </Column>
            <Column size={1 / 2}>
              <Row margin>
                <Column size={1 / 3}>
                  <AttributeInput
                    required={required}
                    errors={errors}
                    value={address_street1}
                    onChange={onChange}
                    disabled={loading}
                  >
                    {address_street1.label.default}
                  </AttributeInput>
                </Column>
                <Column size={1 / 3}>
                  <AttributeInput
                    required={required}
                    errors={errors}
                    value={address_street2}
                    onChange={onChange}
                    disabled={loading}
                  >
                    {address_street2.label.default}
                  </AttributeInput>
                </Column>
                <Column size={1 / 3}>
                  <AttributeInput
                    required={required}
                    errors={errors}
                    value={address_city}
                    onChange={onChange}
                    disabled={loading}
                  >
                    {address_city.label.default}
                  </AttributeInput>
                </Column>
              </Row>
              <Row>
                <Column size={1 / 2}>
                  <AttributeInput
                    required={required}
                    errors={errors}
                    value={address_zip}
                    onChange={onChange}
                    disabled={loading}
                  >
                    {address_zip.label.default}
                  </AttributeInput>
                </Column>
                <Column size={1 / 2}>
                  <AttributeInput
                    required={required}
                    errors={errors}
                    value={address_state}
                    onChange={onChange}
                    disabled={loading}
                  >
                    {address_state.label.default}
                  </AttributeInput>
                </Column>
              </Row>
            </Column>
          </Row>
        </Content>

        <Content>
          <Row>
            <Column size={1 / 2}>
              <Row>
                <Column size={1 / 2}>
                  <Row>
                    <Column>
                      <Info type={!!fhv ? 'success' : 'info'} flat>
                        {!!fhv ? 'FHV Uploaded' : 'Upload FHV'}
                      </Info>
                    </Column>
                  </Row>
                  <Row>
                    <Column>
                      <UploadContainer>
                        <Uploader
                          loading={loading}
                          onChange={([file]) => onChange(file, 'fhv')}
                          small
                        />
                      </UploadContainer>
                    </Column>
                  </Row>
                </Column>
                <Column size={1 / 2}>
                  <Row margin>
                    <Column>
                      <AttributeInput
                        required={required}
                        errors={errors}
                        value={fhv_license_number}
                        onChange={onChange}
                        disabled={loading}
                      >
                        {fhv_license_number.label.default}
                      </AttributeInput>
                    </Column>
                  </Row>
                  <Row>
                    <Column>
                      <AttributeInput
                        required={required}
                        errors={errors}
                        value={fhv_license_expiry}
                        onChange={onChange}
                        disabled={loading}
                      >
                        {fhv_license_expiry.label.default}
                      </AttributeInput>
                    </Column>
                  </Row>
                </Column>
              </Row>
            </Column>
            <Column size={1 / 2}>
              <Row>
                <Column size={1 / 2}>
                  <Row>
                    <Column>
                      <Info type={!!dmv ? 'success' : 'info'} flat>
                        {!!dmv ? 'DMV Uploaded' : 'Upload DMV'}
                      </Info>
                    </Column>
                  </Row>
                  <Row>
                    <Column>
                      <UploadContainer>
                        <Uploader
                          loading={loading}
                          onChange={([file]) => onChange(file, 'dmv')}
                          small
                        />
                      </UploadContainer>
                    </Column>
                  </Row>
                </Column>
                <Column size={1 / 2}>
                  <Row margin>
                    <Column>
                      <AttributeInput
                        required={required}
                        errors={errors}
                        value={dmv_license_state}
                        onChange={onChange}
                        disabled={loading}
                      >
                        {dmv_license_state.label.default}
                      </AttributeInput>
                    </Column>
                  </Row>
                  <Row margin>
                    <Column>
                      <AttributeInput
                        required={required}
                        errors={errors}
                        value={dmv_license_number}
                        onChange={onChange}
                        disabled={loading}
                      >
                        {dmv_license_number.label.default}
                      </AttributeInput>
                    </Column>
                  </Row>
                  <Row margin>
                    <Column>
                      <AttributeInput
                        required={required}
                        errors={errors}
                        value={dmv_license_expiry}
                        onChange={onChange}
                        disabled={loading}
                      >
                        {dmv_license_expiry.label.default}
                      </AttributeInput>
                    </Column>
                  </Row>
                  <Row>
                    <Column>
                      <AttributeInput
                        required={required}
                        errors={errors}
                        value={dmv_license_issue_date}
                        onChange={onChange}
                        disabled={loading}
                      >
                        {dmv_license_issue_date.label.default}
                      </AttributeInput>
                    </Column>
                  </Row>
                </Column>
              </Row>
            </Column>
          </Row>
        </Content>
      </Form>
    )}
  </Modal>
);

NewDriverModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  loading: PropTypes.bool,
  onCreate: PropTypes.func,
  onChange: PropTypes.func,
  errors: PropTypes.array,
  required: PropTypes.array,

  first_name: PropTypes.object,
  last_name: PropTypes.object,
  phone_number: PropTypes.object,
  email: PropTypes.object,
  address_street1: PropTypes.object,
  address_street2: PropTypes.object,
  address_city: PropTypes.object,
  address_state: PropTypes.object,
  address_zip: PropTypes.object,
  fhv_license_expiry: PropTypes.object,
  fhv_license_number: PropTypes.object,
  ssn: PropTypes.object,
  ssnConfirm: PropTypes.object,
  date_of_birth: PropTypes.object,
  dmv_license_state: PropTypes.object,
  dmv_license_number: PropTypes.object,
  dmv_license_expiry: PropTypes.object,
  dmv_license_issue_date: PropTypes.object,

  fhv: PropTypes.object,
  dmv: PropTypes.object,
};

export default NewDriverModal;
