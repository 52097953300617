import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {vinLastSeven} from '../../types';

export default attribute({
  type: TEXT,
  attribute: 'vinLastSeven',
  display: vinLastSeven,
  input: vinLastSeven,
  api: vinLastSeven,
  label: {
    default: 'Last 7 of VIN',
    short: 'Last 7 of VIN',
  },
});
