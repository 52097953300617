// Attributes
import amountAttribute from '../attributes/amount.attribute.payout';

// Lib
import sum from '../../lib/sum';
import isAcceptingCheck from '../lib/isAcceptingCheck.lib.payout';

// Errors
import invalidPayout from '../errors/invalidPayout.error.payout';
import emptyCheckNumber from '../errors/emptyCheckNumber.error.payout';

// Type
import {amount as amountType} from '../../types';

export default ({
  payout,
  earnings,
  total,
  credit,
  payoutAmount,
  owed,
  isIncomeAllocation,
  isDepositAllocation,
  transactions,
  accepting_payment_method,
  check_number_from_driver,
}) => {
  if (
    !isIncomeAllocation &&
    !isDepositAllocation &&
    total < 0 &&
    isAcceptingCheck(accepting_payment_method) &&
    !`${check_number_from_driver.api.format()}`.trim().length
  )
    throw emptyCheckNumber();

  if (!isIncomeAllocation) return;

  const exceedingBalance = !![...transactions]
    .filter(
      ({owed_to_sally, subtype}) =>
        owed_to_sally && !!payout[`${subtype}_amount`]
    )
    .find(
      ({subtype, balance}) =>
        amountAttribute(payout[`${subtype}_amount`]).api.format() > balance
    );
  if (exceedingBalance)
    throw invalidPayout({
      message:
        'Check all payment fields. Allocated credit value can not be greater than the outstanding charge balance.',
    });

  if (total < 0)
    throw invalidPayout({
      message: `You can not allocate more than $${amountType(credit).format()}`,
    });
  if (credit > owed && sum(owed, payoutAmount) !== 0)
    throw invalidPayout({message: 'Allocate all of the available credit'});
  if (owed >= credit && total !== 0)
    throw invalidPayout({message: 'Allocate all of the available credit'});
};
