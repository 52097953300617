import React, {Component} from 'react';
import PropTypes from 'prop-types';

// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// alert actions
import {set as setAction} from '../../redux/actions';

// alert components
import CreateAlertModal from '../../components/CreateAlertModal/CreateAlertModal';

// alert events
import alertCreatedEvent from '../../events/created.event.alert';
import showCreateAlertModalEvent from '../../events/showCreateModal.event.alert';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// redux
import {connect} from 'react-redux';

class CreateAlertContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
    user: PropTypes.string,
  };

  static INITIAL_STATE = {
    visible: false,
    loading: false,
    message: '',
    error: true,
  };

  state = {...this.constructor.INITIAL_STATE};

  componentDidMount() {
    this.props.subscribe(showCreateAlertModalEvent.subscribe(this.show));
  }

  data = {
    object: null,
    objectType: null,
    saveApi: () => {},
  };

  show = (data = this.data) => {
    this.data = {...data};
    this.setState({...this.constructor.INITIAL_STATE, visible: true});
  };

  hide = () => {
    if (this.state.loading) return;
    this.setState({visible: false});
  };

  changeMessage = (message) => {
    if (this.state.loading) return;
    this.setState({message});
  };

  save = async () => {
    const {object, objectType, saveApi} = this.data;
    const {user, dispatch} = this.props;
    const {loading, message, error} = this.state;

    if (loading) return;

    if (!message.trim().length) return alert.warning('Insert message');

    this.setState({loading: true});
    dispatch(setAction({creatingAlert: true}));

    try {
      const createdAlert = await saveApi(object.id, {
        message,
        error,
        user,
        [objectType]: object.id,
      });
      alertCreatedEvent.publish({alert: createdAlert, object, objectType});
      this.setState({loading: false, visible: false});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({loading: false});
    }

    dispatch(setAction({creatingAlert: false}));
  };

  render() {
    const {objectType} = this.data;
    const {loading, message, visible} = this.state;
    return (
      <CreateAlertModal
        loading={loading}
        message={message}
        objectType={objectType}
        onChange={this.changeMessage}
        onClose={this.hide}
        onCreate={this.save}
        visible={visible}
      />
    );
  }
}

export default connect((state) => ({user: state.auth.username}))(
  subscriptionHOC(CreateAlertContainer)
);
