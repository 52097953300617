import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {transitions} from 'polished';

// Components
import {Icon} from '@matthahn/sally-ui';

// Lib
import {lib} from '@matthahn/sally-ui';

// Colors
const {
  colors: {darkGrey, black, orange},
} = lib;

// Helper components
const Container = styled.div`
  height: 100%;
  position: relative;
  margin-right: 50px;
`;

const Content = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  color: ${({selected}) => (selected ? black : darkGrey)};
  font-weight: 500;
  font-size: 14px;
  white-space: nowrap;
  ${transitions('color .3s ease-in-out')};

  &:hover {
    color: ${black};
  }
`;

const StyledIcon = styled(Icon)`
  font-size: 20px !important;
  margin-right: 10px;
  transform: translateY(-1px);
  display: block;
`;

const Line = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: ${orange};
`;

const Badge = styled.div`
  background: ${orange};
  color: ${black};
  margin-left: 10px;
  font-size: 10px;
  padding: 3px 4px;
  font-weight: 500;
  border-radius: 4px;
`;

const NavigationButton = ({icon, url, children, selected, badge, onClick}) => (
  <Container>
    <Link to={url}>
      <Content selected={selected} onClick={onClick}>
        <StyledIcon icon={icon} />
        {children}
        {!!badge && <Badge>{badge}</Badge>}
      </Content>
    </Link>
    {selected && <Line />}
  </Container>
);

NavigationButton.propTypes = {
  icon: PropTypes.string,
  url: PropTypes.string,
  children: PropTypes.node,
  badge: PropTypes.number,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

NavigationButton.defaultProps = {
  selected: false,
  badge: 0,
};

export default NavigationButton;
