import {NUMBER} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {amount, formattedNumber} from '../../types';

export default attribute({
  type: NUMBER,
  attribute: 'credit',
  display: amount,
  input: formattedNumber,
  api: formattedNumber,
  label: {
    default: "Driver's Credit",
    short: 'Credit',
  },
});
