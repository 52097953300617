import {SELECT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {string} from '../../types';

export default attribute({
  type: SELECT,
  attribute: 'insurance_liability_carrier',
  api: string,
  label: {
    default: 'Insurance Carrier',
    short: 'Carrier',
  },
  options: ['HEREFORD', 'ATIC'],
});
