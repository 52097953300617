import {NUMBER} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {amount, paymentPlanAmount, stringNumber} from '../../types';

export default attribute({
  type: NUMBER,
  attribute: 'payment_plan_amount',
  display: amount,
  input: stringNumber,
  api: paymentPlanAmount,
  label: {
    default: 'Payment Plan Amount',
    short: 'Payment Plan',
  },
  errorMessage: 'Should be a multiple of 25',
});
