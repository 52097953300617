import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal, Row, Column, Form, Button, Text} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';
import Action from './components/Action';

// Types
import {amount, dateTime} from '../../../types';

const ApprovedChequeActionModal = ({
  removing,
  printing,
  visible,
  cheque,
  fhvConfirmed,
  fhv_license_number,
  onFhvChange,
  onClose,
  onPrint,
  onRemove,
  onFhvCheck,
  onChange,
}) => (
  <Modal
    title="Cheque Actions"
    visible={visible}
    onClose={onClose}
    buttonsRight={
      fhvConfirmed
        ? []
        : [{label: 'Confirm', onClick: onFhvCheck, theme: 'orange'}]
    }
  >
    {(Content) =>
      fhvConfirmed ? (
        !cheque ? null : (
          <Content>
            <Row margin>
              <Column size={1 / 2}>
                <Text value={cheque.check_number}>Cheque Number</Text>
              </Column>
              <Column size={1 / 2}>
                <Text
                  value={`${cheque.driver.first_name} ${cheque.driver.last_name}`}
                >
                  Driver
                </Text>
              </Column>
            </Row>
            <Row margin>
              <Column size={1 / 2}>
                <Text value={dateTime(cheque.created_at).format()}>Date</Text>
              </Column>
              <Column size={1 / 2}>
                <Text value={amount(cheque.amount).format()}>Amount</Text>
              </Column>
            </Row>
            <Row margin />
            <Row margin>
              <Column size={1 / 2}>
                <Action>
                  <Button
                    size="large"
                    theme="grey"
                    icon="printer"
                    onClick={onPrint}
                    disabled={removing}
                    loading={printing}
                  >
                    Print
                  </Button>
                </Action>
              </Column>
              <Column size={1 / 2}>
                <Action>
                  <Button
                    size="large"
                    theme="grey"
                    icon="delete"
                    onClick={onRemove}
                    loading={removing}
                    disabled={printing}
                  >
                    Remove
                  </Button>
                </Action>
              </Column>
            </Row>
          </Content>
        )
      ) : (
        <Content>
          <Row margin />
          <Row margin>
            <Column size={1 / 2} offset={1 / 4}>
              <Form onSubmit={onFhvCheck} enter>
                <AttributeInput
                  value={fhv_license_number}
                  onChange={onFhvChange}
                >
                  Hack License
                </AttributeInput>
              </Form>
            </Column>
          </Row>
        </Content>
      )
    }
  </Modal>
);

ApprovedChequeActionModal.propTypes = {
  removing: PropTypes.bool,
  printing: PropTypes.bool,
  visible: PropTypes.bool,
  cheque: PropTypes.object,
  fhvConfirmed: PropTypes.bool,
  fhv_license_number: PropTypes.object,
  onFhvChange: PropTypes.func,
  onClose: PropTypes.func,
  onPrint: PropTypes.func,
  onRemove: PropTypes.func,
  onFhvCheck: PropTypes.func,
  onChange: PropTypes.func,
};

export default ApprovedChequeActionModal;
