import styled from 'styled-components';

// Colors
import {lib} from '@matthahn/sally-ui';
const {
  colors: {grey},
} = lib;

const Preview = styled.div`
  width: 100%;
  height: 100%;
  ${'' /* max-height: 635px; */}
  background: ${grey};
  position: relative;
`;

export default Preview;
