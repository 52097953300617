import styled from 'styled-components';

// Colors
import {lib} from '@matthahn/sally-ui';
const {
  colors: {darkGrey},
} = lib;

const NoData = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${darkGrey};
  font-size: 16px;
`;

export default NoData;
