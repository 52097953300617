import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal, Row, Column, P, Info} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

const SkipPayoutModal = ({
  visible,
  loading,
  rental,
  end_datetime,
  displayEndDatePicker,
  displayCreditWarning,
  onSkip,
  onClose,
  onEndDatetime,
}) => (
  <Modal
    title="End Without Payout"
    subtitle={
      !rental
        ? null
        : `${rental.driver.first_name} ${rental.driver.last_name} on ${rental.medallion.medallion_number}`
    }
    icon="enter"
    visible={visible}
    onClose={onClose}
    buttonsRight={
      !rental
        ? []
        : [
            {
              label: 'End Rental',
              theme: 'orange',
              loading,
              onClick: onSkip,
            },
          ]
    }
  >
    {(Content) =>
      !!rental && (
        <Fragment>
          {displayCreditWarning && (
            <Content padding="none">
              <Info type="info" flat>
                CREDIT WILL BE GIVEN FOR UNUSED RENTAL DAYS
              </Info>
            </Content>
          )}
          <Content>
            <Row margin />
            <Row margin>
              <Column>
                Are you sure you want to check in{' '}
                <P weight="bold" inline>
                  {rental.driver.first_name} {rental.driver.last_name}
                </P>{' '}
                without payout?
              </Column>
            </Row>
            {displayEndDatePicker && (
              <Row margin>
                <Column>
                  <AttributeInput
                    value={end_datetime}
                    onChange={onEndDatetime}
                    disabled={loading}
                  >
                    End Date
                  </AttributeInput>
                </Column>
              </Row>
            )}
          </Content>
        </Fragment>
      )
    }
  </Modal>
);

SkipPayoutModal.propTypes = {
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  displayCreditWarning: PropTypes.bool,
  displayEndDatePicker: PropTypes.bool,
  rental: PropTypes.object,
  end_datetime: PropTypes.object,
  onSkip: PropTypes.func,
  onClose: PropTypes.func,
  onEndDatetime: PropTypes.func,
};

export default SkipPayoutModal;
