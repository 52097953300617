import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Info, TextColor} from '@matthahn/sally-ui';

// Types
import {date} from '../../../types';

const VehicleArchivedInfo = ({vehicle}) => (
  <Info type="error">
    Vehicle archived.
    {!!vehicle && !!vehicle.vehicle_archived && (
      <span>
        {' '}
        <TextColor theme="white" weight="bold">
          Reason:
        </TextColor>{' '}
        {vehicle.vehicle_archived.reason}
      </span>
    )}
    {!!vehicle && !!vehicle.vehicle_archived && (
      <span>
        {' '}
        <TextColor theme="white" weight="bold">
          Archived date:
        </TextColor>{' '}
        {!!vehicle.vehicle_archived.archived_date
          ? date(vehicle.vehicle_archived.archived_date).format()
          : '-'}
      </span>
    )}
  </Info>
);

VehicleArchivedInfo.propTypes = {
  vehicle: PropTypes.object,
};

export default VehicleArchivedInfo;
