import styled from 'styled-components';

const SelectContainer = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 100px 0px;
  margin: auto;
`;

export default SelectContainer;
