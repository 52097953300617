import styled from 'styled-components';

// colors
import {white, grey} from '@matthahn/sally-ui/lib/libs/colors';

const UserRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
  background: ${white};
  cursor: pointer;
  gap: 10px;

  &:hover {
    background: ${grey};
  }
`;

export default UserRow;
