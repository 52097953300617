import {NUMBER} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {amount, formattedNumber, number} from '../../types';

export default attribute({
  type: NUMBER,
  attribute: 'registration_cost',
  display: amount,
  input: formattedNumber,
  api: number,
  label: {
    default: 'Cost',
    short: 'Cost',
  },
});
