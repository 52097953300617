import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {hide as hideAct} from './redux/actions';
import {set as setAct} from '../../redux/actions';

// Attributes
import decommissionDateAttr from '../../attributes/decommission_date.attribute.vehicle';
import reasonAttr from '../../attributes/reason.attribute.vehicle';

// Api
import decommissionApi from '../../api/decommission.api.vehicle';

// Components
import DecommissionVehicleModal from '../../components/DecommissionVehicleModal/DecommissionVehicleModal';

// Lib
import {lib} from '@matthahn/sally-ui';
import parseError from '../../../error/parseError';

// Permissions
import decommissionPermission from '../../permissions/decommission.permission.vehicle';

// Preps
import decommissionPrep from '../../preparation/decommission.preparation.vehicle';

// Alert
const {alert} = lib;

class DecommissionVehicleContainer extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    vehicle: PropTypes.object,
  };

  static STATE = {
    loading: false,
    decommission_date: decommissionDateAttr(''),
    reason: reasonAttr(''),
  };

  state = {...this.constructor.STATE};

  componentDidMount() {
    if (this.props.visible) this.init();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) this.init();
  }

  init = () => {
    this.setState({...this.constructor.STATE});
  };

  onClose = () => {
    const {dispatch} = this.props;
    const {loading} = this.state;
    if (loading) return;
    dispatch(hideAct());
  };

  onChange = (val, key) => {
    if (this.state.loading) return;
    this.setState({[key]: val});
  };

  save = async () => {
    const {dispatch, visible, vehicle} = this.props;
    const {loading, ...attributes} = this.state;

    if (loading || !visible || !vehicle) return;

    if (!decommissionPermission())
      return alert.warning(
        'You do not have permission to decommission vehicles'
      );

    this.setState({loading: true});

    try {
      const vehicleDecommission = await decommissionPrep(attributes);
      const response = await decommissionApi(vehicle.id, vehicleDecommission);
      alert.success('Vehicle decommission');
      dispatch(hideAct());
      dispatch(setAct({vehicle: response}));
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
    }

    this.setState({loading: false});
  };

  render() {
    const {visible} = this.props;
    const {loading, decommission_date, reason} = this.state;
    return (
      <DecommissionVehicleModal
        visible={visible}
        loading={loading}
        decommission_date={decommission_date}
        reason={reason}
        onClose={this.onClose}
        onChange={this.onChange}
        onSave={this.save}
      />
    );
  }
}

export default connect((state) => ({...state.decommissionVehicle}))(
  DecommissionVehicleContainer
);
