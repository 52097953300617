import status from './wrapper.status';

import api from '../api/markAsWaiting.api.ticket';

// Statuses
import sallyToPay from './sally_to_pay.status.ticket';

export default status({
  key: 'waiting',
  label: 'Driver responsibility. Sally will charge only at due date.',
  api,
  transition: [sallyToPay],
  icon: 'ellipsis1',
});
