import attachDataToFn from '../lib/attachDataToFn';

export default ({
  postSave = async () => {},
  attributes = [],
  defaultFileName,
  fileAttributes = [],
  fileName = () => 'Please specify a filename function',
  initAttributes = ({attributes}) => [...attributes].map((attr) => attr('')),
  manual = null,
  folderName,
  preSaveValidation = async () => null,
  selection = [],
  type,
  dynamicFormData = () => null,
  optionalAttributes = [],
  defaultObjectData = {},
  uploadable = true,
  signature = false,
}) => {
  const folder = {
    attributes,
    attributesList: [...attributes].map(({attribute}) => attribute),
    defaultFileName,
    dynamicFormData,
    fileAttributes,
    fileName,
    initAttributes: ({...data} = {}) =>
      initAttributes({attributes, ...data}).reduce(
        (setAttributes, attribute) => ({
          ...setAttributes,
          [attribute.attribute]: attribute,
        }),
        {}
      ),
    manual,
    folderName,
    optionalAttributes,
    preSaveValidation,
    postSave,
    selection,
    type,
    uploadable,
    signature,
    defaultObjectData,
  };
  return attachDataToFn((...folders) => {
    if (!!folders.length) folder.folders = folders;
    return folder;
  }, folder);
};
