import styled from 'styled-components';

// colors
import {grey} from '@matthahn/sally-ui/lib/libs/colors';

const UsersListFlex = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${grey};
  gap: 1px;
`;

export default UsersListFlex;
