import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {Card, Table, Button} from '@matthahn/sally-ui';
import NegativeParenthesis from '../../../layout/components/NegativeParenthesis/NegativeParenthesis';
import TripVoided from '../../../trip/components/TripVoided/TripVoided';
import Break from './components/Break';
import More from './components/More';
import ButtonsRow from './components/ButtonsRow';
import ButtonCol from './components/ButtonCol';

// Columns
import columns from './columns';

// Lib
import isTripTransaction from '../../../transaction/lib/isTripTransaction.lib.transaction';

const DriverTransactionsCard = ({
  loading,
  loadingStatement,
  transactions,
  statementYear,
  filter,
  filters,
  onFilter,
  more,
  onMore,
  onTransaction,
  onStatement,
}) => (
  <Card
    title="Transactions"
    icon="swap"
    headerActions={[
      {
        loading: loadingStatement,
        icon: 'download',
        // tooltip: `Download ${statementYear} statement`,
        tooltip: 'Generate statement',
        onClick: onStatement,
      },
    ]}
  >
    {(Content) => (
      <Fragment>
        <Content>
          <ButtonsRow>
            {filters.map((f) => (
              <ButtonCol key={f.key}>
                <Button
                  onClick={onFilter(f.key)}
                  theme={f.key === filter ? 'orange' : 'grey'}
                  size="small"
                >
                  {f.label}
                </Button>
              </ButtonCol>
            ))}
          </ButtonsRow>
        </Content>
        <Content padding="none">
          <Table
            columns={columns}
            loading={loading && !transactions.length}
            theme="orange"
            infinite
            noBorder
            noRadius
            smallNoResultsLabel
          >
            {(TableRow) =>
              transactions.map((transaction) => (
                <TableRow
                  key={transaction.id}
                  onClick={onTransaction(transaction)}
                >
                  {(TableColumn) => [
                    <TableColumn key="date">
                      <TripVoided trip={transaction.trip}>
                        {transaction.postedAt}
                      </TripVoided>
                    </TableColumn>,
                    <TableColumn key="type">
                      <TripVoided trip={transaction.trip}>
                        {transaction.computedType}
                      </TripVoided>
                    </TableColumn>,
                    <TableColumn key="amount">
                      <TripVoided trip={transaction.trip}>
                        <NegativeParenthesis
                          wrap={
                            isTripTransaction(transaction)
                              ? transaction.amount < 0
                              : transaction.income
                          }
                          value={transaction.amount}
                          pre="$"
                        />
                      </TripVoided>
                    </TableColumn>,
                    <TableColumn key="num_service">
                      {isTripTransaction(transaction)
                        ? transaction.trip.num_service
                        : '-'}
                    </TableColumn>,
                    <TableColumn key="description" span={3}>
                      <TripVoided trip={transaction.trip}>
                        <Break>{transaction.computedDescription}</Break>
                      </TripVoided>
                    </TableColumn>,
                  ]}
                </TableRow>
              ))
            }
          </Table>
          {more && !!transactions.length && (
            <More>
              <Button
                theme="orange"
                loading={loading}
                onClick={onMore}
                size="tiny"
                outline
              >
                Load More
              </Button>
            </More>
          )}
        </Content>
      </Fragment>
    )}
  </Card>
);

DriverTransactionsCard.propTypes = {
  loading: PropTypes.bool,
  transactions: PropTypes.array,
  statementYear: PropTypes.number,
  filter: PropTypes.string,
  filters: PropTypes.array,
  onFilter: PropTypes.func,
  more: PropTypes.bool,
  onMore: PropTypes.func,
  onTransaction: PropTypes.func,
  onStatement: PropTypes.func,
};

export default DriverTransactionsCard;
