import styled from 'styled-components';

// Colors
import {lib} from '@matthahn/sally-ui';
const {
  colors: {grey, darkGrey},
} = lib;

const TableHeader = styled.div`
  width: 100%;
  display: flex;
  background: ${grey};
  color: ${darkGrey};
  text-transform: uppercase;
  font-weight: 700;
  font-size: 15px;
`;

export default TableHeader;
