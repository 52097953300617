import {SELECT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

export default attribute({
  type: SELECT,
  attribute: 'accepting_payment_method',
  label: {
    default: 'Payment Method',
    short: 'Method',
  },
  options: [
    {
      value: 'cash',
      label: 'Cash',
    },
    {
      value: 'check',
      label: 'Check',
    },
  ],
});
