import styled from 'styled-components';

// Colors
import {red} from '@matthahn/sally-ui/lib/libs/colors';

const FileLoader = styled.div`
  width: 100%;
  height: 563px;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${red};
`;

export default FileLoader;
