import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

// Events
import event from '../events/updated.event.accidentPartyInvolved';

export default async (accidentId, partyInvolvedId, accident) => {
  const response = await f3tch(
    `/accidents/dol/${accidentId}/party_involved/${partyInvolvedId}/`
  )
    .profile(profile())
    .body(accident)
    .patch();
  event.pub(response);
  return response;
};
