import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {v4} from 'uuid';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

// Api
import {containsInsensitive} from '../../../api/queries/queries';
import listMedallionsApi from '../../api/list.api.medallion';

// Components
import MedallionsList from '../../components/MedallionsList/MedallionsList';
import NewMedallionModal from '../../components/NewMedallionModal/NewMedallionModal';

// Containers
import NewMedallionContainer from '../NewMedallionContainer/NewMedallionContainer';

// Libs
import {lib} from '@matthahn/sally-ui';
import sortQuery from '../../../api/lib/sortQuery.lib.api';
import numberOfPages from '../../../lib/numberOfPages';

// Route
import medallionRoute from '../../pages/MedallionPage/route';

// Layout
import {clearUnsavedChanges} from '../../../layout/lib/unsavedChanges.lib.layout';

// Permissions
import createPermission from '../../permissions/create.permission.medallion';

// Alert
const {alert, notify} = lib;

class MedallionsListContainer extends Component {
  static propTypes = {
    unsavedChanges: PropTypes.bool,
    history: PropTypes.object,
  };

  static OPTIONS = {
    perPage: 50,
  };

  static DEFAULT_SORT = {
    key: 'accessed_at',
    direction: 'desc',
  };

  state = {
    results: 0,
    page: 1,
    loading: true,
    medallions: [],
    sort: {...this.constructor.DEFAULT_SORT},
    newMedallionModalVisible: false,
    lastNewMedallionUpdate: null,
    search: '',
  };

  componentDidMount() {
    this.mounted = true;
    this.getMedallions();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  mounted = false;
  apiID = v4();
  savingNewMedallion = false;

  onPage = (page) => this.getMedallions({page});

  onSearch = (search) => this.getMedallions({search});

  onSort = (sort) => this.getMedallions({sort});

  pages = () =>
    numberOfPages(this.state.results, this.constructor.OPTIONS.perPage);

  getMedallions = async ({
    page = this.state.page,
    sort = this.state.sort,
    search = this.state.search,
  } = {}) => {
    const apiID = v4();
    this.apiID = apiID;
    const {perPage} = this.constructor.OPTIONS;
    this.setState({loading: true, medallions: [], page, sort, search});
    const query = {
      ...sortQuery(sort || this.constructor.DEFAULT_SORT),
      limit: perPage,
      offset: (page - 1) * perPage,
    };

    if (!!search.trim().length)
      query[containsInsensitive('medallion_number')] = search;

    try {
      const {results, count} = await listMedallionsApi(query);
      if (this.apiID !== apiID || !this.mounted) return;
      this.setState({medallions: results, loading: false, results: count});
    } catch (error) {
      if (this.apiID !== apiID || !this.mounted) return;
      alert.info('The request could not be made');
      this.setState({loading: false, medallions: [], results: 0});
    }
  };

  onMedallion = (id) => () => this.props.history.push(medallionRoute(id));

  onNewMedallion = () =>
    this.setState({
      newMedallionModalVisible: true,
      lastNewMedallionUpdate: v4(),
    });

  onNewMedallionClose = (prompt = true) => {
    if (this.savingNewMedallion) return;

    if (this.props.unsavedChanges && prompt)
      return notify({
        id: 'closeMedallionCreationModal',
        title: 'Close',
        icon: undefined,
        content: 'Are you sure you want to close?',
        primary: {
          label: 'No',
          onClick: () => null,
        },
        secondary: {
          label: 'Yes',
          onClick: () => this.onNewMedallionClose(false),
        },
        closable: false,
        closeOnOutsideClick: true,
      });

    clearUnsavedChanges();

    this.setState({newMedallionModalVisible: false});
  };

  onMedallionSave = (save) => {
    this.savingNewMedallion = save;
  };

  search = (search) => this.getMedallions({search, page: 1});

  render() {
    const {
      page,
      loading,
      medallions,
      sort,
      results,
      newMedallionModalVisible,
      lastNewMedallionUpdate,
      search,
    } = this.state;
    return (
      <Fragment>
        <MedallionsList
          hasPermission={createPermission()}
          results={results}
          loading={loading}
          medallions={medallions}
          page={page}
          pages={this.pages()}
          onPage={this.onPage}
          sort={sort}
          onSort={this.onSort}
          onMedallion={this.onMedallion}
          onNewMedallion={this.onNewMedallion}
          onSearch={this.search}
          search={search}
        />
        <NewMedallionContainer
          lastUpdate={lastNewMedallionUpdate}
          onLoad={this.onMedallionSave}
        >
          {({...props}) => (
            <NewMedallionModal
              {...props}
              visible={newMedallionModalVisible}
              onClose={this.onNewMedallionClose}
            />
          )}
        </NewMedallionContainer>
      </Fragment>
    );
  }
}

export default withRouter(
  connect((state) => ({unsavedChanges: state.layout.unsavedChanges}))(
    MedallionsListContainer
  )
);
