import {NUMBER} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

export default attribute({
  type: NUMBER,
  attribute: 'id',
  label: {
    default: 'Medallion ID',
    short: 'ID',
  },
});
