import styled from 'styled-components';

// Colors
import {lib} from '@matthahn/sally-ui';
const {
  colors: {grey},
} = lib;

const Container = styled.div`
  width: 100%;
  padding: 7px 15px;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;

  &:hover {
    background: ${grey};
  }
`;

export default Container;
