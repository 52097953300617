import {TOGGLE} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

export default attribute({
  type: TOGGLE,
  attribute: 'police_called',
  label: {
    default: 'Police notified',
    short: 'Police notified',
  },
});
