import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

export default (payout) =>
  f3tch('/payments/payouts/payout_rules/')
    .profile(profile())
    .body(payout)
    .post();
