import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// alert components
import PayoutDescription from '../../../alert/components/PayoutDescription/PayoutDescription';

// Components
import {
  Card,
  Table,
  CheckBox,
  Row,
  Column,
  InputTyping,
  Button,
  Label,
  P,
} from '@matthahn/sally-ui';
import DriverTags from '../../../driver/components/DriverTags/DriverTags';
import Check from './components/Check';

// Columns
import columns from './columns';

// payment lib
import isPaymentSelected from '../../lib/isSelected.lib.payment';

// Types
import {amount, dateTime} from '../../../types';

const ApprovePaymentsCard = ({
  loadingBalance,
  balance,
  loading,
  approving,
  rejecting,
  downloading,
  payments,
  search,
  selected,
  onSelect,
  onSelectAll,
  onDriver,
  onReject,
  onApprove,
  onSearch,
  onAction,
}) => (
  <Card
    title="Pending Approval"
    icon="edit"
    headerActions={
      !loading && !!payments.length
        ? [
            {
              tooltip: 'Select All',
              icon: 'check',
              onClick: onSelectAll,
            },
          ]
        : []
    }
    buttonsLeft={
      !loading && !!payments.length
        ? [
            {
              label: 'Reject',
              disabled: approving || !selected.length,
              loading: rejecting,
              onClick: onReject,
            },
          ]
        : []
    }
    buttonsRight={
      !loading && !!payments.length
        ? [
            {
              label: 'Approve',
              loading: approving,
              disabled: rejecting || !selected.length,
              theme: 'grey',
              onClick: onApprove,
            },
          ]
        : []
    }
  >
    {(Content) => (
      <Fragment>
        <Content>
          <Row>
            <Column size={1 / 2}>
              <InputTyping
                preIcon="search1"
                placeholder="Search"
                value={search}
                onChange={onSearch}
                size="large"
              />
            </Column>
            <Column size={1 / 4} offset={1 / 4}>
              <Label label="Dwolla Balance">
                {loadingBalance ? 'Loading..' : `$${amount(balance).format()}`}
              </Label>
            </Column>
          </Row>
        </Content>
        <Content padding="none">
          <Table
            columns={columns}
            loading={loading}
            theme="orange"
            infinite
            noBorder
            noRadius
            smallNoResultsLabel
          >
            {(TableRow) =>
              payments.map((payment) =>
                payment.type === 'alert' ? (
                  <TableRow
                    key={payment.computedId}
                    onClick={onAction(payment)}
                  >
                    {(TableColumn) => [
                      <TableColumn key="driver" span={2}>
                        <P size="inherit" theme="darkGrey">
                          {!!payment?.driver?.first_name
                            ? `${payment?.driver?.first_name} ${payment?.driver?.last_name}`
                            : `Driver #${payment.driver}`}
                        </P>
                      </TableColumn>,
                      <TableColumn key="date">
                        <P size="inherit" theme="darkGrey">
                          {dateTime(payment.created_at).format()}
                        </P>
                      </TableColumn>,
                      <TableColumn key="description" span={4}>
                        <P size="inherit" theme="darkGrey">
                          <PayoutDescription message={payment.message} />
                        </P>
                      </TableColumn>,
                    ]}
                  </TableRow>
                ) : (
                  <TableRow key={payment.computedId}>
                    {(TableColumn) => [
                      <TableColumn key="number" onClick={onSelect(payment)}>
                        <Check>
                          <CheckBox
                            value={isPaymentSelected({
                              payment,
                              payments: selected,
                            })}
                            onChange={() => {}}
                            size="small"
                          />
                        </Check>
                        {payment.id}
                      </TableColumn>,
                      <TableColumn key="amount" onClick={onSelect(payment)}>
                        ${amount(payment.amount).format()}
                      </TableColumn>,
                      <TableColumn key="date" onClick={onSelect(payment)}>
                        {dateTime(payment.created_at).format()}
                      </TableColumn>,
                      <TableColumn key="account" onClick={onSelect(payment)}>
                        {payment.type === 'ach'
                          ? payment?.funding_source?.funding_name || '-'
                          : 'Check'}
                      </TableColumn>,
                      <TableColumn
                        key="driver"
                        onClick={onDriver(payment.driver)}
                      >
                        <DriverTags driver={payment.driver}>
                          {payment.driver.first_name} {payment.driver.last_name}
                        </DriverTags>
                      </TableColumn>,
                      <TableColumn key="medallion" onClick={onSelect(payment)}>
                        {!!payment.medallion &&
                        !!payment.medallion.medallion_number
                          ? payment.medallion.medallion_number
                          : '-'}
                      </TableColumn>,
                      <TableColumn key="report">
                        <Button
                          onClick={onAction(payment)}
                          loading={downloading.includes(payment.computedId)}
                          size="small"
                          theme="grey"
                          icon="download"
                        >
                          Report
                        </Button>
                      </TableColumn>,
                    ]}
                  </TableRow>
                )
              )
            }
          </Table>
        </Content>
      </Fragment>
    )}
  </Card>
);

ApprovePaymentsCard.propTypes = {
  loadingBalance: PropTypes.bool,
  balance: PropTypes.number,
  loading: PropTypes.bool,
  approving: PropTypes.bool,
  rejecting: PropTypes.bool,
  downloading: PropTypes.array,
  selected: PropTypes.array,
  payments: PropTypes.array,
  search: PropTypes.string,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  onApprove: PropTypes.func,
  onReject: PropTypes.func,
  onSearch: PropTypes.func,
  onDriver: PropTypes.func,
  onAction: PropTypes.func,
};

export default ApprovePaymentsCard;
