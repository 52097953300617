import fileExtension from 'file-extension';

// Api
import create from './create.api.document';
import remove from './delete.api.document';
import updateAccidentApi from '../../accident/api/update.api.accident';
import updateDriverApi from '../../driver/api/update.api.driver';
import updateRentalApi from '../../rental/api/update.api.rental';
import updateVehicleApi from '../../vehicle/api/update.api.vehicle';
import updateMedallionApi from '../../medallion/api/update.api.medallion';
import updateTicketApi from '../../ticket/api/update.api.ticket';

// Attributes
import {filename as filenameAttr} from '../attributes';

// Errors
import {
  missingDoc,
  missingFile,
  missingRequiredObject,
  undefinedObject,
} from '../errors';

// Events
import docCreatedEvt from '../events/created.event.document';

// Preparation
import {upload as uploadPrep} from '../preparations';

// Helpers
const updateApi = {
  accident: updateAccidentApi,
  driver: updateDriverApi,
  rental: updateRentalApi,
  vehicle: updateVehicleApi,
  medallion: updateMedallionApi,
  ticket: updateTicketApi,
  lease: () => null,
};

export default async ({
  doc = null,
  file = null,
  accident = null,
  driver = null,
  vehicle = null,
  rental = null,
  medallion = null,
  ticket = null,
  lease = null,
  data = {},
  rawDocumentData = {},
  type = null,
  duplicates = [],
  upload = true,
} = {}) => {
  if (!doc && upload)
    throw missingDoc({message: 'Please select a document to upload'});
  if (!file) throw missingFile({message: 'No file specified'});

  const combinedData = {
    accident,
    driver,
    vehicle,
    rental,
    medallion,
    ticket,
    duplicates,
    lease,
    ...data,
  };

  const object = {accident, driver, rental, vehicle, medallion, ticket}[type];
  if (!object)
    throw undefinedObject({
      message: `Please provide a reference object "${type}"`,
    });

  if (file.selection.includes('accident') && !accident)
    throw missingRequiredObject({message: 'Please select an accident'});
  if (file.selection.includes('driver') && !driver)
    throw missingRequiredObject({message: 'Please select a driver'});
  if (file.selection.includes('rental') && !rental)
    throw missingRequiredObject({message: 'Please select a rental'});
  if (file.selection.includes('vehicle') && !vehicle)
    throw missingRequiredObject({message: 'Please select a vehicle'});
  const preparedData = await uploadPrep(
    data,
    Object.keys(data).filter(
      (required) => !file.optionalAttributes.includes(required)
    )
  );
  await file.preSaveValidation(combinedData);

  if (!upload) return null;

  const objectID = object.id;
  const extension = fileExtension(doc.name);

  const {fileData, objectData} = Object.entries(preparedData)
    .filter(([key]) => key !== filenameAttr.attribute)
    .reduce(
      (combined, [key, value]) => {
        const dataType = file.fileAttributes.includes(key)
          ? 'fileData'
          : 'objectData';
        return {...combined, [dataType]: {...combined[dataType], [key]: value}};
      },
      {fileData: {}, objectData: {}}
    );

  const fileName = file.fileName({...combinedData, extension});
  const formMeta = new FormData();

  formMeta.append('document_file', doc, fileName);
  formMeta.append('name', fileName);
  if (!!accident) formMeta.append('accident', accident.id);
  if (!!driver) formMeta.append('driver', driver.id);
  if (!!vehicle) formMeta.append('vehicle', vehicle.id);
  if (!!rental) formMeta.append('rental', rental.id);
  if (!!medallion) formMeta.append('medallion', medallion.id);
  if (!!ticket) formMeta.append('ticket', ticket.id);
  if (!!lease) formMeta.append('lease', lease.id);

  formMeta.append('type', file.type);
  formMeta.append(
    'extra_data',
    JSON.stringify({
      ...objectData,
      ...fileData,
      ...rawDocumentData,
    })
  );

  const dynamicFormData = file.dynamicFormData(combinedData);

  const fileResponses = await Promise.all(
    !!dynamicFormData
      ? dynamicFormData.map((dfd) => {
          Object.entries(dfd).forEach(([key, val]) =>
            formMeta.append(key, val)
          );
          return create(formMeta, false);
        })
      : [create(formMeta, false)]
  );

  let objectResponse = null;
  if (
    !!Object.keys(objectData).length ||
    !!Object.keys(file.defaultObjectData).length
  ) {
    try {
      objectResponse = await updateApi[type](objectID, {
        ...objectData,
        ...file.defaultObjectData,
      });
    } catch (error) {
      await Promise.all(
        fileResponses.map((blob) =>
          blob.text().then((txt) => {
            const result = JSON.parse(txt);
            remove(result.id);
          })
        )
      );
      throw error;
    }
  }

  await file.postSave({accident, driver, rental, vehicle, ...preparedData});

  fileResponses.forEach((fileResp) => docCreatedEvt.pub(fileResp));

  return {files: fileResponses, object: objectResponse};
};
