import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Attributes
import contractTypeAttr from '../../attributes/contract_type.attribute.lease';
import startTimeAttr from '../../attributes/start_datetime.attribute.lease';
import driverAttr from '../../attributes/driver.attribute.lease';
import amountAttr from '../../../rental/attributes/amount.attribute.rental';
import medallionAttr from '../../../rental/attributes/medallion.attribute.rental';
import vehiclePickupAttr from '../../../rental/attributes/vehicle_pickup_datetime.attribute.rental';

// Api
import listLeasesApi from '../../api/list.api.lease';
import createLeaseApi from '../../api/create.api.lease';
import validateRateApi from '../../../dispatch/api/validateRate.api.dispatch';

// Components
import DispatchLeaseStep from '../../components/DispatchLeaseStep/DispatchLeaseStep';

// Constants
import DEFAULT_DAILY_RENT from '../../../lease/constants/defaultDailyRent.const.lease';

// dispatch constants
import DANGEROUS_DAILY_RATE from '../../../dispatch/constants/dangerousDailyRate.constant.dispatch';

// Libs
import {lib} from '@matthahn/sally-ui';
import notify from '@matthahn/sally-ui/lib/libs/notify';
import parseError from '../../../error/parseError';
import isDailyLease from '../../../lease/lib/isDaily.lib.lease';

// Prep
import createLeasePrep from '../../preparation/create.preparation.lease';
import createRentalPrep from '../../../rental/preparation/create.preparation.rental';

// Types
import {apiDateTime} from '../../../types';

// Alert
const {alert} = lib;

class DispatchLeaseStepContainer extends Component {
  static propTypes = {
    Container: PropTypes.func,
    visible: PropTypes.bool,
    loadingContractTypes: PropTypes.bool,
    contractTypes: PropTypes.array,
    rental: PropTypes.object,
    lease: PropTypes.object,
    onlyWeekly: PropTypes.bool,
    driver: PropTypes.object,
    medallion: PropTypes.object,
    hotswap: PropTypes.bool,
    onBack: PropTypes.func,
    onNext: PropTypes.func,
    onDisabled: PropTypes.func,
  };

  state = {
    loading: false,
    saving: false,
    lease: null,
    contract_type: contractTypeAttr(''),
    amount: amountAttr(''),
    vehicle_pickup_datetime: vehiclePickupAttr(
      apiDateTime(new Date()).format()
    ),
  };

  componentDidMount() {
    if (this.props.visible) this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.visible && this.props.visible) this.init();
  }

  init = async () => {
    const {lease, rental, driver, onDisabled} = this.props;

    this.setState({loading: true});
    onDisabled(true);

    try {
      if (!!lease) {
        onDisabled(false);
        return this.setState({
          ...this.toAttr({lease, rental}),
          loading: false,
        });
      }

      const {results: leases} = await listLeasesApi({
        driver: driver.id,
        ordering: '-created_at',
      });
      const driverLease = [...leases].find((l) => l.driver === driver.id);
      const state = {
        loading: false,
      };
      const addonState = this.toAttr({
        lease: driverLease,
        rental,
      });
      this.setState({
        ...state,
        ...addonState,
      });
    } catch (error) {
      alert.error('Something went wrong. Please close the flow and try again');
      this.setState({loading: false});
    }

    onDisabled(false);
  };

  toAttr = ({lease = null, rental = null} = {}) => ({
    lease,
    rental,
    contract_type: contractTypeAttr(!!lease ? lease.contract_type : ''),
    amount: !!rental?.amount
      ? amountAttr(rental.amount)
      : !!lease && isDailyLease(lease) && !lease.amount
      ? amountAttr(DEFAULT_DAILY_RENT)
      : amountAttr(!!rental ? rental.amount : ''),
    vehicle_pickup_datetime: vehiclePickupAttr(
      !!rental
        ? rental.vehicle_pickup_datetime
        : apiDateTime(new Date()).format()
    ),
  });

  onChange = (val, key) => {
    const {loading, saving, amount} = this.state;
    if (loading || saving) return;
    const state = {[key]: val};
    if (
      key === 'contract_type' &&
      !amount.api.format() &&
      isDailyLease({contract_type: val.api.format()})
    )
      state.amount = amountAttr(DEFAULT_DAILY_RENT);
    this.setState(state);
  };

  save = async ({prompt = true} = {}) => {
    const {onNext, onDisabled, driver, medallion} = this.props;
    const {
      loading,
      lease: existingLease,
      saving,
      amount,
      contract_type,
      vehicle_pickup_datetime,
    } = this.state;

    if (prompt && (loading || saving)) return;

    const availableRentalTypes = this.contractTypes();
    const availableRentalType = [...availableRentalTypes].find(
      ({key}) => key === contract_type.api.format()
    );

    if (!existingLease && !!contract_type.api.format() && !availableRentalType)
      return alert.warning('Select an available contract type');

    if (this.disableContinuing())
      return alert.warning('Driver needs to have a weekly lease contract');

    this.setState({loading: true});
    onDisabled(true);

    const createLease =
      !existingLease ||
      contract_type.api.format() !== existingLease?.contract_type;

    try {
      const preparedLease = !createLease
        ? {...existingLease}
        : await createLeasePrep({
            contract_type,
            driver: driverAttr(driver.id),
            start_datetime: startTimeAttr(new Date()),
          });

      const rental = await createRentalPrep({
        amount,
        vehicle_pickup_datetime,
        driver: driverAttr(driver.id),
        medallion: medallionAttr(medallion.id),
        isDaily: isDailyLease(preparedLease),
      });

      if (
        prompt &&
        isDailyLease(preparedLease) &&
        rental.amount > DANGEROUS_DAILY_RATE
      )
        return notify({
          title: 'Warning',
          content: `Are you sure you want to set the amount to $${rental.amount}?`,
          primary: {
            label: 'No',
            onClick: () => {
              this.setState({loading: false});
              onDisabled(false);
            },
          },
          secondary: {
            label: 'Yes',
            onClick: () => this.save({prompt: false}),
          },
        });

      const {valid} = await validateRateApi({
        rate: rental.amount,
        rental_type: preparedLease.contract_type,
        vehicle_type: medallion.vehicle.vehicle_fuel_type,
      });
      if (!valid) {
        alert.warning('Invalid amount');
        this.setState({loading: false});
        onDisabled(false);
        return;
      }
      const lease = !createLease
        ? {...existingLease}
        : await createLeaseApi(preparedLease);
      await onDisabled(false);
      onNext({lease, rental});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({loading: false});
      onDisabled(false);
    }
  };

  back = () => {
    const {loading, lease, rental} = this.state;
    if (loading) return;
    this.props.onBack({lease, rental});
  };

  next = () => {
    const {lease, rental} = this.state;
    this.props.onNext({lease, rental});
  };

  contractTypes = () => {
    const {onlyWeekly, contractTypes} = this.props;
    return onlyWeekly
      ? [...contractTypes].filter(({key}) => key.startsWith('weekly'))
      : contractTypes;
  };

  disableContinuing = () => {
    // const {hotswap} = this.props;
    // const {lease} = this.state;
    // return hotswap && !!lease && isDailyLease(lease);
    return false;
  };

  render() {
    const {Container, driver, loadingContractTypes} = this.props;
    const {
      loading,
      saving,
      amount,
      contract_type,
      vehicle_pickup_datetime,
      lease,
    } = this.state;
    return (
      <DispatchLeaseStep
        Container={Container}
        loading={loading || loadingContractTypes}
        saving={saving}
        leaseExists={!!lease}
        driver={driver}
        contractTypes={this.contractTypes()}
        amount={amount}
        contract_type={contract_type}
        vehicle_pickup_datetime={vehicle_pickup_datetime}
        disableContinuing={this.disableContinuing()}
        onChange={this.onChange}
        onAction={this.save}
        onBack={this.back}
      />
    );
  }
}

export default connect((state) => ({
  loadingContractTypes: state.lease.loadingContractTypes,
  contractTypes: state.lease.contractTypes,
}))(DispatchLeaseStepContainer);
