export default [
  'email',
  'first_name',
  'last_name',
  'phone_number',
  'ssn',
  'address_street1',
  'address_city',
  'address_zip',
  'address_state',
  'fhv_license_number',
  'fhv_license_expiry',
  'date_of_birth',
  'dmv_license_state',
  'dmv_license_number',
  'dmv_license_expiry',
  'dmv_license_issue_date',
];
