import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {vin} from '../../types';

export default attribute({
  type: TEXT,
  attribute: 'vin',
  display: vin,
  input: vin,
  api: vin,
  label: {
    default: 'VIN',
    short: 'VIN',
  },
});
