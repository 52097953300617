import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Table, Row, Column, Text, P} from '@matthahn/sally-ui';
import NegativeParenthesis from '../../../layout/components/NegativeParenthesis/NegativeParenthesis';
import Container from './components/Container';
import Content from './components/Content';
import Label from './components/Label';

// Columns
import columns from './columns';

// Type
import {dateTime} from '../../../types';

const PayoutOverview = ({payout}) =>
  !payout ? null : (
    <Container>
      <Content padding>
        <Row margin>
          <Column>
            <Text
              value={`by ${payout.payout_by.username} on ${dateTime(
                payout.payout_date
              ).format()}`}
            >
              Performed
            </Text>
          </Column>
        </Row>
        <Row margin>
          <Column>
            <Text
              value={`${payout.driver.first_name} ${payout.driver.last_name}`}
            >
              Driver
            </Text>
          </Column>
        </Row>
        <Row margin>
          <Column size={1 / 2}>
            <Text value={payout.payment_method || 'cash'}>Payment method</Text>
          </Column>
          <Column size={1 / 2}>
            <Text
              value={
                <NegativeParenthesis value={payout.rental_amount} pre="$" />
              }
            >
              Rental Rate
            </Text>
          </Column>
        </Row>
        <Row>
          <Column size={1 / 2}>
            <Text
              value={
                <NegativeParenthesis
                  value={payout.drivers_previous_balance}
                  pre="$"
                />
              }
            >
              Pre-Payout Balance
            </Text>
          </Column>
          <Column size={1 / 2}>
            <Text
              value={
                <NegativeParenthesis
                  value={payout.drivers_current_balance}
                  pre="$"
                />
              }
            >
              Post-Payout Balance
            </Text>
          </Column>
        </Row>
      </Content>
      <Content>
        <Table
          columns={columns}
          theme="orange"
          infinite
          noBorder
          noRadius
          smallNoResultsLabel
        >
          {(TableRow) => [
            ...payout.allocation.map((charge) => (
              <TableRow key={charge.key}>
                {(TableColumn) => [
                  <TableColumn key="type">
                    <Label>{charge.label}</Label>
                  </TableColumn>,
                  <TableColumn key="payment">
                    <Label>
                      <NegativeParenthesis
                        value={charge.amount}
                        pre="$"
                        empty="-"
                      />
                    </Label>
                  </TableColumn>,
                ]}
              </TableRow>
            )),
            <TableRow key="earnings">
              {(TableColumn) => [
                <TableColumn key="type">
                  <Label>
                    {payout.earnings_amount > 0 ? 'Earnings' : 'Payment'}
                  </Label>
                </TableColumn>,
                <TableColumn key="payment">
                  <P
                    theme={payout.earnings_amount > 0 ? 'green' : 'red'}
                    weight="bold"
                  >
                    <NegativeParenthesis
                      wrap={payout.earnings_amount > 0}
                      value={payout.earnings_amount}
                      pre="$"
                    />
                  </P>
                </TableColumn>,
              ]}
            </TableRow>,
          ]}
        </Table>
      </Content>
    </Container>
  );

PayoutOverview.propTypes = {
  payout: PropTypes.object,
};

export default PayoutOverview;
