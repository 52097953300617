import {SELECT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {string} from '../../types';

export default attribute({
  type: SELECT,
  attribute: 'insurance_liability_broker',
  api: string,
  label: {
    default: 'Insurance Broker',
    short: 'Broker',
  },
  options: ['PEARLAND', 'SOLID', 'CITY TAXI'],
});
