import React from 'react';

// Components
import GeneralChequeLayout from '../../components/GeneralChequeLayout/GeneralChequeLayout';

// Containers
import Page from '../../../layout/containers/Page/Page';

// Page
import PAGE from './page';

const DriverDocumentsPage = () => (
  <Page page={PAGE}>
    <GeneralChequeLayout />
  </Page>
);

export default DriverDocumentsPage;
