import {isAfter, isSameMonth, isSameYear} from 'date-fns';

// Lib
import toDate from '../../lib/toDate';

export default (vehicle) => {
  if (!vehicle.registration)
    return {
      status: 'warning',
      message: 'Perform registration',
      file: 'registration',
    };

  const registration_pickup_status =
    vehicle.registration_pickup_status === null
      ? true
      : vehicle.registration_pickup_status;

  const expired = isAfter(new Date(), toDate(vehicle.registration_expiry));
  const aboutToExpire =
    isSameYear(new Date(), toDate(vehicle.registration_expiry)) &&
    isSameMonth(new Date(), toDate(vehicle.registration_expiry));

  if (registration_pickup_status && (expired || aboutToExpire))
    return {
      status: 'error',
      message: 'Vehicle needs a new registration',
      file: 'registration',
    };

  return !registration_pickup_status
    ? {
        status: 'warning',
        message: 'Upload picture of Registration',
        file: 'registrationConfirm',
      }
    : {status: 'done', message: 'Registration', file: 'registration'};
};
