import React from 'react';
import PropTypes from 'prop-types';

// alert components
import AlertInfo from '../../../alert/components/AlertInfo/AlertInfo';
import AlertsWrapper from '../../../alert/components/AlertsWrapper/AlertsWrapper';

// alert containers
import AlertsContainer from '../../../alert/containers/AlertsContainer/AlertsContainer';

// components
import {Button, Modal, Heading, Icon} from '@matthahn/sally-ui';

// local components
import Loader from './components/Loader';

const content = ({
  Content,
  heading,
  icon,
  listApi,
  object,
  objectKey,
  onNewAlert,
  updateApi,
}) => (
  <Content padding="vertical" key={object.id}>
    <AlertsWrapper>
      <Content padding="horizontal" noBorder>
        <Heading>{heading}</Heading>
      </Content>
      <AlertsContainer
        object={object}
        objectType={objectKey}
        listApi={listApi}
        updateApi={updateApi}
      >
        {({loading, alerts}) =>
          loading ? (
            <Loader key={alert.id}>
              <Icon icon="loading2" spin />
            </Loader>
          ) : (
            [...alerts].map((alert) => (
              <AlertInfo alert={alert} {...alert.infoProps} icon={icon} />
            ))
          )
        }
      </AlertsContainer>
      <Content padding="horizontal" noBorder>
        <Button icon="plus" onClick={onNewAlert} theme="grey">
          Add alert
        </Button>
      </Content>
    </AlertsWrapper>
  </Content>
);

const DispatchAlertsModal = ({
  drivers,
  listDriverAlerts,
  listVehicleAlerts,
  onClose,
  onNewDriverAlert,
  onNewVehicleAlert,
  updateDriverAlert,
  updateVehicleAlert,
  vehicle,
  visible,
}) => (
  <Modal
    disableOutsideClickClose
    icon="warning"
    onClose={onClose}
    title="Alerts"
    visible={visible}
  >
    {(Content) =>
      !visible ? null : (
        <Content padding="none" noBorder>
          {drivers.map((driver) =>
            content({
              Content,
              heading: `${driver.first_name} ${driver.last_name}`,
              icon: 'user',
              listApi: listDriverAlerts,
              object: driver,
              objectKey: 'driver',
              onNewAlert: onNewDriverAlert(driver),
              updateApi: updateDriverAlert,
            })
          )}
          {!!vehicle &&
            content({
              Content,
              heading: vehicle?.medallion?.medallion_number || vehicle?.plate,
              icon: 'car',
              listApi: listVehicleAlerts,
              object: vehicle,
              objectKey: 'vehicle',
              onNewAlert: onNewVehicleAlert,
              updateApi: updateVehicleAlert,
            })}
        </Content>
      )
    }
  </Modal>
);

DispatchAlertsModal.propTypes = {
  drivers: PropTypes.array,
  listDriverAlerts: PropTypes.func,
  listVehicleAlerts: PropTypes.func,
  onClose: PropTypes.func,
  onNewDriverAlert: PropTypes.func,
  onNewVehicleAlert: PropTypes.func,
  updateDriverAlert: PropTypes.func,
  updateVehicleAlert: PropTypes.func,
  vehicle: PropTypes.object,
  visible: PropTypes.bool,
};

export default DispatchAlertsModal;
