import React, {Component} from 'react';
import {v4} from 'uuid';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {show as showTicketAct} from '../TicketContainer/redux/actions';

// Api
import listTicketsApi from '../../api/list.api.ticket';

// Components
import TicketsList from '../../components/TicketsList/TicketsList';

// Events
import updatedEvt from '../../events/updated.event.ticket';
import removedEvt from '../../events/removed.event.ticket';

// Libs
import {lib} from '@matthahn/sally-ui';
import sortQuery from '../../../api/lib/sortQuery.lib.api';
import numberOfPages from '../../../lib/numberOfPages';
import eventRace from '../../../events/race.event';

// Sockets
import createdSocket from '../../socket/created.socket.ticket';
import removedSocket from '../../socket/removed.socket.ticket';
import updatedSocket from '../../socket/updated.socket.ticket';

// Alert
const {alert} = lib;

class TicketsListContainer extends Component {
  static propTypes = {
    query: PropTypes.object,
    shouldUpdateOnEvent: PropTypes.func,
    dispatch: PropTypes.func,
  };

  static defaultProps = {
    query: {},
    shouldUpdateOnEvent: () => false,
  };

  static OPTIONS = {
    perPage: 50,
  };

  static DEFAULT_SORT = {
    key: 'due_datetime',
    direction: 'asc',
  };

  state = {
    results: 0,
    page: 1,
    loading: true,
    tickets: [],
    sort: {...this.constructor.DEFAULT_SORT},
    medallion: '',
  };

  componentDidMount() {
    this.mounted = true;
    this.getTickets();
    this.subscribers = [
      createdSocket.subscribe(this.ticketCreated),

      removedSocket.subscribe(this.ticketUpdated),
      removedEvt.sub(this.ticketUpdated),

      updatedSocket.subscribe(this.ticketUpdated),
      updatedEvt.sub(this.ticketUpdated),
    ];
  }

  componentWillUnmount() {
    this.mounted = false;
    this.subscribers.forEach((unsub) => unsub());
  }

  mounted = false;
  apiID = v4();
  subscribers = [];

  ticketCreated = (ticket) => {
    const {tickets} = this.state;
    if (!this.props.shouldUpdateOnEvent(ticket)) return;
    const newTickets = [...tickets].find(({id}) => id === ticket.id)
      ? [...tickets]
      : [ticket, ...tickets];
    this.setState({tickets: newTickets});
  };

  ticketUpdated = eventRace((ticket) => {
    if (!this.props.shouldUpdateOnEvent(ticket)) return;
    const newTickets = [...this.state.tickets].map((t) =>
      t.id === ticket.id ? ticket : t
    );
    this.setState({tickets: newTickets});
  });

  onPage = (page) => this.getTickets({page});

  onSort = (sort) => this.getTickets({sort});

  onMedallion = (medallion) => this.getTickets({medallion, page: 1});

  pages = () =>
    numberOfPages(this.state.results, this.constructor.OPTIONS.perPage);

  getTickets = async ({
    page = this.state.page,
    sort = this.state.sort,
    medallion = this.state.medallion,
  } = {}) => {
    const apiID = v4();
    this.apiID = apiID;
    const {perPage} = this.constructor.OPTIONS;
    this.setState({loading: true, tickets: [], page, sort, medallion});
    const query = {
      ...sortQuery(sort || this.constructor.DEFAULT_SORT),
      removed: false,
      limit: perPage,
      offset: (page - 1) * perPage,
      ...this.props.query,
    };

    if (!!medallion.trim().length) {
      const queryKey =
        medallion.trim().length > 4
          ? 'summons_number'
          : 'vehicle__medallion__medallion_number';
      query[queryKey] = medallion.toUpperCase();
    }

    try {
      const {results, count} = await listTicketsApi(query);
      if (this.apiID !== apiID || !this.mounted) return;
      this.setState({tickets: results, loading: false, results: count});
    } catch (error) {
      if (this.apiID !== apiID || !this.mounted) return;
      alert.info('The request could not be made');
      this.setState({loading: false, tickets: [], results: 0});
    }
  };

  onTicket = (ticket) => () => this.props.dispatch(showTicketAct(ticket));

  render() {
    const {page, loading, tickets, sort, results, medallion} = this.state;
    return (
      <TicketsList
        medallion={medallion}
        results={results}
        loading={loading}
        tickets={tickets}
        page={page}
        pages={this.pages()}
        onPage={this.onPage}
        sort={sort}
        onSort={this.onSort}
        onTicket={this.onTicket}
        onMedallion={this.onMedallion}
      />
    );
  }
}

export default connect()(TicketsListContainer);
