// Error
import {validationError as attributeError} from './error.attribute';

// List
import list from './list.attribute';

// Fields
import fields from './fields.attribute';

export default (attributes, field) => {
  fields(field);

  const errors = list(attributes)
    .map((attribute) =>
      !attribute.reinit(attribute[field].format())[field].validate()
        ? attribute
        : null
    )
    .filter((err) => err !== null);

  if (!errors.length) return;

  const errorData = errors.reduce(
    (combined, attribute) => ({
      fields: [...combined.fields, attribute.attribute],
      names: [...combined.names, attribute.label.default],
      message: `${combined.message}${combined.fields.length > 0 ? ',' : ''} ${
        attribute.label.default
      }${attribute.errorMessage ? ` (${attribute.errorMessage})` : ''}`,
    }),
    {
      fields: [],
      names: [],
      message: 'Please check the following:',
    }
  );
  throw attributeError(errorData);
};
