import {SELECT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {string} from '../../types';

export default attribute({
  type: SELECT,
  attribute: 'vehicle_type',
  api: string,
  label: {
    default: 'Vehicle Type',
    short: 'Type',
  },
});
