import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Api
import updateApi from '../../api/update.api.medallionOwner';

// Attributes
import notesAttr from '../../attributes/notes.attribute.medallionOwner';

// Components
import Notes from '../../components/Notes/Notes';
import NotesEdit from '../../components/NotesEdit/NotesEdit';

// Libs
import {lib} from '@matthahn/sally-ui';
import parseError from '../../../error/parseError';

// Notifications
const {alert} = lib;

class NotesContainer extends Component {
  static propTypes = {
    medallionOwner: PropTypes.object,
  };

  state = {
    loading: false,
    edit: false,
    errors: [],
    notes: notesAttr(''),
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  startEdit = () => {
    const {medallionOwner} = this.props;
    if (this.state.loading) return;
    this.setState({
      edit: true,
      notes: notesAttr(medallionOwner.notes || ''),
    });
  };

  stopEdit = () => {
    if (this.state.loading) return;
    this.setState({edit: false, errors: []});
  };

  onChange = (val, key, errors) => {
    if (this.state.loading) return;
    this.setState({[key]: val, errors});
  };

  update = async () => {
    const {loading, notes} = this.state;
    if (loading) return;

    this.setState({loading: true});

    try {
      await updateApi(this.props.medallionOwner.id, {
        notes: notes.api.format(),
      });
      if (!this.mounted) return;
      this.setState({loading: false, edit: false});
    } catch (error) {
      if (!this.mounted) return;
      const {fields: errors, message} = parseError(error);
      alert.error(message);
      this.setState({loading: false, errors});
    }
  };

  render() {
    const {medallionOwner} = this.props;
    const {loading, edit, errors, notes} = this.state;
    return edit ? (
      <NotesEdit
        loading={loading}
        errors={errors}
        notes={notes}
        onCancel={this.stopEdit}
        onChange={this.onChange}
        onSave={this.update}
      />
    ) : (
      <Notes {...medallionOwner} onEdit={this.startEdit} />
    );
  }
}

export default connect((state) => ({
  medallionOwner: state.medallionOwner.object,
}))(NotesContainer);
