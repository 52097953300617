export default [
  {
    key: 'transaction_date',
    label: 'Transaction Date',
  },
  {
    key: 'amount',
    label: 'Amount',
  },
  {
    key: 'exit_plaza',
    label: 'Exit Plaza',
  },
  {
    key: 'trip',
    label: 'Trip',
  },
];
