import React from 'react';

// Containers
import Page from '../../../layout/containers/Page/Page';
import TollsContainer from '../../containers/TollsContainer/TollsContainer';

// Page
import PAGE from './page';

// query string
import queryString from 'query-string';

const TollsPage = ({location}) => {
  const params = queryString.parse(location.search);
  return (
    <Page page={PAGE}>
      <TollsContainer medallion={params?.medallion || ''} />
    </Page>
  );
};

export default TollsPage;
