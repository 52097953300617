import {SELECT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {string} from '../../types';

export default attribute({
  type: SELECT,
  attribute: 'hold_type',
  api: string,
  label: {
    default: 'Hold Type',
    short: 'Type',
  },
  options: ['Maintenance', 'Reserve For Driver', 'Other'],
});
