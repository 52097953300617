import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

export default attribute({
  type: TEXT,
  attribute: 'taxx_id',
  label: {
    default: 'Tax ID',
    short: 'Tax ID',
  },
});
