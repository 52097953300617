import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {Row, Column, Card} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

const NewMedallionOwner = ({
  loading,
  errors,
  name,
  email,
  address_street1,
  address_street2,
  address_city,
  address_zip,
  address_state,
  taxx_id,
  phone_number,
  phone_number2,
  onChange,
  onSave,
}) => (
  <Fragment>
    <Row margin>
      <Column size={1 / 2} offset={1 / 4}>
        <Card
          icon="tagso"
          title="New Medallion Owner"
          buttonsRight={[
            {label: 'Save', loading, theme: 'orange', onClick: onSave},
          ]}
        >
          {(Content) => (
            <Fragment>
              <Content>
                <Row margin>
                  <Column>
                    <AttributeInput
                      value={name}
                      onChange={onChange}
                      disabled={loading}
                      errors={errors}
                    >
                      {name.label.default}
                    </AttributeInput>
                  </Column>
                </Row>
                <Row margin>
                  <Column>
                    <AttributeInput
                      value={taxx_id}
                      onChange={onChange}
                      disabled={loading}
                      errors={errors}
                    >
                      {taxx_id.label.default}
                    </AttributeInput>
                  </Column>
                </Row>
              </Content>
              <Content>
                <Row margin>
                  <Column size={1 / 2}>
                    <AttributeInput
                      value={phone_number}
                      onChange={onChange}
                      disabled={loading}
                      errors={errors}
                    >
                      {phone_number.label.default}
                    </AttributeInput>
                  </Column>
                  <Column size={1 / 2}>
                    <AttributeInput
                      value={phone_number2}
                      onChange={onChange}
                      disabled={loading}
                      errors={errors}
                    >
                      {phone_number2.label.default}
                    </AttributeInput>
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <AttributeInput
                      value={email}
                      onChange={onChange}
                      disabled={loading}
                      errors={errors}
                    >
                      {email.label.default}
                    </AttributeInput>
                  </Column>
                </Row>
              </Content>
              <Content>
                <Row margin>
                  <Column size={1 / 3}>
                    <AttributeInput
                      errors={errors}
                      value={address_street1}
                      onChange={onChange}
                      disabled={loading}
                    >
                      {address_street1.label.default}
                    </AttributeInput>
                  </Column>
                  <Column size={1 / 3}>
                    <AttributeInput
                      errors={errors}
                      value={address_street2}
                      onChange={onChange}
                      disabled={loading}
                    >
                      {address_street2.label.default}
                    </AttributeInput>
                  </Column>
                  <Column size={1 / 3}>
                    <AttributeInput
                      errors={errors}
                      value={address_city}
                      onChange={onChange}
                      disabled={loading}
                    >
                      {address_city.label.default}
                    </AttributeInput>
                  </Column>
                </Row>
                <Row margin>
                  <Column size={1 / 2}>
                    <AttributeInput
                      errors={errors}
                      value={address_zip}
                      onChange={onChange}
                      disabled={loading}
                    >
                      {address_zip.label.default}
                    </AttributeInput>
                  </Column>
                  <Column size={1 / 2}>
                    <AttributeInput
                      errors={errors}
                      value={address_state}
                      onChange={onChange}
                      disabled={loading}
                    >
                      {address_state.label.default}
                    </AttributeInput>
                  </Column>
                </Row>
              </Content>
            </Fragment>
          )}
        </Card>
      </Column>
    </Row>
  </Fragment>
);

NewMedallionOwner.propTypes = {
  loading: PropTypes.bool,
  errors: PropTypes.array,
  name: PropTypes.object,
  email: PropTypes.object,
  address_street1: PropTypes.object,
  address_street2: PropTypes.object,
  address_city: PropTypes.object,
  address_zip: PropTypes.object,
  address_state: PropTypes.object,
  taxx_id: PropTypes.object,
  phone_number: PropTypes.object,
  phone_number2: PropTypes.object,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
};

export default NewMedallionOwner;
