import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

// Events
import event from '../events/updated.event.vehicleNote';

export default async (vehicleID, noteID, vehicleNote) => {
  const response = await f3tch(`/vehicles/${vehicleID}/notes/${noteID}/`)
    .profile(profile())
    .body(vehicleNote)
    .patch();
  event.pub(response);
  return response;
};
