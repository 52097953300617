import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

// Events
import event from '../events/hotswapped.event.rental';

export default async (hotswap, eventParser = (r) => r) => {
  const response = await f3tch('/dispatch/hotswap/')
    .profile(profile())
    .body(hotswap)
    .post();
  event.pub(eventParser(response));
  return response;
};
