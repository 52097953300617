import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

// Components
import {
  Heading,
  Container,
  Row,
  Column,
  Tabs,
  TextColor,
  Tag,
} from '@matthahn/sally-ui';
import PageContainer from './components/Container';
import Header from './components/Header';
import Content from './components/Content';
import HeaderContent from './components/HeaderContent';
import Big from './components/Big';
import TabContainer from './components/TabContainer';
import Space from './components/Space';
import SpaceTag from './components/SpaceTag';

// Lib
import accessibility from '../../../vehicle/libs/accessibility.lib.vehicle';

// Vehicle
import vehicleRoute from '../../../vehicle/pages/VehiclePage/route';

// State
import medallionState from '../../state';

const MedallionPageLayout = ({
  medallion_number,
  vehicle,
  state,

  tab,
  tabs,
  onTab,
  addon,
  children,
}) => (
  <PageContainer>
    <Header>
      <Container>
        <Row>
          <Column>
            <HeaderContent>
              <Big>
                <Heading size="1">
                  {medallion_number}
                  {!!vehicle && (
                    <Link to={vehicleRoute(vehicle.id)}>
                      <TextColor theme="black">
                        {' '}
                        - {accessibility(vehicle.vehicle_type) && '♿︎ '}
                        {vehicle.vin}
                      </TextColor>
                    </Link>
                  )}
                </Heading>
                <Space flex>
                  <SpaceTag theme="darkGrey" inline>
                    <Tag theme={medallionState(state).color}>
                      {medallionState(state).label}
                    </Tag>
                  </SpaceTag>
                </Space>
              </Big>
            </HeaderContent>
          </Column>
        </Row>
      </Container>
      <TabContainer>
        <Tabs
          tab={tab}
          tabs={tabs}
          onChange={onTab}
          theme="orange"
          addon={
            !!addon
              ? (Addon) => [<Addon key="medallionTabAddon">{addon}</Addon>]
              : undefined
          }
          nav
        />
      </TabContainer>
    </Header>
    <Content>{children}</Content>
  </PageContainer>
);

MedallionPageLayout.propTypes = {
  medallion_number: PropTypes.string,
  vehicle: PropTypes.object,
  state: PropTypes.string,
  tab: PropTypes.string,
  tabs: PropTypes.array,
  onTab: PropTypes.func,
  addon: PropTypes.node,
  children: PropTypes.node,
};

export default MedallionPageLayout;
