import styled, {css} from 'styled-components';

// constants
const SIZE = '320px';

const UsersList = styled.div`
  ${({fullWidth}) =>
    fullWidth
      ? css`
          width: 100%;
        `
      : css`
          width: ${SIZE};
          max-width: ${SIZE};
          min-width: ${SIZE};
        `}
  height: 100%;
  position: relative;
  padding-top: 53px;
`;

export default UsersList;
