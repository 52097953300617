import styled from 'styled-components';

const Page = styled.iframe`
  width: 100%;
  height: calc(100vh - 120px);
  display: block;
  border: none;
  padding: 0px;
  margin: 0px;
`;

export default Page;
