import React, {Component} from 'react';
import {v4} from 'uuid';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {show as showTicketAct} from '../TicketContainer/redux/actions';
import {show as showNewTicketAct} from '../NewTicketContainer/redux/actions';

// Api
import listTicketsApi from '../../api/list.api.ticket';

// Components
import TicketsTable from '../../components/TicketsTable/TicketsTable';

// Events
import updatedEvt from '../../events/updated.event.ticket';
import removedEvt from '../../events/removed.event.ticket';

// Libs
import {lib} from '@matthahn/sally-ui';
import searchQuery from '../../lib/searchQuery.lib.ticket';
import sortQuery from '../../../api/lib/sortQuery.lib.api';
import eventRace from '../../../events/race.event';
import shouldRemoveFromAssignList from '../../lib/shouldRemoveFromAssignList.lib.ticket';

// Query
import {isNull} from '../../../api/queries/queries';

// Sockets
import createdSocket from '../../socket/created.socket.ticket';
import removedSocket from '../../socket/removed.socket.ticket';
import updatedSocket from '../../socket/updated.socket.ticket';

// Statuses
import needsActionStatus from '../../statuses/needs_action.status.ticket';

// Alert
const {alert} = lib;

class UnassignedTicketsContainer extends Component {
  static propTypes = {
    numberOfTickets: PropTypes.number,
    dispatch: PropTypes.func,
  };

  static OPTIONS = {
    perPage: 50,
  };

  static DEFAULT_SORT = {
    key: 'due_datetime',
    direction: 'asc',
  };

  state = {
    page: 1,
    more: false,
    loading: true,
    tickets: [],
    sort: {...this.constructor.DEFAULT_SORT},
    medallion: '',
  };

  componentDidMount() {
    this.mounted = true;
    this.getTickets();
    this.subscribers = [
      createdSocket.subscribe(this.ticketCreated),

      removedSocket.subscribe(this.ticketUpdated),
      removedEvt.sub(this.ticketUpdated),

      updatedSocket.subscribe(this.ticketUpdated),
      updatedEvt.sub(this.ticketUpdated),
    ];
  }

  componentWillUnmount() {
    this.mounted = false;
    this.subscribers.forEach((unsub) => unsub());
  }

  mounted = false;
  apiID = v4();
  subscribers = [];

  ticketCreated = (ticket) => {
    if (!!ticket.rental) {
      return;
    }
    const {tickets} = this.state;
    this.setState({tickets: [...tickets, ticket]});
  };

  ticketUpdated = eventRace((ticket) => {
    const newTickets = shouldRemoveFromAssignList(ticket)
      ? [...this.state.tickets].filter(({id}) => id !== ticket.id)
      : [...this.state.tickets].map((t) => (t.id === ticket.id ? ticket : t));
    this.setState({tickets: newTickets});
  });

  onMedallion = (medallion) => this.getTickets({medallion, page: 1});

  getTickets = async ({
    page = this.state.page,
    sort = this.state.sort,
    medallion = this.state.medallion,
  } = {}) => {
    const apiID = v4();
    this.apiID = apiID;
    const {perPage} = this.constructor.OPTIONS;
    this.setState({
      loading: true,
      tickets: page === 1 ? [] : this.state.tickets,
      page,
      sort,
      medallion,
    });
    const query = {
      ...sortQuery(sort || this.constructor.DEFAULT_SORT),
      [isNull('rental')]: true,
      status: needsActionStatus.key,
      removed: false,
      limit: perPage,
      offset: (page - 1) * perPage,
      ...searchQuery(medallion),
    };

    try {
      const {results, next} = await listTicketsApi(query);
      if (this.apiID !== apiID || !this.mounted) return;
      this.setState({
        tickets: page === 1 ? results : [...this.state.tickets, ...results],
        loading: false,
        more: !!next,
      });
    } catch (error) {
      if (this.apiID !== apiID || !this.mounted) return;
      alert.info('The request could not be made');
      this.setState({loading: false});
    }
  };

  onTicket = (ticket) => () => this.props.dispatch(showTicketAct(ticket));

  onMore = () => this.getTickets({page: this.state.page + 1});

  onNew = () => this.props.dispatch(showNewTicketAct());

  render() {
    const {page, loading, tickets, more, medallion} = this.state;
    return (
      <TicketsTable
        title="Assign Drivers Below"
        medallion={medallion}
        loading={loading}
        tickets={tickets}
        page={page}
        more={more}
        onTicket={this.onTicket}
        onMedallion={this.onMedallion}
        onMore={this.onMore}
        onNew={this.onNew}
      />
    );
  }
}

export default connect()(UnassignedTicketsContainer);
