import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// Api
// import api from '../../../api/lib/getEverythingFromApi.lib.api';
// import listTripsApi from '../../../trip/api/list.api.trip';
import getLeaseById from '../../../lease/api/getByID.api.lease';

// Actions
import {set as setDriverAct} from '../../../driver/redux/actions';

// Components
import ActiveDriverRentalCard from '../../components/ActiveDriverRentalCard/ActiveDriverRentalCard';
import TripPreviewModal from '../../../trip/components/TripPreviewModal/TripPreviewModal';

// error lib
import parseError from '../../../error/parseError';

// Lib
import fkOrId from '../../../lib/fkOrId';
import sum from '../../../lib/sum';

// rental api
import updateRentalRateApi from '../../../rental/api/updateRentalRate.api.rental';

// rental attributes
import amountAttribute from '../../../rental/attributes/amount.attribute.rental';

// rental components
import NewRentalRateModal from '../../../rental/components/Rental/components/NewRentalRateModal';

// States
import activeState from '../../../driver/state/active.state.driver';

// Sockets
import dispatchedSocket from '../../sockets/dispatched.socket.rental';
import hotswapSocket from '../../sockets/hotswap.socket.rental';
import createdTripSocket from '../../../trip/sockets/created.socket.trip';
import updatedTripSocket from '../../../trip/sockets/updated.socket.trip';
import deletedTripSocket from '../../../trip/sockets/deleted.socket.trip';

class ActiveDriverRentalContainer extends Component {
  static propTypes = {
    activeRental: PropTypes.object,
    driver: PropTypes.object,
    children: PropTypes.func.isRequired,
    dispatch: PropTypes.func,
  };

  state = {
    loading: false,
    trips: [],
    tripVisible: false,
    trip: null,
    lease: null,
    newRentalRateVisible: false,
    rentalRateAmount: amountAttribute(''),
    savingRentalRate: false,
  };

  componentDidMount() {
    this.mounted = false;
    this.init();
    this.subscribe();
  }

  componentWillUnmount() {
    this.mounted = false;
    this.unsubscribe();
  }

  subscribe = () => {
    this.subscriptions = [
      dispatchedSocket.subscribe(this.rentalDispatched),
      hotswapSocket.subscribe(this.hotswapped),
      createdTripSocket.subscribe(this.tripCreated),
      updatedTripSocket.subscribe(this.tripUpdated),
      deletedTripSocket.subscribe(this.tripDeleted),
    ];
  };

  unsubscribe = () => {
    this.subscriptions.forEach((unsubscribe) => unsubscribe());
    this.subscriptions = [];
  };

  hotswapped = ({hotswaps}) => {
    const {activeRental, dispatch} = this.props;
    const hotswap = [...hotswaps].find(
      ({old_rental}) => activeRental.id !== old_rental.id
    );
    if (!hotswap) return;
    dispatch(setDriverAct({activeRental: {...hotswap.new_rental}}));
    this.getTripsAndLease({...hotswap.new_rental});
  };

  rentalDispatched = (rental) => {
    const {driver} = this.props;
    if (driver.id !== rental.driver.id) return;
    this.getTripsAndLease(rental);
  };

  tripCreated = (trip) => {
    const {activeRental: rental} = this.props;
    const {trips} = this.state;
    if (!rental || trip.rental !== rental.id) return;
    const newTrips = [...trips, trip];
    this.setState({trips: newTrips});
  };

  tripUpdated = (trip) => {
    const {activeRental: rental} = this.props;
    const {trips} = this.state;
    if (!rental || trip.rental !== rental.id) return;
    const newTrips = [...trips].map((t) => (t.id === trip.id ? trip : t));
    this.setState({trips: newTrips});
  };

  tripDeleted = (trip) => {
    const {activeRental: rental} = this.props;
    const {trips} = this.state;
    if (!rental || trip.rental !== rental.id) return;
    const newTrips = [...trips].filter(({id}) => trip.id !== id);
    this.setState({trips: newTrips});
  };

  hasActiveRental = () => {
    const {activeRental, driver} = this.props;
    return !!driver && driver.state === activeState.key && !!activeRental;
  };

  init = async () => {
    const {activeRental} = this.props;
    if (!this.hasActiveRental()) return;
    this.getTripsAndLease(activeRental);
  };

  getTripsAndLease = async (activeRental) => {
    this.setState({loading: true});

    const state = {loading: false};

    try {
      // const [{data: trips}, lease] = await Promise.all([
      //   api(listTripsApi, {rental: activeRental.id}),
      //   getLeaseById(fkOrId(activeRental.lease_agreement)),
      // ]);
      const lease = await getLeaseById(fkOrId(activeRental.lease_agreement));
      // state.trips = trips;
      state.lease = lease;
      this.setState(state);
    } catch (error) {
      this.setState(state);
    }
  };

  showTrip = (trip) => () => this.setState({tripVisible: true, trip});

  hideTrip = () => this.setState({tripVisible: false});

  cashTrips = () => [...this.state.trips].filter(({type}) => type === '$');

  ccTrips = () => [...this.state.trips].filter(({type}) => type === 'C');

  cashEarnings = () =>
    [...this.cashTrips()].reduce(
      (combined, trip) => sum(combined, trip.total_amount),
      0
    );

  ccEarnings = () =>
    [...this.ccTrips()].reduce(
      (combined, trip) => sum(combined, trip.total_amount),
      0
    );

  totalEarnings = () => sum(this.cashEarnings(), this.ccEarnings());

  updateRentalRateAmount = (amount) => {
    if (this.state.savingRentalRate) return;
    this.setState({rentalRateAmount: amount});
  };

  saveRentalRate = async () => {
    const {activeRental, dispatch} = this.props;
    const {savingRentalRate, rentalRateAmount} = this.state;
    if (savingRentalRate) return;
    const amount = rentalRateAmount.api.format();
    if (amount <= 0) {
      return alert.warning('Rental rate must be greater than 0!');
    }

    try {
      this.setState({savingRentalRate: true});
      const newRentalRate = await updateRentalRateApi({
        amount,
        rental: activeRental.id,
      });
      const updatedRental = {...activeRental, amount: newRentalRate.amount};
      dispatch(setDriverAct({activeRental: updatedRental}));
      this.setState({
        newRentalRateVisible: false,
        savingRentalRate: false,
      });
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({savingRentalRate: false});
    }
  };

  showNewRentalRate = () =>
    this.setState({
      newRentalRateVisible: true,
      rentalRateAmount: amountAttribute(''),
    });

  hideNewRentalRate = () => this.setState({newRentalRateVisible: false});

  renderContent = () => {
    const {activeRental: rental} = this.props;
    const {
      loading,
      trips,
      tripVisible,
      trip,
      lease,
      newRentalRateVisible,
      rentalRateAmount,
      savingRentalRate,
    } = this.state;
    return (
      <Fragment>
        <ActiveDriverRentalCard
          loading={loading}
          lease={lease}
          rental={rental}
          trips={trips}
          cashTrips={this.cashTrips()}
          ccTrips={this.ccTrips()}
          cashEarnings={this.cashEarnings()}
          ccEarnings={this.ccEarnings()}
          totalEarnings={this.totalEarnings()}
          onTrip={this.showTrip}
          onUpdateRental={this.showNewRentalRate}
        />
        <TripPreviewModal
          visible={tripVisible}
          trip={trip}
          onClose={this.hideTrip}
        />
        <NewRentalRateModal
          visible={newRentalRateVisible}
          amount={rentalRateAmount}
          loading={savingRentalRate}
          onChange={this.updateRentalRateAmount}
          onClose={this.hideNewRentalRate}
          onSave={this.saveRentalRate}
        />
      </Fragment>
    );
  };

  render() {
    const {children} = this.props;
    const display = this.hasActiveRental();
    return display ? children({display, content: this.renderContent()}) : null;
  }
}

export default connect((state) => ({
  activeRental: state.driver.activeRental,
  driver: state.driver.driver,
}))(ActiveDriverRentalContainer);
