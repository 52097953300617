import subtract from '../../lib/subtract';

export default (payout = {}, total = 0) =>
  !payout
    ? 0
    : Object.entries(payout).reduce(
        (combined, [key, value]) =>
          !key.endsWith('_amount') || key === 'earnings_amount'
            ? combined
            : subtract(combined, value),
        total
      );
