import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions]
import {show as showStoreModalAct} from '../StoreMedallionContainer/redux/actions';
import {show as showUnmanageModalAct} from '../UnmanageMedallionContainer/redux/actions';
import {show as showEnableModalAct} from '../EnableMedallionContainer/redux/actions';

// Components
import MedallionHeaderButtonSeparator from '../../components/MedallionHeaderButtonSeparator/MedallionHeaderButtonSeparator';

// Lib
import canBeStored from '../../lib/canBeStored.lib.medallion';
import canBeEnabled from '../../lib/canBeEnabled.lib.medallion';
import canBeUnmanaged from '../../lib/canBeUnmanaged.lib.medallion';

// Permissions
import storePermission from '../../permissions/canStore.permission.medallion';

class MedallionInfoSaveContainer extends Component {
  static propTypes = {
    medallion: PropTypes.object,
    dispatch: PropTypes.func,
  };

  store = () => {
    const {dispatch, medallion} = this.props;
    dispatch(showStoreModalAct(medallion));
  };

  unmanage = () => {
    const {dispatch, medallion} = this.props;
    dispatch(showUnmanageModalAct(medallion));
  };

  enable = () => {
    const {dispatch, medallion} = this.props;
    dispatch(showEnableModalAct(medallion));
  };

  render() {
    const {medallion} = this.props;
    return (
      <MedallionHeaderButtonSeparator
        canBeStored={canBeStored(medallion) && storePermission()}
        canBeEnabled={canBeEnabled(medallion) && storePermission()}
        canBeUnmanaged={canBeUnmanaged(medallion) && storePermission()}
        onStore={this.store}
        onEnable={this.enable}
        onUnmanage={this.unmanage}
      />
    );
  }
}

export default connect((state) => ({
  medallion: state.medallion.medallion,
}))(MedallionInfoSaveContainer);
