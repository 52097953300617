import React from 'react';

// Components
import {Row, Column} from '@matthahn/sally-ui';
import SubPage from '../../../layout/components/SubPage/SubPage';

// Containers
import AccidentNotesContainer from '../../../accidentNote/containers/AccidentNotesContainer/AccidentNotesContainer';

const AccidentNotesPage = () => (
  <SubPage>
    <Row>
      <Column size={2 / 3} offset={1 / 6}>
        <AccidentNotesContainer />
      </Column>
    </Row>
  </SubPage>
);

export default AccidentNotesPage;
