import PropTypes from 'prop-types';

const AttributeFormatter = ({attribute, empty}) =>
  attribute.display.format() || empty;

AttributeFormatter.propTypes = {
  attribute: PropTypes.object.isRequired,
  empty: PropTypes.node,
};

AttributeFormatter.defaultProps = {
  empty: '-',
};

export default AttributeFormatter;
