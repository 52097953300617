import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal, Button, FrameDoc} from '@matthahn/sally-ui';

const DriverStatementCard = ({
  visible,
  closable,
  onClose,
  loading,
  driverStatement,
  onUpload,
  onGenerate,
}) => (
  <Modal
    visible={visible}
    closable={closable}
    onClose={onClose}
    size={!!driverStatement ? 'large' : 'small'}
    icon="pdffile1"
    title="Driver Statement"
    headerActions={[
      !!driverStatement && {
        icon: 'download',
        tooltip: 'Generate Driver Statement',
        onClick: onGenerate,
        loading,
      },
      {icon: 'upload', tooltip: 'Upload', onClick: onUpload},
    ].filter((action) => !!action)}
  >
    {(Content) =>
      !driverStatement ? (
        <Content>
          <Button
            loading={loading}
            theme="grey"
            icon="plus"
            onClick={onGenerate}
          >
            Generate Driver Statement
          </Button>
        </Content>
      ) : (
        <Content padding="none">
          <FrameDoc file={driverStatement} height="70vh" />
        </Content>
      )
    }
  </Modal>
);

DriverStatementCard.propTypes = {
  visible: PropTypes.bool,
  closable: PropTypes.bool,
  loading: PropTypes.bool,
  driverStatement: PropTypes.object,
  onUpload: PropTypes.func,
  onGenerate: PropTypes.func,
  onClose: PropTypes.func,
};

export default DriverStatementCard;
