import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {BrowserRouter, Route, Prompt, Switch} from 'react-router-dom';

// Actions
import {init as initAuthAct} from '../../../auth/redux/actions';

// auth containers
import SSOContainer from '../../../auth/containers/SSOContainer/SSOContainer';

// auth pages
import TraditionalSignInPage from '../../../auth/pages/TraditionalSignInPage/TraditionalSignInPage';

// auth routes
import routeTraditionalSignIn from '../../../auth/pages/TraditionalSignInPage/route';

// Components
import {
  Page,
  AlertSystem,
  Confetti,
  NotificationSystem,
  Portal,
  FullScreenLoader,
} from '@matthahn/sally-ui';
import Routes from '../../../routes/components/Routes/Routes';

// shortcut containers
import Shortcuts from '../../../spotlight/containers/Shortcuts/Shortcuts';

// Routes
import unsavedChangesPrompt from '../../../routes/lib/unsavedChangesPrompt.lib.routes';

// Sockets
import socket from '../../../sockets/socket';
import '../../../sockets/events.sockets';

class App extends Component {
  static propTypes = {
    initialized: PropTypes.bool,
    unsavedChanges: PropTypes.bool,
    token: PropTypes.string,
    username: PropTypes.string,
    dispatch: PropTypes.func,
  };

  state = {
    show: false,
  };

  componentDidMount() {
    this.props.dispatch(initAuthAct());
    socket.connect(process.env.REACT_APP_SOCKETS_URL);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.initialized && this.props.initialized)
      setTimeout(() => this.setState({show: true}), 500);
  }

  renderContent() {
    const {initialized, token, unsavedChanges} = this.props;
    if (!initialized) return null;

    return (
      <Fragment>
        <BrowserRouter getUserConfirmation={unsavedChangesPrompt}>
          {!token ? (
            <Switch>
              <Route
                exact
                path={routeTraditionalSignIn()}
                component={TraditionalSignInPage}
              />
              <Route>
                <SSOContainer />
              </Route>
            </Switch>
          ) : (
            <Route component={Routes} />
          )}
          <Prompt
            key="Prompt"
            when={unsavedChanges}
            message={() => 'Are you sure you want to leave?'}
          />
        </BrowserRouter>
        {!!token && <Shortcuts />}
      </Fragment>
    );
  }

  render() {
    const {show} = this.state;
    return (
      <Page>
        {this.renderContent()}
        <Portal container={Portal.assetsContainer.secondary} always>
          <Confetti />
          <AlertSystem />
          <NotificationSystem />
          {!show && <FullScreenLoader theme="orange" fixed />}
        </Portal>
      </Page>
    );
  }
}

export default connect((state) => ({
  ...state.auth,
  unsavedChanges: state.layout.unsavedChanges,
}))(App);
