import React from 'react';
import PropTypes from 'prop-types';

// Components
import Container from './components/Container';
import Content from './components/Content';

const StepFooter = ({left, right}) => (
  <Container>
    <Content>{!!left && left}</Content>
    <Content>{!!right && right}</Content>
  </Container>
);

StepFooter.propTypes = {
  left: PropTypes.node,
  right: PropTypes.node,
};

export default StepFooter;
