import React from 'react';
import PropTypes from 'prop-types';

// Components
import {
  Heading,
  Container,
  Row,
  Column,
  Tag,
  P,
  Icon,
  Tabs,
} from '@matthahn/sally-ui';
import NegativeParenthesis from '../../../layout/components/NegativeParenthesis/NegativeParenthesis';
import DriverTags from '../DriverTags/DriverTags';
import PageContainer from './components/Container';
import Header from './components/Header';
import Content from './components/Content';
import HeaderContent from './components/HeaderContent';
import Big from './components/Big';
import Small from './components/Small';
import Space from './components/Space';
import SpaceTag from './components/SpaceTag';
import StyledLink from './components/StyledLink';
import Box from './components/Box';
import Right from './components/Right';
import TabContainer from './components/TabContainer';

// Containers
import DriverBalanceContainer from '../../containers/DriverBalanceContainer/DriverBalanceContainer';
import DriverDepositBalanceContainer from '../../containers/DriverDepositBalanceContainer/DriverDepositBalanceContainer';

// States
import activeState from '../../state/active.state.driver';

// Routes
import vehicleRoute from '../../../vehicle/pages/VehiclePage/route';

const DriverPageLayout = ({
  name,
  email,
  phone_number,
  state,
  loadingActiveRental,
  activeRental,
  driver,
  onCreateAlert,

  tab,
  tabs,
  onTab,
  addon,
  children,
}) => (
  <PageContainer>
    <Header>
      <Container>
        <Row>
          <Column>
            <HeaderContent>
              <div>
                <Big>
                  <Heading size="1">
                    <DriverTags driver={driver} inline>
                      {name}
                    </DriverTags>
                  </Heading>
                  <Space flex>
                    <SpaceTag theme="darkGrey" inline>
                      <Tag theme={state.color}>{state.label}</Tag>
                    </SpaceTag>
                  </Space>
                  {state.key === activeState.key &&
                    !loadingActiveRental &&
                    !!activeRental && (
                      <Space flex>
                        <P theme="darkGrey">
                          <StyledLink
                            to={vehicleRoute(activeRental.medallion.vehicle.id)}
                          >
                            {activeRental.medallion.medallion_number} -{' '}
                            {activeRental.medallion.vehicle.vin}
                          </StyledLink>
                        </P>
                      </Space>
                    )}
                </Big>
                <Small flex>
                  <a href={`tel:${phone_number}`}>
                    <P theme="darkGrey" inline>
                      <Icon icon="phone" /> {phone_number}
                    </P>
                  </a>
                  {!!email && (
                    <Space flex>
                      <P theme="darkGrey" inline>
                        <Icon icon="mail" /> {email}
                      </P>
                    </Space>
                  )}
                </Small>
              </div>
              <Right>
                <DriverBalanceContainer>
                  {({balance}) => (
                    <Box>
                      <Heading size="3" light>
                        <NegativeParenthesis value={balance * 1} pre="$" />
                      </Heading>
                      <Small>
                        <Heading size="6" grey>
                          Balance
                        </Heading>
                      </Small>
                    </Box>
                  )}
                </DriverBalanceContainer>
                <DriverDepositBalanceContainer>
                  {({deposit_balance}) => (
                    <Box>
                      <Heading size="3" light>
                        <NegativeParenthesis
                          value={deposit_balance * 1}
                          pre="$"
                        />
                      </Heading>
                      <Small>
                        <Heading size="6" grey>
                          Deposit
                        </Heading>
                      </Small>
                    </Box>
                  )}
                </DriverDepositBalanceContainer>
              </Right>
            </HeaderContent>
          </Column>
        </Row>
      </Container>
      <TabContainer>
        <Tabs
          tab={tab}
          tabs={tabs}
          onChange={onTab}
          theme="orange"
          addon={(Addon) =>
            [!!addon && <Addon key="driverTabAddon">{addon}</Addon>].filter(
              (a) => !!a
            )
          }
          nav
        />
      </TabContainer>
    </Header>
    <Content>{children}</Content>
  </PageContainer>
);

DriverPageLayout.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  phone_number: PropTypes.string,
  state: PropTypes.object,
  loadingActiveRental: PropTypes.bool,
  activeRental: PropTypes.object,
  driver: PropTypes.object,
  onCreateAlert: PropTypes.func,

  tab: PropTypes.string,
  tabs: PropTypes.array,
  onTab: PropTypes.func,
  addon: PropTypes.node,
  children: PropTypes.node,
};

export default DriverPageLayout;
