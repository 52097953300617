import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {v4} from 'uuid';

// Api
import listTransactionsApi from '../../../transaction/api/list.api.transaction';

// Components
import DriverBalanceModal from '../../components/DriverBalanceModal/DriverBalanceModal';
import DriverBalanceTransactionBreakdownModal from '../../components/DriverBalanceTransactionBreakdownModal/DriverBalanceTransactionBreakdownModal';

// Lib
import api from '../../../api/lib/getEverythingFromApi.lib.api';
import payoutGroup from '../../../payout/lib/grouping.lib.payout';
import allocationOrder from '../../../payout/lib/allocationOrder.lib.payout';
import groupOutcomeTransactions from '../../../payout/lib/groupOutcomeTransactions.lib.payout';

class DriverBalanceContainer extends Component {
  static propTypes = {
    driver: PropTypes.object,
    transactionTypes: PropTypes.array,
    dispatch: PropTypes.func,
    children: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
    transactions: [],
    modalVisible: false,
    transactionBreakdownVisible: false,
    transaction: null,
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  apiID = null;

  showModal = async () => {
    const apiID = v4();
    this.apiID = apiID;
    const {driver, transactionTypes} = this.props;
    this.setState({modalVisible: true, loading: true, transactions: []});
    const [
      {data: openCharges},
      {data: cashPayments},
      {data: creditPayments},
      {data: ccEarnings},
      {data: checkPayments},
    ] = await Promise.all([
      api(listTransactionsApi, {
        driver: driver.id,
        status: 'open',
        ordered: '-created_at',
        type: 'charge',
      }),
      api(listTransactionsApi, {
        driver: driver.id,
        type: 'payment',
        subtype: 'cash',
        status: 'open',
        ordered: '-created_at',
      }),
      api(listTransactionsApi, {
        driver: driver.id,
        type: 'credit',
        status: 'open',
        ordered: '-created_at',
      }),
      api(listTransactionsApi, {
        driver: driver.id,
        type: 'cc',
        status: 'open',
        ordered: '-created_at',
      }),
      api(listTransactionsApi, {
        driver: driver.id,
        type: 'payment',
        subtype: 'check',
        status: 'open',
        ordered: '-created_at',
      }),
    ]);
    if (!this.mounted || apiID !== this.apiID) return;
    const types = [...transactionTypes];
    const transactions = groupOutcomeTransactions(
      allocationOrder(
        payoutGroup(
          [
            ...openCharges,
            ...cashPayments,
            ...creditPayments,
            ...ccEarnings,
            ...checkPayments,
          ],
          types
        )
      )
    ).filter(({balance}) => !!balance);
    this.setState({loading: false, transactions});
  };

  hideModal = () => {
    if (this.state.transactionBreakdownVisible) return;
    this.setState({modalVisible: false});
  };

  netBalance = () => {
    const {
      driver: {balance},
    } = this.props;
    return balance;
  };

  transactions = () => [...this.state.transactions];

  onTransaction = (transaction) => () =>
    this.showTransactionBreakdown(transaction);

  showTransactionBreakdown = (transaction) =>
    this.setState({transactionBreakdownVisible: true, transaction});

  hideTransactionBreakdown = () =>
    this.setState({transactionBreakdownVisible: false});

  render() {
    const {children, driver} = this.props;
    const {loading, modalVisible, transactionBreakdownVisible, transaction} =
      this.state;
    return (
      <Fragment>
        <div onClick={this.showModal}>
          {children({balance: this.netBalance()})}
        </div>
        <DriverBalanceModal
          loading={loading}
          visible={modalVisible && !transactionBreakdownVisible}
          balance={!!driver ? driver.balance : 0}
          transactions={this.transactions()}
          onClose={this.hideModal}
          onTransaction={this.onTransaction}
        />
        <DriverBalanceTransactionBreakdownModal
          visible={modalVisible && transactionBreakdownVisible}
          transaction={transaction}
          onClose={this.hideTransactionBreakdown}
        />
      </Fragment>
    );
  }
}

export default connect((state) => ({
  driver: state.driver.driver,
  transactionTypes: state.transactionType.transactionTypes,
}))(DriverBalanceContainer);
