import isString from 'is-string';

import type from '../type';

const format = (value) =>
  isString(value) ? value.replace(/[^a-zA-Z0-9]/gi, '').toUpperCase() : '';

const validate = (value) =>
  /^T\d{6}C$/.test(value) ||
  /^Y\d{6}C$/.test(value) ||
  /^\d{1}[A-Z]{1}\d{2}[A-Z]{1}$/.test(value);

export default type({type: 'string', format, validate});
