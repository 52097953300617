import React from 'react';
import PropTypes from 'prop-types';

// Components
import {P} from '@matthahn/sally-ui';

// Lib
import isHoldAfterETC from '../../../vehicleHold/lib/isAfterETC.lib.vehicleHold';
import isOnHold from '../../../vehicleHold/lib/isOnHold.lib.vehicleHold';
import isRentable from '../../lib/isRentable.lib.dispatch';

const Rentable = ({vehicle, children}) => (
  <P
    theme={
      isOnHold(vehicle.vehicle_hold)
        ? isHoldAfterETC({etc: vehicle.vehicle_hold.date_etc})
          ? 'black'
          : 'orange'
        : isRentable(vehicle)
        ? 'black'
        : 'darkGrey'
    }
    weight={
      isOnHold(vehicle.vehicle_hold) &&
      !isHoldAfterETC({etc: vehicle.vehicle_hold.date_etc})
        ? 'bold'
        : 'normal'
    }
  >
    {children}
  </P>
);

Rentable.propTypes = {
  vehicle: PropTypes.object,
  children: PropTypes.node,
};

export default Rentable;
