const parseAch = (ach) => ({
  ...ach,
  type: 'ach',
  computedId: `ach${ach.id}`,
  search: [
    ach.id,
    ach?.driver ? `${ach.driver.first_name} ${ach.driver.last_name}` : null,
    ach.driver.fhv_license_number || null,
  ]
    .filter((q) => !!q)
    .map((query) => `${query}`.toLowerCase()),
});

export default parseAch;
