import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {alphanumeric, string} from '../../types';

export default attribute({
  type: TEXT,
  attribute: 'dmv_license_number',
  display: alphanumeric,
  input: alphanumeric,
  api: string,
  label: {
    default: 'DMV License Number',
    short: 'DMV License Number',
  },
});
