import React from 'react';

// Components
import SubPage from '../../../layout/components/SubPage/SubPage';

// Containers
import VehicleTicketsContainer from '../../containers/VehicleTicketsContainer/VehicleTicketsContainer';

const VehicleTicketsPage = () => (
  <SubPage>
    <VehicleTicketsContainer />
  </SubPage>
);

export default VehicleTicketsPage;
