// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// changeOrder components
import SignChangeOrderContractModal from '../../components/SignChangeOrderContractModal/SignChangeOrderContractModal';

// changeOrder events
import changeOrderSignedEvent from '../../events/changeOrderSigned.event.changeOrder';
import showSignChangeOrderContractEvent from '../../events/showSignChangeOrderContract.event.changeOrder';

// contract api
import getContractByCsrIdApi from '../../api/getContractHtmlByCsrId.api.contract';
import uploadContractByCsrIdApi from '../../api/sign.api.contract';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// lib
import blobToFile from '@matthahn/sally-fw/lib/lib/blobToFile';

// pdf api
import convertToPDFApi from '../../../pdf/api/convert.api.pdf';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

class SignChangeOrderContractContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
  };

  componentDidMount() {
    this.props.subscribe(showSignChangeOrderContractEvent.subscribe(this.show));
  }

  state = {
    html: '',
    loading: true,
    signatureRequest: null,
    signing: false,
    visible: false,
  };

  show = async ({signatureRequest}) => {
    if (!signatureRequest) return;

    this.setState({
      visible: true,
      loading: true,
      signatureRequest,
    });

    try {
      const html = await getContractByCsrIdApi(signatureRequest.id);
      this.setState({loading: false, html});
    } catch (error) {
      this.setState({loading: false, visible: false});
      alert.error('Error signing contract');
    }
  };

  hide = () => {
    const {loading, signing} = this.state;
    if (loading || signing) return;
    this.setState({visible: false});
  };

  signContract = async (html, {ignoreLoading = false} = {}) => {
    const {signing, signatureRequest} = this.state;
    if (!ignoreLoading && signing) return;
    this.setState({signing: true});
    try {
      const blob = await convertToPDFApi(html);
      const doc = blobToFile(blob);

      const formMeta = new FormData();

      formMeta.append('document_file', doc);

      await uploadContractByCsrIdApi(signatureRequest.id, formMeta);
      changeOrderSignedEvent.publish({signatureRequest});
      this.setState({signing: false, visible: false});
      alert.success('Contract generated successfully');
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({signing: false});
    }
  };

  signingContractErrorHandler = (error) => {
    const {message} = parseError(error);
    alert.error(message);
  };

  render() {
    const {loading, visible, html, signing} = this.state;
    return (
      <SignChangeOrderContractModal
        html={html}
        loading={loading}
        onClose={this.hide}
        onExec={this.signContract}
        onExecError={this.signingContractErrorHandler}
        signing={signing}
        visible={visible}
      />
    );
  }
}

export default subscriptionHOC(SignChangeOrderContractContainer);
