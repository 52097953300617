import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {ScrollContext} from 'react-router-scroll-4';

// Accident
import AccidentPage from '../../../accident/pages/AccidentPage/AccidentPage';
import routeAccident from '../../../accident/pages/AccidentPage/route';

// Accidents
import AccidentsPage from '../../../accident/pages/AccidentsPage/AccidentsPage';
import routeAccidents from '../../../accident/pages/AccidentsPage/route';

// Components
import Layout from '../../../layout/components/Layout/Layout';

// GeneralCheque
import GeneralChequePage from '../../../cheque/pages/GeneralChequePage/GeneralChequePage';
import routeGeneralCheque from '../../../cheque/pages/GeneralChequePage/route';

// Dispatcher
import DispatcherPage from '../../../dispatch/pages/DispatcherPage/DispatcherPage';
import routeDispatcher from '../../../dispatch/pages/DispatcherPage/route';

// Insurances
import InsurancesPage from '../../../insurance/pages/InsurancesPage/InsurancesPage';
import routeInsurances from '../../../insurance/pages/InsurancesPage/route';

// Cyclops
import CyclopsPage from '../../../cyclops/pages/CyclopsPage/CyclopsPage';
import routeCyclops from '../../../cyclops/pages/CyclopsPage/route';

// Drivers
import DriversPage from '../../../driver/pages/DriversPage/DriversPage';
import routeDrivers from '../../../driver/pages/DriversPage/route';

// Medallions
import MedallionsPage from '../../../medallion/pages/MedallionsPage/MedallionsPage';
import routeMedallions from '../../../medallion/pages/MedallionsPage/route';

// MedallionOwners
import MedallionOwnersPage from '../../../medallionOwner/pages/MedallionOwnersPage/MedallionOwnersPage';
import routeMedallionOwners from '../../../medallionOwner/pages/MedallionOwnersPage/route';

// Tolls
import TollsPage from '../../../toll/pages/TollsPage/TollsPage';
import routeTolls from '../../../toll/pages/TollsPage/route';

// Tickets
import TicketsPage from '../../../ticket/pages/TicketsPage/TicketsPage';
import routeTickets from '../../../ticket/pages/TicketsPage/route';

// Vehicles
import VehiclesPage from '../../../vehicle/pages/VehiclesPage/VehiclesPage';
import routeVehicles from '../../../vehicle/pages/VehiclesPage/route';

const Routes = () => (
  <ScrollContext>
    <Layout>
      <Switch>
        <Route exact path={routeDispatcher()} component={DispatcherPage} />
        <Route exact path={routeCyclops()} component={CyclopsPage} />
        <Route path={routeDrivers()} component={DriversPage} />
        <Route path={routeMedallions()} component={MedallionsPage} />
        <Route path={routeMedallionOwners()} component={MedallionOwnersPage} />
        <Route path={routeVehicles()} component={VehiclesPage} />
        <Route path={routeTolls()} component={TollsPage} />
        <Route path={routeTickets()} component={TicketsPage} />
        <Route path={routeGeneralCheque()} component={GeneralChequePage} />
        <Route path={routeAccident()} component={AccidentPage} />
        <Route path={routeAccidents()} component={AccidentsPage} />
        <Route path={routeInsurances()} component={InsurancesPage} />
      </Switch>
    </Layout>
  </ScrollContext>
);

export default Routes;
