import React, {Component} from 'react';
import PropTypes from 'prop-types';

// accident lib
import getRecentQueryDate from '../../lib/getRecentQueryDate.lib.amount';

// accident states
import pending4By4State from '../../../accident/state/pending_4x4.state.accident';

// Accident
import accidentRoute from '../../pages/AccidentPage/route';

// Alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// Api
import listAccidentsApi from '../../../accident/api/list.api.accident';

// Components
import AccidentsList from '../../components/AccidentsList/AccidentsList';

// Events
import startNewAccidentFlowEvt from '../../events/startNewAccidentFlow.event.accident';
import accidentCreatedEvt from '../../../accident/events/created.event.accident';

// Libs
import numberOfPages from '../../../lib/numberOfPages';

// Router
import {withRouter} from 'react-router-dom';

// Subscribe
import subscriptionHoc from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// Query API
import sortQuery from '../../../api/lib/sortQuery.lib.api';

class AccidentsContainer extends Component {
  static propTypes = {
    history: PropTypes.object,
    subscribe: PropTypes.func,
  };

  static OPTIONS = {
    perPage: 50,
  };

  static DEFAULT_SORT = {
    key: 'created_at',
    direction: 'desc',
  };

  state = {
    loading: false,
    accidents: [],
    page: 1,
    sort: {...this.constructor.DEFAULT_SORT},
    search: '',
    query: {},
    state: 'all',
  };

  componentDidMount() {
    this.mounted = true;
    this.props.subscribe(accidentCreatedEvt.subscribe(this.accidentCreated));
    this.init();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  init = () => {
    this.getAccidents();
  };

  accidentCreated = (accident) => {
    this.getAccidents({page: 1});
  };

  getAccidents = async ({
    page = this.state.page,
    sort = this.state.sort,
    search = this.state.search,
    query = this.state.query,
  } = {}) => {
    const {perPage} = this.constructor.OPTIONS;
    this.setState({
      loading: true,
      accidents: [],
      page,
      sort,
      search,
      query,
    });

    const actualQuery = {
      ...sortQuery(sort || this.constructor.DEFAULT_SORT),
      limit: perPage,
      offset: (page - 1) * perPage,
      ...query,
    };

    if (!!search.trim().length) actualQuery.search = search;

    try {
      const {results, count} = await listAccidentsApi(actualQuery);
      if (!this.mounted) return;
      this.setState({accidents: results, loading: false, results: count});
    } catch (error) {
      if (!this.mounted) return;
      alert.info(`Failed to fetch accidents: ${error}`);
      this.setState({loading: false, accidents: [], results: 0});
    }
  };

  onSearch = (search) => this.getAccidents({search, page: 1});

  onPage = (page) => this.getAccidents({page});

  onSort = (sort) => this.getAccidents({sort});

  onAccident = (accident) => () => {
    this.props.history.push(accidentRoute(accident.id));
  };

  pages = () =>
    numberOfPages(this.state.results, this.constructor.OPTIONS.perPage);

  startAccidentFlow = () => {
    startNewAccidentFlowEvt.publish();
  };

  states = () => [
    {
      value: 'all',
      label: 'All',
      query: {},
    },
    {
      value: 'recent',
      label: 'Recent',
      query: {
        created_at__gte: getRecentQueryDate(),
      },
    },
    {
      value: 'pending4x4',
      label: 'Pending 4x4',
      query: {
        accidentstate__state: pending4By4State.key,
      },
    },
  ];

  changeState = (stateValue) => {
    const state = [...this.states()].find(({value}) => value === stateValue);
    if (!state) return;
    this.setState({state: stateValue});
    this.getAccidents({page: 1, query: state.query});
  };

  render() {
    const {loading, accidents, page, sort, search, state} = this.state;
    return (
      <AccidentsList
        loading={loading}
        accidents={accidents}
        state={state}
        states={this.states()}
        onState={this.changeState}
        page={page}
        pages={this.pages()}
        onPage={this.onPage}
        sort={sort}
        onSort={this.onSort}
        onAccident={this.onAccident}
        search={search}
        onSearch={this.onSearch}
        onNewAccident={this.startAccidentFlow}
      />
    );
  }
}

export default withRouter(subscriptionHoc(AccidentsContainer));
