import React, {Component} from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import {connect} from 'react-redux';

// Actions
import {hide as hideAct} from './redux/actions';

// Attributes
import amountDueAttr from '../../attributes/amount_due.attribute.ticket';
import dueDatetimeAttr from '../../attributes/due_datetime.attribute.ticket';
import fineAmountAttr from '../../attributes/fine_amount.attribute.ticket';
import interestAmountAttr from '../../attributes/interest_amount.attribute.ticket';
import paymentAmountAttr from '../../attributes/payment_amount.attribute.ticket';
import penaltyAmountAttr from '../../attributes/penalty_amount.attribute.ticket';
import plateAttr from '../../../vehicle/attributes/plate.attribute.vehicle';
import reductionAmountAttr from '../../attributes/reduction_amount.attribute.ticket';
import stateAttr from '../../attributes/state.attribute.ticket';
import summonsNumberAttr from '../../attributes/summons_number.attribute.ticket';
import violationDatetimeAttr from '../../attributes/violation_datetime.attribute.ticket';
import violationAttr from '../../attributes/violation.attribute.ticket';

// Api
import createApi from '../../api/create.api.ticket';
import uploadDocumentApi from '../../../document/api/upload.api.document';

// Components
import NewTicketModal from '../../components/NewTicketModal/NewTicketModal';

// Documents
import ticketDoc from '../../documents/folders/ticket.document.ticket';

// Lib
import {lib} from '@matthahn/sally-ui';
import parseError from '../../../error/parseError';

// Prep
import createPrep from '../../preparation/create.preparation.ticket';

// Alerts
const {alert} = lib;

class NewTicketContainer extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    dispatch: PropTypes.func,
  };

  static INITIAL_STATE = {
    loading: false,
    image: null,
    amount_due: amountDueAttr(''),
    due_datetime: dueDatetimeAttr(''),
    fine_amount: fineAmountAttr(''),
    interest_amount: interestAmountAttr('0'),
    payment_amount: paymentAmountAttr('0'),
    penalty_amount: penaltyAmountAttr('0'),
    plate: plateAttr(''),
    reduction_amount: reductionAmountAttr('0'),
    state: stateAttr('NY'),
    summons_number: summonsNumberAttr(''),
    violation_datetime: violationDatetimeAttr(''),
    violation: violationAttr(''),
  };

  state = {...this.constructor.INITIAL_STATE};

  componentDidMount() {
    if (this.props.visible) this.init();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) this.init();
  }

  init = () => {
    this.setState({...this.constructor.INITIAL_STATE});
  };

  onClose = () => {
    if (this.state.loading) return;
    this.props.dispatch(hideAct());
  };

  onChange = (val, key) => {
    if (this.state.loading) return;
    
    // Calculate new amount due
    const {
      fine_amount,
      interest_amount,
      penalty_amount,
      reduction_amount,
      payment_amount
    } = {...this.state, [key]: val};

    const amount_due = amountDueAttr(
      numeral(fine_amount.value()).value() + 
      numeral(interest_amount.value()).value() +
      numeral(penalty_amount.value()).value() -
      numeral(reduction_amount.value()).value() -
      numeral(payment_amount.value()).value()
    );

    this.setState({[key]: val, amount_due});
  };

  save = async () => {
    const {visible, dispatch} = this.props;
    const {loading, ...attributes} = this.state;

    if (loading || !visible) return;

    this.setState({loading: true});

    try {
      const {image, ...preparedTicket} = await createPrep(attributes);
      const ticket = await createApi(preparedTicket);
      await this.uploadImage({ticket, image});
      dispatch(hideAct());
      alert.success('Ticket created');
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
    }

    this.setState({loading: false});
  };

  uploadImage = async ({ticket, image}) => {
    try {
      await uploadDocumentApi({
        doc: image,
        file: ticketDoc,
        ticket,
        type: 'ticket',
      });
    } catch (error) {
      // DO nothing, just don't fail
    }
  };

  render() {
    const {visible} = this.props;
    const {
      loading,
      image,
      amount_due,
      due_datetime,
      fine_amount,
      interest_amount,
      payment_amount,
      penalty_amount,
      plate,
      reduction_amount,
      state,
      summons_number,
      violation_datetime,
      violation,
    } = this.state;
    return (
      <NewTicketModal
        visible={visible}
        loading={loading}
        onChange={this.onChange}
        onClose={this.onClose}
        onSave={this.save}
        image={image}
        amount_due={amount_due}
        due_datetime={due_datetime}
        fine_amount={fine_amount}
        interest_amount={interest_amount}
        payment_amount={payment_amount}
        penalty_amount={penalty_amount}
        plate={plate}
        reduction_amount={reduction_amount}
        state={state}
        summons_number={summons_number}
        violation_datetime={violation_datetime}
        violation={violation}
      />
    );
  }
}

export default connect((state) => ({...state.newTicket}))(NewTicketContainer);
