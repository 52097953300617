import {SELECT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {string} from '../../types';

export default attribute({
  type: SELECT,
  attribute: 'preferred_payment_way',
  api: string,
  label: {
    default: 'Preferred Payment Way',
    short: 'Preferred Payment Way',
  },
  options: [
    {
      value: 'disabled',
      label: 'Disabled',
    },
    {
      value: 'check',
      label: 'Check',
    },
    {
      value: 'direct_deposit',
      label: 'Direct Deposit',
    },
  ],
});
