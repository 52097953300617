import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// google
import {GoogleLogin} from 'react-google-login';

// local components
import {Button, Input, Form} from '@matthahn/sally-ui';
import FormContainer from './components/FormContainer';
import Logo from './components/Logo';
import Submit from './components/Submit';

const SSO = ({
  clientId,
  loading,
  loginWithCredentials,
  onChange,
  onFailure,
  onSignIn,
  onStart,
  onSuccess,
  password,
  username,
}) => (
  <Fragment>
    <Logo color="white" />
    {loginWithCredentials ? (
      <Form onSubmit={onSignIn}>
        <FormContainer>
          <Input
            value={username}
            onChange={onChange('username')}
            disabled={loading}
            placeholder="Username"
            size="xl"
            autoCapitalize="none"
            transparent
            moreRadius
          />
          <Input
            type="password"
            value={password}
            onChange={onChange('password')}
            disabled={loading}
            placeholder="Password"
            size="xl"
            autoCapitalize="none"
            transparent
            moreRadius
          />
          <Submit>
            <Button
              loading={loading}
              size="xl"
              icon="login"
              theme="black"
              noRadius
              block
              shadow
            >
              Sign In
            </Button>
          </Submit>
        </FormContainer>
      </Form>
    ) : (
      <GoogleLogin
        clientId={clientId}
        buttonText="Login"
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={'single_host_origin'}
        render={({onClick, ...renderProps}) => (
          <Button
            {...renderProps}
            onClick={() => {
              onStart();
              onClick();
            }}
            loading={loading}
            size="xl"
            icon="google"
            theme="black"
            noRadius
            block
            shadow
          >
            Sign In
          </Button>
        )}
      />
    )}
  </Fragment>
);

SSO.propTypes = {
  clientId: PropTypes.string,
  loading: PropTypes.bool,
  loginWithCredentials: PropTypes.bool,
  onChange: PropTypes.func,
  onFailure: PropTypes.func,
  onSignIn: PropTypes.func,
  onStart: PropTypes.func,
  onSuccess: PropTypes.func,
  password: PropTypes.string,
  username: PropTypes.string,
};

export default SSO;
