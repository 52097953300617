import {DATETIME} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {shortDateTime} from '../../types';

export default attribute({
  type: DATETIME,
  attribute: 'date_created',
  display: shortDateTime,
  label: {
    default: 'Created At',
    short: 'Created',
  },
});
