import {SELECT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

export default attribute({
  type: SELECT,
  attribute: 'subtype',
  label: {
    default: 'Type',
    short: 'Type',
  },
});
