import styled from 'styled-components';

// Colors
import {black} from '@matthahn/sally-ui/lib/libs/colors';

const Arrow = styled.div`
  position: absolute;
  top: 50%;
  cursor: pointer;
  font-size: 40px;
  color: ${black};
  user-select: none;
  transform: translateY(-50%);
  ${({left}) => `${left ? 'left' : 'right'}: 10px;`};
`;

export default Arrow;
