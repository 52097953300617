import {date, fileName, folder} from '../../../document';

export default folder({
  defaultFileName: 'Ticket',
  // TICKET - DRIVER ID - UPLOAD DATE
  fileName: fileName(
    ({ticket}) => `TICKET - ${ticket.summons_number} - ${date()}`
  ),
  folderName: 'Tickets',
  type: 'ticket',
  uploadable: false,
});
