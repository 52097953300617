import prepare from '../../attribute/prepare.attribute';

export default (attributes) =>
  prepare(attributes, {
    field: 'api',
    required: [
      'policy_number',
      'insurance_company_name',
      'insurance_company_number',
      'accident_phone_number',
      'policy_effective_date',
      'policy_expiration_date',
    ],
  });
