import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

// Events
import event from '../events/updated.event.ticket';

export default async (ticketID, rentalID) => {
  const response = await f3tch(`/tickets/${ticketID}/assign_rental/`)
    .profile(profile())
    .body({rental: rentalID})
    .post();
  event.pub(response);
  return response;
};
