import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

export default attribute({
  type: TEXT,
  attribute: 'payee',
  label: {
    default: 'Payee',
    short: 'Payee',
  },
});
