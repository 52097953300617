import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

// api
import api from '@matthahn/sally-fw/lib/api/lib/getEverything.lib.api';

// event HOCs
import subscriptionHoc from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// redux
import {connect} from 'react-redux';

// vehicle actions
import {set as setVehicleAction} from '../../redux/actions';

// vehicle api
import getKeyLabelHistoryApi from '../../api/getKeyLabelHistory.api.vehicle';

// vehicle components
import KeyLabel from '../../components/KeyLabel/KeyLabel';
import KeyLabelHistoryModal from '../../components/KeyLabelHistoryModal/KeyLabelHistoryModal';

// vehicle sockets
import keyLabelUpdatedSocket from '../../socket/keyLabelUpdated.socket.vehicle';

class KeyLabelContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    keyLabel: PropTypes.object,
    vehicle: PropTypes.object,
    subscribe: PropTypes.func,
  };

  state = {
    visible: false,
    loading: false,
    keyLabels: [],
  };

  componentDidMount() {
    this.props.subscribe(keyLabelUpdatedSocket.subscribe(this.keyLabelUpdated));
  }

  showModal = () => {
    this.loadKeyLabels();
  };

  hideModal = () => {
    if (this.state.loading) return;
    this.setState({visible: false});
  };

  keyLabelUpdated = (keyLabel) => {
    const {dispatch, vehicle} = this.props;
    if (vehicle.vin !== keyLabel.vin) return;
    dispatch(setVehicleAction({keyLabel}));
  };

  loadKeyLabels = async () => {
    const {vehicle} = this.props;
    const {loading} = this.state;
    if (loading) return;
    this.setState({loading: true, visible: true});

    try {
      const {data: keyLabels} = await api(
        (query) => getKeyLabelHistoryApi(vehicle.id, query),
        {
          ordering: '-created_at',
        }
      );
      this.setState({loading: false, keyLabels});
    } catch (error) {
      this.setState({loading: false, keyLabels: []});
    }
  };

  render() {
    const {keyLabel} = this.props;
    const {visible, loading, keyLabels} = this.state;
    return !keyLabel?.label ? null : (
      <Fragment>
        <KeyLabel label={keyLabel.label} onClick={this.showModal} />
        <KeyLabelHistoryModal
          visible={visible}
          loading={loading}
          keyLabels={keyLabels}
          onClose={this.hideModal}
        />
      </Fragment>
    );
  }
}

export default connect((state) => ({
  keyLabel: state.vehicle.keyLabel,
  vehicle: state.vehicle.vehicle,
}))(subscriptionHoc(KeyLabelContainer));
