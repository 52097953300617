import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Info} from '@matthahn/sally-ui';

const DriverBlacklistedInfo = ({driver}) => (
  <Info type="error">
    Driver blacklisted.
    {!!driver && !!driver.driver_blacklisted && (
      <span> Reason: {driver.driver_blacklisted.reason}</span>
    )}
  </Info>
);

DriverBlacklistedInfo.propTypes = {
  driver: PropTypes.object,
};

export default DriverBlacklistedInfo;
