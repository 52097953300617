import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Api
import updateApi from '../../api/update.api.medallionOwner';

// Attributes
import nameAttr from '../../attributes/name.attribute.medallionOwner';
import taxIdAttr from '../../attributes/taxx_id.attribute.medallionOwner';

// Components
import BasicInfo from '../../components/BasicInfo/BasicInfo';
import BasicInfoEdit from '../../components/BasicInfoEdit/BasicInfoEdit';

// Libs
import {lib} from '@matthahn/sally-ui';
import parseError from '../../../error/parseError';

// Prep
import updatePrep from '../../preparation/updateBasicInfo.preparation.medallionOwner';

// Notifications
const {alert} = lib;

class BasicInfoContainer extends Component {
  static propTypes = {
    medallionOwner: PropTypes.object,
  };

  state = {
    loading: false,
    edit: false,
    errors: [],
    name: nameAttr(''),
    taxx_id: taxIdAttr(''),
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  startEdit = () => {
    const {medallionOwner} = this.props;
    if (this.state.loading) return;
    this.setState({
      edit: true,
      name: nameAttr(medallionOwner.name || ''),
      taxx_id: taxIdAttr(medallionOwner.taxx_id || ''),
    });
  };

  stopEdit = () => {
    if (this.state.loading) return;
    this.setState({edit: false, errors: []});
  };

  onChange = (val, key, errors) => {
    if (this.state.loading) return;
    this.setState({[key]: val, errors});
  };

  update = async () => {
    const {loading, edit, errors, ...attributes} = this.state;
    if (loading) return;

    this.setState({loading: true});

    try {
      const obj = await updatePrep(attributes);
      await updateApi(this.props.medallionOwner.id, obj);
      if (!this.mounted) return;
      this.setState({loading: false, edit: false});
    } catch (error) {
      if (!this.mounted) return;
      const {fields: errors, message} = parseError(error);
      alert.error(message);
      this.setState({loading: false, errors});
    }
  };

  render() {
    const {medallionOwner} = this.props;
    const {loading, edit, errors, name, taxx_id} = this.state;
    return edit ? (
      <BasicInfoEdit
        loading={loading}
        errors={errors}
        name={name}
        taxx_id={taxx_id}
        onCancel={this.stopEdit}
        onChange={this.onChange}
        onSave={this.update}
      />
    ) : (
      <BasicInfo {...medallionOwner} onEdit={this.startEdit} />
    );
  }
}

export default connect((state) => ({
  medallionOwner: state.medallionOwner.object,
}))(BasicInfoContainer);
