import jwt from 'jsonwebtoken';

// Api
import refreshToken from '../api/refreshToken.api.auth';
import getUserApi from '../api/getUserInfo.api.auth';

// Action constants
import {SET_TOKEN} from './constants';

// Events
import unauthorizedEvt from '../events/unauthorized.event.auth';

// Constants
import authKey from '../constants/key.const.auth';

// Libraries
import * as storage from '../../lib/localStorage';
import roles from '../lib/roles.lib.auth';
import authToken from '../lib/token.lib.auth';

// Store
import store from '../../redux/store';

// Sockets
import socket from '../../sockets/socket';

const checkToken = (token) => async (dispatch) => {
  try {
    const response = await refreshToken(token);
    const data = jwt.decode(token);
    const user = await getUserApi(data.user_id, token);
    dispatch(signIn(response.token, user));
  } catch (error) {
    dispatch(signOut(false));
  }
};

export const init = () => (dispatch) => {
  const token = storage.get(authKey);
  if (!token)
    return dispatch({
      type: SET_TOKEN,
      data: {
        token: null,
        username: '',
        userID: 0,
        first_name: '',
        last_name: '',
        roles: roles(),
      },
    });
  dispatch(checkToken(token));
};

export const signIn = (token, user) => (dispatch, getState) => {
  try {
    storage.add(authKey, token);
    authToken.set(token);
    if (!getState().auth.token) socket.authorize(token);
    dispatch({
      type: SET_TOKEN,
      data: {
        token,
        userID: user.id || 0,
        username: user.username,
        first_name: user.first_name || '',
        last_name: user.last_name || '',
        roles: roles(user),
        kibana_creds: user.kibana_creds || '',
        user,
      },
    });
  } catch (error) {
    dispatch(signOut());
  }
};

export const signOut = () => (dispatch, getState) => {
  storage.remove(authKey);
  authToken.remove();
  socket.deauthorize();
  dispatch({
    type: SET_TOKEN,
    data: {
      token: null,
      username: '',
      userID: 0,
      first_name: '',
      last_name: '',
      roles: roles(),
      user: null,
    },
  });
};

unauthorizedEvt.sub(() => {
  store.dispatch(signOut());
});
