import isArray from 'isarray';
import isObject from 'is-object';

// Error
import {generalError} from './error.attribute';

export default (attributes) => {
  if (!isArray(attributes) && !isObject(attributes))
    throw generalError('Attributes is not an object or an array');
  return isArray(attributes) ? [...attributes] : Object.values({...attributes});
};
