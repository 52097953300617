import {fileName, folder, date} from '../../../document';

// Attributes
import {plate as plateAttr} from '../../../vehicle/attributes';

// Errors
import plateMissmatch from '../../../vehicle/errors/plateMissmatch.error.vehicle';

export default folder({
  attributes: [plateAttr],
  fileAttributes: ['plate'],
  defaultFileName: 'Police Report',
  // POLICE REPORT - DRIVER ID - DATE OF LOSS
  fileName: fileName(
    ({accident}) =>
      `POLICE REPORT - ${accident.driver?.first_name}_${
        accident.driver?.last_name
      } - ${accident?.vehicle?.svid} - ${date()}`
  ),
  preSaveValidation: ({plate, accident}) => {
    if (accident?.vehicle?.plate !== plate.api.format())
      throw plateMissmatch({
        message: 'Plates need to match',
      });
  },
  folderName: 'Police Reports',
  type: 'accidentPoliceReport',
});
