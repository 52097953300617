import f3tch from 'f3tch';

// Profiles
import profile from '../../api/profiles/json.profile.api';

export default ({username, password}) =>
  f3tch('/authentication/login/')
    .profile(profile())
    .body({username, password})
    .post();
