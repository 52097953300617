import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// actions
import {set as setAct} from '../../redux/actions';

// api
import listVehicleTypesApi from '../../api/listTypes.api.vehicle';

// components
import {FullScreenLoader} from '@matthahn/sally-ui';

// error
import parseError from '@matthahn/sally-fw/lib/error/parseError';

class VehicleTypeFetchContainer extends Component {
  static propTypes = {
    children: PropTypes.node,
    dispatch: PropTypes.func,
  };

  state = {
    loaded: false,
  };

  componentDidMount() {
    this.init();
  }

  init = async () => {
    const {dispatch} = this.props;
    try {
      const {make_models, vehicle_types} = await listVehicleTypesApi();
      dispatch(
        setAct({
          makes: make_models,
          types: vehicle_types,
        })
      );
      this.setState({loaded: true});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      alert.info('Refresh');
    }
  };

  render() {
    const {children} = this.props;
    const {loaded} = this.state;
    return loaded ? children : <FullScreenLoader theme="orange" />;
  }
}

export default connect()(VehicleTypeFetchContainer);
