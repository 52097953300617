import React from 'react';
import PropTypes from 'prop-types';

// components
import TableHeader from '@matthahn/sally-ui/lib/components/Table/components/TableHeader.Table';
import TableHeaderColumn from '@matthahn/sally-ui/lib/components/Table/components/TableHeaderColumn.Table';

const AccidentTableHeader = ({children}) => (
  <TableHeader noRadius>
    <TableHeaderColumn portions={1} span={1}>
      {children}
    </TableHeaderColumn>
  </TableHeader>
);

AccidentTableHeader.propTypes = {
  children: PropTypes.node,
};

export default AccidentTableHeader;
