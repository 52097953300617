import isObject from 'is-object';
import isFunction from 'is-function';

// Default type
import {any} from '../types/';

const init = ({
  type = null,
  attribute = null,
  display = any,
  input = any,
  api = any,
  label = null,
  errorMessage = null,
  ...additional
} = {}) => {
  if (type === null) throw new Error('please specify a attribute type');

  if (!isObject(label) || !label.default || !label.short)
    throw new Error('invalid label attribute');

  if (!attribute) throw new Error('please specify the attribute name');

  if (!isFunction(display))
    throw new Error(
      `display parameter should be a function but got ${typeof display}`
    );
  if (!isFunction(input))
    throw new Error(
      `input parameter should be a function but got ${typeof input}`
    );
  if (!isFunction(api))
    throw new Error(`api parameter should be a function but got ${typeof api}`);

  const attributeFn = (firstInput, ...args) => ({
    type,
    label,
    attribute,
    additional,
    errorMessage,
    reinit: (newInput, ...newArgs) => {
      const initArgs = !!newArgs.length ? newArgs : args;
      return init({
        type,
        attribute,
        display,
        input,
        api,
        label,
        errorMessage,
        ...additional,
      })(newInput !== undefined ? newInput : firstInput, ...initArgs);
    },
    display: display(firstInput, ...args),
    api: api(firstInput, ...args),
    input: input(firstInput, ...args),
    value: () => (firstInput !== undefined ? firstInput : null),
  });
  attributeFn.type = type;
  attributeFn.label = label;
  attributeFn.attribute = attribute;
  attributeFn.errorMessage = errorMessage;
  attributeFn.additional = additional;
  attributeFn.switchAttribute = (newAttributeName) =>
    init({
      type,
      attribute: newAttributeName,
      display,
      input,
      api,
      label,
      errorMessage,
      ...additional,
    });
  return attributeFn;
};

export default init;
