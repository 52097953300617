import prepare from '../../attribute/prepare.attribute';

// Constants
import dmvAttributes from '../constants/dmvAttributes.constants.driver';

export default async (attributes) =>
  prepare(attributes, {
    field: 'api',
    required: [...dmvAttributes],
  });
