import styled from 'styled-components';

const Content = styled.div`
  width: 100%;
  max-width: 300px;
  padding: 50px 0px;
  margin: auto;
`;

export default Content;
