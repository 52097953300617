// Action constants
import {SET} from './constants';

const DEFAULT_STATE = {
  loadingDriver: false,
  driver: null,

  loadingLease: false,
  lease: null,

  loadingNotes: false,
  notes: [],

  loadingActiveRental: false,
  activeRental: null,

  loadingDriverAlerts: false,
  driverAlerts: [],

  loadingCsr: false,
  csr: null,

  waitingTickets: [],
};

export default (state = DEFAULT_STATE, {type, data}) => {
  switch (type) {
    case SET: {
      return {
        ...state,
        ...data,
      };
    }

    default: {
      return state;
    }
  }
};
