import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Attribute
import amountAttr from '../../attributes/amount.attribute.payout';

// Constants
import EMPTY_EDITABLE_FIELDS from '../../constants/emptyEditableFields.constant.payout';
import EDITABLE_FIELDS from '../../constants/editableFields.constant.payout';
import HIDDEN_EMPTY_FIELDS from '../../constants/hiddenEmptyFields.constant.payout';

// Components
import {
  Modal,
  P,
  Table,
  TextColor,
  Heading,
  Info,
  Row,
  Column,
  Button,
} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';
import SmallLoader from '../../../layout/components/SmallLoader/SmallLoader';
import NegativeParenthesis from '../../../layout/components/NegativeParenthesis/NegativeParenthesis';
import EndDatetime from '../EndDatetime/EndDatetime';

import Label from './components/Label';
import FhvContainer from './components/FhvContainer';
import ChargeLabel from './components/ChargeLabel';
import Overflow from './components/Overflow';
import RoundMessage from './components/RoundMessage';
import FlexRow from './components/FlexRow';
import FlexCol from './components/FlexCol';
import Preview from './components/Preview';
import Img from './components/Img';
import IFrame from './components/IFrame';
import DateContainer from './components/DateContainer';
import PaymentMethodCol from './components/PaymentMethodCol';
import PaymentMethodRow from './components/PaymentMethodRow';

// Columns
import columns from './columns';
import ticketColumns from './ticketColumns';

// dispatch components
import DispatchAlerts from '../../../dispatch/components/DispatchAlerts/DispatchAlerts';

// Libs
import isImage from 'is-image';
import amountColor from '../../lib/amountColor.lib.payout';
import subtract from '../../../lib/subtract';
import focusAfterAnimation from '../../../layout/lib/focusAfterAnimation.lib.layout';
import isDirectDeposit from '../../../payout/lib/isDirectDeposit.lib.payout';

// Types
import {amount, dateTime as dateTimeType} from '../../../types';

class PayoutDriverModal extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    displayDate: PropTypes.bool,
    initing: PropTypes.bool,
    loading: PropTypes.bool,
    showTickets: PropTypes.bool,
    isIncomeAllocation: PropTypes.bool,
    isAcceptingCheck: PropTypes.bool,
    shouldCaptureMileage: PropTypes.bool,
    title: PropTypes.string,
    payoutButtonLabel: PropTypes.string,
    creditAmount: PropTypes.number,
    isDepositAllocation: PropTypes.bool,
    depositAmount: PropTypes.number,
    showAlerts: PropTypes.bool,
    openTolls: PropTypes.number,
    transactions: PropTypes.array,
    returnCredit: PropTypes.number,
    earnings: PropTypes.object,
    totalAmount: PropTypes.number,
    roundedTotal: PropTypes.number,
    leftover: PropTypes.number,
    credit: PropTypes.number,
    unverifiedBankAccounts: PropTypes.array,
    contract: PropTypes.object,
    fhv: PropTypes.object,
    driver: PropTypes.object,
    rental: PropTypes.object,
    payout: PropTypes.object,
    mileage: PropTypes.object,
    endRental: PropTypes.bool,
    paymentMethods: PropTypes.array,
    payment_method: PropTypes.object,
    accepting_payment_method: PropTypes.object,
    check_number_from_driver: PropTypes.object,
    isCashPayout: PropTypes.bool,
    tickets: PropTypes.array,
    loadingBankAccountBalance: PropTypes.bool,
    availableBankAccountBalance: PropTypes.number,
    waitingTickets: PropTypes.array,
    autochargingTickets: PropTypes.array,
    lastRoundMessageShake: PropTypes.number,
    owedRent: PropTypes.number,
    end_datetime: PropTypes.object,
    onEndDatetime: PropTypes.func,
    onPaymentMethodChange: PropTypes.func,
    onPayout: PropTypes.func,
    onClose: PropTypes.func,
    onOpenTransaction: PropTypes.func,
    onOpenCredit: PropTypes.func,
    onPaymentChange: PropTypes.func,
    onNewCharge: PropTypes.func,
    onNewCredit: PropTypes.func,
    onTakePayment: PropTypes.func,
    onEarnings: PropTypes.func,
    onPayoutFocus: PropTypes.func,
    onPayoutBlur: PropTypes.func,
    onTickets: PropTypes.func,
    onTicket: PropTypes.func,
    onFhv: PropTypes.func,
    onContract: PropTypes.func,
    onGoToDriverPage: PropTypes.func,
    onChange: PropTypes.func,
    onCreateDriverAlert: PropTypes.func,
    onCreateVehicleAlert: PropTypes.func,
    onTolls: PropTypes.func,
  };

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.visible && this.props.visible) ||
      (prevProps.initing && !this.props.initing)
    )
      this.focus();
  }

  input = null;

  focus = () => {
    const {visible, initing, driver} = this.props;
    if (!visible || initing || !!driver || !this.input) return;
    focusAfterAnimation(this.input);
  };

  inputRef = (dom) => {
    this.input = dom;
  };

  render() {
    const {
      displayDate,
      visible,
      closable,
      initing,
      loading,
      title,
      payoutButtonLabel,
      showTickets,
      isIncomeAllocation,
      isAcceptingCheck,
      shouldCaptureMileage,
      mileage,
      creditAmount,
      isDepositAllocation,
      depositAmount,
      showAlerts,
      openTolls,
      transactions,
      earnings,
      totalAmount,
      roundedTotal,
      leftover,
      credit,
      fhv,
      // contract,
      unverifiedBankAccounts,
      driver,
      rental,
      payout,
      returnCredit,
      paymentMethods,
      payment_method,
      accepting_payment_method,
      check_number_from_driver,
      isCashPayout,
      lastRoundMessageShake,
      // endRental,
      tickets,
      loadingBankAccountBalance,
      availableBankAccountBalance,
      waitingTickets,
      autochargingTickets,
      owedRent,
      end_datetime,
      onEndDatetime,
      onPaymentMethodChange,
      onPayout,
      onClose,
      onOpenTransaction,
      onOpenCredit,
      onPaymentChange,
      onNewCharge,
      onNewCredit,
      onTakePayment,
      onEarnings,
      onPayoutFocus,
      onPayoutBlur,
      onTickets,
      onTicket,
      onFhv,
      // onContract,
      onGoToDriverPage,
      onChange,
      onCreateDriverAlert,
      onCreateVehicleAlert,
      onTolls,
    } = this.props;

    const directDeposit = isDirectDeposit(payment_method.api.format());

    return (
      <Modal
        title={title}
        disableOutsideClickClose
        subtitle={
          isDepositAllocation
            ? `$${amount(depositAmount).format()}`
            : isIncomeAllocation
            ? `$${amount(creditAmount).format()}`
            : null
        }
        icon={
          isDepositAllocation ? 'swap' : isIncomeAllocation ? 'upload' : 'enter'
        }
        visible={visible}
        closable={closable}
        onClose={onClose}
        size={!initing && !!driver && !!fhv ? 'large' : 'medium'}
        buttonsRight={
          !driver || initing
            ? []
            : [
                {
                  label: payoutButtonLabel,
                  theme: 'orange',
                  loading,
                  disabled: initing,
                  onClick: onPayout,
                },
              ]
        }
        headerActions={
          !driver || isIncomeAllocation || isDepositAllocation
            ? []
            : [
                {
                  icon: 'car',
                  tooltip: 'Vehicle alert',
                  onClick: onCreateVehicleAlert,
                },
                {
                  icon: 'user',
                  tooltip: 'Driver alert',
                  onClick: onCreateDriverAlert,
                },
                {
                  icon: 'barcode',
                  tooltip: 'Take Payment',
                  disabled: loading,
                  onClick: onTakePayment,
                },
                {
                  icon: 'creditcard',
                  tooltip: 'Charge',
                  disabled: loading,
                  onClick: onNewCharge,
                },
                {
                  icon: 'upload',
                  tooltip: 'Credit',
                  disabled: loading,
                  onClick: onNewCredit,
                },
              ]
        }
      >
        {(Content) =>
          initing || !driver ? (
            <Content padding="none">
              <FhvContainer>
                <SmallLoader />
              </FhvContainer>
            </Content>
          ) : (
            <Content padding="none" noBorder>
              {showAlerts && openTolls > 0 && (
                <Info
                  type="warning"
                  action={{label: 'Show', onClick: onTolls}}
                  flat
                >
                  This vehicle has {openTolls} unassigned tolls
                </Info>
              )}
              {showAlerts && !!tickets.length && (
                <Info
                  type="warning"
                  action={{
                    label: showTickets ? 'Hide' : 'Resolve',
                    onClick: onTickets,
                  }}
                  flat
                >
                  There {tickets.length === 1 ? 'is' : 'are'} {tickets.length}{' '}
                  {tickets.length === 1 ? 'ticket' : 'tickets'} that need
                  attention
                </Info>
              )}
              {showAlerts && !!waitingTickets.length && (
                <Info
                  type={!!autochargingTickets.length ? 'error' : 'info'}
                  flat
                >
                  There {waitingTickets.length === 1 ? 'is' : 'are'}{' '}
                  {waitingTickets.length}{' '}
                  {waitingTickets.length === 1 ? 'ticket' : 'tickets'} waiting
                  to be resolved.
                  {!!autochargingTickets.length && (
                    <TextColor weight="bold" theme="white">
                      {' '}
                      We will automatically charge for{' '}
                      {autochargingTickets.length} within a week.
                    </TextColor>
                  )}
                </Info>
              )}
              {/* {showAlerts && !contract && (
                <Info
                  type="warning"
                  action={{
                    label: 'Sign',
                    onClick: onContract,
                  }}
                  flat
                >
                  Contract is missing
                </Info>
              )} */}
              {showAlerts && !fhv && (
                <Info
                  type="warning"
                  action={{
                    label: 'Take Picture',
                    onClick: onFhv,
                  }}
                  flat
                >
                  Driver's FHV license is missing
                </Info>
              )}
              {showAlerts && unverifiedBankAccounts.length > 0 && (
                <Info
                  type="info"
                  action={{
                    label: 'Verify',
                    onClick: onGoToDriverPage,
                  }}
                  flat
                >
                  There are unverified bank accounts
                </Info>
              )}
              {showAlerts && returnCredit > 0 && (
                <Info type="info" flat>
                  CREDIT WILL BE GIVEN FOR UNUSED RENTAL DAYS
                </Info>
              )}
              {showAlerts && (
                <DispatchAlerts
                  driver={driver}
                  driverAlertFilter={({isPayout}) => !isPayout}
                  vehicle={
                    !!rental?.vehicle
                      ? !!rental?.vehicle?.id
                        ? rental.vehicle
                        : {id: rental.vehicle}
                      : null
                  }
                />
              )}
              {showTickets && !!tickets.length && (
                <Table
                  columns={ticketColumns}
                  theme="orange"
                  infinite
                  noBorder
                  noRadius
                  smallNoResultsLabel
                >
                  {(TableRow) =>
                    tickets.map((ticket) => (
                      <TableRow onClick={onTicket(ticket)}>
                        {(TableCol) => [
                          <TableCol key="summons_number">
                            {ticket.summons_number}
                          </TableCol>,
                          <TableCol key="due_datetime">
                            {dateTimeType(ticket.due_datetime).format()}
                          </TableCol>,
                          <TableCol key="violation" span={2}>
                            {ticket.violation}
                          </TableCol>,
                          <TableCol key="medallion">
                            {!!ticket.vehicle && !!ticket.vehicle.medallion
                              ? ticket.vehicle.medallion.medallion_number
                              : '-'}
                          </TableCol>,
                          <TableCol key="fine_amount">
                            ${amount(ticket.fine_amount).format()}
                          </TableCol>,
                        ]}
                      </TableRow>
                    ))
                  }
                </Table>
              )}
              <Content>
                <Heading size="3" bold>
                  {driver.first_name} {driver.last_name} (
                  {driver.fhv_license_number}){' '}
                  {!!rental && `on ${rental.medallion.medallion_number}`}
                </Heading>
              </Content>
              {displayDate && (
                <Content>
                  <DateContainer>
                    <EndDatetime
                      end_datetime={end_datetime}
                      onEndDatetime={onEndDatetime}
                    />
                  </DateContainer>
                </Content>
              )}
              <FlexRow>
                <FlexCol full={!fhv}>
                  <div id="payoutContainer">
                    <Content padding="none">
                      <Table
                        columns={columns}
                        theme="orange"
                        infinite
                        noBorder
                        noRadius
                        smallNoResultsLabel
                      >
                        {(TableRow) =>
                          [
                            ...transactions.map((transaction) =>
                              transaction.owed_to_sally &&
                              !transaction.balance &&
                              HIDDEN_EMPTY_FIELDS.includes(
                                transaction.subtype
                              ) ? null : (
                                <TableRow key={transaction.key}>
                                  {(TableColumn) => [
                                    <TableColumn
                                      key="type"
                                      span={1.5}
                                      onClick={onOpenTransaction(transaction)}
                                    >
                                      <Label>{transaction.label}</Label>
                                    </TableColumn>,
                                    <TableColumn
                                      key="outstanding"
                                      onClick={onOpenTransaction(transaction)}
                                      align="right"
                                    >
                                      <Label>
                                        {transaction.subtype === 'rent' &&
                                          !!owedRent && (
                                            <TextColor theme="red">
                                              <Overflow>
                                                <NegativeParenthesis
                                                  value={owedRent}
                                                />
                                              </Overflow>
                                            </TextColor>
                                          )}
                                        <TextColor
                                          theme={
                                            !!transaction.balance &&
                                            !transaction.owed_to_sally
                                              ? 'green'
                                              : 'black'
                                          }
                                          weight={
                                            !!transaction.balance &&
                                            !transaction.owed_to_sally
                                              ? 'bold'
                                              : 'normal'
                                          }
                                        >
                                          <Overflow>
                                            <NegativeParenthesis
                                              value={transaction.balance}
                                              wrap={
                                                !transaction.owed_to_sally &&
                                                !!transaction.balance
                                              }
                                              empty="-"
                                            />
                                          </Overflow>
                                        </TextColor>
                                      </Label>
                                    </TableColumn>,
                                    <TableColumn key="payment" align="right">
                                      {transaction.owed_to_sally ? (
                                        EDITABLE_FIELDS.includes(
                                          transaction.subtype
                                        ) &&
                                        (!!transaction.balance ||
                                          (!isIncomeAllocation &&
                                            EMPTY_EDITABLE_FIELDS.includes(
                                              transaction.subtype
                                            ))) ? (
                                          <AttributeInput
                                            value={amountAttr(
                                              payout[
                                                `${transaction.subtype}_amount`
                                              ] || ''
                                            )}
                                            onChange={onPaymentChange(
                                              `${transaction.subtype}_amount`
                                            )}
                                            size="small"
                                            disabled={loading}
                                            right
                                            condensed
                                          />
                                        ) : (
                                          <Label>
                                            <Overflow>
                                              <NegativeParenthesis
                                                value={
                                                  payout[
                                                    `${transaction.subtype}_amount`
                                                  ]
                                                }
                                                empty="-"
                                              />
                                            </Overflow>
                                          </Label>
                                        )
                                      ) : (
                                        ''
                                      )}
                                    </TableColumn>,
                                    <TableColumn
                                      key="balance"
                                      onClick={onOpenTransaction(transaction)}
                                      align="right"
                                    >
                                      <Label>
                                        <Overflow>
                                          {transaction.owed_to_sally ? (
                                            <TextColor
                                              theme={
                                                subtract(
                                                  transaction.balance,
                                                  payout[
                                                    `${transaction.subtype}_amount`
                                                  ] || 0
                                                ) < 0
                                                  ? 'red'
                                                  : 'darkerGrey'
                                              }
                                              weight="normal"
                                            >
                                              <NegativeParenthesis
                                                value={subtract(
                                                  transaction.balance,
                                                  payout[
                                                    `${transaction.subtype}_amount`
                                                  ] || 0
                                                )}
                                                empty="-"
                                              />
                                            </TextColor>
                                          ) : (
                                            ''
                                          )}
                                        </Overflow>
                                      </Label>
                                    </TableColumn>,
                                  ]}
                                </TableRow>
                              )
                            ),
                            credit && (
                              <TableRow key="credit" onClick={onOpenCredit}>
                                {(TableColumn) => [
                                  <TableColumn key="type" span={1.5}>
                                    <Label>Credit</Label>
                                  </TableColumn>,
                                  <TableColumn key="amount" align="right">
                                    <Label>
                                      <TextColor theme="green" weight="bold">
                                        <Overflow>
                                          <NegativeParenthesis
                                            value={credit}
                                            wrap={credit > 0}
                                          />
                                        </Overflow>
                                      </TextColor>
                                    </Label>
                                  </TableColumn>,
                                ]}
                              </TableRow>
                            ),
                            directDeposit && (
                              <TableRow key="bankAccountBalance">
                                {(TableColumn) => [
                                  <TableColumn key="type" span={2.5}>
                                    <Label>Dwolla Balance</Label>
                                  </TableColumn>,
                                  <TableColumn
                                    key="amount"
                                    align="right"
                                    span={2}
                                  >
                                    <P
                                      theme={amountColor(totalAmount)}
                                      align="right"
                                    >
                                      <Label>
                                        <Overflow>
                                          {loadingBankAccountBalance ? (
                                            'Loading..'
                                          ) : (
                                            <NegativeParenthesis
                                              value={
                                                availableBankAccountBalance
                                              }
                                            />
                                          )}
                                        </Overflow>
                                      </Label>
                                    </P>
                                  </TableColumn>,
                                ]}
                              </TableRow>
                            ),
                            <TableRow key="total">
                              {(TableColumn) => [
                                <TableColumn key="type" span={2.5}>
                                  <Label>Available</Label>
                                </TableColumn>,
                                <TableColumn key="amount" align="right">
                                  <P
                                    theme={amountColor(totalAmount)}
                                    align="right"
                                    weight="bold"
                                  >
                                    <Label>
                                      <Overflow>
                                        <NegativeParenthesis
                                          value={totalAmount}
                                        />
                                      </Overflow>
                                    </Label>
                                  </P>
                                </TableColumn>,
                              ]}
                            </TableRow>,
                            !isIncomeAllocation && totalAmount > 0 && (
                              <TableRow key="payout">
                                {(TableColumn) => [
                                  <TableColumn key="type" span={2.5}>
                                    <P weight="bold">
                                      <Label>Payout</Label>
                                    </P>
                                  </TableColumn>,
                                  <TableColumn key="amount">
                                    <AttributeInput
                                      value={earnings}
                                      onChange={onEarnings}
                                      disabled={loading}
                                      size="small"
                                      onFocus={onPayoutFocus}
                                      onBlur={onPayoutBlur}
                                      right
                                      condensed
                                    />
                                  </TableColumn>,
                                  <TableColumn key="message">
                                    {isCashPayout && (
                                      <RoundMessage
                                        lastRoundMessageShake={
                                          lastRoundMessageShake
                                        }
                                      >
                                        Cash payouts are rounded automatically
                                      </RoundMessage>
                                    )}
                                  </TableColumn>,
                                ]}
                              </TableRow>
                            ),
                            !isIncomeAllocation && totalAmount > 0 && (
                              <TableRow key="leftover">
                                {(TableColumn) => [
                                  <TableColumn key="type" span={2.5}>
                                    <Label>Savings</Label>
                                  </TableColumn>,
                                  <TableColumn key="amount" align="right">
                                    <Label>
                                      <TextColor
                                        theme={
                                          leftover < 0 ? 'red' : 'darkerGrey'
                                        }
                                      >
                                        <Overflow>
                                          <NegativeParenthesis
                                            value={leftover}
                                            empty="-"
                                          />
                                        </Overflow>
                                      </TextColor>
                                    </Label>
                                  </TableColumn>,
                                ]}
                              </TableRow>
                            ),
                          ].filter((row) => !!row)
                        }
                      </Table>
                    </Content>
                    {!isIncomeAllocation && totalAmount >= 0 && (
                      <Content padding="horizontal">
                        <Row margin />
                        <Row>
                          {paymentMethods.map((paymentMethod) => (
                            <Column size={1 / 2} margin key={paymentMethod.key}>
                              <Button
                                disabled={loading}
                                theme={
                                  payment_method.api.format() ===
                                  paymentMethod.key
                                    ? 'red'
                                    : 'grey'
                                }
                                onClick={() =>
                                  onPaymentMethodChange(
                                    payment_method.reinit(paymentMethod.key),
                                    'payment_method'
                                  )
                                }
                                block
                              >
                                {paymentMethod.label}
                              </Button>
                            </Column>
                          ))}
                        </Row>
                      </Content>
                    )}
                    {!isDepositAllocation &&
                      !isIncomeAllocation &&
                      roundedTotal < 0 && (
                        <Content>
                          <ChargeLabel>
                            <Row margin>
                              <Column>
                                <P>
                                  Please make sure to take{' '}
                                  <TextColor weight="bold">
                                    $
                                    {amount(
                                      Math.abs(
                                        isAcceptingCheck
                                          ? totalAmount
                                          : roundedTotal
                                      )
                                    ).format()}
                                  </TextColor>{' '}
                                  from {driver.first_name} {driver.last_name}.
                                </P>
                              </Column>
                            </Row>
                            <Row margin={isAcceptingCheck}>
                              <Column>
                                <PaymentMethodRow>
                                  {accepting_payment_method.additional.options.map(
                                    (paymentMethod) => (
                                      <PaymentMethodCol
                                        key={paymentMethod.value}
                                      >
                                        <Button
                                          disabled={loading}
                                          theme={
                                            accepting_payment_method.api.format() ===
                                            paymentMethod.value
                                              ? 'red'
                                              : 'grey'
                                          }
                                          onClick={() =>
                                            onChange(
                                              accepting_payment_method.reinit(
                                                paymentMethod.value
                                              ),
                                              'accepting_payment_method'
                                            )
                                          }
                                          block
                                        >
                                          {paymentMethod.label}
                                        </Button>
                                      </PaymentMethodCol>
                                    )
                                  )}
                                </PaymentMethodRow>
                              </Column>
                            </Row>
                            {isAcceptingCheck && (
                              <Row>
                                <Column>
                                  <AttributeInput
                                    value={check_number_from_driver}
                                    onChange={onChange}
                                    disabled={loading}
                                  >
                                    {check_number_from_driver.label.default}
                                  </AttributeInput>
                                </Column>
                              </Row>
                            )}
                          </ChargeLabel>
                        </Content>
                      )}
                  </div>
                </FlexCol>
                {!!fhv && (
                  <FlexCol border>
                    <Preview>
                      {isImage(fhv.name) ? (
                        <Img src={fhv.document_file} />
                      ) : (
                        <IFrame src={fhv.document_file} />
                      )}
                    </Preview>
                  </FlexCol>
                )}
              </FlexRow>
              {shouldCaptureMileage && (
                <Content>
                  <AttributeInput
                    value={mileage}
                    onChange={onChange}
                    disabled={loading}
                  >
                    Latest vehicle mileage
                  </AttributeInput>
                </Content>
              )}
            </Content>
          )
        }
      </Modal>
    );
  }
}

export default PayoutDriverModal;
