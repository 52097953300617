// List
import list from './list.attribute';

// Fields
import fields from './fields.attribute';

export default (attributes, field) => {
  fields(field);

  return list(attributes).reduce(
    (data, attribute) => ({
      ...data,
      [attribute.attribute]: attribute[field].format(),
    }),
    {}
  );
};
