import React from 'react';
import PropTypes from 'prop-types';

// Components
import {
  Heading,
  Container,
  Row,
  Column,
  Tag,
  P,
  Tabs,
  Icon,
  TextColor,
} from '@matthahn/sally-ui';
import PageContainer from './components/Container';
import Header from './components/Header';
import Content from './components/Content';
import HeaderContent from './components/HeaderContent';
import Big from './components/Big';
import Small from './components/Small';
import Space from './components/Space';
import SpaceTag from './components/SpaceTag';
import TabContainer from './components/TabContainer';

// Lib
import accessibility from '../../libs/accessibility.lib.vehicle';
import isOnHold from '../../../vehicleHold/lib/isOnHold.lib.vehicleHold';

// State
import vehicleState from '../../state';

const VehiclePageLayout = ({
  vin,
  year,
  make,
  model,
  type,
  state,
  medallion,
  hold,
  tag,
  tab,
  tabs,
  onTab,
  addon,
  children,
  onCreateAlert,
}) => (
  <PageContainer>
    <Header>
      <Container>
        <Row>
          <Column>
            <HeaderContent>
              <Big>
                <Heading size="1">
                  {isOnHold(hold) && (
                    <TextColor theme="red">
                      <Icon icon="warning" />{' '}
                    </TextColor>
                  )}
                  {accessibility(type) && '♿︎ '}
                  {vin}
                  {!!medallion && <span> - {medallion.medallion_number}</span>}
                  {tag}
                </Heading>
                <Space flex>
                  <SpaceTag theme="darkGrey" inline>
                    <Tag theme={vehicleState(state).color}>
                      {vehicleState(state).label}
                    </Tag>
                  </SpaceTag>
                </Space>
              </Big>
              <Small>
                <P theme="darkGrey" inline>
                  {year} {make} {model}
                </P>
              </Small>
            </HeaderContent>
          </Column>
        </Row>
      </Container>
      <TabContainer>
        <Tabs
          tab={tab}
          tabs={tabs}
          onChange={onTab}
          theme="orange"
          addon={(Addon) =>
            [!!addon && <Addon key="tabAddon">{addon}</Addon>].filter(
              (a) => !!a
            )
          }
          nav
        />
      </TabContainer>
    </Header>
    <Content>{children}</Content>
  </PageContainer>
);

VehiclePageLayout.propTypes = {
  vin: PropTypes.string,
  year: PropTypes.number,
  make: PropTypes.string,
  model: PropTypes.string,
  type: PropTypes.string,
  state: PropTypes.string,
  medallion: PropTypes.object,
  tab: PropTypes.string,
  tabs: PropTypes.array,
  hold: PropTypes.object,
  onTab: PropTypes.func,
  addon: PropTypes.node,
  children: PropTypes.node,
  onCreateAlert: PropTypes.func,
};

export default VehiclePageLayout;
