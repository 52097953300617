import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {
  Modal,
  Table,
  P,
  Row,
  Column,
  InputTyping,
  Text,
} from '@matthahn/sally-ui';

// Columns
import columns from './columns';

// Types
import {amount} from '../../../types';

const HotswapModal = ({
  loading,
  visible,
  vehicles,
  rental,
  search,
  onSearch,
  onHotswap,
  onClose,
}) => (
  <Modal
    visible={visible}
    title="Hotswap"
    subtitle={!!rental ? rental.medallion.medallion_number : ''}
    onClose={onClose}
  >
    {(Content) =>
      !rental ? null : (
        <Fragment>
          <Content>
            <Row margin>
              <Column size={1 / 2}>
                <Text
                  value={`${rental.driver.first_name} ${rental.driver.last_name}`}
                >
                  Driver
                </Text>
              </Column>
              <Column size={1 / 2}>
                <Text value={rental.medallion.medallion_number}>Vehicle</Text>
              </Column>
            </Row>
            <Row>
              <Column>
                <Text value={`$${amount(rental.amount).format()}`}>Rate</Text>
              </Column>
            </Row>
          </Content>
          <Content>
            <Row>
              <Column size={1 / 2} offset={1 / 4}>
                <InputTyping
                  value={search}
                  onChange={onSearch}
                  disabled={loading}
                  size="large"
                  placeholder="Search for vehicle"
                  preIcon="search1"
                />
              </Column>
            </Row>
          </Content>
          <Content padding="none">
            <Table
              columns={columns}
              theme="orange"
              infinite
              noBorder
              noRadius
              smallNoResultsLabel
            >
              {(TableRow) =>
                vehicles.map((vhcl) => (
                  <TableRow key={vhcl.id} onClick={onHotswap(vhcl)}>
                    {(TableColumn) => [
                      <TableColumn>
                        <P size="2">
                          {!!vhcl.medallion
                            ? vhcl.medallion.medallion_number
                            : ''}
                        </P>
                      </TableColumn>,
                      <TableColumn>
                        <P size="2">{vhcl.vehicle_year}</P>
                      </TableColumn>,
                      <TableColumn>
                        <P size="2">{vhcl.vehicle_model}</P>
                      </TableColumn>,
                    ]}
                  </TableRow>
                ))
              }
            </Table>
          </Content>
        </Fragment>
      )
    }
  </Modal>
);

HotswapModal.propTypes = {
  loading: PropTypes.bool,
  visible: PropTypes.bool,
  vehicles: PropTypes.array,
  rental: PropTypes.object,
  search: PropTypes.string,
  onSearch: PropTypes.func,
  onHotswap: PropTypes.func,
  onClose: PropTypes.func,
};

export default HotswapModal;
