import isObject from 'is-object';

export default ({
  object = {},
  attributes = {},
  convert = [],
  overrideValue = {},
} = {}) =>
  convert.reduce((combined, attribute) => {
    const attributeKey = isObject(attribute)
      ? !!attribute.key && !!attribute.fn
        ? attribute.key
        : null
      : attribute;
    if (!attributeKey || !attributes[attributeKey]) return combined;
    const value = isObject(attribute)
      ? attribute.fn(object)
      : object[attributeKey];
    return {
      ...combined,
      [attributeKey]: attributes[attributeKey](
        overrideValue[attributeKey] ||
          (value === null || value === undefined
            ? ''
            : typeof value === 'boolean'
            ? value
            : `${value}`)
      ),
    };
  }, {});
