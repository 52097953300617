import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getUnixTime} from 'date-fns';

// Actions
import {set as setAct} from '../VehicleInfoContainer/redux/actions';
import {show as showVehicleHoldModalAct} from '../../../vehicleHold/containers/PutVehicleOnHoldContainer/redux/actions';
import {show as showArchiveModalAct} from '../ArchiveVehicleContainer/redux/actions';
import {show as showUnarchiveModalAct} from '../EnableVehicleContainer/redux/actions';
import {show as showDecommissionModalAct} from '../DecommissionVehicleContainer/redux/actions';
import {show as showRevertDecommissionModalAct} from '../RevertDecommissionVehicleContainer/redux/actions';

// Components
import VehicleHeaderButtonSeparator from '../../components/VehicleHeaderButtonSeparator/VehicleHeaderButtonSeparator';

// Lib
import isOnHold from '../../../vehicleHold/lib/isOnHold.lib.vehicleHold';
import canBeArchived from '../../libs/canBeArchived.lib.vehicle';
import canBeEnabled from '../../libs/canBeEnabled.lib.vehicle';
import canBeDecommissioned from '../../libs/canBeDecommissioned.lib.vehicle';
import canRevertDecommission from '../../libs/canRevertDecommission.lib.vehicle';

// Permissions
import updatePermission from '../../permissions/update.permission.vehicle';
import archivePermission from '../../permissions/archive.permission.vehicle';
import decommissionPermission from '../../permissions/decommission.permission.vehicle';

// vehicleAlert lib
import showVehicleAlertCreateModal from '../../../vehicleAlert/lib/showCreateModal.lib.vehicleAlert';

class VehicleInfoSaveContainer extends Component {
  static propTypes = {
    saving: PropTypes.bool,
    vehicle: PropTypes.object,
    hold: PropTypes.object,
    dispatch: PropTypes.func,
  };

  save = () => this.props.dispatch(setAct({save: getUnixTime(new Date())}));

  hold = () => {
    const {vehicle, dispatch} = this.props;
    dispatch(showVehicleHoldModalAct(vehicle));
  };

  archive = () => {
    const {dispatch, vehicle} = this.props;
    dispatch(showArchiveModalAct(vehicle));
  };

  enable = () => {
    const {dispatch, vehicle} = this.props;
    dispatch(showUnarchiveModalAct(vehicle));
  };

  decommission = () => {
    const {dispatch, vehicle} = this.props;
    dispatch(showDecommissionModalAct(vehicle));
  };

  revertDecommission = () => {
    const {dispatch, vehicle} = this.props;
    dispatch(showRevertDecommissionModalAct(vehicle));
  };

  showCreateAlertModal = () => {
    const {vehicle} = this.props;
    showVehicleAlertCreateModal({vehicle});
  };

  render() {
    const {saving, hold, vehicle} = this.props;
    return (
      <VehicleHeaderButtonSeparator
        canBePutOnHold={!isOnHold(hold)}
        saving={saving}
        hasSavePermission={updatePermission()}
        canBeArchived={canBeArchived(vehicle) && archivePermission()}
        canBeEnabled={canBeEnabled(vehicle) && archivePermission()}
        canBeDecommissioned={
          canBeDecommissioned(vehicle) && decommissionPermission()
        }
        canRevertDecommission={
          canRevertDecommission(vehicle) && decommissionPermission()
        }
        onSave={this.save}
        onHold={this.hold}
        onArchive={this.archive}
        onEnable={this.enable}
        onDecommission={this.decommission}
        onRevertDecommission={this.revertDecommission}
        onCreateVehicleAlert={this.showCreateAlertModal}
      />
    );
  }
}

export default connect((state) => ({
  saving: state.vehicleInfo.saving,
  vehicle: state.vehicle.vehicle,
  hold: state.vehicle.hold,
}))(VehicleInfoSaveContainer);
