import styled from 'styled-components';

// Libs
import {lib} from '@matthahn/sally-ui';

// Colors
const {
  colors: {orange},
} = lib;

const Container = styled.div`
  width: 100%;
  height: 300px;
  font-size: 50px;
  color: ${orange};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default Container;
