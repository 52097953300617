export default [
  'congestion',
  'imp',
  'mta',
  'health',
  'ehail',
  'service_fee',
  'airport_access',
  'advance',
  'gas',
  'cash_rounding',
  'ezpass',
  'ticket',
  'rent',
  'repair',
  'cash_rounding',
];
