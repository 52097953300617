import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// components
import {Heading, Row, Column} from '@matthahn/sally-ui';
// import MedallionsCard from '../MedallionsCard/MedallionsCard';

// containers
import AddressContainer from '../../containers/AddressContainer/AddressContainer';
import BasicInfoContainer from '../../containers/BasicInfoContainer/BasicInfoContainer';
import ContactContainer from '../../containers/ContactContainer/ContactContainer';
import NotesContainer from '../../containers/NotesContainer/NotesContainer';
import Poc1Container from '../../containers/Poc1Container/Poc1Container';
import Poc2Container from '../../containers/Poc2Container/Poc2Container';

// medallion components
import MedallionContactCard from '../../../medallion/components/MedallionContactCard/MedallionContactCard';

const MedallionOwner = ({medallionOwner, medallions, onMedallion}) => (
  <Fragment>
    <Row margin>
      <Column>
        <Heading>{!!medallionOwner ? medallionOwner.name : ''}</Heading>
      </Column>
    </Row>
    <Row margin>
      <Column size={1 / 2}>
        <BasicInfoContainer />
      </Column>
      <Column size={1 / 2}>
        <NotesContainer />
      </Column>
    </Row>
    <Row margin>
      <Column size={1 / 2}>
        <AddressContainer />
      </Column>
      <Column size={1 / 2}>
        <ContactContainer />
      </Column>
    </Row>
    <Row margin>
      <Column size={1 / 2}>
        <Poc1Container />
      </Column>
      <Column size={1 / 2}>
        <Poc2Container />
      </Column>
    </Row>
    <Row>
      {medallions.map((medallion) => (
        <Column size={1 / 2} key={medallion.id} margin>
          <MedallionContactCard
            medallion={medallion}
            onClick={onMedallion(medallion)}
          />
        </Column>
      ))}
    </Row>
    {/* <Row margin>
      <Column size={1 / 2}>
        <MedallionsCard medallions={medallions} onMedallion={onMedallion} />
      </Column>
    </Row> */}
  </Fragment>
);

MedallionOwner.propTypes = {
  medallionOwner: PropTypes.object,
  medallions: PropTypes.array,
  onMedallion: PropTypes.func,
};

export default MedallionOwner;
