import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Icon, Row, Column, Button, Text} from '@matthahn/sally-ui';
import Connect from './components/Connect';
import Connected from './components/Connected';
import Center from './components/Center';
import Medallion from './components/Medallion';

// Types
import {date} from '../../../types';

const VehicleMedallionCard = ({
  loading,
  canDisconnect,
  display,
  vehicle,
  onConnect,
  onDisconnect,
}) =>
  !display ? (
    <Connect>No medallion can be connected with this vehicle</Connect>
  ) : !vehicle.medallion ? (
    <Connect onClick={onConnect}>
      <Icon icon="staro" /> Connect vehicle with a medallion
    </Connect>
  ) : (
    <Connected>
      <Row>
        <Column size={5 / 12}>
          <Center>
            <Medallion>{vehicle.medallion.medallion_number}</Medallion>
          </Center>
        </Column>
        <Column size={5 / 12}>
          <Text value={date(vehicle.medallion.medallion_expiry).format()}>
            Expiry
          </Text>
        </Column>
        {canDisconnect && (
          <Column size={2 / 12}>
            <Center>
              <Button
                size="small"
                theme="grey"
                loading={loading}
                icon="close"
                onClick={onDisconnect}
              />
            </Center>
          </Column>
        )}
      </Row>
    </Connected>
  );

VehicleMedallionCard.propTypes = {
  loading: PropTypes.bool,
  canDisconnect: PropTypes.bool,
  display: PropTypes.bool,
  vehicle: PropTypes.object,
  onConnect: PropTypes.func,
  onDisconnect: PropTypes.func,
};

export default VehicleMedallionCard;
