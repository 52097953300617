import styled from 'styled-components';

// colors
import * as colors from '@matthahn/sally-ui/lib/libs/colors';

const Container = styled.div`
  width: 18px;
  min-width: 18px;
  max-width: 18px;
  height: 18px;
  min-height: 18px;
  max-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 700;
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
  border-color: ${({color}) => colors[color]};
  color: ${({color}) => colors[color]};

  & i {
    transform: translateY(-1px);
  }
`;

export default Container;
