import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// accident actions
import {set as setAct} from '../../redux/actions';

// accident apis
import fileClaimApi from '../../../accident/api/fileClaim.api.accident';
import createNoClaimReasonApi from '../../../accident/api/createNoClaimReason.api.accident';
import deleteNoClaimReasonApi from '../../../accident/api/deleteNoClaimReason.api.accident';

// accident components
import AccidentClaimCard from '../../components/AccidentClaimCard/AccidentClaimCard';
import NoClaimModal from '../../components/NoClaimModal/NoClaimModal';

// components
import {Button} from '@matthahn/sally-ui';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// redux
import {connect} from 'react-redux';

class AccidentClaimContainer extends Component {
  static propTypes = {
    accident: PropTypes.object,
    dispatch: PropTypes.func,
    userId: PropTypes.number,
  };

  state = {
    visible: false,
    noClaimVisible: false,
    claiming: false,
    text: '',
  };

  showModal = () => {
    if (this.state.claiming) return;
    this.setState({visible: true});
  };

  hideModal = () => {
    if (this.state.claiming) return;
    this.setState({visible: false});
  };

  showNoClaimModal = () => {
    if (this.state.claiming) return;
    this.setState({noClaimVisible: true, visible: false, text: ''});
  };

  hideNoClaimModal = () => {
    if (this.state.claiming) return;
    this.setState({noClaimVisible: false});
  };

  renderActions = () => {
    const {accident} = this.props;
    return !!accident?.noclaim_reason ? (
      <Button theme="grey" size="small" onClick={this.showModal}>
        Not required
      </Button>
    ) : accident?.accidentstate?.claim_state === 'claim_filed' ? (
      <Button theme="green" size="small" icon="check">
        Filed
      </Button>
    ) : (
      <Button theme="grey" size="small" onClick={this.showModal}>
        File claim
      </Button>
    );
  };

  claim = async () => {
    const {accident, dispatch} = this.props;
    const {claiming} = this.state;

    if (claiming) return;

    this.setState({claiming: true});

    try {
      const updatedAccident = await fileClaimApi(accident.id);
      dispatch(setAct({accident: updatedAccident}));
      this.setState({claiming: false, visible: false});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({claiming: false});
    }
  };

  noClaim = async () => {
    const {accident, userId, dispatch} = this.props;
    const {claiming, text} = this.state;

    if (claiming) return;
    if (!text.trim().length) return alert.info('Insert reason');

    this.setState({claiming: true});

    try {
      const noclaim_reason = await createNoClaimReasonApi({
        text,
        dol: accident.id,
        owner: userId,
      });
      const updatedAccident = {...accident, noclaim_reason};
      dispatch(setAct({accident: updatedAccident}));
      this.setState({claiming: false, noClaimVisible: false});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({claiming: false});
    }
  };

  deleteNoClaim = async () => {
    const {accident, dispatch} = this.props;
    const {claiming} = this.state;

    if (claiming || !accident?.noclaim_reason) return;

    this.setState({claiming: true});

    try {
      await deleteNoClaimReasonApi(accident?.noclaim_reason?.id);
      const updatedAccident = {...accident, noclaim_reason: null};
      dispatch(setAct({accident: updatedAccident}));
      this.setState({claiming: false, visible: false});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({claiming: false});
    }
  };

  change = (text) => {
    if (this.state.claiming) return;
    this.setState({text});
  };

  render() {
    const {accident} = this.props;
    const {visible, noClaimVisible, claiming, text} = this.state;
    return (
      <Fragment>
        {this.renderActions()}
        <AccidentClaimCard
          accident={accident}
          claiming={claiming}
          visible={visible}
          onClaim={this.claim}
          onClose={this.hideModal}
          onNoClaim={this.showNoClaimModal}
          onDeleteNoClaim={this.deleteNoClaim}
        />
        <NoClaimModal
          loading={claiming}
          onChange={this.change}
          onClose={this.hideNoClaimModal}
          onSave={this.noClaim}
          text={text}
          visible={noClaimVisible}
        />
      </Fragment>
    );
  }
}

export default connect((state) => ({
  accident: state.accident.accident,
  userId: state.auth.userID,
}))(AccidentClaimContainer);
