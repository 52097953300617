import styled from 'styled-components';

// Colors
import {lib} from '@matthahn/sally-ui';
const {
  colors: {darkGrey},
} = lib;

const Name = styled.div`
  width: 100%;
  flex: 1;
  color: ${darkGrey};
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export default Name;
