import {SELECT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {string} from '../../types';

export default attribute({
  type: SELECT,
  attribute: 'insurance_carrier',
  display: string,
  input: string,
  api: string,
  label: {
    default: 'Insurance carrier',
    short: 'Insurance carrier',
  },
  options: ['Hereford', 'The Hartford', 'Crum and Forster', 'CHUBB'],
});
