import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/fileDownload.profile.api';

export default (driverId, {start_date, end_date, transaction_types, summary}) =>
  f3tch(`/drivers/${driverId}/payment_statement/`)
    .profile(profile({contentType: 'application/json'}))
    .body(JSON.stringify({start_date, end_date, transaction_types, summary}))
    .post();
