import React from 'react';
import PropTypes from 'prop-types';

// components
import {Card, Column, Row, Table} from '@matthahn/sally-ui';

// layout components
import SubPage from '../../../layout/components/SubPage/SubPage';

// local data
import columns from './columns';

const DriverEventsCard = ({events, loading}) => (
  <SubPage>
    <Row>
      <Column size={1 / 2} offset={1 / 4}>
        <Card title="Events log" icon="calendar">
          {(Content) => (
            <Content padding="none">
              <Table
                columns={columns}
                loading={loading}
                headless
                infinite
                noBorder
                noRadius
                smallNoResultsLabel
              >
                {(TableRow) =>
                  events.map((event, index) => (
                    <TableRow key={`${index}${event}`}>
                      {(TableCol) => [<TableCol key="event">{event}</TableCol>]}
                    </TableRow>
                  ))
                }
              </Table>
            </Content>
          )}
        </Card>
      </Column>
    </Row>
  </SubPage>
);

DriverEventsCard.propTypes = {
  loading: PropTypes.bool,
  events: PropTypes.array,
};

export default DriverEventsCard;
