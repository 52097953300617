export default [
  {
    key: 'bank_name',
    label: 'Bank',
  },
  {
    key: 'date',
    label: 'Date Added',
  },
  {
    key: 'verify',
    label: 'Verify',
  },
];
