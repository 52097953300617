import styled from 'styled-components';

const Date = styled.div`
  width: 100%;
  font-size: 12px;
  margin-top: 2px;
  font-weight: 600;
`;

export default Date;
