// date
import {parseISO, isDate, isAfter, addWeeks} from 'date-fns';

const isPastDue = ({now = new Date(), dateCreated = new Date()}) =>
  !now ||
  !dateCreated ||
  isAfter(
    isDate(now) ? now : parseISO(now),
    addWeeks(isDate(dateCreated) ? dateCreated : parseISO(dateCreated), 2)
  );

export default isPastDue;
