import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

export default async (driver, amount, jwt = null) => {
  const api = f3tch(`/payments/take_deposit/`).profile(profile());

  if (!!jwt) api.headers({Authorization: `JWT ${jwt}`});

  const response = await api
    .body({driver_id: driver.id, amount: amount})
    .post();

  return response;
};
