import React from 'react';
import PropTypes from 'prop-types';

// components
import {Modal} from '@matthahn/sally-ui';

const PayoutWarningMessage = ({
  loading,
  message,
  onClose,
  onProceed,
  visible,
}) => (
  <Modal
    visible={visible}
    title="Warning"
    onClose={onClose}
    buttonsRight={[
      {loading, label: 'Proceed', onClick: onProceed, theme: 'yellow'},
    ]}
  >
    {(Content) => <Content>{message}</Content>}
  </Modal>
);

PayoutWarningMessage.propTypes = {
  loading: PropTypes.bool,
  message: PropTypes.node,
  onClose: PropTypes.func,
  onProceed: PropTypes.func,
  visible: PropTypes.bool,
};

export default PayoutWarningMessage;
