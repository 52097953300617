import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

// Events
import event from '../events/approved.event.ach';

export default async (achs, jwt = null) => {
  const api = f3tch('/payments/approve_ach/').profile(profile());

  if (!!jwt) api.headers({Authorization: `JWT ${jwt}`});

  const response = await api.body(achs).post();
  event.pub(response);
  return response;
};
