import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {hide as hideAct} from './redux/actions';

// Api
import uploadDocumentApi from '../../../document/api/upload.api.document';

// Components
import DriverFhvCaptureModal from '../../components/DriverFhvCaptureModal/DriverFhvCaptureModal';

// Document
import fhvFile from '../../documents/folders/fhv.document.driver';

// Lib
import {lib} from '@matthahn/sally-ui';
import parseError from '../../../error/parseError';
import isProduction from '../../../app/lib/isProduction.lib.app';
import dataUriToFile from '../../../layout/lib/dataUriToFile.lib.layout';

// Alert
const {alert} = lib;

class DriverFhvCaptureContainer extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    driver: PropTypes.object,
    image: PropTypes.string,
  };

  static STATE = {
    loading: false,
    attributes: [],
    data: {},
  };

  state = {...this.constructor.STATE};

  componentDidMount() {
    if (this.props.visible) this.init();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) this.init();
  }

  init = () => {
    const {driver} = this.props;
    const data = fhvFile.initAttributes({driver});
    const attributes = Object.keys(data);
    this.setState({...this.constructor.STATE, data, attributes});
  };

  onClose = () => {
    const {dispatch} = this.props;
    const {loading} = this.state;
    if (loading) return;
    dispatch(hideAct());
  };

  onChange = (val, key) => {
    const {loading, data} = this.state;
    if (loading) return;
    this.setState({data: {...data, [key]: val}});
  };

  save = async () => {
    const {dispatch, visible, image, driver} = this.props;
    const {loading, data} = this.state;

    if (loading || !visible || !driver || !image) return;

    this.setState({loading: true});

    try {
      await uploadDocumentApi({
        data,
        doc: dataUriToFile(image, 'fhv.jpg'),
        file: fhvFile,
        driver: driver,
        type: 'driver',
      });
      alert.success('Driver FHV Uploaded');
      dispatch(hideAct());
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
    }

    this.setState({loading: false});
  };

  render() {
    const {visible, image} = this.props;
    const {loading, attributes, data} = this.state;
    return (
      <DriverFhvCaptureModal
        isProduction={isProduction()}
        visible={visible}
        loading={loading}
        image={image}
        attributes={attributes}
        data={data}
        onClose={this.onClose}
        onChange={this.onChange}
        onSave={this.save}
      />
    );
  }
}

export default connect((state) => ({...state.driverFhvCapture}))(
  DriverFhvCaptureContainer
);
