import {SELECT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {string} from '../../types';

export default attribute({
  type: SELECT,
  attribute: 'payment_method',
  api: string,
  label: {
    default: 'Payment Method',
    short: 'Payment Method',
  },
  options: ['CHECK', 'ACH', 'WIRE'],
});
