import React from 'react';

// Components
import {ScreenLoader} from '@matthahn/sally-ui';
import FullScreen from '../../../layout/components/FullScreen/FullScreen';

const MedallionLoader = () => (
  <FullScreen>
    <ScreenLoader theme="orange">Loading Medallion</ScreenLoader>
  </FullScreen>
);

export default MedallionLoader;
