import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/noData.profile.api';

// Events
import event from '../events/deleted.event.accidentNote';

export default async (accidentID, noteID) => {
  const response = await f3tch(`/accidents/dol/${accidentID}/notes/${noteID}/`)
    .profile(profile())
    .delete();
  event.pub(noteID);
  return response;
};
