import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

export default attribute({
  type: TEXT,
  attribute: 'insurance_liability_number',
  label: {
    default: 'Insurance Liability Number',
    short: 'Insurance Liability',
  },
});
