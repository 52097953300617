// Constants
import {SET} from './constants';
import DRIVER_STORAGE_KEY from '../../driver/constants/spotlightStorageKey.constants.driver';
import MEDALLION_STORAGE_KEY from '../../medallion/constants/spotlightStorageKey.constants.medallion';
import VEHICLE_STORAGE_KEY from '../../vehicle/constants/spotlightStorageKey.constants.vehicle';

// Localstorage
import * as storage from '../../lib/localStorage';

// Sync
import syncObjectByModificationDate from '../../sync/lib/syncObjectByModificationDate';

export const set = (data = {}) => ({
  type: SET,
  data,
});

export const show = () => set({visible: true});

export const hide = () => set({visible: false});

const syncDriverStorage = (drivers) =>
  storage.add(DRIVER_STORAGE_KEY, JSON.stringify(drivers));

export const syncDrivers = (newData) => (dispatch, getState) => {
  const oldData = getState().spotlight.drivers;
  const list = syncObjectByModificationDate(oldData, newData);
  syncDriverStorage(list);
  dispatch({
    type: SET,
    data: {drivers: list},
  });
};

export const removeDriver = (id) => (dispatch, getState) => {
  const oldList = getState().spotlight.drivers;
  const list = [...oldList].filter((obj) => obj.id !== id);
  syncDriverStorage(list);
  dispatch({type: SET, data: {drivers: list}});
};

export const removeUnusedDrivers = (newList) => (dispatch, getState) => {
  const combinedList = getState().spotlight.drivers;
  const list = [...combinedList].filter(
    (obj) => !!newList.find(({id}) => obj.id === id)
  );
  syncDriverStorage(list);
  dispatch({type: SET, data: {drivers: list}});
};

const syncMedallionStorage = (medallions) =>
  storage.add(MEDALLION_STORAGE_KEY, JSON.stringify(medallions));

export const syncMedallions = (newData) => (dispatch, getState) => {
  const oldData = getState().spotlight.medallions;
  const list = syncObjectByModificationDate(oldData, newData);
  syncMedallionStorage(list);
  dispatch({
    type: SET,
    data: {medallions: list},
  });
};

export const removeMedallion = (id) => (dispatch, getState) => {
  const oldList = getState().spotlight.medallions;
  const list = [...oldList].filter((obj) => obj.id !== id);
  syncMedallionStorage(list);
  dispatch({type: SET, data: {medallions: list}});
};

export const removeUnusedMedallions = (newList) => (dispatch, getState) => {
  const combinedList = getState().spotlight.medallions;
  const list = [...combinedList].filter(
    (obj) => !!newList.find(({id}) => obj.id === id)
  );
  syncMedallionStorage(list);
  dispatch({type: SET, data: {medallions: list}});
};

const syncVehicleStorage = (vehicles) =>
  storage.add(VEHICLE_STORAGE_KEY, JSON.stringify(vehicles));

export const syncVehicles = (newData) => (dispatch, getState) => {
  const oldData = getState().spotlight.vehicles;
  const list = syncObjectByModificationDate(oldData, newData);
  syncVehicleStorage(list);
  dispatch({
    type: SET,
    data: {vehicles: list},
  });
};

export const removeVehicle = (id) => (dispatch, getState) => {
  const oldList = getState().spotlight.vehicles;
  const list = [...oldList].filter((obj) => obj.id !== id);
  syncVehicleStorage(list);
  dispatch({type: SET, data: {vehicles: list}});
};

export const removeUnusedVehicles = (newList) => (dispatch, getState) => {
  const combinedList = getState().spotlight.vehicles;
  const list = [...combinedList].filter(
    (obj) => !!newList.find(({id}) => obj.id === id)
  );
  syncVehicleStorage(list);
  dispatch({type: SET, data: {vehicles: list}});
};
