import React from 'react';

// Components
import {Icon} from '@matthahn/sally-ui';
import Container from './components/Container';

const PageLoader = () => (
  <Container>
    <Icon icon="loading2" spin />
  </Container>
);

export default PageLoader;
