import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal, FrameDoc, Info, Select} from '@matthahn/sally-ui';
import SmallLoader from '../../../layout/components/SmallLoader/SmallLoader';
import Wrapper from './components/Wrapper';
import SelectContainer from './components/SelectContainer';

// Containers
import DocumentsContainer from '../../containers/DocumentsContainer/DocumentsContainer';
import GoogleDocContainer from '../../containers/GoogleDocContainer/GoogleDocContainer';

const Contract = ({
  init,
  loading,
  externalDevicesVisible,
  visible,
  view,
  file,
  duplicates,
  folder,
  data,
  driver,
  contract,
  contracts,
  noOptions,
  onFileUploadDone,
  onView,
  onLoad,
  onClose,
  onExec,
  onError,
  onContract,
  undoVersion,
}) => (
  <Modal
    visible={visible && !externalDevicesVisible}
    title="Lease Contract"
    onClose={onClose}
    size="large"
    headerActions={
      noOptions
        ? []
        : [
            view !== 'preview' &&
              !!file && {
                icon: 'eye',
                tooltip: 'Preview',
                onClick: () => onView('preview'),
              },
            view !== 'upload' && {
              icon: 'upload',
              tooltip: 'Upload',
              onClick: () => onView('upload'),
            },

            !(view === 'generate' || (view === 'preview' && !file)) && {
              icon: 'edit',
              tooltip: 'Generate',
              onClick: () => onView('generate'),
            },
            !!contract && {
              icon: 'back',
              tooltip: 'Select Version',
              onClick: undoVersion,
            },
          ].filter((a) => !!a)
    }
  >
    {(Content) =>
      !init || (loading && !file && view === 'preview') ? (
        <Content>
          <SmallLoader />
        </Content>
      ) : (
        <div>
          {view === 'preview' && !!file && (
            <Content padding="none">
              <Info type="warning" noIcon flat>
                This is an already generated contract.
              </Info>
              <Wrapper>
                <FrameDoc file={file} fullscreenable />
              </Wrapper>
            </Content>
          )}
          {view === 'upload' && (
            <Content padding="none">
              {!!file && (
                <Info type="warning" noIcon flat>
                  A contract already exists
                </Info>
              )}
              <Wrapper>
                <DocumentsContainer
                  type="driver"
                  folderType={folder.type}
                  driver={driver}
                  duplicates={duplicates}
                  onDone={onFileUploadDone}
                  onLoad={onLoad}
                  clearFileOnBack
                  uploadMode
                  staticSelection
                  small
                />
              </Wrapper>
            </Content>
          )}
          {visible && (view === 'generate' || (view === 'preview' && !file)) && (
            <Content padding="none">
              {!!contract ? (
                <Fragment>
                  {!!file && (
                    <Info type="warning" noIcon flat>
                      A contract already exists
                    </Info>
                  )}
                  <GoogleDocContainer
                    loading={loading}
                    html={contract}
                    data={data}
                    required={['signature', 'smallSignature']}
                    onExec={onExec}
                    onError={onError}
                  />
                </Fragment>
              ) : (
                <Fragment>
                  {!!file && (
                    <Info type="warning" noIcon flat>
                      A contract already exists
                    </Info>
                  )}
                  <SelectContainer>
                    <Select
                      value={null}
                      options={[...contracts].map((c) => ({
                        value: c.version,
                        label: !!c.name
                          ? c.name
                          : `${c.version_major}.${c.version_minor}.${c.version_revision}`,
                      }))}
                      placeholder="Select contract version"
                      onChange={onContract}
                      disabled={loading}
                      dropdownOptions={{size: 400}}
                    >
                      Contract Version
                    </Select>
                  </SelectContainer>
                </Fragment>
              )}
            </Content>
          )}
        </div>
      )
    }
  </Modal>
);

Contract.propTypes = {
  init: PropTypes.bool,
  loading: PropTypes.bool,
  externalDevicesVisible: PropTypes.bool,
  visible: PropTypes.bool,
  view: PropTypes.string,
  file: PropTypes.object,
  duplicates: PropTypes.array,
  folder: PropTypes.object,
  data: PropTypes.object,
  rental: PropTypes.object,
  contract: PropTypes.object,
  contracts: PropTypes.array,
  noOptions: PropTypes.bool,
  onFileUploadDone: PropTypes.func,
  onView: PropTypes.func,
  onLoad: PropTypes.func,
  onClose: PropTypes.func,
  onExec: PropTypes.func,
  onError: PropTypes.func,
  onContract: PropTypes.func,
  undoVersion: PropTypes.func,
};

export default Contract;
