import {SET} from './constants';

export const set = (data = {}) => ({
  type: SET,
  data,
});

export const show = (rentalReport = null) => set({visible: true, rentalReport});

export const hide = () => set({visible: false});
