import React from 'react';
import PropTypes from 'prop-types';

// components
import {Input} from '@matthahn/sally-ui';

const StringPermission = ({label, onChange, value}) => (
  <Input value={value} onChange={onChange}>
    {label}
  </Input>
);

StringPermission.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default StringPermission;
