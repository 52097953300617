import React from 'react';
import PropTypes from 'prop-types';

// alert components
import AlertsWrapper from '../../../alert/components/AlertsWrapper/AlertsWrapper';

// alert containers
import AlertsContainer from '../../../alert/containers/AlertsContainer/AlertsContainer';

// components
import {Info} from '@matthahn/sally-ui';

// contract events
import showChangeOrderActionsEvent from '../../../contract/events/showActions.event.changeOrder';

// driverAlert api
import listDriverAlertsApi from '../../../driverAlert/api/list.api.driverAlert';
import updateDriverAlertApi from '../../../driverAlert/api/update.api.driverAlert';

// local components
import Date from './components/Date';

// types
import dateTimeType from '@matthahn/sally-fw/lib/type/types/dateTime.type';

// vehicleAlert api
import listVehicleAlertsApi from '../../../vehicleAlert/api/list.api.vehicleAlert';
import updateVehicleAlertApi from '../../../vehicleAlert/api/update.api.vehicleAlert';

const DispatchAlerts = ({
  csr,
  displayCsr,
  driver,
  driverAlertFilter,
  onCloseModal,
  vehicle,
  vehicleAlertFilter,
}) => {
  return (
    <AlertsWrapper>
      {displayCsr && !!csr && (
        <Info
          type="error"
          action={{
            onClick: () => {
              onCloseModal();
              showChangeOrderActionsEvent.publish({csr});
            },
            label: 'Sign',
          }}
          flat
        >
          Contract signature required
        </Info>
      )}
      {!!driver && (
        <AlertsContainer
          object={driver}
          objectType="driver"
          listApi={listDriverAlertsApi}
          updateApi={updateDriverAlertApi}
          filterAlerts={driverAlertFilter}
        >
          {({alerts}) =>
            [...alerts].map((alert) => (
              <Info {...alert.infoProps} icon="user">
                {alert.message}
                <Date>{dateTimeType(alert.created_at).format()}</Date>
              </Info>
            ))
          }
        </AlertsContainer>
      )}
      {!!vehicle && (
        <AlertsContainer
          object={vehicle}
          objectType="vehicle"
          listApi={listVehicleAlertsApi}
          updateApi={updateVehicleAlertApi}
          filterAlerts={vehicleAlertFilter}
        >
          {({alerts}) =>
            [...alerts].map((alert) => (
              <Info {...alert.infoProps} icon="car">
                {alert.message}
                <Date>{dateTimeType(alert.created_at).format()}</Date>
              </Info>
            ))
          }
        </AlertsContainer>
      )}
    </AlertsWrapper>
  );
};

DispatchAlerts.propTypes = {
  csr: PropTypes.object,
  displayCsr: PropTypes.bool,
  driver: PropTypes.object,
  driverAlertFilter: PropTypes.func,
  oncloseModal: PropTypes.func,
  vehicle: PropTypes.object,
  vehicleAlertFilter: PropTypes.func,
};

export default DispatchAlerts;
