import {TOGGLE} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

export default attribute({
  type: TOGGLE,
  attribute: 'dov',
  label: {
    default: 'Driver Owned Vehicle',
    short: 'DOV',
  },
});
