// components
import Column from '@matthahn/sally-ui/lib/components/Grid/Column/Column';
import Modal from '@matthahn/sally-ui/lib/components/Modal/Modal';
import Row from '@matthahn/sally-ui/lib/components/Grid/Row/Row';
import Table from '@matthahn/sally-ui/lib/components/Table/Table';

// layout components
import AttributeFormatter from '../../../layout/components/AttributeFormatter/AttributeFormatter';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';
import ModalLoader from '../../../layout/components/ModalLoader/ModalLoader';

// local constants
import columns from './constants/columns.constant';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Fragment} from 'react';

// vehicle attributes
import vin from '../../../vehicle/attributes/vin.attribute.vehicle';
import vehicle_year from '../../../vehicle/attributes/vehicle_year.attribute.vehicle';
import vehicle_model from '../../../vehicle/attributes/vehicle_model.attribute.vehicle';
import plate from '../../../vehicle/attributes/plate.attribute.vehicle';

// vehicle components
import VehicleTags from '../../../vehicle/components/VehicleTags/VehicleTags';

const InsuranceEditModal = ({
  accident_phone_number,
  deleting,
  insurance_company_name,
  insurance_company_number,
  loading,
  onAddVehicles,
  onChange,
  onClose,
  onDelete,
  onSave,
  policy_effective_date,
  policy_expiration_date,
  policy_number,
  saving,
  subtitle = null,
  title = 'Insurance',
  vehicles,
  visible,
}) => (
  <Modal
    visible={visible}
    title={title}
    subtitle={subtitle}
    onClose={onClose}
    buttonsRight={[
      {
        label: 'Save',
        theme: 'orange',
        onClick: onSave,
        loading: loading || saving,
        disabled: deleting,
      },
    ]}
    buttonsLeft={
      !!onDelete
        ? [
            {
              label: 'Delete',
              onClick: onDelete,
              disabled: loading || saving,
              loading: deleting,
            },
          ]
        : []
    }
    disableOutsideClickClose
  >
    {(Content) =>
      loading ? (
        <Content padding="none">
          <ModalLoader />
        </Content>
      ) : (
        <Fragment>
          <Content>
            <Row margin>
              <Column size={1 / 2}>
                <AttributeInput
                  value={policy_number}
                  disabled={deleting || loading || saving}
                  onChange={onChange}
                >
                  {policy_number.label.default}
                </AttributeInput>
              </Column>
              <Column size={1 / 2}>
                <AttributeInput
                  value={accident_phone_number}
                  disabled={deleting || loading || saving}
                  onChange={onChange}
                >
                  {accident_phone_number.label.default}
                </AttributeInput>
              </Column>
            </Row>
            <Row margin>
              <Column size={1 / 2}>
                <AttributeInput
                  value={insurance_company_name}
                  disabled={deleting || loading || saving}
                  onChange={onChange}
                >
                  {insurance_company_name.label.default}
                </AttributeInput>
              </Column>
              <Column size={1 / 2}>
                <AttributeInput
                  value={insurance_company_number}
                  disabled={deleting || loading || saving}
                  onChange={onChange}
                >
                  {insurance_company_number.label.default}
                </AttributeInput>
              </Column>
            </Row>
            <Row>
              <Column size={1 / 2}>
                <AttributeInput
                  value={policy_effective_date}
                  disabled={deleting || loading || saving}
                  onChange={onChange}
                >
                  {policy_effective_date.label.default}
                </AttributeInput>
              </Column>
              <Column size={1 / 2}>
                <AttributeInput
                  value={policy_expiration_date}
                  disabled={deleting || loading || saving}
                  onChange={onChange}
                >
                  {policy_expiration_date.label.default}
                </AttributeInput>
              </Column>
            </Row>
          </Content>
          {!!onAddVehicles && (
            <Content padding="none">
              <Table
                columns={columns}
                infinite={false}
                theme="orange"
                noBorder
                noRadius
                smallNoResultsLabel
              >
                {(TableRow) => [
                  ...vehicles.map((vehicle) => (
                    <TableRow key={vehicle.id}>
                      {(TableCol) =>
                        [
                          <TableCol key="vin" uppercase bold>
                            <VehicleTags vehicle={vehicle} display="vin">
                              <AttributeFormatter
                                attribute={vin(vehicle.vin)}
                              />
                            </VehicleTags>
                          </TableCol>,
                          <TableCol key="medallion">
                            {!!vehicle.medallion &&
                            !!vehicle.medallion.medallion_number
                              ? vehicle.medallion.medallion_number
                              : '-'}
                          </TableCol>,
                          <TableCol key="year-model">
                            <AttributeFormatter
                              attribute={vehicle_year(vehicle.vehicle_year)}
                            />{' '}
                            <AttributeFormatter
                              attribute={vehicle_model(vehicle.vehicle_model)}
                            />
                          </TableCol>,
                          <TableCol key="plate">
                            <AttributeFormatter
                              attribute={plate(vehicle.plate)}
                            />
                          </TableCol>,
                        ].filter((col) => !!col)
                      }
                    </TableRow>
                  )),
                  <TableRow key="no-vehicles" onClick={onAddVehicles}>
                    {(TableCol) => (
                      <TableCol span={4} key="add-vehicle">
                        + Add Vehicles
                      </TableCol>
                    )}
                  </TableRow>,
                ]}
              </Table>
            </Content>
          )}
        </Fragment>
      )
    }
  </Modal>
);

InsuranceEditModal.propTypes = {
  accident_phone_number: PropTypes.object,
  deleting: PropTypes.bool,
  insurance_company_name: PropTypes.object,
  insurance_company_number: PropTypes.object,
  loading: PropTypes.bool,
  onAddVehicles: PropTypes.func,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
  policy_effective_date: PropTypes.object,
  policy_expiration_date: PropTypes.object,
  policy_number: PropTypes.object,
  saving: PropTypes.bool,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  vehicles: PropTypes.array,
  visible: PropTypes.bool,
};

export default InsuranceEditModal;
