import {fileName, folder, date} from '../../../document';

// Attributes
import registrationControlNumberAttr from '../../attributes/registration_control_number.attribute.vehicle';

// Errors
import registrationControlNumberMissmatch from '../../errors/registrationControlNumberMissmatch.error.vehicle';

export default folder({
  attributes: [registrationControlNumberAttr],
  defaultFileName: 'Registration Confirm',
  // REGI AFFIXED - VIN - DATE
  fileName: fileName(
    ({vehicle}) => `REGI AFFIXED - ${vehicle.vin} - ${date()}`
  ),
  folderName: 'Registration Confirmations',
  preSaveValidation: ({vehicle, registration_control_number}) => {
    if (
      vehicle.registration_control_number !==
      registration_control_number.api.format()
    )
      throw registrationControlNumberMissmatch({
        message: `Wrong document control number`,
      });
  },
  type: 'registrationConfirm',
  uploadable: false,
});
