import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

export default attribute({
  type: TEXT,
  attribute: 'bank_account_number',
  label: {
    default: 'Bank Account Number',
    short: 'Bank Account Number',
  },
});
