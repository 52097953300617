import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/noData.profile.api';

// Events
import event from '../events/updated.event.transaction';

export default async (transactionID, transaction) => {
  const response = await f3tch(`/payments/transactions/${transactionID}/`)
    .profile(profile())
    .body(transaction)
    .put();
  event.pub(response);
  return response;
};
