// Lib
import dueDateThisWeek from './dueDateThisWeek.lib.ticket';
import isAfterDueDate from './isAfterDueDate.lib.ticket';

// Statuses
import needsActionStatus from '../statuses/needs_action.status.ticket';
import sallyToPayStatus from '../statuses/sally_to_pay.status.ticket';
import waitingStatus from '../statuses/waiting.status.ticket';

export default (ticket) =>
  (isAfterDueDate(ticket) || dueDateThisWeek(ticket)) &&
  [needsActionStatus.key, sallyToPayStatus.key, waitingStatus.key].includes(
    ticket.status
  );
