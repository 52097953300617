import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {set as setAct} from '../../redux/actions';

// Api
import listAchsApi from '../../api/list.api.ach';

// Lib
import wait from '../../../lib/wait';

// Sockets
import achApprovedSocket from '../../sockets/approved.socket.ach';
import achRejectedSocket from '../../sockets/canceled.socket.ach';

// Query
import {isNull} from '../../../api/queries/queries';

class NumberOfPendingAchsSync extends Component {
  static propTypes = {
    numberOfPendingAchs: PropTypes.number,
    children: PropTypes.node,
    dispatch: PropTypes.func,
  };

  static defaultProps = {
    children: null,
  };

  componentDidMount() {
    this.subscribe();
    this.init();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  syncing = false;

  init = () => {
    this.getNumberOfAchs();
  };

  subscribe = () => {
    this.sockets = [
      achApprovedSocket.subscribe(this.achRemoved),
      achRejectedSocket.subscribe(this.achRemoved),
    ];
  };

  unsubscribe = () => {
    this.sockets.forEach((unsub) => unsub());
  };

  achAdded = () => {
    const {numberOfPendingAchs, dispatch} = this.props;
    dispatch(setAct({numberOfPendingAchs: numberOfPendingAchs + 1}));
  };

  achRemoved = () => {
    const {numberOfPendingAchs, dispatch} = this.props;
    dispatch(setAct({numberOfPendingAchs: numberOfPendingAchs - 1}));
  };

  getNumberOfAchs = async () => {
    const {dispatch} = this.props;
    this.syncing = true;

    try {
      const {count} = await listAchsApi({
        [isNull('approved_by_user')]: true,
        [isNull('canceled_on')]: true,
        removed: false,
        limit: 1,
        offset: 0,
        fields: 'id',
      });
      dispatch(setAct({numberOfPendingAchs: count}));
    } catch (error) {
      // DO NOTHING
    }

    this.syncing = false;
    await wait(60 * 1000);
    this.getNumberOfAchs();
  };

  render() {
    const {children} = this.props;
    return children || null;
  }
}

export default connect((state) => ({
  numberOfPendingAchs: state.ach.numberOfPendingAchs,
}))(NumberOfPendingAchsSync);
