import React, {Component} from 'react';
import PropTypes from 'prop-types';

// driver api
import listDriverEventsApi from '../../api/listEvents.api.driver';

// driver components
import DriverEventsCard from '../../components/DriverEventsCard/DriverEventsCard';

// redux
import {connect} from 'react-redux';

class DriverEventsContainer extends Component {
  static propTypes = {
    driver: PropTypes.object,
  };

  state = {
    loading: true,
    events: [],
  };

  componentDidMount() {
    this.mounted = true;
    this.getEvents();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getEvents = async () => {
    const {driver} = this.props;
    this.setState({loading: true});
    try {
      const events = await listDriverEventsApi(driver.id);
      if (!this.mounted) return;
      this.setState({loading: false, events});
    } catch (error) {
      if (!this.mounted) return;
      this.setState({loading: false, events: []});
    }
  };

  render() {
    const {loading, events} = this.state;
    return <DriverEventsCard events={events} loading={loading} />;
  }
}

export default connect((state) => ({driver: state.driver.driver}))(
  DriverEventsContainer
);
