export default [
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'phoneNumber',
    label: 'Phone Number',
  },
];
