import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/file.profile.api';

export default (documents) =>
  f3tch('/vehicles/insurance_upload/')
    .profile(profile())
    .body(documents)
    .post();
