import React from 'react';

// Components
import TwoTicketColumnLayout from '../../components/TwoTicketColumnLayout/TwoTicketColumnLayout';

// Containers
import Page from '../../../layout/containers/Page/Page';
import UnassignedTicketsContainer from '../../containers/UnassignedTicketsContainer/UnassignedTicketsContainer';
import NeedActionTicketsContainer from '../../containers/NeedActionTicketsContainer/NeedActionTicketsContainer';

// Page
import PAGE from './page';

const TicketsPage = () => (
  <Page page={PAGE}>
    <TwoTicketColumnLayout
      left={<UnassignedTicketsContainer />}
      right={<NeedActionTicketsContainer />}
    />
  </Page>
);

export default TicketsPage;
