import styled from 'styled-components';

// Libs
import {lib} from '@matthahn/sally-ui';

// Colors
const {
  colors: {white},
} = lib;

const Bottom = styled.div`
  width: 100%;
  height: 50px;
  background: ${white};
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
`;

export default Bottom;
