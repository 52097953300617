import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {Card, Info, Text} from '@matthahn/sally-ui';
import DocumentsContainer from '../../../document/containers/DocumentsContainer/DocumentsContainer';

import Container from './components/Container';

// Documents
import * as documents from '../../documents';

// Types
import {date} from '../../../types';

const VehicleStateInspectionCard = ({status, vehicle, onDone}) => (
  <Card
    title="State Inspection"
    icon="profile"
    info={(Content) => (
      <Fragment>
        <Content padding="none" noBorder>
          {status.status === 'done' ? (
            <Info type="plain" flat>
              State Inspection is up to date
            </Info>
          ) : (
            <Info type={status.status} flat>
              {status.message}
            </Info>
          )}
        </Content>
        <Content>
          <Text value={date(vehicle.emissions_expiry).format() || '-'}>
            Expiration
          </Text>
        </Content>
      </Fragment>
    )}
    visible={status.status !== 'done'}
    foldable
  >
    {(Content) => (
      <Content padding="none">
        <Container>
          <DocumentsContainer
            type="vehicle"
            vehicle={vehicle}
            folderType={documents.foldersObject.stateInspection.type}
            predefinedDocs={documents}
            onDone={onDone}
            uploadMode
            clearFileOnBack
            staticSelection
            small
          />
        </Container>
      </Content>
    )}
  </Card>
);

VehicleStateInspectionCard.propTypes = {
  section: PropTypes.object,
  isChicago: PropTypes.bool,
  vehicle: PropTypes.object,
  onDone: PropTypes.func,
};

export default VehicleStateInspectionCard;
