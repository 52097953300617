import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal, Row, Column, Form} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

const ChargeModal = ({
  visible,
  loading,
  amount,
  subtype,
  subtypes,
  description,
  onSave,
  onChange,
  onClose,
}) => (
  <Modal
    icon="creditcard"
    title="Charge"
    visible={visible}
    onClose={onClose}
    size="small"
    buttonsRight={[
      {
        label: 'Charge',
        theme: 'orange',
        onClick: onSave,
        loading: loading,
      },
    ]}
  >
    {(Component) => (
      <Component>
        <Form onSubmit={onSave} enter>
          <Row margin>
            <Column>
              <AttributeInput
                value={amount}
                onChange={onChange}
                disabled={loading}
                preValue="$"
              >
                {amount.label.short}
              </AttributeInput>
            </Column>
          </Row>
          <Row margin>
            <Column>
              <AttributeInput
                value={subtype}
                onChange={onChange}
                disabled={loading}
                options={subtypes}
              >
                {subtype.label.short}
              </AttributeInput>
            </Column>
          </Row>
          <Row>
            <Column>
              <AttributeInput
                value={description}
                onChange={onChange}
                disabled={loading}
              >
                {description.label.short}
              </AttributeInput>
            </Column>
          </Row>
        </Form>
      </Component>
    )}
  </Modal>
);

ChargeModal.propTypes = {
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  amount: PropTypes.object,
  subtype: PropTypes.object,
  subtypes: PropTypes.array,
  description: PropTypes.object,
  onSave: PropTypes.func,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
};

export default ChargeModal;
