import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {capitalWord} from '../../types';

export default attribute({
  type: TEXT,
  attribute: 'poc_address_city',
  display: capitalWord,
  input: capitalWord,
  api: capitalWord,
  label: {
    default: 'City',
    short: 'City',
  },
});
