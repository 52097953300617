import {DATETIME} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {dateTime, apiDateTime, shortDateTime} from '../../types';

export default attribute({
  type: DATETIME,
  attribute: 'endorsment_date',
  display: shortDateTime,
  input: dateTime,
  api: apiDateTime,
  label: {
    default: 'Date Of Endorsment',
    short: 'Endorsment Date',
  },
});
