import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

// Actions
import {set as setAct} from '../../redux/actions';

// api
import getByIDApi from '../../api/getByID.api.medallionOwner';

// Components
import PageLoader from '../../../layout/components/PageLoader/PageLoader';
import MedallionOwner from '../../components/MedallionOwner/MedallionOwner';

// Libs
import {lib} from '@matthahn/sally-ui';

// medallion api
import getMedallionByIdApi from '../../../medallion/api/getByID.api.medallion';

// Routes
import medallionOwnersRoute from '../../pages/MedallionOwnersPage/route';
import medallionRoute from '../../../medallion/pages/MedallionPage/route';

// Alerts
const {alert} = lib;

class MedallionOwnerContainer extends Component {
  static propTypes = {
    id: PropTypes.string,
    medallionOwner: PropTypes.object,
    history: PropTypes.object,
    dispatch: PropTypes.func,
  };

  state = {
    fetching: true,
    medallionOwner: null,
    medallions: [],
  };

  componentDidMount() {
    this.mounted = true;
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.id !== this.props.id) this.init();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  shouldStop = (id) => !this.mounted || this.props.id !== id;

  init = async () => {
    const {id, history, dispatch} = this.props;

    this.setState({fetching: true});

    try {
      const medallionOwner = await getByIDApi(id);
      const medallions = await Promise.all(
        (medallionOwner?.medallion_set || []).map((medallion) =>
          getMedallionByIdApi(medallion.id)
        )
      );
      if (this.shouldStop(id)) return;
      this.setState({fetching: false, medallionOwner, medallions});
      dispatch(setAct({object: medallionOwner}));
    } catch (error) {
      if (this.shouldStop(id)) return;
      alert.error("Medallion Owner doesn't exist");
      history.replace(medallionOwnersRoute());
    }
  };

  onMedallion = (medallion) => () =>
    this.props.history.push(medallionRoute(medallion.id));

  render() {
    const {medallionOwner: owner} = this.props;
    const {fetching, medallionOwner, medallions} = this.state;
    return fetching || !medallionOwner ? (
      <PageLoader />
    ) : (
      <MedallionOwner
        medallionOwner={owner}
        medallions={medallions}
        onMedallion={this.onMedallion}
      />
    );
  }
}

export default withRouter(
  connect((state) => ({medallionOwner: state.medallionOwner.object}))(
    MedallionOwnerContainer
  )
);
