import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Components
import AssociatedAccidentsTable from '../../components/AssociatedAccidentsTable/AssociatedAccidentsTable';

// Lib
import numberOfPages from '../../../lib/numberOfPages';

// Routes
import {withRouter} from 'react-router-dom';
import accidentRoute from '../../pages/AccidentPage/route';

class AssociatedAccidentsContainer extends Component {
  static propTypes = {
    api: PropTypes.func.isRequired,
    query: PropTypes.object,
    displayDriver: PropTypes.bool,
    displayVehicle: PropTypes.bool,
    history: PropTypes.object,
  };

  static defaultProps = {
    query: {},
    displayDriver: false,
    displayVehicle: false,
  };

  static LIMIT = 25;

  state = {
    loading: false,
    accidents: [],
    results: 0,
    page: 1,
  };

  componentDidMount() {
    this.getAccidents();
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getAccidents = async ({page = this.state.page} = {}) => {
    const {api, query} = this.props;
    const {loading} = this.state;
    const {LIMIT} = this.constructor;
    if (loading) return;

    this.setState({loading: true, page});

    try {
      const {results, count} = await api({
        offset: (page - 1) * LIMIT,
        limit: LIMIT,
        ordering: '-created_at',
        ...query,
      });
      if (!this.mounted) return;
      this.setState({
        loading: false,
        accidents: results,
        results: count,
      });
    } catch (error) {
      if (!this.mounted) return;
      this.setState({loading: false});
    }
  };

  onPage = (page) => this.getPayouts({page});

  pages = () => numberOfPages(this.state.results, this.constructor.LIMIT);

  onAccident = (accident) => () =>
    this.props.history.push(accidentRoute(accident.id));

  render() {
    const {displayDriver, displayVehicle} = this.props;
    const {loading, accidents, page} = this.state;
    return (
      <AssociatedAccidentsTable
        loading={loading}
        displayDriver={displayDriver}
        displayVehicle={displayVehicle}
        accidents={accidents}
        page={page}
        pages={this.pages()}
        onPage={this.onPage}
        onAccident={this.onAccident}
      />
    );
  }
}

export default withRouter(AssociatedAccidentsContainer);
