import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {show as showDispatchVehicleFlowAct} from '../../../dispatch/containers/DispatchVehicleFlowContainer/redux/actions';
import {show as showPayoutDriverModalAct} from '../../../payout/containers/PayoutDriverContainer/redux/actions';
import {show as showSkipPayoutModallAct} from '../../../payout/containers/SkipPayoutDriverContainer/redux/actions';
import {show as showHotswapAct} from '../../../rental/containers/HotswapContainer/redux/actions';
import {show as showPutVehicleOnHoldAct} from '../../../vehicleHold/containers/PutVehicleOnHoldContainer/redux/actions';
import {show as showRemoveVehicleFromHoldAct} from '../../../vehicleHold/containers/ReleaseVehicleFromHoldContainer/redux/actions';

// Components
import PayoutActionModal from '../../components/PayoutActionModal/PayoutActionModal';

// Lib
import {lib} from '@matthahn/sally-ui';
import canHaveMultipleRentals from '../../../dispatch/lib/canHaveMultipleRentals.lib.dispatch';
import isOnHold from '../../../vehicleHold/lib/isOnHold.lib.vehicleHold';

// Sockets
import dispatchedSocket from '../../../rental/sockets/dispatched.socket.rental';
import rentalEndedSocket from '../../../rental/sockets/ended.socket.rental';
import hotswapSocket from '../../../rental/sockets/hotswap.socket.rental';

// Permissions
import hotswapPermission from '../../../rental/permissions/hotswap.permission.rental';
import checkInAndPayoutPermission from '../../permission/checkInAndPayout.permission.payout';
import checkInOnlyPermission from '../../permission/checkInOnly.permission.payout';
import payoutOnlyPermission from '../../permission/payoutOnly.permission.payout';
import dispatchAnotherDriverPermission from '../../../dispatch/permission/dispatchAdditionalDriver.permission.dispatch';

// Alerts
const {alert} = lib;

class PayoutActionModalContainer extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    vehicle: PropTypes.object,
    onClose: PropTypes.func,
    dispatch: PropTypes.func,
  };

  state = {
    rental: null,
  };

  componentDidMount() {
    this.subscribe();
    if (this.props.visible) this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.visible && this.props.visible) this.init();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  subscriptions = [];

  subscribe = () => {
    this.subscriptions = [
      dispatchedSocket.subscribe(this.onDispatched),
      rentalEndedSocket.subscribe(this.onRentalEnd),
      hotswapSocket.subscribe(this.onHotswap),
    ];
  };

  unsubscribe = () => {
    this.subscriptions.forEach((unsubscribe) => unsubscribe());
    this.subscriptions = [];
  };

  onDispatched = (rental) =>
    this.closeBecauseUpdate({
      rental,
      message: `${rental.driver.first_name} ${rental.driver.last_name} was dispatched on ${rental.medallion.medallion_number}`,
    });

  onRentalEnd = (rental) =>
    this.closeBecauseUpdate({
      rental,
      message: `${rental.driver.first_name} ${rental.driver.last_name} returned ${rental.medallion.medallion_number}`,
    });

  onHotswap = ({hotswaps}) => {
    [...hotswaps].forEach(({old_rental, new_rental}) => {
      this.closeBecauseUpdate({
        rental: old_rental,
        message: `${old_rental.driver.first_name} ${old_rental.driver.last_name} switched from ${old_rental.medallion.medallion_number} to ${new_rental.medallion.medallion_number}`,
      });
      this.closeBecauseUpdate({
        rental: new_rental,
        message: `${old_rental.driver.first_name} ${old_rental.driver.last_name} switched from ${old_rental.medallion.medallion_number} to ${new_rental.medallion.medallion_number}`,
      });
    });
  };

  closeBecauseUpdate = ({rental, message}) => {
    const {visible, vehicle, onClose} = this.props;
    const isSameRental =
      !!vehicle &&
      !!vehicle.active_rentals &&
      !!vehicle.active_rentals.find(({id}) => rental.id === id);
    if (!visible || !isSameRental) return;
    onClose();
    alert.info(message);
  };

  init = () => {
    const {vehicle} = this.props;
    const rental =
      !!vehicle.active_rentals && vehicle.active_rentals.length === 1
        ? vehicle.active_rentals[0]
        : null;
    this.setState({rental});
  };

  dispatchNewDriver = () => {
    const {dispatch, vehicle, onClose} = this.props;
    if (!vehicle) return;
    onClose();
    dispatch(showDispatchVehicleFlowAct({...vehicle}, true));
  };

  payout = () => {
    this.dispatchPayout(false);
  };

  endWithPayout = () => {
    this.dispatchPayout(true);
  };

  dispatchPayout = (endRental) => {
    const {dispatch, vehicle, onClose} = this.props;
    const {rental} = this.state;
    if (!vehicle || !rental) return;
    onClose();
    dispatch(
      showPayoutDriverModalAct({
        driver: rental.driver,
        rental: rental,
        endRental,
      })
    );
  };

  endWithoutPayout = () => {
    const {dispatch, vehicle, onClose} = this.props;
    const {rental} = this.state;
    if (!vehicle || !rental) return;
    onClose();
    dispatch(showSkipPayoutModallAct(rental));
  };

  hotswap = () => {
    const {dispatch, vehicle, onClose} = this.props;
    const {rental} = this.state;
    if (!vehicle || !rental) return;
    onClose();
    dispatch(showHotswapAct({...rental}));
  };

  putOnHold = () => {
    const {vehicle, dispatch, onClose} = this.props;
    if (!vehicle) return;
    dispatch(showPutVehicleOnHoldAct(vehicle));
    onClose();
  };

  removeFromHold = () => {
    const {vehicle, dispatch, onClose} = this.props;
    if (!vehicle || !isOnHold(vehicle.vehicle_hold)) return;
    onClose();
    dispatch(
      showRemoveVehicleFromHoldAct({vehicle, hold: vehicle.vehicle_hold})
    );
  };

  options = () =>
    (this.canHaveMultipleRentals()
      ? [
          checkInAndPayoutPermission() && {
            id: 'endAndPayout',
            label: 'Check in and payout',
            icon: 'arrowright',
            onClick: this.endWithPayout,
          },
          checkInOnlyPermission() && {
            id: 'endWithoutPayout',
            label: 'Check in only',
            icon: 'enter',
            onClick: this.endWithoutPayout,
          },
          dispatchAnotherDriverPermission() && {
            id: 'dispatchAnotherDriver',
            label: 'Dispatch an additional driver',
            icon: 'dashboard',
            onClick: this.dispatchNewDriver,
          },
          hotswapPermission() && {
            id: 'hotswap',
            label: 'Hotswap',
            icon: 'swap',
            onClick: this.hotswap,
          },
          !isOnHold(
            !!this.props.vehicle ? this.props.vehicle.vehicle_hold : null
          ) && {
            id: 'putOnHold',
            label: 'Put on hold',
            icon: 'warning',
            onClick: this.putOnHold,
          },
        ]
      : [
          // {
          //   id: 'payoutOnly',
          //   label: 'Payout Only',
          //   icon: 'creditcard',
          //   onClick: this.payout,
          // },
          // {
          //   id: 'endWithoutPayout',
          //   label: 'End without payout',
          //   icon: 'enter',
          //   onClick: this.endWithoutPayout,
          // },
          // {
          //   id: 'hotswap',
          //   label: 'Hotswap',
          //   icon: 'swap',
          //   onClick: this.hotswap,
          // },
          !isOnHold(
            !!this.props.vehicle ? this.props.vehicle.vehicle_hold : null
          ) && {
            id: 'putOnHold',
            label: 'Put on hold',
            icon: 'warning',
            onClick: this.putOnHold,
          },
        ]
    ).filter((act) => !!act);

  onRental = (rental) => () => this.setState({rental});

  onBack = () => this.setState({rental: null});

  canHaveMultipleRentals = () => {
    const {vehicle} = this.props;
    return !!vehicle ? canHaveMultipleRentals(vehicle) : false;
  };

  render() {
    const {visible, vehicle, onClose} = this.props;
    const {rental} = this.state;
    return (
      <PayoutActionModal
        canCheckInAndPayout={checkInAndPayoutPermission()}
        canCheckInOnly={checkInOnlyPermission()}
        canPayoutOnly={payoutOnlyPermission()}
        isOnHold={isOnHold(!!vehicle ? vehicle.vehicle_hold : null)}
        visible={visible}
        vehicle={vehicle}
        rental={rental}
        canHaveMultipleRentals={this.canHaveMultipleRentals()}
        options={this.options()}
        hotswapPermission={hotswapPermission()}
        onEndWithPayout={this.endWithPayout}
        onEndWithoutPayout={this.endWithoutPayout}
        onClose={onClose}
        onPayout={this.payout}
        onHotswap={this.hotswap}
        onRental={this.onRental}
        onBack={this.onBack}
        onRemoveFromHold={this.removeFromHold}
      />
    );
  }
}

export default connect()(PayoutActionModalContainer);
