import React from 'react';

// Components
import {ScreenLoader} from '@matthahn/sally-ui';
import FullScreen from '../../../layout/components/FullScreen/FullScreen';

const AccidentLoader = () => (
  <FullScreen>
    <ScreenLoader theme="red">Loading Accident</ScreenLoader>
  </FullScreen>
);

export default AccidentLoader;
