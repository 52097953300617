import {date, fileName, folder} from '../../../document';

import {
  date_of_birth,
  dmv_license_state,
  dmv_license_number,
  dmv_license_expiry,
  dmv_license_issue_date,
} from '../../attributes';

export default folder({
  attributes: [
    date_of_birth,
    dmv_license_state,
    dmv_license_number,
    dmv_license_expiry,
    dmv_license_issue_date,
  ],
  defaultFileName: 'DMV License',
  // DMV - DRIVER ID - UPLOAD DATE
  fileName: fileName(
    ({driver}) => `DMV - ${driver.first_name}_${driver.last_name} - ${date()}`
  ),
  folderName: 'Licenses DMV',
  initAttributes: ({attributes, driver}) =>
    [...attributes].map((attr) =>
      attr.attribute === 'date_of_birth'
        ? attr(driver.date_of_birth || '')
        : attr('')
    ),
  type: 'dmv',
});
