import {Component} from 'react';
import PropTypes from 'prop-types';

// api
import api from '@matthahn/sally-fw/lib/api/lib/getEverything.lib.api';

// alert actions
import {set as setAlertsAction} from '../../redux/actions';

// alert events
import alertCreatedEvent from '../../events/created.event.alert';

// driverAlert api
import listDriverAlertsApi from '../../../driverAlert/api/listAll.api.driverAlert';

// driverAlert lib
import isPayoutAlert from '../../../driverAlert/lib/isPayoutAlert.lib.driverAlert';

// driverAlert sockets
import driverAlertCreatedSocket from '../../../driverAlert/sockets/created.socket.driverAlert';

// event HOCs
import subscriptionHoc from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// lib
import fkOrId from '@matthahn/sally-fw/lib/lib/fkOrId';

// rental sockets
import payoutSocket from '../../../rental/sockets/payout.socket.rental';

// redux
import {connect} from 'react-redux';

class FailedAutoPayoutsSync extends Component {
  static propTypes = {
    loadingFailedPayoutAlerts: PropTypes.bool,
    failedPayoutAlerts: PropTypes.array,
    children: PropTypes.node,
    dispatch: PropTypes.func,
    subscribe: PropTypes.func,
  };

  static defualtProps = {
    children: null,
  };

  componentDidMount() {
    this.mounted = true;
    this.getAlerts();
    this.props.subscribe(
      alertCreatedEvent.subscribe(this.alertCreated),
      driverAlertCreatedSocket.subscribe(this.alertCreatedSocketHandler),
      payoutSocket.subscribe(this.removeAlertsAfterPayout)
    );
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getAlerts = async () => {
    const {dispatch} = this.props;
    dispatch(
      setAlertsAction({
        loadingFailedPayoutAlerts: true,
      })
    );
    try {
      const {data: alerts} = await api((query) =>
        listDriverAlertsApi({
          resolved: false,
          subtype: 'autopayout',
          ...query,
        })
      );
      if (!this.mounted) return;
      dispatch(
        setAlertsAction({
          loadingFailedPayoutAlerts: false,
          failedPayoutAlerts: alerts,
        })
      );
    } catch (error) {
      if (!this.mounted) return;
      dispatch(setAlertsAction({loadingFailedPayoutAlerts: false}));
    }
  };

  alertCreated = ({alert}) => {
    const {dispatch} = this.props;
    const exists = !![...this.props.failedPayoutAlerts].find(
      ({id}) => id === alert.id
    );
    if (exists || !isPayoutAlert(alert)) return;
    dispatch(
      setAlertsAction({
        failedPayoutAlerts: [...this.props.failedPayoutAlerts, alert],
      })
    );
  };

  alertCreatedSocketHandler = (alert) => {
    this.alertCreated({alert});
  };

  removeAlertsAfterPayout = ({payout}) => {
    const {dispatch} = this.props;
    const alerts = [...this.props.failedPayoutAlerts].filter(
      (alert) =>
        !isPayoutAlert(alert) || fkOrId(alert.driver) !== fkOrId(payout.driver)
    );
    dispatch(setAlertsAction({failedPayoutAlerts: alerts}));
  };

  render() {
    const {children} = this.props;
    return children || null;
  }
}

export default connect((state) => ({
  loadingFailedPayoutAlerts: state.alert.loadingFailedPayoutAlerts,
  failedPayoutAlerts: state.alert.failedPayoutAlerts,
}))(subscriptionHoc(FailedAutoPayoutsSync));
