import {TOGGLE} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

export default attribute({
  type: TOGGLE,
  attribute: 'camera_unplugged',
  label: {
    default: 'Camera unplugged',
    short: 'Camera unplugged',
  },
});
