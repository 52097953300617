import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {v4} from 'uuid';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

// Api
import listDriversApi from '../../api/list.api.driver';

// Components
import DriversList from '../../components/DriversList/DriversList';
import NewDriverModal from '../../components/NewDriverModal/NewDriverModal';

// Containers
import NewDriverContainer from '../NewDriverContainer/NewDriverContainer';

// Libs
import {lib} from '@matthahn/sally-ui';
import sortQuery from '../../../api/lib/sortQuery.lib.api';
import numberOfPages from '../../../lib/numberOfPages';

// Route
import driverRoute from '../../pages/DriverPage/route';

// Permissions
import createDriverPermission from '../../permissions/create.permission.driver';

// Layout
import {clearUnsavedChanges} from '../../../layout/lib/unsavedChanges.lib.layout';

// States
import {stateList} from '../../state';

// Alert
const {alert, notify} = lib;

class DriversListContainer extends Component {
  static propTypes = {
    unsavedChanges: PropTypes.bool,
    history: PropTypes.object,
  };

  static OPTIONS = {
    perPage: 50,
  };

  static DEFAULT_SORT = {
    key: 'accessed_at',
    direction: 'desc',
  };

  state = {
    tab: ['all'],
    results: 0,
    page: 1,
    loading: true,
    drivers: [],
    sort: {...this.constructor.DEFAULT_SORT},
    newDriverModalVisible: false,
    lastNewDriverUpdate: null,
  };

  componentDidMount() {
    this.mounted = true;
    this.getDrivers();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  mounted = false;
  apiID = v4();
  savingNewDriver = false;

  tabs = () => [
    {
      value: 'all',
      label: 'All',
      query: {},
    },
    ...stateList.map((s) => ({
      value: s.key,
      label: s.label,
      query: {state__in: s.key},
    })),
  ];

  onTabChange = (tab) => {
    if (tab === 'all') {
      this.getDrivers({tab: ['all'], page: 1});
      return;
    }
    const filteredTab = (this.state.tab.includes(tab)
      ? [...this.state.tab].filter((t) => t !== tab)
      : [...this.state.tab, tab]
    ).filter((t) => t !== 'all');

    if (!filteredTab.length) {
      this.getDrivers({tab: ['all'], page: 1});
      return;
    }

    this.getDrivers({tab: filteredTab, page: 1});
  };

  onPage = (page) => this.getDrivers({page});

  onSearch = (search) => this.getDrivers({search});

  onSort = (sort) => this.getDrivers({sort});

  getTabData = (tab) => [...this.tabs()].find(({value}) => value === tab);

  pages = () =>
    numberOfPages(this.state.results, this.constructor.OPTIONS.perPage);

  getDrivers = async ({
    page = this.state.page,
    tab = this.state.tab,
    sort = this.state.sort,
  } = {}) => {
    const apiID = v4();
    this.apiID = apiID;
    const {perPage} = this.constructor.OPTIONS;
    this.setState({loading: true, drivers: [], page, tab, sort});
    const query = {
      ...sortQuery(sort || this.constructor.DEFAULT_SORT),
      limit: perPage,
      offset: (page - 1) * perPage,
      fields:
        'id,first_name,last_name,email,phone_number,state,balance,fhv_license_number,lease,deposit_balance',
    };

    if (tab.length > 0 && !tab.includes('all')) query.state__in = tab.join(',');

    try {
      const {results, count} = await listDriversApi(query);
      if (this.apiID !== apiID || !this.mounted) return;
      this.setState({drivers: results, loading: false, results: count});
    } catch (error) {
      if (this.apiID !== apiID || !this.mounted) return;
      alert.info('The request could not be made');
      this.setState({loading: false, drivers: [], results: 0});
    }
  };

  onDriver = (id) => () => this.props.history.push(driverRoute(id));

  onNewDriver = () =>
    this.setState({newDriverModalVisible: true, lastNewDriverUpdate: v4()});

  onNewDriverClose = (prompt = true) => {
    if (this.savingNewDriver) return;

    if (this.props.unsavedChanges && prompt)
      return notify({
        id: 'closeDriverCreationModal',
        title: 'Close',
        icon: undefined,
        content: 'Are you sure you want to close?',
        primary: {
          label: 'No',
          onClick: () => null,
        },
        secondary: {
          label: 'Yes',
          onClick: () => this.onNewDriverClose(false),
        },
        closable: false,
        closeOnOutsideClick: true,
      });

    clearUnsavedChanges();

    this.setState({newDriverModalVisible: false});
  };

  onDriverSave = (save) => {
    this.savingNewDriver = save;
  };

  render() {
    const {
      tab,
      page,
      loading,
      drivers,
      sort,
      results,
      newDriverModalVisible,
      lastNewDriverUpdate,
    } = this.state;
    return (
      <Fragment>
        <DriversList
          results={results}
          tab={tab}
          tabs={this.tabs()}
          onTabChange={this.onTabChange}
          loading={loading}
          drivers={drivers}
          page={page}
          pages={this.pages()}
          createDriverPermission={createDriverPermission()}
          onPage={this.onPage}
          sort={sort}
          onSort={this.onSort}
          onDriver={this.onDriver}
          onNewDriver={this.onNewDriver}
        />
        <NewDriverContainer
          lastUpdate={lastNewDriverUpdate}
          onLoad={this.onDriverSave}
        >
          {({...props}) => (
            <NewDriverModal
              {...props}
              visible={newDriverModalVisible}
              onClose={this.onNewDriverClose}
            />
          )}
        </NewDriverContainer>
      </Fragment>
    );
  }
}

export default withRouter(
  connect((state) => ({unsavedChanges: state.layout.unsavedChanges}))(
    DriversListContainer
  )
);
