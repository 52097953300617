import {NUMBER} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {number, stringNumber} from '../../types';

export default attribute({
  type: NUMBER,
  attribute: 'number_of_people_in_vehicle',
  display: stringNumber,
  input: stringNumber,
  api: number,
  label: {
    default: 'Number people in vehicle',
    short: '# people in vehicle',
  },
});
