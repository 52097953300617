import React from 'react';
import PropTypes from 'prop-types';

// local components
import Container from './components/Container';

const KeyLabel = ({label, onClick}) => (
  <Container onClick={onClick}>
    <span role="img" aria-label="key icon">
      🔑
    </span>{' '}
    {label}
  </Container>
);

KeyLabel.propTypes = {
  label: PropTypes.node,
  onClick: PropTypes.func,
};

export default KeyLabel;
