// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// insurance api
import createInsuranceApi from '../../api/create.api.insurance';

// insurance attributes
import accidentPhoneNumberAttribute from '../../attributes/accident_phone_number.attribute.insurance';
import insuranceCompanyNameAttribute from '../../attributes/insurance_company_name.attribute.insurance';
import insuranceCompanyNumberAttribute from '../../attributes/insurance_company_number.attribute.insurance';
import policyEffectiveDateAttribute from '../../attributes/policy_effective_date.attribute.insurance';
import policyExpirationDateAttribute from '../../attributes/policy_expiration_date.attribute.insurance';
import policyNumberAttribute from '../../attributes/policy_number.attribute.insurance';

// insurance components
import InsuranceEditModal from '../../components/InsuranceEditModal/InsuranceEditModal';

// insurance events
import showInsuranceCreateModalEvent from '../../events/showCreateModal.event.insurance';

// insurance preparations
import createInsurancePreparation from '../../preparations/create.preparation.insurance';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

class CreateInsuranceContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
  };

  static DEFAULT_STATE = {
    // ui state
    saving: false,
    visible: false,

    // attributes
    accident_phone_number: accidentPhoneNumberAttribute(''),
    insurance_company_name: insuranceCompanyNameAttribute(''),
    insurance_company_number: insuranceCompanyNumberAttribute(''),
    policy_effective_date: policyEffectiveDateAttribute(''),
    policy_expiration_date: policyExpirationDateAttribute(''),
    policy_number: policyNumberAttribute(''),
  };

  state = {
    ...this.constructor.DEFAULT_STATE,
  };

  componentDidMount() {
    this.props.subscribe(showInsuranceCreateModalEvent.subscribe(this.show));
  }

  show = () => {
    this.setState({
      ...this.constructor.DEFAULT_STATE,
      visible: true,
    });
  };

  hide = () => {
    if (this.state.saving) return;
    this.setState({visible: false});
  };

  change = (value, key) => {
    if (this.state.saving) return;
    this.setState({[key]: value});
  };

  save = async () => {
    const {saving, visible, ...attributes} = this.state;

    if (saving || !visible) return;

    this.setState({saving: true});

    try {
      const preparedInsurance = await createInsurancePreparation(attributes);
      await createInsuranceApi(preparedInsurance);
      alert.success('Insurance created');
      this.setState({saving: false, visible: false});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({saving: false});
    }
  };

  render() {
    const {saving, visible, ...attributes} = this.state;
    return (
      <InsuranceEditModal
        {...attributes}
        saving={saving}
        onClose={this.hide}
        onChange={this.change}
        onSave={this.save}
        title="Create Insurance"
        visible={visible}
      />
    );
  }
}

export default subscriptionHOC(CreateInsuranceContainer);
