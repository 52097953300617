import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

// Events
import event from '../events/created.event.medallion';

export default async (medallion) => {
  const response = await f3tch('/medallions/')
    .profile(profile())
    .body(medallion)
    .post();
  event.pub(response);
  return response;
};
