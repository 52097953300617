import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Components
import {
  Button,
  Container,
  Row,
  Column,
  Table,
  Tag,
  InputTyping,
} from '@matthahn/sally-ui';

import PageContainer from './components/Container';
import Content from './components/Content';

// State
import medallionState from '../../state';

class MedallionsList extends Component {
  static propTypes = {
    hasPermission: PropTypes.bool,
    results: PropTypes.number,
    loading: PropTypes.bool,
    medallions: PropTypes.array,
    page: PropTypes.number,
    pages: PropTypes.number,
    onPage: PropTypes.func,
    sort: PropTypes.object,
    onSort: PropTypes.func,
    onMedallion: PropTypes.func,
    onNewMedallion: PropTypes.func,
    search: PropTypes.string,
    onSearch: PropTypes.func,
  };

  columns = () => [
    {
      key: 'medallion_number',
      label: 'Medallion Number',
      sortable: true,
    },
    {
      key: 'agent',
      label: 'Owner',
    },
    {
      key: 'vehicle',
      label: 'Vehicle',
    },
    {
      key: 'state',
      label: 'State',
    },
  ];

  headerItems = (Content) => (
    <Content>
      {this.props.hasPermission && (
        <Button
          icon="plus"
          onClick={this.props.onNewMedallion}
          size="small"
          theme="orange"
        >
          New Medallion
        </Button>
      )}
    </Content>
  );

  results = () =>
    `${this.props.results} medallion${this.props.results === 1 ? '' : 's'}`;

  render() {
    const {
      loading,
      medallions,
      page,
      pages,
      onPage,
      sort,
      onSort,
      onMedallion,
      search,
      onSearch,
    } = this.props;
    return (
      <PageContainer>
        <Content>
          <Container>
            <Row margin>
              <Column size={1 / 4} offset={3 / 8}>
                <InputTyping
                  value={search}
                  onChange={onSearch}
                  preIcon="search1"
                >
                  Search for medallion number
                </InputTyping>
              </Column>
            </Row>
            <Row margin>
              <Column>
                <Table
                  columns={this.columns()}
                  subtitle={this.results()}
                  infinite={false}
                  page={page}
                  pages={pages}
                  onPage={onPage}
                  loading={loading}
                  sort={sort}
                  onSort={onSort}
                  headerItems={this.headerItems}
                  theme="orange"
                >
                  {(TableRow) =>
                    medallions.map((medallion) => {
                      return (
                        <TableRow onClick={onMedallion(medallion.id)}>
                          {(TableCol) => [
                            <TableCol key="medallion_number" uppercase bold>
                              {medallion.medallion_number}
                            </TableCol>,
                            <TableCol key="agent">
                              {!!medallion.owner && medallion.owner.name
                                ? medallion.owner.name
                                : '-'}
                            </TableCol>,
                            <TableCol key="vehicle">
                              {!!medallion.vehicle
                                ? medallion.vehicle.vin
                                : '-'}
                            </TableCol>,
                            <TableCol key="state">
                              <Tag
                                theme={medallionState(medallion.state).color}
                              >
                                {medallionState(medallion.state).label}
                              </Tag>
                            </TableCol>,
                          ]}
                        </TableRow>
                      );
                    })
                  }
                </Table>
              </Column>
            </Row>
          </Container>
        </Content>
      </PageContainer>
    );
  }
}

export default MedallionsList;
