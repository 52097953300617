import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Card, Row, Column, Form} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

const BasicInfoEdit = ({
  loading,
  errors,
  name,
  taxx_id,
  onChange,
  onCancel,
  onSave,
}) => (
  <Card
    title="Basic Info"
    icon="infocirlceo"
    headerActions={[
      {
        icon: 'close',
        onClick: onCancel,
        tooltip: 'Exit edit mode',
      },
    ]}
    buttonsRight={[{label: 'Save', loading, onClick: onSave, theme: 'orange'}]}
  >
    {(Content) => (
      <Content>
        <Form onSubmit={onSave} enter>
          <Row margin>
            <Column>
              <AttributeInput
                value={name}
                onChange={onChange}
                disabled={loading}
                errors={errors}
              >
                {name.label.default}
              </AttributeInput>
            </Column>
          </Row>
          <Row>
            <Column>
              <AttributeInput
                value={taxx_id}
                onChange={onChange}
                disabled={loading}
                errors={errors}
              >
                {taxx_id.label.default}
              </AttributeInput>
            </Column>
          </Row>
        </Form>
      </Content>
    )}
  </Card>
);

BasicInfoEdit.propTypes = {
  loading: PropTypes.bool,
  errors: PropTypes.array,
  name: PropTypes.object,
  type: PropTypes.object,
  taxx_id: PropTypes.object,
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};

export default BasicInfoEdit;
