import status from './wrapper.status';

// Statuses
import sallyToPay from './sally_to_pay.status.ticket';
import waiting from './waiting.status.ticket';

export default status({
  key: 'needs_action',
  label: 'Needs Action',
  transition: [waiting, sallyToPay],
  icon: 'warning',
});
