import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

export default (cheques, jwt = null) => {
  const api = f3tch('/payments/print_checks/').profile(profile());

  if (!!jwt) api.headers({Authorization: `JWT ${jwt}`});

  return api.body(cheques).post();
};
