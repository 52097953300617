import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {set as setVehicle} from '../../redux/actions';

// Components
import VehicleDocumentsLayout from '../../components/VehicleDocumentsLayout/VehicleDocumentsLayout';

// Containers
import DocumentsContainer from '../../../document/containers/DocumentsContainer/DocumentsContainer';

class VehicleDocumentsContainer extends Component {
  onDone = ({object}) => {
    this.props.dispatch(setVehicle({vehicle: object}));
  };

  render() {
    const {vehicle} = this.props;
    return (
      <VehicleDocumentsLayout>
        <DocumentsContainer vehicle={vehicle} type="vehicle" />
      </VehicleDocumentsLayout>
    );
  }
}

VehicleDocumentsContainer.propTypes = {
  vehicle: PropTypes.object,
};

export default connect((state) => ({
  vehicle: state.vehicle.vehicle,
}))(VehicleDocumentsContainer);
