import isNumber from 'is-number';

import type from '../type';

const format = (value) =>
  !!value || value === 0 || value === '0'
    ? `${value}`.replace(/[^0-9]/g, '') * 1
    : '';

const validate = (value) =>
  isNumber(value) &&
  /^\d*$/.test(value) &&
  value * 1 >= 300 &&
  value * 1 <= 1500;

export default type({type: 'number', format, validate});
