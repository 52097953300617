// lib
import orderByDate from '@matthahn/sally-fw/lib/lib/orderByDate';

// payment lib
import parseAch from './parseAch.lib.payment';
import parseAlert from './parseAlert.lib.payment';
import parseCheque from './parseCheque.lib.payment';

const parsePayments = ({achs, alerts, cheques}) => {
  const parsedAchs = [...achs].map((ach) => parseAch(ach));
  const parsedAlerts = [...alerts].map((alert) => parseAlert(alert));
  const parsedCheques = [...cheques].map((cheque) => parseCheque(cheque));
  return orderByDate(
    [...parsedAchs, ...parsedAlerts, ...parsedCheques],
    'created_at',
    'asc'
  );
};

export default parsePayments;
