import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {v4} from 'uuid';
import {withRouter, Switch, Route} from 'react-router-dom';
import {connect} from 'react-redux';

// Api
import api from '../../../api/lib/getEverythingFromApi.lib.api';
import getVehicleByIDApi from '../../api/getByID.api.vehicle';
import getCurrentKeyLabelApi from '../../api/getCurrentKeyLabel.api.vehicle';
import getVehicleMileageApi from '../../api/getMileage.api.vehicle';
import listNotesApi from '../../../vehicleNote/api/list.api.vehicleNote';
import listVehicleHoldsApi from '../../../vehicleHold/api/list.api.vehicleHold';
import listTicketsApi from '../../../ticket/api/list.api.ticket';

// Actions
import {set as setAct} from '../../redux/actions';

// Components
import VehicleLoader from '../../components/VehicleLoader/VehicleLoader';
import VehiclePageLayout from '../../components/VehiclePageLayout/VehiclePageLayout';

// containers
import KeyLabelContainer from '../../containers/KeyLabelContainer/KeyLabelContainer';

// Events
import archivedEvt from '../../events/archived.event.vehicle';
import unarchivedEvt from '../../events/unarchived.event.vehicle';
import decommissionedEvt from '../../events/decommissioned.event.vehicle';
import decommissionRevertedEvt from '../../events/decommissionReverted.event.vehicle';
import connectedWithMedallionEvt from '../../events/connectedWithMedallion.event.vehicle';
import disconnectedFromMedallionEvt from '../../events/disconnectedFromMedallion.event.vehicle';
import vehicleHoldUpdatedEvt from '../../../vehicleHold/events/updated.event.vehicleHold';

// nsurance api
import getInsuranceByIdApi from '../../../insurance/api/getById.api.insurance';

// Libs
import {lib} from '@matthahn/sally-ui';
import fkOrId from '../../../lib/fkOrId';
import eventRace from '../../../events/race.event';

// Route
import vehiclesListRoute from '../../pages/VehiclesListPage/route';

// Tickets
import waitingTicketStatus from '../../../ticket/statuses/waiting.status.ticket';

// vehicleAlert api
import listVehicleAlertsApi from '../../../vehicleAlert/api/list.api.vehicleAlert';

// vehicleAlert lib
import showVehicleAlertCreateModal from '../../../vehicleAlert/lib/showCreateModal.lib.vehicleAlert';

// VehicleInfoSave
import VehicleInfoSavePage from '../../pages/VehicleInfoSavePage/VehicleInfoSavePage';
import routeVehicleInfoSave from '../../pages/VehicleInfoSavePage/route';

// VehicleInfo
import VehicleInfoPage from '../../pages/VehicleInfoPage/VehicleInfoPage';
import routeVehicleInfo from '../../pages/VehicleInfoPage/route';

// VehicleMaintenance
import VehicleMaintenancePage from '../../pages/VehicleMaintenancePage/VehicleMaintenancePage';
import routeVehicleMaintenance from '../../pages/VehicleMaintenancePage/route';

// VehicleRentals
import VehicleRentalsPage from '../../pages/VehicleRentalsPage/VehicleRentalsPage';
import routeVehicleRentals from '../../pages/VehicleRentalsPage/route';

// VehicleDocuments
import VehicleDocumentsPage from '../../pages/VehicleDocumentsPage/VehicleDocumentsPage';
import routeVehicleDocuments from '../../pages/VehicleDocumentsPage/route';

// VehicleTickets
import VehicleTicketsPage from '../../pages/VehicleTicketsPage/VehicleTicketsPage';
import routeVehicleTickets from '../../pages/VehicleTicketsPage/route';

// VehicleNotes
import VehicleNotesPage from '../../pages/VehicleNotesPage/VehicleNotesPage';
import routeVehicleNotes from '../../pages/VehicleNotesPage/route';

// VehicleRedirect
import VehicleRedirectPage from '../../pages/VehicleRedirectPage/VehicleRedirectPage';
import routeVehicleRedirect from '../../pages/VehicleRedirectPage/route';

// Sockets
import updatedSocket from '../../socket/updated.socket.vehicle';
import putOnHoldSocket from '../../../vehicleHold/socket/putOnHold.socket.vehicleHold';
import removeFromHoldSocket from '../../../vehicleHold/socket/removeFromHold.socket.vehicleHold';

// Alert
const {alert} = lib;

class VehicleContainer extends Component {
  static propTypes = {
    id: PropTypes.string,
    dispatch: PropTypes.func,
    loading: PropTypes.bool,
    history: PropTypes.object,
    location: PropTypes.object,
    loadingVehicle: PropTypes.bool,
    vehicle: PropTypes.object,
    loadingNotes: PropTypes.bool,
    notes: PropTypes.array,
    loadingMileage: PropTypes.bool,
    mileage: PropTypes.object,
    loadingHold: PropTypes.bool,
    hold: PropTypes.object,
    waitingTickets: PropTypes.array,
  };

  componentDidMount() {
    this.init(this.props.id);
    this.subscribe();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) this.init(this.props.id);
  }

  componentWillUnmount() {
    this.mounted = false;
    this.unsubscribe();
  }

  mounted = true;
  apiID = v4();
  events = [];

  subscribe = () => {
    this.events = [
      archivedEvt.sub(this.remoteUpdate),
      unarchivedEvt.sub(this.remoteUpdate),
      decommissionedEvt.sub(this.remoteUpdate),
      decommissionRevertedEvt.sub(this.remoteUpdate),
      connectedWithMedallionEvt.sub(this.remoteUpdate),
      disconnectedFromMedallionEvt.sub(this.remoteUpdate),
      vehicleHoldUpdatedEvt.sub(this.putOnHold),

      updatedSocket.subscribe(this.remoteUpdate),
      putOnHoldSocket.subscribe(this.putOnHold),
      removeFromHoldSocket.subscribe(this.removeFromHold),
    ];
  };

  unsubscribe = () => {
    this.events.forEach((unsubscribe) => unsubscribe());
  };

  init = (id) => {
    this.apiID = v4();
    this.getVehicle(id);
    this.getNotes(id);
    this.getVehicleAlerts(id);
    this.getHold(id);
    this.getWaitingTickets(id);
    this.getMileage(id);
    this.getKeyLabel(id);
  };

  stop = (apiID) => this.apiID !== apiID || !this.mounted;

  remoteUpdate = eventRace((newVehicle) => {
    const {vehicle, dispatch} = this.props;
    if (
      !vehicle ||
      vehicle.id !== newVehicle.id ||
      vehicle.state === newVehicle.state
    )
      return;
    dispatch(
      setAct({
        vehicle: {
          ...vehicle,
          state: newVehicle.state,
          medallion: newVehicle.medallion,
        },
      })
    );
  });

  putOnHold = (hold) => {
    const {vehicle, dispatch} = this.props;
    if (!vehicle || vehicle.id !== fkOrId(hold.vehicle)) return;
    dispatch(setAct({hold}));
  };

  removeFromHold = (hold) => {
    const {vehicle, dispatch} = this.props;
    if (!vehicle || vehicle.id !== fkOrId(hold.vehicle)) return;
    dispatch(setAct({hold: null}));
  };

  getVehicle = async (id) => {
    const {dispatch} = this.props;
    const apiID = this.apiID;

    dispatch(setAct({loadingVehicle: true}));

    try {
      const vehicle = await getVehicleByIDApi(id);
      const insurance = await (!!vehicle.insurance
        ? getInsuranceByIdApi(vehicle.insurance)
        : null);
      vehicle.insurance = insurance;
      if (this.stop(apiID)) return;
      dispatch(setAct({loadingVehicle: false, vehicle}));
    } catch (error) {
      if (this.stop(apiID)) return;
      alert.warning('This vehicle does not exist');
      this.props.history.replace(vehiclesListRoute());
    }
  };

  getNotes = async (vehicle) => {
    const {dispatch} = this.props;
    const apiID = this.apiID;

    dispatch(setAct({loadingNotes: true}));

    try {
      const {results: notes} = await listNotesApi(vehicle, {
        ordering: '-date_created',
      });
      if (this.stop(apiID)) return;
      dispatch(setAct({loadingNotes: false, notes}));
    } catch (error) {
      if (this.stop(apiID)) return;
      dispatch(setAct({loadingNotes: false}));
    }
  };

  getVehicleAlerts = async (vehicleId) => {
    const {dispatch} = this.props;
    const apiID = this.apiID;

    dispatch(setAct({loadingVehicleAlerts: true}));

    try {
      const {results: vehicleAlerts} = await listVehicleAlertsApi(vehicleId, {
        resolved: false,
      });
      if (this.stop(apiID)) return;
      dispatch(setAct({loadingVehicleAlerts: false, vehicleAlerts}));
    } catch (error) {
      if (this.stop(apiID)) return;
      dispatch(setAct({loadingVehicleAlerts: false}));
    }
  };

  getHold = async (vehicle) => {
    const {dispatch} = this.props;
    const apiID = this.apiID;

    dispatch(setAct({loadingHold: true}));

    try {
      const {results: holds} = await listVehicleHoldsApi({
        vehicle,
        ordering: '-date_created',
        limit: 1,
        offset: 0,
      });
      if (this.stop(apiID)) return;
      const hold = !!holds.length ? holds[0] : null;
      dispatch(setAct({loadingHold: false, hold}));
    } catch (error) {
      if (this.stop(apiID)) return;
      dispatch(setAct({loadingHold: false}));
    }
  };

  getWaitingTickets = async (vehicle) => {
    const {dispatch} = this.props;
    const apiID = this.apiID;

    dispatch(setAct({waitingTickets: []}));

    try {
      const {data: waitingTickets} = await api(listTicketsApi, {
        vehicle,
        removed: false,
        status: waitingTicketStatus.key,
      });
      if (this.stop(apiID)) return;
      dispatch(setAct({waitingTickets}));
    } catch (error) {
      if (this.stop(apiID)) return;
      dispatch(setAct({waitingTickets: []}));
    }
  };

  getMileage = async (vehicleId) => {
    const {dispatch} = this.props;
    const apiID = this.apiID;

    dispatch(setAct({loadingMileage: true, mileage: null}));

    try {
      const mileage = await getVehicleMileageApi(vehicleId);
      if (this.stop(apiID)) return;
      dispatch(setAct({loadingMileage: false, mileage}));
    } catch (error) {
      if (this.stop(apiID)) return;
      dispatch(setAct({loadingMileage: false}));
    }
  };

  getKeyLabel = async (id) => {
    const {dispatch} = this.props;
    const apiID = this.apiID;

    dispatch(setAct({loadingKeyLabel: true}));

    try {
      const keyLabel = await getCurrentKeyLabelApi(id);
      if (this.stop(apiID)) return;
      dispatch(setAct({loadingKeyLabel: false, keyLabel}));
    } catch (error) {
      if (this.stop(apiID)) return;
      dispatch(setAct({loadingKeyLabel: false, keyLabel: null}));
    }
  };

  tabs = () => {
    const {
      vehicle: {alerts, id},
      notes,
      waitingTickets,
    } = this.props;
    return [
      {
        id: routeVehicleInfo(id),
        label: 'Info',
      },
      {
        id: routeVehicleMaintenance(id),
        label: 'Maintenance',
        notifications: !!alerts ? alerts.length : 0,
      },
      {
        id: routeVehicleRentals(id),
        label: 'Rentals',
        selected: this.props.location.pathname.startsWith(
          routeVehicleRentals(id)
        ),
      },
      {
        id: routeVehicleDocuments(id),
        label: 'Documents',
      },
      {
        id: routeVehicleNotes(id),
        label: 'Notes',
        notifications: notes.length,
      },
      {
        id: routeVehicleTickets(id),
        label: 'Tickets',
        notifications: waitingTickets.length,
      },
    ];
  };

  onTab = (route) => this.props.history.push(route);

  showCreateAlertModal = () => {
    const {vehicle} = this.props;
    showVehicleAlertCreateModal({vehicle});
  };

  addon = () => (
    <Switch>
      <Route
        exact
        path={routeVehicleInfoSave()}
        component={VehicleInfoSavePage}
      />
    </Switch>
  );

  render() {
    const {
      id,
      loadingVehicle,
      loadingNotes,
      loadingVehicleAlerts,
      loadingHold,
      loadingKeyLabel,
      loadingMileage,
      vehicle,
      hold,
      location: {pathname},
    } = this.props;
    return loadingVehicle ||
      loadingNotes ||
      loadingVehicleAlerts ||
      loadingHold ||
      loadingMileage ||
      loadingKeyLabel ||
      !vehicle ||
      (!!vehicle && `${vehicle.id}` !== id) ? (
      <VehicleLoader />
    ) : (
      <VehiclePageLayout
        vin={vehicle.vin}
        year={vehicle.vehicle_year}
        make={vehicle.vehicle_make}
        model={vehicle.vehicle_model}
        type={vehicle.vehicle_type}
        hold={hold}
        tag={<KeyLabelContainer />}
        state={vehicle.state}
        medallion={vehicle.medallion}
        addon={this.addon()}
        tab={pathname}
        tabs={this.tabs()}
        onTab={this.onTab}
        onCreateAlert={this.showCreateAlertModal}
      >
        <Switch>
          <Route exact path={routeVehicleInfo()} component={VehicleInfoPage} />
          <Route
            exact
            path={routeVehicleMaintenance()}
            component={VehicleMaintenancePage}
          />
          <Route path={routeVehicleRentals()} component={VehicleRentalsPage} />
          <Route
            exact
            path={routeVehicleDocuments()}
            component={VehicleDocumentsPage}
          />
          <Route
            exact
            path={routeVehicleNotes()}
            component={VehicleNotesPage}
          />
          <Route
            exact
            path={routeVehicleTickets()}
            component={VehicleTicketsPage}
          />
          <Route
            path={routeVehicleRedirect()}
            component={VehicleRedirectPage}
          />
        </Switch>
      </VehiclePageLayout>
    );
  }
}

export default withRouter(
  connect((state) => ({
    ...state.vehicle,
  }))(VehicleContainer)
);
