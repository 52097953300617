import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

export default attribute({
  type: TEXT,
  attribute: 'fax_number',
  label: {
    default: 'FAX',
    short: 'Fax',
  },
});
