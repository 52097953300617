import * as colors from '@matthahn/sally-ui/lib/libs/colors';

const colorValues = Object.values(colors);
const colorKeys = Object.keys(colors);

export const colorValue = (string) =>
  colorValues[`${string}`.charCodeAt(0) % (colorValues.length - 1)];

export const colorKey = (string) =>
  colorKeys[`${string}`.charCodeAt(0) % (colorKeys.length - 1)];
