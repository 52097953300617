// Constants
import DIRECT_DEPOSIT_KEY from '../constants/directDepositKey.constant.payout';

export default ({paymentMethods = [], bankAccounts = []} = []) => [
  ...[...paymentMethods].filter(({key}) => key !== 'direct_deposit'),
  ...bankAccounts
    .filter(({verified}) => verified)
    .map(({dwolla_funding_id, funding_name}) => ({
      key: `${DIRECT_DEPOSIT_KEY}${dwolla_funding_id}`,
      label: `Deposit to ${funding_name}`,
    })),
];
