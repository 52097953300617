import {DATETIME} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {dateTime, apiDateTime} from '../../types';

export default attribute({
  type: DATETIME,
  attribute: 'datetime_of_loss',
  display: dateTime,
  input: dateTime,
  api: apiDateTime,
  label: {
    default: 'Date of loss',
    short: 'Date of loss',
  },
});
