import {SET} from './constants';

export const show = (rental) => ({
  type: SET,
  data: {visible: true, rental},
});

export const hide = () => ({
  type: SET,
  data: {visible: false},
});
