import {Component} from 'react';
import PropTypes from 'prop-types';

// api
import api from '@matthahn/sally-fw/lib/api/lib/getEverything.lib.api';

// alert actions
import {set as setAction} from '../../redux/actions';

// alert events
import alertCreatedEvent from '../../events/created.event.alert';

// component lib
import alert from '@matthahn/sally-ui/lib/libs/alert';
import notify from '@matthahn/sally-ui/lib/libs/notify';

// driverAlert lib
import isPayoutAlert from '../../../driverAlert/lib/isPayoutAlert.lib.driverAlert';

// driverAlert sockets
import driverAlertCreatedSocket from '../../../driverAlert/sockets/created.socket.driverAlert';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// event HOCs
import subscriptionHoc from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// lib
import fkOrId from '@matthahn/sally-fw/lib/lib/fkOrId';

// payout actions
import {show as showPayoutAct} from '../../../payout/containers/PayoutDriverContainer/redux/actions';

// rental sockets
import payoutSocket from '../../../rental/sockets/payout.socket.rental';

// redux
import {connect} from 'react-redux';

// vehicleAlert sockets
import vehicleAlertCreatedSocket from '../../../vehicleAlert/sockets/created.socket.vehicleAlert';

class AlertsContainer extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    dispatch: PropTypes.func,
    listApi: PropTypes.func.isRequired,
    object: PropTypes.object.isRequired,
    objectType: PropTypes.string.isRequired,
    subscribe: PropTypes.func,
    updateApi: PropTypes.func.isRequired,
    filterAlerts: PropTypes.func,
  };

  state = {
    loading: true,
    alerts: [],
    resolving: [],
  };

  componentDidMount() {
    this.mounted = true;
    this.getAlerts();
    this.props.subscribe(
      alertCreatedEvent.subscribe(this.alertCreated),
      driverAlertCreatedSocket.subscribe(
        this.alertCreatedSocketHandler('driver')
      ),
      vehicleAlertCreatedSocket.subscribe(
        this.alertCreatedSocketHandler('vehicle')
      ),
      payoutSocket.subscribe(this.removeAlertsAfterPayout)
    );
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getAlerts = async () => {
    const {object, objectType, listApi} = this.props;
    try {
      const {data: alerts} = await api((query) =>
        listApi(object.id, {resolved: false, [objectType]: object.id, ...query})
      );
      if (!this.mounted) return;
      this.setState({loading: false, alerts});
    } catch (error) {
      if (!this.mounted) return;
      this.setState({loading: false, alerts: []});
    }
  };

  alertCreated = ({alert, object, objectType}) => {
    const exists = !![...this.state.alerts].find(({id}) => id === alert.id);
    if (
      this.props.object?.id !== object.id ||
      this.props.objectType !== objectType ||
      exists
    )
      return;
    this.setState({alerts: [...this.state.alerts, alert]});
  };

  alertCreatedSocketHandler = (objectType) => (alert) => {
    const object = alert[objectType];
    if (!object) return;
    this.alertCreated({object, objectType, alert});
  };

  resolve = async (id, prompt = true) => {
    const {object, updateApi, dispatch} = this.props;
    const {resolving} = this.state;

    if (resolving.includes(id)) return;

    if (prompt)
      return notify({
        id: 'resolveAlert',
        title: 'Confirm',
        icon: undefined,
        content: 'Are you sure you want to resolve this alert?',
        primary: {
          label: 'No',
          onClick: () => null,
        },
        secondary: {
          label: 'Yes',
          onClick: () => this.resolve(id, false),
        },
        closable: false,
        closeOnOutsideClick: true,
      });

    this.setState({resolving: [...resolving, id]});
    dispatch(setAction({updatingAlert: true}));
    const removeResolving = (alertID) =>
      [...this.state.resolving].filter((aID) => aID !== alertID);

    try {
      await updateApi(object.id, id, {
        resolved: true,
      });
      dispatch(setAction({updatingAlert: false}));

      if (!this.mounted) return;
      this.setState({
        alerts: [...this.state.alerts].filter(
          ({id: alertId}) => alertId !== id
        ),
        resolving: removeResolving(id),
      });
    } catch (error) {
      dispatch(setAction({updatingAlert: false}));
      if (!this.mounted) return;
      const {message} = parseError(error);
      alert.error(message);
      this.setState({resolving: removeResolving(id)});
    }
  };

  payout = (alert) => () => {
    const {dispatch} = this.props;
    const isPayout = isPayoutAlert(alert);
    if (!isPayout) return;
    dispatch(
      showPayoutAct({
        driver: !!alert?.driver?.id ? {...alert.driver} : {id: alert.driver},
        endRental: false,
      })
    );
  };

  alerts = () => {
    const {filterAlerts} = this.props;
    const parsedAlerts = [...this.state.alerts].map((alert) => {
      const isPayout = isPayoutAlert(alert);
      const actionLabel = isPayout ? 'Payout' : 'Resolve';
      const onAction = isPayout
        ? this.payout(alert)
        : () => this.resolve(alert.id);
      const resolving = this.state.resolving.includes(alert.id);
      return {
        ...alert,
        isPayout,
        resolving,
        actionLabel,
        onAction,
        infoProps: {
          key: alert.id,
          type: alert.error ? 'error' : 'warning',
          action: {
            label: actionLabel,
            onClick: onAction,
          },
          loading: resolving,
          flat: true,
        },
      };
    });
    return !!filterAlerts
      ? [...parsedAlerts].filter(filterAlerts)
      : parsedAlerts;
  };

  removeAlertsAfterPayout = ({payout}) => {
    const alerts = [...this.state.alerts].filter(
      (alert) =>
        !isPayoutAlert(alert) || fkOrId(alert.driver) !== fkOrId(payout.driver)
    );
    this.setState({alerts});
  };

  render() {
    const {children} = this.props;
    const {loading, resolving} = this.state;
    return children({
      loading,
      alerts: this.alerts(),
      resolving,
      onResolve: this.resolve,
    });
  }
}

export default subscriptionHoc(connect()(AlertsContainer));
