import React from 'react';
import PropTypes from 'prop-types';

// Components
import DriverContainer from '../../containers/DriverContainer/DriverContainer';

const DriverPage = ({
  match: {
    params: {id},
  },
}) => <DriverContainer id={id} />;

DriverPage.propTypes = {
  match: PropTypes.object,
};

export default DriverPage;
