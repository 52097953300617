import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {hide as hideAct} from './redux/actions';

// Attributes
import reasonAttr from '../../attributes/reason.attribute.medallion';

// Api
import enableApi from '../../api/enable.api.medallion';

// Components
import EnableMedallionModal from '../../components/EnableMedallionModal/EnableMedallionModal';

// Lib
import {lib} from '@matthahn/sally-ui';
import parseError from '../../../error/parseError';

// Permissions
import storePermission from '../../permissions/canStore.permission.medallion';

// Preps
import enablePrep from '../../preparation/enable.preparation.medallion';

// Alert
const {alert} = lib;

class EnableMedallionContainer extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    medallion: PropTypes.object,
  };

  static STATE = {
    loading: false,
    reason: reasonAttr(''),
  };

  state = {...this.constructor.STATE};

  componentDidMount() {
    if (this.props.visible) this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.visible && this.props.visible) this.init();
  }

  init = () => {
    this.setState({...this.constructor.STATE});
  };

  onClose = () => {
    const {dispatch} = this.props;
    const {loading} = this.state;
    if (loading) return;
    dispatch(hideAct());
  };

  onChange = (val, key) => {
    if (this.state.loading) return;
    this.setState({[key]: val});
  };

  save = async () => {
    const {dispatch, visible, medallion} = this.props;
    const {loading, ...attributes} = this.state;

    if (loading || !visible || !medallion) return;

    if (!storePermission())
      return alert.warning('You do not have permission to enable medallions');

    this.setState({loading: true});

    try {
      const {reason} = await enablePrep(attributes);
      await enableApi(medallion.id, reason);
      alert.success('Medallion enabled');
      dispatch(hideAct());
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
    }

    this.setState({loading: false});
  };

  render() {
    const {visible} = this.props;
    const {loading, reason} = this.state;
    return (
      <EnableMedallionModal
        visible={visible}
        loading={loading}
        reason={reason}
        onClose={this.onClose}
        onChange={this.onChange}
        onSave={this.save}
      />
    );
  }
}

export default connect((state) => ({...state.enableMedallion}))(
  EnableMedallionContainer
);
