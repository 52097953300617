export default [
  {
    key: 'date',
    label: 'Date',
  },
  {
    key: 'type',
    label: 'Type',
  },
  {
    key: 'amount',
    label: 'Amount',
  },
  {
    key: 'balance',
    label: 'Balance',
  },
  {
    key: 'description',
    label: 'Description',
  },
];
