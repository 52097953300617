import {SELECT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {string} from '../../types';

export default attribute({
  type: SELECT,
  attribute: 'dashcam',
  api: string,
  label: {
    default: 'Dashcam',
    short: 'Dashcam',
  },
  options: ['NONE', 'BLACKVUE'],
});
