import React from 'react';

// Components
import {P} from '@matthahn/sally-ui';
import Container from './components/Container';

const VehicleRentalsDashboard = () => (
  <Container>
    <P theme="darkGrey" size="3">
      Place for something cool (stats, quick views, important info, ...)
    </P>
  </Container>
);

export default VehicleRentalsDashboard;
