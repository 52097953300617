import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Components
import {
  Button,
  Container,
  Row,
  Column,
  Table,
  Select,
  P,
  TextColor,
  Tag,
} from '@matthahn/sally-ui';
import AttributeFormatter from '../../../layout/components/AttributeFormatter/AttributeFormatter';
import DriverTags from '../DriverTags/DriverTags';

import PageContainer from './components/Container';
import Content from './components/Content';
import StateSelect from './components/StateSelect';

// Attributes
import first_name from '../../attributes/first_name.attribute.driver';
import last_name from '../../attributes/last_name.attribute.driver';
import fhv_license_number from '../../attributes/fhv_license_number.attribute.driver';
import phone_number from '../../attributes/phone_number.attribute.driver';
import state from '../../attributes/state.attribute.driver';
import balance from '../../attributes/balance.attribute.driver';
import deposit from '../../attributes/deposit.attribute.driver';

// State
import driverState from '../../state';

class DriversList extends Component {
  static propTypes = {
    results: PropTypes.number,
    tab: PropTypes.array,
    tabs: PropTypes.array,
    onTabChange: PropTypes.func,
    loading: PropTypes.bool,
    drivers: PropTypes.array,
    page: PropTypes.number,
    pages: PropTypes.number,
    onPage: PropTypes.func,
    createDriverPermission: PropTypes.bool,
    sort: PropTypes.object,
    onSort: PropTypes.func,
    onDriver: PropTypes.func,
    onNewDriver: PropTypes.func,
  };

  columns = () => [
    {
      key: 'first_name',
      label: first_name.label.short,
      sortable: true,
    },
    {
      key: 'fhv_license_number',
      label: fhv_license_number.label.short,
      sortable: true,
    },
    {
      key: 'phone_number',
      label: phone_number.label.short,
    },
    {
      key: 'state',
      label: state.label.short,
      sortable: true,
    },
    {
      key: 'balance',
      label: balance.label.short,
      sortable: true,
    },
    {
      key: 'deposit',
      label: deposit.label.short,
      sortable: true,
    },
  ];

  headerItems = (Content) =>
    [
      this.props.createDriverPermission && (
        <Content key="newdriver">
          <Button
            icon="plus"
            onClick={this.props.onNewDriver}
            size="small"
            theme="orange"
          >
            New Driver
          </Button>
        </Content>
      ),
      <Content key="statechange">
        <StateSelect>
          <Select
            value={this.props.tab}
            options={this.props.tabs}
            onChange={this.props.onTabChange}
            notFilterable
          />
        </StateSelect>
      </Content>,
    ].filter((dom) => !!dom);

  results = () =>
    `${this.props.results} driver${this.props.results === 1 ? '' : 's'}`;

  parenthesis = (value, wrap = false) => {
    const dom = (
      <span>
        $<AttributeFormatter attribute={balance(Math.abs(value))} empty="0" />
      </span>
    );
    return wrap ? <span>({dom})</span> : dom;
  };

  render() {
    const {loading, drivers, page, pages, onPage, sort, onSort, onDriver} =
      this.props;
    return (
      <PageContainer>
        <Content>
          <Container>
            <Row margin>
              <Column>
                <Table
                  columns={this.columns()}
                  subtitle={this.results()}
                  infinite={false}
                  page={page}
                  pages={pages}
                  onPage={onPage}
                  loading={loading}
                  sort={sort}
                  onSort={onSort}
                  headerItems={this.headerItems}
                  theme="orange"
                >
                  {(TableRow) =>
                    drivers.map((driver) => (
                      <TableRow onClick={onDriver(driver.id)}>
                        {(TableCol) => [
                          <TableCol key="name" uppercase bold>
                            <P theme="black">
                              <DriverTags driver={driver}>
                                <AttributeFormatter
                                  attribute={first_name(driver.first_name)}
                                />{' '}
                                <AttributeFormatter
                                  attribute={last_name(driver.last_name)}
                                />
                              </DriverTags>
                            </P>
                          </TableCol>,
                          <TableCol key="fhv_license_number">
                            <AttributeFormatter
                              attribute={fhv_license_number(
                                driver.fhv_license_number
                              )}
                            />
                          </TableCol>,
                          <TableCol key="number">
                            <AttributeFormatter
                              attribute={phone_number(driver.phone_number)}
                            />
                          </TableCol>,
                          <TableCol key="state">
                            <Tag theme={driverState(driver.state).color}>
                              {driverState(driver.state).label}
                            </Tag>
                          </TableCol>,
                          <TableCol key="balance">
                            <TextColor
                              theme={
                                driver.balance > 0 && driver.credit > 0
                                  ? 'darkGrey'
                                  : 'black'
                              }
                            >
                              {this.parenthesis(
                                driver.balance,
                                driver.balance < 0
                              )}
                            </TextColor>
                          </TableCol>,
                          <TableCol key="deposit">
                            {this.parenthesis(
                              driver.deposit_balance,
                              driver.deposit_balance > 0
                            )}
                          </TableCol>,
                        ]}
                      </TableRow>
                    ))
                  }
                </Table>
              </Column>
            </Row>
          </Container>
        </Content>
      </PageContainer>
    );
  }
}

export default DriversList;
