import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Components
import MedallionDocumentsLayout from '../../components/MedallionDocumentsLayout/MedallionDocumentsLayout';

// Containers
import DocumentsContainer from '../../../document/containers/DocumentsContainer/DocumentsContainer';

class MedallionDocumentsContainer extends Component {
  render() {
    const {medallion} = this.props;
    return (
      <MedallionDocumentsLayout>
        <DocumentsContainer medallion={medallion} type="medallion" />
      </MedallionDocumentsLayout>
    );
  }
}

MedallionDocumentsContainer.propTypes = {
  medallion: PropTypes.object,
};

export default connect((state) => ({
  medallion: state.medallion.medallion,
}))(MedallionDocumentsContainer);
