import React from 'react';
import PropTypes from 'prop-types';

// components
import {Modal, Textarea} from '@matthahn/sally-ui';

const NoClaimModal = ({loading, onChange, onClose, onSave, text, visible}) => (
  <Modal
    visible={visible}
    onClose={onClose}
    title="No claim"
    buttonsRight={[{label: 'Save', onClick: onSave, loading}]}
  >
    {(Content) => (
      <Content>
        <Textarea value={text} onChange={onChange}>
          Reason for not filing a claim
        </Textarea>
      </Content>
    )}
  </Modal>
);

NoClaimModal.propTypes = {
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  text: PropTypes.string,
  visible: PropTypes.bool,
};

export default NoClaimModal;
