const api = async (
  call,
  query = {},
  {current = [], offset = 0, limit = 250} = {}
) => {
  if (limit < 1)
    throw new Error('limit can not be less than 1 in getEverythingFromApi');
  query.offset = offset;
  query.limit = limit;
  query.ordering = query.ordering || 'id';

  try {
    const {results, next} = await call(query);
    const combined = [...current, ...results];

    if (!!next) {
      const nextData = api(call, query, {
        current: combined,
        offset: offset + limit,
        limit,
      });
      return nextData;
    }

    return {data: combined, error: false};
  } catch (error) {
    return {data: current, error: true};
  }
};

export default api;
