import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

// Events
import event from '../events/created.event.accidentPartyInvolved';

export default async (accidentId, accident) => {
  const response = await f3tch(`/accidents/dol/${accidentId}/party_involved/`)
    .profile(profile())
    .body(accident)
    .post();
  event.pub(response);
  return response;
};
