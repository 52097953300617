import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Components
import VehicleRegistrationCard from '../../components/VehicleRegistrationCard/VehicleRegistrationCard';

// Section
import registrationStatus from '../../libs/registrationStatus.lib.vehicle';

class VehicleRegistrationContainer extends Component {
  static propTypes = {
    vehicle: PropTypes.object,
    onComplete: PropTypes.func,
  };

  done = ({object: vehicle}) => this.props.onComplete(vehicle);

  render() {
    const {vehicle} = this.props;

    return !vehicle ? null : (
      <VehicleRegistrationCard
        vehicle={vehicle}
        status={registrationStatus(vehicle)}
        onDone={this.done}
      />
    );
  }
}

export default VehicleRegistrationContainer;
