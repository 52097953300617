export default ({
  key = '',
  label = '',
  api = () => null,
  transition = [],
  needsRentalToTransition = true,
  icon = 'warning',
}) => ({
  key,
  label,
  api,
  transition,
  icon,
  needsRentalToTransition,
});
