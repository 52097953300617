import prepare from '../../attribute/prepare.attribute';

export default async (attributes) => {
  const {settlement_pending_amount} = attributes;
  const settlementAmount = settlement_pending_amount?.api?.format() || 0;
  const required = [
    settlementAmount > 0 && 'settlement_reason',
    // 'insurance_carrier',
    // 'insurance_claim_number',
  ].filter((requirement) => !!requirement);
  const accident = prepare(attributes, {
    field: 'api',
    required,
  });
  return accident;
};
