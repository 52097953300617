import creditAmount from './creditAmount.lib.payout';
import allocationOrder from './allocationOrder.lib.payout';

// Lib
import subtract from '../../lib/subtract';

export default (transactions, returnCredit = 0, customOrder = []) => {
  const {payout, leftover} = allocationOrder(transactions, customOrder)
    .filter(({owed_to_sally}) => owed_to_sally)
    .reduce(
      (combined, current) => {
        const balance =
          current.balance < 0
            ? 0
            : current.balance > combined.leftover
            ? combined.leftover
            : current.balance;
        return {
          ...combined,
          payout: {
            ...combined.payout,
            [`${current.subtype}_amount`]: balance,
          },
          leftover: subtract(combined.leftover, balance),
        };
      },
      {payout: {}, leftover: creditAmount(transactions, returnCredit)}
    );
  return {
    ...payout,
    earnings_amount: leftover,
  };
};
