import styled from 'styled-components';

// Colors
import {lib} from '@matthahn/sally-ui';
const {
  colors: {white, grey},
} = lib;

const Header = styled.div`
  width: 100%;
  position: fixed;
  top: 110px;
  left: 0px;
  z-index: 1000;
  background: ${white};
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.05);
  border-style: solid none none none;
  border-width: 1px;
  border-color: ${grey};
`;

export default Header;
