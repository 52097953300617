import active from './active.state.driver';
import blacklisted from './blacklisted.state.driver';
import prospect from './prospect.state.driver';
import ready from './ready.state.driver';
import unknown from './unknown.state.driver';

const states = [blacklisted, active, prospect, ready].reduce(
  (combined, state) => ({...combined, [state.key]: state}),
  {}
);

export const stateList = [prospect, ready, active, blacklisted];

export default (state) => states[state] || unknown;
