import {NUMBER} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {commaSeperatedNumber, formattedNumber, number} from '../../types';

export default attribute({
  type: NUMBER,
  attribute: 'mileage',
  display: commaSeperatedNumber,
  input: formattedNumber,
  api: number,
  label: {
    default: 'Mileage',
    short: 'Mileage',
  },
});
