import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import {Avatar, TextColor} from '@matthahn/sally-ui';

const Container = styled.div`
  position: relative;
  padding-right: 20px;
  cursor: pointer;
  user-select: none;
  height: 100%;
  display: flex;
  align-items: center;
`;

const Name = styled.div`
  margin-left: 12px;
`;

const NavigationAvatar = ({avatar, onClick}) => (
  <Container onClick={onClick}>
    <Avatar avatar={avatar} theme="orange" />
    <Name>
      <TextColor theme="darkGrey">Hello</TextColor>{' '}
      <TextColor theme="darkGrey" weight="bold">
        {avatar}
      </TextColor>
    </Name>
  </Container>
);

NavigationAvatar.propTypes = {
  avatar: PropTypes.string,
  onClick: PropTypes.func,
};

export default NavigationAvatar;
