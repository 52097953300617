import {SET} from './constants';

export const set = (data = {}) => ({
  type: SET,
  data,
});

export const updateAccident = (accident) => (dispatch, getState) => {
  const oldAccident = getState().accident.accident;
  if (!accident.id || !oldAccident || accident.id !== oldAccident.id) return;
  dispatch(set({accident}));
};
