import React from 'react';

// Components
import {Container, Row, Column} from '@matthahn/sally-ui';
import PageContainer from './components/Container';

// Containers
import ApprovedChequesContainer from '../../containers/ApprovedChequesContainer/ApprovedChequesContainer';
// import ChequesPendingApprovalContainer from '../../containers/ChequesPendingApprovalContainer/ChequesPendingApprovalContainer';
// import AchsPendingApprovalContainer from '../../../ach/containers/AchsPendingApprovalContainer/AchsPendingApprovalContainer';

// driverAlert components
// import PayoutDriverAlertsCard from '../../../driverAlert/components/PayoutDriverAlertsCard/PayoutDriverAlertsCard';

// payment containers
import ApprovePaymentsContainer from '../../../payment/containers/ApprovePaymentsContainer/ApprovePaymentsContainer';

const GeneralChequeLayout = () => (
  <PageContainer>
    <Container>
      <Row margin>
        <Column size={1 / 2}>
          <ApprovePaymentsContainer />
        </Column>
        <Column size={1 / 2}>
          <ApprovedChequesContainer />
        </Column>
      </Row>
      {/* <Row>
        <Column size={1 / 2}>
          <AchsPendingApprovalContainer />
        </Column>
        <Column size={1 / 2}>
          <PayoutDriverAlertsCard />
        </Column>
      </Row> */}
    </Container>
  </PageContainer>
);

export default GeneralChequeLayout;
