const columns = [
  {
    key: 'vin',
    label: 'VIN',
    sortable: true,
  },
  {
    key: 'medallion',
    label: 'Medallion',
  },
  {
    key: 'vehicle_year_model',
    label: 'Year and Model',
  },
  {
    key: 'plate',
    label: 'Plate',
    sortable: true,
  },
];

export default columns;
