import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

export default attribute({
  type: TEXT,
  attribute: 'fhv_image_uri',
  label: {
    default: 'FHV Image URI',
    short: 'Image',
  },
});
