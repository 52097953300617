import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

// Events
import event from '../events/created.event.vehicleNote';

export default async (vehicleID, vehicleNote) => {
  const response = await f3tch(`/vehicles/${vehicleID}/notes/`)
    .profile(profile())
    .body(vehicleNote)
    .post();
  event.pub(response);
  return response;
};
