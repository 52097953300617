import styled from 'styled-components';

// colors
import {grey} from '@matthahn/sally-ui/lib/libs/colors';

const UserContainer = styled.div`
  width: 100%;
  height: 100%;
  border-left: 1px solid ${grey};
  padding-top: 53px;
  position: relative;
`;

export default UserContainer;
