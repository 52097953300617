import authToken from '../../auth/lib/token.lib.auth';

// Auth API
import url from '../constants/url.const.api'

export default ({authorize = true} = {}) => {
  const token = authToken.get();
  const h = {
    'Content-Type': 'application/json',
    'auth-url': url()
  };
  if (!!token && authorize) h.Authorization = `WEB ${token}`;
  return h;
};
