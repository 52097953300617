import {TOGGLE} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

export default attribute({
  type: TOGGLE,
  attribute: 'police_report_taken',
  label: {
    default: 'Police report taken',
    short: 'Police report taken',
  },
});
