import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Card, Form, Row, Column} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

const MedallionPaymentInfoCard = ({
  loading,
  adminEditableAttributes,
  hasPermission,
  onSave,
  onOwner,
  onChange,
  errors,

  bank_account_number,
  bank_routing_number,
  lease_rate,
  payee,
  payment_address_city,
  payment_address_state,
  payment_address_street1,
  payment_address_street2,
  payment_address_zip,
  payment_method,
}) => (
  <Card
    title="Payment Info"
    icon="creditcard"
    buttonsRight={[{label: 'Save', loading, onClick: onSave, theme: 'orange'}]}
  >
    {(Content) => (
      <Form onSubmit={onSave} enter>
        <Content>
          <Row margin>
            <Column size={1 / 2}>
              <AttributeInput
                value={payee}
                onChange={onChange}
                disabled={loading}
              >
                {payee.label.default}
              </AttributeInput>
            </Column>
            <Column size={1 / 2}>
              <AttributeInput
                value={payment_method}
                onChange={onChange}
                disabled={loading}
              >
                {payment_method.label.default}
              </AttributeInput>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 2}>
              <AttributeInput
                value={bank_account_number}
                onChange={onChange}
                disabled={loading}
              >
                {bank_account_number.label.default}
              </AttributeInput>
            </Column>
            <Column size={1 / 2}>
              <AttributeInput
                value={bank_routing_number}
                onChange={onChange}
                disabled={loading}
              >
                {bank_routing_number.label.default}
              </AttributeInput>
            </Column>
          </Row>
          <Row margin>
            <Column>
              <AttributeInput
                value={lease_rate}
                onChange={onChange}
                disabled={loading}
              >
                {lease_rate.label.default}
              </AttributeInput>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 2}>
              <AttributeInput
                value={payment_address_street1}
                onChange={onChange}
                disabled={loading}
              >
                {payment_address_street1.label.default}
              </AttributeInput>
            </Column>
            <Column size={1 / 2}>
              <AttributeInput
                value={payment_address_street2}
                onChange={onChange}
                disabled={loading}
              >
                {payment_address_street2.label.default}
              </AttributeInput>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 3}>
              <AttributeInput
                value={payment_address_zip}
                onChange={onChange}
                disabled={loading}
              >
                {payment_address_zip.label.default}
              </AttributeInput>
            </Column>
            <Column size={2 / 3}>
              <AttributeInput
                value={payment_address_city}
                onChange={onChange}
                disabled={loading}
              >
                {payment_address_city.label.default}
              </AttributeInput>
            </Column>
          </Row>
          <Row>
            <Column>
              <AttributeInput
                value={payment_address_state}
                onChange={onChange}
                disabled={loading}
              >
                {payment_address_state.label.default}
              </AttributeInput>
            </Column>
          </Row>
        </Content>
      </Form>
    )}
  </Card>
);

MedallionPaymentInfoCard.propTypes = {
  loading: PropTypes.bool,
  hasPermission: PropTypes.bool,
  adminEditableAttributes: PropTypes.array,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  onOwner: PropTypes.func,
  errors: PropTypes.array,

  medallion_number: PropTypes.object,
  medallion_type: PropTypes.object,
  medallion_expiry: PropTypes.object,
  owner: PropTypes.object,

  bank_account_number: PropTypes.object,
  bank_routing_number: PropTypes.object,
  payment_address_city: PropTypes.object,
  payment_address_state: PropTypes.object,
  payment_address_street1: PropTypes.object,
  payment_address_street2: PropTypes.object,
  payment_address_zip: PropTypes.object,
  payment_method: PropTypes.object,
};

export default MedallionPaymentInfoCard;
