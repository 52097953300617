import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/noData.profile.api';

// Events
import event from '../events/deleted.event.document';

export default async (id, jwt = null) => {
  const api = f3tch(`/documents/${id}/`).profile(profile());

  if (!!jwt) api.headers({Authorization: `JWT ${jwt}`});

  const response = await api.delete();
  event.pub(id);
  return response;
};
