import prepare from '../../attribute/prepare.attribute';

// Preparations
import readyDmv from './readyDmv.preparation.driver';

export default async (attributes) => {
  const driver = prepare(attributes, {
    field: 'api',
    required: ['state'],
  });
  if (['ready', 'reserved', 'active'].includes(driver.state))
    await readyDmv(attributes);
  return driver;
};
