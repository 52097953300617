import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Card, Form, Row, Column} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

const VehiclePurchaseCard = ({
  loading,
  adminEditableAttributes,
  onSave,
  onChange,
  errors,

  purchase_date,
  purchase_mileage,
  purchase_price,
}) => (
  <Card title="Purchase Info" icon="infocirlceo">
    {(Content) => (
      <Form onSubmit={onSave} enter>
        <Content>
          <Row margin>
            <Column>
              <AttributeInput
                value={purchase_date}
                onChange={onChange}
                errors={errors}
                disabled={loading}
                auth={adminEditableAttributes}
              >
                {purchase_date.label.default}
              </AttributeInput>
            </Column>
          </Row>
          <Row>
            <Column size={1 / 2}>
              <AttributeInput
                value={purchase_price}
                onChange={onChange}
                errors={errors}
                disabled={loading}
                auth={adminEditableAttributes}
                preValue="$"
              >
                {purchase_price.label.default}
              </AttributeInput>
            </Column>
            <Column size={1 / 2}>
              <AttributeInput
                value={purchase_mileage}
                onChange={onChange}
                errors={errors}
                disabled={loading}
                auth={adminEditableAttributes}
              >
                {purchase_mileage.label.default}
              </AttributeInput>
            </Column>
          </Row>
        </Content>
      </Form>
    )}
  </Card>
);

VehiclePurchaseCard.propTypes = {
  loading: PropTypes.bool,
  adminEditableAttributes: PropTypes.array,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  errors: PropTypes.array,

  purchase_date: PropTypes.object,
  purchase_mileage: PropTypes.object,
  purchase_price: PropTypes.object,
};

export default VehiclePurchaseCard;
