import {
  notifyChange,
  shouldNotify,
} from '../../layout/lib/unsavedChanges.lib.layout';

export default (message, callback) => {
  if (shouldNotify)
    notifyChange(
      () => callback(true),
      () => callback(false)
    );
  else callback(true);
};
