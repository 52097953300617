// Error
import {combinedError} from './error.attribute';

const combined = async (fn) => {
  try {
    const data = await fn();
    return {data, error: false};
  } catch (error) {
    return {
      data: error.fields.map((field, index) => ({
        field,
        name: error.names[index],
      })),
      error: true,
    };
  }
};

export default async (...fns) => {
  const outcome = await Promise.all(fns.map(combined));
  const {data, errorFields} = outcome.reduce(
    (combined, current) =>
      current.error
        ? {
            ...combined,
            errorFields: current.data.reduce(
              (fields, {field, name}) =>
                !!fields.find((f) => f.field === field)
                  ? fields
                  : [...fields, {field, name}],
              combined.errorFields
            ),
          }
        : {
            ...combined,
            data: [...combined.data, current.data],
          },
    {data: [], errorFields: []}
  );

  if (!!errorFields.length)
    throw combinedError({
      fields: errorFields.map(({field}) => field),
      names: errorFields.map(({name}) => name),
      message: `Please checkout the following fields: ${errorFields
        .map(({name}) => name)
        .join(', ')}`,
    });
  return data;
};
