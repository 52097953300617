import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {set as setAction} from '../../../accident/redux/actions';

// Attributes
import {dol as dolAttribute, text as textAttribute} from '../../attributes';

// Api
import {
  create as createApi,
  update as updateApi,
  delete as deleteApi,
} from '../../api';

// Preparation
import {
  create as prepareOnCreate,
  update as prepareOnUpdate,
} from '../../preparation';

// Lib
import parseError from '../../../error/parseError';
import {lib} from '@matthahn/sally-ui';

// Components
import {CardLoader} from '@matthahn/sally-ui';
import NotesCard from '../../../note/components/NotesCard/NotesCard';

// Alert
const {alert, notify} = lib;

class AccidentNotesContainer extends Component {
  static propTypes = {
    loadingNotes: PropTypes.bool,
    notes: PropTypes.array,
    accident: PropTypes.object,
    userID: PropTypes.number,
    dispatch: PropTypes.func,
  };

  state = {
    create: false,
    creating: false,
    text: textAttribute(''),
    update: [],
    updating: [],
    search: '',
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  set = (data) => this.props.dispatch(setAction(data));

  onText = (text) => this.setState({text});

  notification = (noteID) => ({
    id: `AccidentNotesContainer__${noteID}`,
    title: `Remove Note #${noteID}`,
    icon: undefined,
    content: 'Are you sure you want to delete this note?',
    primary: {
      label: 'No',
      onClick: () =>
        this.setState({
          updating: [...this.state.updating].filter((u) => u !== noteID),
        }),
    },
    secondary: {
      label: 'Yes',
      onClick: () => this.remove(noteID, false),
    },
    closable: false,
  });

  onUpdateText = (id, text) =>
    this.setState({
      update: [...this.state.update].map((u) => {
        if (u.id === id && !this.isUpdating(id)) u.text = text;
        return u;
      }),
    });

  isUpdating = (id) => this.state.updating.includes(id);

  onUpdateStart = (id) => {
    const note = [...this.props.notes].find((n) => n.id === id);
    if (!note) return;
    this.setState({
      update: [
        ...this.state.update,
        {id: note.id, text: textAttribute(note.text)},
      ],
    });
  };

  onCreateToggle = (open) =>
    this.setState({create: open, text: textAttribute('')});

  onUpdateCancel = (id) => {
    if (this.isUpdating(id)) return;
    this.setState({update: this.state.update.filter((u) => u.id !== id)});
  };

  create = async () => {
    const {notes, accident, userID} = this.props;
    const {creating, text} = this.state;
    if (creating) return;

    this.setState({creating: true});

    try {
      const note = await prepareOnCreate({
        text,
        dol: dolAttribute(accident.id),
      });
      note.owner = userID;
      const response = await createApi(accident.id, note);
      this.set({notes: [response, ...notes]});
      if (!this.mounted) return;
      this.setState({creating: false, text: textAttribute(''), create: false});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      if (!this.mounted) return;
      this.setState({creating: false});
    }
  };

  update = async (id) => {
    const {update, updating} = this.state;
    if (this.isUpdating(id) || !update.find((u) => u.id === id)) return;

    this.setState({updating: [...updating, id]});

    const {text} = update.find((u) => u.id === id);

    try {
      const note = await prepareOnUpdate({text});
      const response = await updateApi(this.props.accident.id, id, note);
      this.set({
        notes: [...this.props.notes].map((n) =>
          n.id === response.id ? response : n
        ),
      });
      if (!this.mounted) return;
      this.setState({
        update: [...this.state.update].filter((u) => u.id !== id),
        updating: [...this.state.updating].filter((u) => u !== id),
      });
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      if (!this.mounted) return;
      this.setState({
        updating: [...this.state.updating].filter((u) => u !== id),
      });
    }
  };

  remove = async (id, prompt = true) => {
    const {updating, update} = this.state;
    if (
      prompt &&
      (this.isUpdating(id) || update.find((u) => u.id === id) !== undefined)
    )
      return;

    if (prompt) {
      this.setState({updating: [...updating, id]});
      notify(this.notification(id));
      return;
    }

    try {
      await deleteApi(this.props.accident.id, id);
      this.set({notes: [...this.props.notes].filter((n) => n.id !== id)});
      if (!this.mounted) return;
      this.setState({
        updating: [...this.state.updating].filter((u) => u !== id),
      });
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      if (!this.mounted) return;
      this.setState({
        updating: [...this.state.updating].filter((u) => u !== id),
      });
    }
  };

  onSearch = (search) => this.setState({search});

  notes = () => {
    const {notes} = this.props;
    const {search} = this.state;
    const formattedSearch = `${search}`.trim().toLowerCase();
    return !!formattedSearch.length
      ? [...notes].filter((note) =>
          [note.text, `${note?.owner?.userIDname}`].some((value) =>
            value.toLowerCase().includes(formattedSearch)
          )
        )
      : notes;
  };

  render() {
    const {loadingNotes, accident} = this.props;
    if (!accident) return null;
    const {text, create, creating, update, updating, search} = this.state;
    return loadingNotes ? (
      <CardLoader />
    ) : (
      <NotesCard
        search={search}
        notes={this.notes()}
        add={create}
        adding={creating}
        edit={update}
        editing={updating}
        text={text}
        onText={this.onText}
        onAdd={() => this.onCreateToggle(true)}
        onAddCancel={() => this.onCreateToggle(false)}
        onSave={this.create}
        onUpdateStart={this.onUpdateStart}
        onUpdateCancel={this.onUpdateCancel}
        onUpdateText={this.onUpdateText}
        onEdit={this.update}
        onRemove={this.remove}
        onSearch={this.onSearch}
      />
    );
  }
}

export default connect((state) => ({
  loadingNotes: state.accident.loadingNotes,
  notes: state.accident.notes,
  accident: state.accident.accident,
  userID: state.auth.userID,
}))(AccidentNotesContainer);
