import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

// Attributes
import fhvLicenseNumberAttr from '../../../driver/attributes/fhv_license_number.attribute.driver';

// Api
import api from '../../../api/lib/getEverythingFromApi.lib.api';
import listChequesApi from '../../api/list.api.cheque';
import printChequeApi from '../../api/print.api.cheque';
import rejectChequesApi from '../../api/reject.api.cheque';

// Components
import ApprovedChequesCard from '../../components/ApprovedChequesCard/ApprovedChequesCard';
import ApprovedChequeActionModal from '../../components/ApprovedChequeActionModal/ApprovedChequeActionModal';

// Lib
import {lib} from '@matthahn/sally-ui';
import {isNull} from '../../../api/queries/queries';
import parseError from '../../../error/parseError';

// Permissions
import approvePermission from '../../permissions/approve.permission.check';

// Sockets
import chequeApprovedSocket from '../../sockets/approved.socket.cheque';
import pdfGeneratedSocket from '../../sockets/pdfGenerated.socket.cheque';
import rejectedSocket from '../../sockets/canceled.socket.cheque';
import printAttemptSocket from '../../sockets/printAttempt.socket.cheque';

// Routes
import driverRoute from '../../../driver/pages/DriverPage/route';

// Types
import {amount as amountType} from '../../../types';

// Alert
const {alert, notify} = lib;

class ApprovedChequesContainer extends Component {
  static propTypes = {
    history: PropTypes.object,
  };

  state = {
    loading: false,
    removing: false,
    printing: false,
    cheques: [],
    search: '',
    actionModalVisible: false,
    actionCheque: null,
    closable: true,
    fhvConfirmed: false,
    fhv_license_number: fhvLicenseNumberAttr(''),
  };

  componentDidMount() {
    this.mounted = true;
    this.getCheques();
    this.subscribe();
  }

  componentWillUnmount() {
    this.mounted = false;
    this.unsubscribe();
  }

  pauseEventsFor = [];

  subscribe = () => {
    this.subscriptions = [
      chequeApprovedSocket.subscribe(this.newCheque),
      pdfGeneratedSocket.subscribe(this.updateCheque),
      rejectedSocket.subscribe(this.removeChequeFromList),
      printAttemptSocket.subscribe(this.removeChequeFromList),
    ];
  };

  unsubscribe = () => {
    this.subscriptions.forEach((unsubscribe) => unsubscribe());
    this.subscriptions = [];
  };

  newCheque = (cheque) => {
    const {loading, cheques} = this.state;
    if (loading) return;
    this.setState({cheques: [cheque, ...cheques]});
  };

  updateCheque = (cheque) => {
    const {cheques} = this.state;
    this.setState({
      cheques: [...cheques].map((c) =>
        c.check_number === cheque.check_number ? cheque : c
      ),
    });
  };

  removeChequeFromList = (cheque) => {
    if (this.pauseEventsFor.includes(cheque.check_number)) return;
    this.setState({
      cheques: [...this.state.cheques].filter(
        ({check_number}) => check_number !== cheque.check_number
      ),
    });
  };

  cheques = () => {
    const {cheques, search} = this.state;
    const src = search.toLowerCase();
    return !src.trim().length
      ? cheques
      : [...cheques].filter((cheque) =>
          [
            cheque.check_number,
            !!cheque.driver &&
              `${cheque.driver.first_name} ${cheque.driver.last_name}`,
            amountType(cheque.amount).format(),
            (!!cheque.driver && cheque.driver.fhv_license_number) || null,
          ]
            .filter((q) => !!q)
            .some((query) => `${query}`.toLowerCase().includes(src))
        );
  };

  onSearch = (search) => {
    if (this.state.loading) return;
    this.setState({search});
  };

  getCheques = async () => {
    const {loading} = this.state;

    if (loading) return;

    this.setState({loading: true});

    try {
      const {data: cheques} = await api(listChequesApi, {
        [isNull('approved_by_user')]: false,
        [isNull('printed_on')]: true,
        [isNull('canceled_on')]: true,
        ordering: '-created_at',
      });
      if (!this.mounted) return;
      this.setState({
        loading: false,
        cheques: [...this.state.cheques, ...cheques],
      });
    } catch (error) {
      if (!this.mounted) return;
      this.setState({loading: false, cheques: []});
    }
  };

  onDriver = (driver) => () => {
    if (!driver) return;
    this.props.history.push(driverRoute(driver.id));
  };

  showAction = (cheque) => () =>
    this.setState({
      actionModalVisible: true,
      actionCheque: cheque,
      fhvConfirmed: false,
      fhv_license_number: fhvLicenseNumberAttr(''),
    });

  hideAction = () => {
    if (this.state.removing || this.state.printing || !this.state.closable)
      return;
    this.setState({actionModalVisible: false});
  };

  onFhvChange = (fhv_license_number) => this.setState({fhv_license_number});

  onCredentials = (val, key) => {
    const {removing, printing} = this.state;
    if (removing || printing) return;
    this.setState({[key]: val});
  };

  checkFhv = () => {
    const {actionCheque, fhv_license_number} = this.state;
    if (!actionCheque) return;
    if (!actionCheque.driver) return alert.info('Refresh page');
    if (
      fhv_license_number.api.format() !== actionCheque.driver.fhv_license_number
    )
      return alert.warning('Invalid hack number');
    this.setState({fhvConfirmed: true});
  };

  remove = async (prompt = true) => {
    const {removing, printing, actionCheque} = this.state;

    if (removing || printing) return;

    if (!approvePermission())
      return alert.warning('You do not have permission to perform this');

    if (prompt) {
      await this.setState({closable: false});
      return notify({
        id: 'removeCheque',
        title: 'Remove',
        icon: undefined,
        content: 'Are you sure you want to remove this cheque?',
        primary: {
          label: 'No',
          onClick: () => this.setState({closable: true}),
        },
        secondary: {
          label: 'Yes',
          onClick: () => this.remove(false),
        },
        onClose: () => this.setState({closable: true}),
        closable: false,
        closeOnOutsideClick: true,
      });
    }

    this.setState({removing: true, closable: true});

    this.pauseEventsFor = [actionCheque.check_number];

    try {
      await rejectChequesApi([{check_number: actionCheque.check_number}]);
      this.setState({
        removing: false,
        cheques: [...this.state.cheques].filter(
          ({check_number}) => check_number !== actionCheque.check_number
        ),
        actionModalVisible: false,
        actionCheque: null,
      });
      alert.success('Cheque removed');
    } catch (error) {
      if (!this.mounted) return;
      const {message} = parseError(error);
      alert.error(message);
      this.setState({removing: false});
    }

    this.pauseEventsFor = [];
  };

  print = async () => {
    const {actionCheque: cheque, removing, printing} = this.state;
    if (!cheque || removing || printing) return;
    if (!cheque.check_pdf) return alert.warning('Cheque not generated yet');

    this.setState({printing: true});
    this.pauseEventsFor = [cheque.check_number];

    try {
      await printChequeApi([{check_number: cheque.check_number}]);
      if (!this.mounted) return;
      window.open(cheque.check_pdf, '_blank');
      this.setState({
        printing: false,
        actionModalVisible: false,
        actionCheque: null,
        cheques: [...this.state.cheques].filter(
          ({check_number}) => check_number !== cheque.check_number
        ),
      });
    } catch (error) {
      if (!this.mounted) return;
      const {message} = parseError(error);
      alert.error(message);
      this.setState({printing: false});
    }

    this.pauseEventsFor = [];
  };

  render() {
    const {
      loading,
      search,
      removing,
      printing,
      actionModalVisible,
      actionCheque,
      // fhvConfirmed,
      fhv_license_number,
    } = this.state;
    return (
      <Fragment>
        <ApprovedChequesCard
          loading={loading}
          cheques={this.cheques()}
          search={search}
          onSearch={this.onSearch}
          onAction={this.showAction}
          onDriver={this.onDriver}
        />
        <ApprovedChequeActionModal
          removing={removing}
          printing={printing}
          visible={actionModalVisible}
          cheque={actionCheque}
          // fhvConfirmed={fhvConfirmed}
          fhvConfirmed
          fhv_license_number={fhv_license_number}
          onFhvChange={this.onFhvChange}
          onClose={this.hideAction}
          onPrint={this.print}
          onRemove={this.remove}
          onFhvCheck={this.checkFhv}
          onChange={this.onCredentials}
        />
      </Fragment>
    );
  }
}

export default withRouter(ApprovedChequesContainer);
