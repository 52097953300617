export default [
  {
    key: 'pickup',
    label: 'Start',
  },
  {
    key: 'dropoff',
    label: 'End',
  },
  {
    key: 'medallion',
    label: 'Medallion',
  },
  {
    key: 'contract_type',
    label: 'Rental Type',
  },
];
