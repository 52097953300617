// date
import {subDays} from 'date-fns';
import utc from '../../lib/utc';

// types
import apiDateTime from '@matthahn/sally-fw/lib/type/types/apiDateTime.type';

const getRecentQueryDate = ({today = new Date()} = {}) =>
  utc(apiDateTime(subDays(today, 30)).format());

export default getRecentQueryDate;
