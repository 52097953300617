import React from 'react';
import PropTypes from 'prop-types';

// Attributes
import nameAttr from '../../attributes/name.attribute.medallionOwner';
import fedIDAttr from '../../attributes/taxx_id.attribute.medallionOwner';

// Components
import {Card, Row, Column} from '@matthahn/sally-ui';
import AttributeText from '../../../layout/components/AttributeText/AttributeText';

const BasicInfo = ({name, taxx_id, onEdit}) => (
  <Card
    title="Basic Info"
    icon="infocirlceo"
    headerActions={[
      {
        icon: 'edit',
        onClick: onEdit,
        tooltip: 'Edit',
      },
    ]}
  >
    {(Content) => (
      <Content>
        <Row margin>
          <Column>
            <AttributeText attribute={nameAttr(name)}>
              {nameAttr.label.default}
            </AttributeText>
          </Column>
        </Row>
        <Row>
          <Column>
            <AttributeText attribute={fedIDAttr(taxx_id)}>
              {fedIDAttr.label.default}
            </AttributeText>
          </Column>
        </Row>
      </Content>
    )}
  </Card>
);

BasicInfo.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  taxx_id: PropTypes.string,
  onEdit: PropTypes.func,
};

export default BasicInfo;
