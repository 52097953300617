import React from 'react';
import PropTypes from 'prop-types';

// Components
import VehicleContainer from '../../containers/VehicleContainer/VehicleContainer';

const VehiclePage = ({
  match: {
    params: {id},
  },
}) => <VehicleContainer id={id} />;

VehiclePage.propTypes = {
  match: PropTypes.object,
};

export default VehiclePage;
