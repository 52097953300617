import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

export default (noClaimReason) =>
  f3tch(`/accidents/dol/noclaim_reason/`)
    .profile(profile())
    .body(noClaimReason)
    .post();
