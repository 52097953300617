const error = (type = 'generalError', {defaultMessage = null} = {}) => {
  if (!type) throw new Error('Please specify the error type');
  const fn = ({
    fields = [],
    names = [],
    message = defaultMessage || `Error of type "${type}" occured.`,
    origin = null,
  } = {}) => ({
    type,
    fields,
    names,
    message,
    origin,
  });
  fn.type = type;
  return fn;
};

export default error;
