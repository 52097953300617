import React from 'react';
import PropTypes from 'prop-types';

// Constants
import WEEKLY_DRIVER_ICON from '../../constants/weeklyDriverIcon.contrants.driver';

// Components
import {TextColor, Icon} from '@matthahn/sally-ui';
import Container from './components/Container';
import Flex from './components/Flex';
import Name from './components/Name';
import Tag from './components/Tag';

// Lease
import isWeeklyLease from '../../../lease/lib/isWeekly.lib.lease';

const DriverTags = ({inline, driver, children, withoutWeeklyIcon}) =>
  !driver ? null : (
    <Container inline={inline}>
      <Flex>
        <Name>{children}</Name>
        {!withoutWeeklyIcon && !!driver.lease && isWeeklyLease(driver.lease) && (
          <Tag>
            <TextColor theme="darkGrey">
              <Icon icon={WEEKLY_DRIVER_ICON} />
            </TextColor>
          </Tag>
        )}
      </Flex>
    </Container>
  );

DriverTags.propTypes = {
  inline: PropTypes.bool,
  withoutWeeklyIcon: PropTypes.bool,
  driver: PropTypes.object,
  children: PropTypes.node,
};

DriverTags.defaultProps = {
  children: null,
  withoutWeeklyIcon: false,
};

export default DriverTags;
