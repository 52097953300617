import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {kustomerID} from '../../types';

export default attribute({
  type: TEXT,
  attribute: 'kustomer_id',
  display: kustomerID,
  input: kustomerID,
  api: kustomerID,
  label: {
    default: 'Kustomer ID',
    short: 'Kustomer',
  },
});
