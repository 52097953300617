import isNumber from 'is-number';
import isString from 'is-string';

import type from '../type';

const format = (value) =>
  isString(value) || isNumber(value)
    ? `${value}`.replace(/\D+/g, '').slice(0, 10)
    : null;

const validate = (value) => /^\d{10}$/.test(value);

export default type({type: 'string', format, validate});
