import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Input Types
import {
  DATE,
  DATETIME,
  NUMBER,
  SELECT,
  SWITCH,
  TEXT,
  TIME,
  TOGGLE,
} from '../../../inputTypes';

// Components
import {Text} from '@matthahn/sally-ui';

class AttributeText extends Component {
  static propTypes = {
    attribute: PropTypes.object.isRequired,
    empty: PropTypes.node,
    emptyZero: PropTypes.bool,
  };

  static defaultProps = {
    empty: '-',
    emptyZero: false,
  };

  static OPTIONS = {
    [DATE]: {postIcon: 'calendar'},
    [DATETIME]: {postIcon: 'calendar'},
    [NUMBER]: {},
    [SELECT]: {},
    [SWITCH]: {},
    [TEXT]: {},
    [TIME]: {postIcon: 'clockcircleo'},
    [TOGGLE]: {},
  };

  render() {
    const {attribute, empty, children, emptyZero, ...props} = this.props;
    const value = attribute.display.format();
    return (
      <Text
        value={!!value && value !== '0' ? value : empty}
        {...this.constructor.OPTIONS[this.props.attribute.type]}
        {...props}
      >
        {children}
      </Text>
    );
  }
}

export default AttributeText;
