import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

// Events
import event from '../events/created.event.driver';

export default async (driver) => {
  const response = await f3tch('/drivers/')
    .profile(profile())
    .body(driver)
    .post();
  event.pub(response);
  return response;
};
