import {TOGGLE} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

export default attribute({
  type: TOGGLE,
  attribute: 'sameAsDriverInfo',
  label: {
    default: 'Same as driver',
    short: 'Same as driver',
  },
});
