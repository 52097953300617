import isNumber from 'is-number';

import type from '../type';

const format = (input) =>
  Number(!input ? '0' : `${input}`.replace(/[^0-9]/g, '') || '0');

const validate = (value) => isNumber(value) && (value * 1) % 25 === 0;

export default type({type: 'number', format, validate});
