import {SET} from './constants';

export const set = (data = {}) => ({
  type: SET,
  data,
});

export const updateDriver = (driver) => (dispatch, getState) => {
  const oldDriver = getState().driver.driver;
  if (!driver.id || !oldDriver || driver.id !== oldDriver.id) return;
  dispatch(set({driver}));
};
