import React from 'react';
import {connect} from 'react-redux';

// Components
import {Row, Column} from '@matthahn/sally-ui';
import SubPage from '../../../layout/components/SubPage/SubPage';

// Containers
import DriverPaymentActionsContainer from '../../containers/DriverPaymentActionsContainer/DriverPaymentActionsContainer';
import DriverTransactionsContainer from '../../containers/DriverTransactionsContainer/DriverTransactionsContainer';
import ActiveDriverRentalContainer from '../../../rental/containers/ActiveDriverRentalContainer/ActiveDriverRentalContainer';
import DriverBlacklistedInfo from '../../components/DriverBlacklistedInfo/DriverBlacklistedInfo';
import BankAccountsContainer from '../../../bankAccount/containers/BankAccountsContainer/BankAccountsContainer';

// driverAlert containers
import DriverAlertsContainer from '../../../driverAlert/containers/DriverAlertsContainer/DriverAlertsContainer';

// State
import blacklistedState from '../../state/blacklisted.state.driver';

const DriverPaymentsLayout = ({driver}) => (
  <SubPage>
    {driver.state === blacklistedState.key && (
      <Row margin>
        <Column>
          <DriverBlacklistedInfo driver={driver} />
        </Column>
      </Row>
    )}
    <DriverAlertsContainer headless>
      {({dom}) =>
        !!dom ? (
          <Row margin>
            <Column>{dom}</Column>
          </Row>
        ) : null
      }
    </DriverAlertsContainer>
    <Row>
      <Column size={1 / 3}>
        <Row margin>
          <Column>
            <DriverPaymentActionsContainer />
          </Column>
        </Row>
        <Row margin>
          <Column>
            <BankAccountsContainer driver={driver} />
          </Column>
        </Row>
        <ActiveDriverRentalContainer>
          {({content}) => (
            <Row margin>
              <Column>{content}</Column>
            </Row>
          )}
        </ActiveDriverRentalContainer>
      </Column>
      <Column size={2 / 3}>
        <DriverTransactionsContainer />
      </Column>
    </Row>
  </SubPage>
);

export default connect((state) => ({
  driver: state.driver.driver,
  driverAlerts: state.driver.driverAlerts,
}))(DriverPaymentsLayout);
