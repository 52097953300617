import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Api
import updateApi from '../../api/update.api.medallionOwner';

// Attributes
import poc1Attr from '../../attributes/poc1.attribute.medallionOwner';
import addressStreet1Attr from '../../attributes/poc1_address_street1.attribute.medallionOwner';
import addressStreet2Attr from '../../attributes/poc1_address_street2.attribute.medallionOwner';
import addressCityAttr from '../../attributes/poc1_address_city.attribute.medallionOwner';
import addressZipAttr from '../../attributes/poc1_address_zip.attribute.medallionOwner';
import addressStateAttr from '../../attributes/poc1_address_state.attribute.medallionOwner';
import phoneNumberAttr from '../../attributes/poc1_phone_number.attribute.medallionOwner';
import emailAttr from '../../attributes/poc1_email.attribute.medallionOwner';
import notesAttr from '../../attributes/poc1_notes.attribute.medallionOwner';

// Components
import Poc1 from '../../components/Poc1/Poc1';
import Poc1Edit from '../../components/Poc1Edit/Poc1Edit';

// Libs
import {lib} from '@matthahn/sally-ui';
import parseError from '../../../error/parseError';

// Prep
import updatePrep from '../../preparation/updatePoc1.preparation.medallionOwner';

// Notifications
const {alert} = lib;

class Poc1Container extends Component {
  static propTypes = {
    medallionOwner: PropTypes.object,
    onUpdate: PropTypes.func,
  };

  static defaultProps = {
    onUpdate: () => {},
  };

  state = {
    loading: false,
    edit: false,
    errors: [],
    poc1: poc1Attr(''),
    poc1_address_street1: addressStreet1Attr(''),
    poc1_address_street2: addressStreet2Attr(''),
    poc1_address_city: addressCityAttr(''),
    poc1_address_zip: addressZipAttr(''),
    poc1_address_state: addressStateAttr(''),
    poc1_email: emailAttr(''),
    poc1_phone_number: phoneNumberAttr(''),
    poc1_notes: notesAttr(''),
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  startEdit = () => {
    const {medallionOwner} = this.props;
    if (this.state.loading) return;
    this.setState({
      edit: true,
      poc1: poc1Attr(medallionOwner.poc1 || ''),
      poc1_address_street1: addressStreet1Attr(
        medallionOwner.poc1_address_street1 || ''
      ),
      poc1_address_street2: addressStreet2Attr(
        medallionOwner.poc1_address_street2 || ''
      ),
      poc1_address_city: addressCityAttr(
        medallionOwner.poc1_address_city || ''
      ),
      poc1_address_zip: addressZipAttr(medallionOwner.poc1_address_zip || ''),
      poc1_address_state: addressStateAttr(
        medallionOwner.poc1_address_state || ''
      ),
      poc1_email: emailAttr(medallionOwner.poc1_email || ''),
      poc1_phone_number: phoneNumberAttr(
        medallionOwner.poc1_phone_number || ''
      ),
      poc1_notes: notesAttr(medallionOwner.poc1_notes || ''),
    });
  };

  stopEdit = () => {
    if (this.state.loading) return;
    this.setState({edit: false, errors: []});
  };

  onChange = (val, key, errors) => {
    if (this.state.loading) return;
    this.setState({[key]: val, errors});
  };

  update = async () => {
    const {onUpdate} = this.props;
    const {loading, edit, errors, ...attributes} = this.state;
    if (loading) return;

    this.setState({loading: true});

    try {
      const obj = await updatePrep(attributes);
      const medallionOwner = await updateApi(this.props.medallionOwner.id, obj);
      onUpdate(medallionOwner);
      if (!this.mounted) return;
      this.setState({loading: false, edit: false});
    } catch (error) {
      if (!this.mounted) return;
      const {fields: errors, message} = parseError(error);
      alert.error(message);
      this.setState({loading: false, errors});
    }
  };

  render() {
    const {medallionOwner} = this.props;
    const {
      loading,
      edit,
      errors,
      poc1,
      poc1_address_street1,
      poc1_address_street2,
      poc1_address_city,
      poc1_address_zip,
      poc1_address_state,
      poc1_email,
      poc1_phone_number,
      poc1_notes,
    } = this.state;
    return edit ? (
      <Poc1Edit
        loading={loading}
        errors={errors}
        poc1={poc1}
        poc1_address_street1={poc1_address_street1}
        poc1_address_street2={poc1_address_street2}
        poc1_address_city={poc1_address_city}
        poc1_address_zip={poc1_address_zip}
        poc1_address_state={poc1_address_state}
        poc1_email={poc1_email}
        poc1_phone_number={poc1_phone_number}
        poc1_notes={poc1_notes}
        onCancel={this.stopEdit}
        onChange={this.onChange}
        onSave={this.update}
      />
    ) : (
      <Poc1 {...medallionOwner} onEdit={this.startEdit} />
    );
  }
}

export {Poc1Container};

export default connect((state) => ({
  medallionOwner: state.medallionOwner.object,
}))(Poc1Container);
