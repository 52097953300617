import {fileName, folder} from '../../../document';

// Attributes
import expiryAttr from '../../attributes/registration_expiry.attribute.vehicle';
import plateAttr from '../../attributes/plate.attribute.vehicle';
import plateConfirmAttr from '../../attributes/plateConfirm.attribute.vehicle';
import registrationAttr from '../../attributes/registration.attribute.vehicle';
import registrationControlNumberAttr from '../../attributes/registration_control_number.attribute.vehicle';
import registrationControlNumberConfirmAttr from '../../attributes/registration_control_number_confirm.attribute.vehicle';
import registrationCostAttr from '../../attributes/registration_cost.attribute.vehicle';
import registrationExpiryAttr from '../../attributes/registration_expiry_confirm.attribute.vehicle';
import vinLastSevenAttr from '../../attributes/vinLastSeven.attribute.vehicle';

import {effective_date as effectiveAttr} from '../../../document/attributes';

// Errors
import registrationExpiryMissmatch from '../../errors/registrationExpiryMissmatch.error.vehicle';
import registrationControlNumberMissmatch from '../../errors/registrationControlNumberMissmatch.error.vehicle';
import plateMissmatch from '../../errors/plateMissmatch.error.vehicle';
import vinMissmatch from '../../errors/vinMissmatch.error.vehicle';

// Lib
import vinValidation from '../../libs/vinValidation.lib.vehicle';

export default folder({
  attributes: [
    vinLastSevenAttr,
    plateAttr,
    plateConfirmAttr,
    registrationAttr,
    effectiveAttr,
    expiryAttr,
    registrationExpiryAttr,
    registrationControlNumberAttr,
    registrationControlNumberConfirmAttr,
    registrationCostAttr,
  ],
  fileAttributes: [
    'vinLastSeven',
    'plateConfirm',
    'effective_date',
    'registration_cost',
  ],
  defaultFileName: 'Registration',
  // REGISTRATION - VIN - REGISTRATION DATE
  fileName: fileName(
    ({vehicle, registration_expiry}) =>
      `REGISTRATION - ${vehicle.vin} - ${registration_expiry.display.format()}`
  ),
  folderName: 'Registrations',
  preSaveValidation: ({
    vehicle,
    vinLastSeven,
    plate,
    plateConfirm,
    registration_control_number,
    registration_control_number_confirm,
    registration_expiry,
    registration_expiry_confirm,
  }) => {
    if (vinValidation(vehicle, vinLastSeven.api.format()))
      throw vinMissmatch({
        message: 'Last 7 VIN characters are incorrect',
      });

    if (plateConfirm.api.format() !== plate.api.format())
      throw plateMissmatch({
        message: 'Plates need to match',
      });

    if (
      registration_control_number.api.format() !==
      registration_control_number_confirm.api.format()
    )
      throw registrationControlNumberMissmatch({
        message: 'Control numbers need to match',
      });

    if (
      registration_expiry.api.format() !==
      registration_expiry_confirm.api.format()
    )
      throw registrationExpiryMissmatch({
        message: 'Expiry dates need to match',
      });
  },
  type: 'registration',
});
