import prepare from '../../attribute/prepare.attribute';

const ADDRESS_FIELDS = [
  'address_street1',
  'address_city',
  'address_zip',
  'address_state',
];

export default async (attributes) => {
  const prepared = prepare(attributes, {field: 'api'});
  if (
    ![...ADDRESS_FIELDS].reduce(
      (combined, field) => (!prepared[field] ? combined : [...combined, field]),
      []
    ).length
  )
    return;

  prepare(attributes, {
    field: 'api',
    required: [...ADDRESS_FIELDS],
  });
};
