import {folderTree as createFolderTree} from '../../document';

// Files
import {
  contract,
  // leaseContract,
  dmv,
  fhv,
  other,
  payout,
  ticket,
  w9,
} from './folders';

// Bundles
import * as rawBundles from './bundles';

const {folders, flatTree, foldersObject, uiDocs} = createFolderTree([
  contract(),
  // leaseContract(),
  dmv(),
  fhv(),
  payout(),
  ticket(),
  w9(),
  other(),
]);

export const bundlesList = Object.values(rawBundles);
export const bundles = rawBundles;

export {foldersObject, flatTree, uiDocs};

export default folders;
