import styled from 'styled-components';

// Colors
import {black, white} from '@matthahn/sally-ui/lib/libs/colors';

const Date = styled.div`
  position: absolute;
  left: 50%;
  bottom: 10px;
  font-size: 12px;
  font-weight: 700;
  color: ${white};
  background: ${black};
  background: black;
  padding: 5px;
  border-radius: 3px;
  pointer-events: none;
  transform: translateX(-50%);
`;

export default Date;
