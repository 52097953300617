import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// Api
import listRawTransactionsApi from '../../../transaction/api/list.api.transaction';
import listTransactionsApi from '../../../transaction/api/tripGroupedRoot.api.transaction';
import listTripTransactionsApi from '../../../transaction/api/trips.api.transaction';
import yearlyRentStatementApi from '../../api/yearlyRentStatement.api.driver';

// Components
import DriverTransactionsCard from '../../components/DriverTransactionsCard/DriverTransactionsCard';

// Containers
import TripTransactionContainer from '../../../transaction/containers/TripTransactionContainer/TripTransactionContainer';

// driver containers
import DriverStatementContainer from '../DriverStatementContainer/DriverStatementContainer';

// Error
import parseError from '../../../error/parseError';

// Events
import transactionCreatedEvt from '../../../transaction/events/create.event.transaction';

// Sockets
import payoutSocket from '../../../rental/sockets/payout.socket.rental';
import rentalEndedSocket from '../../../rental/sockets/ended.socket.rental';
import tripCreatedSocket from '../../../trip/sockets/created.socket.trip';
import tripUpdatedSocket from '../../../trip/sockets/updated.socket.trip';
import tripDeletedSocket from '../../../trip/sockets/deleted.socket.trip';

// Lib
import downloadFile from '../../../lib/downloadFile';
import fkOrId from '../../../lib/fkOrId';
import parseTransactions from '../../../transaction/lib/parseTripGrouped.lib.transaction';
import getStatementYear from '../../lib/getStatementYear.lib.driver';

// Query
import {isNull, isIn, isEqual, negate} from '../../../api/queries/queries';

class DriverTransactionsContainer extends Component {
  static propTypes = {
    driver: PropTypes.object,
  };

  static PER_PAGE = 20;

  state = {
    loading: false,
    transactions: [],
    loadingStatement: false,
    page: 1,
    more: false,
    showTransaction: false,
    transaction: null,
    filter: 'rentCharges',
    statementVisible: false,
  };

  componentDidMount() {
    this.mounted = true;
    this.getTransactions();
    this.events = [transactionCreatedEvt.sub(this.reloadTransactions)];
    this.sockets = [
      payoutSocket.subscribe(this.onPayout),
      rentalEndedSocket.subscribe(this.onRentalEnd),
      tripCreatedSocket.subscribe(this.onTrip),
      tripUpdatedSocket.subscribe(this.onTrip),
      tripDeletedSocket.subscribe(this.onTrip),
    ];
  }

  componentWillUnmount() {
    this.mounted = false;
    [...this.events, ...this.sockets].forEach((unsub) => unsub());
  }

  filters = [
    {
      key: 'rentCharges',
      label: 'Rent',
      api: (query) =>
        listRawTransactionsApi({
          driver: this.props.driver.id,
          [isNull('trip')]: true,
          [isIn('type')]: 'charge,credit',
          subtype: 'rent',
          [isNull('parents')]: true,
          ...query,
        }),
    },
    {
      key: 'charges',
      label: 'Charges, Credits & Payouts',
      api: (query) =>
        listRawTransactionsApi({
          driver: this.props.driver.id,
          [isNull('trip')]: true,
          [isIn('type')]: 'charge,credit,payout',
          [isNull('parents')]: true,
          [negate(isEqual('trigger'))]: 'TransactionMergeCharge',
          ...query,
        }),
    },
    {
      key: 'tickets',
      label: 'Tickets & Tolls',
      api: (query) =>
        listRawTransactionsApi({
          driver: this.props.driver.id,
          [isNull('trip')]: true,
          [isIn('subtype')]: 'ticket,ezpass',
          [isNull('parents')]: true,
          ...query,
        }),
    },
    {
      key: 'trips',
      label: 'Trips & voids',
      api: (query) =>
        listTripTransactionsApi(this.props.driver.id, {
          ...query,
        }),
    },
    {
      key: 'all',
      label: 'All',
      api: (query) => listTransactionsApi(this.props.driver.id, {...query}),
    },
  ];

  onTrip = (trip) => {
    if (!trip || this.props.driver.id !== fkOrId(trip.driver)) return;
    this.reloadTransactions();
  };

  onPayout = ({payout}) => {
    const {driver} = this.props;
    if (!driver || driver.id !== fkOrId(payout.driver)) return;
    this.reloadTransactions();
  };

  onRentalEnd = (rental) => {
    const {driver} = this.props;
    if (!driver || driver.id !== fkOrId(rental.driver)) return;
    this.reloadTransactions();
  };

  onFilter = (filter) => () =>
    this.getTransactions({page: 1, reload: true, filter});

  reloadTransactions = () => this.getTransactions({page: 1, reload: true});

  onMore = () => {
    const {page, pages} = this.state;
    if (page >= pages) return;
    this.getTransactions({page: page + 1});
  };

  showTransaction = (transaction) => () =>
    this.setState({showTransaction: true, transaction});

  hideTransaction = () => this.setState({showTransaction: false});

  onTransactionUpdate = (transaction) => {
    const transactions = parseTransactions(
      [...this.state.transactions].map((t) =>
        t.id === transaction.id ? transaction : t
      )
    );
    this.setState({transactions});
  };

  getTransactions = async ({
    page = this.state.page,
    reload = false,
    filter = this.state.filter,
  } = {}) => {
    const {PER_PAGE} = this.constructor;
    const {loading} = this.state;

    if (loading) return;

    this.setState({
      loading: true,
      transactions: reload ? [] : [...this.state.transactions],
      page,
      filter,
    });

    const api = [...this.filters].find((f) => f.key === filter).api;

    try {
      const {results: rawTransactions, next} = await api({
        offset: (page - 1) * PER_PAGE,
        limit: PER_PAGE,
        ordering: '-posted_at',
      });
      const transactions = parseTransactions(rawTransactions);
      if (!this.mounted) return;
      this.setState({
        loading: false,
        transactions: reload
          ? transactions
          : [...this.state.transactions, ...transactions],
        more: !!next,
      });
    } catch (error) {
      if (!this.mounted) return;
      this.setState({loading: false, transactions: []});
    }
  };

  showStatement = () => {
    this.setState({statementVisible: true});
  };

  hideStatement = () => {
    this.setState({statementVisible: false});
  };

  getStatement = async () => {
    const {driver} = this.props;
    const {loadingStatement} = this.state;

    if (loadingStatement) return;

    this.setState({loadingStatement: true});

    try {
      const statement = await yearlyRentStatementApi(
        driver.id,
        getStatementYear()
      );
      if (!this.mounted) return;
      downloadFile(
        statement,
        `${driver.first_name} ${driver.last_name} - ${getStatementYear()}.pdf`
      );
      this.setState({loadingStatement: false});
    } catch (error) {
      if (!this.mounted) return;
      this.setState({loadingStatement: false});
      const {message} = parseError(error);
      alert.error(message);
    }
  };

  render() {
    const {driver} = this.props;
    const {
      loading,
      loadingStatement,
      transactions,
      more,
      showTransaction,
      transaction,
      filter,
      statementVisible,
    } = this.state;
    return (
      <Fragment>
        <DriverTransactionsCard
          loading={loading}
          loadingStatement={loadingStatement}
          transactions={transactions}
          statementYear={getStatementYear()}
          filter={filter}
          filters={this.filters}
          onFilter={this.onFilter}
          more={more}
          onMore={this.onMore}
          onTransaction={this.showTransaction}
          onStatement={this.showStatement}
        />
        <TripTransactionContainer
          visible={showTransaction}
          transaction={transaction}
          query={{driver: driver.id}}
          onClose={this.hideTransaction}
          onTransactionUpdate={this.onTransactionUpdate}
        />
        <DriverStatementContainer
          driver={driver}
          visible={statementVisible}
          onClose={this.hideStatement}
        />
      </Fragment>
    );
  }
}

export default connect((state) => ({driver: state.driver.driver}))(
  DriverTransactionsContainer
);
