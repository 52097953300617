import styled from 'styled-components';

const TopRight = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default TopRight;
