import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {string} from '../../types';

export default attribute({
  type: TEXT,
  attribute: 'police_report_number',
  display: string,
  input: string,
  api: string,
  label: {
    default: 'Police Report Number',
    short: 'Police Report Number',
  },
});
