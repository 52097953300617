import prepare from '../../attribute/prepare.attribute';
import combine from '../../attribute/combinePreparation.attribute';

// Constnats
import updateAttributes from '../constants/updateAttributes.constants.driver';

// Preparations
import address from './address.preparation.driver';
import ready from './ready.preparation.driver';
import dmv from './dmv.preparation.driver';
import fhv from './fhv.preparation.driver';

export default async (attributes) => {
  const [driver] = await combine(
    async () =>
      prepare(attributes, {
        field: 'api',
        required: ['state'],
      }),
    async () =>
      prepare(attributes, {
        field: 'api',
        required: [...updateAttributes],
      }),
    async () => await dmv(attributes),
    async () => await fhv(attributes),
    async () => await address(attributes),
    !!attributes.state &&
      ['ready', 'reserved', 'active'].includes(attributes.state.value())
      ? async () => await ready(attributes)
      : async () => null
  );
  return driver;
};
