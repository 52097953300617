import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {Link} from 'react-router-dom';
import isFunction from 'is-function';

// Components
import {Icon} from '@matthahn/sally-ui';

// Libs
import {lib} from '@matthahn/sally-ui';

// Colors
const {
  colors: {darkGrey, grey, red, white},
} = lib;

const style = ({live}) => css`
  width: 60px;
  height: 100%;
  border-style: none none none solid;
  border-width: 1px;
  border-color: ${live ? grey : darkGrey};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  marign: 0px;
  padding: 0px;
  color: ${live ? darkGrey : darkGrey};
  font-size: 20px;
  position: relative;
`;

const Container = styled.div`
  ${style}
`;

const StyledLink = styled(Link)`
  ${style}
`;

const Badge = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${red};
  color: ${white};
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 10px;
  font-weight: 500;
`;

const Search = ({live, badge, icon, onClick}) =>
  isFunction ? (
    <Container live={live} onClick={onClick}>
      {!!badge && <Badge>{badge > 9 ? '9+' : badge}</Badge>}
      <Icon icon={icon} />
    </Container>
  ) : (
    <StyledLink live={live} to={onClick}>
      {!!badge && <Badge>{badge > 9 ? '9+' : badge}</Badge>}
      <Icon icon={icon} />
    </StyledLink>
  );

Search.propTypes = {
  live: PropTypes.bool,
  icon: PropTypes.string,
  badge: PropTypes.number,
  onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

export default Search;
