import PropTypes from 'prop-types';

import paymentType from '../../lib/paymentType.lib.trip';

const TripPaymentType = ({type}) => paymentType({type});

TripPaymentType.propTypes = {
  type: PropTypes.string,
};

export default TripPaymentType;
