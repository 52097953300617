import extractValue from '../../attribute/extractValue.attribute';

import type from '../type';

// import dmvLicense from './dmvLicense.type';
import dmvNJLicense from './dmvNJLicense.type';
import apiDmvNYLicense from './apiDmvNYLicense.type';

const stateBasedFn = (dmv_license_state, fn) => {
  const state = extractValue(dmv_license_state);

  switch (state) {
    case 'NY':
      return apiDmvNYLicense[fn];
    default:
      return dmvNJLicense[fn];
    // default:
    //   return dmvLicense[fn];
  }
};

const format = (input, prev, {dmv_license_state = 'default'} = {}) =>
  stateBasedFn(dmv_license_state, 'format')(input, prev);

const validate = (value, prev, {dmv_license_state = 'default'} = {}) =>
  stateBasedFn(dmv_license_state, 'validate')(value);

export default type({type: 'string', format, validate});
