import {DATETIME} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {dateTime, apiDateTime} from '../../types';

export default attribute({
  type: DATETIME,
  attribute: 'violation_datetime',
  display: dateTime,
  input: dateTime,
  api: apiDateTime,
  label: {
    default: 'Violation Date',
    short: 'Violation Date',
  },
  disabledDates: [],
});
