import isString from 'is-string';

import type from '../type';

const format = (value) =>
  isString(value)
    ? value
        .toUpperCase()
        .replace(/[^A-NP-Z0-9]/gi, '')
        .slice(0, 17)
    : '';

const validate = (value) => /^[A-Z0-9]{17}$/.test(value);

export default type({type: 'string', format, validate});
