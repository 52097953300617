import isString from 'is-string';

import type from '../type';

const format = (value) =>
  isString(value)
    ? value
        .replace(/[^0-9]/gi, '')
        .slice(0, 10)
        .toUpperCase()
    : '';

const validate = (value) => /^[0-9]{10}$/.test(value);

export default type({type: 'string', format, validate});
