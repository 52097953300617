const getUserName = (user) => {
  if (!user) return '-';
  const {first_name, last_name, email} = user;
  return (
    (!!first_name || !!last_name
      ? [first_name, last_name].filter((name) => !!name).join(' ')
      : email) || '-'
  );
};

export default getUserName;
