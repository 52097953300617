// alert events
import showAlertCreateModalEvent from '../../alert/events/showCreateModal.event.alert';

// driverAlert api
import createDriverAlertApi from '../api/create.api.driverAlert';

const showDriverAlertCreateModal = ({driver}) =>
  showAlertCreateModalEvent.publish({
    object: driver,
    objectType: 'driver',
    saveApi: createDriverAlertApi,
  });

export default showDriverAlertCreateModal;
