import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Components
import VehicleStateInspectionCard from '../../components/VehicleStateInspectionCard/VehicleStateInspectionCard';

// Lib
import stateInspectionStatus from '../../libs/stateInspectionStatus.lib.vehicle';

class VehicleStateInspectionContainer extends Component {
  static propTypes = {
    vehicle: PropTypes.object,
    onComplete: PropTypes.func,
  };

  done = ({object: vehicle}) => this.props.onComplete(vehicle);

  render() {
    const {vehicle} = this.props;

    return !vehicle ? null : (
      <VehicleStateInspectionCard
        vehicle={vehicle}
        status={stateInspectionStatus(vehicle)}
        onDone={this.done}
      />
    );
  }
}

export default VehicleStateInspectionContainer;
