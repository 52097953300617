export default [
  {
    key: 'type',
    label: 'Type',
    span: 1.5,
  },
  {
    key: 'outstanding',
    label: 'Outstanding',
    align: 'right',
  },
  {
    key: 'payment',
    label: 'Payment',
    align: 'right',
  },
  {
    key: 'balance',
    label: 'Balance',
    align: 'right',
  },
];
