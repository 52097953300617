import React from 'react';
import {v4} from 'uuid';

// Components
import RequiredSignature from '../components/RequiredSignature';

export default (data, {direct, required, onGeneration, html}) => (
  <RequiredSignature
    key={v4()}
    html={html}
    direct={direct}
    required={required}
    onGeneration={onGeneration}
  />
);
