import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {email} from '../../types';

export default attribute({
  type: TEXT,
  attribute: 'poc2_email',
  display: email,
  input: email,
  api: email,
  label: {
    default: 'Email',
    short: 'Email',
  },
});
