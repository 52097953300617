import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Card, Form, Row, Column} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

const DriverEmergencyCard = ({
  loading,
  onSave,
  onChange,
  errors,
  emergency_contact_name,
  emergency_contact_phone,
  emergency_contact_relationship,
}) => (
  <Card title="Emergency Contact" icon="warning">
    {(Content) => (
      <Form onSubmit={onSave} enter>
        <Content>
          <Row margin>
            <Column>
              <AttributeInput
                value={emergency_contact_name}
                onChange={onChange}
                errors={errors}
                disabled={loading}
              >
                {emergency_contact_name.label.short}
              </AttributeInput>
            </Column>
          </Row>
          <Row margin>
            <Column>
              <AttributeInput
                value={emergency_contact_phone}
                onChange={onChange}
                errors={errors}
                disabled={loading}
              >
                {emergency_contact_phone.label.short}
              </AttributeInput>
            </Column>
          </Row>
          <Row>
            <Column>
              <AttributeInput
                value={emergency_contact_relationship}
                onChange={onChange}
                errors={errors}
                disabled={loading}
              >
                {emergency_contact_relationship.label.short}
              </AttributeInput>
            </Column>
          </Row>
        </Content>
      </Form>
    )}
  </Card>
);

DriverEmergencyCard.propTypes = {
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  errors: PropTypes.array,
  emergency_contact_name: PropTypes.object,
  emergency_contact_phone: PropTypes.object,
  emergency_contact_relationship: PropTypes.object,
};

export default DriverEmergencyCard;
