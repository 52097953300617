import styled, {css} from 'styled-components';

const Filter = styled.div`
  width: 250px;
  margin-left: 10px;

  ${({big}) =>
    big
      ? css`
          width: auto;
          margin: 0px;
          display: flex;
        `
      : ''}
`;

export default Filter;
