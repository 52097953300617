import React, {Component} from 'react';
import PropTypes from 'prop-types';

// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// driver api
import generateDriverStatementApi from '../../api/generateStatement.api.driver';

// driver attributes
import endDateAttr from '../../attributes/end_date.attribute.driver';
import startDateAttr from '../../attributes/start_date.attribute.driver';

// driver components
import DriverStatementModal from '../../components/DriverStatementModal/DriverStatementModal';

// driver preparations
import generateDriverStatementPrep from '../../preparation/generateStatement.preparation.driver';

// error
import parseError from '../../../error/parseError';

// lib
import downloadFile from '../../../lib/downloadFile';

class DriverStatementContainer extends Component {
  static propTypes = {
    driver: PropTypes.object,
    visible: PropTypes.bool,
    onClose: PropTypes.func,
  };

  state = {
    loading: false,
    start_date: startDateAttr(''),
    end_date: endDateAttr(''),
    summary: false,
    transaction_types: [
      'repair',
      'rent',
      'congestion',
      'ezpass',
      'ticket',
      'imp',
      'mta',
      'earning',
      'check',
      'cash',
      'cash_rounding',
      'direct_deposit',
      'ehail',
      'health',
      'extras',
      'service_fee',
      'advance',
      'gas',
      'prepaid_tolls',
      'airport_access',
    ],
  };

  componentDidMount() {
    this.setState({
      start_date: startDateAttr(''),
      end_date: endDateAttr(''),
      summary: false,
    });
  }

  change = (value, key) => {
    if (this.state.loading) return;
    this.setState({[key]: value});
  };

  generate = async () => {
    const {driver} = this.props;
    const {loading, start_date, end_date, transaction_types, summary} =
      this.state;

    if (loading) return;

    this.setState({loading: true});

    try {
      const statementData = await generateDriverStatementPrep({
        start_date,
        end_date,
      });
      const statement = await generateDriverStatementApi(driver.id, {
        ...statementData,
        transaction_types: transaction_types.join(','),
        summary,
      });
      downloadFile(
        statement,
        `${driver.first_name} ${driver.last_name} - ${statementData.start_date} - ${statementData.end_date}.pdf`
      );
      this.setState({loading: false});
    } catch (error) {
      this.setState({loading: false});
      const {message} = parseError(error);
      alert.error(message);
    }
  };

  close = () => {
    if (this.state.loading) return;
    this.props.onClose();
  };

  render() {
    const {visible} = this.props;
    const {loading, start_date, end_date, summary} = this.state;
    return (
      <DriverStatementModal
        loading={loading}
        visible={visible}
        start_date={start_date}
        end_date={end_date}
        summary={summary}
        onChange={this.change}
        onClose={this.close}
        onGenerate={this.generate}
      />
    );
  }
}

export default DriverStatementContainer;
