import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Card, Row, Column, Form} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

const NotesEdit = ({loading, errors, notes, onChange, onCancel, onSave}) => (
  <Card
    title="Notes"
    icon="file1"
    headerActions={[
      {
        icon: 'close',
        onClick: onCancel,
        tooltip: 'Exit edit mode',
      },
    ]}
    buttonsRight={[{label: 'Save', loading, onClick: onSave, theme: 'orange'}]}
  >
    {(Content) => (
      <Content>
        <Form onSubmit={onSave} enter>
          <Row>
            <Column>
              <AttributeInput
                value={notes}
                onChange={onChange}
                disabled={loading}
                errors={errors}
              >
                {notes.label.default}
              </AttributeInput>
            </Column>
          </Row>
        </Form>
      </Content>
    )}
  </Card>
);

NotesEdit.propTypes = {
  loading: PropTypes.bool,
  errors: PropTypes.array,
  notes: PropTypes.object,
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};

export default NotesEdit;
