import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {set as setAct} from '../../redux/actions';

// Api
import listTollsApi from '../../api/list.api.toll';

// Lib
import wait from '../../../lib/wait';

// Query
import {isNull} from '../../../api/queries/queries';

class NumberOfTollsSync extends Component {
  static propTypes = {
    children: PropTypes.node,
    dispatch: PropTypes.func,
  };

  static defualtProps = {
    children: null,
  };

  componentDidMount() {
    this.init();
  }

  init = () => {
    this.getNumberOfTolls();
  };

  getNumberOfTolls = async () => {
    const {dispatch} = this.props;
    try {
      const {count} = await listTollsApi({
        [isNull('rental')]: true,
        [isNull('assigned_to_employee')]: true,
        removed: false,
        limit: 1,
        offset: 0,
        fields: 'id',
      });
      dispatch(setAct({numberOfTolls: count}));
    } catch (error) {
      // DO NOTHING
    }

    await wait(60 * 1000);
    this.getNumberOfTolls();
  };

  render() {
    const {children} = this.props;
    return children || null;
  }
}

export default connect()(NumberOfTollsSync);
