import type from '../type';

const format = (input) =>
  !input
    ? ''
    : `${input}`
        .toUpperCase()
        .replace(/[^0-9]/g, '')
        .slice(0, 9);

const validate = (value) => /^[0-9]{9}$/.test(value);

export default type({type: 'string', format, validate});
