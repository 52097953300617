import React from 'react';
import PropTypes from 'prop-types';

// layout components
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

const DriverAutoPayout = ({loading, onChange, option}) => (
  <AttributeInput
    value={option}
    disabled={loading}
    onChange={onChange}
    notFilterable
  >
    Auto Payout
  </AttributeInput>
);

DriverAutoPayout.propTypes = {
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  option: PropTypes.object,
};

export default DriverAutoPayout;
