import styled from 'styled-components';
import PropTypes from 'prop-types';

const height = ({size}) => {
  switch (size) {
    case 'full':
      return '100vh';
    case 'nav':
      return 'calc(100vh - 110px)';
    case 'subnav':
      return 'calc(100vh - 228px)';
    default:
      return '100vh';
  }
};

const FullScreen = styled.div`
  width: 100%;
  height: ${height};
`;

FullScreen.propTypes = {
  size: PropTypes.oneOf(['full', 'nav', 'subnav']),
};

FullScreen.defaultProps = {
  size: 'nav',
};

export default FullScreen;
