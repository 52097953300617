export default (isCredit = false) =>
  [
    {
      key: 'date',
      label: 'Date',
    },
    isCredit && {
      key: 'type',
      label: 'Type',
    },
    {
      key: 'amount',
      label: 'Amount',
    },
    {
      key: 'balance',
      label: 'Balance',
    },
    {
      key: 'description',
      label: 'Description',
      span: 2,
    },
  ].filter((col) => !!col);
