import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {parseISO, isWithinInterval} from 'date-fns';
import {connect} from 'react-redux';

// Api
import api from '../../../api/lib/getEverythingFromApi.lib.api';
import listPossibleRentalsApi from '../../api/possibleRentals.api.toll';
import assignRentalToTollApi from '../../api/assignToRental.api.toll';
import updateApi from '../../api/update.api.toll';
import {show as showReassignTollToVehicleAct} from '../ReassignTollToVehicleContainer/redux/actions';
import {show as showReassignTollToEmployeeAct} from '../ReassignTollToEmployeeContainer/redux/actions';

// Components
import ReassignTollModal from '../../components/ReassignTollModal/ReassignTollModal';

// Containers
import DriverRentalTollContainer from '../DriverRentalTollContainer/DriverRentalTollContainer';

// Lib
import {lib} from '@matthahn/sally-ui';
import parseError from '../../../error/parseError';

// Permissions
import assignPermission from '../../permissions/assign.permission.toll';
import deletePermission from '../../permissions/delete.permission.toll';

// Alert
const {alert, notify} = lib;

class ReassignTollContainer extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    toll: PropTypes.object,
    onClose: PropTypes.func,
    onComplete: PropTypes.func,
    onDelete: PropTypes.func,
    dispatch: PropTypes.func,
  };

  state = {
    loading: false,
    deleting: false,
    closable: true,
    showAssignModal: false,
    rentals: [],
  };

  componentDidMount() {
    if (this.props.visible) this.init();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) this.init();
  }

  init = () => {
    const {toll} = this.props;
    if (!toll) return;
    this.getRentals();
  };

  getRentals = async () => {
    const {toll, onClose} = this.props;

    this.setState({loading: true});

    try {
      const {data: rawRentals} = await api((query) =>
        listPossibleRentalsApi(toll.id, query)
      );
      const rentals = this.parseRentals(rawRentals);
      this.setState({loading: false, rentals});
    } catch (error) {
      this.setState({loading: false});
      alert.error('Could not load rentals');
      onClose();
    }
  };

  parseRentals = (rentals) => {
    const {toll} = this.props;
    const tollDate = parseISO(toll.transaction_date);
    return [...rentals].map((rental) => ({
      ...rental,
      canApply:
        !!rental.vehicle_pickup_datetime &&
        !!rental.vehicle_dropoff_datetime &&
        rental.vehicle_dropoff_datetime > rental.vehicle_pickup_datetime &&
        isWithinInterval(tollDate, {
          start: parseISO(rental.vehicle_pickup_datetime),
          end: parseISO(rental.vehicle_dropoff_datetime),
        }),
    }));
  };

  onClose = () => {
    if (
      this.state.loading ||
      this.state.deleting ||
      !this.state.closable ||
      this.state.showAssignModal
    )
      return;
    this.props.onClose();
  };

  onAssign = (rental) => () => this.assign(rental);

  assign = async (rental, prompt = true) => {
    const {toll, onComplete} = this.props;
    const {loading, deleting} = this.state;
    if (loading || deleting) return;

    if (!assignPermission())
      return alert.warning('You do not have permission to assign tolls');

    if (prompt) {
      await this.setState({closable: false});
      return notify({
        id: 'assignToll',
        title: 'Assign',
        icon: undefined,
        content: 'Are you sure you want to assign this toll?',
        primary: {
          label: 'No',
          onClick: () => this.setState({closable: true}),
        },
        onClose: () => this.setState({closable: true}),
        secondary: {
          label: 'Yes',
          onClick: () => this.assign(rental, false),
        },
        closable: false,
        closeOnOutsideClick: true,
      });
    }

    this.setState({loading: true, showAssignModal: false, closable: true});

    try {
      await assignRentalToTollApi({toll: toll.id, rental: rental.id});
      this.setState({loading: false});
      alert.success('Toll reassigned');
      onComplete(toll);
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({loading: false});
    }
  };

  delete = async (prompt = true) => {
    const {toll, onDelete} = this.props;
    const {loading, deleting} = this.state;

    if (!toll || loading || deleting) return;

    if (!deletePermission())
      return alert.warning('You do not have permission to delete tolls');

    if (prompt) {
      await this.setState({closable: false});
      return notify({
        id: 'deleteToll',
        title: 'Delete',
        icon: undefined,
        content: 'Are you sure you want to delete this toll?',
        primary: {
          label: 'No',
          onClick: () => this.setState({closable: true}),
        },
        secondary: {
          label: 'Yes',
          onClick: () => this.delete(false),
        },
        onClose: () => this.setState({closable: true}),
        closable: false,
        closeOnOutsideClick: true,
      });
    }

    this.setState({deleting: true, closable: true});

    try {
      await updateApi(toll.id, {removed: true});
      this.setState({deleting: false, closable: true});
      alert.success('Toll deleted');
      onDelete(toll);
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({deleting: false, closable: true});
    }
  };

  showAssignModal = () => {
    if (!assignPermission()) return;
    this.setState({showAssignModal: true});
  };

  hideAssignModal = () => this.setState({showAssignModal: false});

  onCustomRentalSelect = (rental) => this.assign(rental, false);

  onChangeVehicle = () => {
    const {dispatch, toll} = this.props;
    this.onClose();
    dispatch(showReassignTollToVehicleAct(toll));
  };

  onAssignEmployee = () => {
    const {dispatch, toll} = this.props;
    this.onClose();
    dispatch(showReassignTollToEmployeeAct(toll));
  };

  render() {
    const {visible, toll} = this.props;
    const {loading, deleting, rentals, showAssignModal} = this.state;
    return (
      <Fragment>
        <ReassignTollModal
          visible={visible && !showAssignModal}
          canAssign={assignPermission()}
          canDelete={deletePermission()}
          loading={loading}
          deleting={deleting}
          toll={toll}
          rentals={rentals}
          onRental={this.onAssign}
          onClose={this.onClose}
          onDelete={this.delete}
          onAdd={this.showAssignModal}
          onChangeVehicle={this.onChangeVehicle}
          onAssignEmployee={this.onAssignEmployee}
        />
        <DriverRentalTollContainer
          toll={toll}
          visible={visible && showAssignModal}
          onSelect={this.onCustomRentalSelect}
          onClose={this.hideAssignModal}
        />
      </Fragment>
    );
  }
}

export default connect()(ReassignTollContainer);
