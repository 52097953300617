import styled from 'styled-components';

// Components
import {Icon} from '@matthahn/sally-ui';

// Colors
import {lib} from '@matthahn/sally-ui';
const {
  colors: {darkerGrey},
} = lib;

const SearchIcon = styled(Icon)`
  color: ${darkerGrey};
  display: block;
  position: absolute;
  top: 50%;
  left: 14px;
  font-size: 28px !important;
  pointer-events: none;
  transform: translateY(-50%);
`;

export default SearchIcon;
