import claim_pending from './claim_pending.state.accident';
import file_closed from './file_closed.state.accident';
import file_review from './file_review.state.accident';
import pending_4x4 from './pending_4x4.state.accident';
import unknown from './unknown.state.accident';

export default (state) => {
  switch (state) {
    case 'claim_pending':
      return claim_pending;
    case 'file_closed':
      return file_closed;
    case 'file_review':
      return file_review;
    case 'pending_4x4':
      return pending_4x4;
    default:
      return unknown;
  }
};
