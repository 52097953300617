import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

export default (fhv_license_number) =>
  f3tch('/drivers/verify_fhv/')
    .profile(profile())
    .body({fhv_license_number})
    .post();
