import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Info, TextColor} from '@matthahn/sally-ui';

const VehicleOnHoldInfo = ({hold, onResolve, flat}) => (
  <Info type="error" action={{label: 'Edit', onClick: onResolve}} flat={flat}>
    Vehicle is on hold.{' '}
    <TextColor weight="bold" theme="white">
      Type:{' '}
    </TextColor>
    {hold.hold_type}.{' '}
    <TextColor weight="bold" theme="white">
      Reason:{' '}
    </TextColor>
    {hold.reason || '-'}
  </Info>
);

VehicleOnHoldInfo.propTypes = {
  hold: PropTypes.object,
  onResolve: PropTypes.func,
  flat: PropTypes.bool,
};

VehicleOnHoldInfo.defaultProps = {
  flat: false,
};

export default VehicleOnHoldInfo;
