import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getTime, formatISO} from 'date-fns';
import {withRouter} from 'react-router-dom';

// Actions
import {hide as hideAct} from './redux/actions';
import {show as showChargeModalAct} from '../../../transaction/containers/ChargeContainer/redux/actions';
import {show as showCreditModalAct} from '../../../transaction/containers/CreditContainer/redux/actions';
import {show as showTakePaymentModalAct} from '../../../payment/containers/TakePaymentContainer/redux/actions';
import {show as showTicketAct} from '../../../ticket/containers/TicketContainer/redux/actions';
import {show as showExternalDevicesAct} from '../../../externalDevice/redux/actions';
import {show as showDriverFhvCaptureAct} from '../../../driver/containers/DriverFhvCaptureContainer/redux/actions';
import {show as showContractAct} from '../../../document/containers/ContractContainer/redux/actions';

// Attributes
import acceptingPaymentMethodAttr from '../../attributes/accepting_payment_method.attribute.payout';
import checkNumberFromDriverAttr from '../../attributes/check_number_from_driver.attribute.payout';
import confirmAmountAttr from '../../attributes/confirmAmount.attribute.payout';
import earningsAttr from '../../attributes/earnings.attribute.payout';
import endDatetimeAttr from '../../attributes/end_datetime.attribute.payout';
import paymentMethodAttr from '../../attributes/payment_method.attribute.payout';

// Api
import api from '../../../api/lib/getEverythingFromApi.lib.api';
import listTollsApi from '../../../toll/api/list.api.toll';
import applyPayoutApi from '../../api/createAndApply.api.payout';
import applyDepositPayoutApi from '../../api/createAndApplyDeposit.api.payout';
import listTransactionsApi from '../../../transaction/api/list.api.transaction';
import listPaymentMethodsApi from '../../../paymentMethod/api/types.api.paymentMethod';
import returnCreditApi from '../../../rental/api/returnCredit.api.rental';
import listDocumentsApi from '../../../document/api/list.api.document';
import uploadDocumentApi from '../../../document/api/upload.api.document';
import listTicketsApi from '../../../ticket/api/list.api.ticket';
import listBankAccountsApi from '../../../bankAccount/api/list.api.bankAccount';
import getBankAccountBalanceApi from '../../../bankAccount/api/getBalance.api.bankAccount';

// Components
import PayoutDriverModal from '../../components/PayoutDriverModal/PayoutDriverModal';
import PayoutWarningMessage from '../../components/PayoutWarningMessage/PayoutWarningMessage';
import ConfirmCashPayoutModal from '../../components/ConfirmCashPayoutModal/ConfirmCashPayoutModal';
import TransactionTypeOverviewModal from '../../../transaction/components/TransactionTypeOverviewModal/TransactionTypeOverviewModal';

// Documents
import payoutDocument from '../../../driver/documents/folders/payout.document.driver';
import leaseContract from '../../../driver/documents/folders/leaseContract.document.driver';

// Driver
import driverRoute from '../../../driver/pages/DriverPage/route';

// driverAlert lib
import showCreateDriverAlertModal from '../../../driverAlert/lib/showCreateModal.lib.driverAlert';

// Events
import ticketUpdatedEvt from '../../../ticket/events/updated.event.ticket';
import ticketRemovedEvt from '../../../ticket/events/removed.event.ticket';
import fileCreatedEvt from '../../../document/events/created.event.document';

// Sockets
import payoutSocket from '../../../rental/sockets/payout.socket.rental';
import rentalEndedSocket from '../../../rental/sockets/ended.socket.rental';
import hotswapSocket from '../../../rental/sockets/hotswap.socket.rental';

// Preparations
import createPrep from '../../preparations/create.preparation.payout';

// Lib
import {lib} from '@matthahn/sally-ui';
import fkOrId from '../../../lib/fkOrId';
import sum from '../../../lib/sum';
import subtract from '../../../lib/subtract';
import parseError from '../../../error/parseError';
import payoutGroup from '../../lib/grouping.lib.payout';
import payoutTotal from '../../lib/totalPayout.lib.payout';
import allocationOrder from '../../lib/allocationOrder.lib.payout';
import payoutAllocation from '../../lib/allocation.lib.payout';
import creditAmount from '../../lib/creditAmount.lib.payout';
import pureCreditSum from '../../lib/pureCreditSum.lib.payout';
import parsePaymentMethod from '../../lib/parsePaymentMethod.lib.payout';
import availablePaymentMethods from '../../lib/availablePaymentMethods.lib.payout';
import incomeAllocationTransaction from '../../lib/incomeAllocationTransaction.lib.payout';
import isAcceptingCheck from '../../lib/isAcceptingCheck.lib.payout';
import isCashPayout from '../../lib/isCashPayout.lib.payout';
import availableTransactionTypes from '../../lib/availableTransactionTypes.lib.payout';
import getOwedRent from '../../lib/getOwedRent.lib.payout';
import owedAmount from '../../lib/owedAmount.lib.payout';
import issuingCreditTransactions from '../../lib/issuingCreditTransactions.lib.payout';
import shouldPerformPayout from '../../lib/shouldPerformPayout.lib.payout';
import isDailyLease from '../../../lease/lib/isDaily.lib.lease';
import takeScreenshot from '../../../layout/lib/takeScreenshot.lib.layout';
import dueDateInWeek from '../../../ticket/lib/dueDateInWeek.lib.ticket';

// Query
import {greaterThan} from '../../../api/queries/queries';

// Unsaved Changes
import {
  checkForUnsavedChanges,
  setUnsavedChanges,
  clearUnsavedChanges,
} from '../../../layout/lib/unsavedChanges.lib.layout';

// Files
import {foldersObject} from '../../../driver/documents';

// Query
import {isNull} from '../../../api/queries/queries';

// Tickets
import needsActionTicketStatus from '../../../ticket/statuses/needs_action.status.ticket';
import waitingTicketStatus from '../../../ticket/statuses/waiting.status.ticket';

// toll routes
import tollsRoute from '../../../toll/pages/TollsPage/route';

// Validation
import createValidation from '../../validations/create.validation.payout';

// Vehicle
import getVehicleByIdApi from '../../../vehicle/api/getByID.api.vehicle';
import updateVehicleMileageApi from '../../../vehicle/api/updateMileage.api.vehicle';
import mileageAttr from '../../../vehicle/attributes/mileage.attribute.vehicle';

// vehicleAlert lib
import showCreateVehicleAlertModal from '../../../vehicleAlert/lib/showCreateModal.lib.vehicleAlert';

// Types
import {apiDateTime, amount as amountType} from '../../../types';

// services
import checkPayoutRulesService from '../../services/checkRules.service.payout';

// UI
const {fhv: fhvFile, leaseContract: leaseContractFile} = foldersObject;

// Alert
const {alert, notify} = lib;

class PayoutDriverContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    chargeModalVisible: PropTypes.bool,
    chargeCreated: PropTypes.bool,
    creditModalVisible: PropTypes.bool,
    creditCreated: PropTypes.bool,
    takePaymentModalVisible: PropTypes.bool,
    paymentTaken: PropTypes.bool,
    visible: PropTypes.bool,
    driver: PropTypes.object,
    rental: PropTypes.object,
    incomeAllocation: PropTypes.object,
    endRental: PropTypes.bool,
    transactionTypes: PropTypes.array,
    userID: PropTypes.number,
    ticketVisible: PropTypes.bool,
    externalDevicesVisible: PropTypes.bool,
    captureDriverFhvVisible: PropTypes.bool,
    contractGenerationVisible: PropTypes.bool,
    depositPayout: PropTypes.bool,
  };

  state = {
    initing: false,
    loading: false,
    paymentMethods: [],
    transactions: [],
    transactionOverviewVisible: false,
    transaction: null,
    payout: null,
    payment_method: paymentMethodAttr(''),
    accepting_payment_method: acceptingPaymentMethodAttr('cash'),
    check_number_from_driver: checkNumberFromDriverAttr(''),
    returnCredit: 0,
    credit: 0,
    earnings: earningsAttr(''),
    showConfirmationModal: false,
    confirmAmount: confirmAmountAttr(''),
    payoutFocus: false,
    lastRoundMessageShake: 0,
    payoutChanged: false,
    closable: true,
    fhv: null,
    openTolls: 0,
    owedRent: 0,
    tickets: [],
    waitingTickets: [],
    showTickets: false,
    end_datetime: endDatetimeAttr(formatISO(new Date())),
    contract: null,
    unverifiedBankAccounts: [],
    loadingBankAccountBalance: true,
    availableBankAccountBalance: 0,
    mileage: mileageAttr(''),
    vehicle: null,
    payoutWarningMessage: null,
  };

  componentDidMount() {
    this.subscribe();
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.visible && this.props.visible) ||
      (this.props.visible &&
        ((prevProps.chargeModalVisible &&
          !this.props.chargeModalVisible &&
          this.props.chargeCreated) ||
          (prevProps.creditModalVisible &&
            !this.props.creditModalVisible &&
            this.props.creditCreated) ||
          (prevProps.takePaymentModalVisible &&
            !this.props.takePaymentModalVisible &&
            this.props.paymentTaken)))
    )
      this.init({
        accepting_payment_method:
          !prevProps.visible && this.props.visible
            ? acceptingPaymentMethodAttr('cash')
            : this.state.accepting_payment_method,
        check_number_from_driver:
          !prevProps.visible && this.props.visible
            ? checkNumberFromDriverAttr('')
            : this.state.check_number_from_driver,
        end_datetime:
          !prevProps.visible && this.props.visible
            ? endDatetimeAttr(formatISO(new Date()))
            : this.state.end_datetime,
        mileage:
          !prevProps.visible && this.props.visible
            ? mileageAttr('')
            : this.state.mileage,
      });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  checkingIn = false;
  handlingSockets = false;

  init = async ({
    accepting_payment_method = this.state.accepting_payment_method,
    check_number_from_driver = this.state.check_number_from_driver,
    end_datetime = this.state.end_datetime,
    mileage = this.state.mileage,
  } = {}) => {
    this.checkingIn = false;
    this.handlingSockets = false;
    const {transactionTypes, driver, rental, incomeAllocation, endRental} =
      this.props;

    // Set Payment Method
    const payment_method = paymentMethodAttr('');

    // Set default state
    this.setState({
      accepting_payment_method,
      check_number_from_driver,
      end_datetime,
      mileage,
      closable: true,
      rental: null,
      payment_method,
      transaction: null,
      transactions: [],
      showConfirmationModal: false,
      openTolls: 0,
      initing: true,
      showTickets: false,
    });

    try {
      // Get payment method, existing payout objects and return credit amount
      const [
        rawPaymentMethods,
        {rent_credit_amount: rawReturnCredit},
        fhv,
        {count: openTolls},
        {data: allDriverTransactionsFromApi},
        // {data: unassignedTickets},
        {data: ticketsThatNeedAction},
        {data: waitingTickets},
        contract,
        {data: bankAccounts},
        vehicle,
      ] = await Promise.all([
        listPaymentMethodsApi(),
        endRental
          ? returnCreditApi(rental.id, end_datetime.api.format())
          : {rent_credit_amount: 0},
        this.getFhv(driver.id),
        !!rental
          ? listTollsApi({
              [isNull('rental')]: true,
              removed: false,
              vehicle__medallion__medallion_number:
                rental.medallion.medallion_number,
              [isNull('assigned_to_employee')]: true,
              limit: 1,
              offset: 0,
              fields: 'id',
            })
          : {count: 0},
        api(listTransactionsApi, {
          driver: driver.id,
          [greaterThan('balance')]: 0,
        }),
        // !!rental
        //   ? api(listTicketsApi, {
        //       [isNull('rental')]: true,
        //       removed: false,
        //       vehicle: fkOrId(rental.vehicle),
        //       status: needsActionTicketStatus.key,
        //     })
        //   : {data: []},
        api(listTicketsApi, {
          rental__driver: driver.id,
          removed: false,
          status: needsActionTicketStatus.key,
        }),
        api(listTicketsApi, {
          rental__driver: driver.id,
          removed: false,
          status: waitingTicketStatus.key,
        }),
        this.getContract(),
        api(listBankAccountsApi, {
          driver: driver.id,
          removed: false,
        }),
        !!rental ? getVehicleByIdApi(fkOrId(rental.vehicle)) : null,
      ]);

      // Bank account balance
      this.getBankAccountBalance();

      // Filter out duplicate tickets
      // const tickets = [...unassignedTickets, ...ticketsThatNeedAction].reduce(
      //   (combined, current) =>
      //     !![...combined].find((ticket) => ticket.id === current.id)
      //       ? combined
      //       : [...combined, current],
      //   []
      // );
      const tickets = [...ticketsThatNeedAction];

      // Filter Payment Methods
      const paymentMethods = availablePaymentMethods({
        paymentMethods: rawPaymentMethods,
        bankAccounts,
      });

      // Check for unverified bank accounts
      const unverifiedBankAccounts = [...bankAccounts].filter(
        ({verified}) => !verified
      );

      // Remove duplicate tickets
      const {allDriverTransactions} = [...allDriverTransactionsFromApi].reduce(
        (combined, current) => {
          const key = !!combined.processed[current.id]
            ? 'duplicateTransactions'
            : 'allDriverTransactions';
          return {
            ...combined,
            [key]: [...combined[key], current],
            processed: {...combined.processed, [current.id]: true},
          };
        },
        {allDriverTransactions: [], duplicateTransactions: [], processed: {}}
      );

      // Attach the credit transaction if applicable
      const allDriverTransactionsWithIssuingCredit = issuingCreditTransactions(
        allDriverTransactions,
        incomeAllocation
      );

      // Filter driver transactions that don't have a balance
      const driverTransactions = [
        ...allDriverTransactionsWithIssuingCredit,
      ].filter(({balance}) => balance > 0);

      // Set return credit to 0 if lease type is daily
      const returnCredit =
        this.isDepositAllocation() ||
        this.isIncomeAllocation() ||
        !endRental ||
        !rental ||
        isDailyLease(rental.lease_agreement)
          ? 0
          : rawReturnCredit;

      // Filter out transaction types. Ignore payout
      const types = availableTransactionTypes(transactionTypes);

      // Set custom order of transactions if needed
      const transactionOrder = this.isIncomeAllocation()
        ? [incomeAllocation.transaction.subtype]
        : [];

      // Group transactions together per type
      const transactions = allocationOrder(
        payoutGroup(driverTransactions, types, {
          exclude: true,
          isIncomeAllocation: this.isIncomeAllocation(),
          isDepositAllocation: this.isDepositAllocation(),
        })
      );

      // Create payout object
      const payout = payoutAllocation(
        transactions,
        returnCredit,
        transactionOrder
      );

      // Calculate the credit
      const credit = pureCreditSum(transactions, returnCredit);

      // Calculate Earnings Amount
      const earnings = earningsAttr(
        earningsAttr(
          this.roundTotal({
            payout,
            transactions,
            returnCredit,
            payment_method,
          })
        ).display.format()
      );

      // Owed Rent
      const owedRent = getOwedRent(transactions, rental);

      // Set State
      this.setState({
        initing: false,
        transactions,
        paymentMethods,
        payout,
        payment_method,
        returnCredit,
        credit,
        earnings,
        fhv,
        openTolls,
        tickets,
        waitingTickets,
        owedRent,
        contract,
        unverifiedBankAccounts,
        vehicle,
      });
    } catch (error) {
      this.props.dispatch(hideAct());
      alert.error('Could not initiate the payout screen');
    }
  };

  getBankAccountBalance = async () => {
    this.setState({
      loadingBankAccountBalance: true,
      availableBankAccountBalance: 0,
    });

    try {
      const {dwolla_balance} = await getBankAccountBalanceApi();
      if (!this.props.visible) return;
      this.setState({
        loadingBankAccountBalance: false,
        availableBankAccountBalance: dwolla_balance,
      });
    } catch (error) {
      if (!this.props.visible) return;
      const {message} = parseError(error);
      alert.warning(message);
      this.setState({loadingBankAccountBalance: false});
    }
  };

  getFhv = async (driverID) => {
    try {
      const {results} = await listDocumentsApi({
        type: fhvFile.type,
        ordering: '-created_at',
        driver: driverID,
      });
      const doc = results.length > 0 ? results[0] : null;
      return doc;
    } catch (error) {
      return null;
    }
  };

  subscribe = () => {
    this.subscriptions = [
      payoutSocket.subscribe(this.onDriverPayout),
      rentalEndedSocket.subscribe(this.onRentalEnd),
      hotswapSocket.subscribe(this.onHotswap),

      // Events
      ticketUpdatedEvt.sub(this.onTicketUpdate),
      ticketRemovedEvt.sub(this.onTicketUpdate),
      fileCreatedEvt.sub(this.fileCreated),
    ];
  };

  unsubscribe = () => {
    this.subscriptions.forEach((unsubscribe) => unsubscribe());
    this.subscriptions = [];
  };

  fileCreated = (file) => {
    const {visible, driver} = this.props;
    if (!visible || !driver || fkOrId(file.driver) !== driver.id) return;

    if (file.type === fhvFile.type) return this.setState({fhv: file});
    if (file.type === leaseContractFile.type)
      return this.setState({contract: file});
  };

  onDriverPayout = ({payout}) => {
    this.payoutOccured(
      fkOrId(payout.driver),
      `${payout.driver.first_name} ${payout.driver.last_name} was payed out`
    );
  };

  onRentalEnd = (rental) => {
    this.payoutOccured(
      fkOrId(rental.driver),
      `${rental.driver.first_name} ${rental.driver.last_name} returned ${rental.medallion.medallion_number}`
    );
  };

  onHotswap = ({hotswaps}) => {
    [...hotswaps].forEach(({old_rental, new_rental}) => {
      this.payoutOccured(
        fkOrId(old_rental.driver),
        `${old_rental.driver.first_name} ${old_rental.driver.last_name} switched from ${old_rental.medallion.medallion_number} to ${new_rental.medallion.medallion_number}`
      );
      this.payoutOccured(
        fkOrId(new_rental.driver),
        `${old_rental.driver.first_name} ${old_rental.driver.last_name} switched from ${old_rental.medallion.medallion_number} to ${new_rental.medallion.medallion_number}`
      );
    });
  };

  onTicketUpdate = (ticket) => {
    const exists = !![...this.state.tickets].find(({id}) => id === ticket.id);
    if (!this.props.visible || !exists) return;
    if (!exists) return;
    this.init();
  };

  getContract = async () => {
    const {driver} = this.props;
    try {
      const {results: contracts} = await listDocumentsApi({
        driver: driver.id,
        type: leaseContract.type,
        ordering: '-id',
      });
      return [...contracts].find((c) => fkOrId(c.driver) === driver.id) || null;
    } catch (error) {
      return null;
    }
  };

  payoutOccured = (driverID, message) => {
    const {dispatch, visible, driver} = this.props;
    if (
      !visible ||
      this.checkingIn ||
      this.handlingSockets ||
      !driver ||
      driver.id !== driverID
    )
      return;
    this.handlingSockets = true;
    dispatch(hideAct());
    alert.info(message);
    this.handlingSockets = false;
  };

  isIncomeAllocation = () =>
    !!this.props.incomeAllocation && !!this.props.incomeAllocation.api;

  isDepositAllocation = () => this.props.depositPayout;

  showAlerts = () => !this.isIncomeAllocation();

  shouldCaptureMileage = () =>
    !this.isIncomeAllocation() &&
    !this.isDepositAllocation() &&
    !!this.state.vehicle;

  shouldEndRental = () => {
    const {rental, endRental} = this.props;
    return !!rental && endRental;
  };

  onPaymentMethodChange = setUnsavedChanges(
    (val) => {
      const {earnings: currentEarnings} = this.state;
      const currentEarningsValue = currentEarnings.api.format();
      const totalAmount = this.totalAmount();
      const previousRoundTotal = this.roundTotal();
      const roundTotal = this.roundTotal({payment_method: val});

      const state = {
        payment_method: val,
      };

      if (totalAmount > 0 && currentEarningsValue >= previousRoundTotal) {
        state.earnings = earningsAttr(
          earningsAttr(roundTotal).display.format()
        );
      } else if (isCashPayout(val)) {
        state.earnings = earningsAttr(
          earningsAttr(this.round(currentEarningsValue)).display.format()
        );
      }

      if (isCashPayout(val)) state.lastRoundMessageShake = getTime(new Date());

      this.setState(state);
    },
    () => this.state.loading
  );

  onPaymentChange = (key) =>
    setUnsavedChanges(
      (val) => {
        const payout = {...this.state.payout, [key]: val.input.format()};
        const earnings = earningsAttr(
          earningsAttr(this.roundTotal({payout})).display.format()
        );
        this.setState({payout, earnings});
      },
      () => this.state.loading
    );

  onEarnings = setUnsavedChanges(
    (earnings) => {
      this.setState({earnings, payoutChanged: true});
    },
    () => this.state.loading
  );

  onConfirmAmount = (val) => {
    if (this.state.loading) return;
    this.setState({confirmAmount: val});
  };

  showConfirmation = () => {
    if (this.state.loading) return;
    this.setState({
      showConfirmationModal: true,
      confirmAmount: confirmAmountAttr(''),
    });
  };

  hideConfirmation = () => {
    if (this.state.loading) return;
    this.setState({showConfirmationModal: false});
  };

  rentBalance = () => {
    const {transactions, returnCredit} = this.state;
    const rentBalance =
      [...transactions].find(({key}) => key === 'chargerent')?.balance || 0;
    return Math.min(rentBalance, returnCredit);
  };

  confirmPayout = () => {
    const {loading, confirmAmount, earnings} = this.state;
    if (loading) return;

    const totalAmount = this.totalAmount();
    const confirmationAmount = confirmAmount.api.format();

    if (
      (totalAmount < 0 && Math.abs(totalAmount) !== confirmationAmount) ||
      (totalAmount > 0 && confirmationAmount !== earnings.api.format())
    ) {
      alert.warning('Insert the correct amount');
      return this.hideConfirmation();
    }

    this.payout({confirmed: true});
  };

  payout = async ({confirmed = false, checkRules = true} = {}) => {
    const {dispatch, driver, rental, userID, incomeAllocation, endRental} =
      this.props;
    const {
      loading,
      payout: rawPayout,
      payment_method: paymentMethod,
      earnings: earningsObj,
      payoutFocus,
      credit,
      transactions,
      tickets,
      end_datetime,
      accepting_payment_method,
      check_number_from_driver,
      vehicle,
      mileage,
    } = this.state;

    if (loading || payoutFocus) return;

    const rentBalance = this.rentBalance();

    const earnings = earningsObj.api.format();
    const total = this.totalAmount();
    const paymentMethodValue = paymentMethod.api.format();
    const owed = owedAmount(transactions);
    const payoutAmount = payoutTotal(rawPayout);

    if (!this.isIncomeAllocation() && !!tickets.length)
      return alert.warning('Resolve tickets before payout');

    if (
      !this.isIncomeAllocation() &&
      total > 0 &&
      earnings > 0 &&
      !paymentMethodValue
    )
      return alert.warning('Select payment method');

    if (!this.isIncomeAllocation() && total > 0 && earnings > this.roundTotal())
      return alert.warning(
        'Payout amount can not be higher than the total amount'
      );

    if (
      this.shouldCaptureMileage() &&
      !!mileage.api.format() &&
      (vehicle?.mileage || 0) > mileage.api.format()
    )
      return alert.warning(
        `Latest captured mileage was ${vehicle.mileage}. Insert a number higher than that.`
      );

    if (Number(rawPayout.rent_amount) < rentBalance)
      return alert.error(
        `Rent amount needs to be at least $${amountType(rentBalance).format()}`
      );

    if (!this.isIncomeAllocation() && !confirmed && total > 0)
      return this.showConfirmation();

    try {
      createValidation({
        payout: rawPayout,
        earnings,
        total,
        credit,
        payoutAmount,
        owed,
        transactions,
        isIncomeAllocation: this.isIncomeAllocation(),
        isDepositAllocation: this.isDepositAllocation(),
        accepting_payment_method,
        check_number_from_driver,
      });
    } catch (error) {
      return alert.warning(parseError(error).message);
    }

    this.setState({loading: true});
    this.checkingIn = true;

    try {
      const {payment_method, dwolla_funding_id} =
        parsePaymentMethod(paymentMethodValue);
      const formattedPayout = createPrep(rawPayout);

      formattedPayout.payment_method = this.isIncomeAllocation()
        ? null
        : payment_method;
      formattedPayout.dwolla_funding_id = dwolla_funding_id;
      formattedPayout.earnings_amount = this.isIncomeAllocation()
        ? 0
        : earnings > total
        ? total
        : earnings;
      formattedPayout.created_by = userID;
      formattedPayout.trigger = 'dashboard';
      formattedPayout.driver = driver.id;

      if (this.isDepositAllocation() && total < 0)
        throw new Error('Can not take money while allocating a deposit');
      if (total <= 0) formattedPayout.payment_method = null;
      if (this.isIncomeAllocation()) await incomeAllocation.api();
      const performPayout = shouldPerformPayout({
        isIncomeAllocation: this.isIncomeAllocation(),
        credit,
        total,
      });

      if (checkRules) {
        const {payoutWarningMessage} = await checkPayoutRulesService({
          credit,
          payout: formattedPayout,
          performPayout,
          total,
          transactions: this.displayableTransactions(),
        });

        if (!!payoutWarningMessage)
          return this.setState({payoutWarningMessage, loading: false});
      }

      const [payoutImage, payout] = await Promise.all([
        performPayout ? this.createPayoutSnapshot() : null,
        performPayout
          ? this.isDepositAllocation()
            ? applyDepositPayoutApi({
                payout: formattedPayout,
                dwolla_funding_id,
              })
            : applyPayoutApi({
                payout: formattedPayout,
                check_number_from_driver:
                  !this.isIncomeAllocation() &&
                  !this.isDepositAllocation() &&
                  total < 0 &&
                  isAcceptingCheck(accepting_payment_method)
                    ? check_number_from_driver.api.format()
                    : null,
                dwolla_funding_id,
                current_rental_id: rental?.id || null,
                end_rental_id: this.shouldEndRental() ? rental.id : null,
                end_datetime:
                  !this.isIncomeAllocation() &&
                  endRental &&
                  !isDailyLease(rental.lease_agreement)
                    ? end_datetime.api.format()
                    : null,
              })
          : null,
      ]);

      if (!!payoutImage)
        this.uploadPayoutSnapshot({payoutImage, driver, payout, rental});
      this.saveVehicleMileage();
      clearUnsavedChanges();
      dispatch(hideAct());
      alert.success(
        this.isIncomeAllocation()
          ? `${incomeAllocation.label} allocation successful`
          : 'Payout successful'
      );
    } catch (error) {
      this.checkingIn = false;
      const {message} = parseError(error);
      alert.error(message);
    }

    this.setState({loading: false, showConfirmationModal: false});
  };

  createPayoutSnapshot = async () => {
    try {
      const payoutImage = await takeScreenshot(
        '#payoutContainer',
        'payout.png'
      );
      return payoutImage;
    } catch (error) {
      return null;
    }
  };

  uploadPayoutSnapshot = async ({payoutImage, driver, payout, rental}) => {
    try {
      await uploadDocumentApi({
        doc: payoutImage,
        file: payoutDocument(),
        driver,
        rental: !!rental ? rental : null,
        rawDocumentData: {
          payout: payout.id,
        },
        type: 'driver',
      });
    } catch (error) {
      // Do nothing
    }
  };

  saveVehicleMileage = async () => {
    const {vehicle, mileage} = this.state;
    if (!this.shouldCaptureMileage() || !mileage.api.format()) return;
    try {
      await updateVehicleMileageApi(vehicle.id, {
        mileage: mileage.api.format(),
        mileage_captured_at: apiDateTime(new Date()).format(),
      });
    } catch (error) {
      // Do not fail
    }
  };

  onClose = ({prompt = true, cb = () => {}} = {}) => {
    const {chargeModalVisible, creditModalVisible, incomeAllocation} =
      this.props;
    const {loading, closable} = this.state;
    if (!closable) this.setState({closable: true});
    if (loading || chargeModalVisible || creditModalVisible || !closable)
      return;

    if (this.isIncomeAllocation()) {
      if (prompt)
        return notify({
          id: 'closePayoutModal',
          title: `Close ${incomeAllocation.label} Allocation`,
          icon: undefined,
          content: `${incomeAllocation.label} was not made yet. Are you sure you want to close this window?`,
          primary: {
            label: 'No',
            onClick: () => {},
          },
          onClose: () => {},
          secondary: {
            label: 'Yes',
            onClick: () => this.onClose({prompt: false, cb}),
          },
          closable: false,
          closeOnOutsideClick: true,
        });
      clearUnsavedChanges();
      this.props.dispatch(hideAct());
      cb();
      return;
    }

    checkForUnsavedChanges(() => {
      cb();
      this.props.dispatch(hideAct());
    })();
  };

  onNewCharge = () => {
    const {dispatch, rental, driver} = this.props;
    const {loading} = this.state;
    if (loading) return;
    dispatch(showChargeModalAct(driver, {rental: !!rental ? rental.id : null}));
  };

  onNewCredit = () => {
    const {dispatch, rental, driver} = this.props;
    const {loading} = this.state;
    if (loading) return;
    dispatch(
      showCreditModalAct(
        driver,
        {rental: !!rental ? rental.id : null},
        {allocate: false}
      )
    );
  };

  onTakePayment = () => {
    const {dispatch, driver} = this.props;
    const {loading} = this.state;
    if (loading) return;
    dispatch(showTakePaymentModalAct(driver, {allocate: false}));
  };

  onPayoutFocus = () =>
    this.setState({payoutFocus: true, payoutChanged: false});

  onPayoutBlur = async () => {
    const {earnings, payment_method, lastRoundMessageShake, payoutChanged} =
      this.state;

    if (isCashPayout(payment_method))
      await this.setState({
        earnings: earningsAttr(
          earningsAttr(this.round(earnings.api.format())).display.format()
        ),
        lastRoundMessageShake: payoutChanged
          ? getTime(new Date())
          : lastRoundMessageShake,
      });
    const setState = () =>
      this.setState({payoutFocus: false, payoutChanged: false});
    if (!payoutChanged) return setState();
    setTimeout(setState, 200);
  };

  onChange = (value, key) => {
    if (this.state.loading) return;
    this.setState({[key]: value});
  };

  onTickets = () => {
    const {tickets} = this.state;
    if (!tickets.length) return;
    this.setState({showTickets: !this.state.showTickets});
  };

  onTicket = (ticket) => () => this.props.dispatch(showTicketAct(ticket));

  onEndDatetime = (end_datetime) => {
    if (this.state.loading) return;
    this.init({end_datetime});
  };

  openTransaction = (transaction) => () =>
    this.setState({transaction, transactionOverviewVisible: true});

  openCredit = () => {
    const {incomeAllocation} = this.props;
    const {returnCredit} = this.state;
    const filteredTransactions = [...this.state.transactions]
      .filter(({type}) => type === 'credit')
      .map(({transactions}) => [...transactions])
      .flat()
      .filter(({id}) => id !== 'incomeAllocation');

    const dynamicTransactions = [
      !!returnCredit && {
        amount: returnCredit,
        balance: returnCredit,
        description: 'Rental Return Credit',
        id: -1,
        posted_at: new Date(),
        subtype: 'return credit',
        type: 'returncredit',
      },
      this.isIncomeAllocation() &&
        incomeAllocationTransaction(incomeAllocation.transaction),
    ].filter((transaction) => !!transaction);

    const transactions = [...dynamicTransactions, ...filteredTransactions];
    const transaction = {
      balance: 0,
      key: 'credit',
      label: 'Credit',
      owed_to_sally: false,
      subtype: 'credit',
      type: 'credit',
      transactions,
    };
    this.setState({transaction, transactionOverviewVisible: true});
  };

  closeTransaction = () => this.setState({transactionOverviewVisible: false});

  displayableTransactions = () =>
    [...this.state.transactions].filter(
      ({type}) => !['credit', 'payment'].includes(type)
    );

  totalAmount = ({
    payout = this.state.payout,
    transactions = this.state.transactions,
    returnCredit = this.state.returnCredit,
  } = {}) =>
    !payout ? 0 : payoutTotal(payout, creditAmount(transactions, returnCredit));

  roundTotal = ({
    payout = this.state.payout,
    payment_method = this.state.payment_method,
    transactions = this.state.transactions,
    returnCredit = this.state.returnCredit,
  } = {}) => {
    if (!payout) return 0;
    const totalAmount = this.totalAmount({payout, transactions, returnCredit});
    const negative = totalAmount < 0;
    return isCashPayout(payment_method) || negative
      ? this.round(totalAmount, negative)
      : totalAmount;
  };

  selectedTransactionSum = () => {
    const {transaction} = this.state;
    if (!transaction) return {balance: 0, amount: 0};
    const balance = [...transaction.transactions].reduce(
      (combined, transaction) => sum(combined, transaction.balance),
      0
    );
    const amount = [...transaction.transactions].reduce(
      (combined, transaction) => sum(combined, transaction.amount),
      0
    );
    return {balance, amount};
  };

  round = (amount, negative = false) =>
    negative ? Math.round(Math.abs(amount)) * -1 : Math.round(Math.abs(amount));

  autochargingTickets = () =>
    [...this.state.waitingTickets].filter(dueDateInWeek);

  onFhv = () => {
    const {dispatch} = this.props;
    dispatch(
      showExternalDevicesAct({extension: 'camera', handler: this.handleFhv})
    );
  };

  handleFhv = (data) => {
    const {dispatch, driver} = this.props;
    dispatch(showDriverFhvCaptureAct({driver, image: data.image}));
  };

  handleMissingContract = () => {
    const {dispatch, driver} = this.props;
    dispatch(showContractAct(driver));
  };

  goToDriverAccount = () => {
    const {driver, history} = this.props;
    this.onClose({
      cb: () => {
        history.push(driverRoute(driver.id));
      },
    });
  };

  closePayoutWarningMessage = () => {
    if (this.state.loading) return;
    this.setState({payoutWarningMessage: null});
  };

  proceedWithPayout = () => {
    if (this.state.loading) return;
    this.setState({payoutWarningMessage: null}, () =>
      this.payout({confirmed: true, checkRules: false})
    );
  };

  createVehicleAlert = () => {
    const {rental} = this.props;
    if (!rental?.vehicle) return;
    showCreateVehicleAlertModal({vehicle: {id: rental.vehicle}});
  };

  createDriverAlert = () => {
    const {driver} = this.props;
    showCreateDriverAlertModal({driver});
  };

  goToTolls = () => {
    const {history, dispatch, rental} = this.props;
    if (!rental?.medallion) return;
    dispatch(hideAct());
    history.push(tollsRoute(rental.medallion.medallion_number));
  };

  render() {
    const {
      externalDevicesVisible,
      chargeModalVisible,
      creditModalVisible,
      takePaymentModalVisible,
      visible,
      driver,
      rental,
      ticketVisible,
      endRental,
      captureDriverFhvVisible,
      contractGenerationVisible,
      incomeAllocation,
    } = this.props;
    const {
      initing,
      loading,
      transactionOverviewVisible,
      transaction,
      paymentMethods,
      accepting_payment_method,
      check_number_from_driver,
      payment_method,
      payout,
      credit,
      earnings,
      showConfirmationModal,
      confirmAmount,
      lastRoundMessageShake,
      fhv,
      openTolls,
      returnCredit,
      tickets,
      waitingTickets,
      owedRent,
      showTickets,
      end_datetime,
      contract,
      unverifiedBankAccounts,
      loadingBankAccountBalance,
      availableBankAccountBalance,
      mileage,
      payoutWarningMessage,
    } = this.state;

    const earningsValue = earnings.api.format();
    const isCash = isCashPayout(payment_method);
    const totalAmount = this.totalAmount();
    const roundedTotal = this.roundTotal();
    const leftover = subtract(totalAmount, earningsValue);

    return (
      <Fragment>
        <PayoutDriverModal
          title={
            this.isDepositAllocation()
              ? 'Allocate Deposit'
              : this.isIncomeAllocation()
              ? `Allocate ${incomeAllocation.label}`
              : 'Payout Driver'
          }
          payoutButtonLabel={
            this.isDepositAllocation()
              ? 'Allocate Deposit'
              : this.isIncomeAllocation()
              ? `Allocate ${incomeAllocation.label}`
              : endRental
              ? 'End Rental & Payout'
              : 'Payout'
          }
          displayDate={
            !this.isDepositAllocation() &&
            !this.isIncomeAllocation() &&
            endRental &&
            !!rental &&
            !isDailyLease(rental.lease_agreement)
          }
          initing={initing}
          loading={loading}
          showTickets={showTickets}
          visible={
            visible &&
            !transactionOverviewVisible &&
            !chargeModalVisible &&
            !creditModalVisible &&
            !takePaymentModalVisible &&
            !ticketVisible &&
            !externalDevicesVisible &&
            !captureDriverFhvVisible &&
            !contractGenerationVisible &&
            !payoutWarningMessage
          }
          end_datetime={end_datetime}
          openTolls={openTolls}
          closable={!showConfirmationModal && !payoutWarningMessage}
          isIncomeAllocation={this.isIncomeAllocation()}
          creditAmount={
            this.isIncomeAllocation()
              ? this.props.incomeAllocation.transaction.amount
              : 0
          }
          isDepositAllocation={this.isDepositAllocation()}
          depositAmount={
            this.isDepositAllocation() ? driver.deposit_balance : 0
          }
          showAlerts={this.showAlerts()}
          unverifiedBankAccounts={unverifiedBankAccounts}
          accepting_payment_method={accepting_payment_method}
          check_number_from_driver={check_number_from_driver}
          contract={contract}
          fhv={fhv}
          transactions={this.displayableTransactions()}
          earnings={earnings}
          totalAmount={totalAmount}
          roundedTotal={roundedTotal}
          leftover={leftover}
          credit={credit}
          endRental={this.shouldEndRental()}
          driver={driver}
          rental={rental}
          payout={payout}
          mileage={mileage}
          // shouldCaptureMileage={this.shouldCaptureMileage()}
          shouldCaptureMileage={false}
          returnCredit={returnCredit}
          paymentMethods={paymentMethods}
          payment_method={payment_method}
          isCashPayout={isCash}
          lastRoundMessageShake={lastRoundMessageShake}
          tickets={tickets}
          owedRent={owedRent}
          loadingBankAccountBalance={loadingBankAccountBalance}
          availableBankAccountBalance={availableBankAccountBalance}
          waitingTickets={waitingTickets}
          autochargingTickets={this.autochargingTickets()}
          isAcceptingCheck={isAcceptingCheck(accepting_payment_method)}
          onPaymentMethodChange={this.onPaymentMethodChange}
          onPayout={this.payout}
          onClose={this.onClose}
          onOpenTransaction={this.openTransaction}
          onOpenCredit={this.openCredit}
          onPaymentChange={this.onPaymentChange}
          onNewCharge={this.onNewCharge}
          onNewCredit={this.onNewCredit}
          onTakePayment={this.onTakePayment}
          onEarnings={this.onEarnings}
          onPayoutFocus={this.onPayoutFocus}
          onPayoutBlur={this.onPayoutBlur}
          onTickets={this.onTickets}
          onTicket={this.onTicket}
          onEndDatetime={this.onEndDatetime}
          onFhv={this.onFhv}
          onContract={this.handleMissingContract}
          onGoToDriverPage={this.goToDriverAccount}
          onChange={this.onChange}
          onCreateDriverAlert={this.createDriverAlert}
          onCreateVehicleAlert={this.createVehicleAlert}
          onTolls={this.goToTolls}
        />
        <TransactionTypeOverviewModal
          visible={transactionOverviewVisible}
          transaction={transaction}
          onClose={this.closeTransaction}
          {...this.selectedTransactionSum()}
        />
        <ConfirmCashPayoutModal
          loading={loading}
          visible={showConfirmationModal}
          driver={driver}
          confirmAmount={confirmAmount}
          paymentMethod={payment_method.api.format()}
          paymentMethods={paymentMethods}
          onChange={this.onConfirmAmount}
          onClose={this.hideConfirmation}
          onConfirm={this.confirmPayout}
        />
        <PayoutWarningMessage
          loading={loading}
          visible={visible && !!payoutWarningMessage}
          message={payoutWarningMessage}
          onClose={this.closePayoutWarningMessage}
          onProceed={this.proceedWithPayout}
        />
      </Fragment>
    );
  }
}

export default connect((state) => ({
  ...state.payoutDriver,
  transactionTypes: state.transactionType.transactionTypes,
  chargeModalVisible: state.charge.visible,
  chargeCreated: state.charge.chargeCreated,
  creditModalVisible: state.credit.visible,
  creditCreated: state.credit.creditCreated,
  takePaymentModalVisible: state.takePayment.visible,
  paymentTaken: state.takePayment.paymentTaken,
  ticketVisible: state.ticketInfo.visible,
  userID: state.auth.userID,
  externalDevicesVisible: state.externalDevice.visible,
  captureDriverFhvVisible: state.driverFhvCapture.visible,
  contractGenerationVisible: state.contract.visible,
}))(withRouter(PayoutDriverContainer));
