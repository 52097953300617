// alert events
import showAlertCreateModalEvent from '../../alert/events/showCreateModal.event.alert';

// vehicleAlert api
import createVehicleAlertApi from '../api/create.api.vehicleAlert';

const showVehicleAlertCreateModal = ({vehicle}) =>
  showAlertCreateModalEvent.publish({
    object: vehicle,
    objectType: 'vehicle',
    saveApi: createVehicleAlertApi,
  });

export default showVehicleAlertCreateModal;
