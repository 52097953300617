import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Containers
import MedallionOwnerContainer from '../../containers/MedallionOwnerContainer/MedallionOwnerContainer';

class MedallionOwnerPage extends Component {
  static propTypes = {
    match: PropTypes.object,
  };

  render() {
    const {
      match: {
        params: {id},
      },
    } = this.props;
    return <MedallionOwnerContainer id={id} />;
  }
}

export default MedallionOwnerPage;
