import React from 'react';
import PropTypes from 'prop-types';

// Attributes
import poc2Attr from '../../attributes/poc2.attribute.medallionOwner';
import addressStreet1Attr from '../../attributes/poc2_address_street1.attribute.medallionOwner';
import addressStreet2Attr from '../../attributes/poc2_address_street2.attribute.medallionOwner';
import addressCityAttr from '../../attributes/poc2_address_city.attribute.medallionOwner';
import addressZipAttr from '../../attributes/poc2_address_zip.attribute.medallionOwner';
import addressStateAttr from '../../attributes/poc2_address_state.attribute.medallionOwner';
import phoneNumberAttr from '../../attributes/poc2_phone_number.attribute.medallionOwner';
import emailAttr from '../../attributes/poc2_email.attribute.medallionOwner';
import notesAttr from '../../attributes/poc2_notes.attribute.medallionOwner';

// Components
import {Card, Row, Column} from '@matthahn/sally-ui';
import AttributeText from '../../../layout/components/AttributeText/AttributeText';

const Poc2 = ({
  poc2,
  poc2_address_street1,
  poc2_address_street2,
  poc2_address_city,
  poc2_address_zip,
  poc2_address_state,
  poc2_email,
  poc2_phone_number,
  poc2_notes,
  onEdit,
}) => (
  <Card
    title="POC 2"
    icon="phone"
    headerActions={[
      {
        icon: 'edit',
        onClick: onEdit,
        tooltip: 'Edit',
      },
    ]}
  >
    {(Content) => (
      <Content>
        <Row margin>
          <Column>
            <AttributeText attribute={poc2Attr(poc2)}>
              {poc2Attr.label.default}
            </AttributeText>
          </Column>
        </Row>
        <Row margin>
          <Column size={1 / 2}>
            <AttributeText attribute={emailAttr(poc2_email)}>
              {emailAttr.label.default}
            </AttributeText>
          </Column>
          <Column size={1 / 2}>
            <AttributeText attribute={phoneNumberAttr(poc2_phone_number)}>
              {phoneNumberAttr.label.default}
            </AttributeText>
          </Column>
        </Row>
        <Row margin>
          <Column size={1 / 3}>
            <AttributeText attribute={addressStreet1Attr(poc2_address_street1)}>
              {addressStreet1Attr.label.default}
            </AttributeText>
          </Column>
          <Column size={1 / 3}>
            <AttributeText attribute={addressStreet2Attr(poc2_address_street2)}>
              {addressStreet2Attr.label.default}
            </AttributeText>
          </Column>
          <Column size={1 / 3}>
            <AttributeText attribute={addressCityAttr(poc2_address_city)}>
              {addressCityAttr.label.default}
            </AttributeText>
          </Column>
        </Row>
        <Row margin>
          <Column size={1 / 2}>
            <AttributeText attribute={addressZipAttr(poc2_address_zip)}>
              {addressZipAttr.label.default}
            </AttributeText>
          </Column>
          <Column size={1 / 2}>
            <AttributeText attribute={addressStateAttr(poc2_address_state)}>
              {addressStateAttr.label.default}
            </AttributeText>
          </Column>
        </Row>
        <Row>
          <Column>
            <AttributeText attribute={notesAttr(poc2_notes)}>
              {notesAttr.label.default}
            </AttributeText>
          </Column>
        </Row>
      </Content>
    )}
  </Card>
);

Poc2.propTypes = {
  poc2: PropTypes.string,
  poc2_address_street1: PropTypes.string,
  poc2_address_street2: PropTypes.string,
  poc2_address_city: PropTypes.string,
  poc2_address_zip: PropTypes.string,
  poc2_address_state: PropTypes.string,
  poc2_email: PropTypes.string,
  poc2_phone_number: PropTypes.string,
  poc2_notes: PropTypes.string,
  onEdit: PropTypes.func,
};

export default Poc2;
