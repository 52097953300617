import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal, Table, InputTyping, Row, Column} from '@matthahn/sally-ui';

// Columns
import columns from './colimns';

const SelectMedallionOwnerModal = ({
  visible,
  search,
  owners,
  onClose,
  onSearch,
  onSelect,
}) => (
  <Modal title="Owner Select" visible={visible} onClose={onClose}>
    {(Content) => (
      <Fragment>
        <Content>
          <Row margin />
          <Row margin>
            <Column size={1 / 2} offset={1 / 4}>
              <InputTyping
                placeholder="Search"
                value={search}
                onChange={onSearch}
              />
            </Column>
          </Row>
        </Content>
        <Content padding="none">
          <Table columns={columns} noBorder noRadius smallNoResultsLabel>
            {(TableRow) =>
              [
                ...owners.map((owner) => (
                  <TableRow key={owner.id} onClick={onSelect(owner)}>
                    {(TableColumn) => [
                      <TableColumn key="owner">{owner.name}</TableColumn>,
                    ]}
                  </TableRow>
                )),
              ].filter((row) => !!row)
            }
          </Table>
        </Content>
      </Fragment>
    )}
  </Modal>
);

SelectMedallionOwnerModal.propTypes = {
  visible: PropTypes.bool,
  search: PropTypes.string,
  owners: PropTypes.array,
  onClose: PropTypes.func,
  onSelect: PropTypes.func,
  onSearch: PropTypes.func,
};

export default SelectMedallionOwnerModal;
