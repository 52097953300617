import React from 'react';
import PropTypes from 'prop-types';

// accident components
import AccidentValueTable from '../../components/AccidentValueTable/AccidentValueTable';

// Components
import {Card} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

const AccidentInfoCard = ({
  loading,
  accident,
  onChange,
  onSave,

  appointment_time,
  datetime_of_loss,
  police_called,
  police_report_taken,
  camera_unplugged,
  tow_required,
  description,
  dol_city,
  dol_location,
  dol_state,
  number_of_people_in_vehicle,
  police_report_number,
  injuries,
  injuries_description,
}) => (
  <Card
    title="Accident Info"
    icon="info"
    buttonsRight={[{label: 'Save', loading, onClick: onSave, theme: 'orange'}]}
  >
    {(Content) => (
      <Content padding="none" noBorder>
        <AccidentValueTable
          label={datetime_of_loss.label.default}
          display={
            <AttributeInput
              value={datetime_of_loss}
              onChange={onChange}
              disabled={loading}
              size="small"
              flat
            />
          }
          wrapContent
          noPadding
        />
        <AccidentValueTable
          label={police_called.label.default}
          display={
            <AttributeInput
              value={police_called}
              onChange={onChange}
              disabled={loading}
              size="small"
            />
          }
        />
        <AccidentValueTable
          label={police_report_taken.label.default}
          display={
            <AttributeInput
              value={police_report_taken}
              onChange={onChange}
              disabled={loading}
              size="small"
            />
          }
        />
        <AccidentValueTable
          label={camera_unplugged.label.default}
          display={
            <AttributeInput
              value={camera_unplugged}
              onChange={onChange}
              disabled={loading}
              size="small"
            />
          }
        />
        <AccidentValueTable
          label={tow_required.label.default}
          display={
            <AttributeInput
              value={tow_required}
              onChange={onChange}
              disabled={loading}
              size="small"
            />
          }
        />
        <AccidentValueTable
          label={police_report_number.label.default}
          display={
            <AttributeInput
              value={police_report_number}
              placeholder={police_report_number.label.default}
              onChange={onChange}
              disabled={loading}
              size="small"
              flat
            />
          }
          wrapContent
          noPadding
        />
        <AccidentValueTable
          label={number_of_people_in_vehicle.label.default}
          display={
            <AttributeInput
              value={number_of_people_in_vehicle}
              placeholder={number_of_people_in_vehicle.label.default}
              onChange={onChange}
              disabled={loading}
              size="small"
              flat
            />
          }
          wrapContent
          noPadding
        />
        <AccidentValueTable
          label={dol_city.label.default}
          display={
            <AttributeInput
              value={dol_city}
              onChange={onChange}
              disabled={loading}
              size="small"
              flat
            />
          }
          wrapContent
          noPadding
        />
        <AccidentValueTable
          label={dol_location.label.default}
          display={
            <AttributeInput
              value={dol_location}
              onChange={onChange}
              disabled={loading}
              size="small"
              flat
            />
          }
          wrapContent
          noPadding
        />
        <AccidentValueTable
          label={dol_state.label.default}
          display={
            <AttributeInput
              value={dol_state}
              onChange={onChange}
              disabled={loading}
              size="small"
              flat
            />
          }
          wrapContent
          noPadding
        />
        <AccidentValueTable
          label={injuries.label.default}
          display={
            <AttributeInput
              value={injuries}
              onChange={onChange}
              disabled={loading}
              size="small"
            />
          }
        />
        {injuries?.value() && (
          <AccidentValueTable
            label={injuries_description.label.default}
            display={
              <AttributeInput
                value={injuries_description}
                onChange={onChange}
                disabled={loading}
                size="small"
              />
            }
            noPadding
          />
        )}
        <AccidentValueTable
          label={appointment_time.label.default}
          display={
            <AttributeInput
              value={appointment_time}
              onChange={onChange}
              disabled={loading}
              size="small"
              flat
            />
          }
          wrapContent
          noPadding
        />
        <AccidentValueTable
          label={description.label.default}
          display={
            <AttributeInput
              value={description}
              onChange={onChange}
              disabled={loading}
              size="small"
            />
          }
          noPadding
        />
      </Content>
    )}
  </Card>
);

AccidentInfoCard.propTypes = {
  loading: PropTypes.bool,
  accident: PropTypes.object,
  onChange: PropTypes.func,
  onSave: PropTypes.func,

  appointment_time: PropTypes.object,
  datetime_of_loss: PropTypes.object,
  police_called: PropTypes.object,
  police_report_taken: PropTypes.object,
  camera_unplugged: PropTypes.object,
  driver: PropTypes.object,
  vehicle: PropTypes.object,
  tow_required: PropTypes.object,
  description: PropTypes.object,
  injuries: PropTypes.object,
  injuries_description: PropTypes.object,
};

export default AccidentInfoCard;
