import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Api
import listContractTypesApi from '../../api/contractTypes.api.lease';

// Actions
import {set as setAct} from '../../redux/actions';

class ContractTypesSyncContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    children: PropTypes.node,
  };

  componentDidMount() {
    this.init();
  }

  init = () => {
    this.getContractTypes();
  };

  getContractTypes = async () => {
    const {dispatch} = this.props;

    dispatch(setAct({loadingContractTypes: true}));

    try {
      const {types} = await listContractTypesApi();
      dispatch(setAct({loadingContractTypes: false, contractTypes: types}));
    } catch (error) {
      dispatch(setAct({loadingContractTypes: false}));
    }
  };

  render() {
    const {children} = this.props;
    return !!children ? children : null;
  }
}

export default connect()(ContractTypesSyncContainer);
