import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {subMinutes} from 'date-fns';

// Actions
import {hide as hideAct} from '../../redux/actions';

// Api
import api from '../../../api/lib/getEverythingFromApi.lib.api';
import listApi from '../../api/list.api.externalDevice';

// Components
import ExternalDevices from '../../components/ExternalDevices/ExternalDevices';

// Lib
import {lib} from '@matthahn/sally-ui';
import parseError from '../../../error/parseError';
import wait from '../../../lib/wait';

// Query
import {greaterThanOrEqualTo} from '../../../api/queries/queries';

// Sockets
import abortSocket from '../../sockets/abort.socket.camera';
import cancelSocket from '../../sockets/cancel.socket.camera';
import completeSocket from '../../sockets/complete.socket.camera';
import openSocket from '../../sockets/open.socket.camera';

// Types
import {apiDateTime} from '../../../types';

// Alerts
const {alert} = lib;

class ExternalDevicesContainer extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    extension: PropTypes.string,
    timeout: PropTypes.number,
    handler: PropTypes.func,
    dispatch: PropTypes.func,
  };

  state = {
    loading: false,
    devices: [],
    device: null,
  };

  componentDidMount() {
    if (this.props.visible) this.init();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) return this.init();
    if (prevProps.visible && !this.props.visible) return this.unsubscribe();
  }

  subscribers = [];
  timeout = null;

  subscribe = () => {
    const {extension} = this.props;
    this.subscribers = [
      cancelSocket(extension).subscribe(this.checkDevice(this.onAbort)),
      completeSocket(extension).subscribe(this.checkDevice(this.onComplete)),
    ];
  };

  unsubscribe = () => {
    this.subscribers.forEach((unsubscribe) => unsubscribe());
    this.subscribers = [];
    clearTimeout(this.timeout);
    this.timeout = null;
  };

  init = async () => {
    const {dispatch} = this.props;

    this.setState({loading: true, devices: [], device: null});

    try {
      const devices = await this.getDevices();
      this.setState({loading: false, devices});
      this.refreshDevices();
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      dispatch(hideAct());
      this.setState({loading: false});
    }
  };

  getDevices = async () => {
    try {
      const {data} = await api(listApi, {
        ordering: 'id',
        online: true,
        [greaterThanOrEqualTo('modified_at')]: new Date(
          apiDateTime(subMinutes(new Date(), 1)).format()
        ).toISOString(),
      });
      return data;
    } catch (error) {
      return [];
    }
  };

  refreshDevices = async () => {
    await wait(5000);
    if (!this.props.visible) return;

    const devices = await this.getDevices();
    this.setState({devices});

    this.refreshDevices();
  };

  checkDevice = (fn) => (data) => {
    const {device} = this.state;
    if (!device || device.id !== data.deviceId) return;
    fn(data);
  };

  onClose = () => {
    const {loading, device} = this.state;
    if (loading || !!device) return;
    this.props.dispatch(hideAct());
  };

  onDevice = (device) => () => {
    const {extension, data, timeout} = this.props;
    if (this.state.loading) return;
    this.setState({device});
    this.subscribe();
    openSocket(extension).send({deviceId: device.id, data});
    if (!!timeout) this.timeout = setTimeout(this.onTimeout, timeout);
  };

  onCancel = () => {
    const {extension} = this.props;
    const {device} = this.state;
    this.unsubscribe();
    abortSocket(extension).send({deviceId: device.id});
    this.setState({device: null});
  };

  onAbort = () => {
    this.onCancel();
    alert.warning('Action cancelled on the external device');
  };

  onTimeout = () => {
    this.onCancel();
    alert.warning('Action could not be executed');
  };

  onComplete = (data) => {
    const {handler, dispatch} = this.props;
    handler(data);
    dispatch(hideAct());
  };

  render() {
    const {visible} = this.props;
    const {loading, devices, device} = this.state;
    return (
      <ExternalDevices
        visible={visible}
        loading={loading}
        devices={devices}
        device={device}
        onClose={this.onClose}
        onCancel={this.onCancel}
        onDevice={this.onDevice}
      />
    );
  }
}

export default connect((state) => ({...state.externalDevice}))(
  ExternalDevicesContainer
);
