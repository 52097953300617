import {SELECT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

export default attribute({
  type: SELECT,
  attribute: 'state',
  label: {
    default: 'Vehicle Status',
    short: 'Status',
  },
});
