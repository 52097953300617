import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal, Row, Column} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';
import Image from './components/Image';

const DriverFhvCaptureModal = ({
  isProduction,
  visible,
  loading,
  image,
  attributes,
  data,
  onClose,
  onChange,
  onSave,
}) => (
  <Modal
    title="Upload FHV"
    icon="scan1"
    visible={visible}
    onClose={onClose}
    buttonsRight={[
      {label: 'Upload', theme: 'orange', loading, onClick: onSave},
    ]}
  >
    {(Content) => (
      <Content>
        {!!image && (
          <Row margin>
            <Column>
              <Image src={image} />
            </Column>
          </Row>
        )}
        {attributes.map((attribute, index) => (
          <Row key={attribute} margin={index + 1 < attributes.length}>
            <Column>
              <AttributeInput
                value={data[attribute]}
                onChange={onChange}
                disabled={loading}
                computedValues={data}
                noPaste={isProduction}
              >
                {data[attribute].label.short}
              </AttributeInput>
            </Column>
          </Row>
        ))}
      </Content>
    )}
  </Modal>
);

DriverFhvCaptureModal.propTypes = {
  isProducion: PropTypes.bool,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  image: PropTypes.string,
  attributes: PropTypes.array,
  data: PropTypes.object,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
};

export default DriverFhvCaptureModal;
