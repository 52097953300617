import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {Row, Column, Form, Button, Info} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';
import StepFooter from '../../../layout/components/StepFooter/StepFooter';
import VehicleOnHoldInfo from '../../../vehicle/components/VehicleOnHoldInfo/VehicleOnHoldInfo';

const DispatchMissingVehicleInfoStep = ({
  Container,
  loading,
  vehicle,
  isRentable,
  isOnHold,
  plate,
  onChange,
  onNext,
  onRemoveFromHold,
}) => (
  <Fragment>
    {isRentable ? (
      <Container>
        <Form onSubmit={onNext} enter>
          <Row>
            <Column>
              <AttributeInput
                value={plate}
                onChange={onChange}
                disabled={loading}
                size="large"
              >
                {plate.label.default}
              </AttributeInput>
            </Column>
          </Row>
        </Form>
      </Container>
    ) : isOnHold ? (
      <Container padding="none">
        <VehicleOnHoldInfo
          hold={!!vehicle ? vehicle.vehicle_hold : null}
          onResolve={onRemoveFromHold}
          flat
        />
      </Container>
    ) : (
      <Container padding="none">
        <Info type="warning" flat>
          Vehicle is not ready to be rented
        </Info>
      </Container>
    )}
    {isRentable && (
      <Container>
        <StepFooter
          right={
            <Button
              size="small"
              theme="orange"
              loading={loading}
              onClick={onNext}
            >
              Next
            </Button>
          }
        />
      </Container>
    )}
  </Fragment>
);

DispatchMissingVehicleInfoStep.propTypes = {
  Container: PropTypes.object,
  loading: PropTypes.bool,
  vehicle: PropTypes.object,
  isRentable: PropTypes.bool,
  isOnHold: PropTypes.bool,
  plate: PropTypes.object,
  onChange: PropTypes.func,
  onNext: PropTypes.func,
  onRemoveFromHold: PropTypes.func,
};

export default DispatchMissingVehicleInfoStep;
