import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {Row, Column, Form, Button} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';
import StepFooter from '../../../layout/components/StepFooter/StepFooter';

const DispatchMissingDriverInfoStep = ({
  Container,
  loading,
  ssn,
  ssnConfirm,
  onChange,
  onBack,
  onNext,
}) => (
  <Fragment>
    <Container>
      <Form onSubmit={onNext} enter>
        <Row margin>
          <Column>
            <AttributeInput value={ssn} onChange={onChange} disabled={loading}>
              {ssn.label.default}
            </AttributeInput>
          </Column>
        </Row>
        <Row>
          <Column>
            <AttributeInput
              value={ssnConfirm}
              onChange={onChange}
              disabled={loading}
            >
              {ssnConfirm.label.default}
            </AttributeInput>
          </Column>
        </Row>
      </Form>
    </Container>
    <Container>
      <StepFooter
        left={
          <Button size="small" theme="grey" onClick={onBack} disabled={loading}>
            Back
          </Button>
        }
        right={
          <Button
            size="small"
            theme="orange"
            loading={loading}
            onClick={onNext}
          >
            Save
          </Button>
        }
      />
    </Container>
  </Fragment>
);

DispatchMissingDriverInfoStep.propTypes = {
  Container: PropTypes.func,
  loading: PropTypes.bool,
  ssn: PropTypes.object,
  ssnConfirm: PropTypes.object,
  onChange: PropTypes.func,
  onBack: PropTypes.func,
  onNext: PropTypes.func,
};

export default DispatchMissingDriverInfoStep;
