import prepare from '../../attribute/prepare.attribute';

// Validation
import amount from '../validations/amount.validation.transaction';

export default async (attributes) => {
  const credit = prepare(attributes, {
    field: 'api',
    required: ['amount', 'driver', 'subtype', 'description'],
  });
  amount(credit);
  credit.type = 'credit';
  return credit;
};
