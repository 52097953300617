import React from 'react';
import {Switch, Route} from 'react-router-dom';

// DriversList
import DriversListPage from '../../pages/DriversListPage/DriversListPage';
import routeDriversList from '../../pages/DriversListPage/route';

// Driver
import DriverPage from '../../pages/DriverPage/DriverPage';
import routeDriver from '../../pages/DriverPage/route';

const DriversPagesContainer = () => (
  <Switch>
    <Route exact path={routeDriversList()} component={DriversListPage} />
    <Route path={routeDriver()} component={DriverPage} />
  </Switch>
);

export default DriversPagesContainer;
