import {isDate, parseISO, format as formatDate} from 'date-fns';

import type from '../type';
import customDateTimeParser from '../customDateTimeParser.types';
import timeConverter from './helpers/time.converter';

const format = (value) => {
  if (!value) return '';
  const dateObj = isDate(value) ? value : parseISO(customDateTimeParser(value));
  const formattedDate = formatDate(dateObj, "yyyy-MM-dd'T'HH:mm:ssXXX");
  return timeConverter(dateObj, formattedDate);
};

const validate = () => true;

export default type({type: 'date', format, validate});
