import styled from 'styled-components';

// Lib
import {lib} from '@matthahn/sally-ui';

// Colors
const {
  colors: {orange},
} = lib;

const NotLive = styled.div`
  position: absolute;
  left: 210px;
  top: 50%;
  transform: translateY(-50%);
  color: ${orange};
  font-weight: 700;
  text-transform: uppercase;
  font-size: 28px;
`;

export default NotLive;
