import {NUMBER} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {number} from '../../types';

export default attribute({
  type: NUMBER,
  attribute: 'dol',
  api: number,
  label: {
    default: 'DOL',
    short: 'DOL',
  },
});
