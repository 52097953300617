import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal, Row, Column, Text} from '@matthahn/sally-ui';

// Types
import {dateTime, date, amount} from '../../../types';

const TollPreviewModal = ({visible, toll, onReassign, onClose}) => (
  <Modal
    visible={visible}
    title="Toll Preview"
    icon="tago"
    onClose={onClose}
    headerActions={[
      {
        icon: 'enter',
        onClick: onReassign,
        tooltip: 'Reassign',
      },
    ]}
  >
    {(Content) =>
      !toll ? null : (
        <Content>
          <Row margin>
            <Column size={1 / 2}>
              <Text value={dateTime(toll.date_created).format()}>Date</Text>
            </Column>
            <Column size={1 / 2}>
              <Text value={toll.activity}>activity</Text>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 2}>
              <Text value={date(toll.post_date).format()}>Post Date</Text>
            </Column>
            <Column size={1 / 2}>
              <Text value={dateTime(toll.transaction_date).format()}>
                Transaction Date
              </Text>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 2}>
              <Text value={`$${amount(toll.amount).format()}`}>Amount</Text>
            </Column>
            <Column size={1 / 2}>
              <Text value={`$${amount(toll.fee).format()}`}>Fee</Text>
            </Column>
          </Row>
          <Row>
            <Column>
              <Text value={toll.prepaid ? 'Yes' : 'No'}>Prepaid</Text>
            </Column>
          </Row>
        </Content>
      )
    }
  </Modal>
);

TollPreviewModal.propTypes = {
  visible: PropTypes.bool,
  toll: PropTypes.object,
  onClose: PropTypes.func,
  onReassign: PropTypes.func,
};

export default TollPreviewModal;
