import styled from 'styled-components';

const Side = styled.div`
  width: 350px;
  height: calc(100vh - 292px);
  position: fixed;
  top: 262px;
  left: 30px;
`;

export default Side;
