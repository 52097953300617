import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {
  set as setAct,
  syncDrivers as syncDriversAct,
  removeUnusedDrivers as removeUnusedDriversAct,
} from '../../../spotlight/redux/actions';

// Api
import listDriversApi from '../../api/list.api.driver';

// Constants
import STORAGE_KEY from '../../constants/spotlightStorageKey.constants.driver';

// Localstorage
import * as storage from '../../../lib/localStorage';

// Lib
import fkOrId from '../../../lib/fkOrId';

// Sockets
import createdSocket from '../../socket/created.socket.driver';
import updatedSocket from '../../socket/updated.socket.driver';
import dispatchedSocket from '../../../rental/sockets/dispatched.socket.rental';
import rentalEndedSocket from '../../../rental/sockets/ended.socket.rental';

class DriversSpotlightSync extends Component {
  static propTypes = {
    drivers: PropTypes.array,
    dispatch: PropTypes.func,
  };

  static FIELDS = [
    'id',
    'first_name',
    'last_name',
    'created_at',
    'modified_at',
    'ssn',
    'phone_number',
    'email',
    'fhv_license_number',
    'state',
  ];

  componentDidMount() {
    this.init();
    this.subscribe();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  subscribe = () => {
    this.subscriptions = [
      createdSocket.subscribe(this.driverSync),
      updatedSocket.subscribe(this.driverSync),
      dispatchedSocket.subscribe(this.onDispatchOrPayout),
      rentalEndedSocket.subscribe(this.onDispatchOrPayout),
    ];
  };

  unsubscribe = () => {
    this.subscriptions.forEach((unsubscribe) => unsubscribe());
    this.subscriptions = [];
  };

  init = async () => {
    const {dispatch} = this.props;
    const rawDrivers = storage.get(STORAGE_KEY);
    const drivers = !!rawDrivers ? JSON.parse(rawDrivers) : [];
    dispatch(syncDriversAct(drivers));
    dispatch(setAct({driversLoading: true}));
    const freshDrivers = await this.fetch();
    dispatch(removeUnusedDriversAct(freshDrivers));
    dispatch(setAct({driversLoading: false, driversLoaded: true}));
  };

  fetch = async ({drivers = [], limit = 500, offset = 0} = {}) => {
    const {FIELDS} = this.constructor;
    try {
      const {results, next} = await listDriversApi({
        limit,
        offset,
        fields: FIELDS.join(','),
      });
      const newList = [...drivers, ...results];
      this.props.dispatch(syncDriversAct(results));
      if (!!next)
        return this.fetch({
          drivers: newList,
          limit,
          offset: offset + limit,
        });
      return newList;
    } catch (error) {
      return drivers;
    }
  };

  driverSync = (driver) => {
    const {FIELDS} = this.constructor;
    const {dispatch} = this.props;
    const formattedDriver = [...FIELDS].reduce(
      (combined, field) => ({...combined, [field]: driver[field]}),
      {}
    );
    if (!!Object.values(formattedDriver).find((value) => value === undefined))
      return;
    dispatch(syncDriversAct([formattedDriver]));
  };

  onDispatchOrPayout = (rental) => {
    const {dispatch, drivers} = this.props;
    if (!rental.driver) return;
    const driver = [...drivers].find(({id}) => id === fkOrId(rental.driver));
    if (!driver) return;
    driver.state = rental.driver.state || driver.state;
    dispatch(syncDriversAct([driver]));
  };

  render() {
    return null;
  }
}

export default connect((state) => ({drivers: state.spotlight.drivers}))(
  DriversSpotlightSync
);
