import parseServerError from './server.error.api';

export default async (response) => {
  if (response.status >= 500) {
    const message = await response.text();
    throw new Error(message);
  }

  if (response.status < 200 || response.status >= 300) {
    const data = await response.json();
    throw new Error(parseServerError(data).message);
  }
};
