import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {
  startLoading,
  stopLoading,
  syncData,
  removeData,
} from '../../redux/actions';

// Api
import listApi from '../../api/list.api.medallionOwner';

// Events
import listedEvt from '../../events/listed.event.medallionOwner';
import createdEvt from '../../events/created.event.medallionOwner';
import updatedEvt from '../../events/updated.event.medallionOwner';
import deletedEvt from '../../events/deleted.event.medallionOwner';

// Lib
import wait from '../../../lib/wait';

class MedallionOwnerFetchContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    children: PropTypes.node,
  };

  static defaultProps = {
    children: null,
  };

  componentDidMount() {
    this.mounted = true;
    this.getMedallionOwners();
    this.subscribe();
  }

  componentWillUnmount() {
    this.mounted = false;
    this.unsubscribe();
  }

  subscribe = () => {
    this.unsub = [
      listedEvt.sub(this.onList),
      createdEvt.sub(this.onCreate),
      updatedEvt.sub(this.onUpdate),
      deletedEvt.sub(this.onDelete),
    ];
  };

  unsubscribe = () => {
    this.unsub.forEach((fn) => fn());
    this.unsub = [];
  };

  getMedallionOwners = async () => {
    const {dispatch} = this.props;
    if (!this.mounted) return;

    dispatch(startLoading());
    await this.fetchMedallionOwners();
    dispatch(stopLoading());
    await wait(60 * 1000);
    this.getMedallionOwners();
  };

  fetchMedallionOwners = async ({
    medallionOwners = [],
    limit = 200,
    offset = 0,
  } = {}) => {
    try {
      const {results, next} = await listApi({limit, offset});
      const newMedallionOwners = [...medallionOwners, ...results];
      if (!!next)
        return this.fetchMedallionOwners({
          medallionOwners: newMedallionOwners,
          limit,
          offset: offset + limit,
        });
      return newMedallionOwners;
    } catch (error) {
      return medallionOwners;
    }
  };

  onList = (...medallionOwners) =>
    this.props.dispatch(syncData(medallionOwners));

  onCreate = (medallionOwner) =>
    this.props.dispatch(syncData([medallionOwner]));

  onUpdate = (medallionOwner) =>
    this.props.dispatch(syncData([medallionOwner]));

  onDelete = (medallionOwnerID) =>
    this.props.dispatch(removeData(medallionOwnerID));

  render() {
    const {children} = this.props;
    return children;
  }
}

export default connect()(MedallionOwnerFetchContainer);
