import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Button} from '@matthahn/sally-ui';
import Container from './components/Container';
import Separator from './components/Separator';

const VehicleHeaderButtonSeparator = ({
  canBePutOnHold,
  hasSavePermission,
  canBeArchived,
  canBeEnabled,
  canBeDecommissioned,
  canRevertDecommission,
  saving,
  onSave,
  onHold,
  onArchive,
  onEnable,
  onDecommission,
  onRevertDecommission,
  onCreateVehicleAlert,
}) => (
  <Container>
    <Separator>
      <Button
        theme="grey"
        icon="exclamationcircleo"
        onClick={onCreateVehicleAlert}
        size="small"
      >
        Create vehicle alert
      </Button>
    </Separator>
    {canBeEnabled && (
      <Separator>
        <Button
          theme="grey"
          icon="check"
          size="small"
          disabled={saving}
          onClick={onEnable}
          outline
        >
          Enable
        </Button>
      </Separator>
    )}
    {canBeArchived && (
      <Separator>
        <Button
          theme="grey"
          icon="delete"
          size="small"
          disabled={saving}
          onClick={onArchive}
          outline
        >
          Archive
        </Button>
      </Separator>
    )}
    {canRevertDecommission && (
      <Separator>
        <Button
          theme="grey"
          icon="check"
          size="small"
          disabled={saving}
          onClick={onRevertDecommission}
          outline
        >
          Revert decommission
        </Button>
      </Separator>
    )}
    {canBeDecommissioned && (
      <Separator>
        <Button
          theme="grey"
          icon="delete"
          size="small"
          disabled={saving}
          onClick={onDecommission}
          outline
        >
          Decommission
        </Button>
      </Separator>
    )}
    {canBePutOnHold && (
      <Separator>
        <Button
          theme="grey"
          icon="warning"
          size="small"
          disabled={saving}
          onClick={onHold}
        >
          Put on hold
        </Button>
      </Separator>
    )}
    {hasSavePermission && (
      <Separator>
        <Button theme="orange" size="small" loading={saving} onClick={onSave}>
          Save
        </Button>
      </Separator>
    )}
  </Container>
);

VehicleHeaderButtonSeparator.propTypes = {
  canBePutOnHold: PropTypes.bool,
  hasSavePermission: PropTypes.bool,
  canBeArchived: PropTypes.bool,
  canBeEnabled: PropTypes.bool,
  canBeDecommissioned: PropTypes.bool,
  canRevertDecommission: PropTypes.bool,
  saving: PropTypes.bool,
  onSave: PropTypes.func,
  onHold: PropTypes.func,
  onArchive: PropTypes.func,
  onEnable: PropTypes.func,
  onDecommission: PropTypes.func,
  onRevertDecommission: PropTypes.func,
  onCreateVehicleAlert: PropTypes.func,
};

export default VehicleHeaderButtonSeparator;
