import authToken from '../../auth/lib/token.lib.auth';

export default ({authorize = true} = {}) => {
  const token = authToken.get();
  const h = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  if (!!token && authorize) h.Authorization = `JWT ${token}`;
  return h;
};
