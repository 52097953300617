import {date, fileName, folder} from '../../../document';

import {lease_rate, lease_start_date, lease_expiry} from '../../attributes';

export default folder({
  attributes: [lease_rate, lease_start_date, lease_expiry],
  defaultFileName: 'Lease',
  // LEASE - MEDALLION NUMBER - UPLOAD DATE
  fileName: fileName(
    ({medallion}) => `Lease - ${medallion.medallion_number} - ${date()}`
  ),
  folderName: 'Leases',
  type: 'lease',
});
