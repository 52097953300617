import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Card, Row, Column} from '@matthahn/sally-ui';
import AttributeText from '../../../layout/components/AttributeText/AttributeText';

const VehicleTollPassCard = ({
  loading,
  tollPassSet,
  onOpen,
  onShowUploader,
  onRemove,
  file,

  ezpass_tag_number,
}) => (
  <Card
    title="Toll Pass"
    icon="scan1"
    headerActions={[
      !!file &&
        tollPassSet && {
          icon: 'eyeo',
          onClick: onOpen,
          tooltip: 'Show file',
        },
      {
        icon: 'addfile',
        tooltip: 'Upload Toll Pass',
        onClick: onShowUploader,
        loading,
      },
      tollPassSet && {
        icon: 'delete',
        tooltip: 'Remove Toll Pass',
        onClick: onRemove,
        loading,
      },
    ].filter((act) => !!act)}
  >
    {(Content) => (
      <Content>
        <Row>
          <Column>
            <AttributeText attribute={ezpass_tag_number}>
              {ezpass_tag_number.label.default}
            </AttributeText>
          </Column>
        </Row>
      </Content>
    )}
  </Card>
);

VehicleTollPassCard.propTypes = {
  loading: PropTypes.bool,
  tollPassSet: PropTypes.bool,
  onOpen: PropTypes.func,
  onShowUploader: PropTypes.func,
  onRemove: PropTypes.func,
  file: PropTypes.object,
  ezpass_tag_number: PropTypes.object,
};

export default VehicleTollPassCard;
