import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {show as showTakePaymentAct} from '../../../payment/containers/TakePaymentContainer/redux/actions';
import {show as showChargeAct} from '../../../transaction/containers/ChargeContainer/redux/actions';
import {show as showCreditAct} from '../../../transaction/containers/CreditContainer/redux/actions';
import {show as showPayoutAct} from '../../../payout/containers/PayoutDriverContainer/redux/actions';

// Components
import DriverPaymentActions from '../../components/DriverPaymentActions/DriverPaymentActions';

// Permissions
import checkInAndPayoutPermission from '../../../payout/permission/checkInAndPayout.permission.payout';
import payoutPermission from '../../../payout/permission/payoutOnly.permission.payout';

class DriverPaymentActionsContainer extends Component {
  static propTypes = {
    driver: PropTypes.object,
    activeRental: PropTypes.object,
    dispatch: PropTypes.func,
  };

  actions = () =>
    [
      {
        key: 'takeMoneyNow',
        icon: '$',
        label: 'Take Payment',
        isDisabled: false,
        action: this.onTakeMoneyNow,
      },
      {
        key: 'charge',
        icon: 'creditcard',
        label: 'Charge',
        isDisabled: false,
        action: this.onCharge,
      },
      {
        key: 'credit',
        icon: 'upload',
        label: 'Credit',
        isDisabled: false,
        action: this.onCredit,
      },
      (checkInAndPayoutPermission() || payoutPermission()) && {
        key: 'payout',
        icon: 'enter',
        label: 'Payout',
        action: this.onPayout,
      },
    ].filter((act) => !!act);

  onTakeMoneyNow = () => {
    const {driver, dispatch} = this.props;
    dispatch(showTakePaymentAct(driver));
  };

  onCharge = () => {
    const {driver, dispatch, activeRental} = this.props;
    const addon = !!activeRental ? {rental: activeRental.id} : {};
    dispatch(showChargeAct(driver, addon));
  };

  onCredit = () => {
    const {driver, dispatch, activeRental} = this.props;
    const addon = !!activeRental ? {rental: activeRental.id} : {};
    dispatch(showCreditAct(driver, addon));
  };

  onPayout = () => {
    const {driver, activeRental, dispatch} = this.props;
    dispatch(
      showPayoutAct({driver, rental: activeRental || null, endRental: false})
    );
  };

  render() {
    return <DriverPaymentActions actions={this.actions()} />;
  }
}

export default connect((state) => ({
  driver: state.driver.driver,
  activeRental: state.driver.activeRental,
}))(DriverPaymentActionsContainer);
