// Constants
import DIRECT_DEPOSIT_KEY from '../constants/directDepositKey.constant.payout';

// Lib
import isDirectDeposit from './isDirectDeposit.lib.payout';

export default (paymentMethod) => {
  const dwolla_funding_id = isDirectDeposit(paymentMethod)
    ? paymentMethod.replace(DIRECT_DEPOSIT_KEY, '')
    : null;
  const payment_method = isDirectDeposit(paymentMethod)
    ? DIRECT_DEPOSIT_KEY
    : paymentMethod;
  return {dwolla_funding_id, payment_method};
};
