import styled from 'styled-components';

// Libs
import {lib} from '@matthahn/sally-ui';

// Colors
const {
  colors: {darkGrey},
} = lib;

const NoNotes = styled.div`
  width: 100%;
  text-align: center;
  padding: 20px 0px;
  color: ${darkGrey};
`;

export default NoNotes;
