import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

// Events
import event from '../events/unmanaged.event.medallion';

export default async (id, reason, jwt = null) => {
  const api = f3tch(`/medallions/${id}/unmanage/`).profile(profile());

  if (!!jwt) api.headers({Authorization: `JWT ${jwt}`});

  const response = await api.body({reason}).post();
  event.pub(response);
  return response;
};
