import {SET} from './constants';

export const set = (data = {}) => ({
  type: SET,
  data,
});

export const updateMedallion = (medallion) => (dispatch, getState) => {
  const oldMedallion = getState().medallion.medallion;
  if (!medallion.id || !oldMedallion || medallion.id !== oldMedallion.id)
    return;
  dispatch(set({medallion}));
};
