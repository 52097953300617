// accidents api
import listAccidentsApi from '../../../accident/api/list.api.accident';

// accidents container
import AssociatedAccidentsContainer from '../../../accident/containers/AssociatedAccidentsContainer/AssociatedAccidentsContainer';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// redux
import {connect} from 'react-redux';

class DriverAccidentsContainer extends Component {
  static propTypes = {driver: PropTypes.object};

  render() {
    const {driver} = this.props;
    return (
      <AssociatedAccidentsContainer
        api={listAccidentsApi}
        query={{driver: driver.id}}
        displayVehicle
      />
    );
  }
}

export default connect((state) => ({driver: state.driver.driver}))(
  DriverAccidentsContainer
);
