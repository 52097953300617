import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Api
import updateApi from '../../api/update.api.driver';

// Actions
import {updateDriver as updateDriverAct} from '../../redux/actions';
import {set as setInfoAct} from './redux/actions';

// Attributes
import * as driverAttributes from '../../attributes';
import objectToAttributes from '../../../attribute/objectToAttributes';

// Components
import {Row, Column} from '@matthahn/sally-ui';
import SubPage from '../../../layout/components/SubPage/SubPage';
import DriverBasicInfoCard from '../../components/DriverBasicInfoCard/DriverBasicInfoCard';
import DriverAddressCard from '../../components/DriverAddressCard/DriverAddressCard';
import DriverEmergencyCard from '../../components/DriverEmergencyCard/DriverEmergencyCard';
import DriverOtherCard from '../../components/DriverOtherCard/DriverOtherCard';
import DriverBlacklistedInfo from '../../components/DriverBlacklistedInfo/DriverBlacklistedInfo';

// Containers
import DriverDmvContainer from '../DriverDmvContainer/DriverDmvContainer';
import DriverFhvContainer from '../DriverFhvContainer/DriverFhvContainer';
import DriverSSNContainer from '../DriverSSNContainer/DriverSSNContainer';

// Error
import parseError from '../../../error/parseError';

// Libs
import {lib} from '@matthahn/sally-ui';
import {
  setUnsavedChanges,
  clearUnsavedChanges,
} from '../../../layout/lib/unsavedChanges.lib.layout';

// Prep
import updatePrep from '../../preparation/update.preparation.driver';

// State
import blacklistedState from '../../state/blacklisted.state.driver';

// Permissions
import updatePermission from '../../permissions/update.permission.driver';

// Alerts
const {alert} = lib;

class DriverInfoContainer extends Component {
  static propTypes = {
    driver: PropTypes.object,
    save: PropTypes.number,
  };

  static attributes = [
    'state',

    'first_name',
    'last_name',
    'phone_number',
    'phone_number_two',
    'email',
    'ssn',

    'address_street1',
    'address_street2',
    'address_city',
    'address_zip',
    'address_state',

    'date_of_birth',
    'dmv_license_number',
    'dmv_license_state',
    'dmv_license_expiry',
    'dmv_license_issue_date',

    'fhv_license_number',
    'fhv_license_expiry',

    'emergency_contact_name',
    'emergency_contact_phone',
    'emergency_contact_relationship',

    'kustomer_id',
  ];

  static driverAttributes = {
    ...driverAttributes,
  };

  state = {
    loading: false,
    driver: null,
    errors: [],
    showSSNUpdate: false,
  };

  componentDidMount() {
    this.mounted = true;
    this.setState({
      driver: objectToAttributes({
        object: this.props.driver,
        attributes: {
          ...this.constructor.driverAttributes,
        },
        convert: this.constructor.attributes,
      }),
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.save !== this.props.save) this.save();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  change = setUnsavedChanges(
    (value, key, errors) => {
      const {driver} = this.state;
      this.setState({driver: {...driver, [key]: value}, errors});
    },
    () => this.state.loading
  );

  showSSN = () => this.setState({showSSNUpdate: true});

  hideSSN = () => this.setState({showSSNUpdate: false});

  onSSNUpdate = (driver) => {
    this.props.dispatch(updateDriverAct(driver));
    this.refreshAttributes(['ssn']);
    this.hideSSN();
  };

  save = async () => {
    const {
      dispatch,
      driver: {id},
    } = this.props;
    const {
      loading,
      driver: {ssn, ...attributes},
    } = this.state;

    if (loading) return;

    if (!updatePermission())
      return alert.warning('You do not have permission to update driver info');

    this.setState({loading: true});
    dispatch(setInfoAct({saving: true}));

    try {
      const driver = await updatePrep(attributes);
      const response = await updateApi(id, driver);
      if (this.props.driver.id !== id) return;
      clearUnsavedChanges();
      dispatch(setInfoAct({saving: false}));
      if (!this.mounted) return;
      dispatch(updateDriverAct(response));
      alert.success('Driver info saved');
      this.setState({loading: false});
    } catch (error) {
      const {message, fields} = parseError(error);
      alert.error(message);
      dispatch(setInfoAct({saving: false}));
      if (!this.mounted) return;
      this.setState({loading: false, errors: fields});
    }
  };

  refreshAttributes = (attributes) => {
    const {driver} = this.state;
    const newDriverAttributes = objectToAttributes({
      object: this.props.driver,
      attributes: {
        ...this.constructor.driverAttributes,
      },
      convert: attributes,
    });

    this.setState({driver: {...driver, ...newDriverAttributes}});
  };

  render() {
    const {driver: rawDriver} = this.props;
    const {loading, driver, errors, showSSNUpdate} = this.state;
    return !driver ? null : (
      <SubPage>
        {rawDriver.state === blacklistedState.key && (
          <Row margin>
            <Column>
              <DriverBlacklistedInfo driver={rawDriver} />
            </Column>
          </Row>
        )}
        <Row margin>
          <Column size={1 / 3} m={1 / 2} margin>
            <DriverBasicInfoCard
              loading={loading}
              errors={errors}
              onChange={this.change}
              onSave={this.save}
              onSSN={this.showSSN}
              {...driver}
            />
          </Column>
          <Column size={1 / 3} m={1 / 2} margin>
            <DriverAddressCard
              loading={loading}
              errors={errors}
              onChange={this.change}
              onSave={this.save}
              {...driver}
            />
          </Column>
          <Column size={1 / 3} m={1 / 2} margin>
            <DriverDmvContainer
              loading={loading}
              errors={errors}
              driver={rawDriver}
              onChange={this.change}
              onSave={this.save}
              onAttributeRefresh={this.refreshAttributes}
              {...driver}
            />
          </Column>
          <Column size={1 / 3} m={1 / 2} margin>
            <DriverFhvContainer
              loading={loading}
              errors={errors}
              driver={rawDriver}
              onChange={this.change}
              onSave={this.save}
              onAttributeRefresh={this.refreshAttributes}
              {...driver}
            />
          </Column>
          <Column size={1 / 3} m={1 / 2} margin>
            <DriverEmergencyCard
              loading={loading}
              errors={errors}
              onChange={this.change}
              onSave={this.save}
              {...driver}
            />
          </Column>
          <Column size={1 / 3} m={1 / 2} margin>
            <DriverOtherCard
              loading={loading}
              errors={errors}
              onChange={this.change}
              onSave={this.save}
              {...driver}
            />
          </Column>
        </Row>
        <DriverSSNContainer
          driver={rawDriver}
          visible={showSSNUpdate}
          onComplete={this.onSSNUpdate}
          onClose={this.hideSSN}
        />
      </SubPage>
    );
  }
}

export default connect((state) => ({
  driver: state.driver.driver,
  save: state.driverInfo.save,
}))(DriverInfoContainer);
