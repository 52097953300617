import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Components
import {Row, Column, Table, InputTyping} from '@matthahn/sally-ui';
import HighlightTicket from '../HighlightTicket/HighlightTicket';
import PageContainer from './components/Container';
import Search from './components/Search';
import Content from './components/Content';

// Statuses
import status from '../../statuses/status';

// Types
import {amount as amountType, dateTime as dateTimeType} from '../../../types';

class TicketsList extends Component {
  static propTypes = {
    medallion: PropTypes.string,
    results: PropTypes.number,
    loading: PropTypes.bool,
    tickets: PropTypes.array,
    page: PropTypes.number,
    pages: PropTypes.number,
    onPage: PropTypes.func,
    sort: PropTypes.object,
    onSort: PropTypes.func,
    onMedallion: PropTypes.func,
  };

  columns = () => [
    {
      key: 'summons_number',
      label: 'Summons Number',
      sortable: true,
    },
    {
      key: 'violation_datetime',
      label: 'Violation Date',
      sortable: true,
    },
    {
      key: 'due_datetime',
      label: 'Due Date',
      sortable: true,
    },
    {
      key: 'status',
      label: 'Status',
      sortable: true,
    },
    {
      key: 'violation',
      label: 'Violation',
      span: 2,
    },
    {
      key: 'license_plate',
      label: 'License Plate',
    },
    {
      key: 'driver',
      label: 'Driver',
    },
    {
      key: 'fine_amount',
      label: 'Fine Amount',
      sortable: true,
    },
    {
      key: 'amount_due',
      label: 'Amount Due',
      sortable: true,
    },
  ];

  negativeInParantheses = (value) => {
    const dom = <span>${amountType(Math.abs(value)).format()}</span>;
    return value < 0 ? <span>({dom})</span> : dom;
  };

  render() {
    const {
      medallion,
      loading,
      tickets,
      page,
      pages,
      onPage,
      sort,
      onSort,
      onTicket,
      onMedallion,
    } = this.props;
    return (
      <PageContainer>
        <Search>
          <Row margin>
            <Column size={1 / 4} offset={3 / 8}>
              <InputTyping
                value={medallion}
                onChange={onMedallion}
                preIcon="search1"
                placeholder="Search"
              />
            </Column>
          </Row>
        </Search>
        <Content>
          <Row margin>
            <Column>
              <Table
                columns={this.columns()}
                infinite={false}
                page={page}
                pages={pages}
                onPage={onPage}
                loading={loading}
                sort={sort}
                onSort={onSort}
                theme="orange"
              >
                {(TableRow) =>
                  tickets.map((ticket) => (
                    <TableRow onClick={onTicket(ticket)}>
                      {(TableCol) => [
                        <TableCol key="summons_number">
                          <HighlightTicket ticket={ticket}>
                            {ticket.summons_number}
                          </HighlightTicket>
                        </TableCol>,
                        <TableCol key="violation_datetime">
                          <HighlightTicket ticket={ticket}>
                            {dateTimeType(ticket.violation_datetime).format() ||
                              '-'}
                          </HighlightTicket>
                        </TableCol>,
                        <TableCol key="due_datetime">
                          <HighlightTicket ticket={ticket}>
                            {dateTimeType(ticket.due_datetime).format()}
                          </HighlightTicket>
                        </TableCol>,
                        <TableCol key="status">
                          <HighlightTicket ticket={ticket}>
                            {status(ticket).label}
                          </HighlightTicket>
                        </TableCol>,
                        <TableCol key="violation" span={2}>
                          <HighlightTicket ticket={ticket}>
                            {ticket.violation}
                          </HighlightTicket>
                        </TableCol>,
                        <TableCol key="license_plate">
                          <HighlightTicket ticket={ticket}>
                            {ticket.license_plate}
                          </HighlightTicket>
                        </TableCol>,
                        <TableCol key="driver">
                          <HighlightTicket ticket={ticket}>
                            {!!ticket.rental && ticket.rental.driver
                              ? `${ticket.rental.driver.first_name} ${ticket.rental.driver.last_name}`
                              : '-'}
                          </HighlightTicket>
                        </TableCol>,
                        <TableCol key="fine_amount">
                          <HighlightTicket ticket={ticket}>
                            {this.negativeInParantheses(ticket.fine_amount)}
                          </HighlightTicket>
                        </TableCol>,
                        <TableCol key="amount_due">
                          <HighlightTicket ticket={ticket}>
                            {this.negativeInParantheses(ticket.amount_due)}
                          </HighlightTicket>
                        </TableCol>,
                      ]}
                    </TableRow>
                  ))
                }
              </Table>
            </Column>
          </Row>
        </Content>
      </PageContainer>
    );
  }
}

export default TicketsList;
