import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import amountAttribute from '../../attributes/amount.attribute.rental';
import parseError from '../../../error/parseError';

// Api
import api from '../../../api/lib/getEverythingFromApi.lib.api';
import getRentalByID from '../../api/getByID.api.rental';
import listTripsApi from '../../../trip/api/list.api.trip';
import getDriverByID from '../../../driver/api/getByID.api.driver';
import getMedallionByID from '../../../medallion/api/getByID.api.medallion';
import listTollsApi from '../../../toll/api/list.api.toll';
import listRentalReportsApi from '../../../rentalReport/api/list.api.rentalReport';
import rentalRateApi from '../../api/updateRentalRate.api.rental';

// Components
import RentalLoader from '../../components/RentalLoader/RentalLoader';
import Rental from '../../components/Rental/Rental';
import TollPreviewModal from '../../../toll/components/TollPreviewModal/TollPreviewModal';
import TripPreviewModal from '../../../trip/components/TripPreviewModal/TripPreviewModal';
import NewRentalRateModal from '../../components/Rental/components/NewRentalRateModal';

// Container
import ReassignTollContainer from '../../../toll/containers/ReassignTollContainer/ReassignTollContainer';

// Lib
import {lib} from '@matthahn/sally-ui';
import fkOrId from '../../../lib/fkOrId';

// Routes
import dispatcherRoute from '../../../dispatch/pages/DispatcherPage/route';
import driverRoute from '../../../driver/pages/DriverPage/route';
import medallionRoute from '../../../medallion/pages/MedallionPage/route';
import vehicleRoute from '../../../vehicle/pages/VehiclePage/route';

// Sockets
import dispatchedSocket from '../../sockets/dispatched.socket.rental';
import rentalEndedSocket from '../../../rental/sockets/ended.socket.rental';
import createdTripSocket from '../../../trip/sockets/created.socket.trip';
import updatedTripSocket from '../../../trip/sockets/updated.socket.trip';
import deletedTripSocket from '../../../trip/sockets/deleted.socket.trip';

// Alert
const {alert} = lib;

class RentalContainer extends Component {
  static propTypes = {
    id: PropTypes.string,
    history: PropTypes.object,
  };

  state = {
    loading: false,
    rental: null,
    trips: [],
    tolls: [],
    driver: null,
    medallion: null,
    vehicle: null,
    tripVisible: false,
    trip: null,
    tollVisible: false,
    toll: null,
    reassignVisible: false,
    rentalReport: null,
    downloading: false,
    newRentalRateVisible: false,
    rentalRateAmount: amountAttribute(''),
    savingRentalRate: false,
  };

  componentDidMount() {
    this.mounted = true;
    this.init();
    this.subscribe();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.id !== this.props.id) this.init();
  }

  componentWillUnmount() {
    this.mounted = false;
    this.unsubscribe();
  }

  subscribe = () => {
    this.subscriptions = [
      dispatchedSocket.subscribe(this.rentalUpdate),
      rentalEndedSocket.subscribe(this.rentalUpdate),
      createdTripSocket.subscribe(this.tripCreated),
      updatedTripSocket.subscribe(this.tripUpdated),
      deletedTripSocket.subscribe(this.tripDeleted),
    ];
  };

  unsubscribe = () => {
    this.subscriptions.forEach((unsubscribe) => unsubscribe());
    this.subscriptions = [];
  };

  rentalUpdate = (rental) => {
    const {rental: currentRental} = this.state;
    if (!currentRental || currentRental.id !== rental.id) return;
    this.setState({rental});
  };

  tripCreated = (trip) => {
    const {rental, trips} = this.state;
    if (!rental || trip.rental !== rental.id) return;
    const newTrips = [...trips, trip];
    this.setState({trips: newTrips});
  };

  tripUpdated = (trip) => {
    const {rental, trips} = this.state;
    if (!rental || trip.rental !== rental.id) return;
    const newTrips = [...trips].map((t) => (t.id === trip.id ? trip : t));
    this.setState({trips: newTrips});
  };

  tripDeleted = (trip) => {
    const {rental, trips} = this.state;
    if (!rental || trip.rental !== rental.id) return;
    const newTrips = [...trips].filter(({id}) => trip.id !== id);
    this.setState({trips: newTrips});
  };

  updateRentalRateAmount = (amount) => {
    this.setState({rentalRateAmount: amount});
  };

  saveRentalRate = async () => {
    const {savingRentalRate, rentalRateAmount, rental} = this.state;
    if (savingRentalRate) return true;
    const amount = rentalRateAmount.api.format();
    if (amount <= 0) {
      return alert.warning('Rental rate must be greater than 0!');
    }

    try {
      this.setState({savingRentalRate: true});
      const newRentalRate = await rentalRateApi({amount, rental: rental.id});
      this.setState({
        newRentalRateVisible: false,
        savingRentalRate: false,
        rental: {...rental, amount: newRentalRate.amount},
      });
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({savingRentalRate: false});
    }
  };

  init = async () => {
    const {id, history} = this.props;
    this.setState({loading: true});

    try {
      const rental = await getRentalByID(id);
      const {data: trips} = await api(listTripsApi, {
        rental: rental.id,
        ordering: 'end_datetime',
      });
      const [driver, medallion, {data: rawTolls}, {results: rentalReports}] =
        await Promise.all([
          getDriverByID(fkOrId(rental.driver)),
          getMedallionByID(fkOrId(rental.medallion)),
          api(listTollsApi, {rental: rental.id, removed: false}),
          listRentalReportsApi({
            rental: rental.id,
            driver: fkOrId(rental.driver),
          }),
        ]);
      const rentalReport = [...rentalReports].find(
        (sr) => sr.rental === rental.id && sr.driver === fkOrId(rental.driver)
      );
      const tolls = [...rawTolls].filter(
        (toll) => fkOrId(toll.rental) === rental.id
      );
      if (!this.mounted) return;
      this.setState({
        loading: false,
        rental,
        trips,
        tolls,
        driver,
        medallion,
        rentalReport,
        vehicle: {...medallion.vehicle},
        newRentalRateVisible: false,
      });
    } catch (error) {
      if (!this.mounted) return;
      alert.warning('This rental does not exist');
      history.replace(dispatcherRoute());
    }
  };

  showTrip = (trip) => () => this.setState({tripVisible: true, trip});

  hideTrip = () => this.setState({tripVisible: false});

  showToll = (toll) => () => this.setState({tollVisible: true, toll});

  hideToll = () => this.setState({tollVisible: false});

  onDriver = () => this.props.history.push(driverRoute(this.state.driver.id));

  onMedallion = () =>
    this.props.history.push(medallionRoute(this.state.medallion.id));

  onVehicle = () =>
    this.props.history.push(vehicleRoute(this.state.vehicle.id));

  showReassignToll = () => this.setState({reassignVisible: true});

  hideReassignToll = () => this.setState({reassignVisible: false});

  showNewRentalRate = () =>
    this.setState({
      newRentalRateVisible: true,
      rentalRateAmount: amountAttribute(''),
    });

  hideNewRentalRate = () => this.setState({newRentalRateVisible: false});

  tollReassigningComplete = () => {
    const {toll} = this.state;
    if (!toll) return;
    this.setState({
      reassignVisible: false,
      tollVisible: false,
      tolls: [...this.state.tolls].filter((t) => t.id !== toll.id),
    });
  };

  render() {
    const {
      loading,
      rental,
      trips,
      tolls,
      driver,
      medallion,
      vehicle,
      tripVisible,
      trip,
      tollVisible,
      toll,
      reassignVisible,
      newRentalRateVisible,
      rentalRateAmount,
      savingRentalRate,
    } = this.state;
    return (
      <Fragment>
        {loading || !rental ? (
          <RentalLoader />
        ) : (
          <Rental
            rental={rental}
            trips={trips}
            tolls={tolls}
            driver={driver}
            medallion={medallion}
            vehicle={vehicle}
            onDriver={this.onDriver}
            onMedallion={this.onMedallion}
            onVehicle={this.onVehicle}
            onTrip={this.showTrip}
            onToll={this.showToll}
            onNewRentalRate={this.showNewRentalRate}
          />
        )}
        <TripPreviewModal
          visible={tripVisible}
          trip={trip}
          onClose={this.hideTrip}
        />
        <TollPreviewModal
          visible={tollVisible && !reassignVisible}
          toll={toll}
          onReassign={this.showReassignToll}
          onClose={this.hideToll}
        />
        <ReassignTollContainer
          visible={reassignVisible}
          toll={toll}
          onClose={this.hideReassignToll}
          onComplete={this.tollReassigningComplete}
        />
        <NewRentalRateModal
          visible={newRentalRateVisible}
          amount={rentalRateAmount}
          loading={savingRentalRate}
          onChange={this.updateRentalRateAmount}
          onClose={this.hideNewRentalRate}
          onSave={this.saveRentalRate}
        />
      </Fragment>
    );
  }
}

export default withRouter(RentalContainer);
