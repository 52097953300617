import {isDate, parseISO, format as formatDate} from 'date-fns';

import type from '../type';
import customDateTimeParser from '../customDateTimeParser.types';
import timeConverter from './helpers/time.converter';

const format = (value) => {
  if (!value) return '';
  const dateObj = isDate(value) ? value : parseISO(customDateTimeParser(value));
  const formattedDate = formatDate(dateObj, 'M.d h:mmaaaaa');
  return timeConverter(dateObj, formattedDate);
};

const validate = (value) =>
  /^\d{1,2}\.\d{1,2}\s{1}\d{1,2}:{1}\d{2}(a|p){1}$/.test(value);

export default type({type: 'string', format, validate});
