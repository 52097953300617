export default [
  {
    key: 'id',
    label: 'Trip',
  },
  {
    key: 'start_date',
    label: 'Start',
  },
  {
    key: 'end_date',
    label: 'End',
  },
  {
    key: 'num_service',
    label: 'Num Service',
  },
  {
    key: 'type',
    label: 'Type',
  },
  {
    key: 'price',
    label: 'Price',
  },
];
