import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {uppercase} from '../../types';

export default attribute({
  type: TEXT,
  attribute: 'vehicle_model',
  display: uppercase,
  input: uppercase,
  api: uppercase,
  label: {
    default: 'Vehicle Model',
    short: 'Model',
  },
});
