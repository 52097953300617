import {subYears} from 'date-fns';

import {DATE} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {date, apiDate} from '../../types';

export default attribute({
  type: DATE,
  attribute: 'date_of_birth',
  display: date,
  input: date,
  api: apiDate,
  label: {
    default: 'Date of Birth',
    short: 'Birthdate',
  },
  disabledDates: [
    {
      after: subYears(new Date(), 18),
      message: 'Person needs to be older than 18 years',
    },
  ],
});
