import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal, FrameDoc} from '@matthahn/sally-ui';

const PoliceReportModal = ({visible, onClose, policeReport, onUpload}) =>
  !policeReport ? null : (
    <Modal
      visible={visible}
      onClose={onClose}
      size="large"
      icon="pdffile1"
      title="Police Report"
      headerActions={[{icon: 'upload', tooltip: 'Upload', onClick: onUpload}]}
    >
      {(Content) => (
        <Content padding="none">
          <FrameDoc file={policeReport} height="70vh" />
        </Content>
      )}
    </Modal>
  );

PoliceReportModal.propTypes = {
  visible: PropTypes.bool,
  closable: PropTypes.bool,
  loading: PropTypes.bool,
  policeReport: PropTypes.object,
  onUpload: PropTypes.func,
  onGenerate: PropTypes.func,
  onClose: PropTypes.func,
};

export default PoliceReportModal;
