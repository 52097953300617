import styled from 'styled-components';

// Colors
import {lib} from '@matthahn/sally-ui';
const {
  colors: {grey},
} = lib;

const Connected = styled.div`
  width: 100%;
  border: 1px solid ${grey};
  padding: 20px;
`;

export default Connected;
