import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// API
import takePaymentApi from '../../api/takeNow.api.payment';
import getDriverByIDApi from '../../../driver/api/getByID.api.driver';

// Actions
import {hide as hideAct} from './redux/actions';
import {show as showPayoutAct} from '../../../payout/containers/PayoutDriverContainer/redux/actions';

// Attributes
import amountAttr from '../../attributes/amount.attribute.payment';
import integerAmountAttr from '../../attributes/integerAmount.attribute.payment';
import checkNumberAttr from '../../attributes/check_number.attribute.payment';
import driverAttr from '../../attributes/driver.attribute.payment';
import subtypeAttr from '../../attributes/subtype.attribute.payment';

// Components
import TakePaymentModal from '../../components/TakePaymentModal/TakePaymentModal';

// Libs
import {lib} from '@matthahn/sally-ui';
import parseError from '../../../error/parseError';

// Prep
import takePaymentPrep from '../../preparation/takeNow.preparation.payment';

// UI
const {alert} = lib;

class TakePaymentContainer extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    driver: PropTypes.object,
    allocate: PropTypes.bool,
    dispatch: PropTypes.func,
  };

  static INITIAL_STATE = {
    loading: false,
    amount: integerAmountAttr(''),
    subtype: subtypeAttr(subtypeAttr?.additional?.options[0]?.value || ''),
    check_number: checkNumberAttr(''),
  };

  state = {...this.constructor.INITIAL_STATE};

  componentDidMount() {
    if (this.props.visible) this.init();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) this.init();
  }

  init = () => {
    this.setState({...this.constructor.INITIAL_STATE});
  };

  hide = () => {
    if (this.state.loading) return;
    this.props.dispatch(hideAct(false));
  };

  change = (val, key) => {
    const {loading, amount} = this.state;
    if (loading) return;
    const state = {[key]: val};
    if (key === 'subtype')
      state.amount =
        val.api.format() === 'cash'
          ? integerAmountAttr(Math.round(amount.api.format()))
          : amountAttr(amount.api.format());
    this.setState(state);
  };

  save =
    (allocate = true) =>
    async () => {
      const {visible, dispatch, driver} = this.props;

      const {loading, amount, subtype, check_number} = this.state;

      if (!visible || !driver || loading) return;

      this.setState({loading: true});

      try {
        const payment = await takePaymentPrep({
          amount,
          subtype,
          check_number,
          driver: driverAttr(driver.id),
        });
        const api = () =>
          this.takePayment({
            payment,
            driver,
          });
        if (allocate) {
          dispatch(
            showPayoutAct({
              driver,
              incomeAllocation: {label: 'Payment', transaction: payment, api},
            })
          );
          dispatch(hideAct(false));
          return;
        }
        await api();
        this.setState({loading: false});
        alert.success('Payment successful');
        dispatch(hideAct(true));
      } catch (error) {
        const {message} = parseError(error);
        alert.error(message);
        this.setState({loading: false});
      }
    };

  takePayment = async ({payment, driver}) => {
    await takePaymentApi(payment);
    await getDriverByIDApi(driver.id);
  };

  render() {
    const {visible, allocate} = this.props;
    const {loading, amount, subtype, check_number} = this.state;
    return (
      <TakePaymentModal
        allocate={allocate}
        visible={visible}
        loading={loading}
        isCheck={subtype.api.format() === 'check'}
        amount={amount}
        subtype={subtype}
        check_number={check_number}
        onSave={this.save(true)}
        onIssue={this.save(false)}
        onChange={this.change}
        onClose={this.hide}
      />
    );
  }
}

export default connect((state) => ({
  ...state.takePayment,
}))(TakePaymentContainer);
