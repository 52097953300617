export default [
  {
    key: 'vehicle',
    label: 'Vehicle',
    span: 2,
  },
  {
    key: 'start',
    label: 'Start',
  },
  {
    key: 'end',
    label: 'End',
  },
];
