import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {v4} from 'uuid';
import {withRouter, Switch, Route} from 'react-router-dom';
import {connect} from 'react-redux';

// Api
import getMedallionByIDApi from '../../api/getByID.api.medallion';

// Actions
import {set as setAct} from '../../redux/actions';

// Components
import MedallionLoader from '../../components/MedallionLoader/MedallionLoader';
import MedallionPageLayout from '../../components/MedallionPageLayout/MedallionPageLayout';

// Events
import enabledEvt from '../../events/enabled.event.medallion';
import storedEvt from '../../events/stored.event.medallion';

// Libs
import {lib} from '@matthahn/sally-ui';
import eventRace from '../../../events/race.event';

// Route
import medallionsListRoute from '../../pages/MedallionsListPage/route';

// MedallionInfoSave
import MedallionInfoSavePage from '../../pages/MedallionInfoSavePage/MedallionInfoSavePage';
import routeMedallionInfoSave from '../../pages/MedallionInfoSavePage/route';

// MedallionInfo
import MedallionInfoPage from '../../pages/MedallionInfoPage/MedallionInfoPage';
import routeMedallionInfo from '../../pages/MedallionInfoPage/route';

// MedallionRentals
import MedallionRentalsPage from '../../pages/MedallionRentalsPage/MedallionRentalsPage';
import routeMedallionRentals from '../../pages/MedallionRentalsPage/route';

// MedallionDocuments
import MedallionDocumentsPage from '../../pages/MedallionDocumentsPage/MedallionDocumentsPage';
import routeMedallionDocuments from '../../pages/MedallionDocumentsPage/route';

// MedallionRedirect
import MedallionRedirectPage from '../../pages/MedallionRedirectPage/MedallionRedirectPage';
import routeMedallionRedirect from '../../pages/MedallionRedirectPage/route';

// Sockets
import updatedSocket from '../../socket/updated.socket.medallion';

// Alert
const {alert} = lib;

class MedallionContainer extends Component {
  static propTypes = {
    id: PropTypes.string,
    dispatch: PropTypes.func,
    loading: PropTypes.bool,
    history: PropTypes.object,
    location: PropTypes.object,
    loadingMedallion: PropTypes.bool,
    medallion: PropTypes.object,
    loadingNotes: PropTypes.bool,
    notes: PropTypes.array,
  };

  componentDidMount() {
    this.init(this.props.id);
    this.subscribe();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) this.init(this.props.id);
  }

  componentWillUnmount() {
    this.mounted = false;
    this.unsubscribe();
  }

  mounted = true;
  apiID = v4();
  events = [];

  subscribe = () => {
    this.events = [
      enabledEvt.sub(this.remoteUpdate),
      storedEvt.sub(this.remoteUpdate),

      updatedSocket.subscribe(this.remoteUpdate),
    ];
  };

  unsubscribe = () => {
    this.events.forEach((unsubscribe) => unsubscribe());
  };

  init = (id) => {
    this.apiID = v4();
    this.getMedallion(id);
  };

  remoteUpdate = eventRace((newMedallion) => {
    const {medallion, dispatch} = this.props;
    if (
      !medallion ||
      medallion.id !== newMedallion.id ||
      medallion.state === newMedallion.state
    )
      return;
    dispatch(
      setAct({
        medallion: {
          ...medallion,
          state: newMedallion.state,
          medallion_stored:
            newMedallion?.medallion_stored ||
            medallion?.medallion_stored ||
            null,
        },
      })
    );
  });

  stop = (apiID) => this.apiID !== apiID || !this.mounted;

  getMedallion = async (id) => {
    const {dispatch} = this.props;
    const apiID = this.apiID;

    dispatch(setAct({loadingMedallion: true}));

    try {
      const medallion = await getMedallionByIDApi(id);
      if (this.stop(apiID)) return;
      dispatch(
        setAct({
          loadingMedallion: false,
          medallion,
        })
      );
    } catch (error) {
      if (this.stop(apiID)) return;
      alert.warning('This medallion does not exist');
      this.props.history.replace(medallionsListRoute());
    }
  };

  tabs = () => {
    const {
      medallion: {id},
    } = this.props;
    return [
      {
        id: routeMedallionInfo(id),
        label: 'Info',
      },
      {
        id: routeMedallionRentals(id),
        label: 'Rentals',
        selected: this.props.location.pathname.startsWith(
          routeMedallionRentals(id)
        ),
      },
      {
        id: routeMedallionDocuments(id),
        label: 'Documents',
      },
    ];
  };

  onTab = (route) => this.props.history.push(route);

  addon = () => (
    <Switch>
      <Route
        exact
        path={routeMedallionInfoSave()}
        component={MedallionInfoSavePage}
      />
    </Switch>
  );

  render() {
    const {
      id,
      loadingMedallion,
      medallion,
      location: {pathname},
    } = this.props;
    return loadingMedallion ||
      !medallion ||
      (!!medallion && `${medallion.id}` !== id) ? (
      <MedallionLoader />
    ) : (
      <MedallionPageLayout
        medallion_number={medallion.medallion_number}
        state={medallion.state}
        vehicle={medallion.vehicle}
        addon={this.addon()}
        tab={pathname}
        tabs={this.tabs()}
        onTab={this.onTab}
      >
        <Switch>
          <Route
            exact
            path={routeMedallionInfo()}
            component={MedallionInfoPage}
          />
          <Route
            path={routeMedallionRentals()}
            component={MedallionRentalsPage}
          />
          <Route
            exact
            path={routeMedallionDocuments()}
            component={MedallionDocumentsPage}
          />
          <Route
            path={routeMedallionRedirect()}
            component={MedallionRedirectPage}
          />
        </Switch>
      </MedallionPageLayout>
    );
  }
}

export default withRouter(
  connect((state) => ({
    ...state.medallion,
  }))(MedallionContainer)
);
