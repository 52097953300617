import styled from 'styled-components';

// Colors
import {lib} from '@matthahn/sally-ui';
const {
  colors: {grey},
} = lib;

const TableRow = styled.div`
  width: 100%;
  display: flex;
  font-weight: 400;
  font-size: 13px;
  border-bottom: 1px solid ${grey};
  cursor: pointer;
`;

export default TableRow;
