import {date, fileName, folder} from '../../../document';

// Attributes
import {
  plate as plateAttr,
  ezpass_tag_number as ezpassTag,
  ezpass_tag_number_confirm as ezpassTagConfirm,
  toll_pass_activation_number as tollPassActivationNumber,
  toll_pass_activation_number_confirm as tollPassActivationNumberConfirm,
} from '../../attributes';

// Errors
import plateMissmatchErr from '../../errors/plateMissmatch.error.vehicle';
import tollTagMissmatchErr from '../../errors/tollTagMissmatch.error.vehicle';
import tollPassActivationNumberMissmatchErr from '../../errors/tollPassActivationNumberMissmatch.error.vehicle';

export default folder({
  attributes: [
    plateAttr,
    ezpassTag,
    ezpassTagConfirm,
    tollPassActivationNumber,
    tollPassActivationNumberConfirm,
  ],
  fileAttributes: [
    'plate',
    'ezpass_tag_number_confirm',
    'toll_pass_activation_number',
    'toll_pass_activation_number_confirm',
  ],
  optionalAttributes: [
    'toll_pass_activation_number',
    'toll_pass_activation_number_confirm',
  ],
  defaultFileName: 'Toll Pass',
  // TOLL PASS - SVID - UPLOAD DATE
  fileName: fileName(
    ({vehicle}) =>
      `TOLL PASS - ${
        !!vehicle.medallion ? vehicle.medallion.medallion_number : vehicle.vin
      } - ${date()}`
  ),
  folderName: 'Toll Passes',
  preSaveValidation: ({
    vehicle,
    plate,
    ezpass_tag_number,
    ezpass_tag_number_confirm,
    toll_pass_activation_number,
    toll_pass_activation_number_confirm,
  }) => {
    if (vehicle.plate !== plate.api.format())
      throw plateMissmatchErr({message: 'Invalid Plate'});

    if (
      ezpass_tag_number.api.format() !== ezpass_tag_number_confirm.api.format()
    )
      throw tollTagMissmatchErr({
        message: 'Toll Pass Tag Number missmatch',
      });

    if (
      toll_pass_activation_number.api.format() !==
      toll_pass_activation_number_confirm.api.format()
    )
      throw tollPassActivationNumberMissmatchErr({
        message: 'Toll Pass Activation Number missmatch',
      });
  },
  type: 'tollPass',
});
