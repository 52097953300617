import styled from 'styled-components';

const ActionContainer = styled.div`
  width: 100%;
  text-align: center;
  user-select: none;
  cursor: pointer;
  opacity: ${({isDisabled}) => (isDisabled ? 0.3 : 1)};
  pointer-events: ${({isDisabled}) => (isDisabled ? 'none' : 'auto')};
`;

export default ActionContainer;
