import {Component} from 'react';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// MedallionInfo
import routeMedallionInfo from '../MedallionInfoPage/route';

class MedallionRedirectPage extends Component {
  static propTypes = {
    medallion: PropTypes.object,
    history: PropTypes.object,
  };

  componentDidMount() {
    const {
      history,
      medallion: {id},
    } = this.props;
    history.replace(routeMedallionInfo(id));
  }

  render() {
    return null;
  }
}

export default withRouter(
  connect((state) => ({medallion: state.medallion.medallion}))(
    MedallionRedirectPage
  )
);
