// Error
import negativeAmount from '../errors/negativeAmount.error.payment';

export default ({amount = 0} = {}) => {
  if (amount <= 0)
    throw negativeAmount({
      message: 'Amount can not be 0',
      fields: ['amount'],
    });
};
