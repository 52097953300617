import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Api
import updateApi from '../../api/update.api.vehicle';

// Actions
import {set as setAct} from '../../redux/actions';
import {set as setInfoAct} from './redux/actions';
import {show as showRealeaseFromHoldAct} from '../../../vehicleHold/containers/ReleaseVehicleFromHoldContainer/redux/actions';

// Attributes
import * as vehicleAttributes from '../../attributes';
import objectToAttributes from '../../../attribute/objectToAttributes';

// Components
import {Row, Column} from '@matthahn/sally-ui';
import SubPage from '../../../layout/components/SubPage/SubPage';
import VehicleArchivedInfo from '../../components/VehicleArchivedInfo/VehicleArchivedInfo';
import VehicleBasicInfoCard from '../../components/VehicleBasicInfoCard/VehicleBasicInfoCard';
import VehicleDecommissionedInfo from '../../components/VehicleDecommissionedInfo/VehicleDecommissionedInfo';
import VehicleInsuranceCard from '../../components/VehicleInsuranceCard/VehicleInsuranceCard';
import VehicleOnHoldInfo from '../../components/VehicleOnHoldInfo/VehicleOnHoldInfo';
import VehiclePurchaseCard from '../../components/VehiclePurchaseCard/VehiclePurchaseCard';

// Containers
import VehicleLicensesContainer from '../VehicleLicensesContainer/VehicleLicensesContainer';
import VehicleTollPassContainer from '../VehicleTollPassContainer/VehicleTollPassContainer';
import VehicleMedallionContainer from '../VehicleMedallionContainer/VehicleMedallionContainer';
import VehicleMileageContainer from '../VehicleMileageContainer/VehicleMileageContainer';

// Error
import parseError from '../../../error/parseError';

// Libs
import {lib} from '@matthahn/sally-ui';
import {
  setUnsavedChanges,
  clearUnsavedChanges,
} from '../../../layout/lib/unsavedChanges.lib.layout';
import isOnHold from '../../../vehicleHold/lib/isOnHold.lib.vehicleHold';

// Prep
import updatePrep from '../../preparation/update.preparation.vehicle';

// Permissions
import updatePermission from '../../permissions/update.permission.vehicle';

// States
import archived from '../../state/archived.state.vehicle';
import decommissioned from '../../state/decommissioned.state.vehicle';

// vehicleAlert containers
import VehicleAlertsContainer from '../../../vehicleAlert/containers/VehicleAlertsContainer/VehicleAlertsContainer';

// Alerts
const {alert} = lib;

class VehicleInfoContainer extends Component {
  static propTypes = {
    mileage: PropTypes.object,
    vehicle: PropTypes.object,
    hold: PropTypes.object,
    save: PropTypes.number,
  };

  static attributes = [
    'state',
    'name',
    'vehicle_make',

    'vin',
    'vehicle_model',
    'vehicle_year',
    'vehicle_type',
    'topper_type',
    'dov',
    'hackup_date',
    'dashcam',

    'purchase_date',
    'purchase_mileage',
    'purchase_price',

    'plate',
    'fhv_license_number',
    'fhv_license_expiry',
    'registration_expiry',

    'insurance_liability_number',
    'ezpass_tag_number',
  ];

  static vehicleAttributes = {
    ...vehicleAttributes,
  };

  state = {
    loading: false,
    vehicle: null,
    errors: [],
  };

  componentDidMount() {
    this.mounted = true;
    this.setState({
      vehicle: objectToAttributes({
        object: this.props.vehicle,
        attributes: {
          ...this.constructor.vehicleAttributes,
        },
        convert: this.constructor.attributes,
      }),
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.save !== this.props.save) this.save();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  change = setUnsavedChanges(
    (value, key, errors) => {
      const {vehicle} = this.state;
      this.setState({vehicle: {...vehicle, [key]: value}, errors});
    },
    () => this.state.loading
  );

  save = async () => {
    const {dispatch, vehicle: currentVehicle} = this.props;
    const {
      loading,
      vehicle: {...attributes},
    } = this.state;

    if (loading) return;

    if (!updatePermission())
      return alert.warning('You do not have permission to update vehicle info');

    this.setState({loading: true});
    dispatch(setInfoAct({saving: true}));

    try {
      const vehicle = await updatePrep(attributes);
      if (!vehicle.plate) vehicle.plate = null;
      const {medallion, ...savedVehicle} = await updateApi(
        currentVehicle.id,
        vehicle
      );
      if (this.props.vehicle.id !== currentVehicle.id) return;
      clearUnsavedChanges();
      dispatch(setInfoAct({saving: false}));
      if (!this.mounted) return;
      dispatch(setAct({vehicle: {...currentVehicle, ...savedVehicle}}));
      alert.success('Vehicle info saved');
      if (!this.mounted) return;
      this.setState({
        loading: false,
      });
    } catch (error) {
      const {message, fields} = parseError(error);
      alert.error(message);
      dispatch(setInfoAct({saving: false}));
      if (!this.mounted) return;
      this.setState({loading: false, errors: fields});
    }
  };

  refreshAttributes = (attributes) => {
    const {vehicle} = this.state;
    const newVehicleAttributes = objectToAttributes({
      object: this.props.vehicle,
      attributes: {
        ...this.constructor.vehicleAttributes,
      },
      convert: attributes,
    });

    this.setState({vehicle: {...vehicle, ...newVehicleAttributes}});
  };

  resolveHold = () => {
    const {vehicle, hold, dispatch} = this.props;
    dispatch(showRealeaseFromHoldAct({vehicle, hold}));
  };

  render() {
    const {vehicle: rawVehicle, hold, mileage} = this.props;
    const {loading, vehicle, errors} = this.state;
    return !vehicle ? null : (
      <SubPage>
        {rawVehicle.state === decommissioned.key && (
          <Row margin>
            <Column>
              <VehicleDecommissionedInfo vehicle={rawVehicle} />
            </Column>
          </Row>
        )}
        {rawVehicle.state === archived.key && (
          <Row margin>
            <Column>
              <VehicleArchivedInfo vehicle={rawVehicle} />
            </Column>
          </Row>
        )}
        {isOnHold(hold) && (
          <Row margin>
            <Column>
              <VehicleOnHoldInfo hold={hold} onResolve={this.resolveHold} />
            </Column>
          </Row>
        )}
        <VehicleAlertsContainer headless>
          {({dom}) =>
            !!dom ? (
              <Row margin>
                <Column>{dom}</Column>
              </Row>
            ) : null
          }
        </VehicleAlertsContainer>
        <Row margin>
          <Column size={1 / 3} m={1 / 2} margin>
            <Row margin>
              <Column>
                <VehicleBasicInfoCard
                  loading={loading}
                  errors={errors}
                  onChange={this.change}
                  onSave={this.save}
                  {...vehicle}
                />
              </Column>
            </Row>
          </Column>
          <Column size={1 / 3} m={1 / 2} margin>
            <Row margin>
              <Column>
                <VehicleMedallionContainer vehicle={rawVehicle} />
              </Column>
            </Row>
            <Row margin>
              <Column>
                <VehicleLicensesContainer
                  loading={loading}
                  errors={errors}
                  vehicle={rawVehicle}
                  onChange={this.change}
                  onSave={this.save}
                  onAttributeRefresh={this.refreshAttributes}
                  {...vehicle}
                />
              </Column>
            </Row>
            <Row>
              <Column>
                <VehicleTollPassContainer
                  loading={loading}
                  errors={errors}
                  vehicle={rawVehicle}
                  onChange={this.change}
                  onSave={this.save}
                  onAttributeRefresh={this.refreshAttributes}
                  {...vehicle}
                />
              </Column>
            </Row>
          </Column>
          <Column size={1 / 3} m={1 / 2} margin>
            <Row margin>
              <Column>
                <VehicleInsuranceCard vehicle={rawVehicle} />
              </Column>
            </Row>
            <Row margin>
              <Column>
                <VehiclePurchaseCard
                  loading={loading}
                  errors={errors}
                  onChange={this.change}
                  onSave={this.save}
                  {...vehicle}
                />
              </Column>
            </Row>
            <Row>
              <Column>
                <VehicleMileageContainer
                  vehicle={rawVehicle}
                  mileage={mileage}
                />
              </Column>
            </Row>
          </Column>
        </Row>
      </SubPage>
    );
  }
}

export default connect((state) => ({
  vehicle: state.vehicle.vehicle,
  mileage: state.vehicle.mileage,
  hold: state.vehicle.hold,
  save: state.vehicleInfo.save,
}))(VehicleInfoContainer);
