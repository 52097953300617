import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

// Events
import event from '../events/listed.event.document';

export default async (query = {}) => {
  const response = await f3tch('/documents/')
    .profile(profile())
    .query(query)
    .get();
  event.pub(...response.results);
  return response;
};
