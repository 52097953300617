import React, {Component} from 'react';
import PropTypes from 'prop-types';

// lib
import isRentable from '../../../dispatch/lib/isRentable.lib.dispatch';
import isVehicleRentable from '../../libs/isRentable.lib.vehicle';
import isMedallionRentable from '../../../medallion/lib/isRentable.lib.medallion';
import isOnHold from '../../../vehicleHold/lib/isOnHold.lib.vehicleHold';
import isHoldAfterETC from '../../../vehicleHold/lib/isAfterETC.lib.vehicleHold';

// local components
import Container from './components/Container';
import Content from './components/Content';
import Date from './components/Date';
import Label from './components/Label';

// redux
import {connect} from 'react-redux';

// vehicleHold actions
import {show as showRemoveVehicleFromHoldAct} from '../../../vehicleHold/containers/ReleaseVehicleFromHoldContainer/redux/actions';

// types
import dateTimeType from '@matthahn/sally-fw/lib/type/types/dateTime.type';

class VehicleRentableDescription extends Component {
  static propTypes = {
    children: PropTypes.func,
    dispatch: PropTypes.func,
    vehicle: PropTypes.object,
  };

  static defaultProps = {
    children: null,
  };

  showVehicleHold = () => {
    const {dispatch, vehicle} = this.props;
    dispatch(
      showRemoveVehicleFromHoldAct({vehicle, hold: vehicle.vehicle_hold})
    );
  };

  reason = () => {
    const {vehicle} = this.props;
    if (isRentable(vehicle)) return '';
    if (isOnHold(vehicle.vehicle_hold)) {
      const isAfterEtc = isHoldAfterETC({etc: vehicle.vehicle_hold.date_etc});
      return (
        <Container onClick={this.showVehicleHold}>
          <Date>
            <Label light={isAfterEtc}>ETC</Label>
            {!!vehicle?.vehicle_hold?.date_etc
              ? dateTimeType(vehicle?.vehicle_hold?.date_etc).format()
              : '-'}
          </Date>
          <Content>
            <Label light={isAfterEtc}>Hold</Label>
            {vehicle.vehicle_hold.hold_type}
            {' - '}
            {vehicle.vehicle_hold.reason || '-'}
          </Content>
        </Container>
      );
    }
    if (!isVehicleRentable(vehicle)) return 'Vehicle is not active';
    if (!vehicle.medallion || !isMedallionRentable(vehicle.medallion))
      return 'Medallion is not active';
    return '';
  };

  render() {
    const {children} = this.props;
    return !!children ? children(this.reason()) : this.reason();
  }
}

export default connect()(VehicleRentableDescription);
