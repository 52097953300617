import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {show as showExternalDevicesAct} from '../../../externalDevice/redux/actions';

// Attributes
import amountOneAttr from '../../attributes/amount_one.attribute.bankAccount';
import amountTwoAttr from '../../attributes/amount_two.attribute.bankAccount';

// Api
import api from '../../../api/lib/getEverythingFromApi.lib.api';
import deleteApi from '../../api/delete.api.bankAccount';
import updateApi from '../../api/update.api.bankAccount';
import getAuthTokenApi from '../../api/getAuthToken.api.bankAccount';
import listApi from '../../api/list.api.bankAccount';
import verifyDepositsApi from '../../api/verifyDeposits.api.bankAccount';

// Components
import BankAccounts from '../../components/BankAccounts/BankAccounts';
import BankAccountVerificationModal from '../../components/BankAccountVerificationModal/BankAccountVerificationModal';

// Lib
import {lib} from '@matthahn/sally-ui';
import addBankAccountUrl from '../../lib/addBankAccountUrl.lib.bankAccount';
import parseError from '../../../error/parseError';
import fkOrId from '../../../lib/fkOrId';

// Preps
import verifyPrep from '../../preparation/verify.preparation.bankAccount';

// Sockets
import addedSocket from '../../socket/added.socket.bankAccount';
import deletedSocket from '../../socket/deleted.socket.bankAccount';
import verifiedSocket from '../../socket/verified.socket.bankAccount';

// Alerts
const {alert, notify} = lib;

class BankAccountsContainer extends Component {
  static propTypes = {
    driver: PropTypes.object,
    dispatch: PropTypes.func,
  };

  state = {
    loading: true,
    token: '',
    accounts: [],
    removing: [],
    updating: [],
    verifying: false,
    verifyModalVisible: false,
    verifyAccount: null,
    amount_one: amountOneAttr(''),
    amount_two: amountTwoAttr(''),
  };

  componentDidMount() {
    this.mounted = true;
    this.init();
    this.subscribe = [
      addedSocket.subscribe(this.accountAdded),
      deletedSocket.subscribe(this.accountRemoved),
      verifiedSocket.subscribe(this.accountVerified),
    ];
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  accountAdded = (account) => {
    if (this.props.driver.id !== fkOrId(account.driver)) return;
    this.setState({accounts: [...this.state.accounts, account]});
  };

  accountVerified = (account) => {
    const {driver} = this.props;
    const {verifying, verifyAccount} = this.state;
    if (verifying || !verifyAccount || driver.id !== fkOrId(account.driver))
      return;
    this.setState({
      accounts: [...this.state.accounts].map((a) =>
        a.id === account ? account : a
      ),
    });
  };

  accountRemoved = (account) => {
    const {driver} = this.props;
    const {removing} = this.state;
    if (removing.includes(account.id) || driver.id !== fkOrId(account.driver))
      return;
    this.setState({
      accounts: [...this.state.accounts].filter((a) => a.id !== account.id),
    });
  };

  init = async () => {
    const {driver} = this.props;
    try {
      const [{token}, {data: accounts}] = await Promise.all([
        getAuthTokenApi(driver.id),
        api(listApi, {driver: driver.id, removed: false}),
      ]);
      if (!this.mounted) return;
      this.setState({loading: false, token, accounts});
    } catch (error) {
      this.setState({loading: false});
    }
  };

  isLoading = () => this.state.loading || this.state.verifying;

  onChange = (val, key) => {
    if (this.isLoading()) return;
    this.setState({[key]: val});
  };

  startAccountVerification = (account) => () => {
    if (this.isLoading()) return;
    this.setState({verifyModalVisible: true, verifyAccount: account});
  };

  hideAccountVerification = () => {
    if (this.isLoading()) return;
    this.setState({verifyModalVisible: false});
  };

  openBankAccountApp = () => {
    const {token} = this.state;
    if (!token) return;
    window.open(addBankAccountUrl(token), '_blank');
  };

  verify = async () => {
    const {amount_one, amount_two, verifyAccount} = this.state;
    if (this.isLoading()) return;

    this.setState({verifying: true});

    try {
      const data = await verifyPrep({amount_one, amount_two});
      await verifyDepositsApi(verifyAccount.id, data);
      const accounts = [...this.state.accounts].map((account) =>
        account.id === verifyAccount.id ? {...account, verified: true} : account
      );
      this.setState({verifying: false, accounts, verifyModalVisible: false});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({verifying: false});
    }
  };

  onDelete = (account) => () => this.delete({account});

  delete = async ({account, prompt = true} = {}) => {
    const {removing} = this.state;

    // if (!deletePermission())
    //   return alert.warning('You are not authorized to do this');
    if (removing.includes(account.id)) return;

    if (prompt)
      return notify({
        id: 'deleteBankAccount',
        title: 'Bank Account',
        icon: undefined,
        content: 'Are you sure you want to remove this account?',
        primary: {
          label: 'No',
          onClick: () => {},
        },
        secondary: {
          label: 'Yes',
          onClick: () => this.delete({account, prompt: false}),
        },
        closable: false,
        closeOnOutsideClick: true,
      });

    this.setState({removing: [...this.state.removing, account.id]});
    const doneRemoving = () =>
      [...this.state.removing].filter((r) => r !== account.id);

    try {
      await deleteApi(account.id);
      // const accounts = [...this.state.accounts].filter(
      //   ({id}) => id !== account.id
      // );
      this.setState({removing: doneRemoving(), loading: true}, this.init);
    } catch (error) {
      if (!this.mounted) return;
      const {message} = parseError(error);
      alert.error(message);
      this.setState({removing: doneRemoving()});
    }
  };

  onSetToDefault = (account) => () => this.setToDefault({account});

  setToDefault = async ({account, prompt = true}) => {
    const {updating} = this.state;

    if (updating.includes(account.id) || account.default) return;

    if (prompt)
      return notify({
        id: 'setBankAccountToDefault',
        title: 'Bank Account',
        icon: undefined,
        content: 'Are you sure you want to set this bank account to default?',
        primary: {
          label: 'No',
          onClick: () => {},
        },
        secondary: {
          label: 'Yes',
          onClick: () => this.setToDefault({account, prompt: false}),
        },
        closable: false,
        closeOnOutsideClick: true,
      });

    this.setState({
      updating: [...this.state.updating, account.id],
    });
    const doneUpdating = () =>
      [...this.state.updating].filter((r) => r !== account.id);

    try {
      await updateApi(account.id, {default: true});
      // const accounts = [...this.state.accounts].map((accountToUpdate) => ({
      //   ...accountToUpdate,
      //   default: accountToUpdate.id === account.id,
      // }));
      this.setState({updating: doneUpdating(), loading: true}, this.init);
    } catch (error) {
      if (!this.mounted) return;
      const {message} = parseError(error);
      alert.error(message);
      this.setState({updating: doneUpdating()});
    }
  };

  openOnDevice = () => {
    const {dispatch} = this.props;
    const {token} = this.state;
    if (!token) return;
    dispatch(
      showExternalDevicesAct({
        extension: 'bank-account',
        handler: this.onAccountAdded,
        data: {url: addBankAccountUrl(token)},
        timeout: 0,
      })
    );
  };

  onAccountAdded = () => {};

  render() {
    const {
      loading,
      accounts,
      removing,
      updating,
      verifying,
      verifyModalVisible,
      verifyAccount,
      amount_one,
      amount_two,
    } = this.state;
    return (
      <Fragment>
        <BankAccounts
          loading={loading}
          canRemove
          accounts={accounts}
          removing={removing}
          updating={updating}
          onNew={this.openBankAccountApp}
          onVerify={this.startAccountVerification}
          onDelete={this.onDelete}
          onSetToDefault={this.onSetToDefault}
          onOpenOnDevice={this.openOnDevice}
        />
        <BankAccountVerificationModal
          visible={verifyModalVisible}
          loading={verifying}
          account={verifyAccount}
          amount_one={amount_one}
          amount_two={amount_two}
          onChange={this.onChange}
          onClose={this.hideAccountVerification}
          onSave={this.verify}
        />
      </Fragment>
    );
  }
}

export default connect()(BankAccountsContainer);
