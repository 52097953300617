import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {apiComputedDmvLicense, computedDmvLicense} from '../../types';

export default attribute({
  type: TEXT,
  attribute: 'dmv_license_number',
  label: {
    default: 'DMV License Number',
    short: 'DMV License Number',
  },
  display: computedDmvLicense,
  input: computedDmvLicense,
  api: apiComputedDmvLicense,
});
