import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

// Api
import listRentalsApi from '../../api/rentals.api.vehicle';

// Components
import VehicleRentalsLayout from '../../components/VehicleRentalsLayout/VehicleRentalsLayout';

// VehicleRentalsDashboard
import VehicleRentalsDashboardPage from '../../pages/VehicleRentalsDashboardPage/VehicleRentalsDashboardPage';
import routeVehicleRentalsDashboard from '../../pages/VehicleRentalsDashboardPage/route';

// VehicleRental
import VehicleRentalPage from '../../pages/VehicleRentalPage/VehicleRentalPage';
import routeVehicleRental from '../../pages/VehicleRentalPage/route';

// Lib
import fkOrId from '../../../lib/fkOrId';

// Sockets
import createdRentalSocket from '../../../rental/sockets/created.socket.rental';
import updatedRentalSocket from '../../../rental/sockets/updated.socket.rental';

// State
import activeState from '../../../rental/state/active.state.rental';

// Types
import {dateTime} from '../../../types';

class VehicleRentalsLayoutContainer extends Component {
  static propTypes = {
    vehicle: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
  };

  static PER_PAGE = 25;

  state = {
    loading: false,
    rentals: [],
    more: false,
    page: 1,
    search: '',
  };

  componentDidMount() {
    this.mounted = true;
    this.getRentals();
    this.subscribe();
  }

  componentWillUnmount() {
    this.mounted = false;
    this.unsubscribe();
  }

  subscribe = () => {
    this.subscriptions = [
      createdRentalSocket.subscribe(this.rentalCreated),
      updatedRentalSocket.subscribe(this.rentalUpdated),
    ];
  };

  unsubscribe = () => {
    this.subscriptions.forEach((unsubscribe) => unsubscribe());
    this.subscriptions = [];
  };

  rentalCreated = (rental) => {
    const {vehicle} = this.props;
    const {rentals} = this.state;
    if (!vehicle || fkOrId(rental.vehicle) !== vehicle.id) return;
    this.setState({rentals: [...rentals, rental]});
  };

  rentalUpdated = (rental) => {
    const {vehicle} = this.props;
    const {rentals} = this.state;
    if (!vehicle || fkOrId(rental.vehicle) !== vehicle.id) return;
    this.setState({
      rentals: [...rentals].map((sft) => (sft.id === rental.id ? rental : sft)),
    });
  };

  getRentals = async ({
    page = this.state.page,
    search = this.state.search,
  } = {}) => {
    const {vehicle} = this.props;
    const {PER_PAGE} = this.constructor;
    this.setState({loading: true, page, search});

    try {
      const query = {
        offset: (page - 1) * PER_PAGE,
        limit: PER_PAGE,
        ordering: '-created_at',
      };
      if (!!search.trim().length) query.search = search;
      const {results: rentals, next} = await listRentalsApi(vehicle.id, query);
      if (!this.mounted) return;
      this.setState({loading: false, rentals, more: !!next});
    } catch (error) {
      if (!this.mounted) return;
      this.setState({loading: false});
    }
  };

  onRental = (id) => () =>
    this.props.history.push(routeVehicleRental(this.props.vehicle.id, id));

  rentals = () =>
    [...this.state.rentals].map(
      ({
        id,
        state,
        medallion,
        driver,
        vehicle_pickup_datetime,
        hotswap_id,
      }) => ({
        key: id,
        avatar: medallion.medallion_number,
        label: `${driver.first_name} ${driver.last_name}`,
        subtext: `${driver.fhv_license_number} / ${dateTime(
          vehicle_pickup_datetime
        ).format()}`,
        theme: activeState.key === state ? 'green' : 'grey',
        searchable: [
          medallion.medallion_number,
          `${driver.first_name} ${driver.last_name}`,
          driver.fhv_license_number,
        ],
        selected:
          this.props.location.pathname ===
          routeVehicleRental(this.props.vehicle.id, id),
        line: !!hotswap_id ? 'orange' : null,
        onClick: this.onRental(id),
      })
    );

  onMore = () => {
    const {more, page} = this.state;
    return more ? () => this.getRentals({page: page + 1}) : null;
  };

  render() {
    const {loading} = this.state;
    return (
      <VehicleRentalsLayout
        loading={loading}
        rentals={this.rentals()}
        onAdd={this.onAdd}
        onMore={this.onMore()}
      >
        <Switch>
          <Route
            exact
            path={routeVehicleRentalsDashboard()}
            component={VehicleRentalsDashboardPage}
          />
          <Route
            exact
            path={routeVehicleRental()}
            component={VehicleRentalPage}
          />
        </Switch>
      </VehicleRentalsLayout>
    );
  }
}

export default withRouter(
  connect((state) => ({vehicle: state.vehicle.vehicle}))(
    VehicleRentalsLayoutContainer
  )
);
