import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal, Form, Row, Column} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

// vehicle lib
import getVehicleMakes from '../../../vehicle/libs/getVehicleMakes.lib.vehicle';
import getVehicleModels from '../../../vehicle/libs/getVehicleModels.lib.vehicle';
import getVehicleTypes from '../../../vehicle/libs/getVehicleTypes.lib.vehicle';

const NewVehicleModal = ({
  visible,
  onClose,
  loading,
  onCreate,
  onChange,
  errors,
  required,

  dov,
  hackup_date,
  vin,
  purchase_date,
  purchase_mileage,
  purchase_price,
  vehicle_model,
  vehicle_year,
  vehicle_make,
  vehicle_type,
}) => (
  <Modal
    visible={visible}
    onClose={onClose}
    size="large"
    closable={!loading}
    title="New Vehicle"
    buttonsRight={[
      {
        loading,
        label: 'Create',
        theme: 'orange',
        onClick: onCreate,
      },
    ]}
  >
    {(Content) => (
      <Form onSubmit={onCreate} enter>
        <Content>
          <Row margin>
            <Column>
              <AttributeInput
                required={required}
                errors={errors}
                value={vin}
                onChange={onChange}
                disabled={loading}
              >
                {vin.label.short}
              </AttributeInput>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 2}>
              <AttributeInput
                required={required}
                errors={errors}
                value={vehicle_type}
                onChange={onChange}
                disabled={loading}
                options={getVehicleTypes()}
              >
                {vehicle_type.label.short}
              </AttributeInput>
            </Column>
            <Column size={1 / 2}>
              <AttributeInput
                required={required}
                errors={errors}
                value={vehicle_year}
                onChange={onChange}
                disabled={loading}
              >
                {vehicle_year.label.short}
              </AttributeInput>
            </Column>
          </Row>
          <Row>
            <Column size={1 / 2}>
              <AttributeInput
                required={required}
                errors={errors}
                value={vehicle_make}
                onChange={onChange}
                disabled={loading}
                options={getVehicleMakes()}
              >
                {vehicle_make.label.short}
              </AttributeInput>
            </Column>
            <Column size={1 / 2}>
              <AttributeInput
                required={required}
                errors={errors}
                value={vehicle_model}
                onChange={onChange}
                disabled={loading}
                options={getVehicleModels({make: vehicle_make.api.format()})}
              >
                {vehicle_model.label.short}
              </AttributeInput>
            </Column>
          </Row>
        </Content>

        <Content>
          <Row>
            <Column>
              <AttributeInput
                required={required}
                errors={errors}
                value={hackup_date}
                onChange={onChange}
                disabled={loading}
              >
                {hackup_date.label.short}
              </AttributeInput>
            </Column>
          </Row>
        </Content>

        <Content>
          <Row margin>
            <Column>
              <AttributeInput
                required={required}
                errors={errors}
                value={purchase_date}
                onChange={onChange}
                disabled={loading}
              >
                {purchase_date.label.short}
              </AttributeInput>
            </Column>
          </Row>
          <Row>
            <Column size={2 / 5}>
              <AttributeInput
                required={required}
                errors={errors}
                value={purchase_price}
                onChange={onChange}
                disabled={loading}
                preValue="$"
              >
                {purchase_price.label.short}
              </AttributeInput>
            </Column>
            <Column size={2 / 5}>
              <AttributeInput
                required={required}
                errors={errors}
                value={purchase_mileage}
                onChange={onChange}
                disabled={loading}
                postValue="mi."
              >
                {purchase_mileage.label.short}
              </AttributeInput>
            </Column>
            <Column size={1 / 5}>
              <AttributeInput
                required={required}
                errors={errors}
                value={dov}
                onChange={onChange}
                disabled={loading}
                green
              >
                {dov.label.default}
              </AttributeInput>
            </Column>
          </Row>
        </Content>
      </Form>
    )}
  </Modal>
);

NewVehicleModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  loading: PropTypes.bool,
  onCreate: PropTypes.func,
  onChange: PropTypes.func,
  errors: PropTypes.array,
  required: PropTypes.array,

  name: PropTypes.object,
  hackup_date: PropTypes.object,
  dov: PropTypes.object,
  vin: PropTypes.object,
  purchase_date: PropTypes.object,
  purchase_mileage: PropTypes.object,
  purchase_price: PropTypes.object,
  vehicle_model: PropTypes.object,
  vehicle_year: PropTypes.object,
  vehicle_make: PropTypes.object,
  vehicle_type: PropTypes.object,
};

export default NewVehicleModal;
