import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

// Events
import event from '../events/updated.event.vehicleAlert';

export default async (vehicleId, vehicleAlertId, vehicleAlert, jwt = null) => {
  const api = f3tch(`/vehicles/${vehicleId}/alerts/${vehicleAlertId}/`).profile(
    profile()
  );

  if (!!jwt) api.headers({Authorization: `JWT ${jwt}`});

  const response = await api.body(vehicleAlert).patch();
  event.publish(response);
  return response;
};
