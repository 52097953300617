import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Api
import updateDriverApi from '../../api/update.api.driver';

// Attributes
import ssnAttr from '../../attributes/ssn.attribute.driver';
import ssnConfirmAttr from '../../attributes/ssnConfirm.attribute.driver';

// Components
import DispatchMissingDriverInfoStep from '../../components/DispatchMissingDriverInfoStep/DispatchMissingDriverInfoStep';

// Error
import parseError from '../../../error/parseError';

// Lib
import {lib} from '@matthahn/sally-ui';

// Prep
import updateSSNPrep from '../../preparation/updateSSN.preparation.driver';

// Alert
const {alert} = lib;

class DispatchMissingDriverInfoStepContainer extends Component {
  static propTypes = {
    Container: PropTypes.func,
    visible: PropTypes.bool,
    driver: PropTypes.object,
    onBack: PropTypes.func,
    onNext: PropTypes.func,
    onDisabled: PropTypes.func,
    goingBack: PropTypes.bool,
  };

  state = {
    loading: false,
    ssn: ssnAttr(''),
    ssnConfirm: ssnConfirmAttr(''),
  };

  componentDidMount() {
    if (this.props.visible) this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.visible && this.props.visible) this.init();
  }

  init = () => {
    const {driver, onNext, onBack, goingBack} = this.props;
    const fn = goingBack ? onBack : onNext;
    if (!!driver.ssn) return fn({driver});
    this.setState({
      loading: false,
      ssn: ssnAttr(''),
      ssnConfirm: ssnConfirmAttr(''),
    });
  };

  onChange = (val, key) => {
    if (this.state.loading) return;
    this.setState({[key]: val});
  };

  save = async () => {
    const {
      driver: {id},
      onNext,
    } = this.props;
    const {loading, ssn, ssnConfirm} = this.state;

    if (loading) return;

    this.setState({loading: true});

    try {
      const body = await updateSSNPrep({ssn, ssnConfirm});
      const driver = await updateDriverApi(id, body);
      onNext({driver});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({loading: false});
    }
  };

  render() {
    const {Container, onBack} = this.props;
    const {loading, ssn, ssnConfirm} = this.state;
    return (
      <DispatchMissingDriverInfoStep
        Container={Container}
        loading={loading}
        ssn={ssn}
        ssnConfirm={ssnConfirm}
        onChange={this.onChange}
        onBack={onBack}
        onNext={this.save}
      />
    );
  }
}

export default DispatchMissingDriverInfoStepContainer;
