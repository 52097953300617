import {v4} from 'uuid';

class Event {
  constructor() {
    this.subscribers = [];
  }

  sub(event, fn) {
    const id = v4();
    this.subscribers = [...this.subscribers, {id, event, fn}];
    return this.unsub.bind(this, id);
  }

  unsub(subscriberID) {
    this.subscribers = [...this.subscribers].filter(
      ({id}) => id !== subscriberID
    );
  }

  pub(event, ...data) {
    this.subscribers.forEach((sub) => {
      if (sub.event !== event) return;
      sub.fn(...data);
    });
  }
}

const event = new Event();

export default (eventName) => ({
  sub: (fn) => event.sub(eventName, fn),
  pub: (...data) => event.pub(eventName, ...data),
});
