import styled from 'styled-components';

// colors
import {grey} from '@matthahn/sally-ui/lib/libs/colors';

const Container = styled.div`
  display: inline-block;
  margin-left: 5px;
  cursor: pointer;
  background: ${grey};
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 0.9em;

  & span {
    font-size: 0.8em;
  }
`;

export default Container;
