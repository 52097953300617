import styled from 'styled-components';

// colors
import {grey} from '@matthahn/sally-ui/lib/libs/colors';

const Container = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  padding: 4px;
  display: flex;
  gap: 10px;
  background: ${grey};
`;

export default Container;
