import authToken from '../../auth/lib/token.lib.auth';

export default ({authorize = true} = {}) => {
  const token = authToken.get();
  const h = {
    'Content-Type': 'text/html',
  };
  if (!!token && authorize) h.Authorization = `JWT ${token}`;
  return h;
};
