import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal, Row, Column, Form} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

const DriverSSNModal = ({
  visible,
  loading,
  ssn,
  ssnConfirm,
  onChange,
  onSave,
  onClose,
}) => (
  <Modal
    title="Update SSN"
    visible={visible}
    onClose={onClose}
    buttonsRight={[{label: 'Save', loading, onClick: onSave}]}
  >
    {(Content) => (
      <Content>
        <Form onSubmit={onSave} enter>
          <Row margin>
            <Column>
              <AttributeInput
                value={ssn}
                onChange={onChange}
                disabled={loading}
              >
                {ssn.label.default}
              </AttributeInput>
            </Column>
          </Row>
          <Row>
            <Column>
              <AttributeInput
                value={ssnConfirm}
                onChange={onChange}
                disabled={loading}
              >
                {ssnConfirm.label.default}
              </AttributeInput>
            </Column>
          </Row>
        </Form>
      </Content>
    )}
  </Modal>
);

DriverSSNModal.propTypes = {
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  ssn: PropTypes.object,
  ssnConfirm: PropTypes.object,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

export default DriverSSNModal;
