import PropTypes from 'prop-types';

const PayoutDescription = ({message}) =>
  !message ? '' : `${message}`.replace('AUTOPAYOUT FAIL: ', '');

PayoutDescription.propTypes = {
  message: PropTypes.string,
};

export default PayoutDescription;
