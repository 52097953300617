import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

export default attribute({
  type: TEXT,
  attribute: 'lien_holder',
  label: {
    default: 'Lien Holder',
    short: 'Lien Holder',
  },
});
