import orderByDate from '../../lib/orderByDate';

export default (vehicles) =>
  [...vehicles].map((vehicle) => {
    const hasMultipleActiveRentals =
      !!vehicle.active_rentals && vehicle.active_rentals.length > 1;
    const rental = !!vehicle.active_rentals
      ? orderByDate(
          [...vehicle.active_rentals].map((activeRental) => ({
            ...activeRental,
            lastActivityRecord: !!activeRental.curb_rental
              ? activeRental.curb_rental.start_datetime ||
                activeRental.curb_rental.created_at
              : 0,
          })),
          'lastActivityRecord',
          'desc'
        )[0]
      : null;
    const lease_agreement = !!rental
      ? rental.lease_agreement
      : {contract_type: ''};
    const driver = !!rental
      ? {
          ...rental.driver,
          name: `${rental.driver.first_name} ${rental.driver.last_name}`,
          lease: lease_agreement,
        }
      : {
          id: null,
          name: '',
          balance: '',
          fhv_license_number: '',
          lease: lease_agreement,
        };
    return {
      ...vehicle,
      rental,
      lease_agreement,
      driver,
      hasMultipleActiveRentals,
    };
  });
