import {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

// Api
import createDriverApi from '../../api/create.api.driver';
import uploadDocumentApi from '../../../document/api/upload.api.document';

// Constants
import createAttributes from '../../constants/createAttributes.constants.driver';

// Document
import dmvDocument from '../../documents/folders/dmv.document.driver';
import fhvDocument from '../../documents/folders/fhv.document.driver';

// Libs
import {lib} from '@matthahn/sally-ui';
import parseError from '../../../error/parseError';
import {
  setUnsavedChanges,
  clearUnsavedChanges,
} from '../../../layout/lib/unsavedChanges.lib.layout';

// Preparation
import createPreparation from '../../preparation/create.preparation.driver';

// Routes
import driverRoute from '../../pages/DriverPage/route';

// Attributes
import address_city from '../../attributes/address_city.attribute.driver';
import address_state from '../../attributes/address_state.attribute.driver';
import address_street1 from '../../attributes/address_street1.attribute.driver';
import address_street2 from '../../attributes/address_street2.attribute.driver';
import address_zip from '../../attributes/address_zip.attribute.driver';
import date_of_birth from '../../attributes/date_of_birth.attribute.driver';
import dmv_license_expiry from '../../attributes/dmv_license_expiry.attribute.driver';
import dmv_license_issue_date from '../../attributes/dmv_license_issue_date.attribute.driver';
import dmv_license_number from '../../attributes/dmv_license_number.attribute.driver';
import dmv_license_state from '../../attributes/dmv_license_state.attribute.driver';
import email from '../../attributes/email.attribute.driver';
import fhv_license_expiry from '../../attributes/fhv_license_expiry.attribute.driver';
import fhv_license_number from '../../attributes/fhv_license_number.attribute.driver';
import first_name from '../../attributes/first_name.attribute.driver';
import last_name from '../../attributes/last_name.attribute.driver';
import phone_number from '../../attributes/phone_number.attribute.driver';
import ssn from '../../attributes/ssn.attribute.driver';
import ssnConfirm from '../../attributes/ssnConfirm.attribute.driver';

// Notifications
const {alert} = lib;

class NewDriverContainer extends Component {
  static propTypes = {
    lastUpdate: PropTypes.any,
    history: PropTypes.object,
    children: PropTypes.func.isRequired,
    onLoad: PropTypes.func,
  };

  static defaultProps = {
    onLoad: () => {},
  };

  static INITIAL_STATE = () => ({
    loading: false,
    errors: [],

    first_name: first_name(''),
    last_name: last_name(''),
    phone_number: phone_number(''),
    email: email(''),
    address_street1: address_street1(''),
    address_street2: address_street2(''),
    address_city: address_city(''),
    address_state: address_state(''),
    address_zip: address_zip(''),
    fhv_license_expiry: fhv_license_expiry(''),
    fhv_license_number: fhv_license_number(''),
    ssn: ssn(''),
    ssnConfirm: ssnConfirm(''),
    date_of_birth: date_of_birth(''),
    dmv_license_state: dmv_license_state(''),
    dmv_license_number: dmv_license_number(''),
    dmv_license_expiry: dmv_license_expiry(''),
    dmv_license_issue_date: dmv_license_issue_date(''),

    fhv: null,
    dmv: null,
  });

  state = {
    ...this.constructor.INITIAL_STATE(),
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.lastUpdate !== this.props.lastUpdate) this.init();
  }

  init = () => {
    this.setState({...this.constructor.INITIAL_STATE()});
  };

  onChange = setUnsavedChanges(
    (value, key, errors = this.state.errors) =>
      this.setState({[key]: value, errors}),
    () => this.state.loading
  );

  onCreate = async () => {
    const {history, onLoad} = this.props;
    const {loading, errors, fhv, dmv, ...attributes} = this.state;

    if (loading) return;

    if (!fhv) return alert.warning('Upload FHV image');
    if (!dmv) return alert.warning('Upload DMV');

    this.setState({loading: true});
    onLoad(true);

    try {
      const driver = await createPreparation(attributes);
      const response = await createDriverApi(driver);
      await Promise.all([
        uploadDocumentApi({
          doc: fhv,
          file: fhvDocument,
          driver: response,
          data: {
            fhv_license_expiry: attributes.fhv_license_expiry,
            fhv_license_number: attributes.fhv_license_number,
          },
          type: 'driver',
        }),
        uploadDocumentApi({
          doc: dmv,
          file: dmvDocument,
          driver: response,
          data: {
            date_of_birth: attributes.date_of_birth,
            dmv_license_state: attributes.dmv_license_state,
            dmv_license_number: attributes.dmv_license_number,
            dmv_license_expiry: attributes.dmv_license_expiry,
            dmv_license_issue_date: attributes.dmv_license_issue_date,
          },
          type: 'driver',
        }),
      ]);
      clearUnsavedChanges();
      history.push(driverRoute(response.id));
    } catch (error) {
      const {fields, message} = parseError(error);
      alert.error(message);
      this.setState({loading: false, errors: fields});
      onLoad(false);
    }
  };

  render() {
    const {children} = this.props;
    return children({
      ...this.state,
      required: createAttributes,
      onChange: this.onChange,
      onCreate: this.onCreate,
    });
  }
}

export default withRouter(NewDriverContainer);
