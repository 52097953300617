import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/json.profile.api';

// Events
import event from '../events/updated.event.insurance';

export default async (insuranceId, insurance) => {
  const response = await f3tch(`/vehicles/insurance/${insuranceId}/`)
    .profile(profile())
    .body(insurance)
    .patch();
  event.publish(response);
  return response;
};
