import {NUMBER} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {number, stringNumber} from '../../types';

export default attribute({
  type: NUMBER,
  attribute: 'fhv_years',
  display: stringNumber,
  input: stringNumber,
  api: number,
  label: {
    default: 'FHV Years',
    short: 'Years',
  },
});
