import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {alphanumeric} from '../../types';

export default attribute({
  type: TEXT,
  attribute: 'ezpass_tag_number',
  display: alphanumeric,
  input: alphanumeric,
  api: alphanumeric,
  label: {
    default: 'Toll Tag ID',
    short: 'Tag ID',
  },
});
