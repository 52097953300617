import isNumber from 'is-number';
import type from '../type';

const format = (value) => {
  if (!value) return '';
  const formatted = `${value}`.replace(/[^a-zA-Z0-9]/gi, '').toUpperCase();
  return [...formatted].reduce(
    (combined, char) => {
      if (isNumber(char)) combined.number = combined.number + 1;
      if (isNumber(char) && combined.number > 3) return combined;
      return {...combined, string: `${combined.string}${char}`};
    },
    {string: '', number: 0}
  ).string;
};

const validate = (value) => {
  if (!/^[A-Z0-9]+$/g.test(value)) return false;
  const numbers = `${value}`.replace(/[^0-9]/g, '');
  return numbers.length <= 3;
};

export default type({type: 'string', format, validate});
