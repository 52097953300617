import styled from 'styled-components';

// Colors
import * as colors from '@matthahn/sally-ui/lib/libs/colors';

const TableRow = styled.div`
  width: 100%;
  display: flex;
  font-weight: 400;
  font-size: 15px;
  background: ${({bg}) => (!!bg ? colors?.[bg] || bg : colors.transparent)};
  border-bottom: ${({bg}) =>
    `1px solid ${!!bg ? colors?.[bg] || bg : colors.grey}`};

  &:nth-child(odd) {
    background: ${({bg}) =>
      !!bg ? colors?.[bg] || bg : colors.superLightBlueGrey};
  }

  &:hover {
    background: ${({bg}) => (!!bg ? colors?.[bg] || bg : colors.grey)};
  }
`;

export default TableRow;
