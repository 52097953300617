import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Card, Row, Column} from '@matthahn/sally-ui';
import AttributeText from '../../../layout/components/AttributeText/AttributeText';

const DriverFhvCard = ({
  loading,
  onOpen,
  onShowUploader,
  file,

  fhv_license_number,
  fhv_license_expiry,
}) => (
  <Card
    title="FHV License"
    icon="scan1"
    headerActions={[
      {
        icon: 'addfile',
        tooltip: 'Upload FHV',
        onClick: onShowUploader,
        loading,
      },
      !!file && {
        icon: 'eyeo',
        onClick: onOpen,
        tooltip: 'Show file',
      },
    ].filter((act) => !!act)}
  >
    {(Content) => (
      <Content>
        <Row margin>
          <Column>
            <AttributeText attribute={fhv_license_number}>
              {fhv_license_number.label.default}
            </AttributeText>
          </Column>
        </Row>
        <Row>
          <Column>
            <AttributeText attribute={fhv_license_expiry}>
              {fhv_license_expiry.label.default}
            </AttributeText>
          </Column>
        </Row>
      </Content>
    )}
  </Card>
);

DriverFhvCard.propTypes = {
  loading: PropTypes.bool,
  onOpen: PropTypes.func,
  onShowUploader: PropTypes.func,
  file: PropTypes.object,
  fhv_license_number: PropTypes.object,
  fhv_license_expiry: PropTypes.object,
};

export default DriverFhvCard;
