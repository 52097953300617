import {TEXT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {ssn} from '../../types';

export default attribute({
  type: TEXT,
  attribute: 'ssnConfirm',
  input: ssn,
  api: ssn,
  label: {
    default: 'Confirm Social Security Number',
    short: 'Confirm SSN',
  },
});
