import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {updateDriver as updateDriverAct} from '../../redux/actions';

// Components
import DriverDocumentsLayout from '../../components/DriverDocumentsLayout/DriverDocumentsLayout';

// Containers
import DocumentsContainer from '../../../document/containers/DocumentsContainer/DocumentsContainer';

class DriverDocumentsContainer extends Component {
  onDone = ({object}) => {
    this.props.dispatch(updateDriverAct(object));
  };

  render() {
    const {driver} = this.props;
    return (
      <DriverDocumentsLayout>
        <DocumentsContainer
          driver={driver}
          type="driver"
          onDone={this.onDone}
        />
      </DriverDocumentsLayout>
    );
  }
}

DriverDocumentsContainer.propTypes = {
  driver: PropTypes.object,
};

export default connect((state) => ({
  driver: state.driver.driver,
}))(DriverDocumentsContainer);
