// Action constants
import {SET} from './constants';

const DEFAULT_STATE = {
  creatingAlert: false,
  updatingAlert: false,
  deletingAlert: false,

  loadingFailedPayoutAlerts: true,
  failedPayoutAlerts: [],
};

export default (state = DEFAULT_STATE, {type, data}) => {
  switch (type) {
    case SET: {
      return {
        ...state,
        ...data,
      };
    }

    default: {
      return state;
    }
  }
};
