import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {Button, Select} from '@matthahn/sally-ui';
import StepFooter from '../../../layout/components/StepFooter/StepFooter';
import SmallLoader from '../../../layout/components/SmallLoader/SmallLoader';
import Content from './components/Content';

const DispatchDriverStep = ({
  Container,
  searching,
  loading,
  drivers,
  selectedDriver,
  onChange,
  onNext,
}) =>
  loading ? (
    <Container>
      <SmallLoader />
    </Container>
  ) : (
    <Fragment>
      <Container>
        <Content>
          <Select
            value={selectedDriver}
            options={drivers}
            disabled={searching}
            onChange={onChange}
            limitOptions={10}
            size="large"
          >
            Select a Driver
          </Select>
        </Content>
      </Container>
      <Container>
        <StepFooter
          right={
            <Button
              size="small"
              theme="orange"
              disabled={searching}
              onClick={onNext}
            >
              Next
            </Button>
          }
        />
      </Container>
    </Fragment>
  );

DispatchDriverStep.propTypes = {
  Container: PropTypes.func,
  searching: PropTypes.bool,
  loading: PropTypes.bool,
  drivers: PropTypes.array,
  selectedDriver: PropTypes.string,
  onChange: PropTypes.func,
  onNext: PropTypes.func,
};

export default DispatchDriverStep;
