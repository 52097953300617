import f3tch from 'f3tch';

// Profile
import profile from '../../api/profiles/fileDownload.profile.api';

export default (driverId, year) =>
  f3tch(`/drivers/${driverId}/yearly_rent_statement/`)
    .profile(profile({contentType: 'application/json'}))
    .body(JSON.stringify({year}))
    .post();
