import active from './active.state.medallion';
import inactive from './inactive.state.medallion';
import stored from './stored.state.medallion';
import unmanaged from './unmanaged.state.medallion';
import unknown from './unknown.state.medallion';

const states = [active, inactive, stored, unmanaged].reduce(
  (combined, state) => ({...combined, [state.key]: state}),
  {}
);

export const switchableStates = [
  active,
  inactive,
  stored,
  unmanaged
].map(({key, label}) => ({value: key, label}));

export default (state) => states[state] || unknown;
