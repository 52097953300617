import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Card, Table, Button, Icon} from '@matthahn/sally-ui';

// Columns
import columns from './columns';

// types
import dateTimeType from '@matthahn/sally-fw/lib/type/types/dateTime.type';

const BankAccounts = ({
  loading,
  canRemove,
  accounts,
  removing,
  updating,
  onNew,
  onVerify,
  onDelete,
  onOpenOnDevice,
  onSetToDefault,
}) => (
  <Card
    icon="bank"
    title="Deposit Accounts"
    headerActions={[
      {
        icon: 'tablet1',
        tooltip: 'Open on iPad',
        onClick: onOpenOnDevice,
      },
      {
        icon: 'plus',
        tooltip: 'New Account',
        onClick: onNew,
      },
    ]}
  >
    {(Content) => (
      <Content padding="none">
        <Table
          columns={columns}
          loading={loading}
          theme="orange"
          infinite
          noBorder
          noRadius
          smallNoResultsLabel
        >
          {(TableRow) =>
            accounts.map((account) => (
              <TableRow
                key={account.id}
                loading={
                  removing.includes(account.id) || updating.includes(account.id)
                }
                actions={[
                  !account.default && {
                    icon: 'staro',
                    color: 'grey',
                    label: 'Mark as default',
                    onClick: onSetToDefault(account),
                  },
                  canRemove && {
                    icon: 'delete',
                    color: 'red',
                    label: 'Remove',
                    onClick: onDelete(account),
                  },
                ].filter((action) => !!action)}
              >
                {(TableColumn) => [
                  <TableColumn key="bankName">
                    {account.funding_name}
                    {account.default && (
                      <span>
                        {' '}
                        <Icon icon="staro" />
                      </span>
                    )}
                  </TableColumn>,
                  <TableColumn key="type">
                    {dateTimeType(account.created_at).format()}
                  </TableColumn>,
                  <TableColumn key="verify">
                    {account.verified ? (
                      'Verified'
                    ) : account.status === 'micro_failed' ? (
                      'FAILED MICRO DEPOSIT'
                    ) : (
                      <Button
                        theme="grey"
                        size="small"
                        onClick={onVerify(account)}
                      >
                        Verify
                      </Button>
                    )}
                  </TableColumn>,
                ]}
              </TableRow>
            ))
          }
        </Table>
      </Content>
    )}
  </Card>
);

BankAccounts.propTypes = {
  loading: PropTypes.bool,
  canRemove: PropTypes.bool,
  accounts: PropTypes.array,
  removing: PropTypes.array,
  updating: PropTypes.array,
  onNew: PropTypes.func,
  onVerify: PropTypes.func,
  onDelete: PropTypes.func,
  onOpenOnDevice: PropTypes.func,
  onSetToDefault: PropTypes.func,
};

export default BankAccounts;
