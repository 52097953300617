// Date
import {parseISO, isDate, formatISO} from 'date-fns';
import {formatToTimeZone, convertToTimeZone} from 'date-fns-timezone';

// Lib
import getTimeZoneFromBranch from './getTimeZoneFromBranch';

const displayTimeZoneSpecificDate = ({
  date,
  type = null,
  branch = null,
  local = false,
} = {}) => {
  if (!date) return '-';
  try {
    const timeZone = getTimeZoneFromBranch({branch});
    const parsedDate = isDate(date) ? date : parseISO(date);
    const localizedDate =
      local || !timeZone ? parsedDate : convertToTimeZone(date, {timeZone});
    const timeZoneAbbreviation = !!timeZone
      ? formatToTimeZone(localizedDate, 'z', {
          timeZone,
        })
      : null;
    const formattedDate = !!type
      ? type(localizedDate).format()
      : formatISO(localizedDate);
    return local || !timeZone
      ? formattedDate
      : `${formattedDate} ${timeZoneAbbreviation}`;
  } catch (error) {
    return '-';
  }
};

export default displayTimeZoneSpecificDate;
