import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Card, Row, Column, Form} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

const Poc1Edit = ({
  loading,
  errors,
  poc1,
  poc1_address_street1,
  poc1_address_street2,
  poc1_address_city,
  poc1_address_zip,
  poc1_address_state,
  poc1_email,
  poc1_phone_number,
  poc1_notes,
  onChange,
  onCancel,
  onSave,
}) => (
  <Card
    title="POC 1"
    icon="phone"
    headerActions={[
      {
        icon: 'close',
        onClick: onCancel,
        tooltip: 'Exit edit mode',
      },
    ]}
    buttonsRight={[{label: 'Save', loading, onClick: onSave, theme: 'orange'}]}
  >
    {(Content) => (
      <Content>
        <Form onSubmit={onSave} enter>
          <Row margin>
            <Column>
              <AttributeInput
                value={poc1}
                onChange={onChange}
                disabled={loading}
                errors={errors}
              >
                {poc1.label.default}
              </AttributeInput>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 2}>
              <AttributeInput
                value={poc1_email}
                onChange={onChange}
                disabled={loading}
                errors={errors}
              >
                {poc1_email.label.default}
              </AttributeInput>
            </Column>
            <Column size={1 / 2}>
              <AttributeInput
                value={poc1_phone_number}
                onChange={onChange}
                disabled={loading}
                errors={errors}
              >
                {poc1_phone_number.label.default}
              </AttributeInput>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 3}>
              <AttributeInput
                value={poc1_address_street1}
                onChange={onChange}
                disabled={loading}
                errors={errors}
              >
                {poc1_address_street1.label.default}
              </AttributeInput>
            </Column>
            <Column size={1 / 3}>
              <AttributeInput
                value={poc1_address_street2}
                onChange={onChange}
                disabled={loading}
                errors={errors}
              >
                {poc1_address_street2.label.default}
              </AttributeInput>
            </Column>
            <Column size={1 / 3}>
              <AttributeInput
                value={poc1_address_city}
                onChange={onChange}
                disabled={loading}
                errors={errors}
              >
                {poc1_address_city.label.default}
              </AttributeInput>
            </Column>
          </Row>
          <Row margin>
            <Column size={1 / 2}>
              <AttributeInput
                value={poc1_address_zip}
                onChange={onChange}
                disabled={loading}
                errors={errors}
              >
                {poc1_address_zip.label.default}
              </AttributeInput>
            </Column>
            <Column size={1 / 2}>
              <AttributeInput
                value={poc1_address_state}
                onChange={onChange}
                disabled={loading}
                errors={errors}
              >
                {poc1_address_state.label.default}
              </AttributeInput>
            </Column>
          </Row>
          <Row>
            <Column>
              <AttributeInput
                value={poc1_notes}
                onChange={onChange}
                disabled={loading}
                errors={errors}
              >
                {poc1_notes.label.default}
              </AttributeInput>
            </Column>
          </Row>
        </Form>
      </Content>
    )}
  </Card>
);

Poc1Edit.propTypes = {
  loading: PropTypes.bool,
  errors: PropTypes.array,
  poc1: PropTypes.object,
  poc1_address_street1: PropTypes.object,
  poc1_address_street2: PropTypes.object,
  poc1_address_city: PropTypes.object,
  poc1_address_zip: PropTypes.object,
  poc1_address_state: PropTypes.object,
  poc1_email: PropTypes.object,
  poc1_phone_number: PropTypes.object,
  poc1_notes: PropTypes.object,
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};

export default Poc1Edit;
