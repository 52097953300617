import styled from 'styled-components';

// Colors
import {lib} from '@matthahn/sally-ui';
const {
  colors: {grey},
} = lib;

const More = styled.div`
  width: 100%;
  text-align: center;
  padding: 8px 0px;
  border-style: solid none none none;
  border-width: 1px;
  border-color: ${grey};
`;

export default More;
