import React from 'react';
import PropTypes from 'prop-types';
import isObject from 'is-object';

// Components
import {TextColor} from '@matthahn/sally-ui';

const TripVoided = ({trip, children}) => (
  <TextColor
    theme={!!trip && isObject(trip) && trip.voided ? 'darkGrey' : 'black'}
  >
    {children}
  </TextColor>
);

TripVoided.propTypes = {
  trip: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  children: PropTypes.node,
};

export default TripVoided;
