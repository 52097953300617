import prepare from '../../attribute/prepare.attribute';
import combine from '../../attribute/combinePreparation.attribute';

// Preparations
import create from './create.preparation.vehicle';
import ready from './ready.preparation.vehicle';

export default async (attributes) => {
  const [formattedVehicle] = await combine(
    async () =>
      prepare(attributes, {
        field: 'api',
        required: ['state'],
      }),
    async () => await create(attributes),
    !!attributes.state &&
      ['ready', 'reserved', 'active', 'sitting'].includes(
        attributes.state.value()
      )
      ? async () => await ready(attributes)
      : async () => null
  );
  const {state, ...vehicle} = formattedVehicle;
  return vehicle;
};
