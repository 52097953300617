import styled, {css} from 'styled-components';

const Container = styled.div`
  ${({inline}) =>
    inline
      ? css`
          display: inline-block;
        `
      : css`
          width: 100%;
        `}
`;

export default Container;
