// Types
import {dateTime} from '../../types';

export default (transactions, balance = 0) =>
  [...transactions].reduce(
    (combined, transaction) => {
      const income = transaction.type !== 'charge';
      const balance = income
        ? combined.balance + transaction.amount
        : combined.balance - transaction.amount;
      return {
        transactions: [
          ...combined.transactions,
          {
            ...transaction,
            createdAt: dateTime(transaction.created_at).format(),
            postedAt: dateTime(transaction.posted_at).format(),
            computedType: `${transaction.type} - ${transaction.subtype}`,
            income,
            balanceFlow: combined.balance,
          },
        ],
        balance: balance,
      };
    },
    {transactions: [], balance}
  );
