import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal, P} from '@matthahn/sally-ui';

const RemoveDocumentModal = ({visible, loading, onRemove, onClose}) => (
  <Modal
    title="Document Removal Confirmation"
    visible={visible}
    size="small"
    onClose={onClose}
    buttonsRight={[{label: 'Remove', loading, onClick: onRemove}]}
  >
    {(Content) => (
      <Content>
        <P align="center" theme="darkGrey">
          Are you sure you want to delete this file?
        </P>
      </Content>
    )}
  </Modal>
);

RemoveDocumentModal.propTypes = {
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  onRemove: PropTypes.func,
  onClose: PropTypes.func,
};

export default RemoveDocumentModal;
