import styled from 'styled-components';

// Lib
import {lib} from '@matthahn/sally-ui';

// Colors
const {
  colors: {grey},
} = lib;

const Top = styled.div`
  width: 100%;
  height: 60px;
  border-style: none none solid none;
  border-width: 1px;
  border-color: ${grey};
  padding: 0px 0px 0px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

export default Top;
