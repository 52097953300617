import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal, Table} from '@matthahn/sally-ui';
import NegativeParenthesis from '../../../layout/components/NegativeParenthesis/NegativeParenthesis';

// Columns
import columns from './columns';

// Types
import {dateTime} from '../../../types';

const DriverBalanceTransactionBreakdownModal = ({
  visible,
  transaction,
  onClose,
}) => (
  <Modal
    visible={visible}
    onClose={onClose}
    title="Balance Overview"
    subtitle={!!transaction ? transaction.label : null}
    icon="areachart"
    size="large"
  >
    {(Content) =>
      !!transaction && (
        <Content padding="none">
          <Table
            columns={columns}
            noBorder
            noRadius
            smallNoResultsLabel
            theme="orange"
          >
            {(TableRow) => [
              ...transaction.transactions.map((trans) => (
                <TableRow key={trans.id}>
                  {(TableColumn) => [
                    <TableColumn key="date">
                      {dateTime(trans.posted_at).format()}
                    </TableColumn>,
                    <TableColumn key="type">
                      {trans.subtype}
                      {trans.type === 'credit' && ` ${trans.type}`}
                    </TableColumn>,
                    <TableColumn key="amount">
                      <NegativeParenthesis
                        pre="$"
                        value={trans.amount}
                        wrap={!transaction.owed_to_sally}
                      />
                    </TableColumn>,
                    <TableColumn key="balance">
                      <NegativeParenthesis
                        pre="$"
                        value={trans.balance}
                        wrap={!transaction.owed_to_sally}
                      />
                    </TableColumn>,
                    <TableColumn key="description">
                      {trans.description || '-'}
                    </TableColumn>,
                  ]}
                </TableRow>
              )),
            ]}
          </Table>
        </Content>
      )
    }
  </Modal>
);

DriverBalanceTransactionBreakdownModal.propTypes = {
  visible: PropTypes.bool,
  transaction: PropTypes.object,
  onClose: PropTypes.func,
};

export default DriverBalanceTransactionBreakdownModal;
