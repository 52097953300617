import prepare from '../../attribute/prepare.attribute';

// Constants
import fhvAttributes from '../constants/fhvAttributes.constants.driver';

export default async (attributes) =>
  prepare(attributes, {
    field: 'api',
    required: [...fhvAttributes],
  });
