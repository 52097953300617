import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal, Row, Column, Form, Select} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

const CreditModal = ({
  allocate,
  visible,
  loading,
  amount,
  subtype,
  subtypes,
  description,
  chargeDriver,
  showDriverSelection,
  drivers,
  onSave,
  onIssue,
  onChange,
  onClose,
}) => (
  <Modal
    icon="upload"
    title="Credit"
    visible={visible}
    onClose={onClose}
    size="small"
    buttonsRight={[
      {
        label: 'Create',
        theme: 'orange',
        onClick: onIssue,
        loading: loading,
      },
    ]}
    buttonsLeft={
      allocate
        ? [
            {
              label: 'Allocate Credit',
              theme: 'grey',
              onClick: onSave,
              disabled: loading,
            },
          ]
        : []
    }
  >
    {(Component) => (
      <Component>
        <Form onSubmit={onSave} enter>
          <Row margin>
            <Column>
              <AttributeInput
                value={amount}
                onChange={onChange}
                disabled={loading}
                preValue="$"
              >
                {amount.label.short}
              </AttributeInput>
            </Column>
          </Row>
          <Row margin>
            <Column>
              <AttributeInput
                value={subtype}
                onChange={onChange}
                disabled={loading}
                options={subtypes}
              >
                {subtype.label.short}
              </AttributeInput>
            </Column>
          </Row>
          <Row margin={showDriverSelection}>
            <Column>
              <AttributeInput
                value={description}
                onChange={onChange}
                disabled={loading}
              >
                {description.label.short}
              </AttributeInput>
            </Column>
          </Row>
          {showDriverSelection && (
            <Select
              value={chargeDriver}
              options={drivers}
              onChange={(val) => onChange(val, 'chargeDriver')}
              disabled={loading}
              limitOptions={15}
            >
              Driver to charge
            </Select>
          )}
        </Form>
      </Component>
    )}
  </Modal>
);

CreditModal.propTypes = {
  allocate: PropTypes.bool,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  amount: PropTypes.object,
  subtype: PropTypes.object,
  subtypes: PropTypes.array,
  description: PropTypes.object,
  chargeDriver: PropTypes.string,
  showDriverSelection: PropTypes.bool,
  drivers: PropTypes.array,
  onSave: PropTypes.func,
  onIssue: PropTypes.func,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
};

export default CreditModal;
