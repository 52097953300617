import React from 'react';
import PropTypes from 'prop-types';

// components
import { Label } from '@matthahn/sally-ui';

// local components
import Container from './Container'

const KibanaDashboards = ({kibanaCreds}) => (
  <Container>
    <Label medium inline label={kibanaCreds}></Label>
  </Container>

);

KibanaDashboards.propTypes = {
  kibanaCreds: PropTypes.string,
};

export default KibanaDashboards;
