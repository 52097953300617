// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// changeOrder components
import ChangeOrderActionsModal from '../../components/ChangeOrderActionsModal/ChangeOrderActionsModal';

// changeOrder events
import showChangeOrderActionsEvent from '../../events/showActions.event.changeOrder';
import showSignChangeOrderContractEvent from '../../events/showSignChangeOrderContract.event.changeOrder';

// contract api
import notifyDriverByCsrIdApi from '../../api/sign.api.contract';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

class ChangeOrderActionsContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
  };

  componentDidMount() {
    this.props.subscribe(showChangeOrderActionsEvent.subscribe(this.show));
  }

  state = {
    sendingText: false,
    signatureRequest: null,
    visible: false,
  };

  show = async ({csr}) => {
    if (!csr) return;
    this.setState({visible: true, signatureRequest: csr});
  };

  hide = () => {
    const {sendingText} = this.state;
    if (sendingText) return;
    this.setState({visible: false});
  };

  actions = () => {
    const {sendingText} = this.state;
    return [
      {
        icon: 'mdi-draw',
        label: 'Sign',
        onClick: this.openChangeOrderContract,
      },
      {
        icon: sendingText ? 'mdi-loading mdi-spin' : 'mdi-message-text',
        label: 'Text to sign',
        onClick: this.sendText,
        disabled: sendingText,
      },
    ].filter((action) => !!action);
  };

  triggerAction = (action) => () => {
    if (action.disabled) return;
    action.onClick();
  };

  openChangeOrderContract = () => {
    const {signatureRequest} = this.state;
    this.setState({visible: false});
    showSignChangeOrderContractEvent.publish({signatureRequest});
  };

  sendText = async () => {
    const {signatureRequest, sendingText} = this.state;
    if (sendingText) return;
    this.setState({sendingText: true});

    try {
      await notifyDriverByCsrIdApi(signatureRequest.id);
      this.setState({sendingText: false, visible: false});
      alert.success('Message sent');
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({sendingText: false});
    }

    this.setState({sendingText: false});
  };

  render() {
    const {visible, signatureRequest} = this.state;
    return (
      <ChangeOrderActionsModal
        actions={this.actions()}
        loading={!signatureRequest}
        onAction={this.triggerAction}
        onClose={this.hide}
        visible={visible}
      />
    );
  }
}

export default subscriptionHOC(ChangeOrderActionsContainer);
