import prepare from '../../attribute/prepare.attribute';

export default async (attributes) => {
  const {injuries} = attributes;
  const required = [
    injuries?.value() && 'injuries_description',
    'datetime_of_loss',
    'description',
  ].filter((requirement) => !!requirement);
  const accident = prepare(attributes, {
    field: 'api',
    required,
  });
  return accident;
};
