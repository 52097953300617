import styled from 'styled-components';

const TableCol = styled.div`
  width: 50px;
  max-width: 50px;
  min-width: 50px;
  padding: 7px 10px;
  cursor: ${({onClick}) => (!!onClick ? 'pointer' : 'initial')};
`;

export default TableCol;
