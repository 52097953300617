// Types
import incomeAllocationTransaction from './incomeAllocationTransaction.lib.payout';

export default (transactions, incomeAllocation) => {
  return !!incomeAllocation
    ? [
        ...transactions,
        incomeAllocationTransaction(incomeAllocation.transaction),
      ]
    : [...transactions];
};
