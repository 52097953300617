import isString from 'is-string';

import type from '../type';

const format = (value) =>
  isString(value) ? value.replace(/[^a-zA-Z0-9]/gi, '').slice(0, 24) : '';

const validate = (value) => /^[a-zA-Z0-9]{24}$/.test(value);

export default type({type: 'string', format, validate});
