import {TEXTAREA} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {string} from '../../types';

export default attribute({
  type: TEXTAREA,
  attribute: 'reason',
  display: string,
  input: string,
  api: string,
  label: {
    default: 'Reason',
    short: 'Reason',
  },
});
