import React from 'react';

// Components
import Page from '../../../layout/containers/Page/Page';

// Containers
import AccidentContainer from '../../containers/AccidentContainer/AccidentContainer';

// Info
import page from './page';

const AccidentPage = ({
  match: {
    params: {id},
  },
}) => (
  <Page page={page}>
    <AccidentContainer id={id} />
  </Page>
);

export default AccidentPage;
