import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {v4} from 'uuid';
import {parseISO, isWithinInterval} from 'date-fns';

// Api
import listDriversApi from '../../../driver/api/list.api.driver';
import listRentalsApi from '../../../rental/api/list.api.rental';

// Components
import DriverRentalTicketModal from '../../components/DriverRentalTicketModal/DriverRentalTicketModal';

class DriverRentalTicketContainer extends Component {
  static propTypes = {
    ticket: PropTypes.object,
    visible: PropTypes.bool,
    onSelect: PropTypes.func,
    onClose: PropTypes.func,
  };

  state = {
    loading: false,
    drivers: [],
    rentals: [],
    rentalPage: 1,
    moreRentals: false,
    search: '',
    driver: null,
  };

  componentDidMount() {
    if (this.props.visible) this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.visible && this.props.visible) this.init();
  }

  apiID = null;

  init = () => {
    this.setState({
      driver: null,
      rentals: [],
      rentalPage: 1,
      moreRentals: false,
    });
    this.getDrivers({search: ''});
  };

  getDrivers = async ({search = this.state.search} = {}) => {
    const apiID = v4();
    this.apiID = apiID;
    this.setState({loading: true, search});

    try {
      const query = {
        limit: 25,
        offset: 0,
      };
      if (!!search.trim().length) query.search = search;
      const {results: drivers} = await listDriversApi(query);
      if (this.apiID !== apiID) return;
      this.setState({loading: false, drivers});
    } catch (error) {
      if (this.apiID !== apiID) return;
      this.setState({loading: false});
    }
  };

  parseRentals = (rentals) => {
    const {ticket} = this.props;
    const ticketDate = !!ticket?.transaction_date
      ? parseISO(ticket.transaction_date)
      : null;
    return [...rentals].map((rental) => ({
      ...rental,
      canApply:
        !!ticketDate &&
        !!rental.vehicle_pickup_datetime &&
        !!rental.vehicle_dropoff_datetime &&
        isWithinInterval(ticketDate, {
          start: parseISO(rental.vehicle_pickup_datetime),
          end: parseISO(rental.vehicle_dropoff_datetime),
        }),
    }));
  };

  onClose = () => {
    if (this.state.loading) return;
    this.setState({search: ''});
    this.props.onClose();
  };

  onSearch = (search) => this.getDrivers({search});

  onBack = () => this.setState({driver: null});

  onDriver = (driver) => () =>
    this.getRentals({driver, rentalPage: 1, reset: true});

  getRentals = async ({
    driver = this.state.driver,
    rentalPage = this.state.rentalPage,
    reset = false,
  }) => {
    const {loading} = this.state;

    if (loading) return;
    this.setState({loading: true, driver, rentalPage});

    const limit = 20;

    try {
      const {results: rawRentals, next} = await listRentalsApi({
        driver: driver.id,
        ordering: '-created_at',
        limit,
        offset: (rentalPage - 1) * limit,
      });
      const newRentals = this.parseRentals(rawRentals);
      const rentals = reset
        ? newRentals
        : [...this.state.rentals, ...newRentals];
      this.setState({loading: false, rentals, moreRentals: !!next});
    } catch (error) {
      this.setState({loading: false});
    }
  };

  onRental = (rental) => () => {
    this.setState({search: ''});
    this.props.onSelect(rental);
  };

  loadMore = () => {
    if (!this.state.moreRentals) return;
    this.getRentals({rentalPage: this.state.rentalPage + 1});
  };

  render() {
    const {visible} = this.props;
    const {loading, drivers, rentals, moreRentals, search, driver} = this.state;
    return (
      <DriverRentalTicketModal
        visible={visible}
        loading={loading}
        drivers={drivers}
        rentals={rentals}
        moreRentals={moreRentals}
        search={search}
        driver={driver}
        onClose={this.onClose}
        onSearch={this.onSearch}
        onDriver={this.onDriver}
        onRental={this.onRental}
        onBack={this.onBack}
        onMore={this.loadMore}
      />
    );
  }
}

export default DriverRentalTicketContainer;
