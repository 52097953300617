import {SELECT} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {string} from '../../types';

export default attribute({
  type: SELECT,
  attribute: 'party_type',
  display: string,
  input: string,
  api: string,
  label: {
    default: 'Party Type',
    short: 'Party Type',
  },
  options: ['VEHICLE', 'PEDESTRIAN', 'BICYCLIST'],
});
