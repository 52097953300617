import {isDate, parseISO, format as formatDate} from 'date-fns';

import type from '../type';
import customDateTimeParser from '../customDateTimeParser.types';

const format = (value) => {
  if (!value) return '';
  const dateObj = isDate(value) ? value : parseISO(customDateTimeParser(value));
  return formatDate(dateObj, 'yyyy-MM-dd');
};

const validate = (value) => /^\d{4}-\d{2}-\d{2}$/.test(value);

export default type({type: 'date', format, validate});
